.container {
  display: flex;
  flex-direction: column;

  .label {
    display: flex;
    margin-bottom: var(--indent-xxs);

    .helpIcon {
      margin-left: 4px;
    }

    .labelContent {
      display: flex;
      justify-content: space-between;
      column-gap: var(--indent-xxs);
      color: var(--color-gray-800);

      .required::after {
        content: '*';
        color: var(--color-red-error);
        margin-left: 4px;
      }
    }
  }
}
