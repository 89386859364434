import request from '../lib/request';

export default {
  findMySuppliers: async (requestMany) =>
    request.post('/api/suppliers/my', requestMany)
};

export const getUsrleInfo = async (query) => {
  try {
    return await request.get(`/auth/usrle_info?query=${query}`);
  } catch (error) {
    console.log(error);
  }
  return [];
};

export const canRegistrationCompanyByInn = async (inn) => {
  try {
    return await request.get(`/auth/check_inn?inn=${inn}`);
  } catch (error) {
    console.log(error);
  }
  return {};
};
