import React, { useRef } from 'react';
import { Button, Icon, Typography } from '@link/react-components';
import cn from 'classnames';
import styles from './FileSection.module.css';
import { Loader } from '../Loader';
import { MODE } from '../constants';
import { useTranslate } from '../../../../../TranslateProvider';

function Title({ title, className }) {
  return (
    <Typography
      variant="body1Med"
      className={cn(styles.title, className)}
      align="center">
      {title}
    </Typography>
  );
}

function Container({ children }) {
  return <section className={styles.section}>{children}</section>;
}

function View({ error = '', fileName }) {
  return (
    <Container>
      <Icon
        iconName="excel"
        width={30}
        height={30}
        className={error ? styles.iconError : ''}
      />

      <Title title={fileName} className={styles.viewText} />

      {error && <Typography className={styles.textError}>{error}</Typography>}
    </Container>
  );
}

function Input({ onFileLoad }) {
  const t = useTranslate();
  const ref = useRef(null);
  const onExchangeFileClick = () => {
    ref.current.click();
  };
  return (
    <Container>
      <Icon iconName="input-file" width={30} height={30} />
      <Button
        onClick={onExchangeFileClick}
        mode="text"
        hasActive={false}
        hasHover={false}
        className={styles.choseFileBlue}>
        {t('modalImportResponse.choose')}
      </Button>
      <input
        type="file"
        id="input-file"
        onChange={onFileLoad}
        ref={ref}
        hidden
      />
    </Container>
  );
}

function Loading() {
  const t = useTranslate();
  return (
    <Container>
      <Loader />
      <Title
        title={t('modalImportResponse.importing')}
        className={styles.loaderText}
      />
    </Container>
  );
}

export const FileSection = ({ onFileLoad, fileName, error, mode }) => (
  <div className={styles.container}>
    {mode === MODE.LOADING && <Loading />}
    {mode === MODE.INPUT && <Input onFileLoad={onFileLoad} />}
    {mode === MODE.ERROR && <View fileName={fileName} error={error} />}
    {mode === MODE.VIEW && <View fileName={fileName} />}
  </div>
);
