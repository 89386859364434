import React, { useCallback } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import fetchIntercept from 'fetch-intercept';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-select/dist/react-select.css';
import './css/common.styl';
import './css/mobile-common.css';
import 'react-components/src/styles/components.css';
import { ThemeProvider } from 'styled-components';
// eslint-disable-next-line import/no-named-as-default
import theme from 'styledComponents/theme';
import GlobalStyles from 'styledComponents/globalStyles';

import { ToastContainer } from '@link/react-components';
import { Interceptor } from '@root/Interceptor';
import { toastConfigurator } from '@root/modules/toast';
import LocalProvider from './localProvider';
import CatchErrors from './CatchErrors';
import ConnectionsWrapper from './ConnectionsWrapper';
import TranslateProvider from './TranslateProvider';
import 'whatwg-fetch';
import i18n from './i18n';
import MainFeedbackFrom from './components/Main/MainFeedbackForm';
import MainComingSoon from './components/Main/MainComingSoon';
import MainNotPermission from './components/Main/MainNotPermission';
import MessengerWidgetWrapper from './components/Widgets/Messenger/MobMessengerWidget';
import MessengerResetPass from './pages/MessengerResetPass';
import MessengerResetPassPerfrom from './pages/MessengerResetPassPerform';
import MessengerVerifyEmail from './pages/MessengerVerifyEmail';
import MessengerInvalidLink from './pages/MessengerInvalidLink';
import MessengerReg from './pages/MessengerReg';
import MessengerAuth from './pages/MessengerAuth';
import Verify from './pages/InviteTokenVerification';
import ClearDataPage from './pages/ClearData';
import PartnerInvite from './pages/PartnerInvite';
import ModalRoot from './components/ModalRoot';

export default (props) => {
  const interceptor = useCallback(
    () =>
      fetchIntercept.register({
        request(url, requestConfig) {
          if (!requestConfig) {
            return [url, requestConfig];
          }
          const modifiedHeaders = new Headers(requestConfig.headers);
          // TODO удалить после удаления проверки на беке
          modifiedHeaders.append('X-Client-Name', 'Link');
          requestConfig.headers = modifiedHeaders;
          return [url, requestConfig];
        }
      }),
    []
  );

  return (
    <I18nextProvider i18n={i18n}>
      <CatchErrors>
        <ThemeProvider theme={theme}>
          <Provider store={props.store}>
            <LocalProvider history={props.history}>
              <Interceptor interceptor={interceptor}>
                <TranslateProvider>
                  <ConnectionsWrapper>
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        overflowX: 'hidden',
                        position: 'relative'
                      }}>
                      <Routes>
                        <Route
                          path="/reset-password/perform"
                          element={<MessengerResetPassPerfrom />}
                        />
                        <Route
                          path="/reset-password"
                          element={<MessengerResetPass theme="mobile" />}
                        />
                        <Route
                          path="/verify-email"
                          element={<MessengerVerifyEmail />}
                        />
                        <Route
                          path="/invalid-link"
                          element={<MessengerInvalidLink />}
                        />
                        <Route
                          path="/registration"
                          element={<MessengerReg theme="mobile" />}
                        />
                        <Route
                          path="/registration/:token"
                          element={<MessengerReg theme="mobile" />}
                        />
                        <Route
                          path="/welcome/registration/:tokenLink"
                          element={<MessengerReg theme="mobile" />}
                        />
                        <Route
                          path="/welcome/partner/registration/:partnerToken"
                          element={<MessengerReg theme="mobile" />}
                        />

                        <Route
                          path="/welcome/login/:tokenLink"
                          element={<MessengerAuth theme="mobile" />}
                        />
                        <Route
                          path="/welcome/partner/login/:partnerToken"
                          element={<MessengerAuth theme="mobile" />}
                        />
                        <Route
                          path="/login"
                          element={<MessengerAuth theme="mobile" />}
                        />
                        <Route path="/" element={<MessengerWidgetWrapper />} />
                        <Route
                          path="/chat/:chatId"
                          element={<MessengerWidgetWrapper />}
                        />

                        <Route
                          path="/dialogs/welcome/:token"
                          element={<PartnerInvite />}
                        />
                        <Route
                          path="/welcome/:companyId/:tokenLink"
                          element={<Verify />}
                        />

                        <Route
                          path="/feedback"
                          element={<MainFeedbackFrom />}
                        />
                        <Route
                          path="/coming-soon"
                          element={<MainComingSoon />}
                        />
                        <Route
                          path="/not-permission"
                          element={<MainNotPermission />}
                        />
                        <Route path="/clear-data" element={<ClearDataPage />} />
                        <Route path="*" element={<Navigate to="/" />} />
                      </Routes>
                    </div>
                  </ConnectionsWrapper>
                  <ModalRoot />
                  <ToastContainer
                    configurator={toastConfigurator}
                    autoClose={5000}
                    closeOnClickOutside
                  />
                  <GlobalStyles />
                </TranslateProvider>
              </Interceptor>
            </LocalProvider>
          </Provider>
        </ThemeProvider>
      </CatchErrors>
    </I18nextProvider>
  );
};
