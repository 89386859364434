import React, { useCallback, useState } from 'react';
import { Loader, Modal } from '@link/react-components';
import { useTranslate, useAsync } from '@hooks';
import { hintService } from '@root/api';
import { toast } from '@root/modules/toast';
import { useFillCompanyDataModal } from '@root/components/Modal';
import { FillCompanyDataForm } from '@root/widgets/FillCompanyDataForm';
import { companyService } from '@api';
import { MODAL_KEY, hints } from '../../lib/constants';
import styles from './ModaFillCompanyData.module.css';
import { FooterFillCompanyData } from '../FooterFillCompanyData/FooterFillCompanyData';
import { toSaveCompanyRequisitesDto } from '../../lib/mapper';

const ModalFillCompanyData = ({ companyId, viewExternalHint = null }) => {
  const [, hideModal] = useFillCompanyDataModal();
  const [isHintChecked, setIsHintChecked] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const t = useTranslate();

  const { loading, value } = useAsync(async () => {
    try {
      const suggestedInfo = await companyService.getSuggestedInfo(companyId);
      return suggestedInfo;
    } catch (err) {
      return {};
    }
  }, [companyId]);

  const disableSubmit = useCallback((values) => {
    const isDisable = Object.values(values).every(
      (value) => Boolean(value) === false
    );
    setIsSubmitDisabled(isDisable);
  }, []);

  const handleCheckHint = () => {
    setIsHintChecked((prevState) => !prevState);
  };

  const handleSaveHintAsViewed = () => {
    hideModal();
    if (isHintChecked) {
      hintService.view({ hintName: hints.populateCompanyInfo });
      viewExternalHint && viewExternalHint();
    }
  };

  const handleSubmitForm = useCallback(
    async (values) => {
      try {
        const dto = toSaveCompanyRequisitesDto(values, isHintChecked);
        await companyService.saveSuggestedInfo(companyId, dto);
        hideModal();
        toast.success(t('fillCompanyDataModal.saveDataSuccess'));
      } catch (err) {
        toast.error(t('fillCompanyDataModal.saveDataError'));
      }
    },
    [isHintChecked]
  );

  return (
    <Modal
      open
      onClose={hideModal}
      className={loading ? styles.containerLoading : styles.container}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Modal.Header>{t('fillCompanyDataModal.title')}</Modal.Header>
          <Modal.Content>
            <FillCompanyDataForm
              data={value}
              handleSubmit={handleSubmitForm}
              disableSubmit={disableSubmit}
            />
          </Modal.Content>
          <Modal.Footer className={styles.footer}>
            <FooterFillCompanyData
              closeModal={handleSaveHintAsViewed}
              hadleCheck={handleCheckHint}
              isHintChecked={isHintChecked}
              isSubmitDisabled={isSubmitDisabled}
            />
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

export const initFillCompanyDataModal = { [MODAL_KEY]: ModalFillCompanyData };
