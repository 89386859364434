.select {
  background-color: var(--color-white);
  border: 1px solid var(--color-gray-500);
  border-radius: 4px;
  width: 100%;

  &:hover {
    border-color: var(--color-blue-200);
  }

  &.error {
    border: 1px solid var(--color-red-error);
    border-radius: 4px;
  }

  &.active {
    border: 1px solid var(--color-blue-400);
    border-radius: 4px;
    box-shadow: 0 0 5px rgb(35 105 241 / 31%);
  }

  &.disabled {
    border: 1px solid var(--color-gray-300);

    &:hover {
      border: 1px solid var(--color-gray-300);
    }
  }

  :global(.MuiAutocomplete-root) {
    padding: 0;
    text-transform: none;

    :global(.MuiInputBase-root) {
      font-size: inherit;
      font-weight: inherit;
      font-family: inherit;
      padding: 0 !important;

      &:global(.MuiOutlinedInput-notchedOutline) {
        padding: 0;
      }

      &:global(.MuiOutlinedInput-root) {
        border: none;
        padding: 0;
      }

      &:global(.MuiOutlinedInput-root.Mui-disabled) {
        background-color: var(--color-gray-300);

        & > input {
          -webkit-text-fill-color: var(--color-gray-700);
        }

        & > input::placeholder {
          color: var(--color-gray-700);
        }
      }

      :global(.MuiAutocomplete-endAdornment) {
        border-left: 1px solid var(--color-gray-500);
        padding: 8px 10px 8px 8px;
        display: flex;
        position: static;
      }

      :global(.MuiIconButton-root) {
        padding: 0;
      }

      &:global(.Mui-disabled) {
        :global(.MuiAutocomplete-endAdornment) {
          border-left-color: var(--color-gray-300);
        }
      }
    }

    :global(.MuiAutocomplete-inputRoot) {
      text-transform: none;
      padding: 0;

      &:hover {
        border-color: var(--color-blue-200);
      }

      :global(.MuiAutocomplete-input) {
        padding: 6px 16px;
        font-family: inherit;
        color: var(--main-dark-gray);
        height: unset;

        &::placeholder {
          color: var(--color-gray-600);
          opacity: 1;
        }
      }

      :global(.MuiOutlinedInput-notchedOutline) {
        border: none;
      }
    }
  }
}

.arrow {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow:hover {
  background-color: transparent;
}

.popper {
  border-radius: 4px;
  box-shadow: 0 3px 7px 0 rgb(36 48 103 / 20%);

  :global(.MuiPaper-root) {
    border: none;
    box-shadow: none;
    color: var(--color-gray-900);

    :global(.MuiAutocomplete-listbox) {
      :global(.MuiAutocomplete-option) {
        padding: 0;
        background-color: var(--color-white);
      }

      &::-webkit-scrollbar {
        width: 8px;
        background-color: var(--color-gray-200);
      }

      &::-webkit-scrollbar-thumb {
        background: rgb(12 35 98 / 20%);
        border-radius: 13px;
      }
    }

    :global(.MuiAutocomplete-noOptions) {
      font-size: inherit;
      font-weight: inherit;
      font-family: inherit;
      padding: 0;
      margin: 0 12px;
    }
  }
}

.option {
  margin: 0 12px;
  cursor: pointer;

  &:hover {
    border-radius: 2px;
    background-color: var(--color-blue-50);
  }

  .optionContent {
    padding: 6px;
    margin: 0;
  }
}

.noOption {
  padding: 8px 0;
  color: var(--color-gray-700);
}

.startAdornment {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: var(--indent-m);
}

.errorMessage {
  margin-top: 2px;
  color: var(--color-red-error);
}
