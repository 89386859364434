import React from 'react';
import ErrorLogo from '../../../../../components/MessengerLogo';
import { useTranslate } from '../../../../../TranslateProvider';
import { clearData, confirmClearData } from '../../../../../storage/lib/LS';
import styles from './ErrorBlock.module.css';

export const ErrorBlock = () => {
  const t = useTranslate();

  const handleClearAndRefresh = () => {
    confirmClearData(clearData);
    document.location.reload();
  };

  return (
    <div className={styles.container}>
      <ErrorLogo width={297} height={50} />
      <p className={styles.text}>{t('something_went_wrong')}</p>
      <a className={styles.refresh} onClick={handleClearAndRefresh}>
        {t('Refresh the page')}
      </a>
      <div className={styles.robotImgContainer}>
        <img alt="robot" src="/img/404.png" />
      </div>
    </div>
  );
};
