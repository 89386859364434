import React, { useCallback, useState } from 'react';
import { Modal, Loader } from '@link/react-components';

import { hintService } from '@root/api';
import { useTranslate, useActions, useAsync } from '@hooks';
import * as modalActions from '@root/action-creators/modal';

import { Welcome } from './welcome';
import { Main } from './main';

import { TRANSLATE_PATH, importPartnersHints } from './constants';

import styles from './ModalImportPartners.module.css';

export const ModalImportPartners = ({ onResetPartnersList }) => {
  const t = useTranslate();
  const translate = (key) => t(`${TRANSLATE_PATH}.${key}`);

  const [showWelcome, setShowWelcome] = useState(false);

  const { loading } = useAsync(async () => {
    const hints = await hintService.getUserHints([importPartnersHints.welcome]);
    setShowWelcome(!hints[0].viewed);
  }, []);

  const handleModalClose = useActions(modalActions.hideModalDialog);

  const goToImport = useCallback(() => {
    setShowWelcome(false);
  }, []);

  return (
    <Modal open onClose={handleModalClose} className={styles.modal}>
      <Modal.Header>{translate('header')}</Modal.Header>
      {loading ? (
        <Loader />
      ) : showWelcome ? (
        <Welcome goToImport={goToImport} />
      ) : (
        <Main
          closeModal={handleModalClose}
          onResetPartnersList={onResetPartnersList}
        />
      )}
    </Modal>
  );
};
