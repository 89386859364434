import { getPlacement, PlacementSides } from './entities/placement';

export const config = {
  // isShowToolBar: true,
  modes: {
    none: true,
    noInternet: true,
    chat: true,
    createTopic: true,
    editTopic: true
  },
  frame: {
    dashboard: true,
    topicPanel: true,
    messages: true,
    inputWrapper: true
  },
  topic: {
    theme: 'main-chat',
    buttonBack: false,
    avatar: true,
    title: true,
    avatarsList: true,
    topic: [
      'search',
      'addMembers',
      'favorite',
      'delayed',
      'notification',
      'videoCall',
      'extra'
    ],
    isShowProducts: true,
    topicEdit: 'edit'
  },
  dashboard: {
    theme: 'main-chat',
    buttonBack: false,
    avatar: true,
    title: true,
    avatarsList: true,
    dialog: [
      'search',
      'favorite',
      'delayed',
      'notification',
      'videoCall',
      'extra'
    ],
    channel: [
      'search',
      'addMembers',
      'favorite',
      'delayed',
      'notification',
      'videoCall',
      'extra'
    ],
    purchaserequestschannel: ['favorite', 'notification'],
    newsfeedchannel: ['extra']
  },
  messages: {
    theme: 'main-chat',
    showAvatar: true,
    avatarSize: 34,
    config: {
      ownMessage: {
        extraBtn: {
          name: 'extra',
          list: [
            {
              conditionName: 'allowEdit?',
              items: [
                {
                  icon: 'edit',
                  value: 'edit',
                  helper: 'chat_message_edit_help',
                  helperPosition: 'right'
                },
                {
                  icon: 'edit',
                  value: 'edit-disable',
                  color: 'inactive',
                  helper: 'chat_message_edit_help',
                  helperPosition: 'right'
                }
              ]
            },
            { icon: 'reply', value: 'reply' },
            {
              icon: 'forward',
              value: 'forward-message'
            },
            {
              conditionName: 'pinned?',
              items: [
                { icon: 'pin', value: 'pin-message' },
                { icon: 'unpin', value: 'unpin-message' }
              ]
            },
            { icon: 'highlight', value: 'highlight-message' },
            { icon: 'star', value: 'favorite' },
            {
              conditionName: 'allowDelete?',
              items: [
                { icon: 'delete', value: 'delete-message' },
                {
                  icon: 'delete',
                  value: 'delete-message-disabled',
                  color: 'inactive'
                }
              ]
            },
            { icon: 'create-task', value: 'create-task' }
          ]
        },
        buttonList: [
          { value: 'reply', tooltip: 'reply-on-message' },
          { value: 'schedule', tooltip: 'set-reminder' },
          { value: 'favorite', tooltip: 'add-to-favorite' }
        ],
        showReadMsg: true,
        placement: 'right',
        positions: {
          default: getPlacement(PlacementSides.Right),
          left: getPlacement(PlacementSides.Left)
        }
      },
      otherMessage: {
        extraBtn: {
          name: 'extra',
          list: [
            { icon: 'reply', value: 'reply' },
            {
              icon: 'forward',
              value: 'forward-message'
            },
            {
              conditionName: 'pinned?',
              items: [
                { icon: 'pin', value: 'pin-message' },
                { icon: 'unpin', value: 'unpin-message' }
              ]
            },
            { icon: 'highlight', value: 'highlight-message' },
            { icon: 'star', value: 'favorite' },
            { icon: 'create-task', value: 'create-task' }
          ]
        },
        buttonList: [
          { value: 'reply', tooltip: 'reply-on-message' },
          { value: 'schedule', tooltip: 'set-reminder' },
          { value: 'favorite', tooltip: 'add-to-favorite' }
        ],
        showReadMsg: false,
        placement: 'left',
        positions: {
          default: getPlacement(PlacementSides.Right),
          left: getPlacement(PlacementSides.Left)
        }
      },
      unsentMessage: {
        extraBtn: {
          name: 'danger-exclamation',
          list: [
            {
              conditionName: 'allowDelete?',
              items: [
                { icon: 'delete', value: 'delete-message' },
                {
                  icon: 'delete',
                  value: 'delete-message-disabled',
                  color: 'inactive'
                }
              ]
            }
          ]
        },
        buttonList: [],
        showReadMsg: false,
        position: 'left-start',
        possiblePositions: [
          'left-center',
          'top-center',
          'right-center',
          'bottom-center'
        ],
        placement: 'right',
        positions: {
          default: getPlacement(PlacementSides.Right),
          left: getPlacement(PlacementSides.Left)
        }
      },
      purchaseRequestMessage: {
        extraBtn: {
          name: 'extra',
          list: [
            {
              icon: 'forward',
              value: 'forward-message'
            },
            { icon: 'highlight', value: 'highlight-message' },
            { icon: 'star', value: 'favorite' }
          ]
        },
        buttonList: [{ icon: 'star', value: 'favorite', tooltip: 'favorite' }],
        showReadMsg: false,
        position: 'right-start',
        possiblePositions: [
          'right-center',
          'top-center',
          'left-center',
          'bottom-center'
        ],
        placement: 'left',
        positions: {
          default: getPlacement(PlacementSides.Left),
          left: getPlacement(PlacementSides.Left)
        }
      },
      newsMessage: {
        placement: 'left'
      }
    }
  },
  favorites: {
    theme: 'main-chat',
    commonDashboardTheme: 'main-chat',
    showAvatar: true,
    config: {
      ownMessage: {
        extraBtn: { name: 'go-to-msg', tooltip: 'go-to-msg' },
        buttonList: [{ icon: 'star', value: 'favorite', tooltip: 'favorite' }],
        showReadMsg: false,
        placement: 'right',
        positions: {
          default: getPlacement(PlacementSides.Right),
          left: getPlacement(PlacementSides.Left)
        }
      },
      otherMessage: {
        extraBtn: { name: 'go-to-msg', tooltip: 'go-to-msg' },
        buttonList: [{ icon: 'star', value: 'favorite', tooltip: 'favorite' }],
        showReadMsg: false,
        placement: 'left',
        positions: {
          default: getPlacement(PlacementSides.Right),
          left: getPlacement(PlacementSides.Left)
        }
      },
      unsentMessage: {
        extraBtn: [],
        buttonList: [],
        showReadMsg: false,
        placement: 'right',
        positions: {
          default: getPlacement(PlacementSides.Right),
          left: getPlacement(PlacementSides.Left)
        }
      },
      purchaseRequestMessage: {
        extraBtn: { name: 'go-to-msg', tooltip: 'go-to-msg' },
        buttonList: [{ icon: 'star', value: 'favorite', tooltip: 'favorite' }],
        showReadMsg: false,
        placement: 'left',
        positions: {
          default: getPlacement(PlacementSides.Left),
          left: getPlacement(PlacementSides.Left)
        }
      }
    }
  },
  delayedMessages: {
    theme: 'main-chat',
    commonDashboardTheme: 'main-chat',
    showAvatar: true,
    config: {
      ownMessage: {
        extraBtn: {
          name: 'extra',
          list: [
            { icon: 'edit', value: 'edit' },
            { icon: 'publish', value: 'publish' },
            { icon: 'delete', value: 'delete-message' }
          ]
        },
        buttonList: [],
        showReadMsg: false,
        position: 'left-start',
        possiblePositions: [
          'left-center',
          'top-center',
          'right-center',
          'bottom-center'
        ],
        placement: 'right',
        positions: {
          default: getPlacement(PlacementSides.Right),
          left: getPlacement(PlacementSides.Left)
        }
      }
    }
  },
  searchMsgs: {
    theme: 'main-chat',
    commonDashboardTheme: 'main-chat',
    showAvatar: true,
    config: {
      ownMessage: {
        extraBtn: { name: 'go-to-msg', tooltip: 'go-to-msg' },
        buttonList: [],
        showReadMsg: false,
        placement: 'right',
        positions: {
          default: getPlacement(PlacementSides.Right),
          left: getPlacement(PlacementSides.Left)
        }
      },
      otherMessage: {
        extraBtn: { name: 'go-to-msg', tooltip: 'go-to-msg' },
        buttonList: [],
        showReadMsg: false,
        placement: 'left',
        positions: {
          default: getPlacement(PlacementSides.Right),
          left: getPlacement(PlacementSides.Left)
        }
      },
      unsentMessage: {
        extraBtn: [],
        buttonList: [],
        showReadMsg: false,
        placement: 'right',
        positions: {
          default: getPlacement(PlacementSides.Right),
          left: getPlacement(PlacementSides.Left)
        }
      },
      purchaseRequestMessage: {
        extraBtn: [],
        buttonList: [],
        showReadMsg: false,
        placement: 'left',
        positions: {
          default: getPlacement(PlacementSides.Left),
          left: getPlacement(PlacementSides.Left)
        }
      }
    }
  },
  inputMessage: {
    theme: 'main-chat'
  }
};
