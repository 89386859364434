import React, { useCallback, useMemo, useState } from 'react';
import { Select, Typography } from '@link/react-components';

import { useAsync } from 'react-use';
import { companyService } from '@api';
import { useSelector } from 'react-redux';
import * as storeGetters from '@root/storeGetters';
import { rolesMapper } from '../../lib/mapper';
import styles from './FullRolesSelect.module.css';

function renderOption(option) {
  return (
    <Typography variant="body1Reg" className={styles.option}>
      {option.name}
    </Typography>
  );
}

export const FullRolesSelect = ({
  id,
  defaultValue,
  setRole,
  denyTyping,
  className,
  additionalOptions,
  disabled
}) => {
  const onSelect = useCallback((entity) => {
    setRole(entity.id);
  }, []);

  const [currentDefaultRole, setCurrentDefaultRole] = useState(null);

  const companyId = useSelector(storeGetters.getCurrentCompanyId);

  const getOptionLabel = useCallback((data) => data.name, []);

  const { value: roles, loading } = useAsync(async () => {
    if (companyId) {
      return companyService.getAssignableRoles(companyId);
    }

    return [];
  }, [companyId]);

  const options = useMemo(() => {
    const result = [];

    if (roles) {
      result.push(...roles);
    }

    if (additionalOptions) {
      result.push(...additionalOptions);
    }

    const currentRole = result.find((role) => role.id === defaultValue);

    if (!currentRole) {
      const defaultRole = result.find((role) => role.default);

      if (defaultRole) {
        setCurrentDefaultRole({ key: defaultRole.id, value: defaultRole });
        setRole(defaultRole.id);
      }
    } else {
      setCurrentDefaultRole({ key: currentRole.id, value: currentRole });
    }
    return rolesMapper.transformToViewModel(result);
  }, [roles, additionalOptions]);

  if (loading) return null;

  return (
    <Select
      id={id}
      className={className}
      options={options}
      renderOption={renderOption}
      onSelect={onSelect}
      getOptionLabel={getOptionLabel}
      defaultValue={currentDefaultRole}
      denyTyping={denyTyping}
      disabled={disabled}
    />
  );
};
