import React, { useCallback, useEffect, useState } from 'react';
import { Button, Modal, Loader } from '@link/react-components';
import { useTranslate, useAsync, useAsyncFn, useSnackbar } from '@hooks';
import {
  useManageEmployeePermissionsModal,
  useConfirmResetEmployeePermissionsModal
} from '@root/components/Modal';
import { myCompanyService } from '@api';
import { EmployeePermissions } from '../../PermissionsAndRoles/components/EmployeePermissions/EmployeePermissions';
import { MODAL_KEY, PERMISSIONS_TYPE } from './constants';
import styles from './ModalManageEmployeePermissions.module.css';

const ModalManageEmployeePermissions = ({ employeeId }) => {
  const t = useTranslate();
  const [showSnackbar] = useSnackbar();

  const [, hideModal] = useManageEmployeePermissionsModal();
  const [openConfirmResetEmployeePermissionsModal] =
    useConfirmResetEmployeePermissionsModal();
  const [canSubmit, setCanSubmit] = useState(false);

  const [permissionsDiff, setPermissionsDiff] = useState([]);

  const { value: permissions, loading: permissionsLoading } = useAsync(
    async () => myCompanyService.getPermissions(employeeId),
    [employeeId]
  );

  const [{ loading }, updatePermissions] = useAsyncFn(
    async (diff) => {
      try {
        await myCompanyService.updatePermissions(employeeId, diff);
        showSnackbar(t('modules.managePermissionsModal.afterSucceed'));
        hideModal();
      } catch (error) {
        console.error(error);
        showSnackbar(t('modules.managePermissionsModal.afterFailure'));
      }
    },
    [employeeId, hideModal, showSnackbar, t]
  );

  const canReset = Array.isArray(permissions)
    ? permissions.some(
        (permission) => permission.type === PERMISSIONS_TYPE.unique
      )
    : false;

  const resetPermissions = useCallback(() => {
    openConfirmResetEmployeePermissionsModal({ employeeId });
  }, [employeeId]);

  useEffect(() => {
    setCanSubmit(!!permissionsDiff.length);
  }, [permissionsDiff]);

  return (
    <Modal open onClose={hideModal}>
      <Modal.Header>{t('modules.managePermissionsModal.title')}</Modal.Header>
      <Modal.Content>
        {permissionsLoading ? (
          <Loader />
        ) : (
          <EmployeePermissions
            initialPermissions={permissions}
            setPermissionsDiff={setPermissionsDiff}
          />
        )}
      </Modal.Content>
      <Modal.Footer align="right">
        <Modal.Actions className={styles.actions}>
          <Button onClick={resetPermissions} disabled={!canReset} mode="text">
            {t('modules.managePermissionsModal.actionButtons.reset')}
          </Button>
          <div className={styles.rightActions}>
            <Button onClick={hideModal} mode="text">
              {t('modules.managePermissionsModal.actionButtons.cancel')}
            </Button>
            <Button
              disabled={!canSubmit}
              loading={loading}
              onClick={() => updatePermissions(permissionsDiff)}>
              {t('modules.managePermissionsModal.actionButtons.save')}
            </Button>
          </div>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export const initManageEmployeePermissions = {
  [MODAL_KEY]: ModalManageEmployeePermissions
};
