import React, { memo } from 'react';
import { Typography } from '@link/react-components';
import { useTranslate } from '@hooks';
import { VisibilitySelect } from '@root/entities';
import styles from './CorporateInfo.module.css';

const VisibilitySelectControl = ({ visibility, setVisibility }) => {
  const t = useTranslate();

  return (
    <div className={styles.selectWrapper}>
      <Typography className={styles.selectLabel}>
        <label htmlFor="visibility-select">
          {t('features.corporateInfo.visibility.label')}
        </label>
      </Typography>
      <VisibilitySelect
        id="visibility-select"
        defaultValue={visibility}
        denyTyping
        setVisibility={setVisibility}
      />
    </div>
  );
};

export const MemoizedVisibilitySelect = memo(VisibilitySelectControl);
