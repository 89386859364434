import React, { useState } from 'react';
import {
  useActions,
  useNavigate,
  useLocation,
  useAsync,
  useTranslate
} from '@hooks';
import { routeService } from '@api';
import { Button, Modal, Typography, Link, Icon } from '@link/react-components';
import { usePermissions } from '@root/modules/permissions';
import { routeType } from '@root/pages/constructor/builder/components/constants';
import * as modalActions from '../../../action-creators/modal';

import styles from './ModalCreatePurchaseRequest.module.css';
import { createRequestPathTypes } from '../../PurchaseRequests/domain/entities';

import { Loader } from '../../../shared/components';

const paths = Object.freeze({
  regular: '/requests/all/my-requests/create',
  withConfirmation: '/requests/all/my-requests/create',
  createRoute: '/constructor/create/request'
});

const SECTION_HEIGHT = 132;

function WithConfirmationOrCreateRouteBlock({ action }) {
  const t = useTranslate();
  const { loading, value: routesCount } = useAsync(async () => {
    const { result } = await routeService.getCompanyRoutesCount({
      type: routeType.request
    });

    return result;
  }, []);

  if (loading) return <SectionLoader />;

  if (routesCount)
    return (
      <InfoBlock
        title={t('purchaseRequest.createModal.withConfirmation.title')}
        content={t('purchaseRequest.createModal.withConfirmation.content')}
        buttonText={t('purchaseRequest.createModal.withConfirmation.button')}
        action={() =>
          action(
            paths.withConfirmation,
            createRequestPathTypes.withConfirmationRequest
          )
        }
      />
    );

  return (
    <InfoBlock
      title={
        <span className={styles.disabledTitle}>
          {t('purchaseRequest.createModal.createRoute.title')}
        </span>
      }
      content={
        <div className={styles.info}>
          <Icon
            className={styles.icon}
            iconName="info"
            width={16}
            height={16}
          />
          <span>{t('purchaseRequest.createModal.createRoute.content')}</span>
          <Link
            openInNewTab={false}
            className={styles.link}
            href={paths.createRoute}
            displayName={t('purchaseRequest.createModal.createRoute.button')}
          />
        </div>
      }
      buttonText={t('purchaseRequest.createModal.withConfirmation.button')}
      action={() => action(paths.createRoute)}
      disabled
    />
  );
}

function SectionLoader({ height = SECTION_HEIGHT }) {
  return (
    <section className={styles.section} style={{ height }}>
      <Loader />
    </section>
  );
}

function InfoBlock({ action, disabled, title, content, buttonText }) {
  const [isPrimaryButton, setIsPrimaryButton] = useState(false);

  return (
    <section className={styles.section}>
      <Typography variant="h2" className={styles.sectionTitle}>
        {title}
      </Typography>
      <Typography
        variant="body1Reg"
        className={styles.sectionContent}
        Component="div">
        {content}
      </Typography>
      <Button
        stretched
        disabled={disabled}
        onMouseLeave={() => setIsPrimaryButton(false)}
        onMouseEnter={() => setIsPrimaryButton(true)}
        mode={isPrimaryButton ? 'primary' : 'outline'}
        onClick={action}>
        {buttonText}
      </Button>
    </section>
  );
}

const ModalCreatePurchaseRequest = ({ options }) => {
  const t = useTranslate();
  const permissions = usePermissions();
  const { hideModalDialog } = useActions(modalActions);
  const navigate = useNavigate();
  const location = useLocation();

  const goToPage = (path, type) => {
    navigate(path, {
      state: {
        noRestoreData: true,
        type,
        from: location.pathname,
        needsFilter: options?.needsFilter || []
      }
    });
    hideModalDialog();
  };

  const shownCreateRegularRequestBlock =
    permissions.haveRequestsEditPermissions();
  const shownCreateWithConfirmationRequestBlock =
    permissions.haveRequestsWithConfirmationCreatePermissions();

  return (
    <Modal onClose={hideModalDialog} open className={styles.container}>
      <Modal.Header
        typographyClassName={styles.title}
        className={styles.header}>
        {t('purchaseRequest.createModal.title')}
      </Modal.Header>
      <Modal.Content className={styles.content}>
        {shownCreateRegularRequestBlock && (
          <InfoBlock
            title={t('purchaseRequest.createModal.regular.title')}
            content={t('purchaseRequest.createModal.regular.content')}
            buttonText={t('purchaseRequest.createModal.regular.button')}
            action={() =>
              goToPage(paths.regular, createRequestPathTypes.regular)
            }
          />
        )}
        {shownCreateWithConfirmationRequestBlock && (
          <WithConfirmationOrCreateRouteBlock action={goToPage} />
        )}
      </Modal.Content>
    </Modal>
  );
};

export default ModalCreatePurchaseRequest;
