import React from 'react';
import { useWizard, Typography } from '@link/react-components';
import classes from './TitleCounter.module.css';

export function TitleCounter() {
  const { getState } = useWizard();

  const { selectedCompanies } = getState();

  return (
    <div className={classes.titleCounter}>
      <Typography className={classes.currentCount} variant="body1Sb">
        {selectedCompanies.length}
      </Typography>
    </div>
  );
}
