import React from 'react';
import cn from 'classnames';
import { Trans } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useTranslate } from '@hooks';
import { DoubleToast } from '@root/modules/toast/components';
import { Typography, Icon } from '@link/react-components';
import styles from './SubscribeCompanyPurchaseRequestToast.module.css';

export const SubscribeCompanyPurchaseRequestToast = (props) => {
  const t = useTranslate();

  const FirstPart = () => (
    <div className={styles.section}>
      <Icon
        className={cn(styles.icon, styles.successIcon)}
        iconName="select"
        width={18}
        height={18}
      />
      <Typography variant="body1Reg">
        {t('modules.notifications.subscribeCompanyPurchaseRequest.mainText')}
      </Typography>
    </div>
  );

  const SecondPart = () => (
    <div className={styles.section}>
      <Icon
        className={cn(styles.icon, styles.notificationOffIcon)}
        iconName="bell-off"
        width={18}
        height={18}
      />
      <Typography variant="body1Reg">
        <Trans
          i18nKey="modules.notifications.subscribeCompanyPurchaseRequest.secondaryText"
          components={{
            text: <Typography variant="body1Reg" />,
            settings: (
              <NavLink className={styles.link} to="/settings/notifications" />
            )
          }}
        />
      </Typography>
    </div>
  );

  return (
    <DoubleToast
      first={<FirstPart />}
      second={props.data.notificationOff ? <SecondPart /> : null}
    />
  );
};
