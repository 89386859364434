import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation as translate } from 'react-i18next';

const Title = ({ t, value, onChangeTitle }) => (
  <div className="cw-create-topic__title">
    <input
      value={value}
      onChange={(e) => onChangeTitle((e && e.target && e.target.value) || '')}
      placeholder={t('chat-widget.create-topic.type-title-placeholder')}
      className="cw-create-topic__title_title-input"
      type="text"
    />
  </div>
);

Title.propTypes = {
  t: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onChangeTitle: PropTypes.func.isRequired
};

export default translate()(Title);
