import React, { useCallback, useRef, useState } from 'react';
import { Button, Modal } from '@link/react-components';
import { useActions, useAsyncFn, useTranslate } from '@hooks';
import { adminsService } from '@api';
import * as messageActions from '@root/action-creators/message';
import { isValidFormState, quickRegistrationMapper } from './lib';
import styles from './ModalQuickRegistration.module.css';
import { MODAL_KEY } from './constants';
import { useQuickRegistrationModal } from './useQuickRegistrationModal';
import { Form } from './components/Form/Form';

const ModalQuickRegistration = () => {
  const t = useTranslate();
  const [, hideModal] = useQuickRegistrationModal();
  const { setSuccessMessage } = useActions(messageActions);

  const [formState, setFormState] = useState({});
  const formStateRef = useRef(null);
  const [{ loading }, quickRegister] = useAsyncFn(async () => {
    const companyInfo = quickRegistrationMapper.makeCompanyInfo(
      formStateRef.current
    );
    const userInfo = quickRegistrationMapper.makeUserInfo(formStateRef.current);

    try {
      await adminsService.inviteCompany({
        userInfo,
        companyInfo
      });
      setSuccessMessage({
        key: t('modules.quickRegistration.modal.successSubmit', {
          companyName: companyInfo.shortName.replaceAll(/"/g, '')
        })
      });
    } catch (error) {
      console.log('error: ', error);
    } finally {
      hideModal();
    }
  }, [hideModal, setSuccessMessage]);

  const onChangeForm = useCallback((form) => {
    setFormState(form);
    formStateRef.current = form;
  }, []);

  const translate = (key) => t(`modules.quickRegistration.modal.${key}`);

  return (
    <Modal open onClose={hideModal} className={styles.container}>
      <Modal.Header>{translate('title')}</Modal.Header>
      <Modal.Content>
        <Form onChangeForm={onChangeForm} />
      </Modal.Content>
      <Modal.Footer align="right">
        <Modal.Actions>
          <Button onClick={hideModal} mode="text">
            {translate('cancel')}
          </Button>
          <Button
            disabled={!isValidFormState(formState)}
            loading={loading}
            onClick={quickRegister}>
            {translate('submit')}
          </Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export const initModalQuickRegistration = {
  [MODAL_KEY]: ModalQuickRegistration
};
