import request from '../lib/request';

class PurchaseResponseService {
  #baseUrl = '/api/purchase-responses';

  /**
   *
   * @param {number} requestId - id заявки
   * @param {Array.<{ id: number, products: number[] }>} responses - массив откликов содержащий id откликов и поле products со всеми id выбранных продуктов
   * @return {Promise<void>}
   */
  async sentBulkInvoiceRequest(requestId, responses) {
    return request.patch(`${this.#baseUrl}/request-invoice`, {
      requestId,
      responses
    });
  }

  async sentInvoiceRequest(responseId, selectedProductsIndexes = []) {
    return request.patch(`${this.#baseUrl}/${responseId}/request-invoice`, {
      selectedProductsIndexes
    });
  }

  async sentAttachedInvoice(responseId, invoiceId) {
    return request.patch(`${this.#baseUrl}/${responseId}/attach-invoice`, {
      invoiceId
    });
  }

  async getApprovalComments(responseId) {
    return request.get(`${this.#baseUrl}/${responseId}/comments`);
  }

  async submitForReview(responseId, routeId) {
    return request.post(
      `${this.#baseUrl}/${responseId}/confirmation/start/${routeId}`
    );
  }

  async resubmitForReview(responseId) {
    return request.patch(`${this.#baseUrl}/${responseId}/confirmation/restart`);
  }

  async getConfirmationComments(confirmationId) {
    return request.get(
      `${this.#baseUrl}/confirmation/${confirmationId}/comments`
    );
  }

  async resolveAction(responseId, { comment }) {
    return request.patch(
      `${this.#baseUrl}/${responseId}/confirmation/confirm`,
      { comment }
    );
  }

  async rejectAction(responseId, { comment }) {
    return request.patch(`${this.#baseUrl}/${responseId}/confirmation/reject`, {
      comment
    });
  }

  async getResponsesByRequestId(requestId, { statuses } = {}) {
    return request.post(`${this.#baseUrl}/${requestId}`, {
      statuses
    });
  }

  /**
   *
   * @param {number} requestId - id заявки
   * @param {number} responseId - id отклика
   * @param {Array.<{
   *  id: number,
   *  price: number,
   *  count: number,
   *  unit: string,
   *  name: string
   * }>} products - массив продуктов
   * @return {Promise<void>}
   */
  async updateResponse(requestId, responseId, { products } = {}) {
    return request.patch(
      `${this.#baseUrl}/${requestId}/responses/${responseId}`,
      {
        products
      }
    );
  }
}

export const purchaseResponseService = new PurchaseResponseService();
