export const toSubcribeDto = (values) => {
  const toValuesArray = (objectsArray) =>
    objectsArray.map((object) => object.value);

  return {
    categories: values.categories ?? [],
    cities: toValuesArray(values.cities),
    countries: toValuesArray(values.countries),
    customers: toValuesArray(values.customers),
    regions: toValuesArray(values.regions),
    sumFrom: values.sumFrom,
    sumTo: values.sumTo
  };
};
