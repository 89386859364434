import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Trans as Interpolate,
  withTranslation as translate
} from 'react-i18next';
import { Panel, Image } from 'react-bootstrap';
import classNames from 'classnames';
import { isEmpty, isNil } from 'ramda';
import { isType } from 'core/lib';
import { isMsgSystem } from 'core/data/light/message/msg-system';
import { getInterlocutor } from 'core/data/light/dialog';
import { getOwnerMember } from 'core/data/light/mainChannel';
import { withRouter } from '../hoc/withRouter';
import { getTime, getDate } from '../utils/date';
import getMsgSystemText from './Channel/messages/msg-system-text';
import { hexToRgb } from '../lib';

import './dialogs-list.styl';

const getLastMessageDate = (messageDate) => {
  if (isNil(messageDate)) return '';

  const lastMessageDate = new Date(messageDate);
  const today = new Date();
  let date = '';
  if (!lastMessageDate) {
    date = '';
  } else if (getDate(today) === getDate(lastMessageDate)) {
    date = getTime(lastMessageDate);
  } else {
    date = lastMessageDate ? getDate(lastMessageDate) : '';
  }
  return date;
};

const isMatchSearch = (search) => (user) => {
  if (search.trim() === '') return true;

  const searchText = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const reg = new RegExp(searchText, 'i');

  let text = '';
  if (user.get('type') === 'Channel' || user.get('type') === 'Topic') {
    text = user.get('name');
  } else {
    text = `${user.get('firstName')} ${user.get('lastName')} ${user.get(
      'companyName'
    )}`;
  }

  return reg.test(text);
};

const getAdditionalInfo = (t, interlocutor) =>
  `${t('Company')}: ${interlocutor.companyName}`;

const getImgSrc = (interlocutor) => ({
  type: 'Img',
  src:
    isEmpty(interlocutor.avatarSrc) || isNil(interlocutor.avatarSrc)
      ? '/img/user.svg'
      : interlocutor.avatarSrc
});

const getCheckedLists = (user) => user.get('checkedLists');

const getIsInactive = (user) =>
  user.get('isInactive') || user.get('companyDeleted');

const getIsArchived = (dialog) => dialog.get('archived');

const getIsNotificationOff = (dialog) => dialog.get('notificationOff');

const getFileNames = (message) => {
  const { files } = message;

  if (isNil(files)) return '';

  return files.reduce((acc, file) => `${acc}${file.name} `, '');
};

const renderMsg = (t, message, isOwnMsg, type, dialog = {}) => {
  let text = '';

  if (isType('MsgUser', message)) {
    const userName = isOwnMsg ? t('You') : `${message.userName}:`;
    text = `${userName} ${message.msg} ${getFileNames(message)}`;
  } else if (isMsgSystem(message)) {
    text = getMsgSystemText(t, type, message);
  } else if (isType('MsgSupport', message)) {
    const interlocutor = getInterlocutor(message.employeeId, dialog);
    if (message.msg === 'greeting') {
      text = t('support_greeting_message', { userName: interlocutor.userName });
    }
    if (message.msg === 'answerphone') {
      text = t('support_back_message', { userName: interlocutor.userName });
    }
  }

  return <span className="dialogs-list-last-message">{text}</span>;
};

const transformOneDialog = (t, currentUser, from, dialog) => {
  const interlocutor = getInterlocutor(
    currentUser.get('employeeId'),
    dialog.toJS()
  );

  const lastMessageId = dialog.getIn(['lastMessage', 'id']);
  const lastMessageEmId = dialog.getIn(['lastMessage', 'employeeId']);
  const index = dialog.get('unreadMessagesId').indexOf(lastMessageId);
  const isOwnMsg = lastMessageEmId === currentUser.get('employeeId');
  const haveOwnerUnreadMsg = index > -1;

  return {
    type: 'dialog',
    id: dialog.get('id'),
    linkParams: { pathname: `/dialogs/${dialog.get('id')}` },
    uniqKey: dialog.get('id'),
    avatar: getImgSrc(interlocutor),
    name: interlocutor.userName,
    time: getLastMessageDate(dialog.getIn(['lastMessage', 'time'])),
    haveOwnerUnreadMsg: haveOwnerUnreadMsg && isOwnMsg,
    haveNewMsgs: dialog.get('haveNewMessages'),
    message: dialog.get('lastMessage') ? (
      renderMsg(
        t,
        dialog.get('lastMessage').toJS(),
        isOwnMsg,
        'channel',
        dialog.toJS()
      )
    ) : (
      <span className="dialog-list-new-message">{t('write_new_message')}</span>
    ),
    extraInfo: getAdditionalInfo(t, interlocutor),
    checkedLists: getCheckedLists(dialog),
    isInactive: getIsInactive(dialog),
    archived: getIsArchived(dialog),
    notificationOff: getIsNotificationOff(dialog)
  };
};

const transformOneChannel = (t, currentUser, from, dialog) => {
  const lastMessageEmId = dialog.getIn(['lastMessage', 'employeeId']);
  const isOwnMsg = lastMessageEmId === currentUser.get('employeeId');

  return {
    id: dialog.get('id'),
    linkParams: { pathname: `/channels/${dialog.get('id')}` },
    uniqKey: dialog.get('id'),
    avatar: {
      type: 'ColorChar',
      color: dialog.get('color'),
      char: dialog.get('name').trim().charAt(0).toUpperCase()
    },
    name: dialog.get('name'),
    time: getLastMessageDate(dialog.getIn(['lastMessage', 'time'])),
    message: dialog.get('lastMessage') ? (
      renderMsg(t, dialog.get('lastMessage').toJS(), isOwnMsg, 'channel')
    ) : (
      <span className="dialog-list-new-message">{t('write_new_message')}</span>
    ),
    checkedLists: getCheckedLists(dialog),
    type: 'channel',
    extraInfo: '',
    archived: getIsArchived(dialog)
  };
};

const Label = ({ color, text }) => (
  <div
    className="dialogs-list-color-label"
    style={{ backgroundColor: `rgba(${hexToRgb(color).join(',')}, 0.3)` }}>
    {text}
  </div>
);

const transformOneTopic = (t, currentUser, from, dialog) => {
  const lastMessageId = dialog.getIn(['lastMessage', 'id']);
  const lastMessageEmId = dialog.getIn(['lastMessage', 'employeeId']);
  const index = dialog.get('unreadMessagesId').indexOf(lastMessageId);
  const isOwnMsg = lastMessageEmId === currentUser.get('employeeId');
  const haveOwnerUnreadMsg = index > -1;
  const creator = getOwnerMember(dialog.toJS());

  return {
    id: dialog.get('id'),
    linkParams: { pathname: `/topics/${dialog.get('id')}` },
    uniqKey: dialog.get('id'),
    avatar: {
      type: 'ColorChar',
      color: dialog.get('color'),
      char: dialog.get('name').trim().charAt(0).toUpperCase()
    },
    name: (
      <div className="dialogs-list-name-block-extra">
        <Label color={dialog.get('color')} text={t('topic.label')} />
        <div className="dialogs-list-name-extra">{dialog.get('name')}</div>
      </div>
    ),
    time: getLastMessageDate(dialog.getIn(['lastMessage', 'time'])),
    haveOwnerUnreadMsg: haveOwnerUnreadMsg && isOwnMsg,
    haveNewMsgs: dialog.get('haveNewMessages'),
    message: dialog.get('lastMessage') ? (
      renderMsg(t, dialog.get('lastMessage').toJS(), isOwnMsg, 'topic')
    ) : (
      <span className="dialog-list-new-message">{t('write_new_message')}</span>
    ),
    checkedLists: getCheckedLists(dialog),
    type: 'channel',
    extraInfo: (
      <div className="dialog-list-extra-info">
        <div className="dialog-list-extra-info-row">{`${t('creator')}: ${
          creator.userName
        }`}</div>
        <div className="dialog-list-extra-info-row">
          {`${t('date_of_creation')}: ${getDate(
            new Date(dialog.get('createdAt'))
          )}`}
        </div>
      </div>
    ),
    archived: getIsArchived(dialog)
  };
};

const transformDialogs = (t, currentUser, from) => (dialog) => {
  switch (dialog.get('type')) {
    case 'Dialog':
      return transformOneDialog(t, currentUser, from, dialog);
    case 'Channel':
      return transformOneChannel(t, currentUser, from, dialog);
    case 'Topic':
      return transformOneTopic(t, currentUser, from, dialog);
    default:
      return null;
  }
};

const ColorChar = ({ color, char }) => (
  <div className="dialogs-list-color-char" style={{ backgroundColor: color }}>
    {char}
  </div>
);

const LinkComponent = ({ to, text, onClick }) => (
  <Link to={to} onClick={onClick}>
    {text}
  </Link>
);

ColorChar.propTypes = {
  color: PropTypes.string.isRequired,
  char: PropTypes.string.isRequired
};

const SingleForwardDialogCard = ({
  t,
  currentUser,
  forwardMessage,
  from,
  users,
  search,
  supportUser
}) => {
  if (users.length === 0) {
    return (
      <div>
        <p className="text-description">
          <Interpolate
            i18nKey="empty_chat_dialog"
            components={{
              employees: (
                <LinkComponent
                  to="/company/employees"
                  text={t('toEmployees')}
                />
              ),
              customers: (
                <LinkComponent to="/customers/list" text={t('toCustomers')} />
              ),
              suppliers: (
                <LinkComponent to="/suppliers/list" text={t('toSuppliers')} />
              ),
              feedback: (
                <LinkComponent
                  to={`/chat/${supportUser}`}
                  text={t('byFeedback')}
                />
              ),
              help: <LinkComponent to="/help" text={t('toHelp')} />
            }}
          />
        </p>
      </div>
    );
  }

  const dialogListVM = users
    .filter((dialog) => {
      if (dialog.get('type') === 'Dialog') return true;
      if (dialog.get('type') === 'Channel') return true;
      if (dialog.get('type') === 'Topic') return true;
      return false;
    })
    .filter(isMatchSearch(search))
    .map(transformDialogs(t, currentUser, from));

  if (search.trim() === '' && dialogListVM.size === 0) {
    return <div className="text-description">{t('dialogs_not_found')}</div>;
  }

  if (search.trim() !== '' && dialogListVM.size === 0) {
    return <div className="text-description">{t('empty_chat_dialogs')}</div>;
  }

  return (
    <div>
      {dialogListVM.map((viewModel) => {
        const { id, type, linkParams, uniqKey, avatar, name, time, message } =
          viewModel;

        return (
          <Panel
            data-id={uniqKey}
            key={uniqKey}
            eventKey={uniqKey}
            className="dialog-panel"
            onClick={() => forwardMessage({ id, linkParams }, type)}>
            <div className="dialogs-list-info">
              <div className="dialogs-list-row">
                {avatar.type === 'Img' ? (
                  <Image
                    name="userImage"
                    src={avatar.src}
                    className="dialogs-list-img"
                    rounded
                  />
                ) : (
                  <ColorChar color={avatar.color} char={avatar.char} />
                )}
                <span className={classNames('dialog-message')} />
                <div className="dialogs-list-left">
                  <div style={{ display: 'flex' }}>
                    <span className="dialogs-list-name">{name}</span>
                    <span className="dialogs-list-time">{time}</span>
                  </div>
                  <span className={classNames('dialogs-list-last-message')}>
                    {message}
                  </span>
                </div>
              </div>
            </div>
          </Panel>
        );
      })}
    </div>
  );
};

SingleForwardDialogCard.propTypes = {
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  from: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  users: PropTypes.object.isRequired,
  supportUser: PropTypes.string.isRequired
};

export default withRouter(
  translate(['ui'], { wait: true })(SingleForwardDialogCard)
);
