import React from 'react';

export function Mic({ micMuted }) {
  return (
    <img
      src={micMuted ? '/img/videochat/muted.svg' : '/img/videochat/unmuted.svg'}
      className="video-chat__mic"
      alt=""
    />
  );
}
