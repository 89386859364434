.interactive {
  cursor: pointer;
}

.interactive:hover {
  background-color: var(--color-gray-300);
}

.interactive:active {
  background-color: var(--color-gray-400);
}

.small {
  width: 10px;
  height: 10px;
}

.medium {
  width: 14px;
  height: 14px;
}

.rating {
  display: flex;
  align-items: center;
  gap: var(--indent-xxxs);
  padding: var(--indent-xxxs) var(--indent-xxs);
  border-radius: var(--indent-xxs);
  width: fit-content;
}

.score {
  color: var(--color-gray-800);
}
