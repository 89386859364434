export const initialState = {
  lastName: {
    value: '',
    errorReason: null
  },
  firstName: {
    value: '',
    errorReason: null
  },
  middleName: {
    value: '',
    errorReason: null
  },
  company: {
    value: {},
    errorReason: null
  },
  email: {
    value: '',
    errorReason: null
  }
};
export function createMethods(state) {
  return {
    changeEmail({ value, errorReason }) {
      return { ...state, email: { value, errorReason } };
    },
    changeCompany({ value, errorReason }) {
      return { ...state, company: { value, errorReason } };
    },

    changeFirstName({ value, errorReason }) {
      return {
        ...state,
        firstName: { value, errorReason }
      };
    },

    changeLastName({ value, errorReason }) {
      return {
        ...state,
        lastName: { value, errorReason }
      };
    },

    changeMiddleName({ value, errorReason }) {
      return {
        ...state,
        middleName: { value, errorReason }
      };
    }
  };
}
