import { validateEmail } from '@root/utils/validateField';
import { isEmpty } from 'ramda';

const makeUserInfo = (formState) => ({
  firstName: formState.firstName.value,
  lastName: formState.lastName.value,
  middleName: formState.middleName.value,
  email: formState.email.value
});

const makeCompanyInfo = (formState) => ({
  inn: formState.company.value?.inn,
  kpp: formState.company.value?.kpp,
  ogrn: formState.company.value?.ogrn,
  fullName: formState.company.value?.fullName,
  shortName: formState.company.value?.shortName,
  legalAddress: formState.company.value?.legalAddress
});

export const quickRegistrationMapper = {
  makeCompanyInfo,
  makeUserInfo
};

export const isValidFormState = (formState) => {
  if (isEmpty(formState)) return false;

  const { firstName, lastName, email, company } = formState;
  if (
    !firstName.value ||
    !lastName.value ||
    !validateEmail(email.value) ||
    isEmpty(company.value)
  )
    return false;

  return !Object.values(formState).some((formField) => formField.errorReason);
};
