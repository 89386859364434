import { initCommand, initEvent, handle } from 'core/data/messageBus/frontend';
import { makeQuery } from 'core/messages/utils';
import { makeCommand, makeEvent } from 'core/data/messageBus/message';
import { emitToServer } from '../../storage/message';

const NS = 'VIDEO_ROOM';
const initCMD = initCommand(NS);
const initEvt = initEvent(NS);

export const joinInCMD = initCMD('JOIN_IN');
export const createVideoRoomCMD = initCMD('CREATE_VIDEO_ROOM');
export const videoRoomWasCreatedEvt = initEvt('VIDEO_ROOM_WAS_CREATED');
export const videoIconClickedEVT = initEvt('VIDEO_ICON_WAS_CLICKED');

const createdRoom = makeEvent(NS, 'ROOM_WAS_CREATED');

const allowJoinCMD = makeCommand(NS, 'ALLOW_JOIN');

const rejectJoinRequest = makeCommand(NS, 'REJECT_REQUEST_JOIN_TO_ROOM');
export const rejectedJoinRequest = makeEvent(
  NS,
  'REJECTED_REQUEST_JOIN_TO_ROOM'
);

const joinRequestCMD = makeCommand(NS, 'REQUEST_JOIN_TO_ROOM');

const joinedToRoom = makeEvent(NS, 'JOINED_TO_ROOM');

const leaveRoom = makeCommand(NS, 'LEAVE_ROOM');
const leftRoom = makeEvent(NS, 'LEFT_ROOM');

export const createdRoomReaction = (cb) => handle(createdRoom, cb);

export const sendJoinRequest = (roomId) =>
  emitToServer(joinRequestCMD, { roomId });
export const incomingJoinRequestReaction = (cb) => handle(joinRequestCMD, cb);

export const sendAllowToJoin = ({ roomId, employeeId, socketId }) =>
  emitToServer(allowJoinCMD, {
    roomId,
    memberId: employeeId,
    memberSocketId: socketId
  });

export const sendRejectJoinRequest = ({ roomId, employeeId, socketId }) =>
  emitToServer(rejectJoinRequest, {
    roomId,
    memberId: employeeId,
    memberSocketId: socketId
  });
export const rejectedJoinReaction = (cb) => handle(rejectedJoinRequest, cb);

export const sendJoinedToRoom = ({ roomId, employeeId, peerId }) =>
  emitToServer(joinedToRoom, { roomId, employeeId, peerId });
export const joinedToRoomReaction = (cb) => handle(joinedToRoom, cb);

export const sendLeaveRoom = (roomId) => emitToServer(leaveRoom, { roomId });
export const leftRoomReaction = (cb) => handle(leftRoom, cb);

export const videoCallQRY = makeQuery(NS, 'video-call');
