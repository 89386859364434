import React from 'react';
import { withTranslation as translate } from 'react-i18next';
import './style.css';

const CWMBtnLoadMore = ({
  t,
  tkey = 'chat-widget.section.chat.load-more',
  onClick,
  isShow = false
}) => {
  if (!isShow) return null;

  return (
    <div className="cwm-btn-load-more">
      <div className="cwm-btn-load-more__text" onClick={() => onClick()}>
        {t(tkey)}
      </div>
    </div>
  );
};

export default translate()(CWMBtnLoadMore);
