import request from '../lib/request';

class ResponseService {
  #baseUrl = '/api/requests';

  async getResponse(responseId) {
    return request.get(`${this.#baseUrl}/responses/${responseId}`);
  }
}

export const responseService = new ResponseService();
