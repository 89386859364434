import { matchPath } from 'react-router';
import { List } from 'immutable';

export const getCurrentPath = () => window.location.pathname;

export const isCurrentPath = (pathToCheck, currentPath = getCurrentPath()) =>
  !!matchPath({ path: pathToCheck }, currentPath);

export const isExternalUrl = (url) => /^http/i.test(url);

export const trimmer = (string, length = 15) =>
  length < string.length ? `${string.slice(0, length - 1)}...` : string;

export const capitalize = (text) =>
  text.charAt(0).toUpperCase() + text.slice(1);

const list = {
  'published-prices': 'prices',
  'orders-customers': 'customerOrders',
  'prices-suppliers': 'supplierOrders',
  'orders-company': 'supplierOrders'
};

export const pathnameToArray = (pathname) => pathname.split('/').slice(1);

export const getPermissionNameByPathname = (pathname = getCurrentPath()) => {
  const pathnameArray = pathnameToArray(pathname);
  const firstWorkPathname = pathnameArray[0];
  if (list[firstWorkPathname]) {
    return list[firstWorkPathname];
  }
  return firstWorkPathname;
};

export const getPermissionsByCurrentSection = () =>
  getPermissionNameByPathname(window.location.pathname);

export const numberWithSpaces = (number = 0) => {
  const parts = number ? number.toString().split('.') : ['0'];
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return parts.join('.');
};

export const numberAsPrice = (number) => Number(number).toFixed(2);

export const roundUpCost = (cost, roundUp = null) => {
  if (!cost) return cost;

  const c = +cost;

  if (!roundUp) return +c.toFixed(2);

  if (![1, 10, 100].includes(roundUp)) return cost;

  return Math.round(c / roundUp) * roundUp;
};

const getShortUnit = (unit, short = false) => {
  if (short) {
    return `${unit.slice(0, 12).trim()}${unit.length > 12 ? '...' : ''}`;
  }
  return unit;
};

const getUnitName = (unit, unitCodes, user, shortName = false) => {
  if (!unit.code) {
    return getShortUnit(unit.name, shortName);
  }
  const baseUnit =
    unitCodes && unitCodes.find((i) => +i.get('code') === +unit.code);
  if (!baseUnit) {
    return getShortUnit(unit.name, shortName);
  }
  return user.get('language') === 'ru'
    ? baseUnit.getIn(['condDesign', 'nat'])
    : baseUnit.getIn(['condDesign', 'int']) ||
        baseUnit.getIn(['codeDesign', 'int']);
};

export const getProductUnits = ({
  t,
  catalog,
  units = List(),
  unitCodes,
  user,
  shortLabels = false
}) => {
  const catalogUnits = catalog ? catalog.get('units') || List() : units;
  const unitsValue = catalogUnits
    .filter((u) => u.get('active') && !u.get('deleted'))
    .map((unit) => ({
      value: unit.get('id') || `${+unit.get('code')}`,
      label: getUnitName(unit.toJS(), unitCodes, user, shortLabels)
    }));

  const resultUnits = unitsValue && unitsValue.toJS();
  resultUnits.unshift({ value: null, label: t('not selected') });
  return resultUnits;
};

export const getPublishedUnitName = (
  unit,
  units = List(),
  unitCodes = List(),
  lang = 'ru',
  fullName = false
) => {
  if (!unit) return null;

  if (`${unit}`.length <= 3 && !isNaN(+unit)) {
    const baseUnit =
      unitCodes && unitCodes.find((i) => +i.get('code') === +unit);
    if (!baseUnit) return null;
    return lang === 'ru'
      ? baseUnit.getIn(['condDesign', 'nat'])
      : baseUnit.getIn(['condDesign', 'int']) ||
          baseUnit.getIn(['codeDesign', 'int']);
  }
  const catalogUnit = units.find((i) => i.get('id') === unit);
  if (!catalogUnit) return null;

  const unitName = catalogUnit.get('name');
  if (fullName) {
    return unitName;
  }
  return `${unitName.slice(0, 9).trim()}${unitName.length > 9 ? '...' : ''}`;
};

export const sortFunc = (prop, a, b) => {
  const aValue = Number.isNaN(+a.get(prop)) ? a.get(prop) : +a.get(prop);
  const bValue = Number.isNaN(+b.get(prop)) ? b.get(prop) : +b.get(prop);
  if (aValue < bValue) return -1;
  if (aValue > bValue) return 1;
  return 0;
};

export const formatFileSize = (size) => {
  if (size >= 1024 * 1024) {
    return `${(size / (1024 * 1024)).toFixed(1)}Mb`;
  }
  return `${(size / 1024).toFixed(1)}Kb`;
};

export const round = (value, precision = 2) =>
  Math.ceil(value * 10 ** precision) / 10 ** precision;
