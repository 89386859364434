import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/mention/lib/plugin.css';

import 'emoji-mart/css/emoji-mart.css';
import './style.css';
import { RichUtils } from 'draft-js';
import { useActions } from '@hooks/useActions';
import { initPlugins } from './editor/plugins';
import { getCountSymbols, isEditMode } from './functions';
import AttachFileMenu from './attachFileMenu';
import { useCallOnceWhenMetaKeyIsPressed } from './editor/hooks/callOnceWhenMetaKeyIsPressed';
import EditorInputFiles from './editorInputFiles';
import { EditorButtons } from './editorButtons/EditorButtons';
import styles from './View.module.css';
import { Counter } from './counter';
import { actions } from '../../modules/delayedMessages/reducer';
import { STYLE_BTN_TYPES } from './constants';
import { DropButtonList } from './textTooling/TextTooling';
// NOTE: If need to open profile by click on user
// https://github.com/draft-js-plugins/draft-js-plugins/blob/master/packages/mention/src/Mention.tsx#L27-L42
// https://github.com/Darex1991/draft-js-with-an-option-for-delete-mentions/blob/master/src/mention.jsx#L75-L85
// const mentionComp = (onClick = () => {}) => ({ children, className, mention }) => (
//   <span className={className} spellCheck={false} data-testid="mentionText" onClick={e => onClick(e, mention)}>
//     {children}
//   </span>
// );

const View = React.forwardRef(
  (
    {
      editorId,
      text,
      files,
      isDisableAttach,
      placeholder,
      mentions,
      rndConfig,
      onAttachLocalFile,
      onAttachStorageFile,
      onDeleteFile,
      theme,
      mode,
      editorState,
      onChangeES,
      handleKeyCommand,
      keyBindingFn,
      handlePastedText,
      handlePastedFiles,
      isSendDisable,
      onButtonSendClick,
      onClickByWrapper,
      addMention,
      maxMsgLength,
      maxFilesLength
    },
    ref
  ) => {
    const wrapperRef = useRef();
    useCallOnceWhenMetaKeyIsPressed(wrapperRef);
    const [isFormattedOpen, setIsFormattedOpen] = useState(false);
    const { clearPlannedDate } = useActions(actions);
    useEffect(
      () => () => {
        setIsFormattedOpen(false);
        clearPlannedDate();
      },
      [editorId]
    );

    const { MentionSuggestions, EmojiSuggestions, EmojiPicker, plugins } =
      useMemo(initPlugins, []);

    const onStyleButtonClick = useCallback(
      ({ type, style, event }) => {
        event.stopPropagation();
        switch (type) {
          case STYLE_BTN_TYPES.INLINE:
            onChangeES(RichUtils.toggleInlineStyle(editorState, style));
            onClickByWrapper();
            return;
          case STYLE_BTN_TYPES.BLOCK:
            onChangeES(RichUtils.toggleBlockType(editorState, style));
            return;
          case STYLE_BTN_TYPES.MENTIONED:
            addMention(event);
            return;
          default:
            throw new Error('Unknown handler');
        }
      },
      [editorState, onChangeES, onChangeES]
    );

    return (
      <div className={styles.wrapper}>
        <AttachFileMenu
          files={files}
          maxLength={maxFilesLength}
          onAttachLocal={onAttachLocalFile}
          onAttachStorage={onAttachStorageFile}
          editMode={isEditMode(mode)}
          isDisableAttach={isDisableAttach}
        />
        <EditorInputFiles
          EmojiSuggestions={EmojiSuggestions}
          editorState={editorState}
          rndConfig={rndConfig}
          files={files}
          editorId={editorId}
          mentions={mentions}
          ref={ref}
          placeholder={placeholder}
          handleKeyCommand={handleKeyCommand}
          keyBindingFn={keyBindingFn}
          handlePastedText={handlePastedText}
          handlePastedFiles={handlePastedFiles}
          onChangeES={onChangeES}
          onDeleteFile={onDeleteFile}
          theme={theme}
          plugins={plugins}
          wrapperRef={wrapperRef}
          MentionSuggestions={MentionSuggestions}
          onClickByWrapper={onClickByWrapper}
          onStyleButtonClick={onStyleButtonClick}
          text={text}
        />
        <EditorButtons
          setIsFormattedOpen={setIsFormattedOpen}
          mode={mode}
          EmojiPicker={EmojiPicker}
          isSendDisable={isSendDisable}
          onButtonSendClick={onButtonSendClick}
          isFormattedOpen={isFormattedOpen}
        />
        {isFormattedOpen && (
          <DropButtonList
            onStyleButtonClick={onStyleButtonClick}
            editorState={editorState}
          />
        )}
        <Counter amount={getCountSymbols(text)} limit={maxMsgLength} />
      </div>
    );
  }
);

export default View;
