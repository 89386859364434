import { useMemo } from 'react';
import { pipe } from 'ramda';

function customBindActionCreator(dispatch, actions) {
  return Object.entries(actions).reduce(
    (acc, [key, fn]) => ({ ...acc, [key]: pipe(fn, dispatch) }),
    {}
  );
}

/**
 * Bind actions to react useReducer.
 * @param {function} dispatch - second param from useReducer
 * @param {Object} actions - object with functions with binded type
 *
 */

export const useActionsReducer = (dispatch, actions) =>
  useMemo(() => customBindActionCreator(dispatch, actions), []);
