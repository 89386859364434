import request from '../lib/request';
import { SaveDraftPurchaseRequestDto, QuickResponseDto } from '../DTOs';
import { searchQuery } from '../utils/searchQuery';

export const IncludesOptionKeys = Object.freeze({
  hints: 'hints'
});

class PurchaseRequestsService {
  #baseUrl = '/api/requests';

  async convertProductsToJson(requestId, file) {
    return request.sendFile(
      `${this.#baseUrl}/${requestId}/table-products/convert`,
      file
    );
  }

  async exportRequestToExcel(requestId) {
    return request.post(`${this.#baseUrl}/${requestId}/export-to-excel`);
  }

  async extendResponseEndDate({ requestId, responseEndDate }) {
    return request.patch(
      `${this.#baseUrl}/${requestId}/extend/responseEndDate`,
      {
        responseEndDate
      }
    );
  }

  async saveDraft(payload) {
    const saveDraftDto = new SaveDraftPurchaseRequestDto(payload);

    return request.post(`${this.#baseUrl}/save`, saveDraftDto);
  }

  async quickResponse(payload) {
    const quickResponseDto = new QuickResponseDto(payload);

    return request.post(`${this.#baseUrl}/quick-response`, quickResponseDto);
  }

  async sendToEmails({ emails, requestId }) {
    return request.post(`${this.#baseUrl}/${requestId}/email/`, { emails });
  }

  async getAuditLog(requestId, { offset = 0, limit = 20 } = {}) {
    const query = searchQuery.generate({ offset, limit });
    return request.get(`${this.#baseUrl}/${requestId}/audit${query}`);
  }

  async getRequestUniqueNumber(requestId) {
    return request.get(`${this.#baseUrl}/${requestId}/unique-number`);
  }

  async getRequest(requestId, { token, includes } = {}) {
    const query = searchQuery.generate({ token });

    return request.post(`${this.#baseUrl}/one/${requestId}${query}`, {
      includes
    });
  }

  /**
   Fetch all requests
   @param {object} params
   @param {string} params.search - Строка для поиска
   @param {number} params.limit - Максимальное кол-во записей
   @param {number} params.offset
   @param {string} params.order - Вариант сортировки "default"|"lastPublishedDate"
   @param {string} params.direction - Направление сортировки ASC|DESC
   @param {[number]} params.selectedCategoriesId - Фильтрация по категориям
   @param {boolean} params.initialRequest
   @param {[string]} params.showMode - Выбор варианта отображения (если выбрано "bookmark" - фильтрация по избранным заявкам)
   @param {[string]} params.filters - Массив userId ответственных за заявку
   @returns {Promise<Array>}
   */
  async getAllRequests({
    limit = 20,
    offset = 0,
    search = '',
    order = '',
    direction = 'DESC',
    selectedCategoriesId = [],
    showMode = [],
    filters = [],
    lang
  }) {
    const query = searchQuery.generate({
      limit,
      offset,
      search,
      orderBy: order,
      direction,
      selCatsId: selectedCategoriesId,
      showMode,
      lang
    });

    return request.post(`${this.#baseUrl}/all${query}`, { filters });
  }

  /**
   Fetch my requests
   @param {object} params
   @param {string} params.search - Строка для поиска
   @param {number} params.limit - Максимальное кол-во записей
   @param {number} params.offset
   @param {string} params.order
   @param {string} params.direction - Направление сортировки ASC|DESC
   @param {Array} params.selectedCategoriesId - Фильтрация по категориям
   @param {Array} params.selectedStatus
   @param {boolean} params.isArchived
   @param {object} params.filterBy
   @param {[number]} params.filterBy.responsibleUsers - Массив userId ответственных за заявку
   @returns {Promise<Array>}
   */
  async getMyRequests({
    search = '',
    limit = 20,
    offset = 0,
    order,
    direction,
    selectedCategoriesId = [],
    selectedStatus = [],
    selectedResponseStatus = [],
    isArchived = false,
    filterBy = {
      responsibleUsers: []
    }
  } = {}) {
    const query = searchQuery.generate({
      search,
      limit,
      offset,
      orderBy: order,
      direction,
      selCatsId: selectedCategoriesId
    });

    const abortController = new AbortController();

    const cancel = () => abortController.abort();

    const fetchFn = async () =>
      request.post(
        `${this.#baseUrl}/my${query}`,
        {
          selectedStatus,
          selectedResponseStatus,
          isArchived,
          filterBy
        },
        { signal: abortController.signal }
      );

    return [fetchFn, cancel];
  }

  async getAssignedAsResponsible(companyId) {
    return request.get(`${this.#baseUrl}/${companyId}/assigned-as-responsible`);
  }

  async archiveRequest(requestId, isArchived) {
    return request.post(`${this.#baseUrl}/${requestId}/archive`, {
      isArchived
    });
  }

  async view(requestId) {
    return request.patch(`${this.#baseUrl}/${requestId}/view`);
  }
}

export const purchaseRequestService = new PurchaseRequestsService();
