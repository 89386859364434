const sections = [
  'company',
  'employees',
  'my-purchase-requests',
  'my-purchase-responses',
  'routes',
  'companies',
  'administration'
];

export const sectionsPositions = sections.reduce((map, section, index) => {
  map[section] = index;

  return map;
}, {});
