import React from 'react';
import { connect } from 'react-redux';
import { Button, ControlLabel, Form, FormGroup } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { withTranslation as translate } from 'react-i18next';
import { reduxForm, Field } from 'redux-form/immutable';

import { Link } from 'react-router-dom';
import Icon from 'react-components/src/icons';
import iconsViews from 'react-components/src/icons/constants';
import { withRouter } from '../../hoc/withRouter';
import SecurityBoxInput from '../../components/MessengerAuth/SecurityBoxInput';
import SecurityBoxCommonError from '../../components/MessengerAuth/SecurityBoxCommonError';
import Loading from '../../components/MessengerAuth/Loading';
import { syncValidation } from '../../utils/loginValidator';
import * as user from '../../action-creators/user';
import * as service from '../../action-creators/services';
import Checkbox from '../../components/Checkbox';

import '../../components/MessengerAuth/style.styl';
import './style.css';
import RoundSmallButton from '../../components/Buttons/RoundSmallButton';
import { convertSearchParamsToObj } from '../../utils/utils';
import { InfoBlock } from '../../components/InfoBlock';

const INFO_ERRORS = ['Company blocked', 'User blocked'];

const MessengerAuth = translate(['ui'], { wait: true })(
  class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: false,
        enableRememberMe: false,
        isPasswordShown: false,
        error: ''
      };
      this.onSubmit = this.onSubmit.bind(this);
    }

    UNSAFE_componentWillMount() {
      this.props.getCurrentUser();
      this.initLoginForm();
    }

    get hasInfoError() {
      const { error } = this.state;

      return error.length > 0 && INFO_ERRORS.includes(error);
    }

    get hasGeneralError() {
      const { error } = this.state;

      return error.length > 0 && !INFO_ERRORS.includes(error);
    }

    async onSubmit(options) {
      const { email, password } = options.toJS();
      const {
        login,
        gaSend,
        match: {
          params: { tokenLink, partnerToken }
        },
        location
      } = this.props;
      this.setState({ loading: true });

      const res = await login({
        email: email.trim(),
        password,
        antibotString: name,
        tokenLink,
        partnerToken,
        enableRememberMe: this.state.enableRememberMe
      });
      const data = await res.json();
      if (res.ok) {
        gaSend({ category: 'Common', action: 'login_success', label: email });
        const { redirectUrl } = convertSearchParamsToObj(location.search);
        window.history.replaceState(
          { fromLogin: true },
          '',
          `${redirectUrl ?? '/'}`
        );
        window.location = `${redirectUrl ?? '/'}`;
      } else {
        this.setState({ loading: false, error: data.errors.code });
      }
    }

    toggleCheckbox(checkboxName) {
      return this.setState((prevState) => ({
        [checkboxName]: !prevState[checkboxName]
      }));
    }

    togglePassportView = (type) =>
      this.setState((prevState) => ({
        ...prevState,
        [type]: !this.state[type]
      }));

    initLoginForm() {
      const {
        location: { state },
        initialize
      } = this.props;

      initialize({ email: state?.loginInfo?.email });
    }

    render() {
      const { t, handleSubmit, theme = 'desktop' } = this.props;
      if (this.state.loading) {
        return <Loading theme={theme} text="Login to the system Please wait" />;
      }

      return (
        <div className="auth-wrapper">
          <div className="auth-container">
            <div className="auth-left-side">
              <div className="auth-logo-container">
                <a className="auth-logo" href="https://istock.link/">
                  {' '}
                </a>
              </div>
              <div className="auth-messenger-info">
                <div className="auth-messenger-info-item">
                  <div className="auth-messenger-info-circle">
                    <div className="auth-messenger-info-circle-inner" />
                  </div>
                  {t('marketplace')}
                </div>
                <div className="auth-messenger-info-item">
                  <div className="auth-messenger-info-square" />
                  {t('corporate_messenger')}
                </div>
              </div>
              <img
                className="auth-image"
                src="/img/registration_image.svg"
                alt=""
              />
            </div>
            <div className="auth-right-side">
              <Form onSubmit={handleSubmit(this.onSubmit)}>
                <div className="auth-form-row">
                  <span className="auth-form-title">{t('Login')}</span>
                  <Link className="auth-register-link" to="/registration">
                    {t('Registration')}
                  </Link>
                </div>
                <FormGroup className="auth-form-group">
                  <ControlLabel className="auth-form-label">
                    {t('E-mail')}
                  </ControlLabel>
                  <Field
                    name="email"
                    type="text"
                    component={SecurityBoxInput}
                    placeholder={t('enter email')}
                  />
                </FormGroup>
                {/* anitbot field */}
                <FormGroup className="auth-form-group special-antibot-input">
                  <ControlLabel className="auth-form-label">
                    {t('name')}
                  </ControlLabel>
                  <Field
                    name="name"
                    component={SecurityBoxInput}
                    input={{ tabIndex: '-1' }}
                    type="text"
                    placeholder={t('enter name')}
                  />
                </FormGroup>
                <FormGroup
                  className="auth-form-group"
                  style={{ position: 'relative' }}>
                  <ControlLabel className="auth-form-label">
                    {t('Password')}
                  </ControlLabel>
                  <Field
                    name="password"
                    component={SecurityBoxInput}
                    marginTop="true"
                    type={this.state.isPasswordShown ? 'text' : 'password'}
                    placeholder={t('enter password')}
                  />
                  <div className="form-group-auth-eye">
                    <RoundSmallButton
                      svg={
                        <Icon
                          size={18}
                          icon={
                            this.state.isPasswordShown
                              ? iconsViews.View
                              : iconsViews.Hide
                          }
                        />
                      }
                      onClick={() => this.togglePassportView('isPasswordShown')}
                      tabIndex="-1"
                    />
                  </div>
                </FormGroup>

                <FormGroup controlId="rememberMe" className="auth-form-group">
                  <Checkbox
                    name="enableRememberMe"
                    set={this.state.enableRememberMe}
                    onClick={() => this.toggleCheckbox('enableRememberMe')}
                  />
                  <span>{t('remember-me')}</span>
                </FormGroup>

                {this.hasInfoError && (
                  <InfoBlock
                    className="auth-info-block"
                    text={this.state.error}
                  />
                )}

                <Button
                  className="auth-submit-button"
                  name="loginUserButton"
                  type="submit"
                  bsStyle="primary">
                  {t('login.enter')}
                </Button>

                <div className="link-section">
                  <div className="auth-pass-recovery">
                    <span>{`${t('Forgot password')} `}</span>
                    <a
                      className="auth-pass-recovery-link"
                      href="/reset-password">
                      {t('Password recovery')}
                    </a>
                  </div>

                  {/* Использование компонента Link нецелесообразно, требуется редизайн формы */}
                  <div className="contact-support">
                    <span>{`${t('contact-support')} `}</span>
                    <a
                      className="contact-support-link"
                      href={`mailto:${process.env.SUPPORT_EMAIL}`}>
                      {process.env.SUPPORT_EMAIL}
                    </a>
                  </div>
                </div>

                {this.hasGeneralError && (
                  /* TODO сгруппировать переводы ошибок */
                  <SecurityBoxCommonError error={t(this.state.error)} />
                )}
              </Form>
            </div>
          </div>
        </div>
      );
    }
  }
);

const mapState = () => ({});

const LoginForm = reduxForm({
  form: 'login',
  validate: syncValidation
})(MessengerAuth);

export default withRouter(
  connect(mapState, (dispatch) =>
    bindActionCreators(
      {
        login: user.loginRefactored,
        gaSend: service.gaSend,
        getCurrentUser: user.getCurrentUser
      },
      dispatch
    )
  )(LoginForm)
);
