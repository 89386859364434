import React from 'react';
import Icon from 'react-components/src/icons';
import iconsView from 'react-components/src/icons/constants';
import { ChatButton } from '../new-components/chat-button/ChatButton';
import styles from './TextTooling.module.css';
import { STYLE_BTN_TYPES } from '../constants';

function VerticalLine() {
  return <div className={styles.verticleLine} />;
}

const INLINE_BUTTONS = [
  {
    icon: <Icon icon={iconsView.StyleBold} size={12} />,
    isSmall: false,
    style: 'BOLD'
  },
  {
    icon: <Icon icon={iconsView.StyleItalic} size={12} />,
    isSmall: false,
    style: 'ITALIC'
  },
  {
    icon: <Icon icon={iconsView.StyleUnderline} size={12} />,
    isSmall: false,
    style: 'UNDERLINE'
  }
];

const BLOCK_BUTTONS = [
  {
    icon: <Icon icon={iconsView.StylePunkt1} size={18} />,
    isSmall: true,
    style: 'unordered-list-item'
  },
  {
    icon: <Icon icon={iconsView.StylePunkt2} size={18} />,
    isSmall: true,
    style: 'ordered-list-item'
  }
];

export function DropButtonList({ onStyleButtonClick, editorState }) {
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div className={styles.wrapper}>
      {INLINE_BUTTONS.map((button, index) => (
        <ChatButton
          isActive={currentStyle.has(button.style)}
          key={button.style + index}
          icon={button.icon}
          isSmall={button.isSmall}
          onMouseDown={(e) => {
            e.preventDefault();
            onStyleButtonClick({
              type: STYLE_BTN_TYPES.INLINE,
              style: button.style,
              event: e
            });
          }}
        />
      ))}
      <VerticalLine />
      {BLOCK_BUTTONS.map((button, index) => (
        <ChatButton
          isActive={button.style === blockType}
          key={button.style + index}
          icon={button.icon}
          isSmall={button.isSmall}
          onClick={(e) =>
            onStyleButtonClick({
              type: STYLE_BTN_TYPES.BLOCK,
              style: button.style,
              event: e
            })
          }
        />
      ))}
      <VerticalLine />
      <ChatButton
        isActive={false}
        icon={<Icon icon={iconsView.Email} size={18} />}
        isSmall
        onClick={(e) =>
          onStyleButtonClick({ type: STYLE_BTN_TYPES.MENTIONED, event: e })
        }
      />
    </div>
  );
}
