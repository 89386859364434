import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withTranslation as translate } from 'react-i18next';
import { Modal, Form, Button } from 'react-bootstrap';
import { haveActivatedMS } from 'core/data/light/channelMember';
import Helper from '../../Helper';
import * as modalAction from '../../../action-creators/modal';
import * as temporaryAction from '../../../action-creators/temporary';
import * as channelUC from '../../../useCases/channel';
import Members from '../Members';
import {
  getTempField,
  getAllChannelMembers,
  getContacts
} from '../../../storeGetters';
import * as modalSetsAction from '../../../action-creators/modalSets';

class SimpleModal extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.showModalPickNewMembers = this.showModalPickNewMembers.bind(this);
  }

  onSubmit() {
    const {
      excludeMembers,
      clearTemporary,
      onCloseModal,
      updateChannel,
      inviteMembers,
      membersRoles
    } = this.props;

    updateChannel({
      excludeMembers,
      inviteMembers,
      membersRoles: Object.entries(membersRoles)
    });
    clearTemporary();
    onCloseModal();
  }

  onCancel() {
    const { clearTemporary, onCloseModal } = this.props;
    clearTemporary();
    onCloseModal();
  }

  getMembersUsers() {
    const { members, contacts } = this.props;
    const activatedMembersId = members
      .filter(haveActivatedMS)
      .map((member) => member.employeeId);
    return contacts.filter((user) =>
      activatedMembersId.includes(user.employeeId)
    );
  }

  getNotMembersUsers() {
    const { members, contacts } = this.props;
    const activatedMembersId = members
      .filter(haveActivatedMS)
      .map((member) => member.employeeId);

    return contacts.filter(
      (user) => !activatedMembersId.includes(user.employeeId)
    );
  }

  showModalPickNewMembers() {
    const {
      showModal,
      setInviteMembers,
      setMemberRole,
      editMembersModal,
      inviteMembers,
      membersRoles
    } = this.props;

    showModal('ADD_TO_GROUP_CHAT', {
      submit: (inviteMembersIds, inviteMembersRoles) => {
        setInviteMembers(inviteMembersIds);
        inviteMembersRoles.forEach(([member, role]) =>
          setMemberRole(member, role)
        );
        editMembersModal();
      },
      mode: 'Topic',
      purpose: 'Add',
      resetMembersIds: true,
      resetMembersRoles: true,
      initialMemberIds: inviteMembers,
      initialMemberRoles: Object.entries(membersRoles),
      btnSubmitText: 'Add',
      cancel: editMembersModal,
      members: this.getMembersUsers(),
      dialogs: this.getNotMembersUsers()
    });
  }

  render() {
    const { t, modalTitle, btnTextConfirm, btnTextCancel, readOnly } =
      this.props;

    return (
      <Modal id="simpleSubmit" onHide={this.onCancel} show>
        <div className="modal-container modal-container_type_default modal-container_theme_default">
          <Modal.Header>
            <Modal.Title>
              {readOnly ? t('view_topic_members') : t(modalTitle)}
              <span
                className="modal-header-cross pull-right"
                onClick={this.onCancel}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Members mode="Topic" readOnly={readOnly} />

              {!readOnly && (
                <div className="members__panel">
                  <button
                    type="button"
                    className="btn-add-members"
                    onClick={this.showModalPickNewMembers}>
                    <img
                      className="btn-add-members__img"
                      src="/img/plus_in_circle.svg"
                      role="presentation"
                      alt={t('channel.add-members')}
                    />
                    <div className="btn-add-members__text">
                      {t('channel.add-members')}
                    </div>
                  </button>
                  <div className="members__panel-helper">
                    <Helper text={t('channel.help-add-members')} />
                  </div>
                </div>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            {!readOnly && (
              <>
                <Button
                  id="cancel"
                  className="pull-left"
                  onClick={this.onCancel}>
                  {t(btnTextCancel)}
                </Button>
                <Button id="submit" bsStyle="primary" onClick={this.onSubmit}>
                  {t(btnTextConfirm)}
                </Button>
              </>
            )}
            {readOnly && (
              <Button id="cancel" onClick={this.onCancel}>
                {t('Close')}
              </Button>
            )}
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

SimpleModal.propTypes = {
  t: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
  btnTextConfirm: PropTypes.string,
  btnTextCancel: PropTypes.string,
  updateChannel: PropTypes.func.isRequired,
  setMemberRole: PropTypes.func.isRequired,
  setInviteMembers: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  clearTemporary: PropTypes.func.isRequired,
  editMembersModal: PropTypes.func.isRequired,
  members: PropTypes.array.isRequired,
  contacts: PropTypes.array.isRequired,
  membersRoles: PropTypes.object.isRequired,
  excludeMembers: PropTypes.array.isRequired,
  inviteMembers: PropTypes.array.isRequired,
  readOnly: PropTypes.bool
};

SimpleModal.defaultProps = {
  modalTitle: 'edit_topic_members',
  btnTextConfirm: 'Save',
  btnTextCancel: 'Cancel',
  readOnly: false
};

export default connect(
  (state) => ({
    membersRoles: getTempField(state, 'membersRoles', {}),
    excludeMembers: getTempField(state, 'excludeMembers', []),
    inviteMembers: getTempField(state, 'inviteMembers', []),
    members: getAllChannelMembers(state),
    contacts: getContacts(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updateChannel: channelUC.updateChannel,
        showModal: modalAction.showModal,
        onCloseModal: modalAction.hideModalDialog,
        setMemberRole: temporaryAction.setToMapTemp('membersRoles'),
        clearTemporary: temporaryAction.clearTemporary,
        setInviteMembers: temporaryAction.setToSetTemp('inviteMembers'),
        editMembersModal: modalSetsAction.editMembers
      },
      dispatch
    )
)(translate(['ui'])(SimpleModal));
