import React, { memo, useCallback, useEffect, useState } from 'react';

import { useTranslate } from '@hooks';
import { Input } from '@link/react-components';
import { validateEmail } from '@root/utils/validateField';
import { ERRORS, ERRORS_TO_TEXT } from '../lib/constants';
import styles from './InvitePersonalInfo.module.css';

export const InvitePersonalInfo = ({ onChange }) => {
  const t = useTranslate();

  const [email, setEmail] = useState({
    value: '',
    error: null
  });

  useEffect(() => {
    onChange({ email });
  }, [email]);

  const onEmailChange = useCallback(
    (value) => {
      if (!value) {
        setEmail({ value, error: ERRORS.NOT_BE_EMPTY });
        return;
      }

      if (!validateEmail(value)) {
        setEmail({
          value,
          error: ERRORS.INCORRECT_EMAIL_WAS_PASSED
        });
        return;
      }
      setEmail({
        value,
        error: null
      });
    },
    [email.value]
  );

  return (
    <div className={styles.container}>
      <div className={styles.inputControl}>
        <Input
          onChange={onEmailChange}
          placeholder={t(`features.invitePersonalInfo.email.placeholder`)}
          value={email.value}
          required
          label={t(`features.invitePersonalInfo.email.label`)}
          valid={!email.error}
          errorText={t(ERRORS_TO_TEXT[email.error])}
        />
      </div>
    </div>
  );
};

export const MemoizedInvitePersonalInfo = memo(InvitePersonalInfo);
