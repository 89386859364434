import React from 'react';
import { useSelector } from 'react-redux';
import { getInterlocutor } from 'core/data/light/dialog';
import Avatar from '../../Avatar';
import { getCurrentEmployeeId } from '../../../../storeGetters';
import {
  isChannel,
  isDialog,
  isNewsFeedChannel,
  isPurchaseRequestsChannel,
  isResponseChat,
  isTopic
} from '../../helpers/chatType';

const ChatAvatar = ({ chat, size = 30, isCursorPointer = false, onClick }) => {
  const currentEmployeeId = useSelector(getCurrentEmployeeId);
  const getForAvatar = () => {
    if (isDialog(chat)) {
      const interlocutor = getInterlocutor(currentEmployeeId, chat);

      return {
        type: 'user',
        src: interlocutor.avatarSrc,
        alt: interlocutor.userName,
        employeeId: +interlocutor.employeeId,
        status: interlocutor.status
      };
    }
    if (isResponseChat(chat)) {
      return { type: 'color-icon', color: chat.color, icon: 'response' };
    }
    if (isChannel(chat) || isTopic(chat)) {
      return { type: 'color-char', color: chat.color, text: chat.name };
    }
    if (isPurchaseRequestsChannel(chat)) {
      return { type: 'color-avatar', subType: 'request-channel' };
    }
    if (isNewsFeedChannel(chat)) {
      return { type: 'color-avatar', subType: 'news-feed-channel' };
    }
    return { type: 'color-char', color: '#aaa', text: 'not avatar' };
  };

  return (
    <Avatar
      size={size}
      info={getForAvatar()}
      isCursorPointer={isCursorPointer}
      onClick={onClick}
    />
  );
};

export default ChatAvatar;
