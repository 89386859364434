import React, { useCallback } from 'react';
import { Rnd } from 'react-rnd';
import Editor from '@draft-js-plugins/editor';
import styles from './EditorInputFiles.module.css';
import { generateWrapperEditorCN, isShowFileAttachments } from '../functions';
import CustomMentionSuggestions from '../editor/plugins/MentionSuggestions';
import Attachments from '../../../../Channel/attachments/Attachments';
import { initHandleDroppedFiles } from '../editor/handlers';

function generateInputFilesCN(theme) {
  return `cw-input-msg__input-files input-files_theme_${theme}`;
}

const styleMap = {
  BOLD: {
    fontWeight: 500
  }
};

const EditorInputFiles = React.forwardRef(
  (
    {
      files,
      rndConfig,
      editorState,
      onClickByWrapper,
      onChangeES,
      plugins,
      placeholder,
      handleKeyCommand,
      handlePastedText,
      keyBindingFn,
      handlePastedFiles,
      editorId,
      mentions,
      MentionSuggestions,
      wrapperRef,
      theme,
      onDeleteFile,
      EmojiSuggestions
    },
    ref
  ) => {
    const handleDroppedFiles = useCallback(initHandleDroppedFiles(), []);
    return (
      <div className={styles.editor} onClick={onClickByWrapper}>
        <Rnd {...rndConfig}>
          <div
            ref={wrapperRef}
            className={generateWrapperEditorCN(editorState)}
            id="message-input">
            <Editor
              editorKey="editor"
              editorState={editorState}
              onChange={onChangeES}
              spellCheck={false}
              plugins={plugins}
              ref={ref}
              placeholder={placeholder}
              handleKeyCommand={handleKeyCommand}
              keyBindingFn={keyBindingFn}
              handleDroppedFiles={handleDroppedFiles}
              handlePastedText={handlePastedText}
              handlePastedFiles={handlePastedFiles}
              autoCapitalize="off"
              autoComplete="off"
              autoCorrect="off"
              customStyleMap={styleMap}
            />
            <EmojiSuggestions />
            <CustomMentionSuggestions
              editorId={editorId}
              mentions={mentions}
              Component={MentionSuggestions}
            />
          </div>
        </Rnd>
        {isShowFileAttachments(files) && (
          <div className={generateInputFilesCN(theme)}>
            <Attachments files={files} onDelete={onDeleteFile} theme={theme} />
          </div>
        )}
      </div>
    );
  }
);

export default EditorInputFiles;
