import React, { Component } from 'react';
import { withTranslation as translate } from 'react-i18next';

import SecurityBoxLogo from '../components/MessengerAuth/SecurityBoxLogo';

class InvalidLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaderText: 'Invalid link text'
    };
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <SecurityBoxLogo />
        <p style={{ textAlign: 'center', marginTop: 55 }}>
          <h4 style={{ fontSize: 18, color: '#252b49', fontWeight: 'normal' }}>
            {t(this.state.loaderText)}
          </h4>
        </p>
      </div>
    );
  }
}

export default translate(['ui'], { wait: true })(InvalidLink);
