import React from 'react';
import { Trans as Interpolate } from 'react-i18next';
import { isType } from 'core/lib';
import { getInterlocutor } from 'core/data/light/dialog';

function isMessengerGreetingMsg(message) {
  return (
    message.msg === 'messenger_greeting' ||
    message.msg.tkey === 'messenger_greeting'
  );
}

const MessageLink = ({ href, text }) => (
  <a href={href} target="_blank" rel="noreferrer">
    {text}
  </a>
);

export const getTextMessage = ({ t, message, chat, interlocutor }) => {
  if (isType('MsgSupport', message) || message.msg?.tkey) {
    let i18key;
    if (isMessengerGreetingMsg(message))
      i18key = 'support_messenger_greeting_message';
    if (message.msg === 'istock_link_faq')
      i18key = 'support_faq_istock_link_message';
    if (message.msg === 'answerphone') i18key = 'support_back_message';
    return {
      ...message,
      msg: (
        <Interpolate
          i18nKey={i18key}
          components={{
            link: (
              <MessageLink
                href="https://istock.link/faq/"
                text={t('helpLink')}
              />
            ),
            userCompanyLink: (
              <MessageLink
                href={`${process.env.ISTOCK_MESSENGER_SERVICE}/company/edit`}
                text={t('userCompanyLink')}
              />
            ),
            createPurchaseRequestLink: (
              <MessageLink
                href={`${process.env.ISTOCK_MESSENGER_SERVICE}/requests/all/my-requests/create`}
                text={t('createPurchaseRequestLink')}
              />
            ),
            companiesListLink: (
              <MessageLink
                href={`${process.env.ISTOCK_MESSENGER_SERVICE}/companies`}
                text={t('companiesListLink')}
              />
            ),
            istockLinkLink: (
              <MessageLink
                href="https://istock.link/"
                text={t('istockLinkLink')}
              />
            )
          }}
          values={{
            userName:
              interlocutor?.userName ||
              getInterlocutor(message.employeeId, chat)?.userName
          }}
        />
      )
    };
  }

  return message;
};
