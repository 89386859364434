import React, { useCallback, useState } from 'react';
import { Button, Checkbox, Modal, Typography } from '@link/react-components';

import { useTranslate } from '@hooks';

import { hintService } from '@root/api';
import { importPartnersHints } from '../constants';

import styles from './Welcome.module.css';

export const Welcome = ({ goToImport }) => {
  const t = useTranslate();
  const translate = (key) => t(`modules.modalImportPartners.${key}`);

  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  const handleSelect = useCallback(() => {
    setDoNotShowAgain((prev) => !prev);
  }, []);

  const handleGoToImport = useCallback(() => {
    if (doNotShowAgain) {
      hintService.view({ hintName: importPartnersHints.welcome });
    }
    goToImport();
  }, [doNotShowAgain]);

  return (
    <>
      <Modal.Content>
        <div className={styles.contentContainer}>
          <Typography variant="body1Reg">
            {translate('welcomeMessage')}
          </Typography>
          <img
            alt="Import partners welcome"
            src="/img/import-partners-welcome.png"
            width={520}
          />
        </div>
      </Modal.Content>
      <Modal.Footer align="space-between">
        <Checkbox
          label={translate('actions.doNotShowAgain')}
          onChange={handleSelect}
          checked={doNotShowAgain}
        />
        <Button onClick={handleGoToImport}>
          {translate('actions.goToImport')}
        </Button>
      </Modal.Footer>
    </>
  );
};
