import { useCallback } from 'react';
import cn from 'classnames';
import { Typography } from '@/components/Typography';
import { RatingProps } from './Rating.types';
import { EmptyIcon, StarIcon } from './Rating.assets';
import styles from './Rating.module.scss';

function normalizeValue(value: number | null, minThreshold: number) {
  let result;
  if (value === null) {
    result = 0;
  } else {
    result = value < minThreshold ? minThreshold : value;
  }
  return result;
}

export function Rating({
  value,
  interactive = true,
  minThreshold = 1,
  onClick,
  className,
  size = 'medium',
  precision = 1
}: RatingProps) {
  const normalizedValue = normalizeValue(value, minThreshold);

  const handleClick = useCallback(() => {
    if (!interactive) {
      return;
    }
    if (typeof onClick === 'function') {
      onClick();
    }
  }, [interactive, onClick]);

  const toFixedPrecision: number = value === null ? 0 : precision;

  return (
    <div
      role="presentation"
      onClick={handleClick}
      className={cn(
        className,
        styles.rating,
        value !== null && interactive && styles.interactive
      )}>
      {value === null ? (
        <EmptyIcon className={styles[`${size}`]} />
      ) : (
        <StarIcon className={styles[`${size}`]} />
      )}
      <Typography variant={size === 'small' ? 'body2Med' : 'body1Med'}>
        {normalizedValue.toFixed(toFixedPrecision)}
      </Typography>
    </div>
  );
}
