import React from 'react';
import { withTranslation as translate } from 'react-i18next';

import MainContent from './elements/MainContent';
import { GridPageThemeDefault } from '../GridPage';
import { GridContentThemeDefault } from '../GridContent';
import ContainersDefault from '../Containers/ContainersDefault';

const MainNotPermission = ({ t }) => (
  <GridPageThemeDefault
    pageDetails={{ title: t('Not permission') }}
    content={
      <GridContentThemeDefault
        content={
          <MainContent>
            <ContainersDefault>
              <h1 className="text-center">
                {t('Not permission Contact the administrator')}
              </h1>
            </ContainersDefault>
          </MainContent>
        }
      />
    }
  />
);

export default translate(['ui'], { wait: true })(MainNotPermission);
