import React from 'react';
import { useTranslate, useActions } from '@hooks';
import { Modal, Button } from '@link/react-components';
import * as modalActions from '../../../../action-creators/modal';

const CWModalBlockContact = ({ onBlock, isBlocked, name, employeeId }) => {
  const t = useTranslate();
  const { hideModalDialog } = useActions(modalActions);

  const title = t(
    isBlocked
      ? 'modals.confirm_unblock_contact.header'
      : 'modals.confirm_block_contact.header',
    { name }
  );

  const text = t(
    isBlocked
      ? 'modals.confirm_unblock_contact.desc'
      : 'modals.confirm_block_contact.desc',
    { name }
  );

  const submitBtnText = t(isBlocked ? 'Unblock' : 'Block');

  async function onSubmit() {
    await onBlock(employeeId);
    hideModalDialog();
  }

  function onClose() {
    hideModalDialog();
  }

  return (
    <Modal onClose={onClose} open>
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>{text}</Modal.Content>
      <Modal.Footer>
        <Modal.Actions>
          <Button mode="text" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button mode="primary" onClick={onSubmit}>
            {submitBtnText}
          </Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default CWModalBlockContact;
