import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import cn from 'classnames';
import { useTranslate } from '@hooks';
import {
  ConfirmationInput,
  Icon,
  Tooltip,
  Typeahead,
  Typography
} from '@link/react-components';
import Highlighter from 'react-highlight-words';
import { productsService } from '@api';
import { noop } from '@root/utils/utils';
import { useNeedPreviewModal } from '@root/components/Modal';
import { GoToIstock } from '../../GoToIstock';
import { Hint } from '../Hint';
import styles from './ProductName.module.css';
import { ProductsTableMode } from '../../../constants';
import { PRODUCTS_TABLE_FIELDS } from '../../constants';
import { validateAnalog } from './validation';

const MARK_ICON_SIZE = 14;
const MAX_TOOLTIP_WIDTH = 700;
const MAX_ANALOG_NAME_LENGTH = 500;
const VARIANTS = Object.freeze({
  link: 'link',
  text: 'text'
});

function ProductOption({ externalId, name, query }) {
  return (
    <div className="product-table-option-block">
      <Typography variant="body1Reg" className="product-table-option-title">
        <Highlighter
          highlightClassName="product-table-option-title_highlighted"
          searchWords={[query]}
          autoEscape
          textToHighlight={name}
        />
      </Typography>
      <GoToIstock externalId={externalId} />
    </div>
  );
}

const ProductFromDictionary = ({
  name,
  externalId,
  variant,
  onClick,
  className
}) => (
  <div
    className={cn('product-row-title', 'with-adornment', className, {
      'product-row-title_link': variant === VARIANTS.link
    })}>
    <span onClick={onClick}>{name}</span>
    <div className={styles.adornment}>
      <GoToIstock externalId={externalId} />
    </div>
  </div>
);

const NeedProduct = ({ name, externalId, variant, onClick, className }) => (
  <div
    className={cn('product-row-title', className, {
      'product-row-title_link': variant === VARIANTS.link
    })}>
    <span onClick={onClick}>{name}</span>
    {externalId && (
      <div className={styles.adornment}>
        <GoToIstock externalId={externalId} />
      </div>
    )}
  </div>
);

const AnalogAdornment = ({ hint }) => (
  <Tooltip title={hint} maxWidth={MAX_TOOLTIP_WIDTH}>
    <Icon width={MARK_ICON_SIZE} height={MARK_ICON_SIZE} iconName="mark" />
  </Tooltip>
);

const ProductAnalog = ({ name, className, originalName }) => (
  <div className={cn(className, styles.analog)}>
    <span className="product-row-title">{name}</span>
    <div className={styles.adornment}>
      <AnalogAdornment hint={originalName} />
    </div>
  </div>
);

const getProductLabel = (product) => product.name;

export const ProductName = ({
  grouped = false,
  mode,
  className,
  readOnly = false,
  name,
  originalName: originalNameProp,
  externalId,
  needId,
  index,
  onSelect = noop,
  onChangeInputTextarea = noop,
  hint,
  onViewHint = noop,
  onPaste,
  customerEditMode
}) => {
  const t = useTranslate();
  const confirmInputRef = useRef();
  const [editMode, setEditMode] = useState(false);
  const originalNameRef = useRef(originalNameProp || name);
  const [query, setQuery] = useState('');
  const nameRef = useRef(name);
  useEffect(() => {
    nameRef.current = name;
  }, [name]);
  useEffect(() => {
    originalNameRef.current = originalNameProp;
  }, [originalNameProp]);

  const [openNeedPreview] = useNeedPreviewModal();

  const cancelFnRef = useRef(null);

  const provider = useMemo(
    () => ({
      async get(search) {
        if (cancelFnRef.current) {
          cancelFnRef.current();
        }

        const [getProducts, cancel] = productsService.getProducts({
          search,
          limit: 20,
          offset: 0
        });

        cancelFnRef.current = cancel;
        const products = await getProducts();
        cancelFnRef.current = null;

        return products.map((product) => ({
          key: product.name,
          value: product
        }));
      }
    }),
    [cancelFnRef]
  );

  const onBlurCancelRequest = useCallback(() => {
    if (cancelFnRef.current) {
      cancelFnRef.current();
      cancelFnRef.current = null;
    }
  }, [cancelFnRef]);

  const editModeOn = useCallback(() => {
    if (!readOnly) {
      setEditMode(true);
    }
  }, [readOnly]);

  const editModeOff = useCallback(() => {
    if (!readOnly) {
      setEditMode(false);
    }
  }, [readOnly]);

  const onFocus = useCallback(() => {
    onViewHint(PRODUCTS_TABLE_FIELDS.productName);

    editModeOn();
  }, [editModeOn, originalNameRef, onViewHint]);

  const isCustomerView = mode === ProductsTableMode.Customer;
  const isSupplierView = mode === ProductsTableMode.Supplier;
  const isAnalog =
    originalNameRef.current &&
    nameRef.current &&
    originalNameRef.current !== nameRef.current;

  const renderOption = useCallback(
    (entity) => (
      <ProductOption
        name={entity.name}
        externalId={entity.externalId}
        query={query}
      />
    ),
    [query]
  );
  const onChange = useCallback(
    (value) => {
      setQuery(value);
      onChangeInputTextarea(value, index);
    },
    [onChangeInputTextarea, index]
  );

  const onSubmit = useCallback(
    (value) => {
      const newValue = validateAnalog(value);

      if (!newValue) {
        const newName = originalNameRef.current;
        confirmInputRef.current.setValue(newName);
        onChange(newName);
        return;
      }

      onChange(newValue);
    },
    [confirmInputRef, originalNameRef, onChange]
  );

  const onHintSubmit = useCallback(
    (hintName) => {
      onViewHint(PRODUCTS_TABLE_FIELDS.productName, hintName);
    },
    [onViewHint]
  );

  const isProductFromDictionary = Boolean(externalId);
  const variant = isCustomerView && needId ? VARIANTS.link : VARIANTS.text;

  const handleClickByTitle = useCallback(() => {
    if (isCustomerView && !grouped && needId) {
      openNeedPreview({ needId });
    }
  }, [isCustomerView, needId, openNeedPreview]);

  if (isCustomerView) {
    if (needId || grouped) {
      return (
        <NeedProduct
          name={name}
          externalId={externalId}
          variant={variant}
          onClick={handleClickByTitle}
          className={styles.needName}
        />
      );
    }

    if (!readOnly) {
      return (
        <div
          className={cn(className, styles.fullSpace, styles.noBorder)}
          onPaste={onPaste}>
          <Typeahead
            containerClassName={styles.fullSpace}
            placeholder={t('product_placeholder')}
            initialValue={name || ''}
            className="typeahead"
            provider={provider}
            renderOption={renderOption}
            onBlur={onBlurCancelRequest}
            endAdornment={
              externalId ? <GoToIstock externalId={externalId} /> : null
            }
            onChange={onChange}
            onSelect={onSelect(index)}
            maxLength={Number.MAX_SAFE_INTEGER}
            getOptionLabel={getProductLabel}
          />
        </div>
      );
    }
  }

  if (isSupplierView || customerEditMode) {
    if (customerEditMode || !readOnly) {
      const shownHint = Boolean(hint);
      const showAdornment = !editMode && isAnalog;

      return (
        <div
          className={cn(className, styles.fullSpace, styles.noBorder)}
          onPaste={onPaste}>
          <Hint
            text={hint}
            open={Boolean(hint)}
            className={cn(styles.hint, shownHint && styles.show)}
            onSubmit={onHintSubmit}>
            <ConfirmationInput
              ref={confirmInputRef}
              className={cn(
                styles.inputContainer,
                'editable-cell',
                !shownHint &&
                  (!editMode || !customerEditMode) &&
                  styles.withBorder
              )}
              containerClassName={styles.input}
              defaultValue={name || originalNameRef.current}
              placeholder={originalNameRef.current}
              onSubmit={onSubmit}
              onFocus={onFocus}
              onBlur={editModeOff}
              maxLength={MAX_ANALOG_NAME_LENGTH}
              endAdornment={
                showAdornment && (
                  <div className={styles.analogAdornment}>
                    <AnalogAdornment hint={originalNameRef.current} />
                  </div>
                )
              }
            />
          </Hint>
        </div>
      );
    }
  }

  if (isProductFromDictionary) {
    return (
      <ProductFromDictionary
        name={name}
        className={className}
        externalId={externalId}
        variant={variant}
        onClick={handleClickByTitle}
      />
    );
  }

  if (isAnalog) {
    return (
      <ProductAnalog
        name={name}
        className={className}
        originalName={originalNameRef.current}
      />
    );
  }

  return (
    <div
      className={cn(
        'product-row-title',
        {
          'product-row-title_link': variant === VARIANTS.link
        },
        className
      )}
      onClick={handleClickByTitle}>
      {name}
    </div>
  );
};
