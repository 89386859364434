import React, { useCallback, useMemo, useState } from 'react';
import { isNil } from 'ramda';
import { companyService } from '@api';
import { calcOffset } from '@root/utils/utils';
import { DynamicList, SearchInput, Typography } from '@link/react-components';
import { NoResult } from '@root/pages/administration/shared';
import * as storeGetters from '@root/storeGetters';
import { useSelector } from 'react-redux';
import { useDebounce, useTranslate } from '@hooks';
import { MemoizedSimpleEmployeeRaw } from '@root/entities';
import styles from './ViewEmployeesListByRole.module.css';

const ITEMS_PER_PAGE = 20;
const GAP_BETWEEN_ITEMS = 0;
const SEARCH_TYPING_DEBOUNCE = 500;
const VIEWED_EMPLOYEE_ATTRIBUTES = ['firstName', 'lastName'];
export const ViewEmployeesListByRole = ({ roleId }) => {
  const t = useTranslate();

  const companyId = useSelector(storeGetters.getCurrentCompanyId);

  const [isEmployeesExist, setIsEmployeesExist] = useState(null);
  const [search, setSearch] = useState('');

  const onSearchTyping = useDebounce((searchValue) => {
    setSearch(searchValue);
  }, SEARCH_TYPING_DEBOUNCE);

  const onLoad = useCallback(
    async (companyId, roleId, { page, limit, search }) => {
      const result = await companyService.getEmployeesByRoleId(
        companyId,
        roleId,
        {
          offset: calcOffset(page, limit),
          limit,
          search
        }
      );
      setIsEmployeesExist((prevState) => {
        if (isNil(prevState)) {
          return !!result.length;
        }
        return prevState;
      });
      return result;
    },
    [search, companyId, roleId]
  );

  const provider = useMemo(
    () => ({
      load: (page, limit) => onLoad(companyId, roleId, { page, limit, search })
    }),
    [companyId, roleId, search]
  );

  if (!isNil(isEmployeesExist) && !isEmployeesExist) {
    return (
      <Typography variant="body1Reg" className={styles.noAssignedRole}>
        {t('widget.viewEmployeesListByRole.roleNotAssigned')}
      </Typography>
    );
  }

  return (
    <div className={styles.viewEmployeesList}>
      <SearchInput
        defaultValue={search}
        onSearch={setSearch}
        onCleanUp={() => setSearch('')}
        onChange={onSearchTyping}
        placeholder={t('widget.viewEmployeesListByRole.enterEmployeeName')}
      />

      <DynamicList
        className={styles.list}
        provider={provider}
        renderItem={(employee) => (
          <MemoizedSimpleEmployeeRaw
            employee={employee}
            viewFields={VIEWED_EMPLOYEE_ATTRIBUTES}
          />
        )}
        noResultsScreen={
          <NoResult
            className={styles.noResult}
            tKey="widget.viewEmployeesListByRole.emptySearch"
          />
        }
        batchSize={ITEMS_PER_PAGE}
        gap={GAP_BETWEEN_ITEMS}
      />
    </div>
  );
};
