import { sectionsPositions } from './constants';

const sortSections = (s1, s2) =>
  sectionsPositions[s1.sectionName] - sectionsPositions[s2.sectionName];

export const groupPermissionsBySortedSections = (permissions) => {
  const groupsMappedToSections = permissions.reduce((acc, permission) => {
    if (!acc[permission.section]) {
      acc[permission.section] = {
        sectionName: permission.section,
        permissions: [permission]
      };
    } else {
      acc[permission.section].permissions.push(permission);
    }
    return acc;
  }, {});
  return Object.values(groupsMappedToSections).sort(sortSections);
};

export const getPermissionsMappedToIds = (permissions) =>
  permissions.reduce((acc, permission) => {
    acc[permission.id] = { ...permission };
    return acc;
  }, {});

export const getPermissionsDiff = (
  updatedPermissionsObj,
  initialPermissions
) => {
  const diffArr = [];
  const initialPermissionsObj = getPermissionsMappedToIds(initialPermissions);
  initialPermissions.forEach((permission) => {
    const { id } = permission;
    if (
      updatedPermissionsObj[id].allowed !== initialPermissionsObj[id].allowed
    ) {
      diffArr.push({
        id: updatedPermissionsObj[id].id,
        allowed: updatedPermissionsObj[id].allowed
      });
    }
  });
  return diffArr;
};
