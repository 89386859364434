import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation as translate } from 'react-i18next';
import { MenuItem } from 'react-bootstrap';
import EnclosureAttachmentMenu from './EnclosureAttachmentMenu';

// Menu Item
// ---------------------------------------------------------------------------------------

const Item = (props) => {
  const { callback, title, ...restProps } = props;
  return (
    <MenuItem className="dropdown_menu_item" onClick={callback} {...restProps}>
      {title}
    </MenuItem>
  );
};

Item.propTypes = {
  title: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired
};

// Menu
// ---------------------------------------------------------------------------------------
const AttachmentMenu = ({
  isShowDownload,
  isShowDelete,
  isShowAddToStorage,
  onDownload,
  onDelete,
  onAddToStorage
}) => {
  const createListItem = (value, cb) => ({ value, onClick: cb });

  const makeList = () => {
    const downloadItem =
      isShowDownload && createListItem('Download', onDownload);
    const deleteItem = isShowDelete && createListItem('Delete', onDelete);
    const addToStorageItem =
      isShowAddToStorage && createListItem('Add_to_storage', onAddToStorage);
    return [downloadItem, deleteItem, addToStorageItem].filter(Boolean);
  };

  return (
    <div style={{ right: 0, top: 0, position: 'absolute' }}>
      <EnclosureAttachmentMenu
        title={<img src="/img/3-dots-outlined.svg" alt="menu" />}
        list={makeList()}
      />
    </div>
  );
};

AttachmentMenu.propTypes = {
  isShowDownload: PropTypes.bool.isRequired,
  isShowDelete: PropTypes.bool.isRequired,
  isShowAddToStorage: PropTypes.bool.isRequired,
  onDownload: PropTypes.func,
  onDelete: PropTypes.func,
  onAddToStorage: PropTypes.func
};

AttachmentMenu.defaultProps = {
  onDownload: () => {},
  onDelete: () => {},
  onAddToStorage: () => {}
};

export default translate()(AttachmentMenu);
