import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Form, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { withTranslation as translate } from 'react-i18next';

import * as modalAction from '../../../../action-creators/modal';
import { Container, Header, Body, Footer } from '../Components';

class ConfirmSubmit extends Component {
  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onCancel() {
    const { cancel, hideModalDialog } = this.props;
    hideModalDialog();
    cancel();
  }

  async onSubmit() {
    const { submit, hideModalDialog } = this.props;
    await submit();
    hideModalDialog();
  }

  render() {
    const { t, title, text, confirmBtnText, cancelBtnText } = this.props;

    return (
      <Modal onHide={this.onCancel} show>
        <Container>
          <Header title={t(title.toLowerCase())} onClose={this.onCancel} />
          {text && (
            <Body>
              <Form>
                <p>{t(text.toLowerCase())}</p>
              </Form>
            </Body>
          )}
          <Footer
            lbTitle={t(cancelBtnText)}
            lbOnClick={this.onCancel}
            rbTitle={t(confirmBtnText)}
            rbOnClick={this.onSubmit}
          />
        </Container>
      </Modal>
    );
  }
}

ConfirmSubmit.propTypes = {
  t: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func,
  text: PropTypes.string,
  confirmBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string
};

ConfirmSubmit.defaultProps = {
  text: '',
  confirmBtnText: 'Send',
  cancelBtnText: 'Cancel',
  cancel: () => {}
};

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      hideModalDialog: modalAction.hideModalDialog
    },
    dispatch
  )
)(translate()(ConfirmSubmit));
