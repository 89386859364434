import React, { Component } from 'react';
import { isEmpty } from 'ramda';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { withTranslation as translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  FormGroup,
  FormControl,
  Button,
  HelpBlock,
  ControlLabel
} from 'react-bootstrap';
import { Typeahead } from '@link/react-components';
import { LocalStorageProxy } from '../../utils/localStorageProxy';
import Helper from '../Helper';
import {
  validateEmail,
  validateCyrLatDigitSpaceDash
} from '../../utils/validateField';
import { logModule } from '../../utils/logger';
import './styles/modal_theme_default.styl';
import Select from '../Select/Universal';

import { ModalContainer } from './elements';
import * as companyActions from '../../action-creators/company';

import * as servicesActions from '../../action-creators/services';
import * as modalAction from '../../action-creators/modal';

const b = block('modal-container');

const getTKeyTitle = (section) => `tips.permissions.${section}.title`;
const getTKeyDesc = (section) => `tips.permissions.${section}.desc`;

const typeaheadTitleKey = 'department';
const maxSuggestionsCount = 15;

const log = logModule.extend('ModalEditEmployee');
class ModalEditEmployee extends Component {
  static isPositionInCompanyValid(value) {
    if (value !== '' && value !== undefined) {
      return validateCyrLatDigitSpaceDash(value);
    }
    return true;
  }

  constructor(props) {
    super(props);
    this.state = {
      email: props.selectedEmployee.get('email') || '',
      department: props.selectedEmployee.get('department') || '',
      position: props.selectedEmployee.get('position') || '',
      employeeVisibility: props.selectedEmployee.get('employeeVisibility'),
      requestComplete: false
    };
    this.setPositionInCompany = this.setPositionInCompany.bind(this);
    this.setDepartment = this.setDepartment.bind(this);
    this.action = this.action.bind(this);
    const storageConst = new LocalStorageProxy(typeaheadTitleKey);
    this.storage = storageConst;
    this.provider = {
      async get(query) {
        const departments = await storageConst.get(query).catch(() => []);
        return departments.map((dept) => ({ key: dept, value: dept }));
      }
    };
  }

  getChildContext() {
    return { b };
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  setPositionInCompany(e) {
    this.setState({ position: e.target.value.toString() });
  }

  setEmail(e) {
    this.setState({ email: e.target.value.toString() });
  }

  setDepartment(value) {
    this.setState({ department: value });
  }

  get isSelectedOwner() {
    const { selectedEmployee, ownerId } = this.props;

    if (!selectedEmployee) return null;

    return selectedEmployee.toJS().userId === ownerId;
  }

  get isCurrentUserOwner() {
    const { userId, ownerId } = this.props;

    return userId === ownerId;
  }

  get isChangeEmailDisabled() {
    return this.isSelectedOwner && !this.isCurrentUserOwner;
  }

  get isEmptyEmail() {
    return isEmpty(this.state.email);
  }

  async validateNameInCompanyUnique(name) {
    const { isNameInCompanyUnique, selectedEmployee } = this.props;
    await isNameInCompanyUnique(name, selectedEmployee.get('id'));
    this.setState({ requestComplete: true });
  }

  isNameInCompanyEmpty() {
    return this.state.nickname.length === 0;
  }

  isEmptyPosition() {
    return this.state.position.trim().length === 0;
  }

  haveChange(key) {
    return this.state[key] !== this.props.selectedEmployee.get(key);
  }

  isValidPosition() {
    return (
      !this.isEmptyPosition() &&
      ModalEditEmployee.isPositionInCompanyValid(this.state.position)
    );
  }

  isSaveDisabled() {
    log(
      'haveChange(position)',
      this.haveChange('position'),
      'haveChange(employeeVisibility)',
      this.haveChange('employeeVisibility'),
      'isValidPosition',
      this.isValidPosition()
    );
    if (this.isEmptyPosition() || !this.isValidPosition()) return true;
    if (!validateEmail(this.state.email)) return true;

    const result =
      this.haveChange('position') ||
      this.haveChange('email') ||
      this.haveChange('department') ||
      this.haveChange('employeeVisibility');
    return !result;
  }

  asterisk() {
    return <span style={{ color: 'red' }}> * </span>;
  }

  inputProps(field) {
    return {
      onChange: (e) => this.onChange(field, e.target.value)
    };
  }

  async action() {
    const { editEmployee, hideModalDialog, getListOfUsers, selectedEmployee } =
      this.props;
    await editEmployee(
      selectedEmployee.get('id'),
      this.state.nickname,
      this.state.position,
      this.state.employeeVisibility,
      this.state.email,
      this.state.department.trim()
    );
    await hideModalDialog();
    await getListOfUsers();
  }

  render() {
    const {
      t,
      title,
      hideModalDialog,
      company,
      selectedEmployee,
      userId,
      ownerId,
      full
    } = this.props;

    return (
      <Modal id="inviteEmployee" onHide={() => hideModalDialog()} show>
        <ModalContainer mods={{ theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t(title)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup
              controlId="email"
              style={{ overflow: 'hidden' }}
              validationState={
                validateEmail(this.state.email) ? 'success' : 'error'
              }>
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {`${t('Employee email')}`}
              </ControlLabel>
              <br />

              <FormControl
                type="text"
                placeholder={t('email')}
                defaultValue={selectedEmployee.get('email')}
                onChange={this.setPositionInCompany}
                disabled={this.isChangeEmailDisabled}
                {...this.inputProps('email')}
              />
              {this.isEmptyEmail && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t('Required fields empty')}
                </HelpBlock>
              )}
              {!validateEmail(this.state.email) && !this.isEmptyEmail && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t('Wrong email format')}
                </HelpBlock>
              )}
            </FormGroup>
            <FormGroup
              controlId="position"
              validationState={this.isEmptyPosition() ? 'error' : null}
              style={{ overflow: 'hidden' }}>
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {t('Position')}
                {this.asterisk()}
              </ControlLabel>
              <FormControl
                type="text"
                placeholder={t('example goodwix')}
                defaultValue={selectedEmployee.get('position')}
                onChange={this.setPositionInCompany}
                {...this.inputProps('position')}
              />
              {this.isEmptyPosition() && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t('Required fields empty')}
                </HelpBlock>
              )}
              {!ModalEditEmployee.isPositionInCompanyValid(
                this.state.position
              ) &&
                !this.isEmptyPosition() && (
                  <HelpBlock style={{ marginBottom: 0 }}>
                    {t('wrong_position_in_company')}
                  </HelpBlock>
                )}
            </FormGroup>
            <FormGroup controlId="department" style={{ overflow: 'hidden' }}>
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {t('department')}
              </ControlLabel>
              <Typeahead
                initialValue={selectedEmployee.get('department')}
                onChange={this.setDepartment}
                provider={this.provider}
              />
            </FormGroup>
            <FormGroup controlId="visability">
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {`${t('visibility-of-employee')}`}
              </ControlLabel>
              <Select
                value={this.state.employeeVisibility}
                onChange={(value) =>
                  this.setState({ employeeVisibility: value })
                }
                options={['hidden-from-other-companies', 'visible-to-all']}
                translateGroup="employee-visibility"
              />
            </FormGroup>
            <div style={{ margin: '25px 0' }}>
              <h5 style={{ display: 'inline' }}>
                {t('Access right to sections')}
              </h5>
              &nbsp;
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="editEmployeerButton"
              bsStyle="primary"
              disabled={this.isSaveDisabled()}
              onClick={async () => {
                await this.storage.add(
                  this.state.department,
                  maxSuggestionsCount
                );
                this.action();
              }}>
              {t('Save changes')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalEditEmployee.propTypes = {
  t: PropTypes.func.isRequired
};

ModalEditEmployee.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    company: state.get('company'),
    user: state.getIn(['user', 'user']),
    ownerId: state.getIn(['company', 'ownerUserId']),
    userId: state.getIn(['user', 'user', 'id'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        showModal: modalAction.showModal,
        clearSelectedEmployee: companyActions.clearSelectedEmployee,
        gaSend: servicesActions.gaSend
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalEditEmployee));
