import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'ramda';

import { disableTag } from '../messages';
import ChatItem from '../../../Components/Cards/Chat';
import * as useCase from '../../../useCases';
import BtnClose from './btnClose';
import NoResults from './noResults';
import LoadMore from '../../../main/btn-load-more';
import { useTranslate } from '../../../../../TranslateProvider';

const CWASChats = ({
  chats = [],
  tag,
  handleChatList,
  onClickLoadMore,
  canLoadMore = false
}) => {
  const t = useTranslate();

  if (chats === null) return null;

  if (isEmpty(chats)) {
    return (
      <>
        <div className="cws-list-search__divider">
          <div className="cws-list-search__divider-text">
            {t(`chat-widget.advanced-search.hashtags.list.${tag}`)}
          </div>
          <div className="cws-list-search__divider-btn">
            <BtnClose onClick={() => disableTag.emit(tag)} />
          </div>
        </div>
        <NoResults />
      </>
    );
  }

  return (
    <>
      <div className="cws-list-search__divider">
        <div className="cws-list-search__divider-text">
          {t(`chat-widget.advanced-search.hashtags.list.${tag}`)}
        </div>
        <div className="cws-list-search__divider-btn">
          <BtnClose onClick={() => disableTag.emit(tag)} />
        </div>
      </div>
      {chats.map((chat) => (
        <ChatItem
          {...chat}
          handler={handleChatList}
          replaceOpenChat={tag === 'messages'}
        />
      ))}
      {canLoadMore && (
        <div className="cws-list-search__load-more">
          <LoadMore onClick={onClickLoadMore} isShow />
        </div>
      )}
    </>
  );
};

export default connect(null, (dispatch) =>
  bindActionCreators(useCase, dispatch)
)(CWASChats);
