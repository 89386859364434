import { isNil } from 'ramda';
import { padStart } from '@root/components/ChatWidget/Datepicker/dateTimePicker/utils';

export function getInitTime(minTime, defaultTime) {
  return !isNil(minTime) ? getTime(minTime) : defaultTime;
}

export function getTime(date, separator = ':') {
  const hh = padStart(new Date(date).getHours(), '0', 2);
  const mm = padStart(new Date(date).getMinutes(), '0', 2);

  return `${hh}${separator}${mm}`;
}

export function getTimeMask(separator = ':') {
  return `HH{${separator}MM`;
}
