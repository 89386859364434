import { emitToServer } from '@root/storage/message';
import * as actions from './actions';
import { profileQRY } from './messages';
import { hideProfileCase } from './useCases/hideProfile';

export const showProfile = (employeeId) => async (dispatch, getState) => {
  const userEmployeeId = getState().getIn(['user', 'user', 'employeeId']);

  emitToServer(profileQRY.sync, {
    whoEmployeeId: userEmployeeId,
    targetEmployeeId: employeeId
  });
};

export const hideProfile = () => async (dispatch) =>
  hideProfileCase({
    hideProfile: () => dispatch(actions.hideProfile())
  });
