import { UserInfoDto } from './userInfo';
import { CompanyInfoDto } from './companyInfo';
import { ResponseInfoDto } from './responseInfo';

export class QuickResponseDto {
  constructor(props) {
    this.userInfo = new UserInfoDto(props.userInfo);
    this.companyInfo = new CompanyInfoDto(props.companyInfo);
    this.responseInfo = new ResponseInfoDto(props.responseInfo);
    this.hints = props.hints;
  }
}
