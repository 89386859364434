import React from 'react';
import { Link } from 'react-router-dom';

const RouterLink = ({ to, text, onClick, ...restProps }) => (
  <Link to={to} onClick={onClick} {...restProps}>
    {text}
  </Link>
);

export default RouterLink;
