export class EmployeeDto {
  constructor(props) {
    this.id = props?.id;
    this.userId = props?.userId;
    this.lastName = props?.lastName || '';
    this.firstName = props?.firstName || '';
    this.middleName = props?.middleName || '';
    this.department = props?.department || '';
    this.title = props?.title || '';
    this.phoneNumber = props?.phoneNumber || '';
    this.added = props?.added || false;
    this.email = props.email || '';
    this.avatar = props.avatar || '';
    this.email = props.email;
    this.employeeVisibility = props.employeeVisibility;
    this.roleId = props.roleId;
    this.isAdmin = props.isAdmin;
    this.position = props?.position || '';
  }
}
