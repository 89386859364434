import { getChatWidget } from '../../getterCommon';
import { REDUX_KEY } from './constants';

export const getConstructor = (state) => getChatWidget(state)[REDUX_KEY];
export const getConstructorState = (state) =>
  getChatWidget(state)[REDUX_KEY].constructors;
export const getConstructorUsersState = (state) =>
  getChatWidget(state)[REDUX_KEY].users;
export const getConstructorUsersList = (state) =>
  getChatWidget(state)[REDUX_KEY].users.list;
export const getBuilderState = (state) =>
  getChatWidget(state)[REDUX_KEY].constructor;
export const getConstructorName = (state) =>
  getChatWidget(state)[REDUX_KEY].constructor.builder.name;

export const getCanBeUpdated = (state) =>
  getChatWidget(state)[REDUX_KEY].constructor.builder?.canBeUpdated;

export const getBuilderType = (state) =>
  getChatWidget(state)[REDUX_KEY].constructor.builder.type;

export const getBuilderPath = (state) =>
  getChatWidget(state)[REDUX_KEY].constructor.builder.path;

export const getIsEndStepExist = (state) =>
  getChatWidget(state)[REDUX_KEY].constructor.builder.isEndStepExist;
