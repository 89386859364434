import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from '../../action-creators/modal';

import { useTranslate } from '../../TranslateProvider';
import { filterCategories, getNameString, toggleCategory } from './helper';

const [UP, DOWN, ENTER] = [38, 40, 13];

const useCategoriesSelect = ({
  list,
  onChoose,
  selectedList,
  disabled,
  nextModalHandler = () => {}
}) => {
  const [value, setValue] = useState('');
  const [isOpened, setIsOpened] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [menuOptions, setMenuOptions] = useState([]);
  const [focusedId, setFocusedId] = useState(0);

  const onClearSelectedList = () => {
    if (disabled) return;
    onChoose([]);
  };

  const inputRef = useRef();
  const dispatch = useDispatch();

  const t = useTranslate();

  const onToggle = (categoryId) => () => {
    if (disabled) return;

    onChoose(toggleCategory({ selectedList, categoryId }));
    setValue('');
    setIsOpened(false);
  };

  useEffect(() => {
    if (value.length === 0) {
      setMenuOptions([]);
      return;
    }

    const filteredOptions = filterCategories({ list, value, t })
      .filter(({ id }) => !selectedList.includes(id))
      .slice(0, 5)
      .map(({ name, id }, index) => ({
        name: getNameString(name, t),
        onClick: onToggle(id),
        isFocused: focusedId === index
      }));

    if (filteredOptions.length > 0) {
      setMenuOptions(filteredOptions);
    } else {
      setMenuOptions([{ name: t('noResultsText') }]);
    }

    setIsOpened(true);
  }, [value, focusedId]);

  const onChange = ({ target }) => !disabled && setValue(target.value);

  const onKeyDown = ({ which }) => {
    if (!isOpened || menuOptions.length === 0 || disabled) {
      return;
    }

    switch (which) {
      case UP:
        setFocusedId((5 + (focusedId - 1)) % 5);
        break;
      case DOWN:
        setFocusedId((focusedId + 1) % 5);
        break;
      case ENTER:
        menuOptions[focusedId].onClick();
        break;
      default:
        break;
    }
  };

  const onFocus = () => {
    inputRef.current.focus();
    setIsFocused(true);
  };

  const onBlur = () => setIsFocused(false);

  const onOpenModal = () => {
    if (disabled) return;

    dispatch(
      showModal('CATEGORY_SELECT', {
        list,
        selectedList,
        onChoose,
        nextModalHandler
      })
    );
  };

  return {
    onClearSelectedList,
    onChange,
    onOpenModal,
    value,
    isOpened,
    setIsOpened,
    menuOptions,
    onToggle,
    onFocus,
    isFocused,
    onBlur,
    inputRef,
    onKeyDown,
    t
  };
};

export default useCategoriesSelect;
