import URLSearchParams from 'url-search-params';
import request from '../lib/request';
import {
  ResponsePartnersCompanyDto,
  ResponseInvitePartnersCompanyDto
} from '../DTOs';

class PartnersCompanyService {
  #baseUrl = '/api/company-partners';

  async getAll({
    offset = 0,
    limit = 20,
    search = '',
    order,
    direction,
    registrationStatus
  } = {}) {
    const urlSearchParams = new URLSearchParams({ offset, limit, search });

    const result = await request.post(
      `${this.#baseUrl}/list?${urlSearchParams}`,
      { order, direction, registrationStatus }
    );

    return result.map((partner) => new ResponsePartnersCompanyDto(partner));
  }

  async invitePartner(email) {
    const result = await request.post(`${this.#baseUrl}/invite`, { email });

    return new ResponseInvitePartnersCompanyDto(result);
  }

  async importPartners(file) {
    const result = await request.sendFile(`${this.#baseUrl}/import`, file);

    return result.map(
      (partner) => new ResponseInvitePartnersCompanyDto(partner)
    );
  }

  async importPartnersTemplate() {
    return request.get(`${this.#baseUrl}/import-template`);
  }
}

export const partnersCompanyService = new PartnersCompanyService();
