import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation as translate } from 'react-i18next';
import ReactSelect from 'react-select';
import { useInnerPlaceholder } from './useInnerPlaceholder';
import './style.css';

const optionRenderer = (t, { label }, show) => (
  <div
    className={`cw-component-input-or-go-to__input-name_select_option${
      show ? ' help' : ''
    }`}>
    <div className="cw-component-input-or-go-to__input-name_select_option-label">
      {label}
    </div>
    <div className="cw-component-input-or-go-to__input-name_select_option-link">
      {t('go-to-the-list')}
    </div>
  </div>
);

const InputOrGoTo = ({
  t,
  existLists,
  onChangeInput,
  onGoTo,
  showGoToList
}) => {
  const [currentOption, setOption] = useState(null);

  useInnerPlaceholder('select-input', t('enter-list-name'));

  const onChange = (option) => {
    setOption(option);
    onGoTo(option);
  };

  return (
    <div className="cw-component-input-or-go-to__input-name_container">
      <div className="cw-component-input-or-go-to__input-name_select">
        <ReactSelect
          id="select-input"
          className="cw-component-select"
          placeholder={t('enter-list-name')}
          arrowRenderer={null}
          noResultsText={null}
          clearable={false}
          options={existLists}
          value={currentOption}
          openOnFocus
          onBlurResetsInput={false}
          onCloseResetsInput={false}
          onChange={onChange}
          onInputChange={onChangeInput}
          optionRenderer={(val) => optionRenderer(t, val, showGoToList)}
          matchProp="label"
        />
      </div>
    </div>
  );
};

InputOrGoTo.propTypes = {
  t: PropTypes.func.isRequired,
  existLists: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onGoTo: PropTypes.func.isRequired,
  showGoToList: PropTypes.bool
};

InputOrGoTo.defaultProps = {
  showGoToList: false
};

export default translate()(InputOrGoTo);
