import request from '@root/lib/request';

class CommissionService {
  #baseUrl = '/api/commission';

  async getCommission() {
    const result = await request.get(this.#baseUrl);

    return result ?? null;
  }
}

export const commissionService = new CommissionService();
