export class ResponsePartnersCompanyDto {
  constructor(props) {
    this.id = props.id;
    this.name = props.name;
    this.email = props.email;
    this.logo = props.logo;
    this.categories = props.categories;
    this.requisites = props.requisites;
    this.contacts = props.contacts;
    this.inn = props.inn;
    this.ogrn = props.ogrn;
    this.isRNP = props.isRNP;
    this.reliability = props.reliability;
    this.groups = props.groups;
    this.note = props.note;
    this.ownerUserId = props.ownerUserId;
    this.isRegistered = props.isRegistered;
  }
}
