export const FORM_INPUTS = {
  firstName: 'firstName',
  lastName: 'lastName',
  middleName: 'middleName',
  company: 'company',
  email: 'email'
};

export const INFO_TYPES = {
  EMAIL: 'EMAIL',
  COMPANY: 'COMPANY'
};

export const ERRORS = {
  [INFO_TYPES.EMAIL]: 'registeredAccount',
  [INFO_TYPES.COMPANY]: 'registeredCompany'
};

export const ACTION_TYPE = {
  SIMPLE_CHANGE_INPUT: 'SIMPLE_CHANGE_INPUT',
  CHANGE_EMAIL: 'CHANGE_EMAIL',
  CHANGE_COMPANY: 'CHANGE_COMPANY'
};

export const DENIED_REGISTRATION_REASONS = {
  NotAllowed: 'NotAllowed',
  UserExists: 'UserExists',
  CompanyExists: 'CompanyExists',
  EmailIsRequired: 'EmailIsRequired',
  InnIsRequired: 'InnIsRequired',
  InvalidEmail: 'InvalidEmail',
  InvalidInn: 'InvalidInn',
  NameIsRequired: 'FirstNameIsRequired',
  SurnameIsRequired: 'FirstNameIsRequired',
  SelectCompanyFromTheList: 'SelectCompanyFromTheList'
};

export const ERROR_REASON = {
  REQUIRED: 'REQUIRED'
};

export const INPUTS_ERROR_REASON = {
  [FORM_INPUTS.firstName]: {
    [ERROR_REASON.REQUIRED]: DENIED_REGISTRATION_REASONS.NameIsRequired
  },
  [FORM_INPUTS.lastName]: {
    [ERROR_REASON.REQUIRED]: DENIED_REGISTRATION_REASONS.SurnameIsRequired
  }
};
