import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation as translate } from 'react-i18next';
import OtherViewer from './OtherViewer';
import * as modalActions from '../../../action-creators/modal';
import * as messageActions from '../../../action-creators/message';
import ViewerHeader from './ViewerHeader';
import ImageViewerFooter from './ViewerFooter';
import { noop } from '../../../utils/utils';
import { resendFile } from '../../ChatWidget/useCases';
import './style.css';

class ModalOtherFileViewer extends React.Component {
  constructor(props) {
    super(props);

    this.resendFile = this.resendFile.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { clearMessages } = this.props;
    // Clear all notifications before open
    clearMessages();
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  onDownload = () => {
    const { file } = this.props;
    const { url, name } = file;
    window.open(`${url}?name=${name}`, '_blank');
  };

  onDelete = async () => {
    const { t, onDeleteFile, hideModalDialog } = this.props;
    if (window.confirm(t('confirm_image_delete'))) {
      await onDeleteFile();
      hideModalDialog();
    }
  };

  convertTo = (size) => {
    if (size > 1024 * 1024) {
      return `${(size / (1024 * 1024)).toFixed(2)} mb`;
    }
    return `${(size / 1024).toFixed(2)} kb`;
  };

  onCopyToStorage = async () => {
    const { file, onCopyToStorageFile } = this.props;
    await onCopyToStorageFile(file.id);
  };

  resendFile() {
    const { file } = this.props;
    this.props.resendFile(file);
  }

  render() {
    const {
      t,
      hideModalDialog,
      isAddToStorageEnable,
      isDeleteEnabled,
      isDownloadEnabled,
      file = {}
    } = this.props;
    const { name = '', addedAt = '', senderName = '', size = 0 } = file;

    return (
      <div className="modal-other-viewer__container">
        <ViewerHeader hideModalDialog={hideModalDialog} />
        <OtherViewer
          name={name}
          size={this.convertTo(size)}
          onDownload={this.onDownload}
        />
        <ImageViewerFooter
          t={t}
          name={name}
          date={addedAt}
          senderName={senderName}
          isShowAddToStorage={isAddToStorageEnable}
          isDeleteEnabled={isDeleteEnabled}
          isDownloadEnabled={isDownloadEnabled}
          onDownload={this.onDownload}
          onDelete={this.onDelete}
          onCopyToStorage={this.onCopyToStorage}
          handleResend={this.resendFile}
        />
      </div>
    );
  }
}

ModalOtherFileViewer.propTypes = {
  t: PropTypes.func.isRequired,
  file: PropTypes.object,
  isDeleteEnabled: PropTypes.bool.isRequired,
  isDownloadEnabled: PropTypes.bool,
  hideModalDialog: PropTypes.func.isRequired,
  clearMessages: PropTypes.func.isRequired,
  isAddToStorageEnable: PropTypes.bool,
  onCopyToStorageFile: PropTypes.func,
  onDeleteFile: PropTypes.func,
  resendFile: PropTypes.func.isRequired
};

ModalOtherFileViewer.defaultProps = {
  isDownloadEnabled: true,
  isAddToStorageEnable: noop,
  onCopyToStorageFile: noop,
  onDeleteFile: noop,
  file: {}
};

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      hideModalDialog: modalActions.hideModalDialog,
      clearMessages: messageActions.clearMessages,
      resendFile
    },
    dispatch
  );

export default connect(null, mapDispatch)(translate()(ModalOtherFileViewer));
