import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation as translate } from 'react-i18next';
import * as ChatWidgetUC from './ChatWidget/useCases';

const SuggestionBtn = (props) => {
  const { t, menuOpened = true, openSupportChat } = props;

  return (
    <button
      type="button"
      className="suggestion-btn-wrap"
      onClick={() => openSupportChat()}>
      <img
        src="/img/feedback.svg"
        width="18"
        style={{ marginRight: 13 }}
        alt="feedback"
      />
      {menuOpened && t('any_question')}
    </button>
  );
};

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      openSupportChat: ChatWidgetUC.openSupportChat
    },
    dispatch
  )
)(translate(['ui'], { wait: true })(SuggestionBtn));
