import React from 'react';
import cn from 'classnames';
import { Typography, Icon } from '@link/react-components';
import { useTranslate } from '@hooks';
import { translate } from '../../Lobby.helpers';
import c from './SomethingBroke.module.css';

export const SomethingBroke = ({ className }) => {
  const t = useTranslate();

  return (
    <div className={cn(className, c.container)}>
      <div className={c.content}>
        <Icon iconName="call-failed" width={43} height={42} />
        <Typography variant="body1Reg" align="center">
          {translate(t, 'failed-connect-text')}
        </Typography>
      </div>
    </div>
  );
};
