import React, { useEffect, useState, useMemo } from 'react';
import {
  Button,
  InputCounter,
  SearchInput,
  useWizard
} from '@link/react-components';
import { useList, useTranslate, useDebounce } from '@hooks';
import { Container, Header, Content, Footer } from '../../layout';
import { NoSearchResult } from '../../noSearchResult';
import { Row } from '../../row';
import { getTranslate } from '../../../utils';
import { config } from '../../../config';
import { useGroupActions } from '../../../hooks';

export function CreateGroup() {
  const t = useTranslate();
  const { getState, previous, setState } = useWizard();

  const { initialized, companies } = getState();

  const [search, setSearch] = useState('');
  const [title, setTitle] = useState('');
  const [selectedCompanies, selectedCompaniesActions] = useList([]);
  const [visibleCompanies, setVisibleCompanies] = useState(companies || []);

  const { loading, create } = useGroupActions();

  const onSearchCompanies = useDebounce((searchValue) => {
    const searchRegExp = new RegExp(searchValue, 'i');
    const selectedCompanies = companies.filter((company) =>
      searchRegExp.test(`${company.label}`)
    );
    setVisibleCompanies(selectedCompanies);
  }, config.searchDebounce);

  useEffect(() => {
    if (initialized && companies?.length > 0) {
      setVisibleCompanies(companies);
    }
  }, [initialized, companies]);

  useEffect(() => {
    onSearchCompanies(search);
  }, [search]);

  function translate(tKey, params) {
    return getTranslate(t, `createGroup.${tKey}`, params);
  }

  async function onSubmit() {
    await create({ name: title, companiesList: selectedCompanies });
    previous();
  }

  function getHint() {
    if (selectedCompanies.length < config.minCompaniesInGroup) {
      return translate('hints.notEnoughCompanies');
    }
  }

  function isCompanyDisabled(selectedCompaniesList, companyId) {
    return selectedCompaniesList.includes(companyId)
      ? false
      : selectedCompaniesList.length === config.maxCompaniesInGroup;
  }

  function disableSubmit() {
    return (
      title.trim().length < config.minGroupNameLength ||
      selectedCompanies.length < config.minCompaniesInGroup
    );
  }

  useEffect(() => {
    setState({ selectedCompanies });
  }, [selectedCompanies, setState]);

  return (
    <Container>
      <Header>
        <InputCounter
          value={title}
          onChange={setTitle}
          label={translate('inputs.title.label')}
          total={config.maxGroupNameLength}
        />
        <SearchInput
          defaultValue={search}
          onSearch={setSearch}
          onChange={setSearch}
          onCleanUp={() => setSearch('')}
          placeholder={translate('inputs.search.placeholder')}
        />
      </Header>
      <Content>
        {Boolean(!visibleCompanies.length && search) && (
          <NoSearchResult tKey="createGroup" />
        )}
        {useMemo(
          () =>
            visibleCompanies.map((company) => (
              <Row
                key={company.value}
                value={company.value}
                checked={selectedCompanies.includes(company.value)}
                onToggle={selectedCompaniesActions.toggle}
                label={company.label}
                checkDisabled={isCompanyDisabled(
                  selectedCompanies,
                  company.value
                )}
              />
            )),
          [visibleCompanies, selectedCompanies]
        )}
      </Content>
      <Footer hint={getHint()}>
        <Button
          mode="primary"
          loading={loading}
          disabled={disableSubmit()}
          onClick={onSubmit}>
          {translate('actions.submit')}
        </Button>
      </Footer>
    </Container>
  );
}
