import React, { useCallback } from 'react';

import { useTranslate, useActions } from '@hooks';

import { Button, Modal, Typography } from '@link/react-components';

import * as modalActions from '@root/action-creators/modal';

export const ModalGenerateApiToken = ({ onBeforeGenerate, generateToken }) => {
  const t = useTranslate();

  const translate = (key) => t(`modules.modalGenerateToken.${key}`);

  const handleModalClose = useActions(modalActions.hideModalDialog);

  const handleGenerate = useCallback(async () => {
    onBeforeGenerate?.();
    handleModalClose();
    generateToken();
  }, []);

  return (
    <Modal open onClose={handleModalClose}>
      <Modal.Header>{translate('header')}</Modal.Header>
      <Modal.Content>
        <Typography variant="body1Reg">{translate('warningText')}</Typography>
      </Modal.Content>
      <Modal.Footer align="right">
        <Button onClick={handleModalClose} mode="text">
          {translate('cancel')}
        </Button>
        <Button onClick={handleGenerate}>{translate('generateButton')}</Button>
      </Modal.Footer>
    </Modal>
  );
};
