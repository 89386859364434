import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation as translate } from 'react-i18next';
import CWSAvatar from '../../Avatar';
import Checkbox from '../../Checkbox';
import SearchBlock from '../../SearchBlock';
import './style.css';

const renderAvatar = (props) => {
  const { type } = props;

  if (type === 'Dialog') {
    const { avatar } = props;
    return (
      <CWSAvatar size={40} info={{ src: avatar, type: 'user', alt: '' }} />
    );
  }

  if (type === 'Channel') {
    const { name, color } = props;
    return (
      <CWSAvatar size={40} info={{ type: 'color-char', text: name, color }} />
    );
  }

  if (type === 'Topic') {
    const { name, color } = props;
    return (
      <CWSAvatar size={40} info={{ type: 'color-char', text: name, color }} />
    );
  }

  return <span />;
};

const renderTitles = (props) => {
  const { type, alreadyAdded } = props;

  const renderMark = () =>
    alreadyAdded ? (
      <img
        className="cw-modal-component__dialogs-list_row-titles_user-name-mark"
        src="/img/mark-blue.svg"
        alt="mark"
      />
    ) : (
      <span />
    );

  if (type === 'Dialog') {
    const { name = '', companyName = '' } = props;
    return (
      <>
        <div className="cw-modal-component__dialogs-list-list_row-titles_user-name">
          {name}
          {renderMark()}
        </div>
        <div className="cw-modal-component__dialogs-list-list_row-titles_company-name">
          {companyName}
        </div>
      </>
    );
  }

  if (type === 'Channel') {
    const { t, name, membersCount } = props;
    return (
      <>
        <div className="cw-modal-component__dialogs-list-list_row-titles_channel-name">
          <img src="/img/chat-widget/channel.svg" alt="channel" />
          <span>{name}</span>
          {renderMark()}
        </div>
        <div className="cw-modal-component__dialogs-list-list_row-titles_channel-members-count">
          {`${t('channel.amount_members')}: ${membersCount}`}
        </div>
      </>
    );
  }

  if (type === 'Topic') {
    const { t, name, membersCount } = props;
    return (
      <>
        <div className="cw-modal-component__dialogs-list-list_row-titles_channel-name">
          <img src="/img/chat-widget/topic.svg" alt="channel" />
          <span>{name}</span>
          {renderMark()}
        </div>
        <div className="cw-modal-component__dialogs-list-list_row-titles_channel-members-count">
          {`${t('channel.amount_members')}: ${membersCount}`}
        </div>
      </>
    );
  }

  return <span />;
};

const Row = (props) => {
  const { id, checkedList, alreadyAdded, onCheckToggle } = props;

  const isChecked = checkedList.includes(id);

  return (
    <div key={id} className="cw-modal-component__dialogs-list_row">
      <div className="cw-modal-component__dialogs-list_row-avatar">
        {renderAvatar(props)}
      </div>
      <div className="cw-modal-component__dialogs-list_row-titles">
        {renderTitles(props)}
      </div>
      <div className="cw-modal-component__dialogs-list_row-checkbox">
        <Checkbox
          onClick={() => onCheckToggle(id, !isChecked)}
          checked={isChecked}
          disabled={alreadyAdded}
        />
      </div>
    </div>
  );
};

const DialogsList = ({ t, dialogList, checkedList, onCheckToggle }) => {
  const [search, setSearch] = useState('');

  const filterBySearch = (contact) => {
    const { name = '', companyName = '' } = contact;
    const candidate = `${name}${companyName}`.toLowerCase();
    return candidate.includes(search.toLowerCase());
  };

  const renderContactList = () => {
    if (dialogList && dialogList.length) {
      return dialogList
        .filter(filterBySearch)
        .map((dialog) => (
          <Row
            key={dialog.id}
            t={t}
            checkedList={checkedList}
            onCheckToggle={onCheckToggle}
            {...dialog}
          />
        ));
    }
    return <span />;
  };

  return (
    <div className="cw-modal-component__dialogs-list-container">
      <div className="cw-modal-component__dialogs-list-search">
        <SearchBlock
          placeholder={t('placeholder-enter-first-last-company-name')}
          value={search}
          onChange={setSearch}
          width={525}
        />
      </div>
      <div className="cw-modal-component__dialogs-list-list">
        {renderContactList()}
      </div>
    </div>
  );
};

DialogsList.propTypes = {
  t: PropTypes.func.isRequired,
  dialogList: PropTypes.arrayOf(PropTypes.object).isRequired,
  checkedList: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCheckToggle: PropTypes.func.isRequired
};

export default translate()(DialogsList);
