import { isEmpty } from 'ramda';
import { nanoid } from 'nanoid';
import { isMessengerService as checkIsMessengerService } from '../../../../../lib/goodwix';
import { MODES } from '../../../../../useCases/files';

function makeFiles(files) {
  return files.map((file) => ({ file, id: nanoid() }));
}

export const attachLocalFilesCase = async (
  {
    getOpenedChatId,
    getChatInput,
    setInputLoadingFilesIds,
    setSuccessMessage,
    setInfoMessage,
    setErrorMessage,
    attachUnsentFile,
    sendWaitingMessages,
    errorHandler,
    addFileToMessage,
    removeFileId,
    getUnsentFiles,
    setInputFiles,
    setFocusOnInputMessage,
    removeInputLoadingFileId,
    getWaitingMessages,
    goToFileStorage,
    filesLoadHandler,
    prepareFiles = makeFiles,
    awaiter = setTimeout
  },
  { chatId, files, isMessengerService = checkIsMessengerService() }
) => {
  const input = getChatInput();
  const preparedFiles = prepareFiles(files);

  await filesLoadHandler({
    mode: MODES.Exclude,
    files: preparedFiles,
    attachedFiles: input.files,
    maxFiles: input.maxFiles,
    beforeLoading: initBeforeLoading(
      { setInputLoadingFilesIds, setInfoMessage },
      { preparedFiles }
    ),
    loadFile: initLoadFile(
      {
        getOpenedChatId,
        attachUnsentFile,
        sendWaitingMessages,
        removeInputLoadingFileId,
        setInputFiles,
        setFocusOnInputMessage,
        getUnsentFiles,
        getWaitingMessages,
        addFileToMessage,
        removeFileId,
        awaiter
      },
      { chatId }
    ),
    onError: initOnError({ sendWaitingMessages, errorHandler }),
    onLoaded: initOnLoaded({
      isMessengerService,
      sendWaitingMessages,
      setSuccessMessage,
      setErrorMessage,
      goToFileStorage
    }),
    includeToAllowExt: ['pptx', 'ppt', 'bmp'],
    maxSize: 104857600
  });
};

export function initBeforeLoading(
  { setInputLoadingFilesIds, setInfoMessage },
  { preparedFiles }
) {
  return () => {
    setInputLoadingFilesIds(getFilesIds());
    setInfoMessage({ key: 'File upload started' });
  };

  function getFilesIds() {
    return preparedFiles.map((file) => file.id);
  }
}

export function initLoadFile(
  {
    getOpenedChatId,
    attachUnsentFile,
    addFileToMessage,
    removeFileId,
    getUnsentFiles,
    setInputFiles,
    setFocusOnInputMessage,
    getWaitingMessages,
    sendWaitingMessages,
    removeInputLoadingFileId,
    awaiter
  },
  { chatId }
) {
  return async ({ id: fileId, file }) => {
    try {
      const uploadedFile = await attachUnsentFile(chatId, file.name, file);
      await setFiles(fileId, uploadedFile);
      endLoadingFile(fileId);
    } catch (e) {
      console.error(e);
      endLoadingFile(fileId);
      throw e;
    }
  };

  async function setFiles(fileId, uploadedFile) {
    if (isFileForWaitingMessage(fileId)) {
      addFileToMessage({ fileId, file: uploadedFile });
    } else {
      const openedChatId = getOpenedChatId();
      if (chatId === openedChatId) {
        const uploadedFiles = await getUnsentFiles(chatId);
        setInputFiles(uploadedFiles);
        awaiter(setFocusOnInputMessage, 0);
      }
    }
  }

  function endLoadingFile(fileId) {
    if (isFileForWaitingMessage(fileId)) {
      removeFileId(fileId);
      sendWaitingMessages();
    } else {
      removeInputLoadingFileId(fileId);
    }
  }

  function isFileForWaitingMessage(fileId) {
    const waitingMessages = getWaitingMessages();

    return waitingMessages.some((waitingMessage) =>
      waitingMessage.waitingFiles.includes(fileId)
    );
  }
}

export function initOnError({ sendWaitingMessages, errorHandler }) {
  return (key, params) => {
    sendWaitingMessages();
    errorHandler(key, params);
  };
}

export function initOnLoaded({
  isMessengerService,
  sendWaitingMessages,
  setSuccessMessage,
  setErrorMessage,
  goToFileStorage
}) {
  return (errors = []) => {
    sendWaitingMessages();

    setFinishMessage(errors);
  };

  function setFinishMessage(errors) {
    if (!isEmpty(errors)) {
      setErrorMessage(getMessage(errors[0]));
    } else {
      setSuccessMessage({ key: 'File upload finished' });
    }
  }

  function getMessage(key) {
    if (!isMessengerService) return { key };

    if (key === 'Storage space limit') {
      return {
        key: 'm_storage_space_limit',
        linkKey: 'go_to_storage',
        linkClickHandler: goToFileStorage
      };
    }

    return { key };
  }
}
