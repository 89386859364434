import { TAX_PERCENT } from '@root/components/PurchaseRequests/constants';

export class ProductsPriceCalculator {
  #vat = TAX_PERCENT;

  constructor(products) {
    this.products = products;
  }

  #round(value, precision) {
    return Math.ceil(value * 10 ** precision) / 10 ** precision;
  }

  #calcSum() {
    return this.products.reduce(
      (acc, prev) => acc + (prev.price * prev.count || 0),
      0
    );
  }

  #calcVat() {
    return this.#calcSum() * (this.#vat / (100 + this.#vat));
  }

  total(precision = 2) {
    return this.#round(this.#calcSum(), precision);
  }

  vat(precision = 2) {
    return this.#round(this.#calcVat(), precision);
  }
}
