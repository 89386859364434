import { useMemo, useState } from 'react';

export const useInfoBlock = () => {
  const [messages] = useState(new Set());
  const [, forceUpdate] = useState({});
  const [size, setSize] = useState(messages.size);

  return useMemo(
    () => ({
      add: (newMessage) => {
        if (messages.has(newMessage)) {
          messages.delete(newMessage);
          messages.add(newMessage);
          forceUpdate({});
        } else {
          messages.add(newMessage);
          setSize((prevState) => prevState + 1);
        }
      },
      delete: (message) => {
        messages.delete(message);
        setSize((prevState) => prevState - 1);
      },
      has: (message) => messages.has(message),
      clear: () => {
        messages.clear();
        setSize(0);
      },
      last: () => Array.from(messages).pop(),
      size
    }),
    []
  );
};
