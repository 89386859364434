export const FormErrors = {
  required: 'required',
  wrongEmailFormat: 'wrongEmailFormat',
  wrongValueFormat: 'wrongValueFormat',
  wrongPhoneNumberFormat: 'wrongPhoneNumberFormat'
};

const generateTKey = (text) => `modules.forms.error.${text}`;
export const FormErrorsText = {
  [FormErrors.required]: generateTKey('required'),
  [FormErrors.wrongEmailFormat]: generateTKey('wrongEmailFormat'),
  [FormErrors.wrongValueFormat]: generateTKey('wrongValueFormat'),
  [FormErrors.wrongPhoneNumberFormat]: generateTKey('wrongPhoneNumberFormat')
};
