import request from '../lib/request';

class HintService {
  #baseUrl = '/api/hint';

  /**
   Fetch hints for user by key
   @param {string[]} keys
   @returns {Promise<Array>}
   */
  async getUserHints(keys = []) {
    return request.post(`${this.#baseUrl}/userHints`, { keys });
  }

  async view({ hintId, hintName }) {
    return request.patch(`${this.#baseUrl}/view`, { hintId, hintName });
  }
}

export const hintService = new HintService();
