import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import MessengerSubHeader from './MessengerSubHeader';
import MessengerBody from './MessengerBody';
import MessengerFooter from './MessengerFooter';
import { getCurrentChatMode } from '../../ChatWidget/getter';

const Messenger = () => {
  const currentMode = useSelector(getCurrentChatMode);

  return (
    <Container>
      <MessengerSubHeader currentMode={currentMode} />
      <MessengerBody currentMode={currentMode} />
      {currentMode === 'none' && <MessengerFooter />}
    </Container>
  );
};

export default React.memo(Messenger);

const Container = styled.div`
  position: relative;
  width: 100%;
  min-height: var(--app-height, 100%);
  height: 300px;
  display: flex;
  flex-direction: column;
  background: ${(p) => p.theme.colors.white};
  box-shadow: 0 6px 10px rgba(118, 123, 146, 0.2);
  border-radius: 8px;
  overflow: hidden;
`;
