import { path } from 'ramda';
import { Emoji, getEmojiDataFromNative } from 'emoji-mart';
import data from 'emoji-mart/data/apple.json';
import { isFunction } from '../../utils/utils';

const replaceAll = (str, find, replace) =>
  str.replace(new RegExp(find, 'g'), replace);

const getSpanEl = (emoji, size = 20) => {
  const { id, skin } = emoji;
  const emojiSpanEl = Emoji({
    emoji: id,
    set: 'apple',
    size,
    skin,
    html: true
  });

  if (!emojiSpanEl) return '';

  return emojiSpanEl;
};

const getReactEl = (emoji, size = 20) => {
  const { id, skin } = emoji;
  const symbol = Emoji({ emoji: id, set: 'apple', size, skin });

  if (!symbol) return '';

  return symbol;
};

const emojiReqexp =
  /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?(?:\u200d(?:[^\ud800-\udfff]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff])[\ufe0e\ufe0f]?(?:[\u0300-\u036f\ufe20-\ufe23\u20d0-\u20f0]|\ud83c[\udffb-\udfff])?)*/g; // eslint-disable-line

const getAllEmojisFromText = (text) => text.match(emojiReqexp);
const getTextWithoutEmoji = (text) =>
  text
    .replace(/<[^>]+>/g, '')
    .replace(emojiReqexp, '')
    .trim();

const getEmojiSize = (textLength, emojiCount) => {
  const onlyOneEmoji = !textLength && emojiCount === 1;
  const onlyEmojiInMsg = !textLength && emojiCount > 1;
  if (onlyOneEmoji) return 64;
  if (onlyEmojiInMsg) return 32;
  return 20;
};

const replaceAllTextEmojis = (text, size) => {
  if (!text || typeof text === 'object') return text;
  let allEmojis = getAllEmojisFromText(text);
  const textWithoutEmoji = getTextWithoutEmoji(text);
  if (allEmojis) {
    const emojiSize =
      size || getEmojiSize(textWithoutEmoji.length, allEmojis.length);
    allEmojis = [...new Set(allEmojis)]; // remove duplicates
    allEmojis.forEach((native) => {
      const emoji = getEmojiDataFromNative(native, 'apple', data);
      if (!emoji) return;
      text = replaceAll(text, native, getImage(emoji, emojiSize)); // eslint-disable-line
    });
  }
  return text;
};

const makeTransparent = () => {
  const canvas = document.createElement('canvas');

  const context = canvas.getContext('2d');

  context.clearRect(0, 0, canvas.width, canvas.height);

  return canvas.toDataURL();
};

const getImage = (emoji, size) => {
  const emojiSpanEl = getReactEl(emoji, size);
  if (!emojiSpanEl) return '';

  const styles = emojiSpanEl.props.children.props.style;
  const style = Object.keys(styles).reduce((acc, key) => {
    if (!Number.isNaN(+styles[key])) {
      acc += `${key}: ${styles[key]}px;`; // eslint-disable-line
    } else if (key === 'backgroundImage') {
      acc += `background-image: ${styles[key]};`; // eslint-disable-line
    } else if (key === 'backgroundPosition') {
      acc += `background-position: ${styles[key]};`; // eslint-disable-line
    } else if (key === 'backgroundSize') {
      acc += `background-size: ${styles[key]};`; // eslint-disable-line
    } else {
      acc += `${key}: ${styles[key]};`; // eslint-disable-line
    }
    return acc;
  }, '');
  return `<img style="${style}" data-emoji="${
    emoji.native
  }" src="${makeTransparent()}" />`; // eslint-disable-line
};

const getText = (t, list, isDialog) => {
  const tr = (txt) => t(`chat-widget.chat.${txt}`);
  if (!list) {
    return `${tr('typing-one')}`;
  }
  const listLength = list.length;
  if (listLength === 1) {
    return `${isDialog ? '' : list[0].userName} ${tr('typing-one')}`.trim();
  }
  if (listLength === 2) {
    return `${list[0].userName} ${tr('and')} ${list[1].userName} ${tr(
      'typing-few'
    )}`;
  }
  return `${tr('several-typing')}`;
};

export const getEmojiImage = (emoji, size) => getImage(emoji, size);
export const getEmojiMarkup = (emoji, size) => getSpanEl(emoji, size);
export const getEmojiReactSymbol = (emoji, size) => getReactEl(emoji, size);
export const replaceEmojiToMarkup = (txt, size) =>
  replaceAllTextEmojis(txt, size);
export const formatTypingText = getText;

const BASE_DEPTH_VALUE = 1;

export const formatListsToHierarchicalView = (lists) => {
  const mainLists = lists.filter((t) => t.parentId === null || t.system);
  const getChilds = (ids) => lists.filter((t) => ids.includes(`${t.id}`));
  const setChildsList = (list, depth) => {
    if (list.system) {
      list.childsIds = []; // eslint-disable-line
      list.childsList = []; // eslint-disable-line
      list.depth = depth; // eslint-disable-line
      return list;
    }
    if (list.childsIds.length > 0) {
      list.childsList = getChilds(list.childsIds); // eslint-disable-line
      list.depth = depth; // eslint-disable-line
      if (list.childsList.length > 0) {
        list.childsList.forEach((l) => setChildsList(l, depth + 1));
      }
    } else {
      list.childsList = []; // eslint-disable-line
      list.depth = depth; // eslint-disable-line
    }
    return list;
  };
  return mainLists.map((list) => setChildsList(list, BASE_DEPTH_VALUE));
};

export const mapHierarchicalView = (fn, hierarchy) =>
  hierarchy.map((child) => {
    if (child.childsList.length > 0) {
      return {
        ...fn(child),
        childsList: mapHierarchicalView(fn, child.childsList)
      };
    }
    return fn(child);
  });

export const searchTree = (row, matchingDepth) => {
  if (row.depth === matchingDepth) {
    return row;
  }
  if (row.childsList.length > 0) {
    let i;
    let result = null;
    for (i = 0; result === null && i < row.childsList.length; i++) {
      // eslint-disable-next-line no-unused-vars
      result = searchTree(row.childsList[i], matchingDepth);
    }
    return result;
  }
  return null;
};

export const linkToProfileHandler = (event, showProfile) => {
  event.persist();
  if (!isFunction(showProfile)) return;

  if (
    event.target.dataset.type === 'mention' &&
    event.target.dataset.employeeId
  ) {
    showProfile(event.target.dataset.employeeId);
  }
};

export const safetyServiceWorkerPostMessage = (msg) => {
  const postMessage = path(
    ['serviceWorker', 'controller', 'postMessage'],
    navigator
  );
  if (postMessage) {
    postMessage.call(navigator.serviceWorker.controller, msg);
  }
};

export const interpreterMessageKey = (message) => {
  if (message.msg === 'greeting' || message.msg.tkey === 'greeting') {
    return 'support_greeting_message';
  }
  if (
    message.msg === 'messenger_greeting' ||
    message.msg.tkey === 'messenger_greeting'
  ) {
    return 'support_messenger_greeting_message';
  }
  if (message.msg === 'answerphone' || message.msg.tkey === 'answerphone')
    return 'support_back_message';
  if (
    message.msg === 'istock_link_faq' ||
    message.msg.tkey === 'istock_link_faq'
  )
    return 'support_faq_istock_link_message';
  return `not translate for with key: ${message.msg}`;
};
