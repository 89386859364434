import React, { memo, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useTranslate } from '@hooks';
import { InputCounter, Typeahead, Typography } from '@link/react-components';
import { InfoBlock } from '../../../../components/InfoBlock';
import { FORM_ID, TRANSLATE_PATH } from '../../lib/constants';
import { CompanyLogo } from '../CompanyLogo/CompanyLogo';
import { TextArea } from '../../../../components/FormControls';
import {
  getCountries,
  getRegions
} from '../../../../modules/dictionaries/storage';
import * as getters from '../../../../modules/dictionaries/getters';
import * as mappers from '../../lib/mapper';
import { validator } from '../../lib/validator';
import styles from './FillCompanyDataForm.module.css';

export const FillCompanyDataForm = ({ data, handleSubmit, disableSubmit }) => {
  const [currentCountry, setCurrentCountry] = useState(
    mappers.toInitialCountry(data)
  );
  const [currentRegion, setCurrentRegion] = useState(
    mappers.toInitialRegion(data)
  );

  const t = useTranslate();
  const translate = (key) => t(`${TRANSLATE_PATH}.${key}`);

  const dispatch = useDispatch();
  const countries = useSelector(getters.getCountries);
  const regions = useSelector(getters.getRegions);

  const initialValues = mappers.toClientFormData(data);
  const initialLogo = mappers.toInitialLogo(data);
  const validate = validator(translate);

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: handleSubmit
  });

  disableSubmit(formik.values);

  const countryProvider = useMemo(
    () => ({
      async get(query) {
        const countriesThunk = getCountries({ search: query });
        const countries = await countriesThunk(dispatch);
        return countries.map((country) => ({
          key: country.label,
          value: country.label
        }));
      }
    }),
    []
  );

  const regionProvider = useMemo(
    () => ({
      async get(query) {
        if (!formik.values.country) {
          return;
        }
        const regionsThunk = getRegions({
          countryId: formik.values.country,
          search: query
        });
        const regions = await regionsThunk(dispatch);
        return regions.map((region) => ({
          key: region.label,
          value: region.label
        }));
      }
    }),
    [formik.values.country]
  );

  const handleCountryChange = (value) => {
    setCurrentCountry(value);
    if (value === '') {
      setCurrentCountry('');
      setCurrentRegion('');
      formik.values.country = '';
      formik.values.region = '';
    }
  };

  const handleRegionChange = (value) => {
    setCurrentRegion(value);
    if (value === '') {
      setCurrentRegion('');
      formik.values.region = '';
    }
  };

  const resetCountry = () => {
    const countryId = formik.values.country;
    if (countryId) {
      const selectedCountry = countries.find(
        (country) => country.value === countryId
      );
      setCurrentCountry(selectedCountry.label);
    } else {
      setCurrentCountry('');
      setCurrentRegion('');
    }
  };

  const resetRegion = () => {
    const regionId = formik.values.region;
    if (regionId) {
      const selectedRegion = regions.find(
        (region) => region.value === regionId
      );
      setCurrentRegion(selectedRegion.label);
    } else {
      setCurrentRegion('');
    }
  };

  const handleCountrySelect = (value) => {
    const selectedCountry = countries.find(
      (country) => country.label === value
    );
    formik.setFieldValue('country', selectedCountry.value);
  };

  const handleRegionSelect = (value) => {
    const selectedRegion = regions.find((region) => region.label === value);
    formik.setFieldValue('region', selectedRegion.value);
  };

  return (
    <form onSubmit={formik.handleSubmit} id={FORM_ID}>
      <InfoBlock text={translate('infoBlock')} className={styles.infoBlock} />
      <Typography variant="h2" className={styles.title}>
        {translate('requisitesTitle')}
      </Typography>
      <InputCounter
        label={translate('actualAddress')}
        total={255}
        id="actualAddress"
        name="actualAddress"
        value={formik.values.actualAddress}
        onInputChange={formik.handleChange}
        className={styles.fullWidthInput}
      />
      <InputCounter
        label={translate('fullBankName')}
        total={255}
        id="bankName"
        name="bankName"
        value={formik.values.bankName}
        onInputChange={formik.handleChange}
        className={styles.fullWidthInput}
      />
      <div className={styles.accBicContainer}>
        <InputCounter
          label={translate('correspondentAccount')}
          total={32}
          id="account"
          name="account"
          value={formik.values.account}
          onInputChange={formik.handleChange}
          className={styles.account}
          valid={!formik.errors.account}
          errorText={formik.errors.account}
        />
        <InputCounter
          label={translate('bic')}
          total={9}
          id="bankBic"
          name="bankBic"
          value={formik.values.bankBic}
          onInputChange={formik.handleChange}
          className={styles.bic}
          valid={!formik.errors.bankBic}
          errorText={formik.errors.bankBic}
        />
      </div>
      <Typography variant="h2" className={styles.title}>
        {translate('logoDescriptionTitle')}
      </Typography>
      <div className={styles.logoDescBlock}>
        <CompanyLogo
          src={formik.values.logo ?? initialLogo}
          id="logo"
          name="logo"
          handleSaveLogo={formik.setFieldValue}
        />
        <TextArea
          id="description"
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          className={styles.description}
        />
      </div>
      <Typography variant="h2" className={styles.title}>
        {translate('contactsTitle')}
      </Typography>
      <Typeahead
        label={translate('country')}
        containerClassName={styles.country}
        initialValue={currentCountry}
        onSelect={handleCountrySelect}
        onChange={handleCountryChange}
        onBlur={resetCountry}
        provider={countryProvider}
      />
      <Typeahead
        label={translate('region')}
        containerClassName={styles.region}
        initialValue={currentRegion}
        onSelect={handleRegionSelect}
        onChange={handleRegionChange}
        onBlur={resetRegion}
        provider={regionProvider}
        disabled={!formik.values.country}
      />
      <InputCounter
        label={translate('postalAddress')}
        total={255}
        id="postalAddress"
        name="postalAddress"
        value={formik.values.postalAddress}
        onInputChange={formik.handleChange}
        className={styles.fullWidthInput}
      />
    </form>
  );
};
