import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation as translate } from 'react-i18next';

const EmptyDialogs = ({ t }) => (
  <div className="cw-modal-component__added-contacts-list-empty">
    <img src="/img/discussions-image.svg" alt="contact" />
    <div>{t('list-is-empty')}</div>
  </div>
);

EmptyDialogs.propTypes = {
  t: PropTypes.func.isRequired
};

export default translate()(EmptyDialogs);
