import { Dialog } from '@mui/material';
import cn from 'classnames';
import { WithClassName } from '@/components/common.types';
import { Typography } from '@/components/Typography';
import { PropsWithChildren, useCallback, MouseEvent, useMemo } from 'react';
import { ButtonIcon } from '@/components/ButtonIcon';
import styles from './Modal.module.scss';
import {
  ModalProps,
  ModalHeaderProps,
  ModalContentProps,
  ModalActionsProps
} from './Modal.types';

type CloseReason = 'escapeKeyDown' | 'backdropClick';

export function Modal({
  children,
  open,
  onClose,
  className,
  disableBackdropClick = false,
  alignmentTop = false
}: PropsWithChildren<ModalProps>) {
  const classes = useMemo(
    () =>
      alignmentTop
        ? {
            root: styles.root
          }
        : { root: styles.paper },
    [alignmentTop]
  );

  const close = useCallback(
    (_event: MouseEvent<HTMLButtonElement>, reason?: CloseReason) => {
      if (disableBackdropClick && reason && reason === 'backdropClick') {
        return;
      }
      if (typeof onClose === 'function') {
        onClose();
      }
    },
    [onClose, disableBackdropClick]
  );

  return (
    <Dialog
      classes={classes}
      data-testid="modal"
      onClose={close}
      open={open}
      fullScreen={false}
      fullWidth={false}
      disableEscapeKeyDown={false}>
      <div
        className={cn(styles.container, className)}
        data-testid="modal-container">
        <ButtonIcon
          iconName="close"
          onClick={onClose}
          className={styles.closeBtn}
        />
        {children}
      </div>
    </Dialog>
  );
}

export function ModalHeader({
  children,
  className
}: PropsWithChildren<ModalHeaderProps>) {
  return (
    <div className={cn(styles.header, className)} data-testid="modal-header">
      <Typography variant="h2">{children}</Typography>
    </div>
  );
}

export function ModalContent({
  children,
  className
}: PropsWithChildren<ModalContentProps>) {
  return (
    <div
      className={cn(styles.content, className, styles.specialTextColor)}
      data-testid="modal-content">
      {children}
    </div>
  );
}

type ActionsAlign = 'right' | 'left' | 'center' | 'space-between';

export interface ModalFooterProps extends WithClassName {
  align?: ActionsAlign;
}

export function ModalFooter({
  children,
  className,
  align = 'right'
}: PropsWithChildren<ModalFooterProps>) {
  return (
    <div
      className={cn(styles.footer, styles[align], className)}
      data-testid="modal-footer">
      {children}
    </div>
  );
}

export function ModalActions({
  children,
  className
}: PropsWithChildren<ModalActionsProps>) {
  return (
    <div className={cn(styles.actions, className)} data-testid="modal-actions">
      {children}
    </div>
  );
}

Modal.Content = ModalContent;
Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;
Modal.Actions = ModalActions;
