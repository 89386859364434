import { isEmpty } from 'ramda';
import request from '../../../lib/request';
import { actions } from './reducer';
import { actions as requestActions } from '../MyRequests/reducer';
import * as prActions from '../../../action-creators/purchaseRequests';
import { addQueryParam } from '../../../utils/utils';
import { hintService, responseService } from '../../../api';
import { ResponseHints } from '../constants';

export const viewRequestResponse =
  (requestId, responseId) => async (dispatch) => {
    const response = await request.post(
      `/api/requests/${requestId}/respond/${responseId}/view`
    );
    dispatch(
      requestActions.changeResponseStatus({
        requestId,
        responseId,
        status: response.status
      })
    );
    dispatch(
      requestActions.viewResponse({
        requestId,
        responseId
      })
    );
    dispatch(
      prActions.setResponseStatus({ responseId, status: response.status })
    );
  };

export const getRequestResponse = (responseId) => async (dispatch) => {
  const result = await Promise.allSettled([
    responseService.getResponse(responseId),
    hintService.getUserHints([ResponseHints.createRouteForResponse])
  ]);

  const [res1, res2] = result;

  const response = { ...res1.value, hints: res2.value || null };

  dispatch(actions.getResponseById({ response }));
};

export const acceptRespond =
  ({ requestId, responseId }) =>
  async (dispatch) => {
    await request.post(
      `/api/requests/${requestId}/respond/${responseId}/accept`
    );
    dispatch(actions.acceptResponse({ requestId, responseId }));
  };

export const cancelRespond =
  ({ requestId, responseId }) =>
  async (dispatch) => {
    await request.post(
      `/api/requests/${requestId}/respond/${responseId}/cancel`
    );
    dispatch(actions.cancelResponse({ requestId, responseId }));
  };

export const refuseRespond =
  ({ requestId, responseId, text = '' }) =>
  async (dispatch) => {
    await request.post(
      `/api/requests/${requestId}/respond/${responseId}/reject`,
      { text }
    );
    dispatch(actions.rejectResponse({ responseId }));
  };

export const sendToRework =
  ({ requestId, responseId, text = '' }) =>
  async (dispatch) => {
    await request.post(
      `/api/requests/${requestId}/respond/${responseId}/rework`,
      { text }
    );
    dispatch(actions.sendToRework({ responseId }));
  };

export const getAllRequestsResponses =
  ({
    search = '',
    limit = 20,
    offset = 0,
    order,
    direction = 'ASC',
    selectedCategoriesId = [],
    filters = [],
    selectedStatus = []
  }) =>
  async (dispatch) => {
    let baseUrl = `/api/requests/responses/all?limit=${limit}&offset=${offset}`;

    if (search) {
      baseUrl += `&search=${search}`;
    }

    if (order) {
      baseUrl += `&orderBy=${order}&direction=${direction}`;
    }

    if (selectedCategoriesId.length > 0) {
      baseUrl += selectedCategoriesId.reduce(
        (str, id) => `${str}&selCatsId[]=${id}`,
        ''
      );
    }

    dispatch(actions.setIsUploaded(false));

    try {
      const data = await request.post(baseUrl, { filters, selectedStatus });
      dispatch(actions.getAllResponses(data));
    } catch (e) {
      console.error(e);
      dispatch(actions.setIsUploaded(true));
    }
  };

export const getAllSuppliersOfMyRequest = () => async (dispatch) => {
  const suppliers = await request.get('/api/requests/responses/all-suppliers');
  dispatch(actions.getAllSuppliersOfMyRequests({ suppliers }));
};

export const getCountries =
  (search = '', countryId, lang) =>
  async (dispatch) => {
    let baseUrl = `/api/requests/countries`;
    if (search) {
      baseUrl += `?search=${search}`;
    }
    if (countryId) {
      baseUrl += `${search ? '&' : '?'}countryId=${countryId}`;
    }
    if (lang) {
      baseUrl += `${search || countryId ? '&' : '?'}lang=${lang}`;
    }

    const countries = await request.get(baseUrl);
    dispatch(actions.getCountries({ countries }));
  };

export const getRegionsCount = (countryId, lang) => async (dispatch) => {
  let baseUrl = `/api/requests/regions/total?countryId=${countryId}`;
  if (lang) {
    baseUrl += `&lang=${lang}`;
  }

  const regionsCount = await request.get(baseUrl);
  dispatch(actions.getRegionsCount({ regionsCount }));
};

export const getRegions =
  (countryId, search = '', regionId, lang) =>
  async (dispatch) => {
    let baseUrl = `/api/requests/regions?countryId=${countryId}`;
    if (search) {
      baseUrl += `&search=${search}`;
    }
    if (regionId) {
      baseUrl += `&regionId=${regionId}`;
    }
    if (lang) {
      baseUrl += `&lang=${lang}`;
    }

    const regions = await request.get(baseUrl);
    dispatch(actions.getRegions({ regions }));
  };

export const getCities =
  (countryId, regionId, search = '', cityId, lang) =>
  async (dispatch) => {
    let baseUrl = `/api/requests/cities?`;
    if (search) {
      baseUrl += `&search=${search}`;
    }
    if (cityId) {
      baseUrl += `&cityId=${cityId}`;
    }
    if (lang) {
      baseUrl += `&lang=${lang}`;
    }

    baseUrl = addQueryParam(baseUrl, 'countryId', countryId);
    baseUrl = addQueryParam(baseUrl, 'regionId', regionId);

    if (Array.isArray(regionId) && !isEmpty(regionId)) {
      baseUrl += regionId.reduce(
        (str, region) => `${str}&regionId[]=${region.value}`,
        ''
      );
    }

    const cities = await request.get(baseUrl);
    dispatch(actions.getCities({ cities }));
  };
