import URLSearchParams from 'url-search-params';
import { searchQuery } from '@root/utils/searchQuery';
import request from '../lib/request';
import { CompanyInfoDto, EmployeeDto } from '../DTOs';

class AdminsService {
  #baseUrl = '/api/administration';

  async getCompanies({ offset = 0, limit = 20, search = '', filter } = {}) {
    const searchParams = new URLSearchParams({ offset, limit, search });
    return request.post(`${this.#baseUrl}/companies/list/?${searchParams}`, {
      ...filter
    });
  }

  async getCompanyInfo(companyId) {
    const result = await request.get(
      `${this.#baseUrl}/companies/${companyId}/info`
    );

    if (!result) return null;

    return new CompanyInfoDto(result);
  }

  async getCompanyPurchaseRequests(companyId, { offset = 0, limit = 20 } = {}) {
    const params = new URLSearchParams({ offset, limit });
    return request.get(
      `${this.#baseUrl}/companies/${companyId}/requests/?${params}`
    );
  }

  async blockCompany(companyId) {
    return request.patch(`${this.#baseUrl}/companies/block/${companyId}`);
  }

  async unblockCompany(companyId) {
    return request.patch(`${this.#baseUrl}/companies/unblock/${companyId}`);
  }

  /**
   * Обновить billingStatus компании
   * @param {string} companyId -  id компании
   * @param {string} status - invoiced, paid, debt
   * @returns {Promise<void>}
   */
  async updateBillingStatus(companyId, status) {
    return request.post(
      `${this.#baseUrl}/companies/${companyId}/update-billing-status`,
      { status }
    );
  }

  async getEmployees(companyId, { offset = 0, limit = 20 } = {}) {
    const searchParams = new URLSearchParams({ offset, limit });
    const employees = await request.get(
      `${this.#baseUrl}/companies/${companyId}/employees/?${searchParams}`
    );

    return employees.map((employee) => new EmployeeDto(employee));
  }

  async getRequests(companyId, { offset = 0, limit = 20, order = '' } = {}) {
    const searchParams = new URLSearchParams({ offset, limit, order });
    const employees = await request.get(
      `${this.#baseUrl}/companies/${companyId}/requests/?${searchParams}`
    );

    return employees.map((employee) => new EmployeeDto(employee));
  }

  /**
   * Получение всех фича - флагов
   * @returns [Promise<{ id, name, enabled }>]
   */
  async getFeatureFlags() {
    return request.get(`${this.#baseUrl}/feature-flags`);
  }

  /**
   * Обновление фича - флагов
   * @param {Array.<{ id, enabled }>} flags - массив содержащий id флага и параметр enabled
   * @returns {Promise<void>}
   */
  async updateFeatureFlags(flags) {
    return request.patch(`${this.#baseUrl}/feature-flags`, flags);
  }

  /**
   Fetch all users
   @param {object} params
   @param {string} params.search - Строка для поиска
   @param {number} params.limit - Максимальное кол-во записей
   @param {number} params.offset
   @param {string} params.orderBy - Поле по которому осуществляется поиск
   @param {string} params.direction - Направление сортировки ASC|DESC
   @returns {Promise<Array>}
   */

  async getUsers({ search = '', limit = 20, offset = 0, orderBy, direction }) {
    const query = searchQuery.generate({
      limit,
      offset,
      search,
      orderBy,
      direction
    });

    return request.get(`${this.#baseUrl}/users/list${query}`);
  }

  /**
   * Блокировка пользователя
   * @param {string} id - Id пользователя
   * @param {boolean} block - Состояние пользователя которое будет после нажатия на кнопку
   * @returns {Promise<void>}
   */
  async blockUserById(id, block) {
    return request.patch(`${this.#baseUrl}/users/${id}/block`, { block });
  }

  /**
   * Удаление пользователя из системы
   * @param {array} data - Id пользователя
   * @returns {Promise<void>}
   */
  async deleteUsersByIds(data) {
    return request.post(`${this.#baseUrl}/delete-users`, { data });
  }

  async inviteCompany({ userInfo, companyInfo }) {
    return request.post(`${this.#baseUrl}/companies/invite`, {
      userInfo,
      companyInfo
    });
  }

  async sendRequestByEmail({ id, emails }) {
    return request.post(`${this.#baseUrl}/send-request/`, { id, emails });
  }

  async changeAdmin(companyId, { newOwnerUserId }) {
    return request.post(
      `${this.#baseUrl}/companies/${companyId}/change-admin`,
      { newOwnerUserId }
    );
  }

  async getCommissions(companyId, from, to) {
    return request.post(`${this.#baseUrl}/companies/${companyId}/finance`, {
      from,
      to
    });
  }

  async unblockPurchaseResponses(companies) {
    return request.post(
      `${this.#baseUrl}/companies/unblock-purchase-responses`,
      { companies }
    );
  }

  async blockPurchaseResponses(companies) {
    return request.post(`${this.#baseUrl}/companies/block-purchase-responses`, {
      companies
    });
  }

  async getProposals({ limit, offset }) {
    const query = searchQuery.generate({
      limit,
      offset
    });

    return request.get(`${this.#baseUrl}/proposals${query}`);
  }

  async getProposal(proposalId) {
    return request.get(`${this.#baseUrl}/proposal/${proposalId}`);
  }

  async publishProposal(proposalId, data) {
    return request.post(`${this.#baseUrl}/proposal/${proposalId}/publish`, {
      ...data
    });
  }
}

export const adminsService = new AdminsService();
