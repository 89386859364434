import {
  assoc,
  assocPath,
  update,
  prepend,
  move,
  isEmpty,
  pipe,
  remove
} from 'ramda';
import { isNilOrEmpty } from '@root/utils/utils';
import {
  reworkCompleted,
  sent,
  viewed,
  rejected,
  ACCEPTED_RESPONSE_STATUSES,
  PENDING_RESPONSE_STATUSES
} from '@root/presenters/sidebars/PurchaseRequestResponsesSidebar/status';
import createSlice from '../../../lib/actionReducer';
import { PurchaseRequestStatuses } from '../entities/PurchaseRequestStatuses';
import { ProductsErrorsReasons } from '../common/ProductsTable.constants';

const initState = {
  isUploaded: false,
  requests: [],
  size: 0,
  limit: 20,
  offset: 0,
  search: '',
  orderBy: '',
  direction: [],
  selectedCategoriesId: [],
  selectedStatus: [],
  compareResponses: {},
  compareRequest: null
};

const slice = createSlice({
  name: 'MY_PURCHASE_REQUEST',
  initState,
  reducers: {
    updateRequest,
    setIsUploaded,
    getMyRequests,
    getResponseById: setPayloadToState,
    setSearch: (state, payload) => ({ ...state, search: payload }),
    changeRequestStatus,
    bindProductsToResponse,
    rejectAllPendingResponses,
    publishRequest: (state, payload) =>
      changeRequestStatus(state, {
        id: payload.id,
        status: PurchaseRequestStatuses.receivingResponses
      }),
    archiveRequest,
    unpublishRequest,
    viewAllResponses,
    reset: () => initState,
    changeResponseStatus,
    viewResponse,
    updateResponse,
    setSelectedStatus,
    updateCompareList,
    setCancelFetchFn,
    setPreviousQuery
  }
});

export const { actions } = slice;
export default slice.reducer;

function setPayloadToState(state, payload) {
  return { ...state, ...payload };
}

function setIsUploaded(state, isUploaded = false) {
  return { ...state, isUploaded };
}

function isFirstPage(offset = 0) {
  return offset === 0;
}

function hasAnySearchParams(state) {
  return (
    !isEmpty(state.search) ||
    !isEmpty(state.selectedStatus) ||
    !isEmpty(state.selectedCategoriesId)
  );
}

function archiveRequest(state, payload) {
  const index = state.requests.findIndex(
    (request) => request.id === payload.id
  );

  if (index === -1) {
    console.error(`Request with id = ${payload.id} is not found`);
    return state;
  }

  return pipe(
    (result) => assoc('requests', remove(index, 1, state.requests), result),
    (result) => assoc('size', result.size - 1, result)
  )(state);
}

function unpublishRequest(state, payload) {
  return changeRequestStatus(state, {
    id: payload.id,
    status: PurchaseRequestStatuses.receptionCompleted
  });
}

function isSelectedProductBySupplier(product) {
  return Number(product.price) > 0 && Number(product.count > 0);
}

function rejectAllPendingResponses(state, payload) {
  const { requestId } = payload;

  const requestIndex = state.requests.findIndex(
    (request) => request.id === requestId
  );

  if (requestIndex === -1) return state;

  const request = state.requests[requestIndex];

  const updatedResponses = request.responses.map((response) =>
    PENDING_RESPONSE_STATUSES.includes(response.status)
      ? assoc('status', rejected, response)
      : response
  );
  const updatedRequest = assoc('responses', updatedResponses, request);

  return assoc(
    'requests',
    update(requestIndex, updatedRequest, state.requests),
    state
  );
}

function bindProductsToResponse(state, payload) {
  const { requestId, products } = payload;

  const requestIndex = state.requests.findIndex(
    (request) => request.id === requestId
  );

  if (requestIndex === -1) return state;

  const request = state.requests[requestIndex];

  if (!request.products?.length) return state;
  const newRequest = { ...request };

  newRequest.products = request.products.map((product, index) => {
    if (!products[index].responses?.length) return product;

    return { ...product, response: products[index].responses[0] };
  });

  newRequest.responses = request.responses.map((response) => {
    const responseProducts = response.attrs.purchaseRequest.products.map(
      (product, productIndex) => {
        if (!isSelectedProductBySupplier(product)) {
          return {
            ...product,
            response: {
              canBeSelected: false,
              reason: ProductsErrorsReasons.NotSelectedBySupplier
            }
          };
        }

        const acceptedResponse = newRequest.products.find(
          (_, index) => productIndex === index
        )?.response;

        if (
          !isNilOrEmpty(acceptedResponse) &&
          acceptedResponse.id !== response.id
        ) {
          return {
            ...product,
            response: {
              canBeSelected: false,
              reason: ProductsErrorsReasons.AnotherSupplierSelected,
              companyName: acceptedResponse.companyName
            }
          };
        }

        if (
          isNilOrEmpty(acceptedResponse) &&
          ACCEPTED_RESPONSE_STATUSES.includes(response.status)
        ) {
          return {
            ...product,
            response: {
              canBeSelected: false,
              reason: ProductsErrorsReasons.RejectedByCustomer
            }
          };
        }

        return product;
      }
    );

    return assocPath(
      ['attrs', 'purchaseRequest', 'products'],
      responseProducts,
      response
    );
  });

  const requests = update(requestIndex, newRequest, state.requests);

  return assoc('requests', requests, state);
}

function updateRequest(state, payload) {
  const { request } = payload;

  if (!isFirstPage(state.offset) || hasAnySearchParams(state)) return state;

  const requestIndex = state.requests.findIndex((r) => r.id === request.id);

  let updatedRequests;
  if (requestIndex === -1) {
    updatedRequests = prepend(request, state.requests);

    if (updatedRequests.length > state.limit) {
      updatedRequests = updatedRequests.slice(0, state.limit - 1);
    }
  } else {
    updatedRequests = update(requestIndex, request, state.requests);

    if (requestIndex !== 0) move(requestIndex, 0, updatedRequests);
  }

  return assoc('requests', updatedRequests, state);
}

function changeRequestStatus(state, payload) {
  const { id, status } = payload;

  return {
    ...state,
    requests: state.requests.map((request) =>
      request.id === id ? { ...request, status } : request
    )
  };
}

function getMyRequests(
  state,
  {
    requests = [],
    size,
    limit = 20,
    offset = 0,
    search = '',
    direction,
    selectedCategoriesId = [],
    orderBy
  }
) {
  return {
    ...state,
    requests,
    size,
    search,
    limit,
    offset,
    orderBy,
    direction,
    selectedCategoriesId,
    isUploaded: true
  };
}

function setSelectedStatus(state, selectedStatus) {
  return { ...state, selectedStatus };
}

function changeResponseStatus(state, { requestId, responseId, status }) {
  return {
    ...state,
    requests: state.requests.map((request, index) =>
      request.id === requestId
        ? {
            ...request,
            responses: state.requests[index].responses.map((response) => ({
              ...response,
              status: response.id === responseId ? status : response.status
            }))
          }
        : request
    )
  };
}

function viewResponse(state, { requestId, responseId }) {
  return {
    ...state,
    requests: state.requests.map((request, index) =>
      request.id === requestId
        ? {
            ...request,
            responses: state.requests[index].responses.map((response) => ({
              ...response,
              isViewed: response.id === responseId ? true : response.isViewed
            }))
          }
        : request
    )
  };
}

function updateResponse(state, { requestId, response }) {
  return {
    ...state,
    requests: state.requests.map((request, index) =>
      request.id === requestId
        ? {
            ...request,
            responses: state.requests[index].responses.map((res) => {
              if (res.id === response.id) {
                return {
                  ...res,
                  ...response
                };
              }
              return res;
            })
          }
        : request
    )
  };
}

function updateCompareList(state, { compareResponses, compareRequest }) {
  return { ...state, compareResponses, compareRequest };
}

function viewAllResponses(state, { requestId }) {
  return {
    ...state,
    requests: state.requests.map((request, index) =>
      request.id === requestId
        ? {
            ...request,
            responses: state.requests[index].responses.map((response) => ({
              ...response,
              status: [sent, reworkCompleted].includes(response.status)
                ? viewed
                : response.status
            }))
          }
        : request
    )
  };
}

function setCancelFetchFn(state, cancelFn) {
  return { ...state, cancelFetchFn: cancelFn };
}

function setPreviousQuery(state, query) {
  return { ...state, previousQuery: query };
}
