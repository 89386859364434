import request from '../lib/request';

class PermissionsService {
  #baseUrl = '/api/permissions';

  async getAll() {
    return request.get(this.#baseUrl);
  }
}

export const permissionsService = new PermissionsService();
