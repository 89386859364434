import React from 'react';
import cn from 'classnames';
import './style.styl';

const Loader = ({ theme, style = {} }) => (
  <div className={`loader ${theme ? `loader_${theme}` : ''}`} style={style}>
    <div className="loader-dots-wrap">
      <div
        className={cn(
          'loader-dots',
          theme === 'intext' && 'loader-dot_intext-container'
        )}>
        {theme === 'intext' ? (
          <span className="loader-dot_intext">...</span>
        ) : (
          <>
            <div
              className={`loader-dot ${theme ? `loader-dot_${theme}` : ''}`}
            />
            <div
              className={`loader-dot ${theme ? `loader-dot_${theme}` : ''}`}
            />
            <div
              className={`loader-dot ${theme ? `loader-dot_${theme}` : ''}`}
            />
          </>
        )}
      </div>
    </div>
  </div>
);

export default Loader;
