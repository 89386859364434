import React from 'react';
import { Icon, Typography } from '@link/react-components';
import cn from 'classnames';
import { Trans as Interpolate } from 'react-i18next';
import { useTranslate } from '../../../TranslateProvider';
import styles from './RequestKPByEmail.module.css';

const translate = (text) => `requestKPByEmail.infoBlock.${text}`;

const Access = ({ text, ...typographyProps }) => (
  <Typography {...typographyProps}>{text}</Typography>
);

export function InfoBlock({ isPrivate = false }) {
  const t = useTranslate();

  if (isPrivate) {
    return (
      <div className={cn(styles.infoBlock, styles.infoBlockPrivate)}>
        <Icon iconName="info" width={18} height={18} className={styles.icon} />
        <ul className={styles.listBlocks}>
          <Typography
            variant="body1Reg"
            Component="li"
            className={styles.infoText}>
            <Interpolate
              i18nKey={translate('privateResponse')}
              components={{
                access: (
                  <Access
                    inline
                    className={cn(styles.infoText, styles.infoTextBold)}
                    variant="body1Reg"
                    text={t(translate('getAccess'))}
                  />
                )
              }}
            />
          </Typography>
          <Typography
            variant="body1Reg"
            Component="li"
            className={styles.infoText}>
            {t(translate('distributionLimit'))}
          </Typography>
        </ul>
      </div>
    );
  }

  return (
    <div className={styles.infoBlock}>
      <Icon iconName="info" width={18} height={18} className={styles.icon} />
      <Typography variant="body1Reg" className={styles.infoText}>
        {t(translate('distributionLimit'))}
      </Typography>
    </div>
  );
}
