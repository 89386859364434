import React, { useCallback } from 'react';
import { useTranslate } from '@hooks';
import { Modal, Icon, ButtonIcon, Typography } from '@link/react-components';
import { useViewAttachmentsModal } from '@root/components/Modal';
import cn from 'classnames';
import { MODAL_KEY } from './constants';
import styles from './ModalViewAttachments.module.css';

const Attachment = ({ attachment, onDownload }) => {
  const handleDownload = useCallback(
    () => onDownload(attachment),
    [attachment]
  );
  return (
    <div className={styles.attachment}>
      <Icon iconName="attach" width={18} height={18} className={styles.icon} />
      <Typography
        variant="body1Med"
        className={cn(styles.name, styles.ellipsis)}>
        {attachment.name}
      </Typography>
      <Typography
        variant="body1Reg"
        className={cn(styles.size, styles.ellipsis)}>{`${Math.round(
        attachment.size / 1025
      )} Kb`}</Typography>
      <ButtonIcon iconName="download" onClick={handleDownload} size={18} />
    </div>
  );
};
const ModalViewAttachments = ({ attachments, onDownload }) => {
  const [, hideModal] = useViewAttachmentsModal();
  const t = useTranslate();
  return (
    <Modal open onClose={hideModal}>
      <Modal.Header>
        {t('purchaseRequestComparison.viewAttachmentsModal.title')}
      </Modal.Header>
      <Modal.Content>
        <div className={styles.content}>
          {attachments.map((attachment) => (
            <Attachment attachment={attachment} onDownload={onDownload} />
          ))}
        </div>
      </Modal.Content>
    </Modal>
  );
};

export const initViewAttachmentsModal = { [MODAL_KEY]: ModalViewAttachments };
