function transformToModel(model) {
  return Object.entries(model).reduce(
    (acc, [key, obj]) => ({ ...acc, [key]: obj.value }),
    {}
  );
}

function transformToViewModel(view) {
  return Object.entries(view).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: {
        value,
        error: null
      }
    }),
    {}
  );
}

export const employeeFormMapper = {
  transformToViewModel,
  transformToModel
};
