import React, { memo } from 'react';
import { getUserDisplayName } from '@root/utils/utils';
import { Typography } from '@link/react-components';
import styles from './SimpleEmployeeRaw.module.css';

export const SimpleEmployeeRaw = ({
  employee,
  viewFields = ['lastName', 'firstName', 'middleName']
}) => (
  <div className={styles.employeeRaw}>
    <Typography variant="body1Reg" className={styles.employeeRawText}>
      {getUserDisplayName(employee, { includedFields: viewFields })}
    </Typography>
  </div>
);

export const MemoizedSimpleEmployeeRaw = memo(SimpleEmployeeRaw);
