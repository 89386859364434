import {
  ADD_CHATS,
  SET_CHATS,
  SET_ARCHIVES,
  ADD_ARCHIVES,
  SET_GROUP_CHAT,
  SET_OPEN_CHAT,
  UPDATE_CHAT,
  UPDATE_GROUP_OF_CHATS,
  UPDATE_CHATS_FILTER,
  UPDATE_ARCHIVIES_FILTER,
  SET_MEMBER_STATUS
} from './constants';
import { ADD_LAST_UNREAD_MESSAGES } from '../../constants';

export const setChats = (chats) => ({ type: SET_CHATS, payload: chats });
export const updateChatsFilter = (filter) => ({
  type: UPDATE_CHATS_FILTER,
  payload: filter
});
export const updateArchiviesFilter = (filter) => ({
  type: UPDATE_ARCHIVIES_FILTER,
  payload: filter
});
export const addChats = (chats) => ({ type: ADD_CHATS, payload: chats });
export const updateChat = (chat) => ({ type: UPDATE_CHAT, payload: { chat } });

export const addLastUnreadMsgs = (payload) => ({
  type: ADD_LAST_UNREAD_MESSAGES,
  payload
});

export const setArchives = (chats) => ({ type: SET_ARCHIVES, payload: chats });
export const addArchives = (chats) => ({ type: ADD_ARCHIVES, payload: chats });

export const updateGroupOfChats = ({ groupId, exclude, include }) => ({
  type: UPDATE_GROUP_OF_CHATS,
  payload: { groupId, exclude, include }
});

export const setGroupChats = (blockGroups) => ({
  type: SET_GROUP_CHAT,
  payload: blockGroups
});

export const setOpenChat = (chat) => ({ type: SET_OPEN_CHAT, payload: chat });

export const setMemberStatus = ({ employeeId, status }) => ({
  type: SET_MEMBER_STATUS,
  payload: { employeeId, status }
});
