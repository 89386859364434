import React from 'react';
import { Typography } from '@link/react-components';
import Loader from '../../../../../Loader';
import { formatTypingText } from '../../../../utils';
import { useTranslate } from '../../../../../../TranslateProvider';
import './style.css';

const Typing = ({ typingList, isDialog }) => {
  const t = useTranslate();

  return (
    <div className="cws-chat-item-typing">
      <Typography variant="body2Reg" className="cw-typing_text">
        {formatTypingText(t, typingList, isDialog)}
      </Typography>
      <Loader theme="intext" />
    </div>
  );
};

export default Typing;
