import React from 'react';
import { Button, Checkbox } from '@link/react-components';
import { useTranslate } from '@hooks';
import { FORM_ID } from '../../../../../widgets/FillCompanyDataForm/lib/constants';
import styles from './FooterFillCompanyData.module.css';

export const FooterFillCompanyData = ({
  closeModal,
  isHintChecked,
  hadleCheck,
  isSubmitDisabled
}) => {
  const t = useTranslate();

  return (
    <>
      <Checkbox
        label={t('fillCompanyDataModal.doNotShowAgain')}
        checked={isHintChecked}
        onChange={hadleCheck}
      />
      <div>
        <Button mode="text" onClick={closeModal}>
          {t('fillCompanyDataModal.skip')}
        </Button>
        <Button
          type="submit"
          form={FORM_ID}
          className={styles.submit}
          disabled={isSubmitDisabled}>
          {t('fillCompanyDataModal.saveData')}
        </Button>
      </div>
    </>
  );
};
