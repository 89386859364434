import React, { useCallback, useMemo, useState } from 'react';

import { useTranslate, useActions, useDebounce } from '@hooks';

import {
  Button,
  Modal,
  SearchInput,
  DynamicList,
  Typography
} from '@link/react-components';

import * as modalActions from '@root/action-creators/modal';
import { companyService, myCompanyService } from '@api';
import { calcOffset } from '@root/utils/utils';

import { CompanyCard } from './components/CompanyCard';

import styles from './ModalSelectPrimaryPartner.module.css';

const SEARCH_TYPING_DEBOUNCE = 500;
const ITEMS_PER_PAGE = 20;
const GAP_BETWEEN_ITEMS = 0;

export const ModalSelectPrimaryPartner = ({ onSuccess }) => {
  const t = useTranslate();

  const [search, setSearch] = useState('');

  const [selected, setSelected] = useState(null);

  const translate = (key) => t(`modules.finance.primaryPartner.modal.${key}`);

  const handleModalClose = useActions(modalActions.hideModalDialog);

  const handleCleanUp = useCallback(() => setSearch(''), []);

  const handleSearch = useCallback((value) => {
    setSearch(value);
  }, []);

  const sendSelected = useCallback(async () => {
    const partner = await myCompanyService.updatePrimaryPartner({
      partnerCompanyId: selected
    });
    onSuccess?.(partner);
    handleModalClose();
  }, [selected]);

  const onSearchTyping = useDebounce(
    (value) => setSearch(value),
    SEARCH_TYPING_DEBOUNCE
  );

  const onLoad = useCallback(
    async (page, limit) => {
      const [fetchCompanies] = companyService.getCompanies({
        limit,
        offset: calcOffset(page, limit),
        search
      });

      return fetchCompanies();
    },
    [search]
  );

  const handleSelect = useCallback(
    (id) => {
      if (selected === id) {
        setSelected(null);
      } else {
        setSelected(id);
      }
    },
    [selected]
  );

  const provider = useMemo(() => {
    setSelected(null);
    return {
      load: (page, limit) => onLoad(page, limit, search)
    };
  }, [search]);

  return (
    <Modal open onClose={handleModalClose}>
      <Modal.Header>{translate('header')}</Modal.Header>
      <Modal.Content className={styles.modalContent}>
        <SearchInput
          onSearch={handleSearch}
          onCleanUp={handleCleanUp}
          onChange={onSearchTyping}
          placeholder={translate('searchPlaceholder')}
        />
        <div className={styles.list}>
          <DynamicList
            provider={provider}
            renderItem={(company) => (
              <CompanyCard
                id={company.id}
                name={company.name}
                inn={company.inn}
                onSelect={handleSelect}
                isSelected={selected === company.id}
                searchValue={search}
              />
            )}
            noResultsScreen={
              <Typography className={styles.noResult} variant="body1Reg">
                {translate('noResults')}
              </Typography>
            }
            batchSize={ITEMS_PER_PAGE}
            gap={GAP_BETWEEN_ITEMS}
          />
        </div>
      </Modal.Content>
      <Modal.Footer align="right">
        <Button onClick={handleModalClose} mode="text">
          {translate('cancel')}
        </Button>
        <Button onClick={sendSelected} disabled={!selected}>
          {translate('select')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
