import React from 'react';
import cn from 'classnames';
import Icon from 'react-components/src/icons';
import iconsVies from 'react-components/src/icons/constants';
import { EditIcon, EditDisableIcon } from './icons';
import styles from './ButtonSend.module.css';

function SendIcon({ isEditMode, isSendDisable }) {
  if (isEditMode) {
    const IconComponent = isSendDisable ? EditDisableIcon : EditIcon;

    return <IconComponent width={25} height={25} />;
  }

  return (
    <Icon
      icon={isSendDisable ? iconsVies.BtnSendDisabled : iconsVies.BtnSendEnable}
      size={18}
    />
  );
}

export const ButtonSend = ({ isEditMode, isSendDisable, onSend }) => (
  <div
    className={cn(styles.send, {
      [styles.sendDisabled]: isSendDisable
    })}
    onClick={onSend}>
    <SendIcon isSendDisable={isSendDisable} isEditMode={isEditMode} />
  </div>
);
