import { useCallback } from 'react';
import { useActions } from './useActions';
import * as modalActions from '../action-creators/modal';

export function useKeyModal(modalKey) {
  const { showModal, hideTargetModal } = useActions(modalActions);

  const show = useCallback(
    (props) => {
      showModal(modalKey, props);
    },
    [modalKey, showModal]
  );

  const hide = useCallback(() => {
    hideTargetModal(modalKey);
  }, [hideTargetModal]);

  return [show, hide];
}
