import { useEditableTableContext } from '@/components/EditableTable/EditableTable.context';
import { RowDef } from '@/components/EditableTable/types';
import { TextCell } from '../TextCell';
import { MaskCell } from '../MaskCell';
import { CustomCell } from '../CustomCell';
import { NumberCell } from '../NumberCell';
import { CheckboxCell } from '../CheckboxCell';

interface CellManagerProps {
  accessor: string;
  rowIndex: number;
  rowData: RowDef;
}

export function CellManager({ accessor, rowIndex, rowData }: CellManagerProps) {
  const context = useEditableTableContext();

  const column = context.columnsMapByAccessor.get(accessor);

  if (!column)
    throw new Error(`Column with field = ${accessor} must be passed`);

  const value = rowData[accessor];

  switch (column.type) {
    case 'text':
      return (
        <TextCell
          value={(value ?? '') as string}
          column={column}
          item={rowData}
          itemIndex={rowIndex}
        />
      );
    case 'mask':
      return (
        <MaskCell
          value={(value ?? '') as string}
          column={column}
          item={rowData}
          itemIndex={rowIndex}
        />
      );
    case 'number':
      return <NumberCell column={column} item={rowData} itemIndex={rowIndex} />;
    case 'checkbox':
      return <CheckboxCell row={rowData} rowIndex={rowIndex} column={column} />;
    case 'custom':
      return (
        <CustomCell
          value={value}
          column={column}
          rowIndex={rowIndex}
          rowData={rowData}
        />
      );
    default:
      throw new Error(`Received unsupported type: ${column.type}`);
  }
}
