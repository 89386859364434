import React from 'react';
import { useSelector } from 'react-redux';
import * as getters from '../../../../ChatWidget/getter';
import AdvancedSearch from '../../../../ChatWidget/sidebar/AdvancedSearch';
import { Contacts, Chats } from './Tabs';
import { bodyConfig } from '../../config';

export function Sidebar() {
  const { chatList } = bodyConfig;
  const advancedSearchActive = useSelector(getters.isAdvancedSearchActive);
  const activeTab = useSelector(getters.getActiveTab);

  if (advancedSearchActive) {
    return <AdvancedSearch />;
  }

  return (
    <>
      {activeTab === 'contacts' && <Contacts />}
      {activeTab === 'chats' && <Chats config={chatList} />}
    </>
  );
}
