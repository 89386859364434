import { convertUrlToFile } from '../components/ChatWidget/modules/files/utils';
import { noop } from './utils';

export const PICTURE_EXT_LIST = ['jpeg', 'jpg', 'png', 'webp', 'bmp'];
// Picture Viewer Interface Creator
// ---------------------------------------------------------------------------------------------------------------
const showImageViewer = ({ fileId, showModal, files }) => {
  const images = files
    .filter(({ extension, type }) =>
      ['jpg', 'jpeg', 'png', 'webp'].includes(extension || type)
    )
    .map((image) => ({
      id: image.id,
      src: image.originalUrl || image.url,
      name: image.name,
      type: image.extension,
      avatar: null
    }));

  const imageIndex = images.findIndex((image) => image.id === fileId);

  showModal('IMAGE_VIEWER', {
    images,
    imageIndex,
    isDeleteEnabled: false,
    file: files.find((f) => f.id === fileId)
  });
};

// PDF Viewer Interface Creator
// ---------------------------------------------------------------------------------------------------------------
const showPDFViewer = ({
  fileId,
  showModal,
  files,
  currentEmployeeId,
  onCopyToStorage
}) => {
  // Get PDFs from files
  const PDFs = files
    .filter(({ extension, type }) => (extension || type) === 'pdf')
    .map((pdf) => ({
      id: pdf.id,
      src: pdf.url,
      url: pdf.url,
      name: pdf.name,
      addedAt: pdf.addedAt,
      employeeId: pdf.employeeId,
      extension: pdf.extension || pdf.type
    }));

  // Find clicked PDF in all PDFs
  const PDF = PDFs.find((pdf) => pdf.id === fileId);

  if (PDF) {
    const { src, name, employeeId, id } = PDF;
    showModal('PDF_VIEWER', {
      avatar: null,
      src,
      name,
      isCopyToStorageEnable: employeeId !== currentEmployeeId,
      onCopyToStorageDocument: () => onCopyToStorage(id),
      isDeleteEnabled: false,
      file: PDF
    });
  }
};

// DOCX Viewer Interface Creator
// ---------------------------------------------------------------------------------------------------------------
const showDocxViewer = ({
  fileId,
  files,
  showModal,
  currentEmployeeId,
  onCopyToStorage
}) => {
  // Get PDFs from files
  const DOCXs = files
    .filter(({ extension, type }) => (extension || type) === 'docx')
    .map((docx) => ({
      id: docx.id,
      src: docx.url,
      url: docx.url,
      name: docx.name,
      addedAt: docx.addedAt,
      employeeId: docx.employeeId,
      extension: docx.extension || docx.type
    }));

  // Find clicked DOCX in all PDFs
  const DOCX = DOCXs.find((docx) => docx.id === fileId);

  if (DOCX) {
    const { src, name, employeeId, id } = DOCX;

    showModal('DOCX_VIEWER', {
      avatar: null,
      src,
      name,
      isCopyToStorageEnable: employeeId !== currentEmployeeId,
      onCopyToStorageDocument: () => onCopyToStorage(id),
      file: DOCX,
      isDeleteEnabled: false
    });
  }
};

// Viewer Interface Creator for other files
// ---------------------------------------------------------------------------------------------------------------
const showOtherFilesViewer = ({
  fileId,
  showModal,
  files,
  onCopyToStorage,
  currentEmployeeId
}) => {
  // Find clicked file in all files
  const currFile = files.find((file) => file.id === fileId);

  const allowCopyToStorage = (employeeId) => employeeId !== currentEmployeeId;

  const onCopyToStorageFile = async () => {
    await onCopyToStorage(fileId);
  };

  showModal('OTHER_VIEWER', {
    file: { ...currFile },
    isDeleteEnabled: false,
    isAddToStorageEnable: allowCopyToStorage(currFile.employeeId),
    onCopyToStorageFile
  });
};

export const isPicture = (file) => {
  const ext = (file.extension || file.type).toLowerCase();
  return PICTURE_EXT_LIST.includes(ext);
};

const isPDF = (file) => {
  const ext = (file.extension || file.type).toLowerCase();
  return ext === 'pdf';
};

const isDOCX = (file) => {
  const ext = (file.extension || file.type).toLowerCase();
  return ext === 'docx';
};

export const showFilesViewer = ({
  showModal,
  files,
  file,
  currentEmployeeId,
  onCopyToStorage = noop
}) => {
  const convertedFiles = files.map((_file) => ({
    ..._file,
    originalUrl: convertUrlToFile(
      _file.originalUrl || _file.fileInfo?.originalUrl || ''
    ),
    url: convertUrlToFile(_file.url || _file.fileInfo?.url || '')
  }));

  const props = {
    fileId: file.id,
    showModal,
    files: convertedFiles,
    onCopyToStorage,
    currentEmployeeId
  };

  if (isPicture(file)) {
    showImageViewer(props);
    return;
  }

  if (isPDF(file)) {
    showPDFViewer(props);
    return;
  }

  if (isDOCX(file)) {
    showDocxViewer(props);
    return;
  }

  showOtherFilesViewer(props);
};
