import React from 'react';
import { Modal } from '@link/react-components';

import { ViewEmployeesListByRole } from '@root/widgets';

import { useViewEmployeesByRole } from './useViewEmployeesByRole';
import { MODAL_KEY } from './constants';
import styles from './ModalViewEmployeesByRole.module.css';

const ModalViewEmployeesByRole = ({ title, roleId }) => {
  const [, hideModal] = useViewEmployeesByRole();

  return (
    <Modal open onClose={hideModal} className={styles.container}>
      <Modal.Header className={styles.header}>{title}</Modal.Header>
      <Modal.Content className={styles.content}>
        <ViewEmployeesListByRole roleId={roleId} />
      </Modal.Content>
    </Modal>
  );
};

export const initViewEmployeesByRole = {
  [MODAL_KEY]: ModalViewEmployeesByRole
};
