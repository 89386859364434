import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { Map } from 'immutable';
import {
  Trans as Interpolate,
  withTranslation as translate
} from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Col,
  Row,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  HelpBlock
} from 'react-bootstrap';
import Select from 'react-select';

import '../styles/modal_theme_default.styl';
import * as servicesAction from '../../../action-creators/services';
import * as customersAction from '../../../action-creators/customers';
import * as companyAction from '../../../action-creators/company';
import * as ContactsUC from '../../ChatWidget/modules/contacts';

import { ModalContainer } from '../elements';
import { validateEmail } from '../../../utils/validateField';

import keyDebounce from '../../../utils/debounce';
import { InputText } from '../../FormControls';
import LoaderWithMessage from '../../Loader/LoaderWithMessage';
import StatusMessage from '../../StatusMessage';
import { isLoading } from '../../../storeGetters';
import { isMessengerService } from '../../../lib/goodwix';

const languages = [
  { value: 'ru', label: 'Русский' },
  { value: 'en', label: 'English' }
];

const b = block('modal-container');

const viewState = {
  DEFAULT: 'DEFAULT',
  INVITE_COMPANY: 'INVITE_COMPANY',
  ADD_COMPANY: 'ADD_COMPANY',
  ADD_USER: 'ADD_USER',
  ADD_COMPANY_AND_USER: 'ADD_COMPANY_AND_USER',
  COMPANY_ALREADY_ADDED: 'COMPANY_ALREADY_ADDED',
  COMPANY_AND_USER_ALREADY_ADDED: 'COMPANY_AND_USER_ALREADY_ADDED'
};

class ModalAddCustomer extends Component {
  static isEmailValid(value) {
    if (value && value.length) {
      return validateEmail(value);
    }
    return true;
  }

  constructor(props) {
    super(props);
    this.state = {
      pristine: true,
      lockCategory: false,
      lockResponsible: false,
      isClick: false,
      consignees: [{ consignee: '', identifier: '' }],
      view: viewState.DEFAULT,
      language: props.company.get('language'),
      isAlreadyAdded: false,
      isUserAlreadyAdded: false,
      isExist: false
    };
    this.onKeyUp = keyDebounce(this.onKeyUp.bind(this));
    this.inputProps = this.inputProps.bind(this);
    this.isValidNameField = this.isValidNameField.bind(this);
    this.isMyEmail = this.isMyEmail.bind(this);
    this.onClick = this.onClick.bind(this);
    this.addConsignee = this.addConsignee.bind(this);
    this.removeConsignee = this.removeConsignee.bind(this);
  }

  onClick() {
    this.setState({ isClick: true }, () =>
      setTimeout(() => this.setState({ isClick: false }), 10000)
    );
  }

  getChildContext() {
    return { b };
  }

  UNSAFE_componentWillMount() {
    const { gaPageView, getGroups, groups } = this.props;
    gaPageView('customer_add_open');
    if (!groups || groups.size === 0) {
      getGroups();
    }
  }

  componentWillUnmount() {
    this.props.clearForm();
  }

  async onKeyUp() {
    const { newCustomer, getInfo } = this.props;
    const {
      isExist,
      isAlreadyAdded,
      isEmailCompany,
      companyName,
      employeeName,
      employeeId,
      responsibleUser,
      isHiddenContact
    } = await getInfo(newCustomer.get('email'), 'customer');

    const isEmailValid = ModalAddCustomer.isEmailValid(
      this.getProperty('email')
    );
    const isMyEmail = this.isMyEmail();

    if (newCustomer.get('email') && isEmailValid && !isMyEmail) {
      // Case when email isn't register
      if (!isExist) {
        this.setState({
          view: viewState.INVITE_COMPANY,
          lockCategory: false,
          lockResponsible: false,
          isUserAlreadyAdded: false
        });
      }
      // Case when company exist, but hasn't been added
      if (isExist && !isAlreadyAdded && isEmailCompany) {
        this.setState({
          view: viewState.ADD_COMPANY,
          lockCategory: false,
          lockResponsible: false,
          isUserAlreadyAdded: false
        });
      }
      // Case add user to contact list if company already added, but user is hidden
      if (isExist && isAlreadyAdded && !isEmailCompany && isHiddenContact) {
        this.setState({
          view: viewState.ADD_USER,
          lockCategory: true,
          lockResponsible: true,
          isUserAlreadyAdded: false
        });
      }
      // Case when email belongs to user which already added to contact
      if (isExist && isAlreadyAdded && !isEmailCompany && !isHiddenContact) {
        this.setState({ view: viewState.DEFAULT, isUserAlreadyAdded: true });
      }
      // Case when email belongs to user which isn't in the contact list
      if (isExist && !isAlreadyAdded && !isEmailCompany) {
        this.setState({
          view: viewState.ADD_COMPANY_AND_USER,
          lockCategory: false,
          lockResponsible: false,
          isUserAlreadyAdded: false
        });
      }
      // Case when email belongs to company which already added
      if (isExist && isAlreadyAdded && isEmailCompany) {
        this.setState({
          view: viewState.COMPANY_ALREADY_ADDED,
          lockCategory: true,
          lockResponsible: true,
          isUserAlreadyAdded: false
        });
      }
    } else {
      this.setState({ view: viewState.DEFAULT, isUserAlreadyAdded: false });
    }
    if (companyName) {
      this.props.updateCustomerField('companyName', companyName);
    }
    if (employeeName) {
      this.props.updateCustomerField('employeeName', employeeName);
    }
    if (employeeId) {
      this.props.updateCustomerField('employeeId', employeeId);
    }
    if (responsibleUser) {
      this.props.updateCustomerField('responsibleUser', responsibleUser);
    }
    this.setState({ isExist, isAlreadyAdded });
  }

  getProperty(field) {
    return this.props.newCustomer.has(field)
      ? this.props.newCustomer.get(field)
      : '';
  }

  isValidNameField() {
    const { newCustomer } = this.props;
    return newCustomer.get('email').length < 1;
  }

  isMyEmail() {
    const { company, newCustomer } = this.props;
    const currentCompanyEmail = company.get('email');
    const currentCompanyUsers = company.get('listOfUsers')
      ? company.get('listOfUsers').toJS()
      : [];
    const currentCompanyUsersEmails = currentCompanyUsers
      .filter((employee) => !employee.deletedAt)
      .map(({ email = '' }) => email);

    const allCompanyEmails = [
      currentCompanyEmail,
      ...currentCompanyUsersEmails
    ];
    return allCompanyEmails.includes(newCustomer.get('email'));
  }

  asterisk() {
    // eslint-disable-line
    return <span style={{ color: 'red' }}>*</span>;
  }

  inputProps(field) {
    return {
      value: this.props.newCustomer.get(field),
      onChange: (e) => {
        if (field === 'email') {
          return this.props.updateCustomerField(field, e.target.value.trim());
        }
        return this.props.updateCustomerField(field, e.target.value);
      }
    };
  }

  showValidMailInfo() {
    const { t } = this.props;
    const { isUserAlreadyAdded } = this.state;
    if (!ModalAddCustomer.isEmailValid(this.getProperty('email'))) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Wrong email format')}
        </HelpBlock>
      );
    }
    if (this.isMyEmail()) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Can not invite yourself')}
        </HelpBlock>
      );
    }
    if (isUserAlreadyAdded) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t(
            'The company employee has already been added to your contact list'
          )}
        </HelpBlock>
      );
    }
    return null;
  }

  addConsignee() {
    const { consignees } = this.state;
    consignees.push({ consignee: '', identifier: '' });
    this.setState({ consignees });
  }

  removeConsignee(n) {
    const consignees = this.state.consignees.filter((c, index) => index !== n);
    this.setState({ consignees });
  }

  havePermissionToUpdate(user) {
    const permissions = user.get('permissions');

    return (
      permissions.getIn(['customerOrders', 'update']) &&
      permissions.getIn(['customers', 'update'])
    );
  }

  getEmployees() {
    const { company } = this.props;
    return company
      .get('listOfUsers')
      .filter(this.havePermissionToUpdate)
      .filter((user) => user.get('deletedAt') === null)
      .map((i) => ({
        value: i.get('id'),
        label: `${i.get('lastName')} ${i.get('firstName')}`
      }))
      .toList()
      .toJS();
  }

  renderField = (field) => {
    const { t, newCustomer, groups, company } = this.props;
    const { lockCategory, lockResponsible } = this.state;

    const extGroups = groups.unshift(
      new Map({ id: 0, name: t('All customers') })
    );
    const category = extGroups
      .filter((i) => !i.get('service'))
      .map((group) => ({ value: group.get('id'), label: group.get('name') }));

    const EXT_FUNC_1_ACCS =
      process.env.EXT_FUNC_1_ACCS && process.env.EXT_FUNC_1_ACCS.split(',');

    switch (field) {
      case 'email':
        return (
          <FormGroup
            controlId="email"
            validationState={
              (this.isValidNameField() ||
                this.isMyEmail() ||
                this.state.isUserAlreadyAdded ||
                !ModalAddCustomer.isEmailValid(
                  this.getProperty('email') ||
                    newCustomer.get('email') === company.get('email')
                )) &&
              !this.state.pristine
                ? 'error'
                : null
            }
            style={{ overflow: 'hidden' }}>
            <ControlLabel>
              {`${t('E-mail')} (${t('only latin')}) `}
              {this.asterisk()}
            </ControlLabel>
            <h6 className="pull-right">
              <small>
                {newCustomer.get('email').length}
                /60
              </small>
            </h6>
            <FormControl
              type="text"
              {...this.inputProps('email')}
              placeholder="example@email.com"
              maxLength="60"
              onKeyUp={this.onKeyUp}
              onKeyDown={() => {
                if (this.state.pristine) {
                  this.setState({ pristine: false });
                }
              }}
            />
            {this.isValidNameField() && !this.state.pristine && (
              <HelpBlock style={{ marginBottom: 0 }}>
                {t('Required fields empty')}
              </HelpBlock>
            )}
            {this.showValidMailInfo()}
          </FormGroup>
        );
      case 'category':
        if (isMessengerService()) return null;

        return (
          <FormGroup controlId="category">
            <ControlLabel>{t('Suppliers groups')}</ControlLabel>
            <Select
              id="group-select"
              value={this.props.newCustomer.get('category')}
              onChange={(e) =>
                this.props.updateCustomerField('category', e.value)
              }
              options={category.toJS()}
              placeholder={t('All customers')}
              clearable={false}
              searchable={false}
              disabled={lockCategory}
            />
          </FormGroup>
        );
      case 'responsible':
        return (
          <FormGroup controlId="responsible">
            <ControlLabel>{t('Responsible for customer')}</ControlLabel>
            <Select
              id="responsible-select"
              value={this.props.newCustomer.get('responsibleUser')}
              onChange={(e) =>
                this.props.updateCustomerField('responsibleUser', e.value)
              }
              options={this.getEmployees()}
              placeholder={t('not selected')}
              clearable={false}
              searchable={false}
              disabled={lockResponsible}
            />
          </FormGroup>
        );
      case 'language':
        return (
          <FormGroup controlId="language">
            <ControlLabel>{t('invite_lang')}</ControlLabel>
            <Select
              id="language-select"
              value={this.state.language}
              onChange={({ value }) => this.setState({ language: value })}
              options={languages}
              clearable={false}
              searchable={false}
            />
          </FormGroup>
        );
      case 'consignees':
        return (
          EXT_FUNC_1_ACCS &&
          EXT_FUNC_1_ACCS.includes(company.get('email')) && (
            <FormGroup controlId="consignees" style={{ overflow: 'hidden' }}>
              <ControlLabel>{t('Consignees')}</ControlLabel>
              <div style={{ width: '93%', display: 'inline-block' }}>
                <Row>
                  <Col md={9}>
                    <FormGroup
                      controlId="consigneeNameLabel"
                      style={{ overflow: 'hidden', marginBottom: 0 }}>
                      <ControlLabel>{t('Name')}</ControlLabel>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup
                      controlId="consigneeIdentifierLabel"
                      style={{ overflow: 'hidden', marginBottom: 0 }}>
                      <ControlLabel>{t('Identifier')}</ControlLabel>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              {this.state.consignees &&
                this.state.consignees.map(({ consignee, identifier }, n) => (
                  <div style={{ marginBottom: 10 }}>
                    <div style={{ width: '93%', display: 'inline-block' }}>
                      <Row>
                        <Col md={9}>
                          <FormGroup
                            controlId="consigneeName"
                            style={{ overflow: 'hidden', marginBottom: 0 }}>
                            <InputText
                              maxLength="128"
                              placeholder={`${t('Consignee')} ${n + 1}`}
                              value={consignee}
                              onChange={(e) => {
                                const { consignees } = this.state;
                                consignees[n].consignee = e.target.value;
                                this.setState({ consignees });
                              }}
                              onKeyDown={(e) =>
                                e.keyCode === 13 ? e.preventDefault() : null
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col md={3}>
                          <FormGroup
                            controlId="consigneeIdentifier"
                            style={{ overflow: 'hidden', marginBottom: 0 }}>
                            <InputText
                              placeholder={12345678}
                              maxLength="8"
                              value={identifier}
                              onChange={(e) => {
                                const { consignees } = this.state;
                                consignees[n].identifier = e.target.value;
                                this.setState({ consignees });
                              }}
                              onKeyDown={(e) =>
                                e.keyCode === 13 ? e.preventDefault() : null
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    {n === 0 && (
                      <img
                        role="presentation"
                        onClick={this.addConsignee}
                        className="profile-category-sign"
                        src="/img/add.svg"
                        alt="add"
                      />
                    )}
                    {n > 0 && (
                      <img
                        role="presentation"
                        onClick={() => this.removeConsignee(n)}
                        className="profile-category-sign"
                        src="/img/remove.svg"
                        alt="remove"
                      />
                    )}
                  </div>
                ))}
            </FormGroup>
          )
        );
      default:
        return null;
    }
  };

  renderEmailFieldWithInfo = () => {
    const { isExist, isAlreadyAdded } = this.state;
    let statusData = {};

    if (isExist && !isAlreadyAdded) {
      statusData = {
        text: 'Was_found',
        icon: 'ok'
      };
    } else if (isExist && isAlreadyAdded) {
      statusData = {
        text: 'Added',
        icon: 'ok'
      };
    } else if (!isExist) {
      statusData = {
        text: 'Not_found',
        color: 'gray'
      };
    }

    return (
      <Row>
        <Col xs={8}>{this.renderField('email')}</Col>
        <Col xs={4}>
          <div style={{ marginTop: '38px' }}>
            <StatusMessage {...statusData} />
          </div>
        </Col>
      </Row>
    );
  };

  renderLoading = () => (
    <>
      <Row>
        <Col xs={8}>{this.renderField('email')}</Col>
      </Row>
      {this.renderHr()}
      <div style={{ marginBottom: '24px' }}>
        <LoaderWithMessage text="Wait loading" />
      </div>
    </>
  );

  renderTextMsg = () => {
    const { view } = this.state;
    const { t, newCustomer } = this.props;
    let text = '';

    if (view === viewState.ADD_USER) {
      return (
        <div style={{ marginBottom: '22px' }}>
          <Interpolate
            i18nKey="Company already added into customers, add employee"
            values={{
              employeeName: newCustomer.get('employeeName')
            }}
          />
        </div>
      );
    }
    if (view === viewState.ADD_COMPANY_AND_USER) {
      return (
        <div style={{ marginBottom: '22px' }}>
          <Interpolate
            i18nKey="Adding company to customers and add employee"
            values={{
              employeeName: newCustomer.get('employeeName'),
              companyName: newCustomer.get('companyName')
            }}
          />
        </div>
      );
    }
    if (view === viewState.INVITE_COMPANY) {
      text =
        'The company is not registered in the Service, clicking on the Invite button, select language';
    }
    if (view === viewState.COMPANY_ALREADY_ADDED) {
      text = 'Customer company already added';
    }
    return <div style={{ marginBottom: '22px' }}>{t(text)}</div>;
  };

  renderHr = (style) => <hr style={{ marginTop: '0px', ...style }} />;

  renderLabel = (text, style) => {
    const { t } = this.props;

    return (
      <div style={{ fontWeight: 500, marginBottom: '10px', ...style }}>
        {t(text)}
      </div>
    );
  };

  renderCompanyAlreadyAddedCase = () => {
    const { newCustomer } = this.props;

    return (
      <>
        {this.renderEmailFieldWithInfo()}
        <Row>
          <Col md={12}>{this.renderField('category')}</Col>
        </Row>
        <Row>
          <Col md={12}>{this.renderField('responsible')}</Col>
        </Row>
        {this.renderHr()}
        {this.renderLabel('Company')}
        <div>{newCustomer.get('companyName')}</div>
        {this.renderHr({ marginTop: '20px' })}
        {this.renderTextMsg()}
        {this.renderField('consignees')}
      </>
    );
  };

  renderAddUserCase = () => {
    const { newCustomer } = this.props;

    return (
      <>
        {this.renderEmailFieldWithInfo()}
        <Row>
          <Col md={12}>{this.renderField('category')}</Col>
        </Row>
        <Row>
          <Col md={12}>{this.renderField('responsible')}</Col>
        </Row>
        {this.renderHr()}
        {this.renderLabel('Company')}
        <div>{newCustomer.get('companyName')}</div>
        {this.renderLabel('Employee', { marginTop: '25px' })}
        <div>{newCustomer.get('employeeName')}</div>
        {this.renderHr({ marginTop: '20px' })}
        {this.renderTextMsg()}
        {this.renderField('consignees')}
      </>
    );
  };

  renderAddCompanyAndUserCase = () => {
    const { newCustomer } = this.props;

    return (
      <>
        {this.renderEmailFieldWithInfo()}
        <Row>
          <Col md={12}>{this.renderField('category')}</Col>
        </Row>
        <Row>
          <Col md={12}>{this.renderField('responsible')}</Col>
        </Row>
        {this.renderHr()}
        {this.renderLabel('Company')}
        <div>{newCustomer.get('companyName')}</div>
        {this.renderLabel('Employee', { marginTop: '25px' })}
        <div>{newCustomer.get('employeeName')}</div>
        {this.renderHr({ marginTop: '20px' })}
        {this.renderTextMsg()}
        {this.renderField('consignees')}
      </>
    );
  };

  renderDefaultCase = () => (
    <Row>
      <Col xs={8}>{this.renderField('email')}</Col>
    </Row>
  );

  renderInviteCompanyCase = () => (
    <>
      {this.renderEmailFieldWithInfo()}
      <Row>
        <Col md={12}>{this.renderField('category')}</Col>
      </Row>
      <Row>
        <Col md={12}>{this.renderField('responsible')}</Col>
      </Row>
      {this.renderHr()}
      {this.renderTextMsg()}
      <Row>
        <Col md={12}>{this.renderField('language')}</Col>
      </Row>
      {this.renderField('consignees')}
    </>
  );

  renderAddCompanyCase = () => (
    <>
      {this.renderEmailFieldWithInfo()}
      <Row>
        <Col md={12}>{this.renderField('category')}</Col>
      </Row>
      <Row>
        <Col md={12}>{this.renderField('responsible')}</Col>
      </Row>
      {this.renderField('consignees')}
    </>
  );

  renderFormFields = () => {
    const { loading } = this.props;
    const { view } = this.state;

    if (loading) return this.renderLoading();

    switch (view) {
      case viewState.DEFAULT:
        return this.renderDefaultCase();
      case viewState.INVITE_COMPANY:
        return this.renderInviteCompanyCase();
      case viewState.COMPANY_ALREADY_ADDED:
        return this.renderCompanyAlreadyAddedCase();
      case viewState.ADD_USER:
        return this.renderAddUserCase();
      case viewState.ADD_COMPANY_AND_USER:
        return this.renderAddCompanyAndUserCase();
      case viewState.ADD_COMPANY:
        return this.renderAddCompanyCase();
      default:
        return null;
    }
  };

  onSubmit = () => {
    const { badgeSocket: socket } = this.context;
    const {
      hideModalDialog,
      newCustomer,
      addCustomer,
      getAllInvitations,
      priceId,
      callback = () => {},
      unhideContactGeneral
    } = this.props;
    const { view, language } = this.state;

    if (view === viewState.ADD_USER) {
      const successMessage = {
        key: 'Employee from company added to your contact list',
        params: {
          companyName: newCustomer.get('companyName'),
          employeeName: newCustomer.get('employeeName')
        }
      };
      return unhideContactGeneral(
        newCustomer.get('employeeId'),
        successMessage
      ).then(() => hideModalDialog());
    }

    this.onClick();
    const consignees = this.state.consignees[0].consignee
      ? this.state.consignees
      : false;
    return addCustomer(newCustomer.toJS(), priceId, consignees, language).then(
      () => {
        getAllInvitations();
        socket.emit(
          'updateCompanyBadges',
          JSON.stringify({ email: newCustomer.get('email') })
        );
        callback();
        hideModalDialog();
      }
    );
  };

  render() {
    const {
      t,
      captionKey,
      hideModalDialog,
      newCustomer,
      gaPageView,
      gaSend,
      company,
      user
    } = this.props;
    const { view } = this.state;

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="addCategory" onHide={() => hideModalDialog()} show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>{this.renderFormFields()}</Form>
            {this.asterisk()}
            {` ${t('Required fields')}`}
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                gaPageView('customer_add_cancel');
                gaSend({
                  category: 'Common',
                  action: 'customer_add_cancel',
                  label: user.get('email')
                });
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveButton"
              bsStyle="primary"
              disabled={
                this.state.isClick ||
                this.isMyEmail() ||
                this.isValidNameField() ||
                !ModalAddCustomer.isEmailValid(this.getProperty('email')) ||
                newCustomer.get('email') === company.get('email') ||
                view === viewState.COMPANY_ALREADY_ADDED ||
                this.state.isUserAlreadyAdded
              }
              onClick={this.onSubmit}>
              {t(
                this.state.view === viewState.INVITE_COMPANY
                  ? 'Button invite'
                  : 'Button add'
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

ModalAddCustomer.propTypes = {
  t: PropTypes.func.isRequired
};

ModalAddCustomer.childContextTypes = {
  b: PropTypes.func
};

ModalAddCustomer.contextTypes = {
  badgeSocket: PropTypes.object
};

export default connect(
  (state) => ({
    newCustomer: state.getIn(['customers', 'newCustomer']),
    company: state.get('company'),
    user: state.getIn(['user', 'user']),
    loading: isLoading('getInfoByEmail', state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        gaPageView: servicesAction.gaPageView,
        gaSend: servicesAction.gaSend,
        getAllInvitations: customersAction.getAllInvitations,
        getInfo: companyAction.getInfoByEmail,
        unhideContactGeneral: ContactsUC.unhideContactGeneral
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalAddCustomer));
