import React, { useEffect, useState } from 'react';
import { equals } from 'ramda';
import { useActions, useAttachFiles, useTranslate } from '@hooks';
import { noop } from '@root/utils/utils';
import { formatDate } from '@root/utils/date';
import { formatFileSize } from '@root/utils';
import './FilesBlock.css';
import { useSelector } from 'react-redux';
import ChatDropzone from '@root/components/Dropzone/Chat';
import FileSelectWrap from '../../../../components/FileSelectWrap';
import { FileContextProvider, useFiles } from '../../model/FileContext';
import BtnIcon from '../../../../components/ChatWidget/ButtonIcon';
import { FILES_BLOCK_MODES, MAX_FILES } from '../../lib/constants';
import * as storeGetters from '../../../../storeGetters';
import * as storageActions from '../../../../action-creators/storage';

const BtnSmallIcon = ({ name, icon, onClick }) => (
  <BtnIcon icon={icon} onClick={onClick} alt={name} />
);

const EditModeFilesButtons = ({ fileId, canBeDeleted = true }) => {
  const { onDownloadFile, onDeleteFile } = useFiles();

  return (
    <>
      <BtnSmallIcon
        icon="download-1"
        name="download"
        onClick={() => onDownloadFile(fileId)}
      />
      {canBeDeleted && (
        <BtnSmallIcon
          name="delete"
          icon="remove-2"
          onClick={() => onDeleteFile(fileId)}
        />
      )}
    </>
  );
};

const ViewModeFilesButtons = ({ fileId }) => {
  const { onDownloadFile, onShowPreview } = useFiles();

  return (
    <>
      <BtnSmallIcon
        icon="download-1"
        name="download"
        onClick={() => onDownloadFile(fileId)}
      />
      <BtnSmallIcon
        name="preview"
        icon="view"
        onClick={() => onShowPreview(fileId)}
      />
    </>
  );
};

const FileButtons = ({ file }) => {
  const { mode } = useFiles();

  if (isEditMode(mode))
    return (
      <EditModeFilesButtons fileId={file.id} canBeDeleted={file.canBeDeleted} />
    );

  if (isViewMode(mode)) return <ViewModeFilesButtons fileId={file.id} />;

  return <></>;
};

const FileItem = ({ file }) => (
  <div className="form-respond-file-item">
    <img
      className="form-respond-file-item__icon"
      src="/img/purchase-requests/attach.svg"
      alt="icon-link"
    />
    <div className="form-respond-file-item__name">{file.fileInfo.name}</div>
    <div className="form-respond-file-item__size">
      {formatFileSize(file.fileInfo.size ?? 0)}
    </div>
    <div className="form-respond-file-item__date">
      {formatDate(new Date(file.fileInfo.createdAt), 'withOutTime')}
    </div>
    <div className="form-respond-file-item__buttons">
      <div className="btns-small-icons">
        <FileButtons file={file} />
      </div>
    </div>
  </div>
);

const FilesHeader = () => {
  const t = useTranslate();
  return (
    <div className="form-respond-file-header">
      <div className="form-respond-file-header__column-1">
        {t('filesBlock.column-1.title')}
      </div>
      <div className="form-respond-file-header__column-2">
        {t('filesBlock.column-2.title')}
      </div>
      <div className="form-respond-file-header__column-3">
        {t('filesBlock.column-3.title')}
      </div>
    </div>
  );
};
const FilesList = () => {
  const { files } = useFiles();
  return (
    <div className="form-respond-files-list">
      {files.map((file) => (
        <FileItem file={file} key={file.fileInfo.name + file.fileInfo.id} />
      ))}
    </div>
  );
};

const isEditMode = equals(FILES_BLOCK_MODES.EDIT);
const isViewMode = equals(FILES_BLOCK_MODES.VIEW);

export const FilesBlock = ({
  maxFilesCount = MAX_FILES,
  files: _files = [],
  mode = FILES_BLOCK_MODES.VIEW,
  onChangeFiles = noop,
  classNames = '',
  hideHeader = false
}) => {
  const t = useTranslate();

  const { getStorageSize } = useActions(storageActions);

  useEffect(() => {
    getStorageSize();
  }, []);

  const haveFreeSpace = useSelector(storeGetters.haveFreeStorageSpace);

  const [files, setFiles] = useState(_files);

  useEffect(() => {
    setFiles((prevState) => (equals(prevState, _files) ? prevState : _files));
  }, [_files]);

  useEffect(() => {
    onChangeFiles(files);
  }, [files.length]);

  const onAttachFiles = useAttachFiles({
    attachedFiles: files,
    maxFiles: maxFilesCount,
    onLoadFile: (file) => setFiles((prevState) => [...prevState, file])
  });

  return (
    <div id="files-block-dnd" className="form-respond-files-list-block">
      <div className="form-respond-files-list-block__header">
        <div className="form-respond-files-list-block__title-wrapper">
          <div className="form-respond-files-list-block__title-text">
            {t('filesBlock.title')}
          </div>
          <div className="form-respond-files-list-block__title-count">{`(${files.length})`}</div>
        </div>
      </div>
      {files.length > 0 && (
        <div className={`form-respond-files-list-block__content ${classNames}`}>
          {!hideHeader && <FilesHeader />}
          <FileContextProvider files={files} mode={mode} setFiles={setFiles}>
            <FilesList />
          </FileContextProvider>
        </div>
      )}
      {isEditMode(mode) && haveFreeSpace && files.length < maxFilesCount && (
        <FileSelectWrap
          multiple
          accept=""
          onChange={onAttachFiles}
          component={({ onClick }) => (
            <button
              type="button"
              className="form-respond-files-list-block__btn-add"
              onClick={onClick}>
              <img
                className="form-respond-files-list-block__btn-add-icon"
                alt="add-file"
                src="/img/purchase-requests/attach-small.svg"
              />
              <span className="form-respond-files-list-block__btn-add-text">
                {t('chat-widget.tooltips.attach-file')}
              </span>
            </button>
          )}
        />
      )}
    </div>
  );
};

export function FilesDropZone({
  maxFilesCount = MAX_FILES,
  files,
  onChangeFile = noop,
  children
}) {
  const onAttachFiles = useAttachFiles({
    attachedFiles: files,
    maxFiles: maxFilesCount,
    onLoadFile: onChangeFile
  });

  const haveFreeSpace = useSelector(storeGetters.haveFreeStorageSpace);
  const canAddFile = haveFreeSpace && files.length < maxFilesCount;
  return (
    <ChatDropzone
      tkeyText="respondForm.dnd.main-text"
      tkeySubText="respondForm.dnd.sub-text"
      disabled={!canAddFile}
      onDrop={onAttachFiles}>
      {children}
    </ChatDropzone>
  );
}
