import React from 'react';
import { Typography } from '@link/react-components';
import { useTranslate } from '@hooks';

export const NoResult = ({ className, tKey }) => {
  const t = useTranslate();

  return (
    <div className={className}>
      <Typography variant="body1Reg">{t(tKey || 'noResultsFound')}</Typography>
    </div>
  );
};
