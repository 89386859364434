import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

export const TransContext = React.createContext(null);

export const useTranslate = () => useContext(TransContext);

const TranslateProvider = ({ children }) => {
  const { t } = useTranslation();

  return <TransContext.Provider value={t}>{children}</TransContext.Provider>;
};

export default TranslateProvider;
