import { useEditableTableContext } from '@/components/EditableTable/EditableTable.context';
import { isFunction } from '@/lib/utils';
import { makeKey } from '@/components/EditableTable/utils';
import { RowDef } from '../../types';
import { CellManager } from '../CellManager';
import { DeleteRow } from './components';
import styles from './Row.module.scss';

interface RowProps {
  rowIndex: number;
  data: RowDef;
}

export function Row({ rowIndex, data }: RowProps) {
  const context = useEditableTableContext();

  const onHover = () => {
    if (isFunction(context.onHover)) {
      context.onHover(data, rowIndex);
    }
  };

  const onDeleteRow = () => {
    if (context.removable && isFunction(context.onRemove)) {
      context.onRemove(data, rowIndex);
    }
  };

  return (
    <tr className={styles.row} onMouseEnter={onHover}>
      {/* TODO Сгенерировать статичный key вместо cellIndex */}
      {/* eslint-disable-next-line react/no-array-index-key */}
      {context.accessorsList.map((accessor, cellIndex) => (
        <CellManager
          key={makeKey(cellIndex)}
          accessor={accessor}
          rowIndex={rowIndex}
          rowData={data}
        />
      ))}
      {context.removable && (
        <DeleteRow className={styles.deleteRow} onClick={onDeleteRow} />
      )}
    </tr>
  );
}
