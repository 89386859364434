import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock
} from 'react-bootstrap';
import { withTranslation as translate } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';
import ImageControl from './ImageControl';
import Helper from './Helper';
import { getRandomInt } from '../utils/utils';
import * as companyActionCreators from '../action-creators/company';
import * as modalActionCreators from '../action-creators/modal';
import * as servicesAction from '../action-creators/services';
import * as userActions from '../action-creators/user';
import {
  validateEmail,
  validateSystemId,
  validateUrl
} from '../utils/validateField';
import keyDebounce from '../utils/debounce';
import Checkbox from './Checkbox';

class CompanyEditor extends React.Component {
  static isEmailValid(value) {
    if (value && value.length) {
      return validateEmail(value);
    }
    return true;
  }

  static isSystemIdValid(value) {
    if (value && value.length) {
      return validateSystemId(value);
    }
    return true;
  }

  static isUrlValid(value) {
    if (value && value.length) {
      return validateUrl(value);
    }
    return true;
  }

  constructor(props) {
    super(props);
    this.state = {
      closing: true,
      requestComplete: true,
      pristineCompanyCategory: true,
      pristineCompanyName: true,
      pristineCompanyEmail: true,
      pristineCompanyLogin: true
    };
    this._onCancelClick = this.onCancelClick.bind(this);
    this._onSaveClick = this.onSaveClick.bind(this);
    this.onSetImg = this.onSetImg.bind(this);
    this.onDelImg = this.onDelImg.bind(this);
    this.onKeyPress = keyDebounce(this.onKeyPress.bind(this), 1000);
    this.isSaveDisabled = this.isSaveDisabled.bind(this);
    this.isEmailValid = this.isEmailValid.bind(this);
    this.setBranch = this.setBranch.bind(this);
    this.addBranch = this.addBranch.bind(this);
    this.removeBranch = this.removeBranch.bind(this);
    this.setRole = this.setRole.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { gaPageView, gaSend, user } = this.props;
    gaPageView('company_reg_open');
    gaSend({
      category: 'Common',
      action: 'company_reg_open',
      label: user.get('email')
    });

    const systemId = `company${getRandomInt(10000000, 99999999)}`;
    this.onChange('systemId', systemId);
    this.validateSystemIdUnique(systemId);
  }

  componentWillUnmount() {
    const { clearNewCompany } = this.props;
    if (this.state.closing) {
      clearNewCompany();
    }
  }

  onSetImg() {
    const { showModal, updateNewCompanyField, uploadLogo } = this.props;
    this.setState({ closing: false }, () => {
      showModal('SAVE_IMG_URL', {
        submit: (url) => {
          updateNewCompanyField('logo', url);

          return Promise.resolve();
        },
        onClosed: () => {
          showModal('EDIT_COMPANY_DIALOG');
          this.setState({ closing: true });
        },
        upload: async ({ file }) => {
          const src = await uploadLogo(file);
          updateNewCompanyField('logo', src);
          return Promise.resolve();
        }
      });
    });
  }

  onDelImg() {
    this.props.updateNewCompanyField('logo', null);
  }

  onCancelClick() {
    const { hideModalDialog } = this.props;
    hideModalDialog();
  }

  async onSaveClick() {
    const { badgeSocket } = this.context;
    const {
      saveNewCompany,
      user,
      showUserEditForm,
      getListOfCompanies,
      callback,
      company,
      fromMessenger
    } = this.props;
    const verifiedIndustry = company
      .get('industry')
      .split('| ')
      .filter((branch) => branch !== '')
      .join('| ');
    this.props.updateNewCompanyField('industry', verifiedIndustry);

    await saveNewCompany(!fromMessenger);
    await getListOfCompanies();

    if (callback) {
      callback();
    }

    if (!user.get('firstName') || !user.get('lastName')) {
      return showUserEditForm();
    }
    badgeSocket.emit('clear');
    return this.props.hideModalDialog();
  }

  onChange(field, value) {
    if (field === 'systemId') {
      this.setState({ requestComplete: false });
    }

    if (field === 'email') {
      this.props.updateNewCompanyField(field, value.trim());
    } else {
      this.props.updateNewCompanyField(field, value);
    }
  }

  onKeyPress(field) {
    const { company } = this.props;
    if (field === 'email' && CompanyEditor.isEmailValid(company.get('email'))) {
      this.validateEmailUnique(company.get('email'));
      if (this.state.pristineCompanyEmail) {
        this.setState({ pristineCompanyEmail: false });
      }
    }

    if (
      field === 'systemId' &&
      CompanyEditor.isSystemIdValid(company.get('systemId'))
    ) {
      this.validateSystemIdUnique(company.get('systemId'));
      if (this.state.pristineCompanyLogin) {
        this.setState({ pristineCompanyLogin: false });
      }
    }
    if (field === 'name') {
      if (this.state.pristineCompanyName) {
        this.setState({ pristineCompanyName: false });
      }
    }
  }

  getProperty(field) {
    return this.props.company.get(field) || '';
  }

  getShopValidationState() {
    const { company } = this.props;
    return (
      company &&
      company.get('name') &&
      company.get('email') &&
      company.get('systemId')
    );
  }

  getFieldValidationState(field) {
    const { company } = this.props;
    if (field === 'roles') {
      return (
        company &&
        (company.getIn(['roles', 'customer']) ||
          company.getIn(['roles', 'supplier']))
      );
    }
    return company && company.get(field).trim();
  }

  setBranch(value, n) {
    const branches = this.props.company.get('industry').split('| ');
    branches[n] = value;
    this.props.updateNewCompanyField('industry', branches.join('| ') || '');
  }

  showHelpInfo(field) {
    const { t } = this.props;
    if (!this.getFieldValidationState(field)) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Required fields empty')}
        </HelpBlock>
      );
    }
    return null;
  }

  showEmailUnique() {
    const { t, company, currentCompany } = this.props;
    if (
      company.get('email').length &&
      CompanyEditor.isEmailValid(company.get('email')) &&
      (!company.get('uniqueEmail') ||
        company.get('email') === currentCompany.get('email'))
    ) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Email already in use by another company')}
        </HelpBlock>
      );
    }
    return null;
  }

  showSystemIdUnique() {
    const { t, company, currentCompany } = this.props;
    if (
      company.get('systemId').length &&
      CompanyEditor.isSystemIdValid(company.get('systemId')) &&
      (!company.get('uniqueSystemId') ||
        company.get('systemId') === currentCompany.get('systemId'))
    ) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Name already in use by another company')}
        </HelpBlock>
      );
    }
    return null;
  }

  showValidMailInfo() {
    const { t, company } = this.props;
    if (!CompanyEditor.isEmailValid(company.get('email'))) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Wrong email format')}
        </HelpBlock>
      );
    }
    return null;
  }

  showValidSystemIdInfo() {
    const { t, company } = this.props;
    if (!CompanyEditor.isSystemIdValid(company.get('systemId'))) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('use_only_latin_or_numbers')}
        </HelpBlock>
      );
    }
    return null;
  }

  showValidUrlInfo() {
    const { t, company } = this.props;
    if (!CompanyEditor.isUrlValid(company.get('url'))) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Wrong url format')}
        </HelpBlock>
      );
    }
    return null;
  }

  inputProps(field, includeBlur = true) {
    const { trimCompanyField } = this.props;
    return {
      value: this.getProperty(field),
      onChange: (e) => this.onChange(field, e.target.value),
      onKeyUp: () => this.onKeyPress(field),
      onPaste: () => this.onKeyPress(field),
      onBlur: includeBlur ? () => trimCompanyField(field) : undefined
    };
  }

  setRole(role, value) {
    const { updateNewCompanyRole } = this.props;
    return updateNewCompanyRole(role, value);
  }

  showCancelButton() {
    const { t } = this.props;
    return (
      <Button
        bsStyle="default"
        className="pull-left"
        onClick={this._onCancelClick}>
        {t('Cancel')}
      </Button>
    );
  }

  isSaveDisabled() {
    const { company, currentCompany } = this.props;

    return !(
      this.getShopValidationState() &&
      CompanyEditor.isEmailValid(company.get('email')) &&
      company.get('uniqueEmail') &&
      company.get('email') !== currentCompany.get('email') &&
      company.get('systemId').length &&
      this.state.requestComplete &&
      CompanyEditor.isSystemIdValid(company.get('systemId')) &&
      company.get('uniqueSystemId') &&
      company.get('systemId') !== currentCompany.get('systemId') &&
      CompanyEditor.isUrlValid(company.get('url')) &&
      company.get('name').trim() !== '' &&
      (company.getIn(['roles', 'customer']) ||
        company.getIn(['roles', 'supplier']))
    );
  }

  isEmailValid() {
    const { company, currentCompany } = this.props;
    return (
      this.getFieldValidationState('email') &&
      CompanyEditor.isEmailValid(company.get('email')) &&
      company.get('uniqueEmail') &&
      company.get('email') !== currentCompany.get('email')
    );
  }

  validateEmailUnique(email) {
    const { isEmailUnique } = this.props;
    return isEmailUnique(email);
  }

  async validateSystemIdUnique(systemId) {
    const { isSystemIdUnique } = this.props;
    await isSystemIdUnique(systemId);
    this.setState({ requestComplete: true });
  }

  addBranch() {
    this.props.updateNewCompanyField(
      'industry',
      `${this.props.company.get('industry')}| `
    );
  }

  removeBranch(n) {
    const branches = this.props.company.get('industry').split('| ');
    branches.splice(n, 1);
    this.props.updateNewCompanyField('industry', branches.join('| '));
  }

  render() {
    const { t, currentCompany, company } = this.props;

    const branchesInUse = company.get('industry').split('| ');
    const allBranches = [
      { value: '', label: t('Select your category') },
      {
        value: 'Retail',
        disabled: branchesInUse.indexOf('Retail') !== -1,
        label: t('Retail')
      },
      {
        value: 'Distribution',
        disabled: branchesInUse.indexOf('Distribution') !== -1,
        label: t('Distribution')
      },
      {
        value: 'Services',
        disabled: branchesInUse.indexOf('Services') !== -1,
        label: t('Services')
      },
      {
        value: 'Auto',
        disabled: branchesInUse.indexOf('Auto') !== -1,
        label: t('Auto')
      },
      {
        value: 'Appliances',
        disabled: branchesInUse.indexOf('Appliances') !== -1,
        label: t('Appliances')
      },
      {
        value: 'Books',
        disabled: branchesInUse.indexOf('Books') !== -1,
        label: t('Books')
      },
      {
        value: 'Raw materials and other materials',
        disabled:
          branchesInUse.indexOf('Raw materials and other materials') !== -1,
        label: t('Raw materials and other materials')
      },
      {
        value: 'Office Supplies',
        disabled: branchesInUse.indexOf('Office Supplies') !== -1,
        label: t('Office Supplies')
      },
      {
        value: 'Children Products',
        disabled: branchesInUse.indexOf('Children Products') !== -1,
        label: t('Children Products')
      },
      {
        value: 'Home, Garden, Tools',
        disabled: branchesInUse.indexOf('Home, Garden, Tools') !== -1,
        label: t('Home, Garden, Tools')
      },
      {
        value: 'Entertaiment',
        disabled: branchesInUse.indexOf('Entertaiment') !== -1,
        label: t('Entertaiment')
      },
      {
        value: 'Computers',
        disabled: branchesInUse.indexOf('Computers') !== -1,
        label: t('Computers')
      },
      {
        value: 'Health and Beauty',
        disabled: branchesInUse.indexOf('Health and Beauty') !== -1,
        label: t('Health and Beauty')
      },
      {
        value: 'Equipment',
        disabled: branchesInUse.indexOf('Equipment') !== -1,
        label: t('Equipment')
      },
      {
        value: 'Clothes, shoes and accessories',
        disabled:
          branchesInUse.indexOf('Clothes, shoes and accessories') !== -1,
        label: t('Clothes, shoes and accessories')
      },
      {
        value: 'Gifts and Flowers',
        disabled: branchesInUse.indexOf('Gifts and Flowers') !== -1,
        label: t('Gifts and Flowers')
      },
      {
        value: 'Food',
        disabled: branchesInUse.indexOf('Food') !== -1,
        label: t('Food')
      },
      {
        value: 'Sport',
        disabled: branchesInUse.indexOf('Sport') !== -1,
        label: t('Sport')
      },
      {
        value: 'Pet Supplies',
        disabled: branchesInUse.indexOf('Pet Supplies') !== -1,
        label: t('Pet Supplies')
      },
      {
        value: 'Electronics',
        disabled: branchesInUse.indexOf('Electronics') !== -1,
        label: t('Electronics')
      },
      {
        value: 'Other',
        disabled: branchesInUse.indexOf('Other') !== -1,
        label: t('Other')
      }
    ];

    const CompanyBranch = ({ value, n }) => (
      <Select
        className="branch-select"
        value={value}
        options={allBranches}
        onChange={(e) => {
          this.setBranch(e.value, n);
          if (this.state.pristineCompanyCategory) {
            this.setState({ pristineCompanyCategory: false });
          }
        }}
        searchable={false}
        clearable={false}
      />
    );

    const companyRoles =
      company.get('roles') &&
      company.get('roles').size > 0 &&
      Object.keys(company.get('roles').toJS());

    return (
      <Modal id="companyModalWindow" enforceFocus show>
        <Modal.Header>
          <Modal.Title>
            {t('Write information about your company')}
            <span
              className="modal-header-cross pull-right"
              onClick={this._onCancelClick}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span
            style={{
              position: 'absolute',
              right: 42,
              width: 281,
              fontSize: 12,
              color: '#999999'
            }}>
            {t('Main image')}
          </span>
          <ImageControl
            name="companyImage"
            imgSrc={company.get('logo')}
            onFileChoose={this.onSetImg}
            onImageRemove={this.onDelImg}
            style={{
              position: 'absolute',
              right: '42px',
              width: '281px',
              height: '281px',
              top: '36px',
              zIndex: 1
            }}
          />
          <div style={{ marginRight: '300px' }}>
            <FormGroup controlId="industry">
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {t('Company category')}
              </ControlLabel>
              {company
                .get('industry')
                .split('| ')
                .map((branch, n) => (
                  <div style={{ marginBottom: 10 }} key={n}>
                    <div style={{ width: '80%', display: 'inline-block' }}>
                      <CompanyBranch
                        branches={company.get('industry').split('| ')}
                        value={branch}
                        n={n}
                      />
                    </div>
                    {company.get('industry') &&
                      company.get('industry').split('| ').length < 5 &&
                      n === 0 && (
                        <img
                          alt=""
                          role="presentation"
                          onClick={this.addBranch}
                          className="profile-category-sign"
                          src="/img/add.svg"
                        />
                      )}
                    {n > 0 && (
                      <img
                        alt=""
                        role="presentation"
                        onClick={() => this.removeBranch(n)}
                        className="profile-category-sign"
                        src="/img/remove.svg"
                      />
                    )}
                  </div>
                ))}
            </FormGroup>
            <FormGroup
              controlId="name"
              validationState={
                this.getFieldValidationState('name') ||
                this.state.pristineCompanyName
                  ? null
                  : 'error'
              }>
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {t('Company name')}
                <span style={{ color: 'red' }}>* </span>
              </ControlLabel>
              <h6 className="pull-right" style={{ marginTop: 5 }}>
                <small>
                  {this.getProperty('name').length}
                  /60
                </small>
              </h6>
              <FormControl
                type="text"
                maxLength="60"
                value={company.get('name')}
                {...this.inputProps('name')}
              />
              {!this.state.pristineCompanyName && this.showHelpInfo('name')}
            </FormGroup>
            <FormGroup
              controlId="roles"
              validationState={
                this.getFieldValidationState('roles') ? null : 'error'
              }>
              <ControlLabel className="control-label-row">
                {t('Roles')}
                <span style={{ color: 'red' }}> * </span>
              </ControlLabel>
              <div>
                {companyRoles.map((key) => (
                  <div
                    style={{ display: 'inline-block', paddingRight: '10px' }}
                    key={key}>
                    <Checkbox
                      set={company.getIn(['roles', key])}
                      onClick={() =>
                        this.setRole(key, !company.getIn(['roles', key]))
                      }
                    />
                    <span className="profile-checkbox-label">{t(key)}</span>
                  </div>
                ))}
              </div>
            </FormGroup>
            <FormGroup
              controlId="email"
              validationState={
                this.isEmailValid() || this.state.pristineCompanyEmail
                  ? null
                  : 'error'
              }>
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {`${t('Company email address')} (${t('only latin')})`}
                <span style={{ color: 'red' }}>* </span>
                <Helper text="Please enter a valid email adress It will be used for service notification" />
              </ControlLabel>
              <FormControl
                type="text"
                value={company.get('email')}
                {...this.inputProps('email')}
                placeholder="example@email.com"
                autoComplete="off"
              />
              {!this.state.pristineCompanyEmail && this.showHelpInfo('email')}
              {this.showEmailUnique()}
              {this.showValidMailInfo()}
            </FormGroup>
            <FormGroup
              controlId="uniqueId"
              validationState={
                (this.getFieldValidationState('systemId') &&
                  CompanyEditor.isSystemIdValid(company.get('systemId')) &&
                  company.get('uniqueSystemId') &&
                  company.get('systemId') !== currentCompany.get('systemId')) ||
                this.state.pristineCompanyLogin
                  ? null
                  : 'error'
              }>
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {`${t('Name in system')} (${t('only latin')})`}
                <span style={{ color: 'red' }}>* </span>
                <Helper text="unique_name_company" />
              </ControlLabel>
              <FormControl
                type="text"
                value={company.get('systemId')}
                {...this.inputProps('systemId')}
                autoComplete="off"
                placeholder={t('examplegoodwix')}
              />
              {!this.state.pristineCompanyLogin &&
                this.showHelpInfo('systemId')}
              {this.showSystemIdUnique()}
              {this.showValidSystemIdInfo()}
            </FormGroup>
            <FormGroup
              controlId="url"
              validationState={
                // eslint-disable-next-line no-nested-ternary
                company.get('url')
                  ? CompanyEditor.isUrlValid(company.get('url'))
                    ? 'success'
                    : 'error'
                  : null
              }>
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {t('Company URL')}
              </ControlLabel>
              <FormControl
                type="text"
                value={company.get('url')}
                {...this.inputProps('url')}
              />
              {this.showValidUrlInfo()}
            </FormGroup>
            <FormGroup controlId="description">
              <h6 className="pull-right" style={{ marginTop: 5 }}>
                <small>
                  {this.getProperty('description').length}
                  /1600
                </small>
              </h6>
              <FormControl
                componentClass="textarea"
                value={company.get('description')}
                maxLength="1600"
                rows="5"
                placeholder={t('Company description')}
                {...this.inputProps('description')}
              />
            </FormGroup>
          </div>
          <span style={{ color: 'red' }}>* </span>
          {t('Required fields')}
        </Modal.Body>
        <Modal.Footer>
          {this.showCancelButton()}
          <Button
            id="saveCompanyButton"
            bsStyle="primary"
            disabled={this.isSaveDisabled()}
            onClick={this._onSaveClick}>
            {t('Save')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

CompanyEditor.contextTypes = {
  badgeSocket: PropTypes.object
};

const mapStateToProps = (state) => ({
  company: state.getIn(['company', 'newCompany']),
  currentCompany: state.get('company'),
  user: state.getIn(['user', 'user'])
});

export default translate(['ui'], { wait: true })(
  connect(mapStateToProps, {
    ...companyActionCreators,
    ...modalActionCreators,
    ...servicesAction,
    ...userActions
  })(CompanyEditor)
);
