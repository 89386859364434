import { path } from 'ramda';
import { COMPANY_FEATURES } from '@root/entities';
import { purchaseRequestTypes } from './domain/entities';
import { unitTypes } from './entities/Products';

export const isResponseAllowed = (request) => {
  if (typeof request.get('isResponseAllowed') !== 'boolean') {
    console.error('Invalid prop [isResponseAllowed] was passed');
    return false;
  }

  return request.get('isResponseAllowed');
};

export const isAuctionType = (requestType) =>
  [
    purchaseRequestTypes.auction,
    purchaseRequestTypes.auctionWithConfirmation
  ].includes(requestType);

export const isResponseLinkToAuction = (response) => {
  const requestType = path(
    ['attrs', 'purchaseRequest', 'requestType'],
    response
  );
  return [
    purchaseRequestTypes.auction,
    purchaseRequestTypes.auctionWithConfirmation
  ].includes(requestType);
};

export const isWithConfirmationType = (requestType) =>
  [
    purchaseRequestTypes.auctionWithConfirmation,
    purchaseRequestTypes.regularWithConfirmationRequest
  ].includes(requestType);

export function getLocationTitle(location, language) {
  if (!location) return '';

  return location.translate[language] || '';
}

export const unitsMustBeInt = Object.freeze([
  unitTypes.instances,
  unitTypes.amount,
  unitTypes.packages
]);

export const validateProductCountBasedOnUnit = (count, unit) => {
  if (!Number.isInteger(+count) && unitsMustBeInt.includes(unit)) {
    return false;
  }
  return true;
};

export const isResponseFeatureBlocked = (blockedFeatures) => {
  const res = blockedFeatures.find(
    (feature) => feature.id === COMPANY_FEATURES.purchaseResponse
  );
  return !!res;
};
