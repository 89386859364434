export const toSaveCompanyRequisitesDto = (values, isHintChecked) => ({
  actualAddress: values.actualAddress || null,
  fullBankName: values.bankName || null,
  correspondentAccount: values.account || null,
  bik: values.bankBic || null,
  logo: values.logo || null,
  description: values.description || null,
  country: values.country || null,
  region: values.region || null,
  postAddress: values.postalAddress || null,
  isHintMarkAsRead: isHintChecked
});
