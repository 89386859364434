import React from 'react';
import { connect } from 'react-redux';
import { getLastMessageRenderMode } from '../../../../getter';
import LastMessage from './last-message';
import Typing from './Typing';
import DraftInfo from './draftInfo';

const MessageInfo = ({
  employeeId,
  lastMessage,
  chatType,
  chat,
  containsUnreadMsg,
  showProfile,
  typingList,
  renderMode,
  channelMember,
  interlocutor,
  chatId
}) => (
  <div className="cws-chat-item__last-message-info">
    {renderMode === 'typing' && (
      <Typing typingList={typingList} isDialog={chatType === 'Dialog'} />
    )}
    {renderMode === 'draft-message' && <DraftInfo chatId={chatId} />}
    {(renderMode === 'last-message' || renderMode === 'unread-message') && (
      <LastMessage
        lastMessage={lastMessage}
        chatType={chatType}
        chat={chat}
        containsUnreadMsg={containsUnreadMsg}
        employeeId={employeeId}
        showProfile={showProfile}
        channelMember={channelMember}
        interlocutor={interlocutor}
      />
    )}
  </div>
);

export default connect(
  (state, ownProps) => ({
    renderMode: getLastMessageRenderMode(state, ownProps.chatId)
  }),
  null
)(MessageInfo);
