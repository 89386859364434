import React, { useEffect, useState } from 'react';
import { isNil } from 'ramda';

import { useSelector } from 'react-redux';
import { useActions } from '@hooks/useActions';
import { useDebounce } from '@hooks/useDebounce';
import { Button, Modal } from '@link/react-components';
import { createSelector } from 'reselect';
import * as modalActions from '../../../action-creators/modal';
import { useTranslate } from '../../../TranslateProvider';
import '../styles/modal_theme_default.styl';

import SearchBlock from '../../ChatWidget/SearchBlock';
import { UserList } from './components/userList';
import styles from './ModalAddUserToConstructor.module.css';
import * as getters from '../../ChatWidget/modules/constructor/getters';

const ModalAddUserToConstructor = ({ action = () => {}, actionArgs = {} }) => {
  const t = useTranslate();
  const list = useSelector(
    createSelector(getters.getConstructorUsersList, (users) =>
      users.map((user) => ({ ...user, currentStepType: actionArgs.stepType }))
    )
  );

  const [search, setSearch] = useState('');

  const [selected, setSelected] = useState(null);

  const { hideModalDialog } = useActions(modalActions);

  const [users, setUsers] = useState(list);

  const onClickSubmit = () => {
    action({ ...actionArgs, user: selected });

    hideModalDialog();
  };

  const onFilterUsers = useDebounce((searchValue) => {
    const searchRegExp = new RegExp(searchValue, 'i');
    setUsers(
      list.filter((user) =>
        searchRegExp.test(`${user.firstName} ${user.lastName}`)
      )
    );
  }, 500);

  useEffect(() => {
    onFilterUsers(search);
  }, [search, list.length]);

  const onChangeSearchInput = (value) => {
    setSearch(value);
  };

  return (
    <Modal onClose={hideModalDialog} open className={styles.modal}>
      <Modal.Header>{t('constructor.listOfUsers')}</Modal.Header>
      <Modal.Content className={styles.content}>
        <SearchBlock
          className={styles.inputBlock}
          onChange={onChangeSearchInput}
          value={search}
          placeholder={t('constructor.searchUser')}
          width="auto"
        />
        <UserList users={users} selected={selected} setSelected={setSelected} />
      </Modal.Content>
      <Modal.Footer align="right">
        <Modal.Actions>
          <Button
            mode="primary"
            onClick={onClickSubmit}
            disabled={isNil(selected)}>
            {t('modals.constructor.assign')}
          </Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalAddUserToConstructor;
