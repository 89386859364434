import React from 'react';
import cn from 'classnames';
import { Loader } from '@link/react-components';
import { Hint } from './components';
import MemberCard from '../../../MemberCard';
import { ButtonCamera, ButtonMicrophone } from '../../../Buttons';
import { useVideoChatContext } from '../../../../hooks';
import c from './Preview.module.css';

export const Preview = ({ className }) => {
  const { currentMember } = useVideoChatContext();

  return (
    <div className={cn(className, c.container)}>
      <div className={c.preview}>
        {currentMember ? (
          <MemberCard className={c.video} member={currentMember} />
        ) : (
          <Loader className={c.loader} />
        )}
      </div>
      <div className={c.actions}>
        <ButtonMicrophone variant="outline" />
        <ButtonCamera variant="outline" />
      </div>
      <Hint className={c.hint} />
    </div>
  );
};
