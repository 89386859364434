import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { withTranslation as translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  FormGroup,
  FormControl,
  Button,
  HelpBlock,
  ControlLabel
} from 'react-bootstrap';

import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';

import { validateEmail } from '../../utils/validateField';

import * as modalActions from '../../action-creators/modal';
import * as companyActions from '../../action-creators/company';
import * as serviceActions from '../../action-creators/services';

const b = block('modal-container');

class ModalChangeCompanyEmail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      codeField: false,
      code: '',
      pristineEmailField: true
    };
    this.setEmail = this.setEmail.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
  }

  getChildContext() {
    return { b };
  }

  setEmail(e) {
    this.setState({
      email: e.target.value.trim(),
      codeField: false,
      error: ''
    });
  }

  validateEmail() {
    return !validateEmail(this.state.email);
  }

  isEmailEmpty() {
    return this.state.email.length === 0;
  }

  asterisk() {
    return <span style={{ color: 'red' }}>*</span>;
  }

  render() {
    const {
      t,
      changeEmail,
      changeEmailVerifyCode,
      hideModalDialog,
      user,
      company,
      gaSend
    } = this.props;

    return (
      <Modal id="inviteEmployee" show>
        <ModalContainer mods={{ theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('Change company email')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup
              controlId="email"
              validationState={
                (!this.validateEmail() || this.state.pristineEmailField) &&
                !(this.state.error && this.state.error.type === 'email')
                  ? null
                  : 'error'
              }
              style={{ overflow: 'hidden' }}>
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {`${t('Enter email')} `}
                {this.asterisk()}
              </ControlLabel>
              <FormControl
                type="text"
                placeholder="example@email.com"
                value={this.state.email}
                onChange={this.setEmail}
                onKeyDown={() => {
                  if (this.state.pristineEmailField) {
                    this.setState({ pristineEmailField: false });
                  }
                }}
              />
              {this.isEmailEmpty() && !this.state.pristineEmailField && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t('Required fields empty')}
                </HelpBlock>
              )}
              {this.validateEmail() && !this.isEmailEmpty() && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t('Wrong email format')}
                </HelpBlock>
              )}
              {company.get('email') === this.state.email && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t('Email must be different')}
                </HelpBlock>
              )}
              {this.state.error && this.state.error.type === 'email' && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t(this.state.error.msg)}
                </HelpBlock>
              )}
            </FormGroup>
            {this.state.codeField && (
              <FormGroup
                controlId="code"
                validationState={
                  this.state.error && this.state.error.type === 'code'
                    ? 'error'
                    : null
                }
                style={{ overflow: 'hidden' }}>
                <ControlLabel
                  style={{
                    color: '#999999',
                    fontWeight: 'normal',
                    fontSize: '14px'
                  }}>
                  {t('Enter code')}
                </ControlLabel>
                <FormControl
                  type="text"
                  value={this.state.code}
                  onChange={(e) => this.setState({ code: e.target.value })}
                />
                {this.state.error && this.state.error.type === 'code' && (
                  <HelpBlock style={{ marginBottom: 0 }}>
                    {t(this.state.error.msg)}
                  </HelpBlock>
                )}
              </FormGroup>
            )}
            <div>
              {this.asterisk()}
              {` ${t('Required fields')}`}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => hideModalDialog()}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              disabled={
                this.validateEmail() ||
                company.get('email') === this.state.email
              }
              onClick={async () => {
                if (!this.state.codeField) {
                  const error = await changeEmailVerifyCode(this.state.email);
                  await gaSend({
                    category: 'Company',
                    action: 'change_company_email',
                    label: user.get('email')
                  });
                  if (!error) {
                    await this.setState({ codeField: true });
                  } else {
                    await this.setState({ error });
                  }
                } else {
                  const error = await changeEmail(
                    this.state.email,
                    this.state.code
                  );
                  if (!error) {
                    hideModalDialog();
                  } else {
                    await this.setState({ error });
                  }
                }
              }}>
              {t('Change')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalChangeCompanyEmail.propTypes = {
  t: PropTypes.func.isRequired
};

ModalChangeCompanyEmail.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    user: state.getIn(['user', 'user']),
    company: state.get('company')
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog,
        changeEmailVerifyCode: companyActions.changeEmailVerifyCode,
        changeEmail: companyActions.changeEmail,
        gaSend: serviceActions.gaSend
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalChangeCompanyEmail));
