function transformToModel(model) {
  return Object.entries(model).reduce(
    (acc, [key, obj]) => ({ ...acc, [key]: obj.value }),
    {}
  );
}

export const inviteEmployeeFormMapper = {
  transformToModel
};
