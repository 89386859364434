.menu:global(.MuiPaper-root) {
  margin-top: var(--indent-xxs);
  border-radius: var(--indent-xxs);
  box-shadow: 0 3px 7px rgba(36 48 103 / 20%);
  padding: var(--indent-xs);
  background-color: var(--color-white);
}

.list:global(.MuiMenu-list) {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: var(--indent-xxs); // TODO когда будем улучшать компонент, вынести в Enum
  padding: 0;
}

.item:global(.MuiMenuItem-root) {
  padding: var(--indent-xs);
  border-radius: var(--indent-xxs);
  width: 100%;
}

.item:hover:global(.MuiMenuItem-root) {
  background-color: var(--color-blue-50);
}

.delimiter {
  width: 100%;
  height: 1px;
  border-top: 1px solid var(--color-gray-400);
}

.container {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.groupItem {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.groupItem:hover {
  background-color: var(--color-blue-50);
  border-radius: var(--indent-xxs);
}

.disabled :global(.MuiMenuItem-disabled) {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}
