import React from 'react';
import { connect } from 'react-redux';

import CWASChats from './list/chats';
import CWASContacts from './list/contacts';
import EmptySearchResults from './emptySearch';

import './style.css';
import { generateList, getShowMode } from './list/generator';
import { loadMoreMsgs } from './messages';

const config = {
  contacts: {
    clickByRow: 'view-profile',
    extra: {
      list: [
        { icon: 'person', value: 'view-profile' },
        { icon: 'folder', value: 'add-to-list' },
        {
          conditionName: 'block-person',
          items: [
            { icon: 'unblock', value: 'unblock' },
            { icon: 'block', value: 'block', color: 'red' }
          ]
        },
        { icon: 'delete', value: 'delete', color: 'red' }
      ],
      adminList: [{ icon: 'person', value: 'view-profile' }]
    }
  },
  chats: {
    extraMenu: {
      icon: 'extra',
      position: 'left-start',
      possiblePositions: ['left-center'],
      fixedMenuPos: false,
      type: 'chats'
    },
    type: 'chats'
  }
};

const AdvancedSearch = ({ showMode, viewModel }) => {
  const { chats, contacts, messages, companies, isSearchByNickname } =
    viewModel;

  if (showMode === 'empty') return <EmptySearchResults />;

  return (
    <div className="cws-list-search">
      <CWASContacts contacts={contacts} tag="contacts" />
      {!isSearchByNickname && (
        <CWASChats
          chats={messages.list}
          tag="messages"
          canLoadMore={messages.canLoadMore}
          onClickLoadMore={() => loadMoreMsgs.emit()}
        />
      )}
      {!isSearchByNickname && <CWASChats chats={chats} tag="chats" />}
      {!isSearchByNickname && (
        <CWASContacts contacts={companies} tag="companies" />
      )}
    </div>
  );
};

export default connect(
  (state) => ({
    showMode: getShowMode(state),
    viewModel: generateList(state, { config })
  }),
  null
)(AdvancedSearch);
