import React, { useCallback, useEffect, useRef, useState } from 'react';
import { EditorState, Modifier, SelectionState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { useUpdateEffect } from 'react-use';
import { OrderedSet } from 'immutable';
import { useCacheState } from './hooks/cacheState';
import { useMessageFromOutside } from './hooks/setMessageFromOutside';
import { getPlainText } from './utils';

export const useDraftEditor = ({
  editorRef,
  editorId,
  files,
  onTyping,
  onChange,
  onUpdateEditorId
}) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const editorStateRef = useRef(editorState);
  const filesRef = useRef(files);
  const editorStateInitialStylesRef = useRef([]);
  useCacheState({ editorId, editorState, setEditorState, clearContent });
  useMessageFromOutside({ editorRef, editorState, setEditorState });

  useEffect(() => {
    editorStateRef.current = editorState;
  }, [editorState]);

  useEffect(() => {
    filesRef.current = files;
  }, [files]);

  useEffect(() => {
    onUpdateEditorId();
  }, [editorId]);

  useUpdateEffect(() => {
    onTyping();
  }, [getPlainText(editorState)]);

  useEffect(() => {
    onChange(getPlainText(editorState), files);
  }, [getPlainText(editorState), files]);

  const onChangeES = useCallback(
    (_editorState) => {
      const current = _editorState.getCurrentInlineStyle().toJS();
      const override = _editorState.getInlineStyleOverride()?.toJS() || null;

      if (Array.isArray(override)) {
        editorStateInitialStylesRef.current = current;
      }
      const newEditor = EditorState.setInlineStyleOverride(
        _editorState,
        OrderedSet(editorStateInitialStylesRef.current)
      );
      setEditorState(newEditor);
    },
    [editorStateInitialStylesRef]
  );

  const onSend = useCallback(
    (cb) => {
      cb(convertFromEditorState(editorStateRef.current, filesRef.current));
      editorStateInitialStylesRef.current = [];
      setEditorState(clearContent(editorStateRef.current));
    },
    [editorStateRef, filesRef, editorStateInitialStylesRef]
  );

  return {
    _editorState: editorState,
    _onChangeES: onChangeES,
    text: getPlainText(editorState),
    onSend
  };
};

function createMentionSpan(mention) {
  return React.createElement(
    'span',
    {
      className: 'rich-text-mention',
      'data-type': 'mention',
      'data-employee-id': mention.employeeId,
      'data-avatar': mention.avatar
    },
    mention.name
  );
}

function convertFromEditorState(es, files) {
  const contentState = es.getCurrentContent();
  const mentionsInMsg = [];
  const options = {
    entityToHTML: (entity, originalText) => {
      if (entity.type === 'mention') {
        const { mention } = entity.data;
        mentionsInMsg.push(mention.employeeId);
        return createMentionSpan(mention);
      }
      return originalText;
    }
  };
  const html = convertToHTML(options)(contentState);

  return {
    html,
    msg: getPlainText(es),
    mentions: mentionsInMsg,
    files
  };
}

function clearContent(es) {
  let contentState = es.getCurrentContent();
  const firstBlock = contentState.getFirstBlock();
  const lastBlock = contentState.getLastBlock();
  const allSelected = new SelectionState({
    anchorKey: firstBlock.getKey(),
    anchorOffset: 0,
    focusKey: lastBlock.getKey(),
    focusOffset: lastBlock.getLength(),
    hasFocus: true
  });
  contentState = Modifier.removeRange(contentState, allSelected, 'backward');
  let updatedEditorState = EditorState.push(es, contentState, 'remove-range');
  updatedEditorState = EditorState.forceSelection(
    updatedEditorState,
    contentState.getSelectionAfter()
  );
  return updatedEditorState;
}
