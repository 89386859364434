import React from 'react';
import { FormGroup, FormControl, HelpBlock } from 'react-bootstrap';
import { withTranslation as translate } from 'react-i18next';

export default translate(['ui'], { wait: true })(
  ({ placeholder, type, t, meta: { touched, error }, input }) => {
    const hasError = touched && error;

    return (
      <FormGroup validationState={hasError ? 'error' : null}>
        <FormControl {...input} type={type} placeholder={placeholder} />
        {hasError ? <HelpBlock>{t(error)}</HelpBlock> : null}
      </FormGroup>
    );
  }
);
