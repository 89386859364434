import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ControlLabel, Button } from 'react-bootstrap';
import { reduxForm, Field } from 'redux-form/immutable';
import { withTranslation as translate } from 'react-i18next';

import resetPasswordPerformValidator from '../utils/resetPasswordPerformValidator';
import SecurityBoxLogo from '../components/MessengerAuth/SecurityBoxLogo';
import SecurityBoxInputsWrap from '../components/MessengerAuth/SecurityBoxInputsWrap';
import SecurityBoxInput from '../components/MessengerAuth/SecurityBoxInput';

import * as auth from '../action-creators/auth';
import { withRouter } from '../hoc/withRouter';

class MessengerResetPassPerform extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = { success: false };
  }

  UNSAFE_componentWillMount() {
    const { tokenVerification, location } = this.props;

    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    tokenVerification(token);
  }

  async onSubmit(val) {
    const { token, updateTokenVerificationError, resetPasswordPerform } =
      this.props;

    const password = val.get('password');
    try {
      const res = await resetPasswordPerform(token, password);
      if (res.ok) {
        this.setState({ success: true });
        // history.push('/login');
      } else {
        const data = await res.json();
        updateTokenVerificationError(data.errors[0].code);
      }
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { t, handleSubmit, tokenError, token, email } = this.props;
    return (
      <div className="auth-box auth-box-register">
        <SecurityBoxLogo />
        <div className="small-form" style={{ height: 350 }}>
          <SecurityBoxInputsWrap>
            {/* eslint-disable-next-line no-nested-ternary */}
            {tokenError || !token ? (
              <div style={{ color: 'red' }}>{t(tokenError)}</div>
            ) : // eslint-disable-next-line react/destructuring-assignment
            !this.state.success ? (
              <div>
                <div>
                  <ControlLabel
                    style={{
                      fontSize: 18,
                      color: '#252b49',
                      fontWeight: 'normal',
                      marginTop: 15,
                      marginBottom: 15
                    }}>
                    {t('create_new_password')}
                  </ControlLabel>
                </div>
                <div style={{ marginBottom: 15 }}>
                  <ControlLabel className="control-label-login">
                    {t('Email')}
                  </ControlLabel>
                  <div style={{ color: '#000' }}>{email}</div>
                </div>
                <ControlLabel className="control-label-login">
                  {t('Password')}
                  <span style={{ color: 'red' }}>*</span>
                </ControlLabel>
                <Field
                  name="password"
                  component={SecurityBoxInput}
                  type="password"
                  placeholder={t('Min length 8 symbols')}
                />
                <ControlLabel className="control-label-login">
                  {t('Repeat password')}
                  <span style={{ color: 'red' }}>*</span>
                </ControlLabel>
                <Field
                  name="repeatPassword"
                  component={SecurityBoxInput}
                  marginTop="true"
                  type="password"
                  placeholder={t('Min length 8 symbols')}
                />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 35
                  }}>
                  <Button
                    bsStyle="primary"
                    style={{ width: 220 }}
                    onClick={handleSubmit(this.onSubmit)}>
                    {t('Get password')}
                  </Button>
                </div>
              </div>
            ) : (
              <div style={{ paddingTop: 64 }}>
                <p
                  style={{
                    fontSize: '18px',
                    paddingBottom: 5,
                    display: 'flex',
                    justifyContent: 'center'
                  }}>
                  <img src="/img/ico_ok.svg" alt="" />
                </p>
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: 14,
                    color: '#252b49',
                    marginTop: 24
                  }}>
                  {t('reset_success_text')}
                </p>
                <p
                  style={{ textAlign: 'center', marginLeft: 5, marginTop: 90 }}>
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: '#767b92',
                      fontWeight: 500,
                      fontSize: 14
                    }}
                    to="/login">
                    {t('to_main')}
                  </Link>
                </p>
              </div>
            )}
          </SecurityBoxInputsWrap>
        </div>
      </div>
    );
  }
}

const ResetPasswordPerformForm = reduxForm({
  form: 'resetPasswordPerform',
  validate: resetPasswordPerformValidator
})(MessengerResetPassPerform);

export default withRouter(
  connect(
    (state) => ({
      tokenError: state.getIn(['resetPassword', 'tokenError']),
      token: state.getIn(['resetPassword', 'token']),
      email: state.getIn(['resetPassword', 'email'])
    }),
    auth
  )(translate()(ResetPasswordPerformForm))
);
