import queryString from 'query-string';
import request from '../../lib/request';

class PublicPurchaseResponseService {
  #baseUrl = '/api/quick-response';

  async getRequest(id, { token, lang } = {}) {
    const queryStr = queryString.stringify({ token, lang }, { skipNull: true });
    const query = queryStr ? `?${queryStr}` : '';

    return request.get(`${this.#baseUrl}/requests/${id}${query}`);
  }
}

export const publicPurchaseResponseService =
  new PublicPurchaseResponseService();
