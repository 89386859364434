import React, { useRef } from 'react';
import { withTranslation as translate } from 'react-i18next';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import useOutsideClick from '../../../../hooks/outsideClick';
import * as useCases from '../../../../modules/topics/useCases';
import { gaSendMessengerAction } from '../../../../modules/GA';
import {
  makeAttachFilesMenuVM,
  getAttachListMode,
  initAttachStorage,
  initAttachLocal,
  initOnToggle,
  getButtonRef,
  initEmmitClickFile,
  getFileInputRef,
  hideWhenClickOutside
} from './dataType';
import { getNotRemovedFiles } from '../../getters';
import { getChatInput } from '../../../../getter';

import './style.css';
import useToggle from '../../../../hooks/toggle';

const wrapper = (chatId) => {
  const onAttachLocalFiles = bindActionCreators(
    useCases.attachLocalFiles,
    useDispatch()
  );
  const onAttachStorageFiles = bindActionCreators(
    useCases.attachFromStorage,
    useDispatch()
  );
  const gaSend = bindActionCreators(gaSendMessengerAction, useDispatch());
  const input = useSelector(getChatInput);
  const files = useSelector(getNotRemovedFiles());

  const { isOpen, hide, toggle } = useToggle();

  const buttonRef = useRef(null);
  const fileInputRef = useRef(null);

  return makeAttachFilesMenuVM({
    chatId,
    isOpen,
    input,
    files,
    toggle,
    hide,
    buttonRef,
    fileInputRef,
    onAttachLocalFiles,
    onAttachStorageFiles,
    gaSend,
    onClickOutside: useOutsideClick
  });
};

const CWAttachFileMenu = ({ t, chatId }) => {
  const vm = wrapper(chatId);

  hideWhenClickOutside(vm);

  return (
    <div
      className="cws-input-msg__btn-attach"
      ref={getButtonRef(vm)}
      onClick={initOnToggle(vm)}>
      <div className="cws-input-msg-btn-attach__list-wrapper">
        <img
          src="/img/chat-widget/attach.svg"
          height={22}
          width={22}
          alt="icon-attach"
        />
        <div
          className={`cws-input-msg-btn-attach__list ${getAttachListMode(vm)}`}>
          <div
            className="cws-input-msg-btn-attach__item"
            onClick={initAttachStorage(vm)}>
            {t('chat-widget.section.input-msg.attach-storage')}
          </div>
          <div
            className="cws-input-msg-btn-attach__item"
            onClick={initEmmitClickFile(vm)}>
            {t('chat-widget.section.input-msg.attach-local')}
            <input
              type="file"
              style={{ display: 'none' }}
              multiple
              ref={getFileInputRef(vm)}
              onChange={initAttachLocal(vm)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default translate()(CWAttachFileMenu);
