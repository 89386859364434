import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import { Typography, Loader } from '@link/react-components';
import { useTranslate, useAsync } from '@hooks';
import { companyService } from '@api';
import * as storeGetters from '@root/storeGetters';
import {
  FilesBlock,
  FILES_BLOCK_MODES,
  LinksBlock,
  LINKS_BLOCK_MODES,
  GoToIstock,
  GoToDialog
} from '@root/features';
import { formatDate } from '@root/utils/date';
import { getUserDisplayName } from '@root/utils/utils';
import { numberFormatter } from '@root/utils/number';
import { unitTypes } from '@root/components/PurchaseRequests/entities/Products';
import styles from './NeedPreview.module.css';

const Property = ({ label, value, html, endAdornment: EndAdornment }) => (
  <div>
    <Typography variant="body1Med" className={styles.propertyLabel}>
      {label}
    </Typography>
    {html ? (
      <Typography
        variant="body1Reg"
        className={styles.propertyHtml}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(html) }}
      />
    ) : (
      <Typography variant="body1Reg" className={styles.propertyValue}>
        {value}
        {EndAdornment}
      </Typography>
    )}
  </div>
);

export const NeedPreview = ({ needId, onOpenedChat }) => {
  const t = useTranslate();
  const language = useSelector(storeGetters.getCurrentUserLanguage);
  const companyId = useSelector(storeGetters.getCurrentCompanyId);
  const employeeId = useSelector(storeGetters.getCurrentEmployeeId);

  function translate(tKey) {
    return t(`modules.needs.preview.${tKey}`);
  }

  const { loading, value: need } = useAsync(
    async () => companyService.getNeedPreview({ needId, companyId }),
    [needId]
  );

  const categoriesText = useMemo(() => {
    if (!need) return '';

    return need.categories
      .map((category) => category.text[language])
      .join(', ');
  }, [language, need]);

  const files = useMemo(() => {
    if (!need) return [];

    // TODO исправить контракт с компонентом FilesBlock
    return need.attachments.map((attachment) => ({
      ...attachment.fileInfo,
      fileInfo: attachment.fileInfo
    }));
  }, [need]);

  if (loading) return <Loader />;

  const shownGoToIstock = Boolean(need.metadata?.externalId);
  const shownGoToDialog = Boolean(need.author && need.author.id !== employeeId);
  const shownComment = Boolean(need.comment);

  const unitDisplayName = unitTypes[need.unit]
    ? t(`units.${need.unit}`)
    : need.unit;
  const quantity = `${numberFormatter(language)(
    need.count
  )} ${unitDisplayName}`;

  const authorName = need.author?.id
    ? getUserDisplayName(need.author, {
        includedFields: ['lastName', 'firstName', 'middleName'],
        separator: ' '
      })
    : '-';

  return (
    <div className={styles.container}>
      <div className={styles.titleBlock}>
        <Typography variant="h2" className={styles.title}>
          {need.name}
        </Typography>
        {shownGoToIstock && (
          <GoToIstock
            className={styles.externalButton}
            externalId={need.metadata.externalId}
          />
        )}
      </div>
      {need?.identifier && (
        <Property
          label={`${translate('identifier')}:`}
          value={need.identifier}
        />
      )}
      <div className={styles.infoRow}>
        <Property label={`${translate('quantity')}:`} value={quantity} />
        <Property
          label={`${translate('dateOfCreate')}:`}
          value={formatDate(need.createdAt, 'withOutTime')}
        />
        <Property
          label={`${translate('dateOfNeed')}:`}
          value={formatDate(need.eta, 'withOutTime')}
        />
      </div>
      <Property
        label={`${translate('author')}:`}
        value={authorName}
        endAdornment={
          shownGoToDialog && (
            <GoToDialog
              variant="small"
              employeeId={need.author.id}
              onOpened={onOpenedChat}
            />
          )
        }
      />
      {need?.extraOptions &&
        need.extraOptions
          .slice(0, 3)
          .map((option) => (
            <Property label={`${option.name}:`} value={option.value} />
          ))}
      {shownComment && (
        <Property label={`${translate('comment')}:`} html={need.comment} />
      )}
      {categoriesText && (
        <Property
          label={`${translate('categories')}:`}
          value={categoriesText}
        />
      )}
      <FilesBlock mode={FILES_BLOCK_MODES.VIEW} files={files} hideHeader />
      <LinksBlock mode={LINKS_BLOCK_MODES.VIEW} links={need.links} />
    </div>
  );
};
