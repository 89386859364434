import React from 'react';
import { Icon, Typography } from '@link/react-components';
import { useTranslate } from '@hooks';
import styles from './InfoBlock.module.css';
import { ERRORS } from '../../model/view';

export const InfoBlock = ({ infoType }) => {
  const t = useTranslate();

  return (
    <div className={styles.infoBlock}>
      <Icon iconName="info" width={18} height={18} className={styles.icon} />
      <Typography variant="body1Reg" className={styles.text}>
        {t(`modules.quickRegistration.${ERRORS[infoType]}`)}
      </Typography>
    </div>
  );
};
