import React from 'react';
import { Button } from '@link/react-components';
import useCategoriesSelect from './useCategoriesSelect';
import PopupSmall from '../PopupSmall';
import PopupContent from '../PopupSmall/PopupContent';
import './style.css';
import Tag from './components/Tag';
import { getCategoryById } from './helper';

const CategoriesSelect = ({
  selectedList,
  list,
  onChoose,
  disabled,
  nextModalHandler = () => {}
}) => {
  const {
    onClearSelectedList,
    onChange,
    value,
    isOpened,
    setIsOpened,
    menuOptions,
    onToggle,
    onFocus,
    isFocused,
    onBlur,
    inputRef,
    onOpenModal,
    onKeyDown,
    t
  } = useCategoriesSelect({
    list,
    onChoose,
    selectedList,
    disabled,
    nextModalHandler
  });

  return (
    <div onClick={onFocus} onBlur={onBlur}>
      <div
        className={`category-wrapper ${!disabled && isFocused ? 'focus' : ''}`}>
        <div className="category-tag-wrapper">
          {selectedList.map((id) => (
            <Tag
              onDelete={onToggle}
              category={getCategoryById(list, id)}
              key={id}
              disabled={disabled}
            />
          ))}
          {selectedList.length < 10 && (
            <input
              data-testid="new-request-category-input"
              placeholder={t('add_category')}
              ref={inputRef}
              onChange={onChange}
              value={value}
              onKeyDown={onKeyDown}
              className="category-select"
              disabled={disabled}
            />
          )}
        </div>
        <img
          data-testid="new-request-category-open-modal"
          onClick={onOpenModal}
          className="category-tag-wrapper-icon"
          src="/img/drop.svg"
          alt=""
        />
        {selectedList.length > 1 && (
          <Button
            mode="text"
            size="small"
            className="category-clear-button"
            onClick={onClearSelectedList}>
            {t('categories_clear_field')}
          </Button>
        )}
      </div>

      <PopupSmall
        testIdContent="new-request-category-show-content"
        testIdWrapper="new-request-category-wrapper-content"
        isOpened={isOpened}
        setIsOpened={setIsOpened}
        content={
          <PopupContent
            data-testid="new-request-category-list-of-content"
            style={{ minWidth: 'auto' }}
            menuOptions={menuOptions}
          />
        }
        style={{ width: '100%' }}
        top={5}
      />
    </div>
  );
};

export default CategoriesSelect;
