import i18next from 'i18next';

const translateRu = i18next.getFixedT('ru');
const translateEn = i18next.getFixedT('en');

export function translate(lang, key) {
  switch (lang) {
    case 'en':
      return translateEn(key);
    case 'ru':
    default:
      return translateRu(key);
  }
}
