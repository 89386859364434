export const STATUSES = Object.freeze({
  noStatus: {
    value: 'noStatus',
    notificationsEnabled: true,
    notificationsControlDisabled: true
  },
  dnd: {
    value: 'dnd',
    notificationsEnabled: false,
    notificationsControlDisabled: true
  },
  away: {
    value: 'away',
    notificationsEnabled: true,
    notificationsControlDisabled: false
  }
});

export const STATUSES_ICONS = Object.freeze({
  [STATUSES.noStatus.value]: 'no-status',
  [STATUSES.dnd.value]: 'dnd',
  [STATUSES.away.value]: 'away'
});
