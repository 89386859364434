import { NimbleEmojiIndex } from 'emoji-mart';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { equals, take } from 'ramda';
import data from 'emoji-mart/data/apple.json';
import addEmoji, { Mode } from '../modify-add-emoji';
import { getSearchText, positionSuggestions } from '../../utils';
import SuggestionItem from './SuggestionItem';

function getSuppostEmojiName(word) {
  const getLength = () =>
    word.charAt(word.length - 1) === ':' ? word.length - 1 : word.length;

  return word.slice(1, getLength());
}

function isSuggestWord(word) {
  return word.charAt(0) === ':' && /(\s|^):[\w]*/.test(word);
}

const EmojiSuggestions = ({ store, callbacks }) => {
  const MAX_SMILE = 5;

  const emojiIndex = new NimbleEmojiIndex(data);

  const ref = useRef(null);
  const [selectedEmoji, selectEmoji] = useState(0);
  const getListEmoji = (name = '') => emojiIndex.search(name) || [];
  const setEmoji = (emoji) => {
    const newEditorState = addEmoji(
      store.getEditorState(),
      emoji.native,
      Mode.REPLACE
    );
    store.setEditorState(newEditorState);
  };

  const { word } = getSearchText(store.getEditorState());
  const search = getSuppostEmojiName(word);
  const getList = useCallback(
    () => take(MAX_SMILE, getListEmoji(search)),
    [search]
  );

  const list = getList();

  const getSelectedEmoji = () => {
    const indexOfLastElem = list.length === 0 ? 0 : list.length - 1;
    return selectedEmoji > indexOfLastElem ? indexOfLastElem : selectedEmoji;
  };
  const getEmojiByIndex = () => list[getSelectedEmoji()];

  const isOpen = useCallback(
    () => isSuggestWord(word) && list.length > 0,
    [list, word]
  );

  useEffect(() => {
    const handleReturn = () => {
      if (isOpen()) {
        setEmoji(getEmojiByIndex());
        return 'handled';
      }
      return 'not-handled';
    };
    // eslint-disable-next-line
    callbacks.handleReturn = handleReturn;
  }, [list, word]);

  const getStyle = () => {
    if (store.getClientRect() === undefined) return {};
    return positionSuggestions({
      decoratorRect: store.getClientRect(),
      popover: ref.current,
      props: { open: isOpen(), suggestions: list }
    });
  };

  return (
    <div className="emoji-suggestions" ref={ref} style={getStyle()}>
      {isOpen() &&
        list.map((emojiData, i) => (
          <SuggestionItem
            key={emojiData.colons}
            onFocus={() => selectEmoji(i)}
            onSelect={() => setEmoji(emojiData)}
            isActive={equals(i, getSelectedEmoji())}
            {...emojiData}
          />
        ))}
    </div>
  );
};

export default EmojiSuggestions;
