import {
  setSuccessMessage,
  setErrorMessage,
  setWarningMessage
} from './message';
import getBrowserLanguage from '../utils/languageDetector';
import { getCurrentLocation } from '../utils/location';
import request from '../lib/request';

export const getCustomers =
  (groupId, { priceId = 0, offset = 0, limit = 20 }) =>
  (dispatch) => {
    dispatch({ type: 'CUSTOMERS:GET_CUSTOMERS:REQUEST' });

    const url = `/api/customers/${groupId}${
      priceId ? `/${priceId}` : ''
    }?offset=${offset}&limit=${limit}`;
    fetch(url, {
      credentials: 'include',
      method: 'GET'
    })
      .then((res) => res.json())
      // eslint-disable-next-line no-shadow
      .then(({ customers, size, totalSize, offset, limit }) => {
        dispatch({
          type: 'CUSTOMERS:GET_CUSTOMERS:SUCCESS',
          payload: { customers, size, totalSize, offset, limit, groupId }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'CUSTOMERS:GET_CUSTOMERS:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const getCustomersByName =
  (name = '', groupId = 0, { offset = 0, limit = 20 }) =>
  (dispatch) => {
    dispatch({ type: 'CUSTOMERS:GET_CUSTOMERS:REQUEST' });

    const url = `/api/customers/search?name=${name}&groupId=${groupId}&offset=${offset}&limit=${limit}`;
    fetch(url, {
      credentials: 'include',
      method: 'GET'
    })
      .then((res) => res.json())
      .then(({ customers, size, totalSize }) => {
        dispatch({
          type: 'CUSTOMERS:GET_CUSTOMERS:SUCCESS',
          payload: { customers, size, totalSize, offset, limit, groupId }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'CUSTOMERS:GET_CUSTOMERS:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const getAllCustomers =
  (offset = 0, limit = 20) =>
  (dispatch) => {
    dispatch({ type: 'CUSTOMERS:GET_CUSTOMERS:REQUEST' });

    fetch(`/api/customers?offset=${offset}&limit=${limit}`, {
      credentials: 'include',
      method: 'GET'
    })
      .then((res) => res.json())
      // eslint-disable-next-line no-shadow
      .then(({ customers, size, totalSize, offset, limit }) => {
        dispatch({
          type: 'CUSTOMERS:GET_CUSTOMERS:SUCCESS',
          payload: { customers, size, totalSize, offset, limit }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'CUSTOMERS:GET_CUSTOMERS:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const addCustomer =
  (data, priceId, consignees, language) => async (dispatch) => {
    dispatch({ type: 'CUSTOMERS:ADD:REQUEST' });
    const { companyName, email, category, responsibleUser } = data;
    const body = {
      companyName,
      email,
      groupId: category,
      language,
      priceId,
      responsibleUser
    };
    if (consignees) {
      body.consignees = consignees;
    }
    try {
      const res = await fetch('/api/customers/add', {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      });
      const json = await res.json();
      if (res.status === 208) {
        dispatch(setWarningMessage({ key: json.msg, params: { email } }));
        return;
      }
      if (res.status === 409) {
        dispatch(
          setErrorMessage({ key: json.msg, params: { time: json.time } })
        );
        return;
      }
      if (json.isExists) {
        dispatch(
          setSuccessMessage({ key: 'customer_request_send', params: { email } })
        );
        return;
      }
      if (json.inviteAdded) {
        dispatch(
          setSuccessMessage({
            key: 'Invite already sent to email',
            params: { email }
          })
        );
        return;
      }
      if (!json.isExists) {
        dispatch(
          setSuccessMessage({
            key: 'Invite was sent to email',
            params: { email }
          })
        );
        return;
      }
      const currLoc = getCurrentLocation();
      if (currLoc === `/prices/${priceId}/share`) {
        // eslint-disable-next-line consistent-return
        return dispatch({
          type: 'PRICE_SHARE:CUSTOMER_ADD:SUCCESS',
          payload: json
        });
      }
      // eslint-disable-next-line consistent-return
      return dispatch({ type: 'CUSTOMERS:ADD:SUCCESS', payload: json });
    } catch (err) {
      dispatch({ type: 'CUSTOMERS:ADD:FAILURE', payload: { err } });
      console.error(err);
    }
  };

export const removeCustomerFromGroup =
  (customers, groupId, deleteGroups = false) =>
  (dispatch) => {
    dispatch({ type: 'CUSTOMERS:GROUP_REMOVE:REQUEST' });
    return fetch('/api/customers/removeFromGroup', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ customers, groupId })
    })
      .then(() => {
        if (deleteGroups) {
          dispatch({
            type: 'CUSTOMERS:GROUP_REMOVE:SUCCESS',
            payload: { customers, groupId }
          });
        } else {
          dispatch({
            type: 'CUSTOMERS:DELETE:SUCCESS',
            payload: { customers }
          });
        }
      })
      .catch((res) => {
        dispatch({
          type: 'CUSTOMERS:GROUP_REMOVE:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const addCustomerToGroup = (customers, groupId) => (dispatch) => {
  dispatch({ type: 'CUSTOMERS:GROUP_ADD:REQUEST' });
  return fetch('/api/customers/addToGroup', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ customers, groupId })
  })
    .then(() => {
      dispatch({
        type: 'CUSTOMERS:GROUP_ADD:SUCCESS',
        payload: { customers, groupId }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'CUSTOMERS:GROUP_ADD:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const deleteCustomer = (customers) => (dispatch) => {
  dispatch({ type: 'CUSTOMERS:DELETE:REQUEST', payload: { customers } });

  return fetch('/api/customers/delete', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ customers })
  })
    .then((res) => res.json())
    .then(({ size, totalSize }) => {
      dispatch({
        type: 'CUSTOMERS:DELETE:SUCCESS',
        payload: { customers, size, totalSize }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'CUSTOMERS:DELETE:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const getGroups = () => (dispatch) => {
  dispatch({ type: 'CUSTOMERS_CATEGORY:GET_CATEGORIES:REQUEST' });

  fetch('/api/customers/groups', {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then(({ groups }) => {
      dispatch({
        type: 'CUSTOMERS_CATEGORY:GET_CATEGORIES:SUCCESS',
        payload: { groups }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'CUSTOMERS_CATEGORY:GET_CATEGORIES:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

// eslint-disable-next-line consistent-return
export const createCustomersCategory = () => async (dispatch, getState) => {
  const name = getState().getIn(['customers', 'newCategory', 'name']).trim();
  dispatch({ type: 'CUSTOMERS_CATEGORY:CREATE:REQUEST' });

  try {
    const res = await fetch('/api/customers/groups', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name })
    });
    const json = await res.json();
    if (json.error) {
      return dispatch(setErrorMessage({ key: json.error }));
    }
    dispatch({
      type: 'CUSTOMERS_CATEGORY:CREATE:SUCCESS',
      payload: json
    });
  } catch (error) {
    dispatch({ type: 'CUSTOMERS_CATEGORY:CREATE:FAILURE', payload: { error } });
  }
};

export const resendInvite = (email) => (dispatch) => {
  dispatch({ type: 'CUSTOMERS:RESEND:REQUEST' });
  return fetch('/api/customers/invite', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: { email, language: getBrowserLanguage() } })
  })
    .then(() => {
      dispatch(
        setSuccessMessage({
          key: 'Invite was sent to email',
          params: { email }
        })
      );
      dispatch({ type: 'CUSTOMERS:RESEND:SUCCESS', payload: { email } });
    })
    .catch((res) => {
      dispatch({
        type: 'CUSTOMERS:RESEND:FAILURE',
        payload: { error: res.errors }
      });
      console.error(res);
    });
};

// eslint-disable-next-line consistent-return
export const updateCustomersCategory = (group) => async (dispatch) => {
  dispatch({ type: 'CUSTOMERS_CATEGORY:UPDATE:REQUEST' });
  try {
    const res = await fetch(`/api/customers/groups/${group.id}`, {
      credentials: 'include',
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name: group.name })
    });
    const json = await res.json();
    if (json.error) {
      return dispatch(setErrorMessage({ key: json.error }));
    }
    dispatch({
      type: 'CUSTOMERS_CATEGORY:UPDATE:SUCCESS',
      payload: { group }
    });
  } catch (err) {
    dispatch({
      type: 'CUSTOMERS_CATEGORY:UPDATE:FAILURE',
      payload: { error: err }
    });
    console.error(err);
  }
};

export const deleteGroup = (groupId) => (dispatch) => {
  dispatch({ type: 'CUSTOMERS_CATEGORY:DELETE:REQUEST' });

  return fetch(`/api/customers/groups/${groupId}`, {
    credentials: 'include',
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(() => {
      dispatch({
        type: 'CUSTOMERS_CATEGORY:DELETE:SUCCESS',
        payload: { groupId }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'CUSTOMERS_CATEGORY:DELETE:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const updateNewCategoryName = (field, value) => ({
  type: 'CUSTOMERS_CATEGORY:CREATE:UPDATE_NAME',
  payload: { field, value }
});

export const updateCategoryName = (field, value) => ({
  type: 'CUSTOMERS_CATEGORY:UPDATE',
  payload: { field, value }
});

export const updateCustomerField = (field, value) => ({
  type: 'CUSTOMERS:CREATE:UPDATE_FIELD',
  payload: { field, value }
});

export const selectCustomer = (id) => ({
  type: 'CUSTOMERS:SELECT',
  payload: { id }
});

export const selectAll = (items) => ({
  type: 'CUSTOMERS:SELECT:ALL',
  payload: { items }
});

export const setSelectedItem = (item) => ({
  type: 'CUSTOMERS:SET_SELECTED_ITEM',
  payload: { item }
});

export const setSelectedItems = (id) => ({
  type: 'CUSTOMERS:SET_SELECTED_ITEMS',
  payload: { id }
});

export const setSelectedRequests = (id) => ({
  type: 'CUSTOMERS:SET_SELECTED_REQUESTS',
  payload: { id }
});

export const setSelectedInvites = (id) => ({
  type: 'CUSTOMERS:SET_SELECTED_INVITES',
  payload: { id }
});

export const selectCategory = (id) => ({
  type: 'CUSTOMERS_CATEGORY:SELECT',
  payload: { id }
});

export const setSelectedCategory = (item) => ({
  type: 'CUSTOMERS_CATEGORY:SET_SELECTED_CATEGORY',
  payload: { item }
});

export const updateSelectedField = (field, value) => ({
  type: 'CUSTOMERS:EDIT:UPDATE',
  payload: { field, value }
});

export const deselectAll = (table = 'common') => ({
  type: 'DESELECT_ALL',
  table
});

export const deselectAllCategories = () => ({
  type: 'DESELECT_ALL_CATEGORIES'
});

export const deselectCategory = () => ({ type: 'DESELECT_CATEGORY' });

export const deselectCustomer = () => ({ type: 'DESELECT_CUSTOMER' });

export const getAllInvitations = () => (dispatch) => {
  dispatch({ type: 'CUSTOMERS:GET_INVITATIONS:REQUEST' });
  fetch('/api/customers/invites', {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then(({ invites, link }) => {
      dispatch({
        type: 'CUSTOMERS:GET_INVITATIONS:SUCCESS',
        payload: { invites, link }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'CUSTOMERS:GET_INVITATIONS:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const getRequests = () => (dispatch) => {
  dispatch({ type: 'CUSTOMERS:GET_MY_REQUESTS:REQUEST' });
  fetch('/api/customers/requests', {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then(({ requests }) => {
      dispatch({
        type: 'CUSTOMERS:GET_MY_REQUESTS:SUCCESS',
        payload: { requests }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'CUSTOMERS:GET_MY_REQUESTS:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const getAdditionRequests = () => (dispatch) => {
  dispatch({ type: 'CUSTOMERS:GET_ADDITION_REQUESTS:REQUEST' });
  fetch('/api/customers/addition_requests', {
    credentials: 'include',
    method: 'GET'
  })
    .then((res) => res.json())
    .then(({ additionRequests }) => {
      dispatch({
        type: 'CUSTOMERS:GET_ADDITION_REQUESTS:SUCCESS',
        payload: { additionRequests }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'CUSTOMERS:GET_ADDITION_REQUESTS:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const confirmAddCustomer = (customerId) => (dispatch) => {
  dispatch({ type: 'CUSTOMERS:CONFIRM_ADD:REQUEST' });
  return fetch('/api/customers/confirm_add', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ customerId })
  })
    .then((res) => res.json())
    .then((resp) => {
      dispatch({
        type: 'CUSTOMERS:CONFIRM_ADD:SUCCESS',
        payload: { companyId: customerId, type: 'customersAdditionRequests' }
      });
      return resp;
    })
    .catch((res) => {
      dispatch({
        type: 'CUSTOMERS:CONFIRM_ADD:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const rejectAddCustomer = (customerId) => (dispatch) => {
  dispatch({ type: 'CUSTOMERS:REJECT_ADD:REQUEST' });
  return fetch('/api/customers/reject_add', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ customerId })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({
        type: 'CUSTOMERS:REJECT_ADD:SUCCESS',
        payload: { companyId: customerId, type: 'customersAdditionRequests' }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'CUSTOMERS:REJECT_ADD:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const deleteRequests = (requests) => (dispatch) => {
  dispatch({ type: 'CUSTOMERS:DELETE_REQUESTS:REQUEST' });
  return fetch('/api/customers/requests/delete', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ requests })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({
        type: 'CUSTOMERS:DELETE_REQUESTS:SUCCESS',
        payload: { requests }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'CUSTOMERS:DELETE_REQUESTS:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const deleteInvites = (invites) => (dispatch) => {
  dispatch({ type: 'CUSTOMERS:DELETE_INVITES:REQUEST' });
  return fetch('/api/customers/invites/delete', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ invites })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({
        type: 'CUSTOMERS:DELETE_INVITES:SUCCESS',
        payload: { invites }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'CUSTOMERS:DELETE_INVITES:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const removeInvites = (requests, invites) => (dispatch) => {
  dispatch({ type: 'PRICE_SHARE:DELETE_INVITES:REQUEST' });
  return fetch('/api/customers/invites_price_share/delete', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ requests, invites })
  })
    .then((res) => res.json())
    .then(() => {
      dispatch({
        type: 'PRICE_SHARE:DELETE_INVITES:SUCCESS',
        payload: { requests, invites }
      });
    })
    .catch((res) => {
      dispatch({
        type: 'PRICE_SHARE:DELETE_INVITES:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const clearInvitations = () => ({ type: 'CUSTOMERS:CLEAR_INVITATIONS' });

export const clearForm = () => ({
  type: 'CUSTOMERS:CLEAR_FORM'
});

export const setCustomerResponsibleUser =
  (customerId, userId) => (dispatch) => {
    dispatch({ type: 'CUSTOMERS:SET_RESPONSIBLE:REQUEST' });
    return fetch('/api/customers/responsible', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ customerId, userId })
    })
      .then((res) => res.json())
      .then(() => {
        dispatch({
          type: 'CUSTOMERS:SET_RESPONSIBLE:SUCCESS',
          payload: { customerId, userId }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'CUSTOMERS:SET_RESPONSIBLE:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });
  };

export const getCustomerConsignees =
  ({ customerId, customerLogin }) =>
  (dispatch) =>
    fetch(
      `/api/customers/consignees?${
        customerId
          ? `customerId=${customerId}`
          : `customerLogin=${customerLogin}`
      }`,
      {
        credentials: 'include',
        method: 'GET'
      }
    )
      .then((res) => res.json())
      .then((consignees) => {
        dispatch({
          type: 'CUSTOMERS:GET_CUSTOMER_CONSIGNEES:SUCCESS',
          payload: { consignees }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'CUSTOMERS:GET_CUSTOMER_CONSIGNEES:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });

export const saveCustomerConsignees =
  ({ customerId, customerLogin }, consigneesList) =>
  (dispatch) =>
    fetch(
      `/api/customers/consignees?${
        customerId
          ? `customerId=${customerId}`
          : `customerLogin=${customerLogin}`
      }`,
      {
        credentials: 'include',
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ consignees: consigneesList })
      }
    )
      .then((res) => res.json())
      .then((consignees) => {
        dispatch({
          type: 'CUSTOMERS:SAVE_CUSTOMER_CONSIGNEES:SUCCESS',
          payload: { consignees }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'CUSTOMERS:SAVE_CUSTOMER_CONSIGNEES:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      });

export const clearConsignees = () => ({ type: 'CUSTOMERS:CONSIGNEES_CLEAR' });

export const toggleLink = (isTokenOn) => (dispatch) => {
  dispatch({ type: 'CUSTOMERS:TOGGLE_LINK:REQUEST' });
  return (
    fetch('/api/customers/toggle_link', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ isTokenOn })
    })
      .then((res) => res.json())
      // eslint-disable-next-line no-shadow
      .then(({ isTokenOn }) => {
        dispatch({
          type: 'CUSTOMERS:TOGGLE_LINK:SUCCESS',
          payload: { isTokenOn }
        });
      })
      .catch((res) => {
        dispatch({
          type: 'CUSTOMERS:TOGGLE_LINK:FAILURE',
          payload: { error: res.error }
        });
        console.error(res);
      })
  );
};

export const getToken = () => (dispatch) =>
  request
    .get('/api/customers/token')
    .then((payload) => {
      dispatch({ type: 'CUSTOMERS:SET_TOKEN', payload });
      return payload;
    })
    .catch((err) => {
      console.error(err);
      dispatch({ type: 'CUSTOMERS:CLEAR_TOKEN' });
    });

export const refreshToken = () => (dispatch) =>
  request
    .post('/api/customers/refresh_token', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    })
    .then((payload) => {
      dispatch({ type: 'CUSTOMERS:SET_TOKEN', payload });
      return payload;
    })
    .catch((err) => {
      console.error(err);
      dispatch({ type: 'CUSTOMERS:CLEAR_TOKEN' });
    });

export const updateCustomers = (payload) => (dispatch) =>
  dispatch({ type: 'CUSTOMERS:UPDATE_DATA', payload });
