import React, { useCallback, useEffect, useRef, useState } from 'react';
import { startOfToday } from 'date-fns';
import { isEmpty, isNil } from 'ramda';
import DateTimePicker from '../../../../Datepicker/dateTimePicker';
import ScheduleSVG from '../../../../../../icons/schedule';
import Tooltip from '../../../../Tooltip';

const minDate = startOfToday();

function getReminderDate(reminder) {
  return reminder ? new Date(reminder.date) : null;
}

const ScheduleIcon = ({ active = false, tooltip }) => (
  <Tooltip text={tooltip}>
    <div className={`cwm-um-footer__btn schedule ${active ? 'is-active' : ''}`}>
      <ScheduleSVG />
    </div>
  </Tooltip>
);

const ButtonSchedule = ({
  t,
  chatId,
  messageId,
  isOwnMessage,
  scheduleMessages = [],
  tooltip = '',
  onClick,
  setActive
}) => {
  const placement = isOwnMessage ? 'left-start' : 'right-start';
  const preferX = isOwnMessage ? 'left' : 'right';
  const reminder = scheduleMessages.find(
    (item) => item.message.id === messageId
  );
  const isActive = !isNil(reminder);
  const isDisabled = scheduleMessages.length > 0 && !isActive;

  const onClickByIcon = useCallback(() => {
    setActive(true);
    if (!isDisabled || isEmpty(scheduleMessages)) return;

    onClick({
      mode: 'clear-reminder',
      chatId,
      message: scheduleMessages[0].message
    });
  }, [isDisabled, scheduleMessages]);
  const onSubmit = useCallback(
    (date) => onClick({ mode: 'default', date }),
    []
  );
  const onClear = useCallback(
    () => onClick({ mode: 'default', date: null }),
    []
  );
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const fn = (event) => {
      if (event.detail.messageId === messageId) {
        setIsShow(true);
      }
    };
    document.addEventListener('set-open-modal-select-delayed-time', fn);
    return () =>
      document.removeEventListener('set-open-modal-select-delayed-time', fn);
  }, []);

  return (
    <DateTimePicker
      t={t}
      value={getReminderDate(reminder)}
      title="scheduleMsg.datepicker.title"
      btnApplyTkey="scheduleMsg.datepicker.btn_apply"
      onSubmit={onSubmit}
      onClear={onClear}
      onClick={onClickByIcon}
      minDate={minDate}
      minTime={new Date()}
      icon={<ScheduleIcon active={isActive} tooltip={tooltip} />}
      placement={placement}
      preferX={preferX}
      overflowContainer={false}
      disabled={isDisabled}
      isCloseAfterClear
      isShow={isShow}
    />
  );
};

const Button = ({
  t,
  chatId,
  messageId,
  tooltip,
  value,
  isSelect,
  isOwnMessage,
  scheduleMessages,
  setActive,
  onClick
}) => {
  if (value === 'schedule') {
    return (
      <ButtonSchedule
        t={t}
        chatId={chatId}
        messageId={messageId}
        scheduleMessages={scheduleMessages}
        tooltip={tooltip}
        isOwnMessage={isOwnMessage}
        onClick={onClick}
        setActive={setActive}
      />
    );
  }

  return (
    <Tooltip text={tooltip}>
      <div
        className={`cwm-um-footer__btn ${value} ${isSelect ? 'is-select' : ''}`}
        onClick={onClick}
      />
    </Tooltip>
  );
};

const Footer = ({
  t,
  chatId,
  messageId,
  isEdited,
  showTime,
  time = '00:00',
  buttons = []
}) => {
  const ref = useRef(null);

  return (
    <div className="cwm-um-footer" ref={ref}>
      <div
        className={`cwm-um-footer__time ${
          showTime ? 'cwm-um-footer__time_show' : ''
        }`}>
        {time}
      </div>
      {isEdited && (
        <div className="cwm-um-footer__edited">
          {t('chat-widget.section.main.edited')}
        </div>
      )}
      <div className="cwm-um-footer__btns">
        {buttons.map((buttonProps, index) => (
          <Button
            t={t}
            chatId={chatId}
            key={`${messageId + index}`}
            messageId={messageId}
            {...buttonProps}
          />
        ))}
      </div>
    </div>
  );
};

export default Footer;
