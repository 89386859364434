import React from 'react';
import { isEmpty, isNil } from 'ramda';
import { useSelector } from 'react-redux';
import { useActions } from '@hooks';
import { isTopic } from 'core/data/light/topic';
import { getInterlocutor } from 'core/data/light/dialog';
import View from './view';
import * as modalsActions from '../../../../action-creators/modal';
import * as storage from '../../../PurchaseRequests/Responses/storage';
import * as responseChatStorage from '../../../../modules/response-chat/storage';
import * as alertMessages from '../../../../action-creators/message';
import * as blockActions from '../../modules/blockedContact';
import * as commonActions from '../../useCases';
import { useTranslate } from '../../../../TranslateProvider';
import * as getters from '../../modules/scheduleMessages/getters';
import * as storeGetters from '../../../../storeGetters';
import * as chatWidgetGetters from '../../getter';
import * as contactsCases from '../../modules/contacts';
import * as scheduleMessagesCases from '../../modules/scheduleMessages/useCases';
import { isWidget } from '../../../../lib/goodwix';
import config from './config';
import { isDialog } from '../../helpers/chatType';
import * as ModalAcceptPurchaseResponse from '../../../Modal/ModalAcceptPurchaseResponse';

const TOP_POSITION_CONFIRMS = ['add-contact', 'fired-schedule-message'];
const BOTTOM_POSITION_CONFIRMS = ['response'];

const makeConfirm = ({
  subtype,
  text,
  acceptTKey = 'no_text_accept',
  rejectTKey = 'no_text_reject',
  onAccept = () => alert('accept'),
  onReject = () => alert('reject'),
  onSendToRework,
  reworkTKey,
  onClose
}) => ({
  subtype,
  text,
  acceptTKey,
  rejectTKey,
  onAccept,
  onReject,
  onSendToRework,
  reworkTKey,
  onClose
});

const commonCloseConfirm = ({ chat, updateChat, type }) => {
  const updatedConfirm = chat.confirm.filter(
    (confirmType) => confirmType !== type
  );
  const updatedChat = { ...chat, confirm: updatedConfirm };
  updateChat(updatedChat);
};

function isNeedShowConfirm(confirm) {
  return !isNil(confirm) && Array.isArray(confirm) && !isEmpty(confirm);
}

function makeResponseConfirm(subtype, { t, chat, showModal, acceptResponse }) {
  const onReject = () => {
    if (isNil(chat)) return;

    showModal('DECLINE_PURCHASE_RESPONSE', {
      requestId: chat.requestId,
      responseId: chat.responseId
    });
  };

  const onAccept = () => {
    if (isNil(chat)) return;

    acceptResponse({ requestId: chat.requestId, responseId: chat.responseId });
  };

  const onSendToRework = () => {
    if (isNil(chat)) return;

    showModal('SEND_TO_REWORK_PURCHASE_RESPONSE', {
      companyName: chat.name.split('"')[1],
      requestId: chat.requestId,
      responseId: chat.responseId
    });
  };

  return makeConfirm({
    subtype,
    acceptTKey: 'accept_offer',
    rejectTKey: 'reject_offer',
    reworkTKey: 'send_to_rework',
    onAccept,
    onReject,
    onSendToRework
  });
}

function makeAddOrBlockContactConfirmForDialog(
  subtype,
  { t, employeeId, chat, unhideContactAndDialog, updateChat }
) {
  const interlocutor = getInterlocutor(employeeId, chat);

  const closeConfirm = () =>
    commonCloseConfirm({ chat, updateChat, type: 'add-contact' });

  const onReject = () => {
    closeConfirm();
  };

  const onAccept = async () => {
    unhideContactAndDialog(interlocutor.employeeId);
    closeConfirm();
  };

  return makeConfirm({
    subtype,
    text: `${t('add_user_to_contacts')}?`,
    acceptTKey: 'yes',
    rejectTKey: 'no',
    onAccept,
    onReject
  });
}

function makeAddContactConfirmForResponseChat(
  subtype,
  { t, chat, setSuccessMessage, updateChat }
) {
  const closeConfirm = () =>
    commonCloseConfirm({ chat, updateChat, type: 'add-contact' });

  const onReject = () => {
    if (isNil(chat)) return;

    responseChatStorage.declineConfirm({
      responseId: chat.responseId,
      confirmType: subtype
    });
    closeConfirm();
  };

  const onAccept = async () => {
    if (isNil(chat)) return;

    await responseChatStorage.addContact(chat.responseId);
    closeConfirm();
    setSuccessMessage({ key: 'contact_was_added_to_list' });
  };

  return makeConfirm({
    subtype,
    text: `${t('add_user_to_contacts')}?`,
    acceptTKey: 'yes',
    rejectTKey: 'no',
    onAccept,
    onReject,
    onClose: onReject
  });
}

function makeScheduleMsgConfirm(
  subtype,
  { t, chat, scheduleMessage, jumpToMessage, removeScheduleMessage, updateChat }
) {
  const closeConfirm = () => {
    const updatedConfirm = chat.confirm.filter(
      (confirmType) => confirmType !== 'fired-schedule-message'
    );
    const updatedChat = { ...chat, confirm: updatedConfirm };
    updateChat(updatedChat);
  };

  const onReject = () => {
    closeConfirm();
  };

  // NOTE: timeout need to fix bug when update chat call before scroll to message
  const onAccept = async () => {
    closeConfirm();
    setTimeout(() => jumpToMessage(scheduleMessage), 100);
    removeScheduleMessage(chat.id, scheduleMessage.id);
  };

  return makeConfirm({
    subtype,
    text: `${t('confirm.texts.go-to-schedule-message')}`,
    acceptTKey: 'confirm.controllers.yes',
    rejectTKey: 'confirm.controllers.no',
    onAccept,
    onReject
  });
}

const findConfirms = (props) => {
  if (!isNeedShowConfirm(props.chat.confirm)) return undefined;

  return props.chat.confirm.map((type) => {
    switch (type) {
      case 'response':
        return makeResponseConfirm(type, props);
      case 'add-contact':
        if (isDialog(props.chat)) {
          return makeAddOrBlockContactConfirmForDialog(type, props);
        }

        return makeAddContactConfirmForResponseChat(type, props);
      case 'fired-schedule-message':
        return makeScheduleMsgConfirm(type, props);
      default:
        throw new Error(`No such handler for make confirm with type: ${type}.`);
    }
  });
};

function filterConfirms(confirms = []) {
  if (!isWidget(window.location.pathname)) return confirms;

  return confirms.filter(
    (confirm) => !config.doNotShowInWidget.includes(confirm.subtype)
  );
}

function sortConfirms(confirms = []) {
  return confirms.reduce(
    (acc, confirm) => {
      if (TOP_POSITION_CONFIRMS.includes(confirm.subtype))
        return { ...acc, topConfirms: [...acc.topConfirms, confirm] };
      if (BOTTOM_POSITION_CONFIRMS.includes(confirm.subtype))
        return { ...acc, bottomConfirms: [...acc.topConfirms, confirm] };

      throw new Error(
        `Can't define confirm position for confirm with type: ${confirm.subtypex}.`
      );
    },
    { topConfirms: [], bottomConfirms: [] }
  );
}

const ChatConfirm = ({ children }) => {
  const t = useTranslate();

  const [openAcceptModal] = ModalAcceptPurchaseResponse.useModal();

  const employeeId = useSelector(storeGetters.getCurrentEmployeeId);
  const scheduleMessage = useSelector(getters.getFiredScheduleMessage);
  const chat = useSelector(chatWidgetGetters.getOpenedChat);

  const {
    showModal,
    setSuccessMessage,
    blockContact,
    jumpToScheduleMessage: jumpToMessage,
    removeScheduleMessage,
    unhideContactAndDialog,
    updateChat
  } = useActions({
    ...modalsActions,
    ...storage,
    ...alertMessages,
    ...blockActions,
    ...commonActions.ac,
    ...scheduleMessagesCases,
    ...contactsCases
  });

  const confirms = findConfirms({
    t,
    chat,
    showModal,
    acceptResponse: openAcceptModal,
    setSuccessMessage,
    blockContact,
    employeeId,
    unhideContactAndDialog,
    updateChat,
    scheduleMessage,
    jumpToMessage,
    removeScheduleMessage
  });

  const { topConfirms, bottomConfirms } = sortConfirms(
    filterConfirms(confirms)
  );

  return (
    <View
      topConfirms={topConfirms}
      bottomConfirms={bottomConfirms}
      isShowPortal={isTopic(chat)}>
      {children}
    </View>
  );
};

export default React.memo(ChatConfirm);
