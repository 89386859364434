import React, { useState, useEffect } from 'react';
import { Modal, Typography, Loader } from '@link/react-components';
import { useActions, useTranslate } from '@hooks';
import cn from 'classnames';
import { companyService } from '../../../api';
import * as modalActions from '../../../action-creators/modal';
import { ReliabilityMeter } from './components/ReliabilityMeter';
import styles from './ModalReliability.module.css';

const DetailsBlock = ({ category, value, info }) => {
  const t = useTranslate();
  const infoExists = info?.length !== 0;
  return (
    <div
      key={category}
      className={cn(styles.details, category === 'cons' && styles.detailsCons)}>
      <Typography
        variant="h2"
        className={cn(
          styles.detailsHeader,
          category === 'cons' && styles.detailsHeaderCons
        )}>
        <span>{t(`reliability.modal.${category}`)}</span>
        <span>
          {value === 0 ? '-' : `${category === 'pros' ? '+' : ''}${value}`}
        </span>
      </Typography>
      <div
        className={cn(
          styles.delimiter,
          category === 'cons' && styles.delimiterCons
        )}
      />
      {infoExists
        ? info.map((data) => (
            <Typography variant="body1Reg" className={styles.property}>
              <span className={styles.description}>{data.description}</span>
              {data?.comments?.length > 0 &&
                data.comments.map((comment) => (
                  <span className={styles.comments}>{comment}</span>
                ))}
            </Typography>
          ))
        : t('reliability.modal.noInfo')}
    </div>
  );
};

export function ModalReliability({ name, id }) {
  const { hideModalDialog } = useActions(modalActions);
  const t = useTranslate();

  const [loading, setLoading] = useState(true);
  const [reliability, setReliability] = useState({});

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const result = await companyService.getExtraInfo(id);
        setReliability(result.reliability);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Modal open onClose={hideModalDialog} className={styles.modal}>
      <Modal.Header>{`${t('reliability.modal.title')} ${name}`}</Modal.Header>
      <Modal.Content className={styles.contentContainer}>
        {loading ? (
          <Loader />
        ) : (
          <div className={styles.content}>
            <ReliabilityMeter
              prosScore={+reliability.advantagesSum}
              consScore={Math.abs(+reliability.disadvantagesSum)}
            />
            <div className={styles.detailsBlock}>
              <DetailsBlock
                category="pros"
                value={reliability.advantagesSum}
                info={reliability.advantages}
              />
              <DetailsBlock
                category="cons"
                value={reliability.disadvantagesSum}
                info={reliability.disadvantages}
              />
            </div>
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
}
