import React, { useCallback, useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Typography,
  Select,
  Loader
} from '@link/react-components';
import { useActions, useNavigate, useTranslate } from '@hooks';
import styles from './ModalAssignResponseReviewFlow.module.css';
import * as modalActions from '../../../action-creators/modal';
import { routeService } from '../../../api';

function getOptionLabel(option) {
  return option.name;
}

function renderOption(option) {
  return (
    <Typography variant="body1Reg" className={styles.option}>
      {option.name}
    </Typography>
  );
}

export function ModalAssignResponseReviewFlow({ type, handleSubmit }) {
  const { hideModalDialog } = useActions(modalActions);
  const t = useTranslate();
  const [flows, setFlows] = useState([]);
  const [flow, setFlow] = useState({});
  const [disable, setDisable] = useState(true);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleSubmitFlow = useCallback(() => {
    if (flows.length === 0) {
      navigate(`/constructor/create/${type}`);
    } else {
      handleSubmit(flow.id);
    }
    hideModalDialog();
  }, [flows, flow.id, type]);

  const flowOptions = flows.map((currentFlow) => ({
    key: currentFlow.id,
    value: currentFlow
  }));

  useEffect(() => {
    setLoading(true);
    routeService
      .getCompanyRoutesListWithoutMe('response')
      .then((res) => {
        setFlows(res.result);
        setLoading(false);
      })
      .catch((error) => console.log(`Cannon load routes, ${error}`));
  }, []);

  useEffect(() => {
    setDisable(!flow?.id && flows?.length !== 0);
  }, [flow, flows]);

  return (
    <Modal open onClose={hideModalDialog} className={styles.modal}>
      <Modal.Header>
        {t('responseApprovalLog.assignFlowModal.title')}
      </Modal.Header>
      <Modal.Content className={styles.content}>
        {loading ? (
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        ) : (
          <>
            {flowOptions.length === 0 ? (
              <Typography variant="body1Reg" className={styles.noFlows}>
                {t('responseApprovalLog.assignFlowModal.noFlows')}
              </Typography>
            ) : (
              <>
                <Typography variant="body2Reg" className={styles.flowLabel}>
                  {t('responseApprovalLog.assignFlowModal.label')}
                </Typography>
                <Select
                  options={flowOptions}
                  placeholder={t(
                    'responseApprovalLog.assignFlowModal.placeholder'
                  )}
                  onSelect={setFlow}
                  getOptionLabel={getOptionLabel}
                  renderOption={renderOption}
                  noOptionsText={t(
                    'responseApprovalLog.assignFlowModal.noResults'
                  )}
                />
              </>
            )}
          </>
        )}
      </Modal.Content>
      <Modal.Footer>
        <Modal.Actions>
          <Button mode="text" onClick={hideModalDialog}>
            {t('responseApprovalLog.assignFlowModal.buttons.cancel')}
          </Button>
          <Button mode="primary" onClick={handleSubmitFlow} disabled={disable}>
            {t(
              flows.length === 0
                ? 'responseApprovalLog.assignFlowModal.buttons.createFlow'
                : 'responseApprovalLog.approvalLog.buttons.submitForReview'
            )}
          </Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
}
