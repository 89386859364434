export function validateAnalog(name) {
  const newName = name
    .replace(/[^[a-zA-Z0-9а-яА-ЯЁё~!\-?@#$%^&*_+()[\\\]{}>'<\/|".,:;\s]]*/g, '')
    .replace(/ {2,}/g, ' ')
    .trim();

  const valid = /[a-zA-Z0-9а-яА-ЯЁё]/.test(newName);

  return valid ? newName : '';
}
