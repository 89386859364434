import request from '@root/lib/request';

class StatusesService {
  #baseUrl = '/api/statuses';

  async getAll() {
    return request.get(this.#baseUrl);
  }
}

export const statusesService = new StatusesService();
