import React, { useRef } from 'react';
import {
  Button,
  ExpandableText,
  Icon,
  Typography
} from '@link/react-components';
import cn from 'classnames';
import { useTranslate } from '@hooks';
import { Loader } from '../Loader';
import { MODE, TRANSLATE_PATH, ERROR_MESSAGES } from '../../constants';

import styles from './FileSection.module.css';

function Title({ title, className }) {
  return (
    <Typography
      variant="body1Med"
      className={cn(styles.title, className)}
      align="center">
      {title}
    </Typography>
  );
}

function Container({ children }) {
  return <section className={styles.section}>{children}</section>;
}

function View({ error = { text: '' }, fileName }) {
  const t = useTranslate();
  const translate = (key) => t(`${TRANSLATE_PATH}.${key}`);

  return (
    <Container>
      <Icon
        iconName="excel"
        width={30}
        height={30}
        className={error.text ? styles.iconError : ''}
      />

      <Title title={fileName} className={styles.viewText} />

      {error.text && (
        <>
          <Typography className={styles.textError} align="center">
            {error.text}
          </Typography>
          {error.errorMessage === ERROR_MESSAGES.INN_NOT_FOUND && (
            <ExpandableText
              textContent={error.list.join(', ')}
              showMoreText={translate('showMore')}
              showLessText={translate('hide')}
              clamp={1}
            />
          )}
        </>
      )}

      {error.errorMessage === ERROR_MESSAGES.VALIDATION_ERROR && (
        <>
          {error.values.inn.length && (
            <>
              <Typography className={styles.textError} align="center">
                {error.innText}
              </Typography>
              <ExpandableText
                textContent={error.values.inn.join(', ')}
                showMoreText={translate('showMore')}
                showLessText={translate('hide')}
                clamp={1}
              />
            </>
          )}
        </>
      )}
    </Container>
  );
}

function Input({ onFileLoad }) {
  const t = useTranslate();
  const translate = (key) => t(`${TRANSLATE_PATH}.${key}`);

  const ref = useRef(null);
  const onExchangeFileClick = () => {
    ref.current.click();
  };

  const handleLoadFile = (e) => {
    onFileLoad(e.target.files);
  };

  return (
    <Container>
      <Button
        onClick={onExchangeFileClick}
        mode="text"
        hasActive={false}
        hasHover={false}
        className={styles.uploadButton}
        before={
          <Icon
            iconName="upload-04"
            width={18}
            height={18}
            className={styles.uploadIcon}
          />
        }>
        {translate('actions.upload')}
      </Button>
      <Typography variant="body1Reg">{translate('filledTemplate')}</Typography>
      <Typography variant="body1Reg" className={styles.grayColorText}>
        {translate('orMoveToSpace')}
      </Typography>
      <input
        type="file"
        id="input-file"
        onChange={handleLoadFile}
        ref={ref}
        hidden
      />
    </Container>
  );
}

function Loading() {
  const t = useTranslate();
  const translate = (key) => t(`${TRANSLATE_PATH}.${key}`);

  return (
    <Container>
      <Loader />
      <Title title={translate('importing')} className={styles.loaderText} />
    </Container>
  );
}

export const FileSection = ({ onFileLoad, fileName, error, mode }) => (
  <div className={styles.container}>
    {mode === MODE.LOADING && <Loading />}
    {mode === MODE.INPUT && <Input onFileLoad={onFileLoad} />}
    {mode === MODE.ERROR && <View fileName={fileName} error={error} />}
    {mode === MODE.VIEW && <View fileName={fileName} />}
  </div>
);
