import { useSelector } from 'react-redux';
import { getPurchaseRequestFilterData } from '../../../components/ChatWidget/PurchaseRequestsFilter/getters';

export const useInitialValues = (data) => {
  if (data) {
    return {
      countries: data.countries ?? [],
      regions: data.regions ?? [],
      cities: data.cities ?? [],
      categories: data.categories ?? [],
      customers: data.customers ?? [],
      sumFrom: data.sumFrom ?? '',
      sumTo: data.sumTo ?? ''
    };
  }

  const filterData = useSelector(getPurchaseRequestFilterData);

  if (!filterData) {
    return {
      countries: [],
      regions: [],
      cities: [],
      categories: [],
      customers: [],
      sumFrom: '',
      sumTo: ''
    };
  }

  return {
    countries: filterData.countries ?? [],
    regions: filterData.regions ?? [],
    cities: filterData.cities ?? [],
    categories: filterData.categories ?? [],
    customers: filterData.customers ?? [],
    sumFrom: filterData.sum.from ?? '',
    sumTo: filterData.sum.to ?? ''
  };
};
