import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { withTranslation as translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Col
} from 'react-bootstrap';
import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';
import * as modalActions from '../../action-creators/modal';
import * as servicesAction from '../../action-creators/services';

const b = block('modal-container');

class ModalCreateGroupChat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      pristine: true
    };
    this.setSubmitText = this.setSubmitText.bind(this);
    this.getAsterisk = this.getAsterisk.bind(this);
  }

  getChildContext() {
    return { b };
  }

  UNSAFE_componentWillMount() {
    const { value } = this.props;
    this.setState({ text: value || '' });
  }

  setSubmitText(e) {
    this.setState({ text: e.target.value });
  }

  getAsterisk() {
    return <span style={{ color: 'red' }}>*</span>;
  }

  isValidNameField() {
    return this.state.text.length < 1 || /^\s+$/.test(this.state.text);
  }

  isNotValid = () => !this.state.text && !this.state.pristine;

  render() {
    const { t, submit, hideModalDialog, userEmail, gaSend } = this.props;

    return (
      <Modal onHide={() => hideModalDialog()} show>
        <ModalContainer mods={{ type: 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('create_group_chat')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup
              controlId="txt"
              style={{ overflow: 'hidden' }}
              validationState={this.isNotValid() ? 'error' : null}>
              <ControlLabel>
                {t('Name')}
                {this.getAsterisk()}
              </ControlLabel>
              <h6 className="pull-right">
                <small>
                  {this.state.text.length}
                  /60
                </small>
              </h6>
              <FormControl
                type="text"
                placeholder=""
                value={this.state.text}
                maxLength="60"
                onChange={this.setSubmitText}
                onKeyDown={() => this.setState({ pristine: false })}
              />
              {this.isNotValid() && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t('Required fields empty')}
                </HelpBlock>
              )}
            </FormGroup>
            <FormGroup style={{ marginBottom: 30 }}>
              <Col
                componentClass={ControlLabel}
                className="modal-create-group-chat__text-error"
                xs={5}>
                {`${this.getAsterisk()} ${t('Required fields')}`}
              </Col>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => hideModalDialog()}>
              {t('Cancel')}
            </Button>
            <Button
              bsStyle="primary"
              disabled={this.isValidNameField()}
              onClick={() => {
                gaSend({
                  category: 'Common',
                  action: 'group_chat_create',
                  label: userEmail
                });
                hideModalDialog();
                submit(this.state.text);
              }}>
              {t('Next')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalCreateGroupChat.propTypes = {
  t: PropTypes.func.isRequired
};

ModalCreateGroupChat.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    userEmail: state.getIn(['user', 'user', 'email'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog,
        gaSend: servicesAction.gaSend
      },
      dispatch
    )
)(translate()(ModalCreateGroupChat));
