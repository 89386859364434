import React, { useState } from 'react';
import cn from 'classnames';
import { convertUrl } from '@root/components/ChatWidget/modules/files/utils';
import { Icon } from '@link/react-components';
import styles from './ImageControl.module.css';

const ImageControl = ({ className, imgSrc, name, alt, onFileChoose }) => {
  const [canvasHovered, setCanvasHovered] = useState(false);
  const [addBtnHovered, setAddBtnHovered] = useState(false);

  const onCanvasMouseEnter = () => {
    setCanvasHovered(true);
    setAddBtnHovered(true);
  };

  const onCanvasMouseLeave = () => {
    setCanvasHovered(false);
    setAddBtnHovered(false);
  };

  const onAddBtnMouseEnter = () => {
    onCanvasMouseEnter();
  };

  const onAddImage = (e) => {
    onCanvasMouseLeave();
    onFileChoose(e);
  };

  return (
    <div className={cn(className, styles.container)}>
      <button
        onMouseEnter={onAddBtnMouseEnter}
        className={cn(styles.addImageBtn, {
          [styles.addImageBtnWithImage]: Boolean(imgSrc),
          [styles.addBtnHover]: Boolean(imgSrc) && addBtnHovered
        })}
        onClick={onAddImage}>
        <Icon
          className={styles.addImageIcon}
          iconName="plus"
          height={24}
          width={24}
        />
      </button>
      <div
        className={cn(styles.canvas, {
          [styles.canvasHover]: Boolean(imgSrc) && canvasHovered
        })}
        onMouseEnter={onCanvasMouseEnter}
        onMouseLeave={onCanvasMouseLeave}>
        {imgSrc && (
          <img
            className={styles.img}
            key={imgSrc}
            name={name}
            src={imgSrc ? convertUrl(imgSrc) : null}
            alt={alt || 'image'}
          />
        )}
      </div>
    </div>
  );
};

export default ImageControl;
