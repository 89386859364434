import { STATUSES } from '@root/entities';

export const MODAL_KEY = 'MODAL_CHANGE_USER_STATUS';

export const translate = (status) =>
  `modules.userStatuses.modal.label.status.${status}`;

export const userStatusesLabels = Object.freeze({
  [STATUSES.noStatus.value]: translate('noStatus'),
  [STATUSES.dnd.value]: translate('dnd'),
  [STATUSES.away.value]: translate('away')
});
export const MODES = {
  EDIT: 'EDIT',
  ASSIGN: 'ASSIGN'
};

// export const statusPeriodTypes = Object.freeze({
//   infinite: 'infinite',
//   halfHour: 'halfHour',
//   hour: 'hour',
//   threeHour: 'threeHour',
//   today: 'today',
//   thisWeek: 'thisWeek',
//   manually: 'manually'
// });

// export const statusPeriod = Object.freeze({
//   [statusPeriodTypes.infinite]: 'infinite',
//   [statusPeriodTypes.halfHour]: 'half-hour',
//   [statusPeriodTypes.hour]: 'hour',
//   [statusPeriodTypes.threeHour]: 'three-hour',
//   [statusPeriodTypes.today]: 'today',
//   [statusPeriodTypes.thisWeek]: 'this-week',
//   [statusPeriodTypes.manually]: 'manually'
// });

// export const statusPeriodLabels = {
//   [statusPeriodTypes.infinite]: 'infinite',
//   [statusPeriodTypes.halfHour]: 'halfHour',
//   [statusPeriodTypes.hour]: 'hour',
//   [statusPeriodTypes.threeHour]: 'threeHour',
//   [statusPeriodTypes.today]: 'today',
//   [statusPeriodTypes.thisWeek]: 'thisWeek',
//   [statusPeriodTypes.manually]: 'manually'
// };

// export const ERROR_REASON = {
//   INCORRECT_TIME: 'INCORRECT_TIME',
//   INCORRECT_DATE: 'INCORRECT_DATE'
// };

// export const TIMES_CONSTANTS = {
//   ONE_HOUR: 1,
//   THIRTY_MINUTES: 30,
//   THREE_HOURS: 3,
//   TIME_STEP: 30 // minutes
// };
