import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from '@hooks';
import { haveActivatedMS } from 'core/data/light/channelMember';
import Input from '../../Components/Editor';
import { DEFAULT_MAX_MSG_LENGTH } from '../../../../constants';
import { convertFileUrls, convertUrl } from '../../modules/files/utils';
import { useDeepMemo } from '../../hooks';
import './style.css';

const transformToMentionSugges = (members) => {
  const getAvatarSrc = (member) =>
    member.avatarSrc ? convertUrl(member.avatarSrc) : '/img/user.svg';

  return members.map((member) => ({
    name: member.userName,
    avatar: getAvatarSrc(member),
    employeeId: member.employeeId
  }));
};

const CWInputMsg = ({
  input,
  chat,
  handler,
  editMode,
  isForwardMode,
  isDisableAttach,
  config,
  blockEdit
}) => {
  const t = useTranslate();

  const onTyping = useCallback(() => {
    handler('typing');
  }, []);
  const onChange = useCallback((text) => {
    handler('set-draft', text);
  }, []);
  const onSubmit = useCallback((message) => {
    handler('submit', message);
  }, []);
  const onFocus = useCallback(() => {
    handler('remove-divider');
  }, []);
  const onSetEditMode = useCallback(() => {
    handler('edit');
  }, []);
  const onAttachLocalFile = useCallback((file) => {
    handler('attach-local', file);
  }, []);
  const onAttachStorageFile = useCallback((file) => {
    handler('attach-storage', file);
  }, []);
  const onDeleteFile = useCallback((_, file) => {
    handler('delete-file', file);
  }, []);
  const onChangeChat = useCallback(
    (props) => handler('change-chat', props),
    []
  );
  const onSaveTextBeforeUnmount = useCallback(
    (text) => handler('set-text', { chatId: chat.id, text }),
    [chat.id]
  );
  const files = useMemo(() => convertFileUrls(input.files), [input.files]);

  const mentions = useDeepMemo(
    () =>
      transformToMentionSugges(
        !chat ? [] : chat.members.filter(haveActivatedMS)
      ),
    [chat.members]
  );
  return (
    <Input
      onChangeEditor={onChangeChat}
      onSaveTextBeforeUnmount={onSaveTextBeforeUnmount}
      onTyping={onTyping}
      onChange={onChange}
      onSubmit={onSubmit}
      onFocus={onFocus}
      onSetEditMode={onSetEditMode}
      onAttachLocalFile={onAttachLocalFile}
      onAttachStorageFile={onAttachStorageFile}
      onDeleteFile={onDeleteFile}
      editorId={chat.id}
      mentions={mentions}
      placeholder={`${t('chat-widget.section.input-msg.placeholder')}...`}
      files={files}
      maxFilesLength={input.maxFiles}
      maxMsgLength={DEFAULT_MAX_MSG_LENGTH}
      isDisableAttach={isDisableAttach}
      allowSendEmpty={isForwardMode}
      theme={config.theme}
      mode={editMode ? 'edit' : 'main'}
      blockEdit={blockEdit}
    />
  );
};

CWInputMsg.propTypes = {
  chat: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  handler: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired
};

export default React.memo(CWInputMsg);
