import React, { memo, useCallback } from 'react';
import { Typography } from '@link/react-components';
import { useTranslate } from '@hooks';
import { FullRolesSelect } from '@root/entities';
import styles from './CorporateInfo.module.css';

const RoleSelect = ({ role, setRole, additionalOptions, disabled }) => {
  const t = useTranslate();

  const onSelect = useCallback((value) => {
    setRole('roleId', value);
  }, []);

  return (
    <div className={styles.selectWrapper}>
      <Typography className={styles.selectLabel}>
        <label htmlFor="role-select">
          {t('features.corporateInfo.role.label')}
          &nbsp;
          <span className={styles.requiredChar}>* </span>
        </label>
      </Typography>

      <FullRolesSelect
        id="role-select"
        defaultValue={role}
        denyTyping
        setRole={onSelect}
        additionalOptions={additionalOptions}
        disabled={disabled}
      />
    </div>
  );
};

export const MemoizedRoleSelect = memo(RoleSelect);
