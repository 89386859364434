import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withTranslation as translate } from 'react-i18next';
import {
  Modal,
  Form,
  Button,
  FormGroup,
  FormControl,
  ControlLabel
} from 'react-bootstrap';
import { haveActivatedMS } from 'core/data/light/channelMember';
import Helper from '../../Helper';
import * as modalAction from '../../../action-creators/modal';
import * as temporaryAction from '../../../action-creators/temporary';
import * as channelUC from '../../../useCases/channel';
import Members from '../Members';
import {
  getTempField,
  getChannelName,
  getAllChannelMembers,
  getContacts
} from '../../../storeGetters';
import * as modalSetsAction from '../../../action-creators/modalSets';

const RequiredSymbol = () => <span style={{ color: 'red' }}>*</span>;

const CounterOfSymbols = ({ string, max }) => (
  <h6 className="pull-right" style={{ marginTop: 5 }}>
    <small>{`${string.length}/${max}`}</small>
  </h6>
);

CounterOfSymbols.propTypes = {
  string: PropTypes.string.isRequired,
  max: PropTypes.number.isRequired
};

class SimpleModal extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.showModalPickNewMembers = this.showModalPickNewMembers.bind(this);
  }

  onSubmit() {
    const {
      channelName,
      excludeMembers,
      clearTemporary,
      onCloseModal,
      updateChannel,
      inviteMembers,
      membersRoles
    } = this.props;

    updateChannel({
      name: channelName,
      excludeMembers,
      inviteMembers,
      membersRoles: Object.entries(membersRoles)
    });
    clearTemporary();
    onCloseModal();
  }

  onCancel() {
    const { clearTemporary, onCloseModal } = this.props;
    clearTemporary();
    onCloseModal();
  }

  getMembersUsers() {
    const { members, contacts } = this.props;
    const activatedMembersId = members
      .filter(haveActivatedMS)
      .map((member) => member.employeeId);
    return contacts.filter((user) =>
      activatedMembersId.includes(user.employeeId)
    );
  }

  getNotMembersUsers() {
    const { members, contacts } = this.props;
    const activatedMembersId = members
      .filter(haveActivatedMS)
      .map((member) => member.employeeId);
    return contacts.filter(
      (user) => !activatedMembersId.includes(user.employeeId)
    );
  }

  validStatus() {
    const { channelName } = this.props;

    if (channelName.trim().length > 0) return null;

    return 'error';
  }

  isValid() {
    return this.validStatus() !== 'error';
  }

  showModalPickNewMembers() {
    const {
      showModal,
      setInviteMembers,
      setMemberRole,
      editChannelModal,
      inviteMembers,
      membersRoles
    } = this.props;

    showModal('ADD_TO_GROUP_CHAT', {
      submit: (inviteMembersIds, inviteMembersRoles) => {
        setInviteMembers(inviteMembersIds);
        inviteMembersRoles.forEach(([member, role]) =>
          setMemberRole(member, role)
        );
        editChannelModal();
      },
      resetMembersIds: true,
      resetMembersRoles: true,
      initialMemberIds: inviteMembers,
      initialMemberRoles: Object.entries(membersRoles),
      btnSubmitText: 'Add',
      cancel: editChannelModal,
      members: this.getMembersUsers(),
      dialogs: this.getNotMembersUsers()
    });
  }

  render() {
    const {
      t,
      modalTitle,
      btnTextConfirm,
      btnTextCancel,
      channelName,
      setChannelName
    } = this.props;

    const field = {
      title: t('Name'),
      value: channelName,
      onChange: (e) => setChannelName(e.target.value),
      maxSymbols: 60,
      validStatus: this.validStatus()
    };

    return (
      <Modal id="simpleSubmit" onHide={this.onCancel} show>
        <div className="modal-container modal-container_type_default modal-container_theme_default">
          <Modal.Header>
            <Modal.Title>
              {t(modalTitle)}
              <span
                className="modal-header-cross pull-right"
                onClick={this.onCancel}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <FormGroup validationState={field.validStatus} controlId="name">
                <ControlLabel className="control-label-row">
                  {field.title}
                  <RequiredSymbol />
                </ControlLabel>
                <CounterOfSymbols string={field.value} max={field.maxSymbols} />
                <FormControl
                  type="text"
                  maxLength={field.maxSymbols}
                  value={field.value}
                  onChange={field.onChange}
                />
              </FormGroup>

              <ControlLabel className="control-label-row">
                {t('channel.members')}
              </ControlLabel>

              <Members mode="Channel" />

              <div className="members__panel">
                <button
                  type="button"
                  className="btn-add-members"
                  onClick={this.showModalPickNewMembers}>
                  <img
                    className="btn-add-members__img"
                    src="/img/plus_in_circle.svg"
                    role="presentation"
                    alt={t('channel.add-members')}
                  />
                  <div className="btn-add-members__text">
                    {t('channel.add-members')}
                  </div>
                </button>
                <div className="members__panel-helper">
                  <Helper text={t('channel.help-add-members')} />
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button id="cancel" className="pull-left" onClick={this.onCancel}>
              {t(btnTextCancel)}
            </Button>
            <Button
              id="submit"
              bsStyle="primary"
              disabled={!this.isValid()}
              onClick={this.onSubmit}>
              {t(btnTextConfirm)}
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    );
  }
}

SimpleModal.propTypes = {
  t: PropTypes.func.isRequired,
  modalTitle: PropTypes.string,
  btnTextConfirm: PropTypes.string,
  btnTextCancel: PropTypes.string,
  updateChannel: PropTypes.func.isRequired,
  setMemberRole: PropTypes.func.isRequired,
  setChannelName: PropTypes.func.isRequired,
  setInviteMembers: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  clearTemporary: PropTypes.func.isRequired,
  editChannelModal: PropTypes.func.isRequired,
  channelName: PropTypes.string.isRequired,
  members: PropTypes.array.isRequired,
  contacts: PropTypes.array.isRequired,
  membersRoles: PropTypes.object.isRequired,
  excludeMembers: PropTypes.array.isRequired,
  inviteMembers: PropTypes.array.isRequired
};

SimpleModal.defaultProps = {
  modalTitle: 'channel.edit-channel',
  btnTextConfirm: 'Save',
  btnTextCancel: 'Cancel'
};

export default connect(
  (state) => ({
    channelName: getTempField(state, 'channelName', getChannelName(state)),
    membersRoles: getTempField(state, 'membersRoles', {}),
    excludeMembers: getTempField(state, 'excludeMembers', []),
    inviteMembers: getTempField(state, 'inviteMembers', []),
    members: getAllChannelMembers(state),
    contacts: getContacts(state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        updateChannel: channelUC.updateChannel,
        showModal: modalAction.showModal,
        onCloseModal: modalAction.hideModalDialog,
        setMemberRole: temporaryAction.setToMapTemp('membersRoles'),
        setChannelName: temporaryAction.setValueTemp('channelName'),
        clearTemporary: temporaryAction.clearTemporary,
        setInviteMembers: temporaryAction.setToSetTemp('inviteMembers'),
        editChannelModal: modalSetsAction.editChannel
      },
      dispatch
    )
)(translate(['ui'])(SimpleModal));
