import { ACTION_TYPES } from './constants';

export const actions = {
  init: (personalInfo) => ({
    type: ACTION_TYPES.INIT,
    payload: { personalInfo }
  }),

  changeFiled: (key, value, error = null) => ({
    type: ACTION_TYPES.CHANGE_FIELD,
    payload: { key, value, error }
  })
};
