export const ACTION_TYPES = {
  INIT: 'INIT',
  CHANGE_FIELD: 'CHANGE_FIELD'
};

export const fields = Object.freeze({
  firstName: 'firstName',
  lastName: 'lastName',
  middleName: 'middleName',
  phoneNumber: 'phoneNumber',
  avatar: 'avatar',
  email: 'email'
});

export const requiredFields = [fields.email, fields.firstName, fields.lastName];
export const latOrCyrillicFields = [
  fields.firstName,
  fields.lastName,
  fields.middleName
];
