import React, { useCallback } from 'react';
import { useActions, useModal as useGeneralModal, useTranslate } from '@hooks';
import * as alertActions from '@root/action-creators/message';
import ModalSimpleSubmit from '../ModalSimpleSubmit';
import { isNilOrEmpty, noop } from '../../../utils/utils';
import { purchaseResponseService } from '../../../api/purchaseResponseService';
import * as requestActions from '../../../action-creators/purchaseRequests';
import { actions as myRequestActions } from '../../PurchaseRequests/MyRequests/reducer';
import { requestInvoice } from '../../../presenters/sidebars/PurchaseRequestResponsesSidebar/status';

import { actions as responseActions } from '../../PurchaseRequests/Responses/reducer';

const MODAL_KEY = 'MODAL_ACCEPT_PURCHASE_RESPONSE';
export const key = MODAL_KEY;

export const useModal = () => {
  const { onOpen, onClose } = useGeneralModal();

  const open = useCallback(
    ({
      requestId,
      responseId,
      selectedProducts,
      onError,
      onBeforeSubmit,
      onAfterSubmit,
      isSelectedAll
    }) => {
      onOpen(MODAL_KEY, {
        requestId,
        responseId,
        selectedProducts,
        onError,
        onBeforeSubmit,
        onAfterSubmit,
        isSelectedAll
      });
    },
    [onOpen]
  );

  const close = useCallback(() => {
    onClose(MODAL_KEY);
  }, [onClose]);

  return [open, close];
};

export const Modal = ({
  requestId,
  responseId,
  selectedProducts,
  onBeforeSubmit,
  onAfterSubmit,
  onError,
  isSelectedAll = false
}) => {
  const t = useTranslate();
  const {
    unpublishRequest,
    changeResponseStatus,
    bindProductsToResponse,
    rejectAllPendingResponses
  } = useActions(myRequestActions);
  const {
    bindProducts: bindProductsToSingleRequest,
    unpublishPurchaseRequest: unpublishSingleRequest,
    rejectAllPendingResponses: rejectAllPendingResponsesOfSingleRequest,
    setResponseStatus: setResponseOfSingleRequest
  } = useActions(requestActions);

  const { setSuccessMessage } = useActions(alertActions);

  const { acceptResponse: acceptResponseSingle } = useActions(responseActions);

  const [, close] = useModal();

  const onSubmit = useCallback(async () => {
    try {
      onBeforeSubmit?.();
      const result = await purchaseResponseService.sentInvoiceRequest(
        responseId,
        selectedProducts
      );
      setSuccessMessage({
        key: 'respondForm.alerts.invoice-request-success'
      });
      onAfterSubmit?.();
      changeResponseStatus({ responseId, status: requestInvoice });
      acceptResponseSingle({ responseId });
      setResponseOfSingleRequest({
        requestId,
        responseId,
        status: requestInvoice
      });
      const requestProducts = result?.request?.products;

      if (!isNilOrEmpty(requestProducts)) {
        bindProductsToResponse({ requestId, products: requestProducts });
        bindProductsToSingleRequest({ requestId, products: requestProducts });
      }

      if (
        result?.request &&
        !isNilOrEmpty(requestProducts) &&
        result.request.hasAvailablePositions === false
      ) {
        unpublishRequest({ id: requestId });
        rejectAllPendingResponses({ requestId });
        unpublishSingleRequest(requestId);
        rejectAllPendingResponsesOfSingleRequest({ requestId });
      }
    } catch (error) {
      onError?.(error);
    } finally {
      close();
    }
  }, [requestId, responseId, selectedProducts, changeResponseStatus]);

  return (
    <ModalSimpleSubmit
      title={t('accept_to_respond')}
      text={
        isSelectedAll
          ? t('request_response_accept_modal_text')
          : t('request_response_part_accept_modal_text')
      }
      textBtnConfirm={t('Confirm')}
      submit={onSubmit}
      hideModalDialog={close}
      onSubmited={noop}
      onCancel={close}
    />
  );
};
