import cn from 'classnames';
import { Typography } from '../Typography';
import styles from './Link.module.scss';
import { LinkProps } from './Link.types';

export function Link({
  href,
  className = '',
  displayName = '',
  openInNewTab = true,
  onClick
}: LinkProps) {
  return (
    <Typography variant="body1Med" className={cn(styles.link, className)}>
      <a
        href={href}
        className={styles.link}
        target={(openInNewTab && '_blank') || '_self'}
        onClick={onClick}>
        {displayName.length === 0 ? href : displayName}
      </a>
    </Typography>
  );
}
