import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation as translate } from 'react-i18next';
import { PanelGroup } from 'react-bootstrap';
import SingleForwardDialogCard from './SingleForwardDialogCard';

const ForwardDialogCards = ({
  t,
  users,
  supportUser,
  search,
  from,
  currentUser,
  forwardMessage
}) => (
  <PanelGroup id="dialog-list" className="dialog-list">
    <SingleForwardDialogCard
      t={t}
      users={users}
      search={search}
      from={from}
      currentUser={currentUser}
      supportUser={supportUser}
      forwardMessage={forwardMessage}
    />
  </PanelGroup>
);

ForwardDialogCards.propTypes = {
  t: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  from: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  users: PropTypes.object.isRequired,
  supportUser: PropTypes.string.isRequired
};

export default translate(['ui'], { wait: true })(ForwardDialogCards);
