import React, { useCallback, useContext, useMemo } from 'react';

import { useModal } from '@hooks';
import { prop } from 'ramda';
import { downloadFile } from '@root/components/PurchaseRequests/Request/files';
import { showFilesViewer } from '@root/utils/filesViewerInterfaceCreator';
import { FILES_BLOCK_MODES } from '../lib/constants';

const FileContext = React.createContext({
  mode: FILES_BLOCK_MODES.EDIT,
  files: []
});

export const FileContextProvider = ({ children, files, mode, setFiles }) => {
  const { onOpen: showModal } = useModal();

  const onDownloadFile = useCallback(
    (fileId) => {
      const targetFile = files.find((file) => file.id === fileId);
      downloadFile(targetFile.fileInfo);
    },
    [files]
  );

  const onShowPreview = useCallback(
    (fileId) => {
      const targetFile = files.find((file) => file.id === fileId);
      showFilesViewer({
        file: targetFile.fileInfo,
        files: files.map(prop('fileInfo')),
        showModal
      });
    },
    [showModal, files]
  );

  const onDeleteFile = useCallback((fileId) => {
    setFiles((prevState) => prevState.filter((file) => file.id !== fileId));
  }, []);

  const context = useMemo(
    () => ({
      files,
      mode,
      onDeleteFile,
      onShowPreview,
      onDownloadFile
    }),
    [files, mode, onDeleteFile, onShowPreview, onDownloadFile]
  );

  return (
    <FileContext.Provider value={context}>{children}</FileContext.Provider>
  );
};

export const useFiles = () => useContext(FileContext);
