import React, { useCallback } from 'react';
import { ButtonIcon, Tooltip } from '@link/react-components';
import { useTranslate } from '@hooks';

export const GoToIstock = ({ className, externalId, size = 18 }) => {
  const t = useTranslate();

  const handleOpen = useCallback(() => {
    const redirectUrl = `${process.env.ISTOCK_INFO_SERVICE}/product/${externalId}`;
    const url = `/api/external-system/istock-info?url=${redirectUrl}`;

    window.open(url);
  }, [externalId]);

  return (
    <Tooltip title={t('open_product_external')}>
      <ButtonIcon
        className={className}
        iconName="link-outside-round"
        variant="small"
        size={size}
        onClick={handleOpen}
      />
    </Tooltip>
  );
};
