import React, { useCallback, useEffect, useRef, useState } from 'react';
import { MemoizedCorporateInfo, MemoizedPersonalInfo } from '@root/features';
import { mergeLeft, pipe } from 'ramda';
import { employeeFormMapper } from '@root/widgets/EmployeeForm/lib/mapper';
import styles from './EmployeeForm.module.css';

export const EmployeeForm = ({ onFormChange }) => {
  const [personalInfo, setPersonalInfo] = useState({});
  const [corporateInfo, setCorporateInfo] = useState({});

  const corporateInfoRef = useRef({});
  const personalInfoRef = useRef({});

  const onPersonalInfoChange = useCallback((_personalInfo) => {
    personalInfoRef.current = _personalInfo;
    setPersonalInfo(_personalInfo);
  }, []);

  const onCorporateInfoChange = useCallback((_corporateInfo) => {
    corporateInfoRef.current = _corporateInfo;
    setCorporateInfo(_corporateInfo);
  }, []);

  useEffect(() => {
    pipe(
      employeeFormMapper.transformToModel,
      onFormChange
    )(mergeLeft(personalInfoRef.current, corporateInfoRef.current));
  }, [corporateInfo, personalInfo]);

  return (
    <div className={styles.container}>
      <MemoizedPersonalInfo onChange={onPersonalInfoChange} />
      <div className={styles.divider} />
      <MemoizedCorporateInfo onChange={onCorporateInfoChange} />
    </div>
  );
};
