import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { withTranslation as translate } from 'react-i18next';
import { Modal, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';

import * as modalActions from '../../action-creators/modal';

const b = block('modal-container');

class ModalPartnerInfo extends Component {
  getChildContext() {
    return { b };
  }

  render() {
    const { t, type, hideModalDialog, onHide, partnerRequestInfo } = this.props;

    const text = partnerRequestInfo.get('supplier')
      ? 'partner_supplier_text'
      : 'partner_customer_text';
    const companyName =
      partnerRequestInfo.get('supplier') || partnerRequestInfo.get('customer');

    return (
      <Modal
        id="partnerInfo"
        onHide={() => {
          if (onHide) {
            return onHide();
          }
          return hideModalDialog();
        }}
        show>
        <ModalContainer mods={{ type: type || 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('Welcome to Goodwix')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => {
                  if (onHide) {
                    return onHide();
                  }
                  return hideModalDialog();
                }}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <p
                className={b('row')}
                dangerouslySetInnerHTML={{ __html: t(text, { companyName }) }}
              />
            </Form>
          </Modal.Body>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalPartnerInfo.propTypes = {
  t: PropTypes.func.isRequired
};

ModalPartnerInfo.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    user: state.getIn(['user', 'user'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalPartnerInfo));
