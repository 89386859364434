import React from 'react';
import cn from 'classnames';
import { FONTS, Fonts } from './config';
import styles from './Typography.module.css';

export type Align = 'left' | 'right' | 'center';

export interface TypographyProps extends React.AllHTMLAttributes<HTMLElement> {
  Component?: React.ElementType;
  children?: React.ReactNode;
  variant?: Fonts;
  noWrap?: boolean;
  align?: Align;
  inline?: boolean;
}

export function Typography({
  Component = 'span',
  variant = 'body2Med',
  noWrap = false,
  align = 'left',
  inline = false,
  children,
  ...restProps
}: TypographyProps) {
  const font = FONTS[variant];
  const TextComponent = font?.Component || Component;
  return (
    <TextComponent
      {...restProps}
      className={cn(
        styles.Text,
        noWrap && styles.noWrap,
        inline && styles.inline,
        styles[align],
        styles[`Text-l-${font.level}`],
        styles[`Text-w-${font.weight}`],
        styles[`Text-lh-${font.lineHeight}`],
        {
          [restProps.className as string]: Boolean(restProps.className)
        }
      )}>
      {children}
    </TextComponent>
  );
}
