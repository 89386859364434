import React, { useCallback, useMemo, useRef } from 'react';
import { useTranslate } from '@hooks';
import { Select, Typography } from '@link/react-components';
import { VISIBILITY } from '../../model/constants';
import styles from './VisibilitySelect.module.css';

const getOptionLabel = (option) => option.label;

function renderOption(option) {
  return (
    <Typography variant="body1Reg" className={styles.option}>
      {option.label}
    </Typography>
  );
}

export const VisibilitySelect = ({
  id,
  defaultValue,
  setVisibility,
  denyTyping
}) => {
  const t = useTranslate();
  const translate = (type) => t(`entity.visibility.${type}`);
  const onSelect = useCallback(
    (entity) => {
      setVisibility('visibility', entity.value);
    },
    [setVisibility]
  );

  const options = useMemo(
    () => [
      {
        key: VISIBILITY.VISIBLE,
        value: {
          label: translate('visible'),
          value: VISIBILITY.VISIBLE
        }
      },
      {
        key: VISIBILITY.HIDDEN,
        value: {
          label: translate('hidden'),
          value: VISIBILITY.HIDDEN
        }
      }
    ],
    [t]
  );

  const defaultVisibility = useRef(
    options.find((option) => option.key === defaultValue)
  );

  return (
    <Select
      id={id}
      options={options}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      onSelect={onSelect}
      defaultValue={defaultVisibility.current}
      denyTyping={denyTyping}
    />
  );
};
