import {
  validateOnlyCyrillicOrLatin,
  validateEmail,
  validatePhoneNumber
} from '@root/utils/validateField';

export function validateForm(formState) {
  const { firstName, lastName, email, position, phoneNumber } = formState;

  return (
    (!phoneNumber.length || validatePhoneNumber(phoneNumber)) &&
    firstName.length > 0 &&
    validateOnlyCyrillicOrLatin(firstName) &&
    lastName.length > 0 &&
    validateOnlyCyrillicOrLatin(lastName) &&
    email.length > 0 &&
    validateEmail(email) &&
    position.length > 0
  );
}
