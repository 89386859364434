import React, { Component } from 'react';
import { withTranslation as translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  FormGroup,
  FormControl,
  ControlLabel,
  Button
} from 'react-bootstrap';

import '../styles/modal_theme_default.styl';
import * as modalActions from '../../../action-creators/modal';

import { ModalContainer } from '../elements';

const getTrans = (sections) => `modals.add-link.${sections}`;
const lenLessThan = (number, string) => string.length < number;
const hasHttpProtocol = (url) => /^(http\:\/\/|https\:\/\/|\/\/)/.test(url);

const normilizeUrl = (url) => (hasHttpProtocol(url) ? url : `//${url}`);

class ModalAddLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      url: ''
    };
    this.onChangeText = this.onChangeText.bind(this);
    this.onChangeUrl = this.onChangeUrl.bind(this);
    this.onClickSubmit = this.onClickSubmit.bind(this);
  }

  setText(text) {
    this.setState({ text });
  }

  setUrl(url) {
    this.setState({ url });
  }

  setTextAndUrl(value) {
    this.setState({ text: value, url: value });
  }

  onChangeText(e) {
    this.setText(e.target.value);
  }

  onChangeUrl(e) {
    const { text, url } = this.state;
    if (text === url) {
      this.setTextAndUrl(e.target.value);
    } else {
      this.setUrl(e.target.value);
    }
  }

  disabledSubmit() {
    const { text, url } = this.state;
    return lenLessThan(3, text.trim()) || lenLessThan(3, url);
  }

  prepareUrl() {}

  onClickSubmit() {
    const { onSubmit, hideModalDialog } = this.props;
    const { text, url } = this.state;
    onSubmit({ text: text.trim(), url: normilizeUrl(url) });
    hideModalDialog();
  }

  render() {
    const { t, hideModalDialog } = this.props;

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="addLink" onHide={hideModalDialog} show>
          <Modal.Header>
            <Modal.Title>
              {t(getTrans('title'))}
              <span
                className="modal-header-cross pull-right"
                onClick={hideModalDialog}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <ControlLabel>{t(getTrans('label-link-text'))}</ControlLabel>
              <FormControl
                type="text"
                onChange={this.onChangeText}
                value={this.state.text}
              />
            </FormGroup>
            <FormGroup>
              <ControlLabel>{t(getTrans('label-url'))}</ControlLabel>
              <FormControl
                type="text"
                onChange={this.onChangeUrl}
                value={this.state.url}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button id="cancel" className="pull-left" onClick={hideModalDialog}>
              {t(getTrans('btn-cancel'))}
            </Button>
            <Button
              id="submit"
              bsStyle="primary"
              disabled={this.disabledSubmit()}
              onClick={this.onClickSubmit}>
              {t(getTrans('btn-submit'))}
            </Button>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      hideModalDialog: modalActions.hideModalDialog
    },
    dispatch
  )
)(translate()(ModalAddLink));
