import React, { Component, Fragment } from 'react';
import { isEmpty } from 'ramda';
import PropTypes from 'prop-types';
import { withTranslation as translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Col,
  DropdownButton
} from 'react-bootstrap';
import customHistory from '../../../customHistory';
import '../styles/modal_theme_default.styl';
import { DescriptionEditor } from '../../DescriptiorEditor';
import { getTempField } from '../../../storeGetters';
import CounterText from '../../CounterText';
import RedStar from '../../RedStar';
import Files from '../../Channel/file/Files';
import FileSelectWrap from '../../FileSelectWrap';

import { ModalContainer } from '../elements';
import * as tempAT from '../../../action-creators/temporary';
import * as modalActions from '../../../action-creators/modal';

class ModalTopic extends Component {
  constructor(props) {
    super(props);
    this.state = { pristine: true };
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDesc = this.onChangeDesc.bind(this);
    this.fileSelectHandler = this.fileSelectHandler.bind(this);
    this.submit = this.submit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.openStorage = this.openStorage.bind(this);
  }

  async componentDidMount() {
    await this.props.initUpload();
  }

  onChangeTitle(e) {
    const { MAX_TITLE, setKeyValueTemp } = this.props;

    const name = e.target.value;
    if (name.length <= MAX_TITLE) {
      setKeyValueTemp('name', name);
    }
  }

  onChangeDesc(e) {
    const { setKeyValueTemp } = this.props;
    setKeyValueTemp('desc', e.target.value);
  }

  async clearData() {
    const { clearValueTemp, clear } = this.props;
    clearValueTemp('name');
    clearValueTemp('desc');
    await clear();
  }

  isNotNameValid() {
    return !this.props.name && !this.state.pristine;
  }

  isValidNameField() {
    return this.props.name.length < 1 || /^\s+$/.test(this.props.name);
  }

  async fileSelectHandler(rawFiles) {
    const { attachFiles, MAX_FILES } = this.props;

    const files = [];
    for (let i = 0; i < rawFiles.length; i++) {
      files.push(rawFiles[i]);
    }
    await attachFiles(files, MAX_FILES);
  }

  async submit() {
    const { name, desc, hideModalDialog, onSubmit } = this.props;

    hideModalDialog();
    onSubmit({ name, desc });
    await this.clearData();
  }

  async cancel() {
    const { hideModalDialog, onCancel } = this.props;

    hideModalDialog();
    onCancel();
    await this.clearData();
  }

  isMaxFiles() {
    const { MAX_FILES, files } = this.props;
    return files.length >= MAX_FILES;
  }

  openStorage() {
    const { hideModalDialog, storage } = this.props;

    hideModalDialog();

    const from = location.pathname; // eslint-disable-line
    customHistory.push({
      pathname: storage.url,
      state: { from, operation: storage.operation }
    });
  }

  render() {
    const {
      t,
      title,
      textBtnSubmit,
      name,
      desc,
      files,
      removeFile,
      storageSize,
      maxStorageSize,
      MAX_TITLE,
      readOnly
    } = this.props;

    return (
      <Modal show>
        <ModalContainer mods={{ type: 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t(title)}
              <span
                className="modal-header-cross pull-right"
                onClick={this.cancel}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup
              controlId="txt"
              validationState={this.isNotNameValid() ? 'error' : null}>
              <div style={{ overflow: 'hidden' }}>
                <ControlLabel>
                  {t('Name')}
                  {!readOnly && <RedStar />}
                </ControlLabel>
                {!readOnly && <CounterText text={name} maxLength={MAX_TITLE} />}
              </div>
              {readOnly && <div>{name}</div>}
              {!readOnly && (
                <FormControl
                  type="text"
                  placeholder=""
                  value={name}
                  maxLength={MAX_TITLE}
                  onChange={this.onChangeTitle}
                  onKeyDown={() => this.setState({ pristine: false })}
                />
              )}
              {this.isNotNameValid() && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t('Required fields empty')}
                </HelpBlock>
              )}
            </FormGroup>

            <FormGroup controlId="desc">
              {(readOnly && !isEmpty(desc)) ||
                (!readOnly && (
                  <div style={{ overflow: 'hidden' }}>
                    <ControlLabel>{t('topic_description')}</ControlLabel>
                  </div>
                ))}
              {readOnly && <div dangerouslySetInnerHTML={{ __html: desc }} />}
              {!readOnly && (
                <DescriptionEditor value={desc} onChange={this.onChangeDesc} />
              )}
            </FormGroup>

            {!readOnly && (
              <DropdownButton
                id="select-files"
                className="breadcrumbs-buttons-dropdown files-dropdown no-disabled-cursor"
                disabled={storageSize >= maxStorageSize || this.isMaxFiles()}
                title={t('topic.attach_docs')}>
                <div className="dropdown_menu_item" onClick={this.openStorage}>
                  {t('Attach_from_storage')}
                </div>
                <FileSelectWrap
                  multiple
                  accept=""
                  className="dropdown_menu_item"
                  onChange={this.fileSelectHandler}
                  disabled={storageSize >= maxStorageSize || this.isMaxFiles()}
                  component={({ onClick }) => (
                    <div
                      onClick={(e) => {
                        document.getElementsByClassName('root')[0].click();
                        onClick(e);
                      }}>
                      {t('Attache file')}
                    </div>
                  )}
                />
              </DropdownButton>
            )}

            <div style={{ margin: '10px 0 10px' }}>
              <Files
                files={files}
                mode={readOnly ? 'none' : 'only-delete'}
                modePreview="simple"
                onDelete={removeFile}
              />
            </div>

            <FormGroup style={{ marginBottom: 30 }}>
              <Col
                componentClass={ControlLabel}
                className="modal-create-group-chat__text-error"
                xs={5}>
                <RedStar />
                {` ${t('Required fields')}`}
              </Col>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            {!readOnly && (
              <>
                <Button id="cancel" className="pull-left" onClick={this.cancel}>
                  {t('Cancel')}
                </Button>
                <Button
                  bsStyle="primary"
                  disabled={this.isValidNameField()}
                  onClick={this.submit}>
                  {t(textBtnSubmit)}
                </Button>
              </>
            )}
            {readOnly && (
              <Button id="cancel" onClick={this.cancel}>
                {t('Close')}
              </Button>
            )}
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalTopic.propTypes = {
  t: PropTypes.func.isRequired,
  hideModalDialog: PropTypes.func.isRequired,

  MAX_TITLE: PropTypes.number,
  MAX_FILES: PropTypes.number,
  title: PropTypes.string.isRequired,
  textBtnSubmit: PropTypes.string.isRequired,

  maxStorageSize: PropTypes.number.isRequired,
  storageSize: PropTypes.number.isRequired,

  setKeyValueTemp: PropTypes.func.isRequired,
  clearValueTemp: PropTypes.func.isRequired,
  attachFiles: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,

  name: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  files: PropTypes.array.isRequired,

  initUpload: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,

  storage: PropTypes.object.isRequired,

  readOnly: PropTypes.bool
};

ModalTopic.defaultProps = {
  MAX_TITLE: 60,
  MAX_FILES: 15,
  onCancel: () => {},
  readOnly: false
};

export default connect(
  (state) => ({
    name: getTempField(state, 'name', ''),
    desc: getTempField(state, 'desc', ''),
    maxStorageSize: state.getIn(['storage', 'maxStorageSize']),
    storageSize: state.getIn(['storage', 'storageSize'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog,
        setKeyValueTemp: tempAT.setKeyValueTemp,
        clearValueTemp: tempAT.clearValueTemp
      },
      dispatch
    )
)(translate()(ModalTopic));
