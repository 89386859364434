import React, { useCallback } from 'react';
import { Button, Icon, Typography } from '@link/react-components';

import { useTranslate } from '@hooks';

import { partnersCompanyService } from '@root/api';

import { TRANSLATE_PATH } from '../../constants';

import styles from './DownloadTemplate.module.css';

export const DownloadTemplate = () => {
  const t = useTranslate();
  const translate = (key) => t(`${TRANSLATE_PATH}.${key}`);

  const handleDownloadTemplate = useCallback(
    () => partnersCompanyService.importPartnersTemplate(),
    []
  );

  return (
    <div className={styles.downloadContainer}>
      <Button
        className={styles.downloadButton}
        mode="text"
        before={
          <Icon
            iconName="download-01"
            width={18}
            height={18}
            className={styles.downloadIcon}
          />
        }
        hasHover={false}
        hasActive={false}
        onClick={handleDownloadTemplate}>
        {translate('actions.download')}
      </Button>
      <Typography variant="body1Reg">
        {translate('andFillTheTemplate')}
      </Typography>
    </div>
  );
};
