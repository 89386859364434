export const ACTION_TYPES = {
  INIT: 'INIT',
  CHANGE_FIELD: 'CHANGE_FIELD'
};

export const fields = Object.freeze({
  email: 'email',
  department: 'department',
  position: 'position',
  visibility: 'visibility',
  role: 'role'
});

export const requiredFields = [fields.email, fields.role, fields.position];
