import { actions } from './slice';
import { addQueryParams } from '../../utils/utils';
import request from '../../lib/request';
import { convertToArray } from './utils';

export const getCountries =
  ({ search = '', countryId, lang } = {}) =>
  async (dispatch) => {
    const baseUrl = addQueryParams(`/api/select-options/search/countries`, {
      search,
      countryId,
      lang
    });

    const data = await request.get(baseUrl);
    dispatch(actions.setCountries({ countries: data.list }));

    return data.list;
  };

export const getRegions =
  ({ countryId, search = '', regionId, lang } = {}) =>
  async (dispatch) => {
    const baseUrl = addQueryParams(`/api/select-options/search/regions`, {
      search,
      countryId: convertToArray(countryId),
      regionId,
      lang
    });

    const data = await request.get(baseUrl);
    dispatch(actions.setRegions({ regions: data.list }));
    return data.list;
  };

export const getCities =
  ({ countryId, regionId, search = '', cityId, lang } = {}) =>
  async (dispatch) => {
    const baseUrl = addQueryParams(`/api/select-options/search/cities`, {
      search,
      countryId: convertToArray(countryId),
      regionId: convertToArray(regionId),
      cityId,
      lang
    });

    const data = await request.get(baseUrl);
    dispatch(actions.setCities({ cities: data.list }));
  };

export const getRegionsCount =
  (countryId, lang = 'ru') =>
  async (dispatch) => {
    const baseUrl = addQueryParams(`/api/requests/regions/total`, {
      countryId,
      lang
    });

    const regionsCount = await request.get(baseUrl);
    dispatch(actions.setRegionsCount({ regionsCount }));
  };

export const getCompanies =
  (search = '', { includesGroups = false, withRequestsOnly = false } = {}) =>
  async (dispatch) => {
    const baseUrl = addQueryParams(`/api/select-options/search/all-companies`, {
      search
    });

    const data = await request.post(baseUrl, {
      includesGroups,
      withRequestsOnly
    });
    dispatch(actions.setCompanies({ companies: data.list }));
  };

export const getPurchaseRequestsCategories = () => async (dispatch) => {
  const purchaseRequestsCategories = await request.get(
    '/api/requests/categories'
  );
  dispatch(
    actions.setPurchaseRequestsCategories({ purchaseRequestsCategories })
  );
};
