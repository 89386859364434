import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import Icon from 'react-components/src/icons';
import iconsView from 'react-components/src/icons/constants';
import { useIsClickOutside, useTranslate } from '@hooks';
import { isIstockMobService } from '@root/lib/goodwix';
import { isMaxFiles } from '../../../data-type/block-input';
import Tooltip from '../../../Tooltip';
import { showSpaceLimitNotification } from '../../../modules/storage/useCases';

const convertFiles = (rawFiles) => {
  const files = [];
  for (let i = 0; i < rawFiles.length; i++) {
    files.push(rawFiles[i]);
  }
  return files;
};

const renderDisabledAttach = (t) => (
  <div className="cw-input-msg__btn-attach">
    <div className="cw-input-msg-btn-attach__list-wrapper">
      <Tooltip text={t('attache_editing_mode')}>
        <Icon icon={iconsView.Attach} size={18} />
      </Tooltip>
    </div>
  </div>
);

const CWAttachFileMenu = ({
  files,
  maxLength = 5,
  onAttachStorage,
  onAttachLocal,
  editMode,
  isDisableAttach
}) => {
  const t = useTranslate();

  const dispatch = useDispatch();
  const { ref, isShow, setIsShow } = useIsClickOutside(false);
  const onClick = () => {
    if (!isMaxFiles({ files, maxLength }))
      return setIsShow((prevState) => !prevState);

    return undefined;
  };

  const fileInputRef = useRef(null);

  const emmitClickFile = () => {
    fileInputRef.current.value = null;
    fileInputRef.current.click();
  };

  const _onAttachLocal = (e) => onAttachLocal(convertFiles(e.target.files));
  const onAttachLocalClick = (e) => {
    if (isDisableAttach) {
      e.preventDefault();
      dispatch(showSpaceLimitNotification());
    }
  };

  if (editMode) return renderDisabledAttach(t);

  const showAttachStorage = !isIstockMobService();

  return (
    <div className="cw-input-msg__btn-attach" ref={ref} onClick={onClick}>
      <div className="cw-input-msg-btn-attach__list-wrapper">
        <Tooltip text={t('attach-file')}>
          <Icon icon={iconsView.Attach} size={18} />
        </Tooltip>
        <div
          className={`cw-input-msg-btn-attach__list ${
            isShow ? 'open' : 'close'
          }`}>
          {showAttachStorage && (
            <div
              className="cw-input-msg-btn-attach__item"
              onClick={onAttachStorage}>
              {t('chat-widget.section.input-msg.attach-storage')}
            </div>
          )}
          <div
            className="cw-input-msg-btn-attach__item"
            onClick={emmitClickFile}>
            {t('chat-widget.section.input-msg.attach-local')}
            <input
              type="file"
              style={{ display: 'none' }}
              multiple
              ref={fileInputRef}
              onClick={onAttachLocalClick}
              onChange={_onAttachLocal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CWAttachFileMenu);
