export const unitTypes = Object.freeze({
  instances: 'instances',
  amount: 'amount',
  kg: 'kg',
  liter: 'liter',
  packages: 'packages',
  m: 'm',
  m2: 'm2',
  m3: 'm3'
});
