import React, { useCallback } from 'react';
import { useModal as useGeneralModal, useTranslate, useNavigate } from '@hooks';
import { noop } from '@root/utils/utils';
import { purchaseResponseService } from '@root/api';
import ModalSimpleSubmit from '../ModalSimpleSubmit';

const MODAL_KEY = 'MODAL_ACCEPT_PURCHASE_RESPONSES';
export const key = MODAL_KEY;

export const useModal = () => {
  const { onOpen, onClose } = useGeneralModal();

  const open = useCallback(
    ({ requestId, responses, onError, onBeforeSubmit, onAfterSubmit }) => {
      onOpen(MODAL_KEY, {
        requestId,
        responses,
        onError,
        onBeforeSubmit,
        onAfterSubmit
      });
    },
    [onOpen]
  );

  const close = useCallback(() => {
    onClose(MODAL_KEY);
  }, [onClose]);

  return [open, close];
};

export const Modal = ({
  requestId,
  responses,
  onBeforeSubmit,
  onAfterSubmit,
  onError
}) => {
  const t = useTranslate();

  const navigate = useNavigate();

  const [, close] = useModal();

  const onSubmit = useCallback(async () => {
    try {
      onBeforeSubmit?.();
      await purchaseResponseService.sentBulkInvoiceRequest(
        requestId,
        responses
      );
      onAfterSubmit?.();
      navigate(`/requests/my/${requestId}/view`);
    } catch (error) {
      onError?.(error);
    } finally {
      close();
    }
  }, [requestId, responses]);

  return (
    <ModalSimpleSubmit
      title={t('accept_to_respond')}
      text={t('request_response_accept_modal_text')}
      textBtnConfirm={t('Confirm')}
      submit={onSubmit}
      hideModalDialog={close}
      onSubmited={noop}
      onCancel={close}
    />
  );
};
