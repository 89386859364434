import React, { useEffect, useRef } from 'react';

const TextWrapper = ({ children, store }) => {
  const ref = useRef(null);

  useEffect(() => {
    store.updateClientRect(ref.current && ref.current.getBoundingClientRect());
  }, []);

  return <span ref={ref}>{children}</span>;
};

export default TextWrapper;
