import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Typography, Button, Icon, Loader } from '@link/react-components';
import { useTimer, useTranslate } from '@hooks';
import { Preview, SomethingBroke } from './components';
import { translate } from './Lobby.helpers';
import {
  useSubscribeVideoChatEvent,
  EventKeys,
  useVideoChatContext
} from '../../hooks';
import c from './Lobby.module.css';

const REQUEST_TIMEOUT = 2 * 60 * 1000;
const RETRY_ICON_SIZE = 14;

export const Lobby = ({ onSubmit, onCancel } = {}) => {
  const t = useTranslate();
  const videoChatContext = useVideoChatContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [setTimer, clearTimer] = useTimer();

  const applyError = useCallback(() => {
    setLoading(false);
    setError(true);
    clearTimer();
  }, [clearTimer]);

  useSubscribeVideoChatEvent(
    EventKeys.RejectedJoin,
    () => {
      applyError();
    },
    []
  );

  useLayoutEffect(() => {
    (async () => {
      await videoChatContext.initCurrentStream();
    })();
  }, []);

  const handleSubmit = useCallback(() => {
    setLoading(true);
    setError(null);
    setTimer(applyError, REQUEST_TIMEOUT);
    onSubmit();
  }, [onSubmit]);

  const SubmitButtonIcon = (() => {
    if (loading) {
      return <Loader className={c.loader} />;
    }

    if (error) {
      return (
        <Icon
          iconName="restore"
          width={RETRY_ICON_SIZE}
          height={RETRY_ICON_SIZE}
        />
      );
    }

    return null;
  })();
  const submitButtonText = translate(
    t,
    error ? 'buttons.repeat' : 'buttons.submit'
  );

  return (
    <div className={c.container}>
      <Typography className={c.title} variant="h2" align="center" noWrap>
        {translate(t, 'title')}
      </Typography>

      {!error ? (
        <Preview
          className={c.content}
          member={videoChatContext.currentMember}
        />
      ) : (
        <SomethingBroke className={c.content} />
      )}

      <div className={c.actions}>
        <Button mode="text" onClick={onCancel}>
          {translate(t, 'buttons.cancel')}
        </Button>
        <Button
          mode="primary"
          className={c.submitButton}
          loading={loading}
          onClick={handleSubmit}
          before={SubmitButtonIcon}>
          {submitButtonText}
        </Button>
      </div>
    </div>
  );
};
