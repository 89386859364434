import React from 'react';
import block from 'bem-cn-lite';
import { withTranslation as translate } from 'react-i18next';

const b = block('cwshf-hashtags');

const Tag = ({ name, value, active = false, onClick }) => (
  <div
    key={value}
    className={b('item', { active })}
    onClick={() => onClick(value)}>
    {name}
  </div>
);

const tkey = (name) => `chat-widget.advanced-search.hashtags.${name}`;

const CWSHFHashtags = ({ t, list, activeList, onClickTag }) => {
  const isActive = (name) => activeList.includes(name);

  return (
    <div className={b()}>
      <div className={b('title')}>{t(tkey('title'))}</div>
      <div className={b('list')}>
        {list.map((name) => (
          <Tag
            key={name}
            onClick={onClickTag}
            name={t(tkey(`list.${name}`))}
            value={name}
            active={isActive(name)}
          />
        ))}
      </div>
    </div>
  );
};

export default translate()(CWSHFHashtags);
