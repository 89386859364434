import {
  haveActivatedMS,
  haveMemberStatus
} from 'core/data/light/channelMember';
import { DELETED, NOT_PARTNER } from 'core/data/light/memberStatus';
import { getInterlocutor } from 'core/data/light/dialog';
import { findMember } from 'core/data/light/mainChannel';
import { assoc, isEmpty, isNil } from 'ramda';
import {
  getTypingInfo,
  getOpenedChat,
  getBadges,
  getChatWidget,
  getAdvancedSearch
} from '../../../getter';
import {
  countOnlineByIds,
  getOnline
} from '../../../../../modules/online/getter';
import { getId } from '../../../data-type/showElement';
import getContextMenu from '../../list/chat-list-item/item/context-menu';
import {
  isAdmin as checkIsAdmin,
  isSupport as checkIsSupport
} from '../../../../../lib/goodwix';
import { isActiveItem } from '../../../data-type/block-list';
import { isSearchByNickname } from '../../../data-type/block-advanced-search';
import { memoizeFn } from '../../../../../utils/utils';

export const getShowMode = (state) => {
  const {
    result: { contacts, chats, messages, companies }
  } = getAdvancedSearch(state);
  return isEmpty(contacts) &&
    isEmpty(chats) &&
    isEmpty(messages) &&
    isEmpty(companies)
    ? 'empty'
    : 'list';
};

const createExtraConditions = (type, pinned, notificationOff) => {
  if (type === 'widget')
    return {
      'pinned?': pinned ? 'unpin' : 'pin'
    };
  if (type === 'chats' || type === 'archive') {
    return {
      'notice-on?': notificationOff ? 'notice-on' : 'notice-off',
      'pinned?': pinned ? 'unpin' : 'pin'
    };
  }
  return {};
};

const createExtraList = (type, chat, user) => {
  if (type === 'widget')
    return [
      {
        conditionName: 'pinned?',
        items: [
          { icon: 'pin', value: 'pin' },
          { icon: 'unpin', value: 'unpin' }
        ]
      }
    ];
  if (type === 'chats' || type === 'archiveChats') {
    return getContextMenu(chat, user);
  }
  return [];
};

const getExtraConfig = ({ type, config, chat, user }) => {
  const { extraMenu } = config;
  return {
    extraMenu: {
      ...extraMenu,
      list: createExtraList(type, chat, user),
      conditions: createExtraConditions(type, chat.pinned, chat.notificationOff)
    }
  };
};

const findBadge = (badges, chatId) =>
  badges.find((badge) => badge.chatId === chatId);

const chooseConfig = (config, isAdmin) => {
  if (isAdmin) {
    return {
      clickByRow: config.clickByRow,
      extra: config.extra.adminList
    };
  }

  return {
    clickByRow: config.clickByRow,
    extra: config.extra.list
  };
};

const generateModelContactItem = (contacts, config) => (contact) => {
  const { employeeId, avatar, name, company, isBlockedContact, department } =
    contact;
  const isAdmin = checkIsAdmin(employeeId) || checkIsSupport(employeeId);

  return {
    key: employeeId,
    employeeId,
    active: isActiveItem(contacts, contact),
    avatarSrc: avatar,
    userName: name,
    companyName: company,
    department,
    isAdmin,
    isBlocked: isBlockedContact,
    config: chooseConfig(config.contacts, isAdmin)
  };
};

const generateModelChatItem =
  (type = 'chats', storeValues, config) =>
  (chat) => {
    const {
      type: chatType,
      id: chatId,
      members,
      color: chatColor,
      name: chatName,
      notificationOff: chatNotificationOff,
      lastMessage,
      pinned
    } = chat;
    const { typingInfo, openedChat, online, blockBadges, user, chatWidget } =
      storeValues;
    const employeeId = user.get('employeeId');
    const currentCompanyId = user.get('currentCompany');
    const language = user.get('language');

    const badge = findBadge(blockBadges.badges, chatId);
    const amountOnline = countOnlineByIds(
      online,
      members.filter(haveActivatedMS).map((m) => m.employeeId)
    );

    const interlocutor = memoizeFn(() => {
      const _interlocutor = getInterlocutor(employeeId, chat);

      if (isNil(_interlocutor)) return {};

      if (
        _interlocutor.department &&
        _interlocutor.companyId === currentCompanyId
      ) {
        return assoc('companyName', _interlocutor.department, _interlocutor);
      }

      return _interlocutor;
    })(employeeId, chat);
    let isNotAvailable = false;

    if (chat.type === 'Dialog') {
      const isNotPartner = haveMemberStatus(NOT_PARTNER, interlocutor);
      const isDeleted = haveMemberStatus(DELETED, interlocutor);
      isNotAvailable = isNotPartner || isDeleted;
    }

    return {
      key: `${chatId}.${lastMessage ? lastMessage.id : 'null'}`,
      chatId,
      typingList: typingInfo[chatId],
      active: getId(openedChat) === chatId,
      chatType,
      chatColor,
      chatName,
      chatNotificationOff,
      pinned,
      language,
      members,
      chat,
      chatWidget,
      amountOnline,
      amountUnreadMsg: badge ? badge.count : 0,
      hasMention: badge ? badge.hasMention : false,
      interlocutor,
      isNotAvailable,
      employeeId,
      lastMessage,
      channelMember: findMember(chat, employeeId),
      currentUser: user,
      config: getExtraConfig({ type, config: config.chats, chat, user })
    };
  };

const isShow = (type, storeValues) => {
  if (storeValues.selectedTags.length === 0) return true;
  return storeValues.selectedTags.includes(type);
};

const generateViewModel = ({ advancedSearch, storeValues, config }) => {
  const { result } = advancedSearch;

  return {
    isSearchByNickname: isSearchByNickname(advancedSearch),
    contacts: isShow('contacts', storeValues)
      ? result.contacts.map(generateModelContactItem(storeValues, config))
      : null,
    messages: {
      list: isShow('messages', storeValues)
        ? result.messages.list.map(
            generateModelChatItem('chats', storeValues, config)
          )
        : null,
      canLoadMore: result.messages.amountLeft > 0
    },
    chats: isShow('chats', storeValues)
      ? result.chats.map(generateModelChatItem('chats', storeValues, config))
      : null,
    companies: isShow('companies', storeValues)
      ? result.companies.map(generateModelContactItem(storeValues, config))
      : null
  };
};

export const generateList = (state, props) => {
  const { config } = props;
  const advancedSearch = getAdvancedSearch(state);
  const storeValues = {
    typingInfo: getTypingInfo(state),
    openedChat: getOpenedChat(state),
    blockBadges: getBadges(state),
    online: getOnline(state),
    user: state.getIn(['user', 'user']),
    chatWidget: getChatWidget(state),
    selectedTags: getAdvancedSearch(state).params.selectedTags
  };

  return generateViewModel({ advancedSearch, storeValues, config });
};
