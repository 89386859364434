import { uploadContactsCase } from './useCases/uploadContacts';
import { applyState } from '../utils';
import { getQuery, updateQuery } from '../query';
import * as storage from './storage';
import { getChatContact } from './getters';
import { getGroupContacts } from '../categories/contacts/getters';
import * as actions from './actions';
import * as actionsContactCategories from '../categories/contacts/actions';
import { setDialogInfo } from '../dialogInfo/actions';
import { chunkUploadContactsCase } from './useCases/uploadChunkContacts';
import { requestContactQueryDataCase } from './useCases/requestContactQueryData';
import { nextPage } from '../../data-type/query';
import { updateCountInGroupContactsCase } from './useCases/updateCountInGroupContacts';
import { setCountsForGroupContactsCase } from './useCases/setCountsForGroupContacts';
import {
  hideContactWithConfirmCase,
  subscribeToDeleteContactCase,
  unSubscribeToDeleteContactCase
} from './useCases/hideContact/hideContact';
import { showModal } from '../../../../action-creators/modal';
import { hideProfile } from '../profile';
import { hideContactGeneralCase } from './useCases/hideContact/hideContactGeneral';
import { setSuccessMessage } from '../../../../action-creators/message';
import { unhideContactCase } from './useCases/hideContact/unhideContact';
import { unhideContactGeneralCase } from './useCases/hideContact/unhideContactGeneral';
import { unhideContactAndDialogCase } from './useCases/hideContact/unhideContactAndDialog';
import { getDialogInfo } from '../dialogInfo/getters';
import { updateDataAfterToggleHideCase } from './useCases/hideContact/updateDataAfterToggleHide';
import {
  scktHideContact,
  unsubToHideContact,
  unsubToUpdateContactByInvite
} from '../../../../modules/contact/storage';
// eslint-disable-next-line import/no-cycle
import {
  subscribeToDeleteContact,
  subscribeToUpdateContactByInvite
} from '../../../../modules/contact/useCases';
import {
  subscribeToUpdateContactListByInviteCase,
  unSubscribeToUpdateContactListByInviteCase
} from './useCases/updateContactsByInvite';

const getQueryUseState = (getState) => () => getQuery('contacts', getState());
const updateQueryMethod = (newQuery) => updateQuery('contacts', newQuery);

export const uploadContacts =
  (newParams = {}) =>
  async (dispatch, getState, service) =>
    uploadContactsCase(
      {
        getQuery: getQueryUseState(getState),
        updateQuery: (newQuery) => dispatch(updateQueryMethod(newQuery)),
        getContacts: async (params) =>
          storage.reqGetContacts(getState(), params),
        getGroups: async () => service.contacts.getGroups(),
        getGroupContacts: applyState(getState, getGroupContacts),
        setContacts: (contacts) => dispatch(actions.setContacts(contacts)),
        setFilteredContacts: (contacts) =>
          dispatch(actions.setFilteredContacts(contacts)),
        setGroupContacts: (groupContacts) =>
          dispatch(actionsContactCategories.setGroupContacts(groupContacts)),
        setLoading: (isLoading) =>
          dispatch(actions.setLoadingContacts(isLoading))
      },
      { newParams }
    );

export const uploadChunkContacts =
  (newParams = {}) =>
  async (dispatch, getState) =>
    chunkUploadContactsCase(
      {
        getQuery: getQueryUseState(getState),
        updateQuery: (newQuery) => dispatch(updateQueryMethod(newQuery)),
        getContacts: async (params) =>
          storage.reqGetContacts(getState(), params),
        addContacts: (contacts) => dispatch(actions.addContacts(contacts))
      },
      { newParams }
    );

export const filterContacts = (blockGroup) => async (dispatch, getState) => {
  const filteredContacts = await storage.reqGetContacts(getState(), {
    blockGroup
  });
  dispatch(actions.setFilteredContacts(filteredContacts));
  dispatch(actions.setContacts(filteredContacts));
  dispatch(actionsContactCategories.setGroupContacts(blockGroup));
};

export const loadContactsByChunks = () => async (dispatch, getState) => {
  const query = getQuery('contacts', getState());
  dispatch(uploadChunkContacts({ query: nextPage(query) }));
};

export const getContactQueryData = () => async (dispatch, getState, service) =>
  requestContactQueryDataCase({
    getGroups: async () => service.contacts.getGroups(),
    setGroupContacts: (groupContacts) =>
      dispatch(actionsContactCategories.setGroupContacts(groupContacts))
  });

export const setCountsForGroupContacts =
  (count) => async (dispatch, getState, service) =>
    setCountsForGroupContactsCase(
      {
        getGroups: async () => service.contacts.getGroups(),
        getGroupContacts: applyState(getState, getGroupContacts),
        setGroupContacts: (groupContacts) =>
          dispatch(actionsContactCategories.setGroupContacts(groupContacts))
      },
      { count }
    );

export const updateCountInGroupContacts =
  (checked, groupId) => async (dispatch, getState) =>
    updateCountInGroupContactsCase(
      {
        getGroupContacts: applyState(getState, getGroupContacts),
        setGroupContacts: (groupContacts) =>
          dispatch(actionsContactCategories.setGroupContacts(groupContacts))
      },
      { checked, groupId }
    );

export const updateDataAfterToggleHide =
  (employeeId, options = {}) =>
  async (dispatch, getState) =>
    updateDataAfterToggleHideCase(
      {
        getDialogInfo: applyState(getState, getDialogInfo),
        setDialogInfo: (props) => dispatch(setDialogInfo(props))
      },
      { isHidden: options.isHidden, employeeId }
    );

export const hideContactGeneral = (employeeId) => async (dispatch) =>
  hideContactGeneralCase(
    {
      reqHideGeneralContact: async (propsEmployeeId) =>
        storage.reqHideContactGeneral(propsEmployeeId),
      uploadContactsCase: async () => dispatch(uploadContacts()),
      setSuccessMessage: (key) => dispatch(setSuccessMessage({ key }))
    },
    { employeeId }
  );

export const unhideContactAndDialog = (employeeId) => async (dispatch) =>
  unhideContactAndDialogCase(
    {
      reqUnhideContact: async (propsEmployeeId) =>
        storage.reqUnhideContact(propsEmployeeId),
      uploadContactsCase: async () => dispatch(uploadContacts()),
      setSuccessMessage: (key) => dispatch(setSuccessMessage({ key })),
      updateDataAfterToggleHide: (propEmployeeId, propOptions) =>
        dispatch(updateDataAfterToggleHide(propEmployeeId, propOptions))
    },
    { employeeId }
  );

export const unhideContact =
  (employeeId, contactCompanyId, contactUserId) => async (dispatch) =>
    unhideContactCase(
      {
        reqUnhideContact: async (propsEmployeeId) =>
          storage.reqUnhideContact(propsEmployeeId),
        setSuccessMessage: (key) => dispatch(setSuccessMessage({ key })),
        unhideDialog: (uniqueId) => dispatch(actions.unhideDialog(uniqueId))
      },
      { employeeId, contactCompanyId, contactUserId }
    );

export const unhideContactGeneral = (employeeId) => async (dispatch) =>
  unhideContactGeneralCase(
    {
      reqUnhideGeneralContact: async (propsEmployeeId) =>
        storage.reqUnhideContactGeneral(propsEmployeeId),
      uploadContactsCase: async () => dispatch(uploadContacts()),
      setSuccessMessage: () =>
        dispatch(setSuccessMessage({ key: 'contact_was_added_to_list' }))
    },
    { employeeId }
  );

export const hideContact = (employeeId, name) => async (dispatch, getState) =>
  hideContactWithConfirmCase(
    {
      showConfirmModal: (props) =>
        dispatch(showModal('CW_DELETE_CONTACT', props)),
      reqHideContact: async (propsEmployeeId) =>
        storage.reqHideContact(propsEmployeeId),
      setSuccessMessage: (props) => dispatch(setSuccessMessage(props)),
      getChatContact: applyState(getState, getChatContact),
      setContacts: (contacts) => dispatch(actions.setContacts(contacts)),
      // eslint-disable-next-line no-shadow
      scktHideContact: (employeeId) => scktHideContact(employeeId),
      hideProfile: () => dispatch(hideProfile()),
      updateDataAfterToggleHide: (propEmployeeId, propOptions) =>
        dispatch(updateDataAfterToggleHide(propEmployeeId, propOptions))
    },
    { name, employeeId }
  );

export const subscribeToDeleteContactBlock = () => (dispatch) =>
  subscribeToDeleteContactCase({
    subToDeleteContact: () => dispatch(subscribeToDeleteContact())
  });

export const unSubscribeToDeleteContactBlock = () => () =>
  unSubscribeToDeleteContactCase({ unSubToDeleteContact: unsubToHideContact });

export const subscribeToUpdateContactListByInvite = () => (dispatch) =>
  subscribeToUpdateContactListByInviteCase({
    subToUpdateByInvite: () => dispatch(subscribeToUpdateContactByInvite())
  });

export const unSubscribeToUpdateContactListByInvite = () => () =>
  unSubscribeToUpdateContactListByInviteCase({
    unsubToUpdateByInvite: unsubToUpdateContactByInvite
  });
