import { add, getHours, getMinutes, startOfDay } from 'date-fns';
import { round } from '@root/utils';
import {
  createRequestPathTypes,
  purchaseRequestTypes
} from '../domain/entities';
import { unitTypes } from '../entities/Products';
import { validateProductCountBasedOnUnit } from '../utils';

export const initProduct = {
  name: '',
  count: '',
  unit: unitTypes.instances
};

export const getProductsWithMissingFields = (
  products,
  { requiredFields = ['name', 'count', 'unit'] } = {}
) =>
  products.reduce((acc, curr) => {
    const isInvalid = Object.entries(curr).some(
      ([key, value]) => requiredFields.includes(key) && !value
    );

    if (isInvalid) {
      acc.push(curr);
    }

    return acc;
  }, []);

export const getAuctionProductsWithMissingFields = (products) =>
  products.reduce((acc, curr) => {
    const isNotEmpty = curr.name || curr.count || curr.unit || curr.price;

    if (
      isNotEmpty &&
      (!curr.name || !curr.count || !curr.unit || !curr.price)
    ) {
      return [...acc, curr];
    }
    return acc;
  }, []);

export const getProductsWithInvalidCounts = (products) =>
  products.filter(
    (product) => !validateProductCountBasedOnUnit(product.count, product.unit)
  );

export const getValidProducts = (products) =>
  products.reduce((acc, curr, index) => {
    if (curr.name && curr.count && curr.unit) {
      return [...acc, { ...curr, id: index }];
    }
    return acc;
  }, []);

export const parseProductsFromClipboard = (clipBoardData) => {
  if (/\t|\n/.exec(clipBoardData)) {
    const paste = clipBoardData.replace(/\\r/g, '');
    const rows = paste.split('\n');

    if (rows[rows.length - 1] === '') {
      rows.pop();
    }

    return rows
      .map((row) => {
        const [name, count] = row.split('\t');
        return {
          name,
          count: (parseInt(count, 10) || '').toString(),
          unit: initProduct.unit
        };
      })
      .filter((row) => row.name || row.count);
  }
  return null;
};

export const parseEditableProductsFromClipboard = (clipBoardData) => {
  if (/\t|\n/.exec(clipBoardData)) {
    const paste = clipBoardData.replace(/\\r/g, '');
    const rows = paste.split('\n');

    if (rows[rows.length - 1] === '') {
      rows.pop();
    }

    return rows
      .map((row) => {
        const [name, price] = row.split('\t');
        return {
          name,
          price: (parseInt(price, 10) || '').toString()
        };
      })
      .filter((row) => row.name || row.price);
  }
  return null;
};

export const countSum = (products) =>
  products.reduce((acc, prev) => acc + (prev.price * prev.count || 0), 0);

export const getSumWithVat = (product) =>
  product.price || product.price === 0
    ? round(product.price * product.count, 2)
    : '';

export const getPathTypeByRequestType = (requestType) => {
  if (
    requestType === purchaseRequestTypes.regular ||
    requestType === purchaseRequestTypes.auction
  ) {
    return createRequestPathTypes.regular;
  }

  if (
    requestType === purchaseRequestTypes.regularWithConfirmationRequest ||
    requestType === purchaseRequestTypes.auctionWithConfirmation
  ) {
    return createRequestPathTypes.withConfirmationRequest;
  }
};

export const getCombinedEndDate = (endDate, endTime) => {
  if (!endDate || !endTime) return null;

  const dayStart = startOfDay(new Date(endDate));

  const hours = getHours(new Date(endTime));
  const minutes = getMinutes(new Date(endTime));

  return add(dayStart, { hours, minutes });
};

export const convertNumberToAutoRenewalText = (number) => {
  const minutes = Math.floor(number / 60000);
  const minutesStr = minutes < 10 ? `0${minutes}` : minutes;
  return `${minutesStr}`;
};

export const convertAutoRenewalTextToNumber = (text) =>
  Number(text) * 60 * 1000;

const MAX_AUTO_RENEWAL_PERIOD = 30 * 60 * 1000;

export const isAutoRenewalPeriodValid = (value) => {
  let period = value;
  if (typeof period === 'string') {
    period = convertAutoRenewalTextToNumber(value);
  }
  return period <= MAX_AUTO_RENEWAL_PERIOD;
};
