import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';
import EnclosureAttachmentMenu from '../../Chat/EnclosureAttachmentMenu';
import './filePreview.css';

import {
  PICTURE_EXT_LIST,
  showFilesViewer,
  isPicture
} from '../../../utils/filesViewerInterfaceCreator';
import * as modalActions from '../../../action-creators/modal';
import { getCurrEmplId } from '../../ChatWidget/getter';
import { convertUrl } from '../../ChatWidget/modules/files/utils';

class FilePreview extends Component {
  static convertSize(size) {
    if (size < 1e6) {
      return `${(size / 1000).toFixed(2)} KB`;
    }
    return `${(size / 1000000).toFixed(2)} MB`;
  }

  static previewSrc(file) {
    const ext = (file.extension || file.type).toLowerCase();
    if (PICTURE_EXT_LIST.includes(ext)) {
      return convertUrl(file.url);
    }
    return '/img/chat-attach-big.svg';
  }

  static previewBSize(file) {
    return isPicture(file) ? 'cover' : 'auto';
  }

  onClick = () => {
    const { file, showModal, files, currentEmployeeId, onCopyToStorage } =
      this.props;
    showFilesViewer({
      file,
      showModal,
      files,
      currentEmployeeId,
      onCopyToStorage
    });
  };

  makeList() {
    const { file, getMenu, allowDelete } = this.props;
    const baseList = getMenu(file, allowDelete);
    return baseList.map((item) => ({
      value: item.btnText,
      onClick: () => item.onClick(file)
    }));
  }

  renderMenu() {
    return (
      <EnclosureAttachmentMenu
        title={<img src="/img/3-dots-outlined.svg" alt="menu" />}
        list={this.makeList()}
      />
    );
  }

  renderCross() {
    const { onDelete } = this.props;

    return (
      <img
        role="presentation"
        alt="deleted-file"
        onClick={onDelete}
        src="/img/chat-attach-cross.svg"
        className="file-preview__btn-delete"
      />
    );
  }

  render() {
    const { file, mode, theme } = this.props;

    return (
      <div
        className={`file-preview file-preview_theme_${theme}`}
        style={{
          backgroundImage: `url('${FilePreview.previewSrc(file)}')`,
          backgroundSize: FilePreview.previewBSize(file),
          cursor: 'zoom-in'
        }}
        onClick={this.onClick}>
        <div className="file-preview__extension">
          {file.extension || file.type}
        </div>
        <div
          className="file-preview__dropdown-container"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          {mode === 'menu' && this.renderMenu()}
          {mode === 'only-delete' && this.renderCross()}
          {mode === 'disable-menu' && <div />}
        </div>

        <div className="file-preview__info">
          <div className="file-preview__name">{file.name}</div>
          <div className="file-preview__size">
            {FilePreview.convertSize(file.size)}
          </div>
        </div>
      </div>
    );
  }
}

FilePreview.propTypes = {
  file: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  getMenu: PropTypes.func,
  allowDelete: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired
};

FilePreview.defaultProps = {
  getMenu: () => []
};

const mapState = (state) => ({
  currentEmployeeId: getCurrEmplId(state)
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      showModal: modalActions.showModal
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(translate()(FilePreview));
