import React, { useEffect, useState } from 'react';
import { useLayer, useHover } from 'react-laag';
import { useTranslate } from '@hooks';
import { Typography } from '@link/react-components';
import { has } from 'ramda';
import Item from './item';
import './style.css';

const selectItem = (config, conditions) => {
  const defaultItem =
    config.items.length > 0
      ? config.items[0]
      : { icon: 'without', value: 'null' };
  const chosen = conditions[config.conditionName];
  if (!chosen) return defaultItem;
  const item = config.items.find(({ value }) => value === chosen);
  return item || defaultItem;
};

function NestedList({ t, item, onSelect, conditions, baseOptions }) {
  // We use `useHover()` to determine whether we should show the nested menu.
  // Notice how we're configuring a small delay on leave.
  const [isOpen, hoverProps, close] = useHover({
    delayEnter: 0,
    delayLeave: 100
  });

  const { renderLayer, triggerProps, layerProps } = useLayer({
    ...baseOptions, // the base-options we defined earlier
    triggerOffset: 2,
    isOpen, // tell whether the user is hovering this item

    // this is an important one: when the root-menu closes, we want all nested
    // menu's to close as well. Therefore, we can utilize this `onParentClose` props
    // to instruct `useHover` in this case to force-close possible nested menu's
    onParentClose: close
  });

  return (
    <>
      <span {...triggerProps} {...hoverProps}>
        <Item
          nested
          key={item.value}
          icon={item.icon}
          color={item.color}
          value={item.value}
          helper={item.helper}
          helperPosition={item.helperPosition}
        />
      </span>

      {isOpen &&
        renderLayer(
          <List
            t={t}
            list={item.nestedItems}
            layerProps={layerProps}
            hoverProps={hoverProps}
            isOpen={isOpen}
            close={close}
            conditions={conditions}
            onSelect={onSelect}
            baseOptions={baseOptions}
          />
        )}
    </>
  );
}

function List({
  t,
  list,
  layerProps,
  close,
  conditions,
  onSelect,
  hoverProps = {},
  baseOptions = {}
}) {
  return (
    <div
      ref={layerProps.ref}
      style={layerProps.style}
      className="cws-dropdown-menu__list open"
      {...hoverProps}>
      {list.map((item0, key) => {
        let item;
        if (has('conditionName', item0)) {
          item = selectItem(item0, conditions);
        } else if (has('separator', item0)) {
          return <div className="cws-dropdown-menu-separator" key={key} />;
        } else if (has('disabled', item0)) {
          return (
            <div className="cws-dropdown-menu-item_disable" key={key}>
              {t(`chat-widget.menu-items.${item0.value}`)}
            </div>
          );
        } else if (has('nestedItems', item0)) {
          return (
            <NestedList
              t={t}
              item={item0}
              conditions={conditions}
              onSelect={onSelect}
              baseOptions={baseOptions}
            />
          );
        } else {
          item = item0;
        }

        return (
          <Item
            key={item.value}
            icon={item.icon}
            color={item.color}
            value={item.value}
            helper={item.helper}
            helperPosition={item.helperPosition}
            selected={item.selected}
            onClick={() => {
              close();
              onSelect(item);
            }}
          />
        );
      })}
    </div>
  );
}

const CWSDropdownMenu = ({
  title,
  list,
  disabled = false,
  conditions = [],
  onSelect,
  fixedMenuPos = true,
  position = 'bottom-center',
  possiblePositions = [],
  onToggle = () => {}
}) => {
  const [isOpen, setOpen] = useState(false);
  const close = () => setOpen(false);
  const toggleOpen = () => setOpen(!isOpen);
  const t = useTranslate();

  const baseOptions = {
    placement: position,
    possiblePlacements: possiblePositions,
    auto: true,
    triggerOffset: 10,
    overflowContainer: fixedMenuPos // keep the menu positioned inside the container
  };

  const { renderLayer, triggerProps, layerProps } = useLayer({
    isOpen,
    onOutsideClick: close, // close the menu when the user clicks outside
    ...baseOptions
  });

  useEffect(() => {
    onToggle(isOpen);
  }, [isOpen]);

  return (
    <div className="cws-dropdown-menu">
      <div
        className="cws-dropdown-menu__title"
        {...triggerProps}
        onClick={() => !disabled && toggleOpen()}>
        {title(isOpen)}
      </div>
      {renderLayer(
        isOpen && (
          <Typography variant="body1Reg" Component="div">
            <List
              t={t}
              list={list}
              layerProps={layerProps}
              isOpen={isOpen}
              close={close}
              conditions={conditions}
              onSelect={onSelect}
              baseOptions={baseOptions}
            />
          </Typography>
        )
      )}
    </div>
  );
};

export default CWSDropdownMenu;
