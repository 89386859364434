import React, { useEffect, useState } from 'react';
import { Modal, Typography, Button, Loader } from '@link/react-components';
import { useActions, useTranslate } from '@hooks';
import cn from 'classnames';
import styles from './ModalResponseApprovalComments.module.css';
import * as modalActions from '../../../action-creators/modal';
import { purchaseResponseService } from '../../../api/purchaseResponseService';
import { Approved } from './components/Approved';
import { Rejected } from './components/Rejected';
import { formatDate } from '../../../utils/date';
import { getFullName } from '../../../utils/utils';

const commentStatuses = Object.freeze({
  resolve: 'resolve',
  reject: 'reject',
  pending: 'pending'
});

const CommentIcon = ({ status }) => {
  switch (status) {
    case commentStatuses.resolve:
      return <Approved />;
    case commentStatuses.reject:
      return <Rejected />;
    case commentStatuses.pending:
    default:
      console.error('this type of icon does not exist');
      return null;
  }
};

const NoComments = () => {
  const t = useTranslate();

  return (
    <Typography variant="body1Reg" className={styles.lightTextColor}>
      {t('responseApprovalLog.commentsModal.noComments')}
    </Typography>
  );
};

const Comment = ({ comment }) => (
  <div className={styles.comment}>
    <div className={styles.name}>
      <CommentIcon status={comment.status} />
      <Typography
        className={cn(styles.mainTextColor, styles.author)}
        variant="body1Med"
        noWrap>
        {getFullName(comment.user)}
      </Typography>
    </div>
    <Typography variant="body1Reg" className={styles.lightTextColor}>
      {formatDate(comment.updatedAt)}
    </Typography>
    <div
      className={cn(styles.mainTextColor, styles.text)}
      dangerouslySetInnerHTML={{ __html: comment.comment }}
    />
  </div>
);

export function ModalResponseApprovalComments({ confirmationId }) {
  const t = useTranslate();
  const { hideModalDialog } = useActions(modalActions);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadData() {
      const result = await purchaseResponseService.getConfirmationComments(
        confirmationId
      );
      setComments(result);
    }

    setLoading(true);
    loadData()
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, [confirmationId]);

  return (
    <Modal open onClose={hideModalDialog} className={styles.modal}>
      <Modal.Header>
        {t('responseApprovalLog.commentsModal.title')}
      </Modal.Header>
      <Modal.Content className={styles.content}>
        {loading && <Loader />}
        {!loading &&
          (comments.length === 0 ? (
            <NoComments />
          ) : (
            <div className={styles.comments}>
              {comments.map((comment, index) => (
                <Comment key={index} comment={comment} />
              ))}
            </div>
          ))}
      </Modal.Content>
      <Modal.Footer>
        <Modal.Actions>
          <Button mode="primary" onClick={hideModalDialog}>
            {t('responseApprovalLog.commentsModal.close')}
          </Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
}
