import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useReducer,
  useMemo
} from 'react';
import { pick, pipe } from 'ramda';
import { useSelector } from 'react-redux';
import * as storeGetters from '@root/storeGetters';

import { useActionsReducer, useTranslate } from '@hooks';
import { Input, Typeahead } from '@link/react-components';

import { LocalStorageProxy } from '@root/utils/localStorageProxy';

import { validateEmail } from '@root/utils/validateField';

import { employeeFormMapper } from './lib/mapper';

import { actions } from './model/actions';
import { initialState, reducer } from './model/reducer';

import { fields, requiredFields } from './model/constants';
import { ERRORS, ERRORS_TO_TEXT } from './lib/constants';

import { MemoizedVisibilitySelect } from '../../features/CorporateInfo/ui/VisibilitySelectControl';
import { MemoizedRoleSelect } from '../../features/CorporateInfo/ui/RoleSelect';

import styles from './EditEmployeeForm.module.css';

export const EditEmployeeForm = ({ onFormChange, initialFormState }) => {
  const t = useTranslate();

  const [state, dispatch] = useReducer(reducer, initialState);
  const { changeFiled, init } = useActionsReducer(dispatch, actions);

  const companyOwnerId = useSelector(storeGetters.getCompanyOwnerId);

  const [employeeInfo, setEmployeeInfo] = useState({});

  const storage = new LocalStorageProxy('department');
  const provider = {
    async get(query) {
      const departments = await storage.get(query).catch(() => []);
      return departments.map((dept) => ({ key: dept, value: dept }));
    }
  };

  const initialEmployeeInfo = pipe(
    pick(['email', 'department', 'position', 'visibility', 'roleId']),
    employeeFormMapper.transformToViewModel
  )(initialFormState);

  const employeeInfoRef = useRef(initialEmployeeInfo);

  const onEmployeeInfoChange = useCallback((_employeeInfo) => {
    employeeInfoRef.current = _employeeInfo;
    setEmployeeInfo(_employeeInfo);
  }, []);

  useEffect(() => {
    init(initialEmployeeInfo);
  }, []);

  useEffect(() => {
    onEmployeeInfoChange(state);
  }, [state]);

  useEffect(() => {
    pipe(
      employeeFormMapper.transformToModel,
      onFormChange
    )(employeeInfoRef.current);
  }, [employeeInfo]);

  const onInputChange = useCallback(
    (key, value) => {
      if (requiredFields.includes(key) && !value) {
        changeFiled(key, value, ERRORS.NOT_BE_EMPTY);
        return;
      }
      if (fields.email === key && !validateEmail(value)) {
        changeFiled(key, value, ERRORS.INCORRECT_EMAIL_WAS_PASSED);
        return;
      }
      changeFiled(key, value);
    },
    [changeFiled]
  );

  const additionalRoles = useMemo(
    () => ({
      admin: { id: 'admin', name: 'Администратор' }
    }),
    [t]
  );

  const additionalRolesOptions = useMemo(() => {
    const result = [];

    if (initialFormState.userId === companyOwnerId) {
      result.push(additionalRoles.admin);
    }

    return result;
  }, [companyOwnerId, initialFormState.userId, additionalRoles]);

  const role =
    initialFormState.userId !== companyOwnerId
      ? initialFormState.roleId
      : additionalRoles.admin.id;

  return (
    <div className={styles.container}>
      <div className={styles.personalInfo}>
        <Input
          onChange={(value) => onInputChange(fields.email, value)}
          value={state.email.value}
          valid={!state.email.error}
          errorText={t(ERRORS_TO_TEXT[`${state.email.error}`])}
          placeholder={t('features.personalInfo.email.placeholder')}
          label={t('features.personalInfo.email.label')}
          required
        />
      </div>
      <div className={styles.divider} />
      <div className={styles.corporateInfo}>
        <Input
          onChange={(value) => onInputChange(fields.position, value)}
          value={state.position.value}
          valid={!state.position.error}
          errorText={t(ERRORS_TO_TEXT[`${state.position.error}`])}
          placeholder={t('features.corporateInfo.position.placeholder')}
          label={t('features.corporateInfo.position.label')}
          required
        />
        <Typeahead
          provider={provider}
          onChange={(value) => onInputChange(fields.department, value)}
          placeholder={t('features.corporateInfo.department.placeholder')}
          valid={!state.department.error}
          label={t('features.corporateInfo.department.label')}
          errorText={t(ERRORS_TO_TEXT[`${state.department.error}`])}
          initialValue={state.department.value}
        />
        <div className={styles.divider} />
        <MemoizedVisibilitySelect
          visibility={initialFormState.visibility}
          setVisibility={changeFiled}
        />
        <MemoizedRoleSelect
          role={role}
          additionalOptions={additionalRolesOptions}
          setRole={changeFiled}
          disabled={initialFormState.userId === companyOwnerId}
        />
      </div>
    </div>
  );
};
