import React from 'react';
import { useTranslate } from '@hooks';
import { ButtonIcon, Tooltip } from '@link/react-components';

export const GoToIstock = ({ externalId }) => {
  const t = useTranslate();

  const handleOpen = () => {
    const redirectUrl = `${process.env.ISTOCK_INFO_SERVICE}/product/${externalId}`;
    const url = `/api/external-system/istock-info?url=${redirectUrl}`;

    window.open(url);
  };

  return (
    <Tooltip title={t('open_product_external')}>
      <ButtonIcon
        iconName="link-outside-round"
        size={18}
        onClick={handleOpen}
      />
    </Tooltip>
  );
};
