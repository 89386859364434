import { VISIBILITY } from '@root/entities';
import { ACTION_TYPES } from './constants';

export const initialState = {
  email: {
    value: '',
    error: null
  },
  position: {
    value: '',
    error: null
  },
  department: {
    value: '',
    error: null
  },
  roleId: {
    value: null,
    error: null
  },
  visibility: {
    value: VISIBILITY.HIDDEN,
    error: null
  }
};

export function reducer(state, action) {
  switch (action.type) {
    case ACTION_TYPES.INIT:
      return { ...state, ...action.payload.personalInfo };
    case ACTION_TYPES.CHANGE_FIELD:
      return {
        ...state,
        [action.payload.key]: {
          value: action.payload.value,
          error: action.payload.error
        }
      };
    default:
      return state;
  }
}
