import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import TextField from '@mui/material/TextField';
import Popper from '@mui/material/Popper';
import InputAdornment from '@mui/material/InputAdornment';
import { ClickAwayListener } from '@mui/base';
import { add, startOfDay } from 'date-fns';
import { isFunction } from '@/lib/utils';
import { Typography } from '@/components/Typography';
import { ButtonIcon } from '@/components/ButtonIcon';
import cn from 'classnames';
import { LocaleTypes } from '@/components/common.types';
import { TimePickerProps } from './TimePicker.types';
import styles from './TimePicker.module.scss';

const RU_PLACEHOLDER = 'ЧЧ:ММ';
const EN_PLACEHOLDER = 'HH:MM';

const LOCALE_PLACEHOLDERS: Record<LocaleTypes, string> = {
  ru: RU_PLACEHOLDER,
  en: EN_PLACEHOLDER
};

const newDate = new Date(2023, 4, 21);

interface CustomAdornmentProps {
  onClick: () => void;
  disabled: boolean;
}

interface TimeStampProps {
  timeStamp: Date;
  onSelectTime: (time: Date) => void;
}

function getTimeIntervals(
  initialDate: Date,
  limited: boolean,
  interval: number
) {
  const startOfTheDay = startOfDay(initialDate);
  const timeStampArray = new Array((24 * 60) / interval)
    .fill(null)
    .map((_value, index) => add(startOfTheDay, { minutes: interval * index }));
  if (!limited) {
    return timeStampArray;
  }

  return timeStampArray.filter((value) => value >= initialDate);
}

const transformToStr = (timeStamp: Date) =>
  `${
    timeStamp.getHours() < 10
      ? `0${timeStamp.getHours()}`
      : timeStamp.getHours()
  }:${
    timeStamp.getMinutes() < 10
      ? `0${timeStamp.getMinutes()}`
      : timeStamp.getMinutes()
  }`;
const convertTime = (time: Date | number | string) => {
  if (typeof time === 'number' || typeof time === 'string') {
    return new Date(time);
  }
  return time;
};
const getTimeStr = (time: Date | number | string | null) => {
  const inputTimeStamp = typeof time === 'string' ? new Date(time) : time;

  return inputTimeStamp ? transformToStr(convertTime(inputTimeStamp)) : '';
};

const timeValid = (str: string) =>
  /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(str);
const isNumber = (char: string) => /^-?\d*\.?\d+$/.test(char);

const setInitialTime = (initialTime: Date | string | number | null) =>
  initialTime ? convertTime(initialTime) : newDate;

function CustomAdornment({ onClick, disabled }: CustomAdornmentProps) {
  return (
    <InputAdornment position="end">
      <ButtonIcon
        disabled={disabled}
        iconName="alarm"
        size={16}
        variant="small"
        className={styles.alarm}
        onClick={onClick}
      />
    </InputAdornment>
  );
}

function TimeStamp({ timeStamp, onSelectTime }: TimeStampProps) {
  const handleChange = () => onSelectTime(timeStamp);
  return (
    <Typography
      variant="body1Reg"
      className={styles.timeStamp}
      onClick={handleChange}>
      {transformToStr(timeStamp)}
    </Typography>
  );
}

export function TimePicker({
  className,
  disabled = false,
  onTimeChange,
  locale = 'ru',
  initialTime = null,
  limited = false,
  interval = 30,
  valid = true
}: TimePickerProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const [timeStr, setTimeStr] = useState(getTimeStr(initialTime));
  const [error, setError] = useState(false);
  const handleOpening = useCallback(() => setOpen(true), []);
  const handleClickAway = useCallback(() => setOpen(false), []);

  const timeRef = useRef(
    getTimeIntervals(setInitialTime(initialTime), limited, interval)
  );

  useEffect(() => {
    const time = getTimeStr(initialTime);
    setTimeStr(time);
    setError(false);
  }, [initialTime]);

  useEffect(() => {
    timeRef.current = getTimeIntervals(
      setInitialTime(initialTime),
      limited,
      interval
    );
  }, [limited, initialTime, interval]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const initialValue = event.target.value;
      let updatedValue = '';
      if (initialValue.at(-1) === ':') {
        switch (initialValue.length) {
          case 1:
            updatedValue = `00${initialValue}`;
            break;
          case 2:
            updatedValue = `0${initialValue}`;
            break;
          default:
            console.error('Unknown case');
        }
      }
      const value = (updatedValue || initialValue).replace(':', '');
      if (isNumber(value) || value === '') {
        const resultedString = value.slice(0, 4);
        const resultedStrWithDivider = resultedString.replace(
          /^(\d{2})(\d)/,
          '$1:$2'
        );
        setTimeStr(resultedStrWithDivider);
        const isValid = timeValid(resultedStrWithDivider);
        const time = setInitialTime(initialTime);
        time.setHours(Math.round(+resultedString / 100), +resultedString % 100);
        setError(
          !isValid || (limited && initialTime ? initialTime > time : false)
        );
        if (isFunction(onTimeChange)) {
          onTimeChange({
            time: isValid ? time : null,
            reason: isValid ? null : 'Incorrect time'
          });
        }
      }
    },
    [initialTime, limited, onTimeChange]
  );

  const onSelectTime = useCallback(
    (newTime: Date) => {
      if (isFunction(onTimeChange)) {
        onTimeChange({ time: newTime, reason: null });
      }
      setTimeStr(transformToStr(newTime));
      setOpen(false);
      setError(false);
    },
    [onTimeChange]
  );

  const popperStyles: CSSProperties = useMemo(() => {
    if (anchorEl) {
      return {
        width: anchorEl?.offsetWidth || 0 - 34
      };
    }
    return {};
  }, [anchorEl]);

  return (
    <div
      className={cn(
        className,
        styles.timePicker,
        disabled && styles.disabled,
        (error || !valid) && styles.error
      )}
      data-testid="time-picker">
      <div ref={(node) => setAnchorEl(node)}>
        <Typography variant="body1Reg" Component="div">
          <TextField
            value={timeStr}
            onChange={handleChange}
            error={error || !valid}
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <CustomAdornment onClick={handleOpening} disabled={disabled} />
              ),
              inputProps: {
                'data-testid': 'input'
              }
            }}
            placeholder={LOCALE_PLACEHOLDERS[locale]}
          />
        </Typography>
      </div>
      {open && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper
            anchorEl={anchorEl}
            open={open}
            style={popperStyles}
            className={styles.popperIndex}>
            <div className={styles.popper}>
              <div className={styles.list}>
                {timeRef.current.map((timeStamp) => (
                  <TimeStamp
                    timeStamp={timeStamp}
                    key={Number(timeStamp)}
                    onSelectTime={onSelectTime}
                  />
                ))}
              </div>
            </div>
          </Popper>
        </ClickAwayListener>
      )}
    </div>
  );
}
