import React, { useCallback } from 'react';
import { ButtonIcon, Tooltip } from '@link/react-components';
import { useTranslate } from '@hooks';
import api from '@root/lib/request';
import { useOpenChat } from '@root/components/OpenChat/useOpenChat';

export const GoToDialog = ({
  employeeId,
  variant = 'medium',
  onOpened,
  isWidget = false,
  disabled
}) => {
  const t = useTranslate();

  const getChat = useCallback(
    () =>
      api
        .get(`/api/dialog/by-employeeId/${employeeId}`)
        .then((dialogId) => api.get(`/api/channel/${dialogId}`))
        .catch((error) => console.log(`Employees' tab error: ${error}`)),
    [employeeId]
  );

  const handleOpenChat = useOpenChat({ getChat, onOpened, isWidget });

  return (
    <Tooltip title={t('go_to_chat')}>
      <ButtonIcon
        iconName="chat"
        variant={variant}
        size={18}
        disabled={disabled}
        onClick={handleOpenChat}
      />
    </Tooltip>
  );
};
