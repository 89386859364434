import React, { memo, useCallback, useEffect } from 'react';
import { useDebounce, useTranslate } from '@hooks';
import { useMethods } from 'react-use';

import { Input, InputCounter } from '@link/react-components';
import { isEmpty, path } from 'ramda';
import {
  DENIED_REGISTRATION_REASONS,
  ERROR_REASON,
  INFO_TYPES,
  INPUTS_ERROR_REASON
} from '@root/components/BasicInformation/constants';

import { validateEmail } from '@root/utils/validateField';
import { registrationService } from '@api/registrationService';

import { useInfoBlock } from '@root/components/Modal/ModalQuickRegistration/hooks/useInfoBlock';
import { createMethods, initialState } from '../../model/actions';
import styles from './Form.module.css';
import { inputs } from '../../model/view';
import { CompanyFormInput } from '../CompanyFormInput';
import { InfoBlock } from '../InfoBlock';

export const Form = memo(({ onChangeForm }) => {
  const t = useTranslate();

  const [state, actions] = useMethods(createMethods, initialState);
  const infoBlock = useInfoBlock();

  useEffect(() => {
    onChangeForm(state);
  }, [state]);

  const onChangeFirstName = useCallback((value) => {
    if (inputs.firstName.total && value.length > inputs.firstName.total) return;

    if (value) {
      actions.changeFirstName({ value, errorReason: null });
    } else {
      const errorReason =
        path(['firstName', ERROR_REASON.REQUIRED], INPUTS_ERROR_REASON) ?? null;
      actions.changeFirstName({ value, errorReason });
    }
  }, []);

  const onChangeLastName = useCallback((value) => {
    if (inputs.lastName.total && value.length > inputs.lastName.total) return;

    if (value) {
      actions.changeLastName({ value, errorReason: null });
    } else {
      const errorReason =
        path(['lastName', ERROR_REASON.REQUIRED], INPUTS_ERROR_REASON) ?? null;
      actions.changeLastName({ value, errorReason });
    }
  }, []);

  const onChangeMiddleName = useCallback((value) => {
    if (inputs.middleName.total && value.length > inputs.middleName.total)
      return;

    if (value) {
      actions.changeMiddleName({ value, errorReason: null });
    } else {
      const errorReason =
        path(['middleName', ERROR_REASON.REQUIRED], INPUTS_ERROR_REASON) ??
        null;
      actions.changeMiddleName({ value, errorReason });
    }
  }, []);

  const validateEmailFn = useDebounce(async (email) => {
    try {
      const result = await registrationService.validateEmail(email);

      actions.changeEmail({
        errorReason: result?.reason || null,
        value: email
      });

      if (result?.reason) {
        infoBlock.add(INFO_TYPES.EMAIL);
      } else {
        infoBlock.delete(INFO_TYPES.EMAIL);
      }
    } catch (e) {
      actions.changeEmail({
        errorReason: DENIED_REGISTRATION_REASONS[e.message],
        value: email
      });

      infoBlock.add(INFO_TYPES.EMAIL);
    }
  }, 500);

  const onChangeEmail = useCallback(async (value) => {
    const isValidEmail = validateEmail(value);

    if (isValidEmail) {
      validateEmailFn(value);
    } else {
      infoBlock.delete(INFO_TYPES.EMAIL);
      actions.changeEmail({
        errorReason: value
          ? DENIED_REGISTRATION_REASONS.InvalidEmail
          : DENIED_REGISTRATION_REASONS.EmailIsRequired,
        value
      });
    }
  }, []);

  const onChangeCompany = useCallback(async (company) => {
    if (!isEmpty(company)) {
      try {
        const result = await registrationService.validateCompany(company);
        if (result.reason === DENIED_REGISTRATION_REASONS.CompanyExists) {
          actions.changeCompany({
            value: company,
            errorReason: result.reason
          });
          infoBlock.add(INFO_TYPES.COMPANY);
          return;
        }
        actions.changeCompany({
          value: company,
          errorReason: null
        });

        infoBlock.delete(INFO_TYPES.COMPANY);
      } catch (e) {
        actions.changeCompany({
          value: company,
          errorReason: e.message
        });

        infoBlock.add(INFO_TYPES.COMPANY);
      }
    } else {
      actions.changeCompany({ value: company, errorReason: null });
    }
  }, []);

  const onChangeCompanyInput = useCallback(
    (value) => {
      if (isEmpty(value)) {
        infoBlock.delete(INFO_TYPES.COMPANY);
      }
      actions.changeCompany({
        value: {},
        errorReason: DENIED_REGISTRATION_REASONS.SelectCompanyFromTheList
      });
    },
    [state]
  );

  return (
    <div className={styles.form}>
      <CompanyFormInput
        placeholder={t(inputs.company.placeholder)}
        label={t(inputs.company.label)}
        required={t(inputs.company.required)}
        errorReason={state.company.errorReason}
        setCompanyInfo={onChangeCompany}
        onChange={onChangeCompanyInput}
      />
      <Input
        onChange={onChangeEmail}
        valid={!state.email.errorReason}
        errorText={t(inputs.email.errorMessages[state.email.errorReason])}
        label={t(inputs.email.label)}
        placeholder={t(inputs.email.placeholder)}
        required={inputs.email.required}
      />

      <InputCounter
        onChange={onChangeLastName}
        valid={!state.lastName.errorReason}
        errorText={t(inputs.lastName.errorMessages[state.lastName.errorReason])}
        value={state.lastName.value}
        label={t(inputs.lastName.label)}
        placeholder={t(inputs.lastName.placeholder)}
        total={inputs.lastName.total}
        required={inputs.lastName.required}
      />

      <InputCounter
        onChange={onChangeFirstName}
        valid={!state.firstName.errorReason}
        errorText={t(
          inputs.firstName.errorMessages[state.firstName.errorReason]
        )}
        value={state.firstName.value}
        label={t(inputs.firstName.label)}
        placeholder={t(inputs.firstName.placeholder)}
        total={inputs.firstName.total}
        required={inputs.lastName.required}
      />

      <InputCounter
        onChange={onChangeMiddleName}
        value={state.middleName.value}
        label={t(inputs.middleName.label)}
        placeholder={t(inputs.middleName.placeholder)}
        total={inputs.middleName.total}
        required={inputs.middleName.required}
      />

      {infoBlock.last() && <InfoBlock infoType={infoBlock.last()} />}
    </div>
  );
});
