import { DENIED_REGISTRATION_REASONS } from '@root/components/BasicInformation/constants';

function translate(...args) {
  return `modules.quickRegistration.form.${args.join('.')}`;
}

export const INFO_TYPES = {
  EMAIL: 'EMAIL',
  COMPANY: 'COMPANY'
};

export const ERRORS = {
  [INFO_TYPES.EMAIL]: 'registeredEmail',
  [INFO_TYPES.COMPANY]: 'registeredCompany'
};

export const inputs = {
  lastName: {
    placeholder: translate('lastName', 'placeholder'),
    label: translate('lastName', 'label'),
    total: 60,
    required: true,
    errorMessages: {
      [DENIED_REGISTRATION_REASONS.SurnameIsRequired]: translate(
        'lastName',
        'error',
        'required'
      )
    }
  },
  firstName: {
    placeholder: translate('firstName', 'placeholder'),
    label: translate('firstName', 'label'),
    total: 60,
    required: true,
    errorMessages: {
      [DENIED_REGISTRATION_REASONS.NameIsRequired]: translate(
        'lastName',
        'error',
        'required'
      )
    }
  },
  middleName: {
    placeholder: translate('middleName', 'placeholder'),
    label: translate('middleName', 'label'),
    total: 60,
    required: false
  },
  company: {
    placeholder: translate('company', 'placeholder'),
    label: translate('company', 'label'),
    required: true
  },
  email: {
    placeholder: translate('email', 'placeholder'),
    label: translate('email', 'label'),
    required: true,
    errorMessages: {
      [DENIED_REGISTRATION_REASONS.InvalidEmail]: translate(
        'email',
        'error',
        'invalid'
      ),
      [DENIED_REGISTRATION_REASONS.EmailIsRequired]: translate(
        'email',
        'error',
        'required'
      )
    }
  }
};
