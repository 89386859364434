import { useCallback, useEffect, useRef } from 'react';

export const useTimer = () => {
  const timerRef = useRef();

  const clearTimer = useCallback(() => {
    clearTimeout(timerRef.current);
  }, []);

  const setTimer = useCallback(
    (cb, timeout = 0) => {
      if (timerRef.current) {
        clearTimer();
      }

      timerRef.current = setTimeout(cb, timeout);
    },
    [clearTimer]
  );

  useEffect(() => clearTimer, [clearTimer]);

  return [setTimer, clearTimer];
};
