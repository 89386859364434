import React, { Component } from 'react';
import { withTranslation as translate } from 'react-i18next';
import block from 'bem-cn-lite';

import './style.styl';

const b = block('items-on-page');

class ItemsOnPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showList: false
    };
    this.toggleList = this.toggleList.bind(this);
  }

  onClick(item) {
    this.props.onChange(item);
    this.hideList();
  }

  hideList() {
    this.setState({ showList: false });
  }

  toggleList() {
    this.setState({ showList: !this.state.showList });
  }

  render() {
    const { t, value, list, style } = this.props;

    return (
      <div className={b()} style={style}>
        <div className={b('text')}>{t('Show by')}</div>
        <div className={b('select')}>
          <div className={b('selected')} onClick={this.toggleList}>
            <div className={b('selected-text')}>{value}</div>
            <div className={b('selected-caret')} />
          </div>
          <div className={b('list', { show: this.state.showList })}>
            {list.map((item) => (
              <div
                onClick={() => this.onClick(item)}
                className={b('item')}
                key={item}>
                {item}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default translate(['ui'], { wait: true })(ItemsOnPage);
