import cn from 'classnames';
import { useId } from '@/hooks';
import { Typography } from '@/components/Typography';
import { useCallback, ChangeEvent } from 'react';
import { InputCounterProps } from './InputCounter.types';
import classes from './InputCounter.module.scss';

export function InputCounter({
  id: idOverride,
  name,
  className,
  value = '',
  onChange,
  onInputChange,
  placeholder,
  label,
  total,
  typography,
  disabled,
  valid = true,
  required = false,
  errorText
}: InputCounterProps) {
  const id = useId(idOverride);

  const onChangeCallback = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value;
      if (newValue.length <= total) {
        if (onChange) {
          onChange(newValue);
        }
        if (onInputChange) {
          onInputChange(e);
        }
      }
    },
    [onChange, total]
  );

  return (
    <div className={className} data-testid="input-counter">
      <label htmlFor={id} className={classes.label}>
        <Typography
          className={cn(
            classes.labelContent,
            Boolean(label) && classes.withLabel
          )}
          variant={typography}
          noWrap>
          {label && (
            <span className={cn({ [classes.required]: required })}>
              {label}
            </span>
          )}
          <div>{`${value ? value.length : '0'}/${total}`}</div>
        </Typography>
      </label>
      <input
        className={cn(classes.input, !valid && classes.isError)}
        id={id}
        name={name}
        value={value}
        onChange={onChangeCallback}
        placeholder={placeholder}
        disabled={disabled}
      />
      {!valid && errorText && (
        <Typography variant="caption" className={classes.errorMessage}>
          {errorText}
        </Typography>
      )}
    </div>
  );
}
