.switch {
  display: flex;
  gap: 8px;
  cursor: pointer;

  & :global(.MuiSwitch-root) {
    width: 34px;
    height: 18px;
    border-radius: 22px;
    padding: 0;

    & :global(.MuiSwitch-switchBase) {
      padding: 0;
      margin: 1px;
      transition-duration: 300ms;

      &:global(.Mui-disabled) :global(.MuiSwitch-thumb) {
        color: var(--color-gray-100);
      }

      &:global(.Mui-checked) {
        transform: translateX(16px);
        color: var(--color-white);

        & + :global(.MuiSwitch-track) {
          background-color: var(--color-blue-500);
          opacity: 1;
          border: 0;
        }

        &:global(.Mui-disabled) + :global(.MuiSwitch-track) {
          opacity: 1;
          background-color: var(--color-blue-200);
        }

        &:global(.Mui-disabled) :global(.MuiSwitch-thumb) {
          color: var(--color-blue-50);
        }
      }

      &:global(.Mui-focusVisible) :global(.MuiSwitch-thumb) {
        color: var(--color-blue-500);
        border: 6px solid var(--color-white);
        border-radius: 22px;
        box-shadow: 0 1px 2px 0 rgb(40 39 50 / 18%);
      }
    }

    & :global(.MuiSwitch-thumb) {
      box-sizing: border-box;
      width: 16px;
      height: 16px;
    }

    & :global(.MuiSwitch-track) {
      border-radius: 13px;
      background-color: var(--color-gray-700);
      opacity: 1;
    }

    & :global(.Mui-disabled) + :global(.MuiSwitch-track) {
      background-color: var(--color-gray-400);
      opacity: 1;
    }
  }
}
