.Text {
  display: block;
  margin: 0;
  font-family: var(--font);
}

.Text.inline {
  display: inline-block;
}

.Text.left {
  text-align: left;
}

.Text.right {
  text-align: right;
}

.Text.center {
  text-align: center;
}

.Text.noWrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Text-l-h1 {
  font-size: var(--h1-text-size);
}

.Text-l-h2 {
  font-size: var(--h2-text-size);
}

.Text-l-h3 {
  font-size: var(--h3-text-size);
}

.Text-lh-h1 {
  line-height: var(--h1-line-height);
}

.Text-lh-h2 {
  line-height: var(--h2-line-height);
}

.Text-lh-h3 {
  line-height: var(--h3-line-height);
}

.Text-l-1 {
  font-size: var(--main-text-size);
}

.Text-l-2 {
  font-size: var(--small-text-size);
}

.Text-l-3 {
  font-size: var(--extra-small-text-size);
}

.Text-w-1 {
  font-weight: var(--text-bold);
}

.Text-w-2 {
  font-weight: var(--text-semibold);
}

.Text-w-3 {
  font-weight: var(--text-regular);
}

.Text-lh-1 {
  line-height: var(--l1-line-height);
}

.Text-lh-2 {
  line-height: var(--l2-line-height);
}

.Text-lh-3 {
  line-height: var(--l3-line-height);
}

.Text-lh-4 {
  line-height: var(--l4-line-height);
}

.Text-lh-5 {
  line-height: var(--l5-line-height);
}
