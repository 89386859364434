import React from 'react';

export function VideoOn() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="18"
      height="19"
      viewBox="0 0 18 19">
      <path
        stroke="#5B5F73"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
        d="M17 7.089c0-.476 0-.714-.096-.824a.404.404 0 0 0-.335-.137c-.147.012-.319.18-.661.516L13 9.5l2.908 2.855c.342.337.514.505.66.517.128.01.253-.041.336-.137.096-.11.096-.348.096-.824V7.09ZM1 7.771c0-1.32 0-1.98.262-2.484a2.38 2.38 0 0 1 1.048-1.03C2.824 4 3.496 4 4.84 4h4.32c1.344 0 2.016 0 2.53.257.451.226.818.587 1.048 1.03C13 5.791 13 6.451 13 7.771v3.458c0 1.32 0 1.98-.262 2.484a2.38 2.38 0 0 1-1.048 1.03C11.176 15 10.504 15 9.16 15H4.84c-1.344 0-2.016 0-2.53-.257a2.38 2.38 0 0 1-1.048-1.03C1 13.209 1 12.549 1 11.229V7.77Z"
      />
    </svg>
  );
}
