import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { withTranslation as translate } from 'react-i18next';

import block from 'bem-cn-lite';

import { withRouter } from '../../hoc/withRouter';
import { updateWindowHeight } from '../../action-creators/browser';
import * as servicesAction from '../../action-creators/services';

import * as visualActions from '../../modules/visual/actions';
import * as visualData from '../../modules/visual/getter';

import './style/Navigation.styl';
import { getCurrentLocation } from '../../utils/location';
import SuggestionBtn from '../SuggestionBtn';

const b = block('navigation');

const nonRedirectLocations = [
  '/help',
  '/demo',
  '/profile/edit',
  '/profile/companies',
  '/billing'
];

class Navbar extends React.Component {
  static linkTo(selectedKey) {
    this.props.history.push(`/${selectedKey}`);
  }

  constructor(props) {
    super(props);
    this.state = {
      organization: true,
      suppliers: true,
      purchases: true,
      help: true
    };
    this.isActiveLink = this.isActiveLink.bind(this);
    this.getBadge = this.getBadge.bind(this);
    this.isShowBadge = this.isShowBadge.bind(this);
    this.saveMenuSections = this.saveMenuSections.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.displayBadgeTotal = this.displayBadgeTotal.bind(this);
  }

  UNSAFE_componentWillMount() {
    const menuSection = JSON.parse(localStorage.getItem('menu-sections'));

    if (!menuSection) {
      localStorage.setItem('menu-sections', JSON.stringify(this.state));
    } else {
      this.setState({
        organization: menuSection.organization,
        suppliers: menuSection.suppliers,
        purchases: menuSection.purchases,
        help: menuSection.help
      });
    }
  }

  // need for right positionig sidebar on small screens
  componentDidMount() {
    const { isStyleFull } = this.props;

    const gridContent = document.getElementById('grid-content');

    gridContent.style.width = isStyleFull
      ? 'calc(100% - 230px)'
      : 'calc(100% - 48px)';
  }

  componentDidUpdate() {
    const { isStyleFull } = this.props;

    const gridContent = document.getElementById('grid-content');

    gridContent.style.width = isStyleFull
      ? 'calc(100% - 230px)'
      : 'calc(100% - 48px)';
  }

  getBadge(name) {
    return this.props.badges.get(name) || 0;
  }

  get menu() {
    const { t, company, billingPermissions } = this.props;

    let orgLinks = [
      {
        name: t('Data about Company'),
        url: '/company/edit'
      }
    ];

    if (
      company.get('billingAllowed') &&
      company.getIn(['billing', 'tariff']) !== 'unlim' &&
      billingPermissions &&
      billingPermissions.get('read')
    ) {
      orgLinks.push({
        name: t('Payment cabinet'),
        url: '/billing'
      });
    }

    orgLinks = orgLinks.concat([
      {
        name: t('Employees'),
        url: '/employees/list'
      },
      {
        name: t('File storage'),
        url: '/file-storage/suppliers-orders'
      },
      {
        name: t('Nomenclature'),
        url: '/catalogs'
      },
      {
        name: t('Stocks'),
        url: '/stocks'
      }
    ]);

    if (company.get('type') !== 'demo') {
      orgLinks.push({
        name: t('Purchase requests'),
        url: '/requests/all',
        badgeName: 'purchaseRequestComments'
      });
    }

    const menu = [
      {
        sectionName: t('Organization'),
        section: 'organization',
        links: orgLinks
      }
    ];

    if (company.getIn(['roles', 'supplier'])) {
      menu.push({
        sectionName: t('Supplies'),
        section: 'suppliers',
        links: [
          {
            name: t('My price lists'),
            url: '/prices'
          },
          {
            name: t('Customers orders'),
            url: '/orders-customers',
            badgeName: 'ordersCustomers'
          },
          {
            name: t('Customers'),
            url: '/customers/list',
            badgeName: 'inviteCustomer'
          }
        ]
      });
    }

    if (company.getIn(['roles', 'customer'])) {
      menu.push({
        sectionName: t('Purchases'),
        section: 'purchases',
        links: [
          {
            name: t('Suppliers products'),
            url: '/prices-suppliers',
            badgeName: 'suppliersPrices'
          },
          {
            name: t('Orders to suppliers'),
            url: '/orders-company',
            badgeName: 'ordersSuppliers'
          },
          {
            name: t('Suppliers'),
            url: '/suppliers/list',
            badgeName: 'inviteSupplier'
          }
        ]
      });
    }

    menu.push({
      sectionName: t('Help'),
      section: 'help',
      links: [
        {
          name: t('Documentation'),
          url: '/help'
        },
        {
          name: t('Demo - version'),
          url: '/demo'
        },
        {
          name: t('mobile_version'),
          url: '/mobile'
        }
      ]
    });

    if (company.get('email') === process.env.ADMIN_LOGIN) {
      menu.unshift({
        alwaysShow: true,
        links: [
          {
            name: t('Organizations'),
            url: '/admin/companies'
          },
          {
            name: t('Users'),
            url: '/admin/users'
          },
          {
            name: t('featureFlagsPage.navigationLinkName'),
            url: '/admin/feature-flags'
          }
        ]
      });
    }

    return menu;
  }

  isShowBadge(name) {
    return this.getBadge(name) > 0;
  }

  isActiveLink(url) {
    let directory = this.props.path.slice(1).split('/')[0];
    if (directory === 'admin') {
      // eslint-disable-next-line no-shadow
      const section = this.props.path.slice(1).split('/')[1];
      if (section === 'companies') {
        directory = 'adminCompanies';
      } else if (section === 'users') {
        directory = 'adminUsers';
      } else if (section === 'feature-flags') {
        directory = 'featureFlags';
      }
    }
    const mainDirectories = {
      company: 'company/edit',
      billing: 'billing',
      employees: 'employees/list',
      customers: 'customers/list',
      catalogs: 'catalogs',
      stocks: 'stocks',
      requests: 'requests/all',
      'orders-customers': 'orders-customers',
      'orders-company': 'orders-company',
      'file-storage': 'file-storage/suppliers-orders',
      prices: 'prices',
      'published-prices': 'prices',
      'prices-suppliers': 'prices-suppliers',
      suppliers: 'suppliers/list',
      help: 'help',
      demo: 'demo',
      adminCompanies: 'admin/companies',
      adminUsers: 'admin/users',
      featureFlags: 'admin/feature-flags'
    };
    const activeUrl = `/${mainDirectories[directory]}`;
    return url === activeUrl;
  }

  displayBadge(name) {
    const count = this.getBadge(name);
    return count > 99 ? '99+' : count;
  }

  // eslint-disable-next-line consistent-return,no-shadow
  displayBadgeTotal(section) {
    if (section) {
      const { links } = section;
      const sumCount =
        links &&
        links
          .map((l) => l.badgeName)
          .map((name) => this.getBadge(name))
          .reduce((a, c) => a + c);
      return sumCount > 99 ? '99+' : sumCount;
    }
  }

  // eslint-disable-next-line no-shadow
  isActiveSection(section) {
    return section.links.some((i) => this.isActiveLink(i.url));
  }

  saveMenuSections() {
    localStorage.setItem('menu-sections', JSON.stringify(this.state));
  }

  // eslint-disable-next-line no-shadow
  toogleSection(section) {
    this.setState(
      (prevState) => ({ [section]: !prevState[section] }),
      () => this.saveMenuSections()
    );
  }

  // eslint-disable-next-line no-shadow
  showSection(section) {
    return this.state[section];
  }

  // eslint-disable-next-line no-shadow
  toogleSectionButton(section) {
    return (
      <img
        role="presentation"
        src={`/img/${
          this.showSection(section) ? 'arrow_up.svg' : 'arrow_down_b.svg'
        }`}
        className={b('section-header-toogler')}
        alt="toogler"
      />
    );
    // onClick={() => this.toogleSection(section)}
  }

  handleLinkClick(e, link) {
    const {
      company,
      history,
      billingPermissions,
      user,
      gaSend,
      currentCompany
    } = this.props;

    if (!currentCompany && !nonRedirectLocations.includes(link.url)) {
      e.preventDefault();
      history.push('/profile/companies');
    }
    if (
      company.get('billingAllowed') &&
      billingPermissions.get('read') &&
      company.get('billing') &&
      (!company.getIn(['billing', 'tariff']) ||
        !company.getIn(['billing', 'paid'])) &&
      company.getIn(['billing', 'tariff']) !== 'unlim' &&
      !nonRedirectLocations.includes(link.url) &&
      !link.url.includes('/file-storage')
    ) {
      e.preventDefault();
      if (getCurrentLocation() !== '/billing/control') {
        history.push('/billing/control');
      }
    }
    if (link.url === '/mobile') {
      e.preventDefault();
      const { href } = window.location;
      window.history.replaceState({ mobilePrevious: true }, 'Goodwix', href);
      // const { origin, pathname } = window.location;
      // window.location.replace(`${origin}${pathname}?view=desktop`);
      window.location.assign(
        `${
          process.env.UNICAT_DEFAULT_SERVICE_MOBILE
        }${getCurrentLocation()}?view=mobile`
      );
    }
    if (link.url === '/demo') {
      gaSend({ category: 'Common', action: 'demo', label: user.get('email') });
    }
  }

  render() {
    const { t, toggleStyle, isStyleFull } = this.props;
    const { menu } = this;

    return (
      <>
        <section className={b()} style={{ width: isStyleFull ? 230 : 48 }}>
          <nav className={b('service')}>
            <ul className={b('service-list', { collapsed: !isStyleFull })}>
              <li
                className={b('section', {
                  gray: true,
                  collapsed: !isStyleFull
                })}
                key="aside-menu-section-top"
                onClick={toggleStyle}>
                <div className={b('section-header')}>
                  <img
                    role="presentation"
                    src={`/img/main_menu${isStyleFull ? '_pale' : ''}.svg`}
                    alt="menu_switcher"
                    className={b('section-header-icon', {
                      collapsed: !isStyleFull
                    })}
                  />
                  <span
                    className={b('section-header-name', {
                      switcher: isStyleFull,
                      switcherHide: !isStyleFull
                    })}>
                    {t('toogle menu')}
                  </span>
                </div>
              </li>
              {/* eslint-disable-next-line no-shadow */}
              {menu.map((section, i) => (
                <li className={b('section')} key={`aside-menu-section-${i}`}>
                  <div
                    className={b('section-header', { collapsed: !isStyleFull })}
                    onClick={() =>
                      isStyleFull && this.toogleSection(section.section)
                    }>
                    <img
                      role="presentation"
                      src={`/img/section_${section.section}${
                        this.isActiveSection(section) ? '_active' : ''
                      }.svg`}
                      alt="submenu_icon"
                      className={b('section-header-icon')}
                    />
                    {!isStyleFull && (
                      <span
                        className={b('badge', {
                          show: this.displayBadgeTotal(section) > 0,
                          collapsed: !isStyleFull
                        })}>
                        {this.displayBadgeTotal(section)}
                      </span>
                    )}
                    {isStyleFull && (
                      <>
                        <span className={b('section-header-name')}>
                          {section.sectionName}
                        </span>
                        {!section.alwaysShow &&
                          this.toogleSectionButton(section.section)}
                      </>
                    )}
                  </div>
                  {section.links.length > 0 &&
                    (this.showSection(section.section) || section.alwaysShow) &&
                    isStyleFull && (
                      <ul className={b('list-of-links')}>
                        {section.links.map((link, j) => (
                          <li
                            className={b('item-link')}
                            key={`${i}-${j}-item-link`}>
                            <Link
                              to={link.url}
                              className={b('link', {
                                active: this.isActiveLink(link.url)
                              })}
                              onClick={(e) => this.handleLinkClick(e, link)}>
                              {link.name}
                              <span
                                className={b('badge', {
                                  show: this.isShowBadge(link.badgeName)
                                })}>
                                {this.displayBadge(link.badgeName)}
                              </span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                </li>
              ))}
            </ul>
          </nav>
        </section>
        <SuggestionBtn menuOpened={isStyleFull} />
      </>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      user: state.getIn(['user', 'user']),
      currentCompany: state.getIn(['user', 'user', 'currentCompany']),
      badges: state.get('badges'),
      company: state.get('company'),
      billingPermissions: state.getIn([
        'user',
        'user',
        'permissions',
        'billing'
      ]),
      isStyleFull: visualData.isStyleFull(state)
    }),
    (dispatch) =>
      bindActionCreators(
        {
          updateWindowHeight,
          gaSend: servicesAction.gaSend,
          toggleStyle: visualActions.toggleStyle
        },
        dispatch
      )
  )(translate(['ui'], { wait: true })(Navbar))
);
