import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';

import '../styles/modal_theme_default.styl';

import { ModalContainer } from '../elements';

import { hideModalDialog } from '../../../action-creators/modal';

import { useTranslate } from '../../../TranslateProvider';
import {
  filterCategories,
  toggleCategory
} from '../../CategoriesSelect/helper';
import SectionOffset from '../../PurchaseRequests/Request/SectionOffset';
import { getChildren, getParentsForFilteredCategories } from './helper';
import MapCategories from './MapCategories';
import SearchBlockDefault from '../../SearchBlock/SearchBlockDefault';

const ModalCategorySelect = ({
  list = [],
  selectedList = [],
  onChoose = () => {},
  nextModalHandler = () => {}
}) => {
  const t = useTranslate();
  const dispatch = useDispatch();

  const [filteredList, setFilteredList] = useState([]);
  const [filteredParentsList, setFilteredParentsList] = useState(
    getChildren(null, list)
  );
  const [isSearch, setIsSearch] = useState(false);
  const [selectedIds, setSelectedIds] = useState(selectedList);
  const [openedCategories, setOpenedCategories] = useState([]);

  const onSearch = (value = '') => {
    if (value.trim().length > 0) {
      setIsSearch(true);
      const filtered = filterCategories({ list, value, t });
      setFilteredList(filtered);
      const filteredParents = getParentsForFilteredCategories(filtered, list);
      setFilteredParentsList(filteredParents);
      setOpenedCategories(filteredParents.map((category) => category.id));
    } else {
      setFilteredParentsList(getChildren(null, list));
      setIsSearch(false);
    }
  };

  const setIsOpened = (categoryId) =>
    setOpenedCategories(
      toggleCategory({ selectedList: openedCategories, categoryId })
    );

  const onCategoryChoose = (categoryId) => () => {
    const newCategories = toggleCategory({
      selectedList: selectedIds,
      categoryId
    });
    if (newCategories.length <= 10) {
      setSelectedIds(newCategories);
    }
  };

  const onHide = () => {
    dispatch(hideModalDialog());
    nextModalHandler(selectedIds);
  };

  const onSubmit = () => {
    onChoose(selectedIds);
    onHide();
  };

  return (
    <Modal id="inviteEmployee" onHide={onHide} show>
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal.Header>
          <Modal.Title>
            {t('categories_list')}
            <span className="modal-header-cross-top" onClick={onHide} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SearchBlockDefault
            style={{ height: 34 }}
            placeholder={t('enter_category_name')}
            onSubmit={onSearch}
            onEmpty={onSearch}
            onChange={onSearch}
          />
          <SectionOffset offset={32} />

          <Modal.Title style={{ fontSize: 14 }}>
            {t('select_categories')}
          </Modal.Title>

          <SectionOffset offset={16} />
          <div style={{ maxHeight: 400, overflow: 'auto' }}>
            <MapCategories
              list={filteredParentsList}
              selectedIds={selectedIds}
              onCategoryChoose={onCategoryChoose}
              openedCategories={openedCategories}
              setIsOpened={setIsOpened}
              fullList={isSearch ? filteredList : list}
              level={0}
            />
            {isSearch && filteredList.length === 0 && (
              <span style={{ color: '#767B92' }}>{t('noResultsText')}</span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: 'flex',
            alignItems: 'center',
            background: '#F2F4F8',
            borderRadius: '0px 8px 8px 10px'
          }}>
          <span style={{ color: '#767B92' }}>{t('category_notice')}</span>
          <Button
            bsStyle="primary"
            style={{ background: 'transparent', marginLeft: 'auto' }}
            onClick={onSubmit}>
            {t('Save')}
          </Button>
        </Modal.Footer>
      </ModalContainer>
    </Modal>
  );
};

export default ModalCategorySelect;
