import React from 'react';
import styles from './CompanyLogo.module.css';

export const CompanyLogo = ({ src, id, name, handleSaveLogo }) => {
  const handleFileInput = (event) => {
    if (event.target.files) {
      const [file] = event.target.files;
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        handleSaveLogo(name, reader.result);
      };
      reader.onerror = (err) => console.log('Error', err);
    }
  };

  return (
    <div className={styles.container}>
      <label className={styles.label}>
        <input
          type="file"
          id={id}
          name={name}
          className={styles.input}
          onChange={handleFileInput}
        />
        {src && <img src={src} alt="company logo" className={styles.image} />}
      </label>
    </div>
  );
};
