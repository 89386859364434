import { useEditableTableContext } from '@/components/EditableTable/EditableTable.context';
import { ColumnHeader } from './components';

export function TableHeader() {
  const { columns } = useEditableTableContext();

  return (
    <thead>
      <tr>
        {columns.map((column) => (
          <ColumnHeader key={column.accessor} column={column} />
        ))}
      </tr>
    </thead>
  );
}
