import React, { useRef } from 'react';

import useDatepickerProps from './datepickerProps';
import DatePickHeader from './components/datepickerHeader';
import DatePickRange from './components/datepickerRange';

const PickDate = (props) => {
  const datepickerRef = useRef(null);
  const { headerProps, bodyProps } = useDatepickerProps({
    datepickerRef,
    ...props
  });

  return (
    <div className="datepicker-goodwix" ref={datepickerRef}>
      <DatePickHeader {...headerProps} />
      <DatePickRange {...bodyProps} />
    </div>
  );
};

export default PickDate;
