import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { withTranslation as translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, FormGroup, ControlLabel } from 'react-bootstrap';
import { InputText } from '../FormControls';
import TablePermissions from '../../containers/TablesNew/TablePermissions';
import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';
import * as usersAction from '../../action-creators/user';
import * as companyActions from '../../action-creators/company';

const b = block('modal-container');

class ModalShowEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      position: props.selectedEmployee.get('position') || '',
      permissions: props.selectedEmployee.get('permissions'),
      requestComplete: false
    };
    this.setPositionInCompany = this.setPositionInCompany.bind(this);
    this.onChangePermissions = this.onChangePermissions.bind(this);
  }

  getChildContext() {
    return { b };
  }

  setPositionInCompany(e) {
    this.setState({ position: e.target.value.toString() });
  }

  get permissions() {
    return this.state.permissions;
  }

  async validateNameInCompanyUnique(name) {
    const { isNameInCompanyUnique, selectedEmployee } = this.props;
    await isNameInCompanyUnique(name, selectedEmployee.get('id'));
    this.setState({ requestComplete: true });
  }

  isChangePermissions() {
    const { selectedEmployee } = this.props;
    return !selectedEmployee.get('permissions').equals(this.state.permissions);
  }

  haveChange(key) {
    return this.state[key] !== this.props.selectedEmployee.get(key);
  }

  isSaveDisabled() {
    const result = this.isChangePermissions() || this.haveChange('position');
    return !result;
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  inputProps(field) {
    return {
      onChange: (e) => this.onChange(field, e.target.value)
    };
  }

  onChangePermissions(permissions) {
    this.setState({ permissions });
  }

  render() {
    const { t, title, hideModalDialog, company, selectedEmployee } = this.props;

    return (
      <Modal id="inviteEmployee" onHide={() => hideModalDialog()} show>
        <ModalContainer mods={{ theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t(title)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup controlId="email" style={{ overflow: 'hidden' }}>
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {`${t('Employee email')}`}
              </ControlLabel>
              <br />
              {selectedEmployee.get('email')}
            </FormGroup>
            <FormGroup controlId="position" style={{ overflow: 'hidden' }}>
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {t('Position')}
              </ControlLabel>
              <InputText
                viewRead
                placeholder={t('example goodwix')}
                value={selectedEmployee.get('position')}
                onChange={this.setPositionInCompany}
                {...this.inputProps('position')}
              />
            </FormGroup>
            <h5 style={{ margin: '25px 0' }}>
              {t('Access right to sections')}
            </h5>
            <TablePermissions
              permissions={this.permissions}
              billingAllowed={company.get('billingAllowed')}
              readOnly
            />
          </Modal.Body>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalShowEmployee.propTypes = {
  t: PropTypes.func.isRequired
};

ModalShowEmployee.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    company: state.get('company'),
    userId: state.getIn(['user', 'user', 'id'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        isNameInCompanyUnique: usersAction.isNameInCompanyUnique,
        clearSelectedEmployee: companyActions.clearSelectedEmployee
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalShowEmployee));
