import React, { Component } from 'react';
import { has, isNil } from 'ramda';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { withTranslation as translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button } from 'react-bootstrap';
import { PARTICIPANT } from 'core/data/light/memberRole';
import { findMember } from 'core/data/light/channel';
import '../styles/modal_theme_default.styl';
import { ModalContainer } from '../elements';
import SearchBlockDefault from '../../SearchBlock/SearchBlockDefault';
import Row from './Row';
import * as channelUC from '../../../useCases/channel';
import * as modalActions from '../../../action-creators/modal';
import * as temporaryAction from '../../../action-creators/temporary';
import { getTempField, getChannel } from '../../../storeGetters';

const b = block('modal-container');

class ModalAddToGroupChat extends Component {
  static initSearchMatch(search) {
    const searchText = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const reg = new RegExp(searchText, 'i');

    return ({
      firstName = '',
      lastName = '',
      companyName = '',
      nickname = ''
    }) => {
      if (searchText === '') return true;

      const text = `${firstName} ${lastName} ${companyName} ${nickname}`;
      return reg.test(text);
    };
  }

  static sortUsersByLastName(users) {
    return users.sort((A, B) => {
      const { firstName: firstNameA = '', lastName: lastNameA = '' } = A;
      const { firstName: firstNameB = '', lastName: lastNameB = '' } = B;
      const nameA = `${lastNameA}${firstNameA}`;
      const nameB = `${lastNameB}${firstNameB}`;
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      search: ''
    };

    this.onCheckDialog = this.onCheckDialog.bind(this);
    this.getDialogs = this.getDialogs.bind(this);
    this.getMemberRole = this.getMemberRole.bind(this);
    this.getMemberRoleOptions = this.getMemberRoleOptions.bind(this);
    this.setSearch = this.setSearch.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.isDialogChecked = this.isDialogChecked.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onChangeMemberRole = this.onChangeMemberRole.bind(this);
    this.showModalChangeMemberRole = this.showModalChangeMemberRole.bind(this);
  }

  getChildContext() {
    return { b };
  }

  componentDidMount() {
    const {
      initialMemberIds,
      initialMemberRoles,
      resetMembersIds,
      resetMembersRoles,
      addMemberId,
      setMemberRole,
      clearMemberIds,
      clearMemberRoles
    } = this.props;

    if (resetMembersIds) {
      clearMemberIds();
    }

    if (resetMembersRoles) {
      clearMemberRoles();
    }

    if (initialMemberIds.length) {
      initialMemberIds.forEach((id) => {
        addMemberId(id);
      });
    }

    if (initialMemberRoles.length) {
      initialMemberRoles.forEach(([member, role]) => {
        setMemberRole(member, role);
      });
    }
  }

  onCheckDialog(uniqueId) {
    const { addMemberId, removeMemberId } = this.props;
    const isChecked = this.isDialogChecked(uniqueId);

    if (isChecked) {
      removeMemberId(uniqueId);
    } else {
      addMemberId(uniqueId);
    }
  }

  onSubmit() {
    const { submit, hideModalDialog, membersIds, membersRoles } = this.props;

    hideModalDialog();
    submit(membersIds, Object.entries(membersRoles));
  }

  onCancel() {
    const { cancel, hideModalDialog } = this.props;
    hideModalDialog();
    cancel();
  }

  onChangeMemberRole(employeeId, role) {
    const { setMemberRole } = this.props;
    setMemberRole(employeeId, role);
  }

  getUsers() {
    const { dialogs, members } = this.props;
    const { search } = this.state;

    const alreadyMembers = members.map((member) => ({
      ...member,
      alreadyMember: true
    }));
    const candidatesMembers = dialogs.map((member) => ({
      ...member,
      alreadyMember: false
    }));
    const allUsers = [...candidatesMembers, ...alreadyMembers];
    const searchMatch = ModalAddToGroupChat.initSearchMatch(search);
    const filteredUsers = allUsers.filter(searchMatch);
    return ModalAddToGroupChat.sortUsersByLastName(filteredUsers);
  }

  getDialogs() {
    const { t } = this.props;
    const users = this.getUsers();

    if (users.length > 0) {
      return users.map(
        ({
          firstName = '',
          lastName = '',
          companyName = null,
          avatar = null,
          employeeId,
          alreadyMember
        }) => (
          <Row
            alreadyMember={alreadyMember}
            rolesOptions={this.getMemberRoleOptions()}
            key={employeeId.toString()}
            uniqueId={employeeId}
            avatar={avatar}
            name={`${lastName} ${firstName}`}
            company={companyName}
            checked={alreadyMember ? true : this.isDialogChecked(employeeId)}
            onCheck={this.onCheckDialog}
            memberRole={this.getMemberRole(employeeId)}
            onChangeMemberRole={this.onChangeMemberRole}
            showModalChangeMemberRole={this.showModalChangeMemberRole}
          />
        )
      );
    }

    return (
      <div style={{ textAlign: 'center' }}>
        {t('chat_participants_not_found')}
      </div>
    );
  }

  getMemberRole(employeeId) {
    const { membersRoles, mainChannel } = this.props;
    if (has(employeeId, membersRoles)) {
      return membersRoles[employeeId];
    }
    const member = findMember(mainChannel, employeeId);
    if (!isNil(member)) {
      return member.memberRole;
    }
    return PARTICIPANT;
  }

  getMemberRoleOptions() {
    const {
      mode,
      purpose,
      getMemberRoleOptionsForCreate,
      getMemberRoleOptionsForEdit
    } = this.props;

    if (purpose === 'Create') {
      return getMemberRoleOptionsForCreate(mode);
    }

    if (purpose === 'Add') {
      return getMemberRoleOptionsForEdit(mode);
    }

    return [];
  }

  setSearch(search) {
    this.setState({ search });
  }

  isDialogChecked = (uniqueId) => {
    const { membersIds } = this.props;
    const isChecked = membersIds.find((item) => item === uniqueId);
    return !!isChecked;
  };

  showModalChangeMemberRole(employeeId) {
    const { showModal, mode, purpose, submit, cancel, dialogs, members } =
      this.props;
    const initialRole = this.getMemberRole(employeeId);

    const showThisModal = () => {
      showModal('ADD_TO_GROUP_CHAT', {
        mode,
        purpose,
        submit,
        btnSubmitText: 'Add',
        cancel,
        dialogs,
        members
      });
    };

    const onSubmit = (role) => {
      this.onChangeMemberRole(employeeId, role);
      showThisModal();
    };

    const onCancel = () => {
      showThisModal();
    };

    const rolesOptions = this.getMemberRoleOptions();

    showModal('EDIT_CHANNEL_USER_ROLE', {
      mode,
      initialRole,
      rolesOptions,
      onSubmit,
      onCancel
    });
  }

  clearSearch() {
    this.setState({ search: '' });
  }

  isDisabledSubmit() {
    const { mode, membersIds } = this.props;
    if (mode === 'Channel') return membersIds.length === 0;
    if (mode === 'Topic') return false;
    return false;
  }

  render() {
    const { t, btnSubmitText } = this.props;

    return (
      <Modal onHide={this.onCancel} show>
        <ModalContainer mods={{ type: 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('add_to_group_chat')}
              <span
                className="modal-header-cross pull-right"
                onClick={this.onCancel}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SearchBlockDefault
              placeholder={t('find_contacts')}
              onChange={this.setSearch}
              onEmpty={this.clearSearch}
              width={510}
            />
            <div className="modal-add-to-group-chat__container">
              {this.getDialogs()}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button id="cancel" className="pull-left" onClick={this.onCancel}>
              {t('Cancel')}
            </Button>
            <Button
              bsStyle="primary"
              disabled={this.isDisabledSubmit()}
              onClick={this.onSubmit}>
              {t(btnSubmitText)}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalAddToGroupChat.propTypes = {
  t: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func,
  showModal: PropTypes.func.isRequired,
  hideModalDialog: PropTypes.func.isRequired,
  dialogs: PropTypes.array.isRequired,
  members: PropTypes.array.isRequired,
  mode: PropTypes.string,
  purpose: PropTypes.string,
  mainChannel: PropTypes.object.isRequired,

  // Roles getters for Select options
  getMemberRoleOptionsForCreate: PropTypes.func.isRequired,
  getMemberRoleOptionsForEdit: PropTypes.func.isRequired,

  // Roles
  membersRoles: PropTypes.object.isRequired,
  setMemberRole: PropTypes.func.isRequired,
  clearMemberRoles: PropTypes.func.isRequired,
  initialMemberRoles: PropTypes.array,
  resetMembersIds: PropTypes.bool,
  // Member ids
  membersIds: PropTypes.array.isRequired,
  addMemberId: PropTypes.func.isRequired,
  removeMemberId: PropTypes.func.isRequired,
  clearMemberIds: PropTypes.func.isRequired,
  initialMemberIds: PropTypes.array,
  resetMembersRoles: PropTypes.bool,

  btnSubmitText: PropTypes.string
};

ModalAddToGroupChat.defaultProps = {
  cancel: () => {},
  resetMembersIds: false,
  resetMembersRoles: false,
  initialMemberRoles: [],
  initialMemberIds: [],
  btnSubmitText: 'Create',
  mode: 'Channel',
  purpose: 'Create'
};

ModalAddToGroupChat.childContextTypes = {
  b: PropTypes.func
};

const mapState = (state) => ({
  mainChannel: getChannel(state),
  membersRoles: getTempField(state, 'inviteMembersRoles', {}),
  membersIds: getTempField(state, 'inviteMembersIds', [])
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      showModal: modalActions.showModal,
      hideModalDialog: modalActions.hideModalDialog,
      // Roles getters for Select options
      getMemberRoleOptionsForCreate: channelUC.getMemberRoleOptionsForCreate,
      getMemberRoleOptionsForEdit: channelUC.getMemberRoleOptionsForEdit,
      // Roles
      setMemberRole: temporaryAction.setToMapTemp('inviteMembersRoles'),
      clearMemberRoles: temporaryAction.clearMapTemp('inviteMembersRoles'),
      // Member ids
      addMemberId: temporaryAction.appendToSetTemp('inviteMembersIds'),
      removeMemberId: temporaryAction.removeFromSetTemp('inviteMembersIds'),
      clearMemberIds: temporaryAction.clearSetTemp('inviteMembersIds')
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(translate()(ModalAddToGroupChat));
