import TextField, { TextFieldProps } from '@mui/material/TextField';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker as MUIDatePicker } from '@mui/x-date-pickers';
// eslint-disable-next-line import/no-duplicates
import { startOfDay } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { enUS, ru } from 'date-fns/locale';
import cn from 'classnames';
import { Typography } from '@/components/Typography';
import { Paper, PaperProps, PopperProps } from '@mui/material';
import { ButtonIcon } from '@/components/ButtonIcon';
import { Icon } from '@/components/Icon';
import { DatePickerProps, DatePickerRef } from './DatePicker.types';
import styles from './DatePicker.module.scss';
import theme from './DatePicker.theme';

const MAX_DATE = new Date('2100-12-31').getTime();
const TODAY = new Date().getTime();

const DATE_RU = 'dd.MM.yyyy';
const DATE_EN = 'MM/dd/yyyy';
const DATE_PLACEHOLDER_RU = 'дд.мм.гггг';

interface ArrowButtonProps {
  onClick: () => void;
}

function LeftArrowButton({ onClick }: ArrowButtonProps) {
  return (
    <ButtonIcon
      className={styles.arrowLeft}
      iconName="arrow-left"
      variant="small"
      size={16}
      onClick={onClick}
    />
  );
}

function RightArrowButton({ onClick }: ArrowButtonProps) {
  return (
    <ButtonIcon
      className={styles.arrowRight}
      iconName="arrow-right"
      variant="small"
      size={16}
      onClick={onClick}
    />
  );
}

function OpenPickerIcon() {
  return (
    <Icon
      className={styles.calendarIcon}
      iconName="calendar"
      height={16}
      width={16}
    />
  );
}

function PaperContent(props: PaperProps) {
  return (
    <Typography variant="body1Reg">
      <Paper className={styles.paper} {...props} />
    </Typography>
  );
}

function formatDayOfWeek(day: string) {
  return `${day.toUpperCase()}`;
}

const calendarComponents = {
  OpenPickerIcon,
  PaperContent,
  LeftArrowButton,
  RightArrowButton
};

const popperProps = { placement: 'bottom-start' } as Partial<PopperProps>;

function isValidDate(d: any) {
  return d instanceof Date && !Number.isNaN(d);
}

function getDefaultDate(date: number | null, minDate: number) {
  let selectedDate = date ?? null;
  if (minDate && selectedDate && selectedDate < minDate) {
    selectedDate = minDate;
  }
  return selectedDate;
}

export const DatePicker = forwardRef<DatePickerRef, DatePickerProps>(
  (
    {
      locale = 'ru',
      className = '',
      date = null,
      minDate = TODAY,
      onDateChange,
      disabled = false
    },
    ref
  ) => {
    const [value, setValue] = useState<number | null>(
      getDefaultDate(date, minDate)
    );

    const [active, setActive] = useState(false);

    useEffect(() => {
      if (date !== null && date !== value) {
        const currentDate = getDefaultDate(date, minDate);
        setValue(currentDate);
      }
    }, [date, minDate, value]);

    const [format, placeholder] = useMemo(() => {
      let currentFormat = '';
      let currentPlaceholder = '';

      if (locale === 'en') {
        currentFormat = DATE_EN;
        currentPlaceholder = currentFormat;
      } else {
        currentFormat = DATE_RU;
        currentPlaceholder = DATE_PLACEHOLDER_RU;
      }
      return [currentFormat, currentPlaceholder];
    }, [locale]);

    useImperativeHandle(
      ref,
      () => ({
        clearValue: () => setValue(null)
      }),
      []
    );

    const handleChange = useCallback(
      (newValue: number | null, _keyboardInputValue?: string) => {
        setValue(newValue);
        const isValid = minDate
          ? newValue === null ||
            (isValidDate(newValue) &&
              newValue >= startOfDay(minDate ?? new Date()).getTime())
          : newValue === null || isValidDate(newValue);

        if (typeof onDateChange === 'function') {
          onDateChange({
            date: isValid ? newValue : null,
            reason: isValid ? null : 'Invalid Date'
          });
        }
      },
      [onDateChange, minDate]
    );

    const handleFocus = useCallback(() => setActive(true), []);
    const handleBlur = useCallback(() => setActive(false), []);

    const renderInputCallback = useCallback(
      (props: TextFieldProps) => (
        <Typography variant="body1Reg">
          <TextField
            classes={{ root: active ? styles.active : '' }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            {...props}
            inputProps={{
              ...props.inputProps,
              placeholder
            }}
          />
        </Typography>
      ),
      [active, handleBlur, handleFocus, placeholder]
    );

    return (
      <div className={cn(className, styles.DatePicker)}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={locale === 'ru' ? ru : enUS}>
          <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
              <MUIDatePicker
                maxDate={MAX_DATE}
                minDate={minDate}
                renderInput={renderInputCallback}
                value={value}
                onChange={handleChange}
                toolbarFormat={format}
                inputFormat={format}
                dayOfWeekFormatter={formatDayOfWeek}
                components={calendarComponents}
                PopperProps={popperProps}
                reduceAnimations
                showDaysOutsideCurrentMonth
                disabled={disabled}
              />
            </StyledEngineProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </div>
    );
  }
);
