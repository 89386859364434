.row {
  position: relative;
}

.deleteRow {
  position: absolute;
  width: 16px;
  height: 100%;
  opacity: 0;
  margin-right: -18px;
}

.row:hover .deleteRow {
  opacity: 1;
}
