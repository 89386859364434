import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { withTranslation as translate } from 'react-i18next';
import Checkbox from '../../Checkbox';
import './styles.css';

const getOptions = (t, rolesOptions) =>
  rolesOptions.map((option) => ({
    ...option,
    label: t(`channel-roles-${option.value}`)
  }));

const getValue = (t, rolesOptions, memberRole) => {
  const options = getOptions(t, rolesOptions);
  return options.find(({ value }) => value === memberRole);
};

const Row = ({
  t,
  rolesOptions,
  alreadyMember,
  uniqueId,
  avatar,
  name,
  company,
  checked,
  onCheck,
  memberRole,
  onChangeMemberRole,
  showModalChangeMemberRole
}) => (
  <div
    className={`modal-add-to-group-chat-row ${
      checked ? 'modal-add-to-group-chat-row__checked' : ''
    }`}>
    <div className="modal-add-to-group-chat-row_checkbox">
      <Checkbox
        set={checked}
        onClick={() => onCheck(uniqueId)}
        readOnly={alreadyMember}
      />
    </div>
    <img
      src={avatar || '/img/user.svg'}
      alt="logo"
      className="modal-add-to-group-chat-row_logo"
    />
    <div className="modal-add-to-group-chat-row_info">
      <div className="modal-add-to-group-chat-row_info-name">{name || '-'}</div>
      <div className="modal-add-to-group-chat-row_info-company">
        {company || '-'}
      </div>
    </div>
    <div className="modal-add-to-group-chat-row_member-role">
      <ReactSelect
        value={getValue(t, rolesOptions, memberRole)}
        options={getOptions(t, rolesOptions)}
        onChange={({ value }) => onChangeMemberRole(uniqueId, value)}
        multi={false}
        searchable={false}
        clearable={false}
        disabled={alreadyMember}
        filterOptions={(options) =>
          options.filter((option) => !option.disabled)
        }
      />
    </div>
    <div className="modal-add-to-group-chat-row_ask">
      <img
        style={{ cursor: alreadyMember ? 'default' : 'pointer' }}
        src="/img/ask.svg"
        alt="ask"
        onClick={
          alreadyMember ? null : () => showModalChangeMemberRole(uniqueId)
        }
      />
    </div>
  </div>
);

Row.propTypes = {
  t: PropTypes.func.isRequired,
  alreadyMember: PropTypes.bool.isRequired,
  rolesOptions: PropTypes.array.isRequired,
  uniqueId: PropTypes.number.isRequired,
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  memberRole: PropTypes.string.isRequired,
  onChangeMemberRole: PropTypes.func.isRequired,
  showModalChangeMemberRole: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  onCheck: PropTypes.func.isRequired
};

Row.defaultProps = {
  avatar: null
};

export default translate()(Row);
