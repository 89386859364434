import React from 'react';
import { useActions } from '@hooks';
import * as useCases from '../../../../../../ChatWidget/useCases';
import EmptyChat from '../../../../../../ChatWidget/sidebar/list/chats/empty';
import ChatList from '../../../../../../ChatWidget/sidebar/list/chat-list-item';
import {
  useChatList,
  useShowMode
} from '../../../../../../ChatWidget/sidebar/list/chat-list-item/generator';

export const Chats = ({ config }) => {
  const showMode = useShowMode();
  const viewModel = useChatList({ config });
  const { handleChatList } = useActions(useCases);

  if (showMode === 'empty') return <EmptyChat />;

  return <ChatList viewModel={viewModel} handler={handleChatList} />;
};
