import io from 'socket.io-client';

export class BaseSocket {
  #io = io;

  #initializing = false;

  #instance;

  init(namespace, options) {
    if (this.#initializing || this.#instance) return;

    this.#instance = this.#io(namespace, options);
    this.#initializing = false;
  }

  emit(event, payload) {
    this.#instance.emit(event, payload);
  }

  joinToRoom(roomName) {
    this.emit('join-room', { roomName });
  }

  leaveRoom(roomName) {
    this.emit('leave-room', { roomName });
  }

  listen(event, cb) {
    this.#instance.on(event, cb);
  }

  removeListener(event, cb) {
    this.#instance.removeListener(event, cb);
  }
}
