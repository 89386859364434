import React, { memo, useCallback, useEffect, useReducer } from 'react';
import { useTranslate } from '@root/TranslateProvider';

import { useActionsReducer } from '@hooks';
import { actions } from '@root/features/PersonalInfo/model/actions';
import { Input, Typeahead } from '@link/react-components';
import { LocalStorageProxy } from '@root/utils/localStorageProxy';

import { fields, requiredFields } from '../model/constants';
import { ERRORS_TO_TEXT, ERRORS } from '../lib/constants';
import { MemoizedVisibilitySelect } from './VisibilitySelectControl';
import { MemoizedRoleSelect } from './RoleSelect';
import styles from './CorporateInfo.module.css';
import { initialState, reducer } from '../model/reducer';

export const CorporateInfo = ({ onChange }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { changeFiled } = useActionsReducer(dispatch, actions);

  const storage = new LocalStorageProxy('department');
  const provider = {
    async get(query) {
      const departments = await storage.get(query).catch(() => []);
      return departments.map((dept) => ({ key: dept, value: dept }));
    }
  };

  useEffect(() => {
    onChange(state);
  }, [state]);

  const t = useTranslate();
  const translate = (...args) => t(`features.corporateInfo.${args.join('.')}`);

  const onInputChange = useCallback(
    (key, value) => {
      if (requiredFields.includes(key) && !value) {
        changeFiled(key, value, ERRORS.NOT_BE_EMPTY);
        return;
      }

      changeFiled(key, value);
    },
    [changeFiled]
  );

  return (
    <div className={styles.corporateInfo}>
      <Input
        onChange={(value) => onInputChange(fields.position, value)}
        value={state.position.value}
        valid={!state.position.error}
        errorText={t(ERRORS_TO_TEXT[`${state.position.error}`])}
        placeholder={translate('position', 'placeholder')}
        label={translate('position', 'label')}
        required
      />
      <Typeahead
        provider={provider}
        onChange={(value) => onInputChange(fields.department, value)}
        placeholder={translate('department', 'placeholder')}
        valid={!state.department.error}
        label={translate('department', 'label')}
        errorText={t(ERRORS_TO_TEXT[`${state.department.error}`])}
        initialValue={state.department.value}
      />

      <div className={styles.divider} />

      <MemoizedVisibilitySelect
        visibility={state.visibility.value}
        setVisibility={changeFiled}
      />

      <MemoizedRoleSelect role={state.roleId.value} setRole={changeFiled} />
    </div>
  );
};

export const MemoizedCorporateInfo = memo(CorporateInfo);
