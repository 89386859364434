import React from 'react';
import { Tooltip } from '@link/react-components';
import IconHelper from './Icons/Help';
import { useTranslate } from '../TranslateProvider';

const Text = ({ text, id, textParams, children }) => {
  const t = useTranslate();

  if (!text && !children) {
    return null;
  }

  return (
    <div id={id || 'popover-positioned-left'}>
      {t(text, textParams)}
      {children}
    </div>
  );
};

const Helper = ({ text, id, textParams, children, placement = 'right' }) => (
  <Tooltip
    title={
      <Text id={id} text={text} textParams={textParams}>
        {children}
      </Text>
    }
    placement={placement}
    maxWidth={500}>
    <span style={{ height: '14px', width: '14px' }}>
      <IconHelper />
    </span>
  </Tooltip>
);

export default Helper;
