import { useEditableTableContext } from '@/components/EditableTable/EditableTable.context';
import { makeKey } from '@/components/EditableTable/utils';
import { Row } from '../Row';

export function TableBody() {
  const context = useEditableTableContext();

  return (
    <tbody>
      {/* TODO Сгенерировать статичный key вместо rowIndex */}
      {/* eslint-disable-next-line react/no-array-index-key */}
      {context.rows.map((row, rowIndex) => (
        <Row key={makeKey(rowIndex)} rowIndex={rowIndex} data={row} />
      ))}
    </tbody>
  );
}
