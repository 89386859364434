import { FormErrors, FormErrorsText } from '@root/shared';

export const ERRORS = {
  NOT_BE_EMPTY: 'NOT_BE_EMPTY',

  INCORRECT_EMAIL_WAS_PASSED: 'INCORRECT_EMAIL_WAS_PASSED'
};

export const ERRORS_TO_TEXT = {
  [ERRORS.NOT_BE_EMPTY]: FormErrorsText[FormErrors.required],
  [ERRORS.INCORRECT_EMAIL_WAS_PASSED]:
    FormErrorsText[FormErrors.wrongEmailFormat]
};
