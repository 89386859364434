import React from 'react';
import { useTranslate } from '@hooks';
import { Hint as UiHint, Button, Typography } from '@link/react-components';
import styles from './Hint.module.css';

const HintContent = ({ text, onSubmit }) => {
  const t = useTranslate();

  return (
    <div>
      <Typography variant="body1Reg" className={styles.text}>
        {t(`hints.${text}`)}
      </Typography>
      <hr className={styles.divider} />
      <div className={styles.actions}>
        <Button
          className={styles.right}
          mode="text"
          size="small"
          onClick={() => onSubmit(text)}>
          {t(`hints-actions.close`)}
        </Button>
      </div>
    </div>
  );
};

export const Hint = ({ open, text, onSubmit, className, children }) => (
  <UiHint
    Content={<HintContent text={text} onSubmit={onSubmit} />}
    open={open}
    className={className}>
    {children}
  </UiHint>
);
