import { ContentState, EditorState, Modifier, convertFromHTML } from 'draft-js';
import { DEFAULT_MAX_CHAT_INPUT_LENGTH } from '@root/constants';
import { getCountSymbols } from '../../functions';

const EDITOR_CHANGE_TYPES = Object.freeze({
  insertCharacters: 'insert-characters',
  insertFragment: 'insert-fragment'
});

function cutText(text, maxLength = DEFAULT_MAX_CHAT_INPUT_LENGTH) {
  return text.slice(0, maxLength);
}

function onPasteText(es, onChangeES, text) {
  // NOTE: need for set test with extra space and \n\n
  const content = es.getCurrentContent();
  const selection = es.getSelection();

  const newContent = Modifier.replaceText(content, selection, text);

  const newEditorState = EditorState.push(
    es,
    newContent,
    EDITOR_CHANGE_TYPES.insertCharacters
  );
  onChangeES(newEditorState);
}

function onPasteHtml(es, onChangeES, html) {
  const content = es.getCurrentContent();
  const selection = es.getSelection();

  const blocksFromHtml = convertFromHTML(html);
  const fragment = ContentState.createFromBlockArray(
    blocksFromHtml.contentBlocks,
    blocksFromHtml.entityMap
  ).getBlockMap();

  const newContent = Modifier.replaceWithFragment(content, selection, fragment);

  const newEditorState = EditorState.push(
    es,
    newContent,
    EDITOR_CHANGE_TYPES.insertFragment
  );
  onChangeES(newEditorState);
}

export const initHandlePastedText =
  ({ currentText, onChangeES }) =>
  (text, html, es) => {
    const currentInputLength = getCountSymbols(currentText);

    if (currentInputLength >= DEFAULT_MAX_CHAT_INPUT_LENGTH) return 'handled';

    if (html) {
      onPasteHtml(es, onChangeES, html);
      return 'handled';
    }

    const preparedText = cutText(
      text,
      DEFAULT_MAX_CHAT_INPUT_LENGTH - currentInputLength
    );

    onPasteText(es, onChangeES, preparedText);
    return 'handled';
  };
