import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import block from 'bem-cn-lite';
import { withTranslation as translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Col
} from 'react-bootstrap';
import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';
import * as modalActions from '../../action-creators/modal';
import * as dialogListsActions from '../../action-creators/dialogLists';
import * as dialogsActions from '../../action-creators/dialogs';

const b = block('modal-container');

class ModalCreateDialogList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: '',
      pristine: true
    };
    this.setSubmitText = this.setSubmitText.bind(this);
    this.getAsterisk = this.getAsterisk.bind(this);
    this.handleCreateDialogList = this.handleCreateDialogList.bind(this);
  }

  getChildContext() {
    return { b };
  }

  setSubmitText(e) {
    this.setState({ text: e.target.value });
  }

  handleCreateDialogList = async () => {
    const {
      createDialogList,
      loadDialogLists,
      clearUserChecked,
      clearSystemChecked,
      changeMode,
      loadDialogs,
      hideModalDialog
    } = this.props;
    const { text } = this.state;
    const name = text.trim();

    createDialogList(name)
      .then(async () => {
        await loadDialogLists();
        await clearUserChecked();
        await clearSystemChecked();
        await changeMode('all');
        await loadDialogs({ mode: 'all' });
        hideModalDialog();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  getAsterisk = () => <span style={{ color: 'red' }}>*</span>;

  isNotValid = () => !this.state.text && !this.state.pristine;

  isValidNameField() {
    return this.state.text.length < 1 || /^\s+$/.test(this.state.text);
  }

  isNameAlreadyExists() {
    const { userLists } = this.props;
    const { text } = this.state;

    const userListsNames = userLists.map((list) => list.get('name')).toJS();
    const userListsNamesLowCase = userListsNames.map((name) =>
      name.toLowerCase()
    );

    const name = text.trim();
    return userListsNamesLowCase.includes(name.toLowerCase());
  }

  render() {
    const { t, hideModalDialog } = this.props;

    return (
      <Modal show onHide={() => hideModalDialog()}>
        <ModalContainer mods={{ type: 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('createContactList')}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup
              controlId="txt"
              style={{ overflow: 'hidden' }}
              validationState={
                this.isNotValid() || this.isNameAlreadyExists() ? 'error' : null
              }>
              <ControlLabel>
                {t('Name')}
                {this.getAsterisk()}
              </ControlLabel>
              <h6 className="pull-right">
                <small>
                  {this.state.text.length}
                  /60
                </small>
              </h6>
              <FormControl
                type="text"
                placeholder=""
                value={this.state.text}
                maxLength="60"
                onChange={this.setSubmitText}
                onKeyDown={() => this.setState({ pristine: false })}
              />
              {this.isNotValid() && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t('Required fields empty')}
                </HelpBlock>
              )}
              {this.isNameAlreadyExists() && (
                <HelpBlock style={{ marginBottom: 0 }}>
                  {t('Name already exist')}
                </HelpBlock>
              )}
            </FormGroup>
            <FormGroup style={{ marginBottom: 30 }}>
              <Col
                componentClass={ControlLabel}
                className="modal-create-group-chat__text-error"
                xs={5}>
                {this.getAsterisk()}
                <span>{t('Required fields')}</span>
              </Col>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => hideModalDialog()}>
              {t('Cancel')}
            </Button>
            <Button
              bsStyle="primary"
              disabled={this.isValidNameField() || this.isNameAlreadyExists()}
              onClick={this.handleCreateDialogList}>
              {t('Create')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalCreateDialogList.propTypes = {
  t: PropTypes.func.isRequired,
  hideModalDialog: PropTypes.func.isRequired,
  createDialogList: PropTypes.func.isRequired,
  loadDialogLists: PropTypes.func.isRequired,
  clearUserChecked: PropTypes.func.isRequired,
  clearSystemChecked: PropTypes.func.isRequired,
  changeMode: PropTypes.func.isRequired,
  loadDialogs: PropTypes.func.isRequired,
  userLists: PropTypes.instanceOf(List).isRequired
};

ModalCreateDialogList.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    userLists: state.getIn(['dialogLists', 'user', 'all'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog,
        createDialogList: dialogListsActions.createDialogList,
        loadDialogLists: dialogListsActions.loadDialogLists,
        clearUserChecked: dialogListsActions.clearUserChecked,
        clearSystemChecked: dialogListsActions.clearSystemChecked,
        changeMode: dialogListsActions.changeMode,
        loadDialogs: dialogsActions.loadDialogs
      },
      dispatch
    )
)(translate()(ModalCreateDialogList));
