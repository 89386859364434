import { isEmpty, isNil, prop, assoc } from 'ramda';
import {
  haveActivatedMS,
  haveMemberStatus
} from 'core/data/light/channelMember';
import { DELETED, NOT_PARTNER } from 'core/data/light/memberStatus';
import { getInterlocutor } from 'core/data/light/dialog';
import { containsUnreadMsg, findMember } from 'core/data/light/mainChannel';
import { isMsgSystem } from 'core/data/light/message/msg-system';
import { isType } from 'core/lib';
import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import {
  getTypingInfo,
  getOpenedChat,
  getBadges,
  getActiveTab
} from '../../../getter';
import {
  getChatBlockWithWhere,
  getChatArchiveWithWhere,
  getChatArchiveList,
  getChatBlockList
} from '../../../modules/chats/getters';
import {
  countOnlineByIds,
  getOnline
} from '../../../../../modules/online/getter';
import { getId } from '../../../data-type/showElement';
import { getCurrentUser } from '../../../../../storeGetters';
import { getEmptyObject } from '../../../../../utils/utils';
import { getSelectedChatIds } from '../../../modules/categories/chats/getters';
import { isDialog } from '../../../helpers/chatType';

const findBadge = (badges, chatId) =>
  badges.find((badge) => badge.chatId === chatId);

function isExistCheckedList(chat) {
  return !isNil(chat.checkedLists);
}

function belongsToChosenGroup(chat, selectedIds) {
  return chat.checkedLists.user.some((groupId) =>
    selectedIds.includes(groupId)
  );
}

function isContacts(activeTab) {
  return activeTab === 'contacts';
}

function isArchives(activeTab) {
  return activeTab === 'archives';
}

function isChats(activeTab) {
  return activeTab === 'chats';
}

export const useShowMode = () => {
  const activeTab = useSelector(getActiveTab);
  const blockChatsList = useSelector(getChatBlockList);
  const archiveChats = useSelector(getChatArchiveList);

  const getChatList = () => {
    if (isArchives(activeTab)) return archiveChats;
    if (isChats(activeTab)) return blockChatsList;
    // Temporary fix for bug with open contacts on widget
    if (isContacts(activeTab)) return [];

    throw new Error(`Not found list for type = ${activeTab}`);
  };

  return isEmpty(getChatList()) ? 'empty' : 'list';
};

const useChatsBlock = (config) => {
  const { type } = config;
  const chats = useSelector(getChatBlockWithWhere);
  const archiveChats = useSelector(getChatArchiveWithWhere);

  return type === 'archive' ? archiveChats : chats;
};

// TODO refactor this
export const useChatList = ({ config }) => {
  const chatsBlock = useChatsBlock(config);
  const selectedChatIds = useSelector(getSelectedChatIds);
  const typingInfo = useSelector(getTypingInfo);
  const openedChat = useSelector(getOpenedChat);
  const online = useSelector(getOnline);
  const currentUser = useSelector(getCurrentUser);
  const blockBadges = useSelector(getBadges);

  const employeeId = currentUser.get('employeeId');
  const currentCompanyId = currentUser.get('currentCompany');
  const language = currentUser.get('language');

  const bySelectedGroups = (chat) =>
    isExistCheckedList(chat) && belongsToChosenGroup(chat, selectedChatIds);

  const getInterlocutorByChat = (chat) => {
    const interlocutor = getInterlocutor(employeeId, chat);

    if (isNil(interlocutor)) return getEmptyObject();

    if (
      interlocutor.department &&
      interlocutor.companyId === currentCompanyId
    ) {
      return assoc('companyName', interlocutor.department, interlocutor);
    }

    return interlocutor;
  };

  const openedChatId = getId(openedChat);

  const toViewChatItem = useCallback(
    (chat) => {
      const {
        type: chatType,
        id: chatId,
        members,
        color: chatColor,
        name: chatName,
        notificationOff: chatNotificationOff,
        lastMessage,
        pinned
      } = chat;

      const badge = findBadge(blockBadges.badges, chatId);

      const amountOnline = countOnlineByIds(
        online,
        members.filter(haveActivatedMS).map((m) => m.employeeId)
      );

      const interlocutor = getInterlocutorByChat(chat);

      let isNotAvailable = false;

      if (isDialog(chat)) {
        const isNotPartner = haveMemberStatus(NOT_PARTNER, interlocutor);
        const isDeleted = haveMemberStatus(DELETED, interlocutor);
        isNotAvailable = isNotPartner || isDeleted;
      }

      const showPinButton = !isNotAvailable && !chat.archived;

      return {
        key: chatId,
        isOwner: employeeId === chat.ownerEmployeeId,
        chatId,
        typingList: typingInfo[chatId],
        active: openedChatId === chatId,
        chatType,
        chatColor,
        chatName,
        chatNotificationOff,
        pinned,
        language,
        members,
        chat,
        amountOnline,
        amountUnreadMsg: badge ? badge.count : 0,
        hasMention: badge ? badge.hasMention : false,
        currentUser,
        interlocutor,
        isNotAvailable,
        employeeId,
        lastMessage,
        containsUnreadMsg:
          !isNil(lastMessage) &&
          containsUnreadMsg(chat, prop('id', lastMessage)),
        channelMember: getChannelMember(),
        config,
        showPinButton
      };

      function getChannelMember() {
        if (!isMsgSystem(lastMessage)) return findMember(chat, employeeId);
        if (
          isType('MsgCreatedChannel', lastMessage) ||
          isType('MsgLeftFromChannel', lastMessage) ||
          isType('MsgRenamedChannel', lastMessage) ||
          isType('MsgRegainedStatus', lastMessage)
        ) {
          return findMember({ members }, lastMessage.employeeId);
        }
        if (
          isType('MsgAddedToChannel', lastMessage) ||
          isType('MsgExcludedFromChannel', lastMessage)
        ) {
          return findMember({ members }, lastMessage.whoEmployeeId);
        }
        if (
          isType('MsgSubscribeToChannel') ||
          isType('MsgUpdateSubscribeToChannel') ||
          isType('MsgUnsubscribeFromChannel')
        ) {
          return getEmptyObject();
        }

        throw new Error(
          `Not found handler for message with type=${lastMessage.type}`
        );
      }
    },
    [openedChatId, typingInfo, blockBadges, language]
  );

  const list = useMemo(() => {
    let result = chatsBlock.list;

    if (!isEmpty(selectedChatIds)) {
      result = result.filter(bySelectedGroups);
    }

    return result.map(toViewChatItem);
  }, [selectedChatIds, chatsBlock.list, toViewChatItem]);

  return {
    list,
    size: chatsBlock.size
  };
};
