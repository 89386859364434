import React, { Component } from 'react';
import { withTranslation as translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Col,
  Form,
  FormGroup,
  FormControl,
  Button
} from 'react-bootstrap';
import { ModalContainer } from './elements';

import * as modalActions from '../../action-creators/modal';

class ModalAdminConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: ''
    };
  }

  render() {
    const { t, action, hideModalDialog, onSuccess, attrs } = this.props;

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="adminConfirm" onHide={() => hideModalDialog()} show>
          <Modal.Header>
            <Modal.Title>
              {t('Confirm action')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              horizontal
              style={{
                whiteSpace: 'nowrap'
              }}>
              <FormGroup controlId="value" style={{ marginBottom: 0 }}>
                <div>{t('modal_admin_message_confirm')}</div>
                <Col sm={12} className="form-modal-input-col">
                  <FormControl
                    type="password"
                    maxLength={255}
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                      }
                    }}
                  />
                </Col>
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button
                id="cancel"
                className="pull-left"
                onClick={() => hideModalDialog()}>
                {t('Cancel')}
              </Button>
              <Button
                id="saveButton"
                bsStyle="primary"
                disabled={this.state.password.length === 0}
                onClick={() => {
                  action(
                    Object.assign(attrs, { password: this.state.password }),
                    onSuccess
                  );
                  hideModalDialog();
                }}>
                {t('Confirm')}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

export default connect(
  (state) => ({
    company: state.get('company')
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalAdminConfirm));
