import React from 'react';
import cn from 'classnames';
import { Typography } from '@link/react-components';
import styles from './Rank.module.css';

const MAX_RANG = 99;

function getClassName(rank) {
  if (!rank) {
    return styles.unknownPlace;
  }

  switch (rank) {
    case 1:
      return styles.firstPlace;
    case 2:
      return styles.secondPlace;
    default:
      return styles.anotherPlace;
  }
}

export const Rank = ({ className: propClassName, value: propValue, limit }) => {
  const value = (() => (propValue > MAX_RANG ? '99+' : propValue))();

  const className = getClassName(value);

  if (limit && value > limit) return null;

  return (
    <Typography
      className={cn(styles.rank, className, propClassName)}
      variant="body1Med">
      {value ?? '-'}
    </Typography>
  );
};
