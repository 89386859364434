import React from 'react';
import { withTranslation as translate } from 'react-i18next';

const EmptyChat = ({ t }) => (
  <div className="cws-chat-empty">
    <img
      className="cws-chat-empty__icon"
      src="/img/chat-widget/message.svg"
      alt="empty chat"
    />
    <div className="cws-chat-empty__text">
      {t('chat-widget.chat.empty-message')}
    </div>
  </div>
);

export default translate()(EmptyChat);
