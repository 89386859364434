import createMentionPlugin from '@draft-js-plugins/mention';
import createEmojiMartPlugin from 'draft-js-emoji-mart-plugin';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import data from 'emoji-mart/data/apple.json';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import { positionSuggestions } from './utils';
import mentionStyle from './MentionSuggestions/mention-style.module.css';
import toolbarStyles from './Toolbar/style.module.css';
import buttonStyles from './Toolbar/button.module.css';
import { createSuggestionsEmojiPlugin } from './SuggestionsEmojiPlugin';

function initMentionSuggestionsPlugin() {
  return createMentionPlugin({
    entityMutability: 'IMMUTABLE',
    positionSuggestions,
    theme: mentionStyle,
    supportWhitespace: true
  });
}

function initEmojiPlugin() {
  return createEmojiMartPlugin({
    data,
    set: 'apple'
  });
}

function initEmojiSuggestionsPlugin() {
  return createSuggestionsEmojiPlugin();
}

function initLinkifyPlugin() {
  return createLinkifyPlugin();
}

function initToolbar() {
  return createToolbarPlugin({
    theme: { buttonStyles, toolbarStyles }
  });
}

export function initPlugins() {
  const mentionPlugin = initMentionSuggestionsPlugin();
  const { MentionSuggestions: MS } = mentionPlugin;

  const emojiSuggestionsPlugin = initEmojiSuggestionsPlugin();
  const { EmojiSuggestions: ES } = emojiSuggestionsPlugin;

  const linkifyPlugin = initLinkifyPlugin();

  const emojiPlugin = initEmojiPlugin();
  const { Picker } = emojiPlugin;

  const staticToolbarPlugin = initToolbar();
  const { Toolbar } = staticToolbarPlugin;

  const plgns = [
    mentionPlugin,
    emojiSuggestionsPlugin,
    emojiPlugin,
    linkifyPlugin,
    staticToolbarPlugin
  ];

  return {
    plugins: plgns,
    MentionSuggestions: MS,
    EmojiSuggestions: ES,
    EmojiPicker: Picker,
    Toolbar
  };
}
