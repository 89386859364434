import { purchaseRequestService } from '@api';
import { requestCategories } from '@root/storage/purchaseRequest.storage';
import {
  show,
  hide,
  setRequest,
  setCategories,
  emptyRequest,
  emptyCategories
} from './redux-storage';
import customHistory from '../../../../customHistory';
import { getRequestId } from './data-type';

export const openResponse = (message) => async (dispatch) => {
  const cats = await requestCategories();
  dispatch(setCategories(cats));

  const request = await purchaseRequestService.getRequest(message.requestId);
  dispatch(setRequest(request));

  dispatch(show());
};

export const closeResponse = () => (dispatch) => {
  dispatch(hide());
  dispatch(emptyRequest());
  dispatch(emptyCategories());
};

export const goToRequest = () => async (dispatch, getState) => {
  customHistory.push(`/requests/all/${getRequestId(getState())}`);
  dispatch(closeResponse());
};
