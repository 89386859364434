import { useCallback } from 'react';
import { useActions } from '@hooks';
import { toast } from '@root/modules/toast';
import { filesLoadHandler } from '../useCases/files';
import * as storageActions from '../action-creators/storage';
import { noop } from '../utils/utils';

export function useAttachFiles({
  attachedFiles = [],
  maxFiles,
  onLoadFile = noop
}) {
  const { uploadFile } = useActions(storageActions);

  return useCallback(
    async (files) => {
      await filesLoadHandler({
        files: Array.from(files).map((rawFile) => ({ file: rawFile })),
        attachedFiles,
        maxFiles,
        loadFile: async ({ file }) => {
          const uploadedFile = await uploadFile(file);
          if (uploadedFile) {
            onLoadFile(uploadedFile);
          } else {
            throw new Error('Error while uploading files');
          }
        },
        beforeLoading: () => {
          toast.system('File upload started', {
            key: 'upload-files-processing'
          });
        },
        onError: (key, params) => {
          toast.system('', {
            key: 'upload-files-failed',
            data: { key, params }
          });
        }
      });
    },
    [uploadFile, attachedFiles, maxFiles, onLoadFile]
  );
}
