import request from '@root/lib/request';

class AuctionService {
  #baseUrl = '/api/auction';

  /**
   * Get auction by auctionId (requestId)
   * @param auctionId
   * @return {Promise<Object>}
   */
  async getAuctionById(auctionId) {
    return request.get(`${this.#baseUrl}/${auctionId}`);
  }

  /**
   * Save new bid
   * @param auctionId
   * @param {Object} payload
   * @param {number} payload.bid - new bid
   * @param {number} payload.productId - index of target product
   * @return {Promise<void|*>}
   */
  async saveBid(auctionId, payload) {
    return request.patch(`${this.#baseUrl}/${auctionId}/bid`, payload);
  }
}

export const auctionService = new AuctionService();
