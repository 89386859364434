import { useEffect } from 'react';
import { handle } from 'core/data/messageBus/frontend';
import { rejectedJoinRequest, videoCallQRY } from '../commandsAndEvents';

export const EventKeys = Object.freeze({
  AllowedJoin: videoCallQRY.set,
  RejectedJoin: rejectedJoinRequest
});

export const useSubscribeVideoChatEvent = (event, cb, deps = []) => {
  useEffect(() => handle(event, cb), deps);
};
