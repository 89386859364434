import React, { useCallback, useState } from 'react';
import { useAsync, useTranslate } from '@hooks';
import { commissionService, hintService } from '@api';
import {
  Modal,
  Loader,
  Typography,
  Button,
  Checkbox
} from '@link/react-components';
import { CommissionInfoTable } from '@root/components/CommissionInfoTable';
import { ResponseHints } from '@root/components/PurchaseRequests/constants';
import { MODAL_KEY, TRANSLATE_KEY } from './constants';
import { useProposalCommissionInfoModal } from './useProposalCommissionInfoModal';
import styles from './modalProposalCommissionInfo.module.css';

function ModalProposalCommissionInfo({ onSubmit }) {
  const t = useTranslate();
  const [, hideModal] = useProposalCommissionInfoModal();
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  const {
    loading,
    error,
    value: commission
  } = useAsync(async () => commissionService.getCommission(), []);

  const handleSelect = useCallback(() => {
    setDoNotShowAgain((prev) => !prev);
  }, []);

  const handleSubmit = useCallback(() => {
    if (doNotShowAgain) {
      hintService
        .view({ hintName: ResponseHints.supplierCommissionInfo })
        .catch(console.error);
    }
    onSubmit();
    hideModal();
  }, [doNotShowAgain, onSubmit]);

  const translate = (key) => t(`${TRANSLATE_KEY}.${key}`);

  const render = () => {
    if (loading) {
      return <Loader />;
    }
    if (error) {
      console.error(error);
      return <Loader />;
    }

    return <CommissionInfoTable commission={commission} />;
  };

  return (
    <Modal open onClose={hideModal} className={styles.modal}>
      <Modal.Header>{translate(`title`)}</Modal.Header>
      <Modal.Content className={styles.content}>
        <Typography variant="body1Reg">
          <p>{translate(`subTitle`)}</p>
          <p>{translate(`description`)}</p>
        </Typography>
        {render()}
      </Modal.Content>
      <Modal.Footer align="space-between">
        <Checkbox
          label={translate('actions.doNotShowAgain')}
          onChange={handleSelect}
          checked={doNotShowAgain}
        />
        <Modal.Actions>
          <Button mode="text" onClick={hideModal}>
            {translate('actions.cancel')}
          </Button>
          <Button onClick={handleSubmit}>{translate('actions.submit')}</Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
}

export const initProposalCommissionInfoModal = {
  [MODAL_KEY]: ModalProposalCommissionInfo
};
