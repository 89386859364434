import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation as translate } from 'react-i18next';

const BlockedContact = ({ t, unblock }) => (
  <div
    className="chat-not-my-contact-notify-container"
    style={{ display: 'block' }}>
    <span>{t('chat.blocked_contact')}</span>
    <button
      type="button"
      className="text-btn-primary"
      style={{ padding: 0 }}
      onClick={() => unblock()}>
      {t('Unblock')}
    </button>
  </div>
);

BlockedContact.propTypes = {
  t: PropTypes.func.isRequired,
  unblock: PropTypes.func.isRequired
};

export default translate()(BlockedContact);
