import React from 'react';
import { useTranslate } from '@hooks';
import { Typography, Tooltip } from '@link/react-components';
import cn from 'classnames';
import { priceRankThreshold } from '../../constants';
import styles from './priceRank.module.css';

const getRankValue = (priceRank) => {
  switch (priceRank) {
    case priceRankThreshold.good:
      return '<5%';
    case priceRankThreshold.pricy:
      return '5-15%';
    case priceRankThreshold.overpriced:
      return '>15%';
    default:
      return null;
  }
};
export function PriceRank({ priceRank, disabled }) {
  const t = useTranslate();

  return (
    <Tooltip
      title={t(`modules.purchaseProductsTable.tooltips.priceRank.${priceRank}`)}
      maxWidth={200}>
      <Typography
        noWrap
        variant="body2Med"
        className={cn(styles[`${priceRank}`], { [styles.disabled]: disabled })}>
        {getRankValue(priceRank)}
      </Typography>
    </Tooltip>
  );
}
