import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SinglePictureAttach from '../../Chat/SinglePictureAttach';
import Files from '../file/Files';
import * as modalActions from '../../../action-creators/modal';
import * as messageActions from '../../../action-creators/message';
import { getCurrEmplId } from '../../ChatWidget/getter';
import {
  convertUrl,
  convertUrlToFile
} from '../../ChatWidget/modules/files/utils';

const ALLOWED_IMAGE_EXT = [
  'jpg',
  'jpeg',
  'png',
  'bmp',
  'JPG',
  'JPEG',
  'PNG',
  'BMP',
  'WEBP',
  'webp'
];

const Attachments = ({
  getAvatar = () => {},
  messageId,
  allowDelete,
  denyCopyToStorage = false,
  files = [],
  getMenu,
  mode,
  onDelete,
  onCopyToStorage,
  showModal,
  setSuccessMessage,
  showSingleImage,
  onLoadSingleImage,
  members,
  currEmployeeId,
  theme,
  filesData
}) => {
  const getSenderName = (employeeId) => {
    const sender = members.find((member) => member.employeeId === employeeId);

    return sender ? sender.userName : '';
  };

  const allowCopyToStorage = (employeeId) =>
    !denyCopyToStorage && employeeId !== currEmployeeId;

  // Picture Viewer Interface Creator
  // ---------------------------------------------------------------------------------------------------------------
  const showImageViewer = (fileId) => {
    const images = files
      .filter(({ extension, type }) =>
        ALLOWED_IMAGE_EXT.includes(extension || type)
      )
      .map((image) => ({
        id: image.id,
        src: convertUrlToFile(image.originalUrl || image.url),
        name: image.name,
        type: image.extension,
        avatar: getAvatar(image),
        addedAt: image.addedAt,
        employeeId: image.employeeId,
        senderName: getSenderName(image.employeeId),
        addToStorageEnable: allowCopyToStorage(image.employeeId)
      }));

    const onCopyToStorageImage = async (imageIndex) => {
      if (imageIndex !== -1) {
        const image = images[imageIndex];
        const { id } = image;
        await onCopyToStorage(id);
      }
    };

    const onDeleteImage = async (imageIndex) => {
      if (imageIndex !== -1) {
        const image = images[imageIndex];
        const { id } = image;
        const file = files.find((f) => f.id === id);
        await onDelete(messageId, file);
        await setSuccessMessage({ key: 'attach_delete_success' });
      }
    };

    const imageIndex = images.findIndex((image) => image.id === fileId);

    showModal('IMAGE_VIEWER', {
      images,
      imageIndex,
      isDeleteEnabled: allowDelete,
      onDeleteImage,
      onCopyToStorageImage,
      file: files.find((f) => f.id === fileId)
    });
  };

  // PDF Viewer Interface Creator
  // ---------------------------------------------------------------------------------------------------------------
  const showPDFViewer = (fileId) => {
    // Get PDFs from files
    const PDFs = files
      .filter(({ extension, type }) => (extension || type) === 'pdf')
      .map((pdf) => ({
        id: pdf.id,
        src: pdf.url,
        url: pdf.url,
        name: pdf.name,
        addedAt: pdf.addedAt,
        employeeId: pdf.employeeId,
        extension: pdf.extension || pdf.type
      }));

    // Find clicked PDF in all PDFs
    const PDF = PDFs.find((pdf) => pdf.id === fileId);

    if (PDF) {
      const file = files.find((f) => f.id === PDF.id);

      const onCopyToStorageDocument = async () => {
        await onCopyToStorage(PDF.id);
      };

      const onDeleteDocument = async () => {
        await onDelete(messageId, file);
        await setSuccessMessage({ key: 'attach_delete_success' });
      };

      const { src, name, addedAt, employeeId } = PDF;

      showModal('PDF_VIEWER', {
        avatar: getAvatar(file),
        src,
        name,
        addedAt,
        senderName: getSenderName(employeeId),
        isCopyToStorageEnable: allowCopyToStorage(employeeId),
        onCopyToStorageDocument,
        isDeleteEnabled: allowDelete,
        onDeleteDocument,
        file: PDF
      });
    }
  };

  // DOCX Viewer Interface Creator
  // ---------------------------------------------------------------------------------------------------------------
  const showDocxViewer = (fileId) => {
    // Get PDFs from files
    const DOCXs = files
      .filter(({ extension, type }) => (extension || type) === 'docx')
      .map((docx) => ({
        id: docx.id,
        src: docx.url,
        url: docx.url,
        name: docx.name,
        addedAt: docx.addedAt,
        employeeId: docx.employeeId,
        extension: docx.extension || docx.type
      }));

    // Find clicked DOCX in all PDFs
    const DOCX = DOCXs.find((docx) => docx.id === fileId);

    if (DOCX) {
      const file = files.find((f) => f.id === DOCX.id);

      const onCopyToStorageDocument = async () => {
        await onCopyToStorage(DOCX.id);
      };

      const onDeleteDocument = async () => {
        await onDelete(messageId, file);
        await setSuccessMessage({ key: 'attach_delete_success' });
      };

      const { src, name, addedAt, employeeId } = DOCX;

      showModal('DOCX_VIEWER', {
        avatar: getAvatar(file),
        src,
        name,
        addedAt,
        file: DOCX,
        senderName: getSenderName(employeeId),
        isCopyToStorageEnable: allowCopyToStorage(employeeId),
        onCopyToStorageDocument,
        isDeleteEnabled: allowDelete,
        onDeleteDocument
      });
    }
  };

  // Viewer Interface Creator for other files
  // ---------------------------------------------------------------------------------------------------------------
  const showOtherFileViewer = (fileId) => {
    // Find clicked file in all files
    const currFile = files.find((file) => file.id === fileId);

    const onCopyToStorageFile = async () => {
      await onCopyToStorage(currFile.id);
    };

    const onDeleteFile = async () => {
      await onDelete(messageId, currFile);
      await setSuccessMessage({ key: 'attach_delete_success' });
    };

    showModal('OTHER_VIEWER', {
      file: { ...currFile, senderName: getSenderName(currFile.employeeId) },
      isDeleteEnabled: allowDelete,
      onDeleteFile,
      isAddToStorageEnable: allowCopyToStorage(currFile.employeeId),
      onCopyToStorageFile
    });
  };

  // Render
  // ---------------------------------------------------------------------------------------------------------------

  if (
    showSingleImage &&
    files.length === 1 &&
    ALLOWED_IMAGE_EXT.includes(files[0].extension || files[0].type)
  ) {
    const [image] = files;
    const { id, url, name } = image;

    const menu = getMenu(image, allowDelete);

    const deleteItem = menu.find((item) => item.btnText === 'Delete');
    const downloadItem = menu.find((item) => item.btnText === 'Download');
    const addToStorageItem = menu.find(
      (item) => item.btnText === 'Add_to_storage'
    );

    const menuProps = {
      isShowDelete: !!deleteItem,
      isShowDownload: !!downloadItem,
      isShowAddToStorage: !!addToStorageItem,
      onDelete: deleteItem ? () => deleteItem.onClick(image) : () => {},
      onDownload: downloadItem ? () => downloadItem.onClick(image) : () => {},
      onAddToStorage: addToStorageItem
        ? () => addToStorageItem.onClick(image)
        : () => {}
    };

    return (
      <SinglePictureAttach
        id={id}
        src={convertUrl(url)}
        name={name}
        showImageViewer={showImageViewer}
        menuProps={menuProps}
        onLoad={onLoadSingleImage}
        disableMenu={mode === 'disable-menu'}
        offset={10}
        filesData={filesData}
      />
    );
  }

  return (
    <Files
      files={files}
      getMenu={getMenu}
      allowDelete={allowDelete}
      mode={mode}
      onDelete={(file) => onDelete(messageId, file)}
      showImageViewer={showImageViewer}
      showPDFViewer={showPDFViewer}
      showDocxViewer={showDocxViewer}
      showOtherFilesViewer={showOtherFileViewer}
      onCopyToStorage={onCopyToStorage}
      theme={theme}
    />
  );
};

Attachments.propTypes = {
  getMenu: PropTypes.func,
  messageId: PropTypes.string,
  showSingleImage: PropTypes.bool,
  onLoadSingleImage: PropTypes.func,
  files: PropTypes.array.isRequired,
  allowDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  onCopyToStorage: PropTypes.func,
  showModal: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  mode: PropTypes.string,
  members: PropTypes.array,
  currEmployeeId: PropTypes.number.isRequired
};

Attachments.defaultProps = {
  getMenu: () => [],
  mode: 'only-delete',
  allowDelete: false,
  messageId: null,
  onDelete: () => console.error('not pass onDelete'),
  onCopyToStorage: () => console.error('not pass onCopyToStorage'),
  onLoadSingleImage: () => {},
  showSingleImage: false,
  members: []
};

const mapState = (state) => ({
  currEmployeeId: getCurrEmplId(state)
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      showModal: modalActions.showModal,
      setSuccessMessage: messageActions.setSuccessMessage
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(Attachments);
