import React from 'react';
import { withTranslation as translate } from 'react-i18next';
import okSvg from './ok';

import './style.css';

const StatusMessage = ({ t, text, color = 'gray', icon }) => (
  <div className="status-message__container">
    <div className="status-message__round-container">
      {icon === 'ok' ? okSvg() : <div className={`round-${color}`} />}
    </div>
    <span>{t(text)}</span>
  </div>
);

export default translate(['ui'], { wait: true })(StatusMessage);
