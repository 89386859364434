import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTranslate } from '@hooks';
import { useInviteEmployeeModal } from '@root/components/Modal';
import { gaSend } from '@root/action-creators/services';
import { usePermissions } from '@root/modules/permissions';
import * as modalActions from '@root/action-creators/modal';
import * as serviceActions from '@root/action-creators/services';
import * as storeGetters from '@root/storeGetters';
import './style-for-empty.css';

const ContactsEmpty = ({ showModal, email, isCompanyOwner = false }) => {
  const t = useTranslate();
  const [openInviteEmployeeModal] = useInviteEmployeeModal();
  const permissions = usePermissions();

  const showModalAddEmployee = () => {
    gaSend({
      category: 'Messenger',
      action: 'chat_add_employee_button',
      label: email
    });
    openInviteEmployeeModal();
  };

  const showModalAddPartner = () => {
    gaSend({
      category: 'Messenger',
      action: 'chat_add_partner_button',
      label: email
    });
    showModal('ADD_PARTNER');
  };

  const shownAddEmployeeBtn =
    isCompanyOwner || permissions.haveEmployeesEditPermissions();

  return (
    <div className="cws-contacts-empty">
      <img
        className="cws-contacts-empty__icon"
        src="/img/contact-icon-36-36.svg"
        alt="contact-icon"
      />
      <span className="cws-contacts-empty__title">{t('contacts_empty')}</span>
      <div className="cws-contacts-empty__buttons-container">
        {shownAddEmployeeBtn && (
          <button
            className="btn btn-primary cws-contacts-empty__button"
            type="button"
            onClick={showModalAddEmployee}>
            <span>{t('contacts_add_employee')}</span>
            <img id="img-employee" alt="icon" />
          </button>
        )}
        <button
          className="btn btn-primary cws-contacts-empty__button"
          type="button"
          onClick={showModalAddPartner}>
          <span>{t('contacts_add_partner')}</span>
          <img id="img-partner" alt="icon" />
        </button>
      </div>
    </div>
  );
};

ContactsEmpty.propTypes = {
  showModal: PropTypes.func.isRequired
};

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      showModal: modalActions.showModal,
      gaSend: serviceActions.gaSend
    },
    dispatch
  );

export default connect(
  (state) => ({
    email: state.getIn(['user', 'user', 'email']),
    isCompanyOwner: storeGetters.isCompanyOwner(state)
  }),
  mapDispatch
)(ContactsEmpty);
