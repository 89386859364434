import React from 'react';
import { withTranslation as translate } from 'react-i18next';

const AttachFilesInfo = ({ t, files = [] }) => {
  const getFilesText = () =>
    files.length === 1
      ? files[0].name || files[0]
      : `${files.length} ${t('chat-widget.files')}`;

  if (!files.length) return null;

  return (
    <div className="cws-chat-last-message__attach">
      <img
        className="cws-chat-last-message__attach-icon"
        src="/img/chat-widget/attach.svg"
        alt="file"
      />
      <div className="cws-chat-last-message__attach-text">{getFilesText()}</div>
    </div>
  );
};

export default translate()(AttachFilesInfo);
