import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@link/react-components';
import Loader from '../../../Loader';
import * as commonGetters from '../../getter';
import { formatTypingText } from '../../utils';
import { useTranslate } from '../../../../TranslateProvider';
import './style.css';

const CWTypingInfo = ({ list, isDialog }) => {
  const t = useTranslate();

  return (
    <Typography variant="body2Reg" className="cw-typing" Component="div">
      <span className="cw-typing_text">
        {formatTypingText(t, list, isDialog)}
      </span>
      <Loader theme="intext" />
    </Typography>
  );
};

const MemoizedCWTypingInfo = React.memo(CWTypingInfo);

const CWTypingInfoContainer = ({ isDialog }) => {
  const list = useSelector(commonGetters.getTypingFromOpenedChat);

  if (!list || list.length === 0) return null;

  return <MemoizedCWTypingInfo list={list} isDialog={isDialog} />;
};

export default CWTypingInfoContainer;
