import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation as translate } from 'react-i18next';
import CWSAvatar from '../../Avatar';
import Checkbox from '../../Checkbox';
import SearchBlock from '../../SearchBlock';
import './style.css';

const Row = ({
  id = '',
  avatar = '',
  name = '',
  company = '',
  checkedList = [],
  onCheckToggle = () => {},
  alreadyAdded = false
}) => {
  const isChecked = checkedList.includes(id);
  const renderMark = () =>
    alreadyAdded ? (
      <img
        className="cw-modal-component__contacts-list_row-titles_user-name-mark"
        src="/img/mark-blue.svg"
        alt="mark"
      />
    ) : (
      <span />
    );

  return (
    <div key={id} className="cw-modal-component__contacts-list_row">
      <div className="cw-modal-component__contacts-list_row-avatar">
        <CWSAvatar size={40} info={{ src: avatar, type: 'user', alt: '' }} />
      </div>
      <div className="cw-modal-component__contacts-list_row-titles">
        <div className="cw-modal-component__contacts-list_row-titles_user-name">
          <span className="cw-modal-component__contacts-list_row-titles_user-name-text">
            {name}
          </span>
          {renderMark()}
        </div>
        <div className="cw-modal-component__contacts-list_row-titles_company-name">
          {company}
        </div>
      </div>
      <div className="cw-modal-component__contacts-list_row-checkbox">
        <Checkbox
          onClick={() => onCheckToggle(id, !isChecked)}
          checked={isChecked}
          disabled={alreadyAdded}
        />
      </div>
    </div>
  );
};

Row.propTypes = {
  id: PropTypes.string,
  avatar: PropTypes.string,
  name: PropTypes.string,
  company: PropTypes.string,
  checkedList: PropTypes.arrayOf(PropTypes.string),
  onCheckToggle: PropTypes.func,
  alreadyAdded: PropTypes.bool
};

Row.defaultProps = {
  id: '',
  avatar: '',
  name: '',
  company: '',
  checkedList: [],
  onCheckToggle: () => {},
  alreadyAdded: false
};

const ContactsList = ({ t, contactList, checkedList, onCheckToggle }) => {
  const [search, setSearch] = useState('');

  const filterBySearch = (contact) => {
    const { name = '', company = '' } = contact;
    const candidate = `${name}${company}`.toLowerCase();
    return candidate.includes(search.toLowerCase());
  };

  const renderContactList = () => {
    if (contactList && contactList.length) {
      return contactList
        .filter(filterBySearch)
        .map((contact) => (
          <Row
            key={contact.id}
            checkedList={checkedList}
            onCheckToggle={onCheckToggle}
            {...contact}
          />
        ));
    }
    return <span />;
  };

  return (
    <div className="cw-modal-component__contacts-list-container">
      <div className="cw-modal-component__contacts-list-search">
        <SearchBlock
          placeholder={t('placeholder-enter-first-last-company-name')}
          value={search}
          onChange={setSearch}
          width={525}
        />
      </div>
      <div className="cw-modal-component__contacts-list-list">
        {renderContactList()}
      </div>
    </div>
  );
};

ContactsList.propTypes = {
  t: PropTypes.func.isRequired,
  contactList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      avatar: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      companyName: PropTypes.string,
      alreadyAdded: PropTypes.bool
    })
  ).isRequired,
  checkedList: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCheckToggle: PropTypes.func.isRequired
};

export default translate()(ContactsList);
