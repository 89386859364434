import React, { useCallback, useReducer, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';
import { Button, Icon, Modal } from '@link/react-components';

import { useTranslate, useActions } from '@hooks';

import { partnersCompanyService } from '@root/api';

import * as messageAction from '../../../../action-creators/message';

import { DownloadTemplate } from './DownloadTemplate';
import { SelectionBlock } from './SelectionBlock';
import { FileSection } from './FileSection';
import { reducer, initialState } from './reducer';

import {
  ERROR_MESSAGES,
  ERROR_TYPES,
  INPUT_TYPES,
  MAX_FILE_SIZE,
  MODE,
  TRANSLATE_PATH,
  allowedFileTypes
} from '../constants';

import styles from './Main.module.css';

const getValuesFromErrorDetails = (details) => {
  const values = {
    inn: []
  };

  details.forEach((d) => {
    switch (d.field) {
      case 'inn':
        values.inn.push(d.value);
        break;
      default:
        break;
    }
  });

  return values;
};

export const Main = ({ closeModal, onResetPartnersList }) => {
  const t = useTranslate();
  const translate = (key) => t(`${TRANSLATE_PATH}.${key}`);

  const { setSuccessMessage } = useActions(messageAction);

  const ref = useRef(null);

  const [state, dispatch] = useReducer(reducer, initialState);

  const onCancel = useCallback(() => {
    closeModal();
  }, []);

  const onFileLoad = useCallback((files) => {
    const targetFile = files[0];
    if (targetFile.size > MAX_FILE_SIZE) {
      dispatch({
        type: INPUT_TYPES.LOAD_FILE_ERROR,
        payload: {
          file: targetFile,
          error: { text: translate('errors.size') }
        }
      });
      return;
    }
    if (!allowedFileTypes.includes(targetFile.type)) {
      dispatch({
        type: INPUT_TYPES.LOAD_FILE_ERROR,
        payload: {
          file: targetFile,
          error: { text: translate('errors.type') }
        }
      });
      return;
    }
    dispatch({
      type: INPUT_TYPES.LOAD_FILE_SUCCESS,
      payload: targetFile
    });
  }, []);

  const {
    getRootProps: getDropzoneRootProps,
    getInputProps: getDropzoneInputProps,
    isDragActive
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: onFileLoad,
    disabled: false
  });

  const onLoad = useCallback(async () => {
    dispatch({ type: INPUT_TYPES.LOADING });
    try {
      await partnersCompanyService.importPartners(state.file);
      dispatch({ type: INPUT_TYPES.SUCCESS });
      onResetPartnersList();
      setSuccessMessage({
        key: 'notifications.successImportResponse'
      });
      closeModal();
    } catch (e) {
      if (e.message.name === ERROR_MESSAGES.VALIDATION_ERROR) {
        dispatch({
          type: INPUT_TYPES.ERROR,
          payload: {
            errorMessage: ERROR_MESSAGES.VALIDATION_ERROR,
            innText: translate('errors.errorInINN'),
            values: getValuesFromErrorDetails(e.message.details)
          }
        });
        return;
      }

      switch (e.name) {
        case ERROR_TYPES.FILE_PARSE: {
          dispatch({
            type: INPUT_TYPES.ERROR,
            payload: { text: translate('errors.template') }
          });
          return;
        }
        case ERROR_TYPES.NOT_FOUND: {
          if (e.message === ERROR_MESSAGES.INN_NOT_FOUND) {
            dispatch({
              type: INPUT_TYPES.ERROR,
              payload: {
                text: translate('errors.errorInINN'),
                errorMessage: ERROR_MESSAGES.INN_NOT_FOUND,
                list: [...e.details.inn]
              }
            });
          }
          return;
        }
        default: {
          dispatch({
            type: INPUT_TYPES.ERROR,
            payload: { text: translate('errors.unknown') }
          });
        }
      }
    }
  }, [state.file]);

  const onExchangeFileClick = () => {
    ref.current.click();
  };

  return (
    <>
      <Modal.Content>
        <div className={styles.contentContainer}>
          <SelectionBlock headerText={translate('stepOne')}>
            <DownloadTemplate />
          </SelectionBlock>
          <div
            className={cn(styles.fileSection, {
              [styles.dropzoneActive]: isDragActive
            })}
            {...getDropzoneRootProps()}>
            <SelectionBlock headerText={translate('stepTwo')}>
              <FileSection
                mode={state.mode}
                onFileLoad={onFileLoad}
                fileName={state.file.name}
                error={state.error}
              />
            </SelectionBlock>
            <input {...getDropzoneInputProps()} />
          </div>
        </div>
      </Modal.Content>
      <Modal.Footer align="space-between">
        <Modal.Actions>
          {state.file && (
            <>
              <Button
                onClick={onExchangeFileClick}
                className={styles.choseFileGray}
                mode="text"
                before={<Icon iconName="restore" width={18} height={18} />}>
                {translate('exchange')}
              </Button>
              <input
                type="file"
                onChange={(e) => onFileLoad(e.target.files)}
                id="input-file"
                hidden
                ref={ref}
              />
            </>
          )}
        </Modal.Actions>
        <Modal.Actions>
          <Button onClick={onCancel} mode="text">
            {translate('cancel')}
          </Button>
          <Button
            onClick={onLoad}
            loading={state.mode === MODE.LOADING}
            disabled={!state.file || state.error}>
            {translate('confirm')}
          </Button>
        </Modal.Actions>
      </Modal.Footer>
    </>
  );
};
