import React, { createContext, useContext } from 'react';

const ConfigContext = createContext(null);

export const useConfig = () => useContext(ConfigContext);

export const ConfigProvider = ({ initialConfig = {}, children }) => {
  const config = [initialConfig];

  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};
