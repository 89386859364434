import React from 'react';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';
import IMask from 'imask/esm/imask';
import { IMaskInput } from 'react-imask';
import { format } from 'date-fns';

const imaskBlockOption = {
  d: {
    mask: IMask.MaskedRange,
    placeholderChar: 'D',
    from: 1,
    to: 31,
    maxLength: 2
  },
  m: {
    mask: IMask.MaskedRange,
    placeholderChar: 'M',
    from: 1,
    to: 12,
    maxLength: 2
  },
  Y: {
    mask: IMask.MaskedRange,
    placeholderChar: 'Y',
    from: 1900,
    to: 2999,
    maxLength: 4
  }
};

const PickDateHeader = ({
  t,
  isShowDPStart = true,
  setShowDPStart,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  minDate,
  maxDate
}) => {
  const clearStartDate = (value) => {
    const number = Number(value[0]);
    if (isNaN(number)) {
      setStartDate(null);
    }
  };

  const clearEndDate = (value) => {
    const number = Number(value[0]);
    if (isNaN(number)) {
      setEndDate(null);
    }
  };

  const parseDateMask = (str) => {
    const dayMothYear = str.split('.');
    return new Date(dayMothYear[2], dayMothYear[1] - 1, dayMothYear[0]);
  };

  const formattedSD = startDate ? format(startDate, 'dd.MM.yyyy') : '';
  const formattedED = endDate ? format(endDate, 'dd.MM.yyyy') : '';

  return (
    <div className="datepicker-goodwix__header">
      <div
        className={`datepicker-goodwix__start-date ${
          isShowDPStart ? 'focused' : ''
        }`}>
        <div className="datepicker-goodwix__input-label">
          {t('chat-widget.section.search-mode.datepicker.from')}
        </div>
        <IMaskInput
          mask={Date}
          overwrite
          autofix
          lazy={false}
          blocks={imaskBlockOption}
          className={`datepicker-goodwix__input ${!formattedSD ? 'empty' : ''}`}
          onAccept={clearStartDate}
          onComplete={(str) => {
            setStartDate(parseDateMask(str));
          }}
          min={minDate}
          max={maxDate}
          value={formattedSD}
          onFocus={() => setShowDPStart(true)}
        />
      </div>
      <div
        className={`datepicker-goodwix__end-date ${
          isShowDPStart ? '' : 'focused'
        }`}>
        <div className="datepicker-goodwix__input-label">
          {t('chat-widget.section.search-mode.datepicker.to')}
        </div>
        <IMaskInput
          mask={Date}
          overwrite
          autofix
          lazy={false}
          blocks={imaskBlockOption}
          className={`datepicker-goodwix__input ${!formattedED ? 'empty' : ''}`}
          onFocus={() => setShowDPStart(false)}
          onAccept={clearEndDate}
          onComplete={(str) => {
            setEndDate(parseDateMask(str));
          }}
          min={startDate || minDate}
          max={maxDate}
          value={formattedED}
        />
      </div>
    </div>
  );
};

export default connect((state) => ({
  user: state.getIn(['user', 'user'])
}))(translate()(PickDateHeader));
