import React from 'react';
import Icon from 'react-components/src/icons';
import iconsView from 'react-components/src/icons/constants';
import { useIsClickOutside } from '@hooks';
import styles from './EmojiSelect.module.css';
import { ChatButton } from '../new-components/chat-button/ChatButton';

export const EmojiSelect = ({ children }) => {
  const { ref, isShow, setIsShow } = useIsClickOutside(false);
  return (
    <div className={styles.emojiSection} ref={ref}>
      <ChatButton
        isActive={isShow}
        icon={<Icon icon={iconsView.Emoji} size={18} />}
        onClick={() => setIsShow((prevState) => !prevState)}
      />
      <div className={styles.emojiPicker}>{isShow && children}</div>
    </div>
  );
};
