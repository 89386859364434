import {
  useState,
  useCallback,
  useRef,
  useImperativeHandle,
  KeyboardEvent,
  ChangeEvent,
  forwardRef,
  useEffect,
  SetStateAction,
  Dispatch
} from 'react';
import cn from 'classnames';
import { useInView } from 'react-intersection-observer';
import {
  ClickAwayListener,
  TextareaAutosize,
  Tooltip as MUITooltip
} from '@mui/material';
import { ButtonIcon } from '@/components/ButtonIcon';
import { Tooltip } from '@/components/Tooltip';
import { Keys } from '@/lib/accessibility';
import { isFunction } from '@/lib/utils';
import {
  ConfirmationInputProps,
  ConfirmationInputRef
} from './ConfirmationInput.types';
import styles from './ConfirmationInput.module.scss';

const CONTROL_ICON_SIZE = 18;

const tooltipClasses = {
  popper: styles.popper
};

interface InitRef<T> {
  focus: () => void;
  blur: () => void;
  setValue: Dispatch<SetStateAction<T>>;
}

export const ConfirmationInput = forwardRef<
  ConfirmationInputRef,
  ConfirmationInputProps
>(
  (
    {
      defaultValue,
      placeholder,
      className,
      containerClassName,
      inputClassName,
      disabled,
      minRows,
      maxRows,
      minLength,
      maxLength,
      onChange: change,
      onSubmit: submit,
      onCancel: cancel,
      onKeyDown: keyDown,
      onFocus: focusCb,
      onBlur: blurCb,
      confirmIcon,
      cancelIcon,
      tooltip = '',
      tooltipPlacement = 'top-start',
      endAdornment,
      allowEmpty = true
    },
    ref
  ) => {
    const { ref: containerRef, inView } = useInView();
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const [value, setValue] = useState(defaultValue || '');
    const [showControls, setShowControls] = useState(false);

    const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      const reg = /\r\n|\r|\n|\u2028|&nbsp/gm;
      const updatedNote = newValue.replace(reg, ' ');

      setValue(updatedNote);

      if (change && isFunction(change)) {
        change(updatedNote);
      }
    };

    const [focused, setFocused] = useState(false);
    const focusOn = useCallback(() => setFocused(true), []);
    const focusOff = useCallback(() => setFocused(false), []);

    const onFocus = useCallback(() => {
      textareaRef.current!.focus();
      // TODO временный фикс для корректного фокуса
      textareaRef.current!.click();
      textareaRef.current!.select();
      focusOn();
    }, [focusOn]);

    const onBlur = useCallback(() => {
      textareaRef.current!.blur();
      focusOff();
    }, [focusOff]);

    useImperativeHandle(
      ref,
      (): InitRef<string> => ({
        focus: onFocus,
        blur: onBlur,
        setValue
      }),
      [onFocus, onBlur]
    );

    useEffect(() => {
      setValue(defaultValue || '');
    }, [defaultValue]);

    useEffect(() => {
      setShowControls(focused && inView);
    }, [inView, focused]);

    useEffect(() => {
      if (focused) {
        focusCb?.();
      } else {
        blurCb?.();
      }
    }, [focused, focusCb, blurCb]);

    const onSubmit = useCallback(() => {
      if (!focused) return;
      if (submit && isFunction(submit)) {
        if (!allowEmpty && value === '' && defaultValue) {
          setValue(defaultValue);
          submit(defaultValue);
        }
        submit(value);
      }

      onBlur();
    }, [submit, focused, value, onBlur, defaultValue, allowEmpty]);

    const onCancel = useCallback(() => {
      if (cancel && isFunction(cancel)) {
        cancel();
      }

      setValue(defaultValue || '');
      onBlur();
    }, [cancel, onBlur, defaultValue, setValue]);

    const onClickOutside = useCallback(() => {
      if (!focused) return;

      onSubmit();
    }, [focused, onSubmit]);

    const onKeyDown = useCallback(
      (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (keyDown && isFunction(keyDown)) {
          keyDown(event);
          return;
        }

        switch (event.key) {
          case Keys.ESCAPE:
            onCancel();
            break;
          case Keys.ENTER: {
            event.preventDefault();
            onSubmit();
            break;
          }
          default:
            break;
        }
      },
      [onCancel, onSubmit, keyDown]
    );

    const tooltipText = !focused ? tooltip : '';

    return (
      <div
        data-testid="confirmation-input"
        className={cn(styles.container, Boolean(className) && className, {
          [styles.focused]: focused
        })}
        ref={containerRef}
        onFocus={onFocus}>
        <Tooltip title={tooltipText} placement={tooltipPlacement}>
          <ClickAwayListener onClickAway={onClickOutside}>
            <MUITooltip
              classes={tooltipClasses}
              title={
                <div className={styles.controls}>
                  <ButtonIcon
                    iconName={confirmIcon || 'ok'}
                    variant="medium"
                    size={CONTROL_ICON_SIZE}
                    onClick={onSubmit}
                  />
                  <ButtonIcon
                    iconName={cancelIcon || 'close'}
                    variant="medium"
                    size={CONTROL_ICON_SIZE}
                    onClick={onCancel}
                  />
                </div>
              }
              placement="bottom-end"
              open={showControls}
              disableHoverListener>
              <div className={cn(containerClassName, styles.inputContainer)}>
                <TextareaAutosize
                  data-testid="confirmation-input-textarea"
                  ref={textareaRef}
                  className={cn(styles.input, inputClassName)}
                  value={value}
                  onChange={onChange}
                  placeholder={placeholder}
                  minRows={minRows}
                  maxRows={maxRows}
                  disabled={disabled}
                  minLength={minLength}
                  maxLength={maxLength}
                  onKeyDown={onKeyDown}
                />
                {Boolean(endAdornment) && endAdornment}
              </div>
            </MUITooltip>
          </ClickAwayListener>
        </Tooltip>
      </div>
    );
  }
);
