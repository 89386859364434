import { PREFIX } from '../constants';

export const SET_CHATS = `${PREFIX}:SET_CHATS`;
export const ADD_CHATS = `${PREFIX}:ADD_CHATS`;
export const HIDE_CHAT = `${PREFIX}:HIDE_CHAT`;
export const UPDATE_CHAT = `${PREFIX}:UPDATE_CHAT`;

export const UPDATE_GROUP_OF_CHATS = `${PREFIX}:UPDATE_GROUP_OF_CHATS`;
export const UPDATE_CHATS_FILTER = `${PREFIX}:UPDATE_FILTER_CHATS`;
export const UPDATE_ARCHIVIES_FILTER = `${PREFIX}:UPDATE_FILTER_ARCHIVIES`;

export const SET_ARCHIVES = `${PREFIX}:SET_ARCHIVES`;
export const ADD_ARCHIVES = `${PREFIX}:ADD_ARCHIVES`;
export const SHOW_ARCHIVE = `${PREFIX}:SHOW_ARCHIVE`;
export const HIDE_ARCHIVE = `${PREFIX}:HIDE_ARCHIVE`;
export const UPDATE_ARCHIVE = `${PREFIX}:UPDATE_ARCHIVE`;

export const SET_GROUP_CHAT = `${PREFIX}:SET_GROUP_CHAT`;
export const SHOW_GROUP_CHAT = `${PREFIX}:SHOW_GROUP_CHAT`;
export const HIDE_GROUP_CHAT = `${PREFIX}:HIDE_GROUP_CHAT`;

export const ACTIVE_TAB = {
  CHATS: 'chats',
  ARCHIVES: 'archives'
};

export const SET_OPEN_CHAT = `${PREFIX}:SET_OPEN_CHAT`;

export const SET_MEMBER_STATUS = `${PREFIX}:SET_MEMBER_STATUS`;
