import React, { useCallback, useRef, useState } from 'react';
import { Button, Modal } from '@link/react-components';
import { useAsyncFn, useTranslate } from '@hooks';
import { companyService } from '@api';
import { useSelector } from 'react-redux';
import * as storeGetters from '@root/storeGetters';

import { EditEmployeeForm } from '@root/widgets';

import { LocalStorageProxy } from '@root/utils/localStorageProxy';
import { MODAL_KEY } from './constants';
import { useEditEmployeeModal } from './useEditEmployeeModal';

import styles from './ModalEditEmployee.module.css';
import { validateForm } from './lib';

const maxSuggestionsCount = 15;

const ModalEditEmployee = ({ employee, updateEmployee }) => {
  const t = useTranslate();
  const [, hideModal] = useEditEmployeeModal();
  const [canSubmit, setCanSubmit] = useState(false);

  const employeeInfo = useRef(employee);
  const companyId = useSelector(storeGetters.getCurrentCompanyId);

  const storage = new LocalStorageProxy('department');

  const [{ loading }, editEmployee] = useAsyncFn(async () => {
    await companyService.updateEmployee(
      companyId,
      employee.id,
      employeeInfo.current
    );
    updateEmployee({
      ...employee,
      email: employeeInfo.current.email,
      title: employeeInfo.current.position,
      department: employeeInfo.current.department,
      roleId: employeeInfo.current.roleId,
      employeeVisibility: employeeInfo.current.visibility
    });
    await storage.add(employeeInfo.current.department, maxSuggestionsCount);
    hideModal();
  }, [companyId, employee.id]);

  const onFormChange = useCallback((formState) => {
    employeeInfo.current = formState;
    const isValid = validateForm(formState);
    setCanSubmit((prevState) => (prevState === isValid ? prevState : isValid));
  }, []);

  return (
    <Modal open onClose={hideModal} className={styles.container}>
      <Modal.Header>
        {t('modules.editEmployee.title', {
          firstName: employee.firstName,
          lastName: employee.lastName
        })}
      </Modal.Header>
      <Modal.Content>
        <EditEmployeeForm
          onFormChange={onFormChange}
          initialFormState={employee}
        />
      </Modal.Content>
      <Modal.Footer align="right">
        <Modal.Actions>
          <Button onClick={hideModal} mode="text">
            {t('modules.editEmployee.actionButtons.cancel')}
          </Button>
          <Button
            disabled={!canSubmit}
            loading={loading}
            onClick={editEmployee}>
            {t('modules.editEmployee.actionButtons.save')}
          </Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export const initModalEditEmployee = { [MODAL_KEY]: ModalEditEmployee };
