import React from 'react';
import { Modal } from '@link/react-components';
import { NeedPreview } from '@root/modules/company';
import { useNeedPreviewModal } from './useNeedPreviewModal';
import { MODAL_KEY } from './constants';
import style from './ModalNeedPreview.module.css';

const ModalNeedPreview = ({ needId }) => {
  const [, hideModal] = useNeedPreviewModal();

  return (
    <Modal open onClose={hideModal} className={style.container}>
      <Modal.Content>
        <NeedPreview needId={needId} onOpenedChat={hideModal} />
      </Modal.Content>
    </Modal>
  );
};

export const initNeedPreviewModal = { [MODAL_KEY]: ModalNeedPreview };
