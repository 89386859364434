import React, { useCallback, useEffect, useState } from 'react';

import { useTranslate } from '@hooks';

import { Switch, Typography } from '@link/react-components';

import cn from 'classnames';

import styles from './Permission.module.css';

export const Permission = ({
  permission,
  sectionPermissionStatuses,
  onSwitchPermission,
  translatePath,
  readOnly
}) => {
  const t = useTranslate();

  const translate = (str) => t(`${translatePath}.permissions.${str}`);

  const [disabled, setDisabled] = useState(false);

  const keyForTranslate = permission.key.replaceAll(':', '-');

  useEffect(() => {
    if (!permission.dependsOn.length) {
      return;
    }
    const atLeastOneParentAllowed = permission.dependsOn.some(
      (id) => sectionPermissionStatuses[id]
    );
    setDisabled(!atLeastOneParentAllowed);
  }, [sectionPermissionStatuses, permission]);

  const onSwitch = useCallback(
    (value) => {
      onSwitchPermission({ id: permission.id, value });
    },
    [permission.id]
  );

  return (
    <div className={styles.permission}>
      <div className={styles.leftSideContainer}>
        <Typography
          variant="body1Reg"
          className={cn({ [styles.disabledLabel]: disabled })}>
          {translate(`${keyForTranslate}.label`)}
        </Typography>
        {translate(`${keyForTranslate}.description`) && (
          <Typography
            variant="body1Reg"
            className={cn(styles.description, {
              [styles.disabledDesc]: disabled
            })}>
            {translate(`${keyForTranslate}.description`)}
          </Typography>
        )}
      </div>
      <Switch
        defaultChecked={permission.allowed}
        onSwitch={onSwitch}
        disabled={readOnly || disabled}
      />
    </div>
  );
};
