import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useTranslate } from '@hooks';
import { Typography } from '@link/react-components';
import { sortPermissionsByDependsOn } from './utils';

import { Permission } from '../Permission';

import styles from './PermissionsSection.module.css';

export const PermissionsSection = ({
  section,
  onSwitchPermission,
  readOnly
}) => {
  const t = useTranslate();

  const translatePath = `modules.managePermissions.sections.${section.sectionName}`;

  const [sectionPermissionStatuses, setSectionPermissionStatuses] = useState(
    section.permissions.reduce(
      (acc, permission) => ({ ...acc, [permission.id]: permission.allowed }),
      {}
    )
  );

  useEffect(() => {
    setSectionPermissionStatuses(
      section.permissions.reduce(
        (acc, permission) => ({ ...acc, [permission.id]: permission.allowed }),
        {}
      )
    );
  }, [section]);

  const sortedPermissions = useMemo(
    () => sortPermissionsByDependsOn(section.permissions),
    [section]
  );

  const onSwitchHandler = useCallback(({ id, value }) => {
    setSectionPermissionStatuses((prevState) => ({
      ...prevState,
      [id]: value
    }));
    onSwitchPermission({ id, value });
  }, []);

  return (
    <div className={styles.section}>
      <Typography variant="body1Sb">{t(`${translatePath}.name`)}</Typography>
      {sortedPermissions.map((permission) => (
        <Permission
          permission={permission}
          onSwitchPermission={onSwitchHandler}
          sectionPermissionStatuses={sectionPermissionStatuses}
          translatePath={translatePath}
          key={permission.key}
          readOnly={readOnly}
        />
      ))}
    </div>
  );
};
