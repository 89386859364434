import React, { useCallback, useEffect, useState } from 'react';
import { equals } from 'ramda';
import { useModal, useTranslate } from '@hooks';
import BtnIcon from '../../components/ChatWidget/ButtonIcon';
import './LinksBlock.css';

import { noop } from '../../utils/utils';
import { transformHLink } from '../../components/PurchaseResponseForm/utils';

const BtnSmallIcon = ({ name, icon, onClick }) => (
  <BtnIcon key={`${icon}-${name}`} icon={icon} onClick={onClick} alt={name} />
);

const LinkItem = ({
  name,
  url,
  linkId,
  onDelete,
  canBeDeleted = true,
  mode
}) => (
  <div className="form-respond-link-item">
    <img
      className="form-respond-link-item__icon"
      src="/img/purchase-requests/link.svg"
      alt="icon-link"
    />
    <a
      className="form-respond-link-item__name"
      href={url}
      target="_blank"
      rel="noreferrer">
      {name}
    </a>
    <div className="form-respond-link-item__buttons">
      <div className="btns-small-icons">
        {isEditMode(mode) && canBeDeleted && (
          <BtnSmallIcon
            icon="remove-2"
            name="delete"
            onClick={() => onDelete(linkId)}
          />
        )}
      </div>
    </div>
  </div>
);

const LinksList = ({ mode, links = [], setLinks }) => {
  const onDelete = useCallback((linkId) => {
    setLinks((prevState) => prevState.filter((link) => link.id !== linkId));
  }, []);

  return (
    <div className="form-respond-links-list">
      {links.map((link) => (
        <LinkItem
          key={link.id}
          linkId={link.id}
          onDelete={onDelete}
          name={link.name}
          url={link.url}
          mode={mode}
          canBeDeleted={link.canBeDeleted}
        />
      ))}
    </div>
  );
};

export const LINKS_BLOCK_MODES = Object.freeze({
  EDIT: 'EDIT',
  VIEW: 'VIEW'
});

const isEditMode = equals(LINKS_BLOCK_MODES.EDIT);

export const LinksBlock = ({
  links: _links = [],
  mode = LINKS_BLOCK_MODES.VIEW,
  onChangeLinks = noop,
  classNames = ''
}) => {
  const t = useTranslate();
  const { onOpen: showModal } = useModal();

  const [links, setLinks] = useState(_links);

  useEffect(() => {
    setLinks((prevState) =>
      prevState.length === _links.length ? prevState : _links
    );
  }, [_links]);

  useEffect(() => {
    onChangeLinks(links);
  }, [links.length]);

  const onAttachLink = useCallback(() => {
    showModal('ADD_LINK', {
      onSubmit: (link) => {
        setLinks((prevState) => [...prevState, transformHLink(link)]);
      }
    });
  }, [showModal]);

  return (
    <div className="form-respond-links-list-block">
      <div className="form-respond-links-list-block__header">
        <div className="form-respond-links-list-block__title-wrapper">
          <div className="form-respond-links-list-block__title-text">
            {t('linksListBlock.title')}
          </div>
          <div className="form-respond-links-list-block__title-count">{`(${links.length})`}</div>
        </div>
      </div>
      {links.length > 0 && (
        <div className={`form-respond-links-list-block__content ${classNames}`}>
          <LinksList links={links} setLinks={setLinks} mode={mode} />
        </div>
      )}
      {isEditMode(mode) && (
        <button
          type="button"
          className="form-respond-links-list-block__btn-add"
          onClick={onAttachLink}>
          <img alt="add-link" src="/img/purchase-requests/link-small.svg" />
          <span className="form-respond-links-list-block__btn-add-text">
            {t('modals.add-link.title')}
          </span>
        </button>
      )}
    </div>
  );
};
