import React from 'react';
import cn from 'classnames';
import { Icon } from '@link/react-components';
import styles from './StatusIcon.module.css';
import { STATUSES_ICONS } from '../model/constants';

export const StatusIcon = ({ iconName, className }) => (
  <span className={cn(styles.iconWrapper, className)}>
    <Icon iconName={STATUSES_ICONS[iconName]} width={18} height={18} />
  </span>
);
