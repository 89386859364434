import React, { useState } from 'react';
import { useModal, useTranslate } from '@hooks';
import { Modal, Button } from '@link/react-components';
import { Typography } from '@components';
import { isFunction, isNilOrEmpty, noop } from '@root/utils/utils';
import styles from './Modal.module.css';
import '../styles/modal_theme_default.styl';

const ModalSimpleSubmit = ({
  title,
  onCancel = noop,
  submit,
  submitArguments,
  text,
  textBtnConfirm,
  textBtnCancel = '',
  hideModalDialog: hideModalDialogCb,
  onSubmited: onSubmitted,
  onFailed = noop,
  isCloseAfterClickBackground = true,
  alignmentTop = false
}) => {
  const t = useTranslate();
  const [submitting, setSubmitting] = useState(false);
  const { onClose: onCloseModal } = useModal();

  const hideModalDialog = isFunction(hideModalDialogCb)
    ? hideModalDialogCb
    : onCloseModal;

  const formatter = (text) =>
    text
      .replaceAll('&lt;', '<')
      .replaceAll('&gt;', '>')
      .replaceAll('&amp;', '&')
      .replaceAll('&quot;', '"');

  const translate = (text) => {
    if (text?.tkey) return formatter(t(text.tkey, text.options));
    return typeof text === 'string' ? t(text) : text;
  };

  const onClose = isCloseAfterClickBackground ? hideModalDialog : undefined;

  async function onSubmit() {
    if (submitting) return;

    try {
      setSubmitting(true);
      const result = await submit(submitArguments);
      hideModalDialog();
      onSubmitted(result);
    } catch (error) {
      console.error(error);
      onFailed(error);
    } finally {
      setSubmitting(false);
    }
  }

  function _onCancel() {
    hideModalDialog();
    onCancel();
  }

  const showContent = !isNilOrEmpty(text);
  const titleText = translate(title);
  const cancelText = translate(textBtnCancel) || t('Cancel');
  const submitText = translate(textBtnConfirm) || t('Send');

  return (
    <Modal open onClose={onClose} alignmentTop={alignmentTop}>
      <Modal.Header>{titleText}</Modal.Header>
      {showContent && (
        <Modal.Content className={styles.content}>
          <Typography variant="body1Reg">{translate(text)}</Typography>
        </Modal.Content>
      )}
      <Modal.Footer>
        <Modal.Actions>
          <Button
            id="cancel"
            mode="text"
            className="pull-left"
            onClick={_onCancel}>
            {cancelText}
          </Button>
          <Button
            id="saveButton"
            mode="primary"
            onClick={onSubmit}
            loading={submitting}>
            {submitText}
          </Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalSimpleSubmit;
