import { assoc, isEmpty } from 'ramda';
import createSlice from '../../../../lib/actionReducer';

const STATUSES = {
  confirmed: 'confirmed',
  rejected: 'rejected'
};

const initState = {
  isUploaded: false,
  responses: [],
  size: 0,
  response: {},
  limit: 20,
  offset: 0,
  search: '',
  orderBy: '',
  direction: [],
  selectedCategoriesId: [],
  buyers: [],
  filterApplied: false,
  countries: [],
  regions: [],
  cities: [],
  regionsCount: 0,
  filters: [],
  selectedStatus: []
};

const slice = createSlice({
  name: 'MY_RESPONSES',
  initState,
  reducers: {
    setIsUploaded,
    getAllResponses,
    removeResponse,
    getResponseById: setPayloadToState,
    purgeResponse: (state) => ({ ...state, response: {} }),
    acceptResponse,
    rejectResponse,
    getAllBuyersOfMyResponses: setPayloadToState,
    getCountries: setPayloadToState,
    getRegionsCount: setPayloadToState,
    getRegions: setPayloadToState,
    getCities: setPayloadToState,
    purgeRegions: (state) => ({ ...state, regions: [] }),
    purgeCities: (state) => ({ ...state, cities: [] }),
    setFilters: setPayloadToState,
    setSearch: (state, search) => ({ ...state, search }),
    reset: () => initState,
    setSelectedStatus,
    updateResponse
  }
});

export const { actions } = slice;
export default slice.reducer;

function setPayloadToState(state, payload) {
  return { ...state, ...payload };
}

function setIsUploaded(state, isUploaded = false) {
  return { ...state, isUploaded };
}

function getAllResponses(
  state,
  {
    list: responses,
    size,
    search = '',
    limit = 20,
    offset = 0,
    orderBy = '',
    direction = [],
    selectedCategoriesId = [],
    filterApplied = false
  }
) {
  return {
    ...state,
    responses,
    size,
    search,
    limit,
    offset,
    orderBy,
    direction,
    selectedCategoriesId,
    filterApplied,
    isUploaded: true
  };
}

const setStatus = (response, status) => assoc('status', status, response);

function acceptResponse(state, { requestId, responseId }) {
  const response = !isEmpty(state.response)
    ? setStatus(state.response, STATUSES.confirmed)
    : state.response;
  let responses = [...state.responses];

  if (!isEmpty(responses)) {
    const currentResponse = responses.find(
      (responseItem) => responseItem.id === responseId
    );

    if (currentResponse) currentResponse.status = '';

    responses = responses.map((responseItem) =>
      isResponseOfCurrentRequest(responseItem)
        ? setStatus(responseItem, choiceStatus(responseItem))
        : responseItem
    );
  }

  return { ...state, responses, response };

  function isResponseOfCurrentRequest(currentResponse) {
    return (
      currentResponse.requestId === requestId ||
      currentResponse.attrs.purchaseRequest.id === requestId
    );
  }

  function choiceStatus(currentResponse) {
    return isAcceptedResponse(currentResponse)
      ? STATUSES.confirmed
      : STATUSES.rejected;
  }

  function isAcceptedResponse(currentResponse) {
    return currentResponse.id === responseId;
  }
}

function rejectResponse(state, { responseId }) {
  const response = !isEmpty(state.response)
    ? setStatus(state.response, STATUSES.rejected)
    : state.response;
  const responses = [...state.responses];

  if (!isEmpty(responses)) {
    const responseForReject = responses.find(
      (responseItem) => responseItem.id === responseId
    );

    if (responseForReject) responseForReject.status = STATUSES.rejected;
  }

  return { ...state, response, responses };
}

function setSelectedStatus(state, selectedStatus) {
  return { ...state, selectedStatus };
}

function removeResponse(state, id) {
  const newList = state.responses.filter((response) => response.id !== id);
  return { ...state, responses: newList };
}

function updateResponse(state, { response }) {
  const responses = [...state.responses];

  const foundResponseIndex = responses.findIndex(
    (responseItem) => responseItem.id === response.id
  );

  responses[foundResponseIndex] = setStatus(
    responses[foundResponseIndex],
    response.status
  );
  return { ...state, responses };
}
