import React, { useCallback, useRef, useState } from 'react';
import { Button, Modal } from '@link/react-components';
import { useAsyncFn, useTranslate } from '@hooks';
import { myCompanyService } from '@api';
import { useSelector } from 'react-redux';
import * as storeGetters from '@root/storeGetters';

import { EmployeeForm } from '@root/widgets';
import { LocalStorageProxy } from '@root/utils/localStorageProxy';
import { useAddEmployeeModal } from './useAddEmployeeModal';
import { MODAL_KEY } from './constants';
import styles from './ModalAddEmployee.module.css';
import { validateForm } from './lib';

const maxSuggestionsCount = 15;

const ModalAddEmployee = () => {
  const t = useTranslate();
  const [, hideModal] = useAddEmployeeModal();
  const [canSubmit, setCanSubmit] = useState(false);

  const employeeInfo = useRef(null);
  const companyId = useSelector(storeGetters.getCurrentCompanyId);

  const storage = new LocalStorageProxy('department');

  const [{ loading }, createEmployee] = useAsyncFn(async () => {
    await myCompanyService.addEmployee({
      data: employeeInfo.current
    });
    await storage.add(employeeInfo.current.department, maxSuggestionsCount);
    hideModal();
  }, [companyId]);

  const onFormChange = useCallback((formState) => {
    employeeInfo.current = formState;
    const isValid = validateForm(formState);
    setCanSubmit((prevState) => (prevState === isValid ? prevState : isValid));
  }, []);

  return (
    <Modal open onClose={hideModal} className={styles.container}>
      <Modal.Header>{t('modules.addEmployee.title')}</Modal.Header>
      <Modal.Content>
        <EmployeeForm onFormChange={onFormChange} />
      </Modal.Content>
      <Modal.Footer align="right">
        <Modal.Actions>
          <Button onClick={hideModal} mode="text">
            {t('modules.addEmployee.actionButtons.cancel')}
          </Button>
          <Button
            disabled={!canSubmit}
            loading={loading}
            onClick={createEmployee}>
            {t('modules.addEmployee.actionButtons.submit')}
          </Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export const initModalAddEmployee = { [MODAL_KEY]: ModalAddEmployee };
