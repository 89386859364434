/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { has, hasPath, identity, intersection } from 'ramda';
import cn from 'classnames';
import {
  Button,
  EditableTable,
  Icon,
  Switch,
  Tooltip as UiTooltip,
  Typography,
  CreatableSelect
} from '@link/react-components';
import { useTranslate } from '@hooks';
import { hintService } from '@api';
import { isFunction } from '@root/utils/utils';
import { ProductsPriceCalculator } from '@root/components/PurchaseRequests/ProductsPriceCalculator';
import { ProductsTableMode, ProductTableType } from '../constants';
import IconSimplePlus from '../../../icons/plus';
import { getSumWithVat } from '../NewRequest/utils';
import Tooltip from '../../ChatWidget/Tooltip';
import { ProductName } from './components/ProductName';
import * as storeGetters from '../../../storeGetters';
import { PRODUCTS_TABLE_FIELDS } from './constants';
import {
  isSupplierFound,
  translateColumns,
  translateError,
  translateHeader,
  translateTableFooter,
  translateTooltips
} from './ProductsTable.helpers';
import {
  ProductsErrorsReasons,
  ProductsErrorsTranslateMap,
  ProductsTableErrors,
  ProductsTableFields,
  ResponseValidationProductsTableErrors,
  SortDirections,
  ValidationProductsTableErrors
} from './ProductsTable.constants';
import { unitTypes } from '../entities/Products';
import { validateProductCountBasedOnUnit } from '../utils';
import { Rank } from './Rank';
import { PriceRank } from './priceRank';
import styles from './ProductTable.module.css';

const EMPTY = {
  Array: [],
  Object: {}
};
const secondOrderNoop = () => () => {};

const ProductsTable = ({
  mode = ProductsTableMode.Supplier,
  products = EMPTY.Array,
  handlePasteTable = secondOrderNoop,
  handleChangeProduct = secondOrderNoop,
  onSelectProducts,
  onToggleSelectProductsMode,
  handleRemoveProductRow = secondOrderNoop,
  handleAddProductRow,
  editMode = true,
  editPriceAndCount = false,
  editPrice = false,
  isErrorShown,
  show = true,
  isShowExportToExcelBtn = true,
  exportToExcelBtn,
  hints: propHints,
  handleViewHint,
  type = ProductTableType.Response,
  error,
  isAuction = false,
  showRank = isAuction,
  onFocusPrice = secondOrderNoop,
  customerEditMode = false,
  allowCustomerEdit = false,
  handleCustomerEdit,
  externalResponse = false,
  handleCancel,
  handleSaveChanges,
  showPriceRank = false
}) => {
  if (!show) return null;

  const disableSort = editMode || editPriceAndCount;
  const t = useTranslate();

  const isAuth = useSelector(storeGetters.isAuth);

  const [hints, setHints] = useState(propHints || []);
  const [selectedProductsIds, setSelectedProductsIds] = useState(EMPTY.Array);
  const [isSelectedProductsMode, setIsSelectedProductsMode] = useState(false);
  const [extendedProducts, setExtendedProducts] = useState([]);

  const canSelectedProduct = useCallback(
    (extendedProduct) => {
      if (
        error?.positions?.includes(extendedProduct.id) &&
        has([error.code], ValidationProductsTableErrors)
      ) {
        return true;
      }

      if (
        error?.positions?.includes(extendedProduct.id) &&
        has([error.code], ResponseValidationProductsTableErrors)
      ) {
        return false;
      }

      const canBeSelected = hasPath(
        ['response', 'canBeSelected'],
        extendedProduct
      )
        ? extendedProduct.response.canBeSelected
        : true;

      return canBeSelected;
    },
    [error]
  );

  const countAvailableProducts = useMemo(
    () =>
      extendedProducts.reduce(
        (count, extendedProduct) =>
          canSelectedProduct(extendedProduct) ? count + 1 : count,
        0
      ),
    [extendedProducts, canSelectedProduct]
  );

  const toggleAllSelectProducts = useCallback(() => {
    setSelectedProductsIds((prevSelected) => {
      const selectedIds = [];

      if (!prevSelected.length) {
        extendedProducts.forEach((extendedProduct) => {
          if (canSelectedProduct(extendedProduct)) {
            selectedIds.push(extendedProduct.id);
          }
        });
      }

      return selectedIds;
    });
  }, [extendedProducts, countAvailableProducts, canSelectedProduct]);

  const selectedProducts = useMemo(() => {
    if (!isSelectedProductsMode) {
      return extendedProducts.filter(canSelectedProduct);
    }

    return extendedProducts.filter((extendedProduct) =>
      selectedProductsIds.includes(extendedProduct.id)
    );
  }, [
    extendedProducts,
    isSelectedProductsMode,
    selectedProductsIds,
    canSelectedProduct
  ]);

  const isProductSelected = useCallback((products, productId) => {
    if (!isSelectedProductsMode) {
      return canSelectedProduct(
        products.find((product) => product.id === productId)
      );
    }

    return selectedProductsIds.includes(productId);
  }, []);

  const toggleSelectProduct = useCallback((productId) => {
    setSelectedProductsIds((prevSelected) =>
      !prevSelected.includes(productId)
        ? [...prevSelected, productId]
        : prevSelected.filter((item) => item !== productId)
    );
  }, []);

  const toggleSelectedProductsMode = useCallback(
    () => setIsSelectedProductsMode((prev) => !prev),
    []
  );

  useEffect(() => {
    setExtendedProducts(
      products.map((product, index) => ({ ...product, id: index }))
    );
  }, [products]);

  useEffect(() => {
    if (type === ProductTableType.Response) {
      setExtendedProducts((prevProducts) =>
        prevProducts.map((product, index) => ({
          ...product,
          sum:
            isProductSelected(prevProducts, index) || customerEditMode
              ? getSumWithVat(product)
              : 0
        }))
      );
    }
  }, [
    type,
    products,
    canSelectedProduct,
    selectedProductsIds,
    isSelectedProductsMode,
    customerEditMode
  ]);

  useEffect(() => {
    setSelectedProductsIds((prev) => {
      if (
        error?.positions &&
        intersection(error.positions, selectedProductsIds)
      ) {
        return prev.filter(
          (selectedIndex) => !error.positions.includes(selectedIndex)
        );
      }

      return prev;
    });
  }, [error]);

  useEffect(() => {
    if (!isSelectedProductsMode) {
      setSelectedProductsIds(EMPTY.Array);
    }

    onToggleSelectProductsMode?.(isSelectedProductsMode);
  }, [isSelectedProductsMode]);

  useEffect(() => {
    if (!isSelectedProductsMode) {
      const selectedIds = [];

      extendedProducts.forEach((extendedProduct) => {
        if (canSelectedProduct(extendedProduct)) {
          selectedIds.push(extendedProduct.id);
        }
      });

      onSelectProducts?.(selectedIds, {
        isSelectedAll: selectedIds.length === extendedProducts.length
      });
    } else {
      onSelectProducts?.(selectedProductsIds, {
        isSelectedAll: selectedProductsIds.length === extendedProducts.length
      });
    }
  }, [selectedProductsIds, isSelectedProductsMode, error, extendedProducts]);

  const onViewHint = useCallback(
    async (fieldName, hintName) => {
      setHints((prevState) => {
        const name = hintName || prevState?.[fieldName]?.[0]?.hint;

        if (!name) return prevState;

        const newState = {
          ...prevState,
          productName: prevState.productName.filter(
            (productNameHint) => productNameHint.hint !== name
          )
        };

        if (isFunction(handleViewHint)) {
          handleViewHint(hintName);
          return newState;
        }

        if (!isAuth) return newState;

        hintService.view({ hintName: name });
      });
    },
    [isAuth, handleViewHint]
  );

  const UNITS = useMemo(
    () => [
      { value: unitTypes.instances, label: t('units.instances') },
      { value: unitTypes.amount, label: t('units.amount') },
      { value: unitTypes.kg, label: t('units.kg') },
      { value: unitTypes.liter, label: t('units.liter') },
      { value: unitTypes.packages, label: t('units.packages') },
      { value: unitTypes.m, label: t('units.m') },
      { value: unitTypes.m2, label: t('units.m2') },
      { value: unitTypes.m3, label: t('units.m3') }
    ],
    [t]
  );

  const productTooltip = (response) => {
    const { reason, companyName } = response || {};

    if (mode === ProductsTableMode.Customer) {
      if (reason === ProductsErrorsReasons.NotSelectedBySupplier) {
        return translateTooltips(t, 'notSelectedBySupplier');
      }

      if (reason === ProductsErrorsReasons.RejectedByCustomer) {
        return translateTooltips(t, 'notSelectedByCustomer');
      }

      if (
        reason === ProductsErrorsReasons.AnotherSupplierSelected ||
        companyName
      ) {
        return `${translateTooltips(t, 'supplierCompanyName')} ${companyName}`;
      }
    }

    if (mode === ProductsTableMode.Supplier) {
      if (reason === ProductsErrorsReasons.NotSelectedBySupplier) {
        return translateTooltips(t, 'youAreNotResponded');
      }

      if (reason === ProductsErrorsReasons.RejectedByCustomer) {
        return translateTooltips(t, 'rejectedByCustomer');
      }

      if (reason === ProductsErrorsReasons.AnotherSupplierSelected) {
        return translateTooltips(t, 'anotherSupplierSelected');
      }
    }

    return null;
  };

  const [sort, setSort] = useState({});

  const handleSort = useCallback(
    (a, b) => {
      const key = Object.keys(sort)[0];
      const direction = sort[key] === SortDirections.Asc ? 1 : -1;

      if (key === 'name') {
        if (a[key].toLowerCase() === b[key].toLowerCase()) {
          return 0;
        }
        return (
          direction * (a[key].toLowerCase() > b[key].toLowerCase() ? 1 : -1)
        );
      }

      if (key === 'sum') {
        return (
          direction * (Number(getSumWithVat(a)) - Number(getSumWithVat(b)))
        );
      }

      return direction * (Number(a[key]) - Number(b[key]));
    },
    [sort]
  );

  const sortedProducts = useMemo(
    () => extendedProducts.sort(handleSort),
    [extendedProducts, handleSort]
  );

  const isAnyErrorShown = useMemo(
    () => isErrorShown?.save || isErrorShown?.publish,
    [isErrorShown?.save, isErrorShown?.publish]
  );

  const isRowEmpty = (row) => !(row.name || row.price || row.count);

  const isValid = (product, value) => {
    if (isAuction) {
      return value || !isAnyErrorShown;
    }
    return isRowEmpty(product) || value || !isAnyErrorShown;
  };

  const isUnitValid = (product) =>
    validateProductCountBasedOnUnit(product.count, product.unit) ||
    !isAnyErrorShown;

  const validate = (product, value, className) =>
    !isValid(product, value) ? className || 'error-border' : '';

  const onChangeUnit = (extendedProduct) => (target) => {
    const { id: index, ...product } = extendedProduct;
    handleChangeProduct(product, index, 'unit')({ target });
  };

  const getUnitValue = (extendedProduct) =>
    UNITS.find((unit) => unit.value === extendedProduct.unit) || {
      value: '',
      label: extendedProduct.unit || '-'
    };

  // TODO remove after refactoring
  useEffect(() => {
    if (mode === ProductsTableMode.Supplier && editPriceAndCount) {
      extendedProducts.forEach((extendedProduct) => {
        if (!extendedProduct.originalName) {
          const { id, ...product } = extendedProduct;
          handleChangeProduct(
            { ...product, originalName: extendedProduct.name },
            id,
            'originalName'
          )({ target: { value: product.name } });
        }
      });
    }
  }, [extendedProducts]);

  const onChangeInputTextarea = useCallback(
    (name, productId) => {
      const changedProduct = extendedProducts.find(
        (item) => item.id === productId
      );
      if (!changedProduct) return;

      if (has('metadata', changedProduct)) {
        delete changedProduct.metadata;
      }

      handleChangeProduct(
        changedProduct,
        productId,
        'name'
      )({ target: { value: name } });
    },
    [handleChangeProduct, extendedProducts]
  );

  const onSelect = (productId) => (productEntity) => {
    const extendedProduct = extendedProducts.find(
      (item) => item.id === productId
    );
    if (!extendedProduct) return;

    handleChangeProduct(
      {
        ...extendedProduct,
        metadata: { externalId: productEntity.externalId }
      },
      productId,
      'name'
    )({ target: { value: productEntity.name } });
  };

  function getHint(key, index) {
    if (!has(key, hints)) return undefined;

    const currentHint = hints[key].find((hint) =>
      hint.condition?.({ product: extendedProducts[index], index })
    );

    return currentHint ? currentHint.hint : undefined;
  }

  function hasErrorInPosition(productId) {
    if (error?.code === ProductsTableErrors.PositionsNotAvailable) {
      return error.positions.includes(productId);
    }

    return false;
  }

  function hasFieldError(fieldName, productId) {
    if (
      [
        ProductsTableErrors.AllPositionsPriceEmpty,
        ProductsTableErrors.SomePositionsPriceEmpty,
        ProductsTableErrors.AuctionPriceTooHighOrEmpty
      ].includes(error?.code)
    ) {
      return (
        ProductsTableFields.Price === fieldName &&
        error.positions.includes(productId)
      );
    }

    return false;
  }

  function disableRow(extendedProduct) {
    if (type === ProductTableType.Request) {
      return isSupplierFound(extendedProduct);
    }

    return customerEditMode ? false : !canSelectedProduct(extendedProduct);
  }

  const readOnly = (product) =>
    !(editMode || editPriceAndCount) ||
    product.metadata?.needId ||
    product.metadata?.needsIds;
  const shownSwitcher =
    isFunction(onSelectProducts) &&
    countAvailableProducts > 0 &&
    !customerEditMode;
  const shownCheckboxes =
    isFunction(onSelectProducts) &&
    ProductsTableMode.Customer === mode &&
    ProductTableType.Response === type &&
    !customerEditMode;
  const selectDisabled =
    !isSelectedProductsMode || countAvailableProducts === 0;
  const shownError =
    has('code', error) && ProductsErrorsTranslateMap[error.code];
  const checkboxTooltip =
    !isSelectedProductsMode && countAvailableProducts > 0
      ? translateTooltips(t, 'turnOnPositionsSelect')
      : null;
  const canEditCount =
    mode === ProductsTableMode.Customer && (editMode || editPriceAndCount);

  const zeroPriceFromExternal = (product) =>
    externalResponse &&
    !product.price &&
    product.response?.reason === ProductsErrorsReasons.NotSelectedBySupplier;
  const priceRankRequired =
    showPriceRank &&
    mode === ProductsTableMode.Supplier &&
    type === ProductTableType.Response;
  const columns = (() => {
    const result = [
      shownCheckboxes
        ? {
            accessor: 'selected',
            type: 'checkbox',
            width: 36,
            title: checkboxTooltip,
            headerTitle: checkboxTooltip,
            titleMaxWidth: 360,
            titleAlign: 'center',
            selectedList: selectedProductsIds,
            selected: (product) => selectedProductsIds.includes(product.id),
            onSelect: (product) => toggleSelectProduct(product.id),
            onSelectAll: toggleAllSelectProducts,
            disabled: (product) =>
              selectDisabled || !canSelectedProduct(product),
            cellClassName: (product) =>
              cn({
                [styles.cell]: true,
                [styles.disabled]:
                  disableRow(product) && !zeroPriceFromExternal(product),
                [styles.error]: hasErrorInPosition(product.id)
              })
          }
        : null,
      {
        accessor: 'number',
        headerName: '№',
        type: 'number',
        width: 40,
        cellClassName: (product) =>
          cn({
            [styles.cell]: true,
            [styles.disabled]:
              disableRow(product) && !zeroPriceFromExternal(product)
          })
      },
      showRank
        ? {
            accessor: 'rank',
            headerName: translateColumns(t, 'rank'),
            width: 47,
            type: 'custom',
            renderCell: ({ item: product, itemIndex: index, classNames }) => (
              <div
                data-testid={`product-table-rank-${index}`}
                className={cn('product-table-rank-container', classNames.cell, {
                  [styles.cell]: true,
                  [styles.disabled]:
                    disableRow(product) && !zeroPriceFromExternal(product)
                })}>
                <Rank value={product.rank} className="product-table-rank" />
              </div>
            )
          }
        : null,
      {
        accessor: 'name',
        headerName: translateColumns(t, 'name'),
        type: 'custom',
        renderCell: ({
          item: extendedProduct,
          itemIndex: index,
          classNames
        }) => (
          <UiTooltip
            title={
              customerEditMode || zeroPriceFromExternal(extendedProduct)
                ? null
                : productTooltip(extendedProduct.response)
            }
            className={cn(
              styles.productName,
              !readOnly(extendedProduct) && 'product-table-options__custom',
              validate(extendedProduct, extendedProduct.name)
            )}
            maxWidth={200}>
            <ProductName
              className={cn({
                [classNames.cell]:
                  !customerEditMode && readOnly(extendedProduct),
                [styles.disabled]:
                  disableRow(extendedProduct) &&
                  !zeroPriceFromExternal(extendedProduct)
              })}
              readOnly={readOnly(extendedProduct)}
              mode={mode}
              name={extendedProduct.name}
              originalName={
                extendedProduct.originalName || extendedProduct.name
              }
              externalId={extendedProduct.metadata?.externalId}
              grouped={extendedProduct.metadata?.grouped}
              needId={extendedProduct.metadata?.needId}
              index={extendedProduct.id}
              onPaste={handlePasteTable(index)}
              onChangeInputTextarea={onChangeInputTextarea}
              onSelect={onSelect}
              hint={getHint(PRODUCTS_TABLE_FIELDS.productName, index)}
              onViewHint={onViewHint}
              customerEditMode={customerEditMode}
            />
          </UiTooltip>
        ),
        sortable: !disableSort,
        cellClassName: (product) =>
          cn({
            [styles.cell]: true,
            [styles.disabled]:
              disableRow(product) && !zeroPriceFromExternal(product)
          })
      },
      {
        accessor: 'count',
        headerName: translateColumns(t, 'count'),
        placeholder: (product) => product.count || '0',
        type: 'mask',
        editable: (product) =>
          canEditCount &&
          !(product.metadata?.needId || product.metadata?.needsIds),
        width: 110,
        mask: Number,
        onChange: (value, product) =>
          handleChangeProduct(
            product,
            product.id,
            'count'
          )({ target: { value } }),
        valid: (product) => isValid(product, product.count),
        onPaste: (_, index) => handlePasteTable(index),
        cellClassName: (product) =>
          cn(
            {
              [styles.cell]: true,
              [styles.disabled]:
                disableRow(product) && !zeroPriceFromExternal(product),
              [styles.error]: !isValid(product, product.count)
            },
            styles.maskedInput
          )
      },
      {
        accessor: 'unit',
        headerName: translateColumns(t, 'units'),
        type: 'custom',
        width: 70,
        editable: (item) => editMode && !item.metadata?.needId,
        renderCell: ({ item: extendedProduct, classNames }) =>
          editMode &&
          !(
            extendedProduct.metadata?.needId ||
            extendedProduct.metadata?.needsIds
          ) ? (
            <div
              className={cn(
                classNames.cell,
                'product-table-unit',
                'editable',
                validate(extendedProduct, extendedProduct.unit),
                {
                  'error-border': !isUnitValid(extendedProduct),
                  [styles.cell]: true,
                  [styles.disabled]:
                    disableRow(extendedProduct) &&
                    !zeroPriceFromExternal(extendedProduct)
                }
              )}>
              <CreatableSelect
                data-testid="product-table-unit-select"
                data-disabled={!canSelectedProduct(extendedProduct)}
                value={getUnitValue(extendedProduct)}
                onChange={onChangeUnit(extendedProduct)}
                placeholder=""
                options={UNITS}
                promptTextCreator={(input) => input}
                clearable={false}
                noResultsText={t('noResultsText')}
              />
            </div>
          ) : (
            <div
              className={cn(classNames.cell, 'product-table-unit', {
                [styles.cell]: true,
                [styles.disabled]:
                  disableRow(extendedProduct) &&
                  !zeroPriceFromExternal(extendedProduct)
              })}>
              <Typography
                Component="div"
                variant="body1Reg"
                data-testid="product-table-get-unit-value">
                {getUnitValue(extendedProduct).label}
              </Typography>
            </div>
          ),
        cellClassName: () =>
          cn({
            [styles.cell]: true
          })
      }
    ];

    if (type === ProductTableType.Response || isAuction) {
      result.push({
        accessor: 'price',
        headerName: translateColumns(t, 'costPerOne'),
        placeholder: (product) => (isAuction ? product.initPrice || '0' : '0'),
        dataTestId: (_, index) =>
          `product-table-number-withoutVat-test-${index}`,
        width: 124,
        type: 'mask',
        editable:
          editMode || editPriceAndCount || editPrice || customerEditMode,
        mask: Number,
        onChange: (value, product) =>
          handleChangeProduct(
            product,
            product.id,
            'price'
          )({ target: { value } }),
        valid: (product) => {
          if (isAuction) {
            return isValid(product, product.price);
          }
          return true;
        },
        onFocus: (product, productIndex) => onFocusPrice(product, productIndex),
        align: 'right',
        onPaste: (_, productIndex) => handlePasteTable(productIndex),
        cellClassName: (product, productIndex) =>
          cn(
            {
              'error-border': hasFieldError(
                ProductsTableFields.Price,
                productIndex
              ),
              [styles.error]: !isValid(product, product.price),
              [styles.cell]: true,
              [styles.right]: true,
              [styles.disabled]:
                disableRow(product) && !zeroPriceFromExternal(product)
            },
            styles.maskedInput,
            styles.noWrap
          ),
        endAdornment: (product) =>
          zeroPriceFromExternal(product) && allowCustomerEdit ? (
            <UiTooltip title={t('chat-widget.tooltips.priceIsMissed')}>
              <Icon
                iconName="alert-triangle"
                width={14}
                height={14}
                className={styles.zeroPrice}
              />
            </UiTooltip>
          ) : null,
        startAdornment: (product) =>
          priceRankRequired && product.price ? (
            <PriceRank
              priceRank={product.priceRank}
              disabled={disableRow(product)}
            />
          ) : null
      });
    }

    if (type === ProductTableType.Response) {
      result.push({
        accessor: 'sum',
        placeholder: (product) => (isAuction ? product.initPrice || '0' : '0'),
        headerName: translateColumns(t, 'cost'),
        dataTestId: (_, index) => `product-table-number-withVat-test-${index}`,
        editable: false,
        type: 'text',
        align: 'right',
        width: 124,
        cellClassName: (product) =>
          cn({
            [styles.cell]: true,
            [styles.right]: true,
            [styles.disabled]:
              disableRow(product) && !zeroPriceFromExternal(product)
          }),
        sortable: !disableSort
      });
    }

    return result.filter(identity);
  })();

  const footer = (() => {
    const result = [];

    if (type === ProductTableType.Response || isAuction) {
      const productsPriceCalculator = new ProductsPriceCalculator(
        selectedProducts
      );

      result.push(
        {
          columns: [
            {
              colSpan: columns.length - 1,
              value: translateTableFooter(t, 'totalPrice')
            },
            {
              value: productsPriceCalculator.total(2)
            }
          ]
        },
        {
          columns: [
            {
              colSpan: columns.length - 1,
              value: translateTableFooter(t, 'totalTaxes')
            },
            {
              value: productsPriceCalculator.vat(2)
            }
          ]
        },
        {
          columns: [
            {
              colSpan: columns.length - 1,
              value: translateTableFooter(t, 'totalPayment')
            },
            {
              value: productsPriceCalculator.total(2)
            }
          ]
        }
      );
    }

    if (editMode) {
      result.push({
        columns: [
          {
            colSpan: columns.length,
            value: (
              <div onClick={handleAddProductRow} className="product-table-plus">
                <Tooltip text={t('add_row')}>
                  <IconSimplePlus />
                </Tooltip>
              </div>
            ),
            className: 'product-table-plus-container'
          }
        ]
      });
    }

    return result;
  })();

  const handleDeleteRow = useCallback(
    (row) => {
      handleRemoveProductRow(row.id)();
    },
    [handleRemoveProductRow]
  );

  const handleSortRow = useCallback((updatedSort) => {
    setSort(updatedSort);
  }, []);

  return (
    <>
      <div className={styles.customerActions}>
        {allowCustomerEdit &&
          (customerEditMode ? (
            <div className={styles.editModeCTA}>
              <Button mode="text" onClick={handleSaveChanges}>
                {t('save')}
              </Button>
              <Button mode="text" onClick={handleCancel}>
                {t('cancel')}
              </Button>
            </div>
          ) : (
            <div className={styles.external}>
              <div className={styles.fillInManually}>
                <Button mode="text" onClick={handleCustomerEdit}>
                  {t('fill_in_manually')}
                </Button>
                <UiTooltip title={t('externalProposal')} maxWidth={200}>
                  <Icon iconName="question-fill" width={18} height={18} />
                </UiTooltip>
              </div>
            </div>
          ))}
        {shownSwitcher && (
          <div className="product-table-switch">
            <Switch
              onSwitch={toggleSelectedProductsMode}
              label={translateHeader(t, 'selectPositionsSwitcher')}
            />
          </div>
        )}
      </div>
      <div className={styles.productTable}>
        <EditableTable
          columns={columns}
          rows={sortedProducts}
          footer={footer}
          removable={editMode}
          onRemove={handleDeleteRow}
          onSort={handleSortRow}
        />
        {shownError && (
          <Typography className="product-table-error" variant="body1Reg">
            {translateError(t, ProductsErrorsTranslateMap[error.code])}
          </Typography>
        )}
      </div>
      {exportToExcelBtn && isShowExportToExcelBtn ? (
        <div className="product-table-export-block">
          <div className="product-table-export-excel">
            <Button
              onClick={() => exportToExcelBtn.onClick(products)}
              className="export-to-excel-btn"
              mode="outline"
              before={<Icon iconName="excel" width={18} height={18} />}>
              {t(exportToExcelBtn.tkeyText)}
            </Button>
            <div className="product-table-export-excel-info">
              <Icon iconName="info" width={14} height={14} />
              <Typography
                className="product-table-export-excel-info-text"
                variant="body1Reg">
                {t('respondForm.form.youCanUseThisFile')}
              </Typography>
              <div />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default React.memo(ProductsTable);
