import React, { useCallback } from 'react';
import { Button, Modal, Typography } from '@link/react-components';
import { useTranslate } from '@hooks';
import { Trans as Interpolate } from 'react-i18next';
import { useChangeOwnerModal } from './useChangeOwnerModal';
import { MODAL_KEY } from './constants';

import styles from './modalChangeOwner.module.css';

export function ModalChangeOwner({ newOwner, onChangeOwner, id }) {
  const [, onCloseModal] = useChangeOwnerModal();
  const t = useTranslate();
  const getTranslation = useCallback(
    (trans) => t(`modules.administration.company.changeAdminModal.${trans}`),
    [t]
  );
  const handleChangeOwner = useCallback(() => {
    onChangeOwner(id);
    onCloseModal();
  }, [id]);
  return (
    <Modal open onClose={onCloseModal} className={styles.modal}>
      <Modal.Header>{getTranslation('title')}</Modal.Header>
      <Modal.Content>
        <>
          <Interpolate
            i18nKey="modules.administration.company.changeAdminModal.description"
            values={{ newOwner }}
            components={{
              text: <Typography variant="body1Reg" className={styles.text} />,
              employee: (
                <Typography variant="body1Med" className={styles.text} />
              )
            }}
          />
        </>
      </Modal.Content>
      <Modal.Footer align="right">
        <Modal.Actions>
          <Button mode="text" onClick={onCloseModal}>
            {getTranslation('cancel')}
          </Button>
          <Button onClick={handleChangeOwner}>
            {getTranslation('confirm')}
          </Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
}

export const initModalChangeOwner = { [MODAL_KEY]: ModalChangeOwner };
