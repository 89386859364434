import React, { useState } from 'react';
import { commissionService } from '@api';
import { Modal, Loader, Typography } from '@link/react-components';
import { useAsync, useTranslate } from '@hooks';
import { CommissionInfoTable } from '@root/components/CommissionInfoTable';
import { MODAL_KEY, TRANSLATE_KEY } from './lib/constants';
import { useCommissionInfoModal } from './lib/useCommissionInfoModal';

import styles from './modalCommissionInfo.module.css';

const ModalCommissionInfo = () => {
  const t = useTranslate();
  const [, hideModal] = useCommissionInfoModal();
  const [commission, setCommission] = useState(null);

  const { loading, error } = useAsync(async () => {
    const result = await commissionService.getCommission();
    setCommission(result);
  }, []);

  const render = () => {
    if (loading) {
      return <Loader />;
    }

    if (error) {
      console.error(error);
      return <Loader />;
    }

    return <CommissionInfoTable commission={commission} />;
  };

  return (
    <Modal open onClose={hideModal} className={styles.modal}>
      <Modal.Header>{t(`${TRANSLATE_KEY}.title`)}</Modal.Header>
      <Modal.Content className={styles.content}>
        <Typography variant="body1Reg">
          <p>{t(`${TRANSLATE_KEY}.subTitle`)}</p>
          <p>{t(`${TRANSLATE_KEY}.description`)}</p>
        </Typography>
        {render()}
      </Modal.Content>
    </Modal>
  );
};

export const initCommissionInfoModal = { [MODAL_KEY]: ModalCommissionInfo };
