import { assoc, equals, curry } from 'ramda';

export const draft = 'draft';
export const sent = 'sent';
export const viewed = 'viewed';
export const requestCanceled = 'request-canceled';
export const rejected = 'rejected';
export const clarification = 'clarification';
export const accepted = 'accepted';
export const rework = 'on-rework';
export const reworkCompleted = 'rework-completed';
export const requestInvoice = 'invoice-request';
export const invoiced = 'invoiced';
export const onApproval = 'on_approval';
export const onIntroduction = 'on_introduction';
export const denialOfApproval = 'denial_of_approval';
export const notified = 'notified';
export const agreed = 'agreed';

export const SHOW_LINE_BADGE_STATUSES = Object.freeze([
  sent,
  reworkCompleted,
  onApproval,
  onIntroduction
]);

export const PENDING_RESPONSE_STATUSES = [
  sent,
  viewed,
  clarification,
  reworkCompleted
];
export const ACCEPTED_RESPONSE_STATUSES = [requestInvoice, invoiced, accepted];

export const statusForSupplier = [
  draft,
  sent,
  viewed,
  rejected,
  clarification,
  accepted,
  rework,
  reworkCompleted,
  requestCanceled,
  requestInvoice,
  invoiced
];
export const statusForBuyer = [
  sent,
  viewed,
  rejected,
  clarification,
  accepted,
  rework,
  reworkCompleted,
  requestInvoice,
  invoiced
];

export const isStatus = curry((wantedStatus, currentStatus) =>
  equals(wantedStatus, currentStatus)
);

export const isOneOfStatus = curry((statusList, currentStatus) =>
  statusList.includes(currentStatus)
);

export const getStatusBySupplierForColor = (status) =>
  equals(status, sent) ? 'sent-as-supplier' : status;

export const getStatusByBuyerForColor = (status) =>
  equals(status, sent) ? 'sent-as-buyer' : status;

export const getTransStatusBySupplier = (status) =>
  equals(status, sent)
    ? 'respondStatus.sent-as-supplier'
    : `respondStatus.${status}`;

export const getTransStatusByBuyer = (status) =>
  `respondStatus.${getStatusByBuyerForColor(status)}`;

const getStatus = (status, state) => state[status];

export const withStatusList = (list) =>
  list.reduce((acc, status) => assoc(status, false, acc), {});

export const toggleStatusState = (status, state) =>
  assoc(status, !getStatus(status, state), state);

export const checkboxVMStatuses = (getLabel, list, statusState, toggleStatus) =>
  list.map((status) => ({
    label: getLabel(status, getStatus(status, statusState)),
    set: getStatus(status, statusState),
    onClick: () => toggleStatus(status)
  }));

export const actionTypeToggleStatus = 'toggle_status';

export const actionToggleStatus = (dispatch) => (status) =>
  dispatch({ type: actionTypeToggleStatus, status });

export const isRejected = isStatus(rejected);

export function isDisableChatWithSupplier(status) {
  return !isOneOfStatus(
    [
      accepted,
      viewed,
      sent,
      clarification,
      rework,
      reworkCompleted,
      rejected,
      requestInvoice,
      invoiced,
      onApproval
    ],
    status
  );
}
export function isDisabledAcceptOrReject(status) {
  return !isOneOfStatus([viewed, sent, clarification, reworkCompleted], status);
}
