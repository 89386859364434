export const ProductsTableErrors = Object.freeze({
  PositionsNotAvailable: 'PositionsNotAvailable',
  AllPositionsPriceEmpty: 'AllPositionsPriceEmpty',
  SomePositionsPriceEmpty: 'SomePositionsPriceEmpty',
  AuctionPriceTooHighOrEmpty: 'AuctionPriceTooHighOrEmpty'
});

export const ProductsErrorsTranslateMap = Object.freeze({
  [ProductsTableErrors.PositionsNotAvailable]: 'positionsNotAvailable',
  [ProductsTableErrors.AllPositionsPriceEmpty]: 'allPositionsPriceEmpty',
  [ProductsTableErrors.SomePositionsPriceEmpty]: 'somePositionsPriceEmpty',
  [ProductsTableErrors.AuctionPriceTooHighOrEmpty]: 'auctionPriceTooHighOrEmpty'
});

export const ProductsErrorsReasons = Object.freeze({
  NotSelectedBySupplier: 'not-selected-by-supplier',
  RejectedByCustomer: 'rejected-by-customer',
  AnotherSupplierSelected: 'another-supplier-selected',
  responseOnRework: 'response-on-rework',
  responseAccepted: 'response-accepted'
});

export const ProductsTableFields = Object.freeze({
  Price: 'price'
});

export const SortDirections = Object.freeze({
  Asc: 'ASC',
  Desc: 'Desc'
});

export const ValidationProductsTableErrors = Object.freeze({
  AllPositionsPriceEmpty: 'AllPositionsPriceEmpty',
  SomePositionsPriceEmpty: 'SomePositionsPriceEmpty',
  AuctionPriceTooHighOrEmpty: 'AuctionPriceTooHighOrEmpty'
});

export const ResponseValidationProductsTableErrors = Object.freeze({
  PositionsNotAvailable: 'PositionsNotAvailable'
});
