import { useEffect } from 'react';
import { useIMask, IMask } from 'react-imask';
import { TextField, TextFieldClasses } from '@mui/material';
import cn from 'classnames';
import { Typography } from '@/components/Typography';
import { InputProps, MaskInputProps } from './maskedInput.types';

import styles from './maskedInput.module.scss';

const textFieldClasses: Partial<TextFieldClasses> = {
  root: styles.root
};

const numberMask = {
  scale: 2,
  padFractionalZeros: false,
  normalizeZeros: false,
  radix: '.',
  mapToRadix: [','],
  min: 0
};

export function MaskedInput({
  id,
  className,
  name,
  onChange,
  defaultValue,
  value,
  onBlur,
  onFocus,
  disabled = false,
  valid = true,
  errorText,
  placeholder,
  onPaste,
  onKeyDown,
  ...maskOptions
}: InputProps) {
  const maskInputOpt: MaskInputProps = {
    mask: maskOptions.mask,
    prepare: maskOptions.prepare,
    validate: maskOptions.validate,
    commit: maskOptions.commit,
    overwrite: maskOptions.overwrite,
    autofix: maskOptions.autofix,
    lazy: maskOptions.lazy,
    blocks: maskOptions.blocks,
    pattern: maskOptions.pattern,
    format: maskOptions.format,
    parse: maskOptions.parse,
    min: maskOptions.min,
    max: maskOptions.max
  };

  const maskOpt: MaskInputProps =
    maskInputOpt.mask === Number
      ? {
          ...maskInputOpt,
          ...numberMask
        }
      : maskInputOpt;

  const { ref, setUnmaskedValue } = useIMask(
    maskOpt as IMask.AnyMaskedOptions,
    {
      onAccept: (val) => onChange(val)
    }
  );

  useEffect(
    () => setUnmaskedValue(value ? value.toString() : ''),
    [value, setUnmaskedValue]
  );

  return (
    <div className={className}>
      <TextField
        name={name}
        inputRef={ref}
        classes={textFieldClasses}
        id={id}
        className={cn(className, styles.background)}
        type="text"
        value={value}
        defaultValue={defaultValue}
        data-testid="masked-input"
        placeholder={placeholder}
        disabled={disabled}
        error={!valid}
        onBlur={onBlur}
        onFocus={onFocus}
        onPaste={onPaste}
        onKeyDown={onKeyDown}
      />

      {!valid && errorText && (
        <Typography variant="caption" className={styles.errorMessage}>
          {errorText}
        </Typography>
      )}
    </div>
  );
}
