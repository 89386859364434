import React from 'react';

import { Typography } from '@link/react-components';

import { useTranslate } from '@hooks';

import cn from 'classnames';

import Highlighter from 'react-highlight-words';
import styles from './CompanyCard.module.css';

export const CompanyCard = ({
  id,
  name,
  inn,
  onSelect,
  isSelected,
  searchValue
}) => {
  const t = useTranslate();

  const translate = (key) =>
    t(`modules.finance.primaryPartner.modal.companyCard.${key}`);

  return (
    <div
      key={id}
      className={cn(styles.container, {
        [styles.selected]: isSelected,
        [styles.selectionHover]: !isSelected
      })}
      onClick={() => onSelect(id)}>
      <Typography variant="body1Reg">
        <Highlighter
          searchWords={[searchValue]}
          highlightClassName={styles.highlight}
          autoEscape
          textToHighlight={name}
        />
      </Typography>
      <Typography variant="body1Reg">
        {translate('inn')}
        &nbsp;
        <Highlighter
          searchWords={[searchValue]}
          highlightClassName={styles.highlight}
          autoEscape
          textToHighlight={inn}
        />
      </Typography>
    </div>
  );
};
