import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation as translate } from 'react-i18next';
import EmptyDialogs from './EmptyDialogs';
import Row from './Row';
import './style.css';

const AddedDialogs = ({ t, list, onAdd, onDeleteItem, onDeleteAll }) => (
  <div className="cw-modal-component__added-dialogs-container">
    <div className="cw-modal-component__added-dialogs-header">
      <div className="cw-modal-component__added-dialogs-header_title">
        {t('added-discussions')}
      </div>
      <div className="cw-modal-component__added-dialogs-header_buttons-container">
        <button
          className="cw-modal-component__added-dialogs-header_buttons-container_button"
          type="button"
          onClick={onAdd}>
          {`+ ${t('Add')}`}
        </button>
        <button
          className="cw-modal-component__added-dialogs-header_buttons-container_button"
          type="button"
          onClick={onDeleteAll}>
          {t('delete-all')}
        </button>
      </div>
    </div>
    <div className="cw-modal-component__added-dialogs-list">
      {list && list.length ? (
        list.map((contact) => (
          <Row
            key={contact.id}
            {...contact}
            onDeleteRow={onDeleteItem(contact.id)}
          />
        ))
      ) : (
        <EmptyDialogs t={t} />
      )}
    </div>
  </div>
);

AddedDialogs.propTypes = {
  t: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      type: PropTypes.string
    })
  ).isRequired,
  onAdd: PropTypes.func.isRequired,
  onDeleteAll: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired
};

export default translate()(AddedDialogs);
