import { IMask } from 'react-imask';

export const MODES = Object.freeze({
  readonly: 'readonly',
  edit: 'edit'
});

export const PRODUCTS_TABLE_FIELDS = {
  productName: 'productName'
};

export const autoRenewalMaskBlockOptions = Object.freeze({
  mm: {
    placeholderChar: '0',
    mask: IMask.MaskedRange,
    from: 0,
    to: 99,
    maxLength: 2
  }
});
