import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, ButtonIcon } from '@link/react-components';
import { useTranslate } from '@hooks';
import Avatar from '../../../Avatar';
import CWSIconDropdown from '../../../Dropdown/Icon';
import { position, possiblePositions } from '../setting-context-menu';

const CWSContactListItem = ({
  active = false,
  employeeId,
  avatarSrc,
  userName,
  companyName,
  isBlocked = false,
  isAdmin = false,
  handler,
  status,
  config
}) => {
  const t = useTranslate();
  const handlerOpenContact = ({ target }) => {
    if (
      !target.className.includes('cws-dropdown-menu-item') &&
      target.className !== 'cws-tooltip__children'
    ) {
      handler(config.clickByRow);
    }
  };

  return (
    <div
      className={`cws-contact-item cws-contact-item_blocked_${
        isBlocked ? 'on' : 'off'
      } ${active ? 'active' : ''}`}
      onClick={handlerOpenContact}>
      <div className="cws-contact-item__avatar">
        <Avatar
          size={46}
          isCursorPointer
          info={{
            type: 'user',
            src: avatarSrc,
            alt: userName,
            employeeId,
            status
          }}
        />
      </div>
      <div className="cws-contact-item__info">
        <div className="cws-contact-item__field-user-name">
          <div className="cws-contact-item__icon-forbidden" />
          <div
            className={`cws-contact-item__user-name ${isAdmin ? 'admin' : ''}`}>
            {userName}
          </div>
        </div>
        <div className="cws-contact-item__company-name">{companyName}</div>
      </div>
      <div className="cws-contact-item__buttons">
        <Tooltip title={t('chat-widget.tooltips.write-message')}>
          <ButtonIcon
            iconName="chat"
            variant="medium"
            size={18}
            onClick={() => handler('open-dialog')}
          />
        </Tooltip>
        <CWSIconDropdown
          icon="extra"
          list={config.extra}
          conditions={{ 'block-person': isBlocked ? 'unblock' : 'block' }}
          onSelect={handler}
          fixedMenuPos={false}
          position={position}
          possiblePositions={possiblePositions}
        />
      </div>
    </div>
  );
};

CWSContactListItem.propTypes = {
  active: PropTypes.bool.isRequired,
  avatarSrc: PropTypes.string,
  userName: PropTypes.string.isRequired,
  companyName: PropTypes.string,
  isBlocked: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  handler: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired
};

CWSContactListItem.defaultProps = {
  avatarSrc: '',
  companyName: ''
};

export default CWSContactListItem;
