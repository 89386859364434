import React from 'react';
import { withTranslation as translate } from 'react-i18next';
import { isType } from 'core/lib';
import PropTypes from 'prop-types';

import Message from './msg';
import { isOnline as isOnlineGetter } from '../../../../../../modules/online/getter';

const CWMMessage = ({ message, onRemove, online, showProfile }) => {
  const isOnline = isOnlineGetter(online, message.employeeId);
  return (
    <Message
      data={message}
      isOnline={isOnline}
      isForwarded={isType('MsgForwarded', message)}
      onRemove={onRemove}
      showProfile={showProfile}
    />
  );
};

CWMMessage.propTypes = {
  message: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  online: PropTypes.array.isRequired
};

export default translate()(CWMMessage);
