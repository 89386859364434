import React from 'react';
import { withTranslation as translate } from 'react-i18next';
import Select from 'react-select';
import { is } from 'ramda';

const UniversalSelect = ({
  t,
  value,
  onChange,
  options,
  translateGroup,
  isUseTranslate = true
}) => {
  const toOptions = (optionValue) => {
    function getLabel(v) {
      return isUseTranslate ? t(`select-options.${translateGroup}.${v}`) : v;
    }

    const createOption = (v) => ({ value: v, label: getLabel(v) });

    if (is(String, optionValue)) {
      return createOption(optionValue);
    }
    if (is(Array, optionValue)) {
      return optionValue.map(toOptions);
    }
    return createOption('', 'not-option');
  };

  const toValue = (option) => {
    if (is(Object, option)) {
      return option.value;
    }
    if (is(Array, option)) {
      return option.map(toValue);
    }
    return '';
  };

  return (
    <Select
      value={toOptions(value)}
      options={toOptions(options)}
      onChange={(e) => onChange(toValue(e))}
      searchable={false}
      clearable={false}
      placeholder={t('not selected')}
    />
  );
};

export default translate()(UniversalSelect);
