import React, { Component } from 'react';
import { withTranslation as translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import helpConfig from '../../config/help_modal.json';
import SingleImgPage from './HelpComponents/SingleImgPage.jsx';
import DoubleImgPage from './HelpComponents/DoubleImgPage.jsx';
import TitlePage from './HelpComponents/TitlePage.jsx';

import { ModalContainer } from './elements';

import './styles/modal_theme_default.styl';
import * as modalActions from '../../action-creators/modal';

class ModalHelp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      pages: {},
      init: false
    };
    this.goNextPage = this.goNextPage.bind(this);
    this.goPrevPage = this.goPrevPage.bind(this);
    this.slideLength = this.slideLength.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { user } = this.props;
    const userLanguage = user.get('language');
    if (!this.state.init && userLanguage) {
      this.setState({
        init: true,
        pages: helpConfig && helpConfig[userLanguage || 'ru'].pages
      });
    }
  }

  UNSAFE_componentWillReceiveProps() {
    const { user } = this.props;
    const userLanguage = user.get('language') || 'ru';
    if (!this.state.init && userLanguage) {
      this.setState({
        init: true,
        pages: helpConfig && helpConfig[userLanguage || 'ru'].pages
      });
    }
  }

  goNextPage() {
    if (this.slideLength !== this.state.currentPage) {
      this.setState({ currentPage: ++this.state.currentPage });
    }
  }

  slideLength() {
    return this.state.pages.length - 1;
  }

  goPrevPage() {
    if (
      this.state.currentPage !== 0 ||
      this.state.pages.length === this.state.page
    ) {
      this.setState({ currentPage: --this.state.currentPage });
    }
  }

  render() {
    const { t, hideModalDialog } = this.props;
    const { pages, currentPage } = this.state;

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal
          id="helpModal"
          onHide={() => hideModalDialog()}
          show
          bsSize="large"
          centered
          dialogClassName="help-modal">
          {pages[currentPage].type === 'simple' && (
            <SingleImgPage
              goNextPage={this.goNextPage}
              goPrevPage={this.goPrevPage}
              pages={pages}
              currentPage={currentPage}
              hideModalDialog={hideModalDialog}
              t={t}
              slideLength={this.slideLength()}
            />
          )}
          {pages[currentPage].type === 'frontpage' && (
            <TitlePage
              t={t}
              goNextPage={this.goNextPage}
              hideModalDialog={hideModalDialog}
              pages={pages}
              currentPage={currentPage}
            />
          )}
          {pages[currentPage].type === 'double' && (
            <DoubleImgPage
              goNextPage={this.goNextPage}
              goPrevPage={this.goPrevPage}
              pages={pages}
              currentPage={currentPage}
              hideModalDialog={hideModalDialog}
              t={t}
              slideLength={this.slideLength()}
            />
          )}
        </Modal>
      </ModalContainer>
    );
  }
}

export default connect(
  (state) => ({
    company: state.get('company'),
    user: state.getIn(['user', 'user'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalHelp));
