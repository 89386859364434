import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { withTranslation as translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Modal,
  Col,
  Row,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button
} from 'react-bootstrap';
import './styles/modal_theme_default.styl';
import { ModalContainer } from './elements';

import * as modalActions from '../../action-creators/modal';
import * as customersActions from '../../action-creators/customers';
import * as servicesAction from '../../action-creators/services';

const b = block('modal-container');

class ModalMoveCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      group: '',
      groups: ''
    };
    this.inputProps = this.inputProps.bind(this);
  }

  getChildContext() {
    return { b };
  }

  UNSAFE_componentWillMount() {
    const { groups, currentGroup } = this.props;
    if (groups && groups.size > 0 && !this.state.group) {
      const fGroups = groups.filter(
        (group) => currentGroup.get('id') !== group.get('id') || 0
      );
      this.setState({ groups: fGroups });
    }
  }

  getProperty(field) {
    return this.state[field];
  }

  inputProps(field) {
    return {
      value: this.state[field],
      onChange: (e) => this.setState({ [field]: e.target.value })
    };
  }

  handleClick() {
    const { hideModalDialog, moveCustomer, currentGroup, customer } =
      this.props;

    moveCustomer(customer, +this.state.group, currentGroup.get('id')).then(
      () => {
        hideModalDialog();
      }
    );
  }

  render() {
    const { t, captionKey, hideModalDialog, currentGroup } = this.props;

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="addSupplier" onHide={() => hideModalDialog()} show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => this.hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup controlId="category">
                    <ControlLabel>{t('Customers groups')}</ControlLabel>
                    <FormControl
                      componentClass="select"
                      {...this.inputProps('group')}>
                      <option
                        key={currentGroup.get('id')}
                        value={currentGroup.get('id')}>
                        {t(currentGroup.get('name'))}
                      </option>
                      {this.state.groups &&
                        this.state.groups.map((group) => (
                          <option key={group.get('id')} value={group.get('id')}>
                            {t(group.get('name'))}
                          </option>
                        ))}
                    </FormControl>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveButton"
              bsStyle="primary"
              onClick={this.handleClick}>
              {t('Add customer')}
            </Button>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

ModalMoveCustomer.propTypes = {
  t: PropTypes.func.isRequired
};

ModalMoveCustomer.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    groups: state.getIn(['customers', 'groups'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        moveCustomer: customersActions.moveCustomer,
        getGroups: customersActions.getGroups,
        gaPageView: servicesAction.gaPageView,
        hideModalDialog: modalActions.hideModalDialog
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalMoveCustomer));
