const createRoleViewModel = (role) => ({
  key: role.id,
  value: role
});

export const transformToViewModel = (roles) => roles.map(createRoleViewModel);

export const rolesMapper = {
  transformToViewModel
};
