import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { startOfDay } from 'date-fns';
import sub from 'date-fns/sub';
import {
  Button,
  DatePicker,
  InputLabel,
  Modal,
  Typography
} from '@link/react-components';
import { useTranslate } from '@hooks';

import * as storeGetters from '../../../storeGetters';
import { MODAL_KEY, TRANSLATE_KEY } from './lib/constants';
import { useSetDateFilterModal } from './lib/useSetDateFilterModal';

import styles from './ModalSetDateFilter.module.css';

const ModalSetDateFilter = ({ submit }) => {
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  const [, hideModal] = useSetDateFilterModal();

  const t = useTranslate();
  const translate = (value) => t(`${TRANSLATE_KEY}.${value}`);

  const lang = useSelector(storeGetters.getCurrentUserLanguage);

  const changeDateFrom = (value) => {
    if (value.date > dateTo) {
      setDateTo(value.date);
    }
    setDateFrom(value.date);
  };

  const changeDateTo = (value) => {
    setDateTo(value.date);
  };

  const handleMonthSelect = () => {
    const to = startOfDay(new Date());
    const from = sub(to, { months: 1 });
    setDateFrom(from);
    setDateTo(to);
  };

  const handleHalfYearSelect = () => {
    const to = startOfDay(new Date());
    const from = sub(to, { months: 6 });
    setDateFrom(from);
    setDateTo(to);
  };

  const handleYearSelect = () => {
    const to = startOfDay(new Date());
    const from = sub(to, { years: 1 });
    setDateFrom(from);
    setDateTo(to);
  };

  const handleSubmit = () => {
    if (!dateFrom || !dateTo) {
      return;
    }
    submit(dateFrom, dateTo);
    hideModal();
  };

  return (
    <Modal open onClose={hideModal} className={styles.container}>
      <Modal.Header>{translate('title')}</Modal.Header>
      <Modal.Content>
        <div className={styles.dateContainer}>
          <InputLabel text={translate('start')}>
            <DatePicker
              locale={lang}
              date={dateFrom}
              onDateChange={changeDateFrom}
              minDate={null}
              className={styles.datePicker}
            />
          </InputLabel>
          <span className={styles.divider}>-</span>
          <InputLabel text={translate('end')}>
            <DatePicker
              locale={lang}
              date={dateTo}
              onDateChange={changeDateTo}
              minDate={dateFrom}
              className={styles.datePicker}
            />
          </InputLabel>
        </div>
        <Typography variant="body1Sb" className={styles.quickSelect}>
          {translate('quick-select')}
        </Typography>
        <div className={styles.buttonsContainer}>
          <Button className={styles.button} onClick={handleMonthSelect}>
            <Typography variant="body1Reg">{translate('month')}</Typography>
          </Button>
          <Button className={styles.button} onClick={handleHalfYearSelect}>
            <Typography variant="body1Reg">{translate('halfYear')}</Typography>
          </Button>
          <Button className={styles.button} onClick={handleYearSelect}>
            <Typography variant="body1Reg">{translate('year')}</Typography>
          </Button>
        </div>
      </Modal.Content>
      <Modal.Footer className={styles.footer}>
        <Button mode="text" onClick={hideModal}>
          {translate('cancel')}
        </Button>
        <Button onClick={handleSubmit} disabled={!dateFrom || !dateTo}>
          {translate('submit')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export const initSetDateFilterModal = { [MODAL_KEY]: ModalSetDateFilter };
