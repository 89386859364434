import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation as translate } from 'react-i18next';

const NotMyContactCard = ({ t }) => (
  <div className="chat-not-my-contact-notify-container">
    <p>{t('chat_not_my_contact')}</p>
  </div>
);

NotMyContactCard.propTypes = {
  t: PropTypes.func.isRequired
};
export default translate()(NotMyContactCard);
