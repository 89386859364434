import React, { useCallback } from 'react';
import { Typeahead, Typography } from '@link/react-components';
import cn from 'classnames';
import { useTranslate } from '@hooks';
import { getUsrleInfo } from '@root/storage/company.storage';
import { DENIED_REGISTRATION_REASONS } from '@root/components/BasicInformation/constants';
import styles from './CompanyFormInput.module.css';

function Label({ label, children, required }) {
  return (
    <Typography className={styles.label} variant="body2Med" component="div">
      <span className={cn({ [styles.required]: required })}>{label}</span>
      {children}
    </Typography>
  );
}

const getCompanyName = (company) => company.shortName;

const provider = {
  async get(search) {
    const companiesListAPI = await getUsrleInfo(search);
    return companiesListAPI.map((company) => ({
      key: company.shortName,
      value: company
    }));
  }
};

export const CompanyFormInput = ({
  errorReason,
  label,
  required,
  placeholder,
  setCompanyInfo,
  onChange
}) => {
  const t = useTranslate();
  const handleRenderOption = useCallback((company) => {
    const { shortName, kpp, inn, legalAddress } = company;

    return (
      <div className={styles.option} key={inn}>
        <Typography variant="body1Sb">{shortName}</Typography>
        <Typography variant="body1Reg">{legalAddress}</Typography>
        <Typography variant="body1Reg">{`${t('inn')}: ${inn} ${t('kpp')}: ${
          kpp || '-'
        }`}</Typography>
      </div>
    );
  }, []);
  return (
    <div className={styles.companyInputGroup}>
      <Label label={label} required={required}>
        <Typeahead
          className={styles.typeahead}
          provider={provider}
          onSelect={setCompanyInfo}
          onChange={onChange}
          placeholder={placeholder}
          renderOption={handleRenderOption}
          valid={!errorReason}
          getOptionLabel={getCompanyName}
          showLoader
        />
      </Label>
      {errorReason === DENIED_REGISTRATION_REASONS.SelectCompanyFromTheList && (
        <Typography variant="caption">
          {t('modules.quickRegistration.form.company.error.selectFromTheList')}
        </Typography>
      )}
    </div>
  );
};
