import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import block from 'bem-cn-lite';
import { withTranslation as translate } from 'react-i18next';
import { Modal, Form } from 'react-bootstrap';
import './styles/modal_theme_default.styl';

import Table from '../TableNew';
import { ModalContainer } from './elements';
import Pagination from '../Pagination';
import Loader from '../Loader';

import * as modalActions from '../../action-creators/modal';
import * as storageActions from '../../action-creators/storage';

// const b = block('modal-container');

class ModalEmployeesStorageStatistic extends Component {
  constructor(props) {
    super(props);
    this.hideModal = this.hideModal.bind(this);
    this.selectNextPagination = this.selectNextPagination.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { getEmployeesStorageStatistic } = this.props;
    getEmployeesStorageStatistic();
  }

  hideModal() {
    this.props.hideModalDialog();
  }

  selectNextPagination(value) {
    const { getEmployeesStorageStatistic, limit } = this.props;
    getEmployeesStorageStatistic({ limit, offset: value });
  }

  render() {
    const { t, statistic, size, limit, offset } = this.props;

    return (
      <Modal
        id="employeesStorageStatistic"
        onHide={this.hideModal}
        show
        className="wide-modal">
        <ModalContainer mods={{ type: 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('Storage statistic')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => this.hideModal()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div style={{ marginBottom: 20 }}>
                {t('Chat storage statistic')}
              </div>
              {!statistic && <Loader />}
              {statistic && statistic.size > 0 && (
                <Table
                  newVersion
                  columns={[
                    Table.ColText({
                      accessor: (item) =>
                        `${item['userInfo.lastName']} ${item['userInfo.firstName']}`,
                      title: t('Employee name'),
                      index: 'name',
                      width: '75%'
                    }),
                    Table.ColText({
                      accessor: (item) => {
                        const itemSize = item.filesSize;
                        if (+itemSize >= 1024 * 1024) {
                          return `${(+itemSize / (1024 * 1024)).toFixed(1)}mb`;
                        }
                        return `${(+itemSize / 1024).toFixed(1)}kb`;
                      },
                      title: t('Storage used'),
                      index: 'size'
                    })
                  ]}
                  data={statistic}
                />
              )}
            </Form>
            <Pagination
              totalSize={+size}
              limit={+limit}
              offset={+offset}
              nextPage={this.selectNextPagination}
            />
          </Modal.Body>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalEmployeesStorageStatistic.propTypes = {
  t: PropTypes.func.isRequired
};

export default connect(
  (state) => ({
    statistic: state.getIn(['storage', 'statistic', 'employees']),
    size: state.getIn(['storage', 'statistic', 'size']),
    limit: state.getIn(['storage', 'statistic', 'limit']),
    offset: state.getIn(['storage', 'statistic', 'offset'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        showModal: modalActions.showModal,
        hideModalDialog: modalActions.hideModalDialog,
        getEmployeesStorageStatistic:
          storageActions.getEmployeesStorageStatistic
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalEmployeesStorageStatistic));
