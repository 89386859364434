import React from 'react';
import { Loader, Modal, Typography } from '@link/react-components';
import { useActions, useAsync, useTranslate } from '@hooks';
import * as modalActions from '@root/action-creators/modal';
import { companyService } from '@api';
import { convertUrl } from '@root/components/ChatWidget/modules/files/utils';
import cn from 'classnames';
import styles from './ModalEmployeePreview.module.css';

const InfoBlock = ({ label, text }) => {
  const t = useTranslate();
  return (
    <div className={styles.infoBlock}>
      <Typography variant="body2Reg" className={styles.secondaryTextColor}>
        {t(`employeesPage.previewModal.${label}`)}
      </Typography>
      <Typography variant="body1Reg" className={styles.primaryTextColor}>
        {text || '-'}
      </Typography>
    </div>
  );
};

export function ModalEmployeePreview({ companyId, employeeId }) {
  const t = useTranslate();
  const { hideModalDialog } = useActions(modalActions);
  const { loading, value: employee } = useAsync(
    async () => companyService.getEmployeeById(companyId, employeeId),
    [companyId]
  );

  return (
    <Modal open onClose={hideModalDialog}>
      <Modal.Header>{t('employeesPage.previewModal.title')}</Modal.Header>
      <Modal.Content>
        {loading ? (
          <Loader />
        ) : (
          <div className={styles.column}>
            <div className={styles.row}>
              <div className={styles.column}>
                <InfoBlock label="lastName" text={employee.lastName} />
                <InfoBlock label="firstName" text={employee.firstName} />
                <InfoBlock label="patronymicName" text={employee.middleName} />
              </div>
              <div className={cn(styles.column, styles.columnRight)}>
                <img
                  className={styles.avatar}
                  src={convertUrl(employee?.avatar) || '/img/user.svg'}
                  onError={() => '/img/user.svg'}
                  alt="avatar"
                  width={172}
                  height={172}
                />
              </div>
            </div>
            <div className={styles.delimiter} />
            <div className={styles.row}>
              <div className={styles.column}>
                <InfoBlock label="email" text={employee.email} />
              </div>
              <div className={cn(styles.column, styles.columnRight)}>
                <InfoBlock label="tel" text={employee.phoneNumber} />
              </div>
            </div>
            <div className={styles.delimiter} />

            <InfoBlock label="position" text={employee.position} />
            <InfoBlock label="department" text={employee.department} />
            <div className={styles.delimiter} />
            <InfoBlock
              label="visibility"
              text={t(`employeesPage.previewModal.${employee.visibility}`)}
            />
            <InfoBlock label="role" text={employee.role.name} />
          </div>
        )}
      </Modal.Content>
    </Modal>
  );
}
