import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation as translate } from 'react-i18next';

import * as useCases from '../../../useCases';

import './style.css';
import Select from '../../../Dropdown/Select';
import ButtonIcon from '../../../ButtonIcon';

const CWSSort = ({ list, handleSortHeader }) => {
  const [sortVal, setSort] = useState({ value: 'sort-by-last-update' });
  const [directionVal, setDirection] = useState('down');

  return (
    <div className="cw-header-sort">
      <div className="cw-header-sort-block__container">
        <Select
          title={sortVal}
          onSelect={(item) => {
            setSort(item);
            handleSortHeader(item.value, directionVal);
          }}
          list={list}
        />
      </div>
      <div
        className="cw-header-sort_direction"
        onClick={() => {
          const direction = directionVal === 'up' ? 'down' : 'up';
          setDirection(direction);
          handleSortHeader(sortVal.value, direction);
        }}>
        <img
          src={`/img/chat-widget/sorting_${directionVal}.svg`}
          role="presentation"
          alt=""
        />
      </div>
      <div
        className="cw-header-sort_close"
        onClick={() => handleSortHeader('close')}>
        <ButtonIcon icon="cross" tooltip="sort-reset" width="12" height="12" />
      </div>
    </div>
  );
};

CWSSort.propTypes = {
  list: PropTypes.array.isRequired,
  handleSortHeader: PropTypes.func.isRequired
};

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      ...useCases
    },
    dispatch
  )
)(translate()(CWSSort));
