import { hasPath } from 'ramda';

export function translate(t, tKey) {
  return t(`modules.purchaseProductsTable.${tKey}`);
}

export function translateColumns(t, tKey) {
  return translate(t, `columns.${tKey}`);
}

export function translateHeader(t, tKey) {
  return translate(t, `header.${tKey}`);
}

export function translateTableFooter(t, tKey) {
  return translate(t, `footer.${tKey}`);
}

export function translateTooltips(t, tKey) {
  return translate(t, `tooltips.${tKey}`);
}

export function translateError(t, tKey) {
  return translate(t, `errors.${tKey}`);
}

export function isSupplierFound(product) {
  return hasPath(['response', 'id'], product);
}
