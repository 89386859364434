import getBrowserLanguage from '../utils/languageDetector';
import { initLogAlert } from '../utils/logger';

export const updateToken =
  (token, email = '') =>
  (dispatch) => {
    dispatch({
      type: 'UPDATE_TOKEN',
      payload: { token, email }
    });
  };

export const updateTokenVerificationError = (message) => (dispatch) => {
  dispatch({
    type: 'UPDATE_TOKEN_VERIFICATION_ERROR',
    message
  });
};

export const tokenVerification = (token) => (dispatch) => {
  fetch('/auth/reset-password/verify', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      data: {
        attributes: {
          token
        }
      }
    })
  }).then((res) => {
    if (res.ok) {
      if (res.redirected) {
        window.location = res.url;
      }
      return res.json().then((data) => {
        dispatch(updateToken(token, data.email));
        dispatch(updateTokenVerificationError(null));
      });
    }
    return res.json().then((data) => {
      dispatch(updateTokenVerificationError(data.error));
    });
  });
};

const getLang = () => navigator.language.substr(0, 2).toLowerCase();

export const resetPassword = (email) => () =>
  fetch('/auth/reset-password', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      data: {
        attributes: {
          email,
          lang: getLang()
        }
      }
    })
  });

export const resetPasswordPerform = (token, password) => () =>
  fetch('/auth/reset-password/perform', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      data: {
        attributes: {
          token,
          password,
          lang: getLang()
        }
      }
    })
  });

export const setInviteInfo = (inviteInfo) => ({
  type: 'INVITE_INFO',
  payload: { inviteInfo }
});

export const setRegisterErrors = (errors) => ({
  type: 'REGISTER_ERRORS',
  payload: { errors }
});

export const clearInvite = () => ({
  type: 'CLEAR_INVITE'
});

// eslint-disable-next-line consistent-return
export const getInviteData = (token) => async (dispatch) => {
  try {
    const res = await fetch(`/api/invites/info/${token}`);
    if (res.status > 200) {
      return Promise.reject();
    }
    const inviteInfo = await res.json();
    dispatch(setInviteInfo(inviteInfo));
  } catch (e) {
    console.error(e);
  }
};

export const performRegistration = (user) => async (dispatch) => {
  const {
    antibot,
    email,
    password,
    firstName,
    lastName,
    middleName,
    phone,
    position,
    companyInfo,
    companyBranch,
    token,
    tokenLink,
    partnerToken,
    successRedirect = null,
    roles
  } = user;
  const res = await fetch('/auth/local/register', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      name: antibot || '',
      email,
      password,
      firstName,
      lastName,
      middleName,
      phone,
      position,
      companyInfo,
      companyBranch,
      language: getBrowserLanguage(),
      inviteToken: token,
      tokenLink,
      partnerToken,
      successRedirect,
      roles
    })
  });
  const parsedRes = await res.json();
  if (res.status > 200) {
    if (parsedRes.errors && parsedRes.errors.length) {
      dispatch(setRegisterErrors(parsedRes.errors));
    }
  }
  return { ok: res.ok, json: parsedRes };
};

export const updateVerifyEmailErrorMessage = (message) => (dispatch) => {
  dispatch({
    type: 'UPDATE_VERIFY_EMAIL_ERROR_MESSAGE',
    payload: { message }
  });
};

const alertLogVerifyToken = initLogAlert(!true);
export const verifyToken =
  (token, successRedirect = null) =>
  (dispatch) => {
    fetch('/auth/local/verify-email', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        data: {
          attributes: {
            token,
            successRedirect
          }
        }
      })
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.errors) {
          dispatch(updateVerifyEmailErrorMessage(data.errors[0].code));
          window.location = `${process.env.UNICAT_MESSENGER_SERVICE}/invalid-link`;
        } else {
          alertLogVerifyToken(`redirect to ${data.location}`);
          sessionStorage.setItem('afterRegister', '1');
          window.history.pushState({ fromLogin: true }, '', data.location);
          window.location.href = data.location;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
