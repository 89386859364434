/* eslint-disable react/no-array-index-key */
import { useMemo } from 'react';
import {
  Menu as MUIMenu,
  MenuItem as MUIMenuItem,
  MenuItemClasses as MUIMenuItemClasses,
  MenuClasses as MUIMenuClasses
} from '@mui/material';
import cn from 'classnames';
import styles from './Menu.module.scss';
import { MenuProps, MenuItemProps } from './Menu.types';

const MenuClasses: Partial<MUIMenuClasses> = {
  paper: styles.menu,
  list: styles.list
};

function Delimiter() {
  return <div className={styles.delimiter} />;
}

export function Menu({
  children,
  anchorEl,
  open,
  onClose,
  alignment = 'right',
  groups = [],
  className
}: MenuProps) {
  return (
    <MUIMenu
      elevation={10}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: `${alignment}`
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: `${alignment}`
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      classes={MenuClasses}>
      {children}
      {groups.length !== 0 ? (
        <div className={cn(styles.container, className)}>
          {groups.map((group, index) => (
            <>
              {index < 1 || groups.length === 1 || group.length === 0 ? null : (
                <Delimiter key={`group${index}`} />
              )}
              {group.map((item, itemIndex) => (
                <div
                  key={`group${index}item${itemIndex}`}
                  className={styles.groupItem}>
                  {item}
                </div>
              ))}
            </>
          ))}
        </div>
      ) : null}
    </MUIMenu>
  );
}

function MenuItem({ children, onClick, disabled, className }: MenuItemProps) {
  const MenuItemClasses: Partial<MUIMenuItemClasses> = useMemo(
    () => ({
      root: cn(styles.item, className),
      disabled: cn(styles.disabled, className)
    }),
    [disabled, className]
  );
  return (
    <MUIMenuItem
      onClick={onClick}
      disableRipple
      classes={MenuItemClasses}
      disabled={disabled}>
      {children}
    </MUIMenuItem>
  );
}

Menu.Item = MenuItem;
