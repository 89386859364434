import { Typography } from '@/components/Typography';
import { ToastContentProps } from './types';
import styles from './Toast.module.scss';

interface Data {
  text?: string;
  linkText?: string;
  onLinkClick?: () => void;
  linkClickHandler: string;
}

export function ToastContentContainer(props: Partial<ToastContentProps<Data>>) {
  const { data } = props;
  const { text = '', linkText, onLinkClick } = data || {};

  const renderLink = () => {
    if (!linkText) return null;

    return (
      <button
        type="button"
        className="link link-alert"
        id="messageLink"
        style={{ cursor: 'pointer', marginLeft: 5 }}
        onClick={onLinkClick}>
        <Typography variant="body1Reg" noWrap>
          {linkText}
        </Typography>
      </button>
    );
  };

  return (
    <div data-testid="toast-content-container">
      <Typography variant="body1Reg" className={styles.Text} noWrap>
        {text}
      </Typography>
      {renderLink()}
    </div>
  );
}
