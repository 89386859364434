import React, { Fragment } from 'react';
import { isEmpty } from 'ramda';
import CWSContactListItem from './item';
import { useTranslate } from '../../../../../TranslateProvider';
import ContactsEmpty from './empty-search';

const keys = {
  company: 'companyName'
};

const getKey = (name) => keys[name] || name;

const getGroups = (array, key) => {
  const result = {};

  array.forEach((contact) => {
    const groupValue = contact[key];

    if (groupValue.length) {
      if (Array.isArray(result[groupValue])) {
        result[groupValue].push(contact);
      } else {
        result[groupValue] = [contact];
      }
    }
  });

  return result;
};

const Group = ({ group, handler, groupByKey }) => {
  const [isCollapsed, setIsCollapsed] = React.useState(false);

  const toggleCollapse = () => setIsCollapsed((prevState) => !prevState);

  return (
    <>
      <div
        className="cws-list-search__divider pointer"
        onClick={toggleCollapse}>
        <div className="cws-list-search__divider-text">{groupByKey}</div>
      </div>
      {!isCollapsed &&
        group.map((contact) => (
          <CWSContactListItem
            key={contact.key}
            employeeId={contact.employeeId}
            active={contact.active}
            avatarSrc={contact.avatarSrc}
            userName={contact.userName}
            companyName={contact.companyName}
            isAdmin={contact.isAdmin}
            isBlocked={contact.isBlocked}
            config={contact.config}
            status={contact.status}
            handler={(itemMenu) => handler(itemMenu, contact)}
          />
        ))}
    </>
  );
};

const CWSGroupList = ({ viewModel, handler, groupBy }) => {
  const t = useTranslate();
  const groups = getGroups(viewModel.list, getKey(groupBy));

  if (isEmpty(groups)) {
    return <ContactsEmpty text={t('contactsSidebar.noSearchDepartments')} />;
  }

  return (
    <div className="cws-contact-list">
      {Object.keys(groups).map((groupByKey) => (
        <Group
          handler={handler}
          key={groupByKey}
          groupByKey={groupByKey}
          group={groups[groupByKey]}
        />
      ))}
    </div>
  );
};

export default CWSGroupList;
