import customHistory from '../customHistory';
import { uploadImage } from './media';
import { setCompanyField } from './company';
import { hideModal } from './modal';
import { setSuccessMessage } from './message';
import getBrowserLanguage from '../utils/languageDetector';
import { getCurrentLocation } from '../utils/location';
import { isCurrentPath } from '../utils';
import {
  isOneOfIstockService,
  isMessengerService,
  getLocationOrigin
} from '../lib/goodwix';
import { logLogic, initLogAlert } from '../utils/logger';
import { setUserIdToLS } from '../modules/setUserIdToLS';
import { SET_CURRENT_EMPLOYEE_ID } from '../components/ChatWidget/constants';

const setUserCompany = (company) => ({ type: 'USER_UPDATE_COMPANY', company });
const setCurrentEmployeeId = (employeeId) => ({
  type: SET_CURRENT_EMPLOYEE_ID,
  payload: employeeId
});

const updateUserListOfCompanies = (companies) => ({
  type: 'SET_LIST_OF_COMPANIES',
  companies
});

/**
 * Dispatch event
 *
 * @param {object} user
 * @returns {object}
 */
export function setUser(user) {
  return {
    type: 'USER_SET',
    user
  };
}

const setCurrentUser = setUser;

/**
 * Dispatch event
 *
 * @param {string} field
 * @param {string|number} value
 * @returns {object}
 */
export function setUserField(field, value) {
  return {
    type: 'USER_SET_FIELD',
    field,
    value
  };
}

/**
 * Dispatch event
 *
 * @param {object} user
 * @param {object} company
 * @returns {object}
 */
export function pushUserToCompany(user, company) {
  return {
    type: 'COMPANY_PUSH_NEW_USER',
    user,
    company
  };
}

export const updateUserField = (field, value) => (dispatch) => {
  dispatch(setUserField(field, value));
};

export const selectCompany = (item) => ({
  type: 'USER:SELECT_COMPANY',
  payload: { item }
});

export const clearSelectedCompany = () => ({
  type: 'USER:CLEAR_SELECTED_COMPANY'
});

/**
 * Creates body of query
 *
 * @param {object} user
 * @param {object} company
 * @returns {object}
 */
function createUserBody(user, company) {
  return {
    credentials: 'include',
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      login: user.get('login'),
      firstName: user.get('firstName'),
      lastName: user.get('lastName'),
      middleName: user.get('middleName'),
      phoneNumber: user.get('phoneNumber'),
      avatar: user.get('avatar') || null,
      currentCompany: company.get('id'),
      companyId: company.get('companyId'),
      nickname: user.get('nickname'),
      email: user.get('email')
    })
  };
}

export const uploadUserImage = (type, file) => (dispatch) => {
  uploadImage(file)
    .then(({ data: { attributes } }) => {
      dispatch(setCompanyField(type, attributes));
    })
    .catch((e) => console.error('FAILED UPDATE CATALOG', e));
};

export const updateUserCompany = (company) => (dispatch) =>
  dispatch(setUserCompany(company));

export const getCurrUser = () =>
  fetch('/api/currentUser/', { credentials: 'include' })
    .then((resp) => resp.json())
    .then(({ data }) => data.attributes);

const isRegistration = (currentLocation) =>
  currentLocation.includes('/registration');
const isInvite = (currentLocation) => currentLocation.includes('/welcome');
function isPublicRoute(currentLocation) {
  if (currentLocation.includes(`/unsubscribe`)) {
    return true;
  }
  if (currentLocation.includes(`/quick-response`)) {
    return true;
  }
  if (currentLocation.includes('/no-access')) {
    return true;
  }
  return currentLocation.split('/').some((part) => part === 'share');
}

const alertLogCurrentUser = initLogAlert(false);

export const getCurrentUser =
  (setNewToFalse = false) =>
  (dispatch) =>
    fetch('/api/currentUser/', { credentials: 'include' })
      .then((response) => {
        const currentLocation = getCurrentLocation();
        if (
          response.status === 401 &&
          !isCurrentPath('/prices/:priceId/share/:token')
        ) {
          setUserIdToLS(null);
          dispatch({ type: 'USER_SET_IS_UPLOADED' });
          console.log('current user 401');

          if (isPublicRoute(currentLocation)) {
            return Promise.resolve();
          }
          if (isOneOfIstockService()) {
            if (
              ![
                '/login',
                '/reset-password',
                '/reset-password/perform',
                '/verify-email',
                '/invalid-link'
              ].includes(currentLocation) &&
              !isRegistration(currentLocation) &&
              !isInvite(currentLocation)
            ) {
              if (
                location.href.includes('redirectUrl') ||
                location.href.includes('redirect')
              )
                return;

              console.log('current user path istock', getLocationOrigin());
              alertLogCurrentUser('redirect login');
              window.location = `${getLocationOrigin()}/login?redirectUrl=${
                location.href
              }`;
              return;
            }
            return Promise.reject(new Error('not authorize in messenger'));
          }

          console.log('current user current location', currentLocation);
          // Chose redirect destination
          switch (currentLocation) {
            case '/dialogs':
              window.location = `${process.env.UNICAT_ACCOUNTS_SERVICE}/chat/login`;
              break;
            case '/requests/all':
              window.location = `${process.env.UNICAT_ACCOUNTS_SERVICE}/purchase/login`;
              break;
            case '/startpage':
            case '/commerce':
              window.location = `${process.env.UNICAT_ACCOUNTS_SERVICE}/commerce/login`;
              break;
            default:
              console.log(
                'current user default case',
                process.env.UNICAT_ACCOUNTS_SERVICE
              );
              window.location = `${process.env.UNICAT_ACCOUNTS_SERVICE}`;
              break;
          }

          console.log('current user throw error');
          return Promise.reject(new Error('not authorize'));
        }
        logLogic('no redirect, pass next');
        return response.json();
      })
      .then(({ data: { attributes } }) => {
        const currentLocation = getCurrentLocation();
        const nonRedirectLocations = [
          '/help',
          '/demo',
          '/profile/edit',
          '/profile/companies'
        ];
        if (isMessengerService()) {
          if (!attributes.currentCompany) {
            console.log('redirect to /company/edit');
            customHistory.push('/company/edit');
          }

          let soundNotifications;

          if (!attributes?.status) {
            soundNotifications =
              localStorage.getItem('sound-notification') === 'on';
          } else {
            soundNotifications = attributes.status.notification;
          }

          dispatch(setCurrentUser({ ...attributes, soundNotifications }));
          dispatch(setCurrentEmployeeId(attributes.employeeId));
          return;
        }

        if (
          attributes.billingBlocked &&
          !nonRedirectLocations.includes(currentLocation) &&
          !currentLocation.includes('/billing') &&
          !isCurrentPath('/prices/:priceId/share/:token')
        ) {
          const { permissions } = attributes;
          if (
            permissions.billing &&
            permissions.billing.read &&
            !currentLocation.includes('/billing')
          ) {
            customHistory.push('/billing/info');
          } else {
            customHistory.push('/profile/companies');
          }
        }
        if (attributes.blocked) {
          if (
            !nonRedirectLocations.includes(currentLocation) &&
            !isCurrentPath('/prices/:priceId/share/:token')
          ) {
            customHistory.push('/profile/companies');
          }
        }
        if (
          (!attributes.company &&
            attributes.organizations &&
            attributes.organizations.length > 0) ||
          !attributes.currentCompany
        ) {
          if (
            !nonRedirectLocations.includes(currentLocation) &&
            !isCurrentPath('/prices/:priceId/share/:token')
          ) {
            customHistory.push('/profile/companies');
          }
        } else if (!attributes.company) {
          dispatch(setCompanyField('cancelEnable', false));
        }
        dispatch(setCompanyField('cancelEnable', true));
        if (setNewToFalse) {
          attributes.new = false; // eslint-disable-line
        }
        dispatch(setCurrentUser(attributes));
        dispatch(setCurrentEmployeeId(attributes.employeeId));
      })
      .catch(console.error);

export const saveUser =
  (reloadPageAfter = false) =>
  (dispatch, getState) =>
    fetch(
      '/api/user',
      createUserBody(
        getState().getIn(['user', 'user']),
        getState().getIn(['company'])
      )
    )
      .then((response) => response.json())
      .then((user) => {
        if (user) {
          const currentData = getState().getIn(['user', 'user']).toJS();
          dispatch(setUser({ ...currentData, ...user }));
          dispatch(pushUserToCompany(user, getState().getIn(['company'])));
          dispatch(hideModal());
        }
        if (reloadPageAfter) window.location.reload();
        return Promise.resolve();
      });

export const editUser = (user) => (dispatch, getState) =>
  fetch('/api/user', {
    credentials: 'include',
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      login: user.login,
      firstName: user.firstName,
      lastName: user.lastName,
      middleName: user.middleName,
      phoneNumber: user.phoneNumber,
      avatar: user.avatar || '',
      email: user.email
    })
  }).then((res) =>
    res.json().then((json) => {
      if (res.status < 400) {
        const currentData = getState().getIn(['user', 'user']).toJS();
        dispatch(setUser({ ...currentData, ...json }));
        return Promise.resolve();
      }
      return Promise.reject({ errors: json.errors, status: res.status }); // eslint-disable-line
    })
  );

export const getListOfCompanies = () => (dispatch) => {
  fetch('/api/user/companies', {
    credentials: 'include',
    method: 'GET'
  })
    .then((response) => response.json())
    .then((companies) => {
      dispatch(updateUserListOfCompanies(companies));
    });
};

export const changeCompany = (companyId) => (dispatch) => {
  dispatch({ type: 'USER:CHANGE_COMPANY:REQUEST' });

  return fetch('/api/user/change_company', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ companyId })
  })
    .then(async () => {
      dispatch({ type: 'USER:CHANGE_COMPANY:SUCCESS', payload: { companyId } });
      await getCurrentUser();
    })
    .catch((res) => {
      dispatch({
        type: 'USER:CHANGE_COMPANY:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const leaveCompany = (companyId) => (dispatch) => {
  dispatch({ type: 'USER:CHANGE_COMPANY:REQUEST' });

  return fetch('/api/user/leave_company', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ companyId })
  })
    .then(() => {
      dispatch({ type: 'USER:CHANGE_COMPANY:SUCCESS', payload: { companyId } });
    })
    .catch((res) => {
      dispatch({
        type: 'USER:CHANGE_COMPANY:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const removeAccount = () => (dispatch) => {
  dispatch({ type: 'USER:REMOVE_ACCOUNT:REQUEST' });

  return fetch('/api/user/remove_account', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(() => {
      dispatch({ type: 'USER:REMOVE_ACCOUNT:SUCCESS' });
    })
    .catch((res) => {
      dispatch({
        type: 'USER:REMOVE_ACCOUNT:FAILURE',
        payload: { error: res.error }
      });
      console.error(res);
    });
};

export const changePassword =
  ({ oldPassword, newPassword }) =>
  (dispatch) => {
    dispatch({
      type: 'USER:CHANGE_PASSWORD:REQUEST',
      payload: { oldPassword, newPassword }
    });

    return fetch('/api/user/change-password', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        oldPassword,
        newPassword,
        language: getBrowserLanguage()
      })
    })
      .then((res) =>
        res.json().then(
          (json) =>
            res.status < 400
              ? Promise.resolve(json)
              : Promise.reject({ errors: json.errors, status: res.status }) // eslint-disable-line
        )
      )
      .then(() => {
        dispatch(setSuccessMessage({ key: 'Password successfully changed' }));
        dispatch({ type: 'USER:CHANGE_PASSWORD:SUCCESS' });
      })
      .catch((res) => {
        dispatch({
          type: 'USER:CHANGE_PASSWORD:FAILURE',
          payload: { error: res.errors }
        });
        return res.errors;
      });
  };

export const changeUserLanguage = (langCode) => (dispatch) => {
  dispatch({ type: 'USER:CHANGE_LANGUAGE:REQUEST', payload: { langCode } });

  return fetch('/api/user/change_language', {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ langCode })
  })
    .then((res) =>
      res.json().then(
        (json) =>
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status }) // eslint-disable-line
      )
    )
    .then(() => {
      dispatch({ type: 'USER:CHANGE_LANGUAGE:SUCCESS' });
    })
    .catch((res) => {
      dispatch({
        type: 'USER:CHANGE_LANGUAGE:FAILURE',
        payload: { error: res.errors }
      });
      return res.errors;
    });
};

export const isNameInCompanyUnique =
  (nickname, userId = 0) =>
  (dispatch) => {
    dispatch({ type: 'COMPANY:VALIDATE_UNIQUE_USER_NAME:REQUEST' });
    fetch('/api/user/validate/nameincompany', {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ nickname, userId })
    })
      .then((res) => res.json())
      .then((res) => {
        const { isUnique } = res;
        dispatch({
          type: 'COMPANY:VALIDATE_UNIQUE_USER_NAME:SUCCESS',
          payload: isUnique
        });
      })
      .catch((res) =>
        dispatch({
          type: 'COMPANY:VALIDATE_UNIQUE_USER_NAME:FAILURE',
          payload: { error: res.error }
        })
      );
  };

export const uploadAvatar = (file) => () =>
  fetch(`/api/user/uploadImage?name=${file.name}&type=${file.type}`, {
    credentials: 'include',
    method: 'POST',
    body: file
  })
    .then((res) =>
      res.json().then(
        (json) =>
          res.status < 400
            ? Promise.resolve(json)
            : Promise.reject({ errors: json.errors, status: res.status }) // eslint-disable-line
      )
    )
    .then((data) => data.src);

export const disableInitialHelp = () => async (dispatch) => {
  await fetch('/api/user/disable_initial_help', {
    credentials: 'include',
    method: 'POST'
  });
  dispatch({ type: 'USER:INITIAL_HELP:DISABLE' });
};

export const disablePartnerInfoModal = () => async (dispatch) => {
  await fetch('/api/user/disable_partner_info', {
    credentials: 'include',
    method: 'POST'
  });
  dispatch({ type: 'USER:PARTNER_INFO:DISABLE' });
};

export const loginRefactored =
  ({
    email,
    password,
    antibotString = '',
    tokenLink,
    partnerToken,
    enableRememberMe = false
  }) =>
  async () => {
    const res = await fetch('/auth/local', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: email.trim(),
        password,
        name: antibotString,
        tokenLink,
        partnerToken,
        enableRememberMe
      })
    });
    return res;
  };

export const setUserStatus = (status) => ({
  type: 'USER:SET_STATUS',
  payload: { status }
});

// NOTE: @deprecated
// Don't use it! Use loginRefactored which is above
// Here parameters is incorect
export const login = (
  email,
  password,
  antibotString,
  tokenLink,
  partnerToken
) =>
  loginRefactored({ email, password, antibotString, tokenLink, partnerToken });
