import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation as translate } from 'react-i18next';

import Avatar from '../../../Avatar';
import './styles.css';

const Row = ({ onClick, employeeId, avatar, name, company }) => (
  <div className="cw-modal-chat-row" onClick={onClick}>
    <div className="cw-modal-chat-row_container">
      <div className="cws-contact-item__avatar">
        <Avatar
          size={46}
          info={{ type: 'user', src: avatar, alt: 'logo', employeeId }}
        />
      </div>
      <div className="cw-modal-row_info">
        <div className="cw-modal-chat-row_info-name">{name || '-'}</div>
        <div className="cw-modal-chat-row_info-company">{company || '-'}</div>
      </div>
    </div>
  </div>
);

Row.propTypes = {
  avatar: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

Row.defaultProps = {
  avatar: null
};

export default translate()(Row);
