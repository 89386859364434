import { Executor } from './executor';
import { Route } from './route';

export const createRequestPathTypes = Object.freeze({
  regular: 'regular',
  withConfirmationRequest: 'withConfirmation'
});

export const purchaseRequestTypes = Object.freeze({
  regular: 'Regular',
  regularWithConfirmationRequest: 'RegularWithConfirmation',
  auction: 'Auction',
  auctionWithConfirmation: 'AuctionWithConfirmation'
});

export const purchaseRequestStatuses = Object.freeze({
  draft: 'draft',
  receivingResponses: 'receiving_responses',
  receptionCompleted: 'reception_completed',
  onIntroduction: 'on_introduction',
  onApproval: 'on_approval',
  denialOfApproval: 'denial_of_approval',
  forPublication: 'for_publication'
});
export const allowedResponseStatuses = [
  purchaseRequestStatuses.receivingResponses
];
export const CAN_BE_ARCHIVED_STATUSES = Object.freeze([
  purchaseRequestStatuses.draft,
  purchaseRequestStatuses.receptionCompleted
]);

export const CONFIRMED_STATUSES = Object.freeze([
  purchaseRequestStatuses.draft,
  purchaseRequestStatuses.receivingResponses,
  purchaseRequestStatuses.receptionCompleted
]);

export const unpublishStatuses = Object.freeze([
  purchaseRequestStatuses.draft,
  purchaseRequestStatuses.forPublication
]);

export const executorActionTypes = Object.freeze({
  agreement: 'agreement',
  introduction: 'introduction',
  publication: 'publication'
});

function toExecutors(executors = []) {
  return executors.map((executor) => new Executor(executor));
}

export class PurchaseRequest {
  constructor(props) {
    this.id = props.id;
    this.type = props.type;
    this.date = props.date || undefined;
    this.routeId = props.routeId;
    this.route = props.route ? new Route(props.route) : undefined;
    this.authorId = props.authorId;
    this.executors = toExecutors(props.executors);
    this.categoriesId = props.categoriesId || undefined;
    this.country = props.country;
    this.region = props.region;
    this.city = props.city;
    this.productsList = props.productsList;
    this.products = props.products;
    this.partResponse = props.partResponse;
    this.supplierRequirements = props.supplierRequirements;
    this.attachments = props.attachments;
    this.companyId = props.companyId;
    this.responsibleUser = props.responsibleUser;
    this.sum = props.sum || undefined;
    this.responseEndDate = props.responseEndDate || null;
    this.autoRenewalPeriod = props.autoRenewalPeriod || 0;
    this.currency = props.currency;
    this.links = props.links;
    this.title = props.title;
    this.number = props.number;
    this.accessCompanyList = props.accessCompanyList || [];
    this.suppliers = props.suppliers || [];
    this.lastPublishedDate = props.lastPublishedDate;
    this.status = props.status;
    this.bookmarkedBy = props.bookmarkedBy;
    this.createdAt = props.createdAt;
    this.isArchived = props.isArchived;
    this.responses = props.responses;
    this.viewedBy = props.viewedBy;
    this.updatedAt = props.updatedAt;
    this.authorizedCompanies = props.authorizedCompanies;
    this.isSupplierBidHidden = props.isSupplierBidHidden;
  }

  get isWithConfirmation() {
    return this.type === purchaseRequestTypes.regularWithConfirmationRequest;
  }

  validate() {
    return this.isWithConfirmation ? Boolean(this.routeId) : true;
  }
}
