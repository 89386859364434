import React from 'react';
import { ButtonIcon, Tooltip, toast } from '@link/react-components';
import { useTranslate } from '../../../../../../TranslateProvider';
import styles from './linkCopyBlock.module.css';

export const LinkCopyBlock = ({ text }) => {
  const t = useTranslate();
  const { container, link } = styles;

  const handleClick = async () => {
    await navigator.clipboard.writeText(text);
    toast.success(t('copied_link'));
  };

  return (
    <div className={container}>
      <p className={link}>{text}</p>
      <Tooltip placement="right" title={t('copy link')}>
        <ButtonIcon iconName="copy" onClick={handleClick} />
      </Tooltip>
    </div>
  );
};
