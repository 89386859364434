import React from 'react';
import styled from 'styled-components';
import { Flex } from '../../../styledComponents/atoms';
import { Sidebar } from './components/Sidebar';
import { subHeaderConfig } from './config';
import ChatWidgetMain from '../../ChatWidget/main';
import { isIstockMobService } from '../../../lib/goodwix';
import { ConfigContext } from '../../ChatWidget/Messenger/context/ConfigContext';

const MessengerBody = ({ currentMode }) => {
  const { chatWidgetMain } = subHeaderConfig;

  const style = isIstockMobService() ? {} : { minHeight: '54px' };
  return (
    <ConfigContext.Provider value={chatWidgetMain}>
      <Container style={style} isIstockMobService={isIstockMobService()}>
        {currentMode === 'none' && <Sidebar />}
        {currentMode !== 'none' && (
          <ChatWidgetWrapper>
            <ChatWidgetMain />
          </ChatWidgetWrapper>
        )}
      </Container>
    </ConfigContext.Provider>
  );
};

const Container = styled(Flex)`
  width: 100%;
  height: 100%;
  background: ${(p) => p.theme.colors.white};
  position: relative;
  flex-shrink: 99;

  ::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }
`;

const ChatWidgetWrapper = styled.div`
  width: 100%;
  height: 100%;

  .chat-widget-main {
    width: 100%;
  }
`;

export default MessengerBody;
