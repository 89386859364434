import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'ramda';
import Files from './files';
import { linkToProfileHandler } from '../../../utils';
import { LinkCopyBlock } from './linkCopyBlock';
import { prepareMsg } from './utils';

const regExp = {
  link: /\\{2}(?!\\)(.*?)([^\s|$|<]*)/g,
  tag: /<[^>]+>/g,
  textInTags: /(?<=^|>)[^><]+?(?=<|$)/g
};

const parseMessage = (message, action) => {
  if (isNil(message)) {
    return [];
  }

  const matches = message.matchAll(regExp[action]);
  const result = [];
  let index = 0;

  const pushTextNode = (textContent) =>
    textContent && result.push({ type: 'text', content: textContent });

  for (const match of matches) {
    const textContent = message.slice(index, match.index);
    pushTextNode(textContent);
    result.push({ type: action, content: match[0] });
    index = match.index + match[0].length;
  }

  const textContent = message.slice(index);
  pushTextNode(textContent);
  return result;
};

const isCloseTag = (tag) => tag[1] === '/';

const getCloseTag = (openTag) => {
  const spaceIndex = openTag.indexOf(' ');
  let tagName = null;
  if (spaceIndex === -1) {
    tagName = openTag.slice(1, openTag.length - 1);
  } else {
    tagName = openTag.slice(1, spaceIndex);
  }
  return `</${tagName}>`;
};

const isTextContains = (htmlString) => {
  const matches = htmlString.match(regExp.textInTags);
  return matches && matches.some((match) => match.trim() !== '');
};

const trimExcessParagraph = (preparedString) => {
  const isExcessParagraph =
    preparedString.endsWith('</p></p>') ||
    preparedString.endsWith('</ol></p>') ||
    preparedString.endsWith('</ul></p>');
  if (!isExcessParagraph) {
    return preparedString;
  }
  const trimedString = preparedString.slice(0, -4);
  return trimedString;
};

const wrapTextWithTags = (parsedMessage) => {
  const tagsStack = [];

  return parsedMessage.map((node) => {
    if (node.type === 'text') {
      let result = node.content;
      if (tagsStack.length) {
        for (let i = tagsStack.length - 1; i >= 0; i--) {
          result = `${tagsStack[i]}${result}${getCloseTag(tagsStack[i])}`;
        }
      }

      const parsedText = parseMessage(node.content, 'tag');
      for (let i = 0; i < parsedText.length; i++) {
        const { type, content } = parsedText[i];
        if (type === 'tag') {
          isCloseTag(content) ? tagsStack.pop() : tagsStack.push(content);
        }
      }

      result = trimExcessParagraph(result);

      return isTextContains(result)
        ? { type: 'text', content: result }
        : { type: 'null' };
    }
    return node;
  });
};

const renderMessage = (message, showProfile) => {
  const parsedMessage = parseMessage(message.html, 'link');
  const messageNodes = wrapTextWithTags(parsedMessage);

  if (typeof message.html === 'string') {
    return (
      <div onClick={(event) => linkToProfileHandler(event, showProfile)}>
        {messageNodes.map((messageNode, index) => {
          switch (messageNode.type) {
            case 'text':
              return (
                <p
                  key={`${message.id}-${index}`}
                  dangerouslySetInnerHTML={{
                    __html: prepareMsg('content')(messageNode)
                  }}
                  style={{ paddingBottom: 2 }}
                />
              );
            case 'link':
              return (
                <LinkCopyBlock
                  key={`${message.id}-${index}`}
                  text={prepareMsg('content')(messageNode)}
                />
              );
            default:
              return null;
          }
        })}
      </div>
    );
  }
  // Type message answerphone render
  if (React.isValidElement(message.msg)) {
    return message.msg;
  }
  return message.html;
};

const CWMUMMsg = ({
  chat,
  message,
  isReadMsg,
  isOwnMsg,
  showProfile,
  theme = 'main-chat',
  onLoadedSingleImage,
  filesData
}) => (
  <>
    <div className="cwm-um-msg">{renderMessage(message, showProfile)}</div>
    {!isNil(filesData) && (
      <Files
        chat={chat}
        isOwnMsg={isOwnMsg}
        isReadMsg={isReadMsg}
        message={message}
        theme={theme}
        onLoadedSingleImage={onLoadedSingleImage}
        filesData={filesData}
      />
    )}
  </>
);

CWMUMMsg.propTypes = {
  chat: PropTypes.object.isRequired,
  message: PropTypes.object.isRequired,
  isOwnMsg: PropTypes.bool
};

CWMUMMsg.defaultProps = {
  isOwnMsg: false
};

export default CWMUMMsg;
