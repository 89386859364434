import React, { Component } from 'react';
import { has, isNil } from 'ramda';
import PropTypes from 'prop-types';
import { withTranslation as translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import { PARTICIPANT } from 'core/data/light/memberRole';
import { haveMemberStatus } from 'core/data/light/channelMember';

import { Container, Header, Body, Footer } from '../Components';
import SearchBlockDefault from '../../../SearchBlock/SearchBlockDefault';
import Row from './Row';
import ContactsNotFound from './ContactsNotFound';
import * as channelUC from '../../../../useCases/channel';
import * as modalActions from '../../../../action-creators/modal';
import * as temporaryAction from '../../../../action-creators/temporary';
import * as contactActions from '../../../../action-creators/contacts';
import * as serviceActions from '../../../../action-creators/services';
import { getTempField } from '../../../../storeGetters';
import Checkbox from '../../Checkbox';
import Helper from '../../../Helper';

class CWModalAddToGroupChat extends Component {
  static initSearchMatch(search) {
    const searchText = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const reg = new RegExp(searchText, 'i');

    return ({
      firstName = '',
      lastName = '',
      companyName = '',
      nickname = ''
    }) => {
      if (searchText === '') return true;

      const text = `${lastName} ${firstName} ${lastName} ${companyName} ${nickname}`;
      return reg.test(text);
    };
  }

  static sortUsersByLastName(users) {
    return users.sort((A, B) => {
      const { firstName: firstNameA = '', lastName: lastNameA = '' } = A;
      const { firstName: firstNameB = '', lastName: lastNameB = '' } = B;
      const nameA = `${lastNameA}${firstNameA}`;
      const nameB = `${lastNameB}${firstNameB}`;
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      search: '',
      isShowPrevious: this.setDefaultShowPreviousValue('init')
    };

    this.onCheckDialog = this.onCheckDialog.bind(this);
    this.getDialogs = this.getDialogs.bind(this);
    this.getMemberRole = this.getMemberRole.bind(this);
    this.getMemberRoleOptions = this.getMemberRoleOptions.bind(this);
    this.setSearch = this.setSearch.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.isDialogChecked = this.isDialogChecked.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onChangeMemberRole = this.onChangeMemberRole.bind(this);
  }

  async componentDidMount() {
    const {
      initialMemberIds,
      initialMemberRoles,
      resetMembersIds,
      resetMembersRoles,
      addMemberId,
      setMemberRole,
      clearMemberIds,
      clearMemberRoles,
      loadContacts,
      loadContactsLists,
      loadContactsForGroupChat
    } = this.props;

    await loadContacts();
    await loadContactsLists();
    await loadContactsForGroupChat();

    if (resetMembersIds) {
      clearMemberIds();
    }

    if (resetMembersRoles) {
      clearMemberRoles();
    }

    if (initialMemberIds.length) {
      initialMemberIds.forEach((id) => {
        addMemberId(id);
      });
    }

    if (initialMemberRoles.length) {
      initialMemberRoles.forEach(([member, role]) => {
        setMemberRole(member, role);
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.membersIds.length && !this.props.membersIds.length) {
      this.setDefaultShowPreviousValue();
    }
  }

  setDefaultShowPreviousValue = (init) => {
    const defaultValue = false;

    if (init) return defaultValue;
    return this.setState({ isShowPrevious: defaultValue });
  };

  findMember = (memberId) => {
    const { members } = this.props;

    return members.find((member) => member.employeeId === memberId);
  };

  addMember = (memberId) => {
    const { addMemberId, setMemberRole, membersRoles } = this.props;

    const excludedMember = this.findMember(memberId);

    if (excludedMember) {
      const role =
        membersRoles[excludedMember.employeeId] || excludedMember.memberRole;
      setMemberRole(memberId, role);
    }

    addMemberId(memberId);
  };

  onCheckDialog(uniqueId) {
    const { removeMemberId } = this.props;
    const isChecked = this.isDialogChecked(uniqueId);
    if (isChecked) {
      removeMemberId(uniqueId);
    } else {
      this.addMember(uniqueId);
    }
  }

  onSubmit() {
    const { submit, hideModalDialog, membersIds, membersRoles } = this.props;
    const { isShowPrevious } = this.state;

    hideModalDialog();
    submit(membersIds, Object.entries(membersRoles), isShowPrevious);
  }

  onCancel() {
    const { cancel, hideModalDialog } = this.props;
    hideModalDialog();
    cancel();
  }

  onChangeMemberRole(employeeId, role) {
    const { setMemberRole, mode, gaSend, userEmail } = this.props;
    setMemberRole(employeeId, role);

    if (mode.toLowerCase() === 'channel') {
      gaSend({
        category: 'Messenger',
        action: 'group_chat_role_edit',
        label: userEmail
      });
    }
    if (mode.toLowerCase() === 'topic') {
      gaSend({
        category: 'Messenger',
        action: 'topic_role_edit',
        label: userEmail
      });
    }
  }

  getUsers() {
    const { contacts = [], members = [] } = this.props;
    const { search } = this.state;
    const contactsList = Array.isArray(contacts) ? contacts : contacts.toJS();

    const alreadyMembers = members
      .map((member) => ({ ...member, alreadyMember: true }))
      .filter(
        (member) =>
          !haveMemberStatus('EXCLUDED', member) &&
          !haveMemberStatus('LEAVE', member)
      );
    const candidatesMembers = contactsList
      .map((member) => ({ ...member, alreadyMember: false }))
      .filter(
        (member) =>
          !alreadyMembers.find((i) => i.employeeId === member.employeeId)
      );
    const allUsers = [...candidatesMembers, ...alreadyMembers];
    const searchMatch = CWModalAddToGroupChat.initSearchMatch(search);
    const filteredUsers = allUsers.filter(searchMatch);
    return CWModalAddToGroupChat.sortUsersByLastName(filteredUsers);
  }

  getDialogs() {
    const { t, mode } = this.props;
    const users = this.getUsers();

    if (users.length > 0) {
      return users.map(
        ({
          firstName = '',
          lastName = '',
          userName,
          companyName = null,
          avatarSrc = null,
          avatar = null,
          employeeId,
          alreadyMember
        }) => (
          <Row
            mode={mode}
            alreadyMember={alreadyMember}
            rolesOptions={this.getMemberRoleOptions()}
            key={employeeId.toString()}
            employeeId={employeeId}
            uniqueId={employeeId}
            avatar={avatarSrc || avatar}
            name={userName || `${lastName} ${firstName}`}
            company={companyName}
            checked={alreadyMember ? true : this.isDialogChecked(employeeId)}
            onCheck={this.onCheckDialog}
            memberRole={this.getMemberRole(employeeId)}
            onChangeMemberRole={this.onChangeMemberRole}
          />
        )
      );
    }

    return <ContactsNotFound t={t} />;
  }

  getMemberRole(employeeId) {
    const { membersRoles, members } = this.props;
    if (has(employeeId, membersRoles)) {
      return membersRoles[employeeId];
    }
    if (!members) {
      return PARTICIPANT;
    }
    const member = members.find((m) => m.employeeId === employeeId);
    if (!isNil(member)) {
      return member.memberRole;
    }
    return PARTICIPANT;
  }

  getMemberRoleOptions() {
    const {
      mode,
      purpose,
      getMemberRoleOptionsForCreate,
      getMemberRoleOptionsForEdit
    } = this.props;

    if (purpose === 'Create') {
      return getMemberRoleOptionsForCreate(mode);
    }

    if (purpose === 'Add') {
      return getMemberRoleOptionsForEdit(mode);
    }

    return [];
  }

  setSearch(search) {
    this.setState({ search });
  }

  isDialogChecked = (uniqueId) => {
    const { membersIds } = this.props;
    return membersIds.includes(uniqueId);
  };

  clearSearch() {
    this.setState({ search: '' });
  }

  isDisabledSubmit() {
    const { mode, membersIds } = this.props;
    if (mode === 'Channel') return membersIds.length === 0;
    if (mode === 'Topic') return false;
    return false;
  }

  hasChanges = () => {
    const { membersIds } = this.props;
    return Boolean(membersIds.length);
  };

  isShowPreviousCheckbox = () => {
    const { actionType } = this.props;
    return actionType === 'Edit' && this.hasChanges();
  };

  toggleShowPrevious = () => {
    this.setState((state) => ({ isShowPrevious: !state.isShowPrevious }));
  };

  renderCheckboxShowPrevious = () => {
    const { t } = this.props;
    const { isShowPrevious } = this.state;

    return (
      <div className="modal-add-to-group-chat__checkbox-wrapper">
        <Checkbox checked={isShowPrevious} onClick={this.toggleShowPrevious} />
        <span
          className="modal-add-to-group-chat-checkbox__label"
          onClick={this.toggleShowPrevious}>
          {t('Show all previous messages to new members')}
        </span>
        <Helper text="New members can use previous messages and files" />
      </div>
    );
  };

  render() {
    const { t } = this.props;

    return (
      <Modal onHide={this.onCancel} show>
        <Container>
          <Header title={t('add_to_group_chat')} onClose={this.onCancel} />
          <Body>
            <SearchBlockDefault
              placeholder={t('find_contacts')}
              onChange={this.setSearch}
              onEmpty={this.clearSearch}
              width={510}
            />
            <div className="modal-add-to-group-chat__container">
              {this.getDialogs()}
            </div>
            {this.isShowPreviousCheckbox() && this.renderCheckboxShowPrevious()}
          </Body>
          <Footer
            lbTitle={t('Cancel')}
            lbOnClick={this.onCancel}
            rbTitle={t('Save')}
            rbOnClick={this.onSubmit}
            rbDisabled={this.isDisabledSubmit()}
          />
        </Container>
      </Modal>
    );
  }
}

CWModalAddToGroupChat.propTypes = {
  t: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  cancel: PropTypes.func,
  hideModalDialog: PropTypes.func.isRequired,
  members: PropTypes.array,
  mode: PropTypes.string,
  purpose: PropTypes.string,

  // Roles getters for Select options
  getMemberRoleOptionsForCreate: PropTypes.func.isRequired,
  getMemberRoleOptionsForEdit: PropTypes.func.isRequired,

  // Roles
  membersRoles: PropTypes.object.isRequired,
  setMemberRole: PropTypes.func.isRequired,
  clearMemberRoles: PropTypes.func.isRequired,
  initialMemberRoles: PropTypes.array,
  resetMembersIds: PropTypes.bool,
  // Member ids
  membersIds: PropTypes.array.isRequired,
  addMemberId: PropTypes.func.isRequired,
  removeMemberId: PropTypes.func.isRequired,
  clearMemberIds: PropTypes.func.isRequired,
  initialMemberIds: PropTypes.array,
  resetMembersRoles: PropTypes.bool
};

CWModalAddToGroupChat.defaultProps = {
  cancel: () => {},
  resetMembersIds: false,
  resetMembersRoles: false,
  initialMemberRoles: [],
  initialMemberIds: [],
  mode: 'Channel',
  purpose: 'Create',
  members: []
};

const mapState = (state) => ({
  membersRoles: getTempField(state, 'inviteMembersRoles', {}),
  membersIds: getTempField(state, 'inviteMembersIds', []),
  contacts: state.getIn(['contacts', 'contactsForChannel']),
  userEmail: state.getIn(['user', 'user', 'email'])
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      showModal: modalActions.showModal,
      hideModalDialog: modalActions.hideModalDialog,
      // Roles getters for Select options
      getMemberRoleOptionsForCreate: channelUC.getMemberRoleOptionsForCreate,
      getMemberRoleOptionsForEdit: channelUC.getMemberRoleOptionsForEdit,
      // Roles
      setMemberRole: temporaryAction.setToMapTemp('inviteMembersRoles'),
      clearMemberRoles: temporaryAction.clearMapTemp('inviteMembersRoles'),
      // Member ids
      addMemberId: temporaryAction.appendToSetTemp('inviteMembersIds'),
      removeMemberId: temporaryAction.removeFromSetTemp('inviteMembersIds'),
      clearMemberIds: temporaryAction.clearSetTemp('inviteMembersIds'),
      loadContacts: contactActions.loadContacts,
      loadContactsLists: contactActions.loadContactsLists,
      loadContactsForGroupChat: contactActions.loadContactsForGroupChat,
      gaSend: serviceActions.gaSend
    },
    dispatch
  );

export default connect(
  mapState,
  mapDispatch
)(translate()(CWModalAddToGroupChat));
