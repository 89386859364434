import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import { withTranslation as translate } from 'react-i18next';
import { Modal, Form, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';

import * as dialogsActions from '../../action-creators/dialogs';
import * as modalActions from '../../action-creators/modal';

const b = block('modal-container');

class ModalArchiveDialog extends Component {
  getChildContext() {
    return { b };
  }

  render() {
    const {
      t,
      hideModalDialog,
      putDialogToArchive,
      dialogId,
      userName,
      onSubmited = () => {}
    } = this.props;

    return (
      <Modal id="simpleSubmit" onHide={() => hideModalDialog()} show>
        <ModalContainer mods={{ type: 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('modal_archive_title')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <p className={b('row')}>
                {t('modal_archive_text', { userName })}
              </p>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => hideModalDialog()}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveButton"
              bsStyle="primary"
              onClick={async () => {
                await putDialogToArchive(dialogId, true);
                await onSubmited();
                hideModalDialog();
              }}>
              {t('move')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalArchiveDialog.propTypes = {
  t: PropTypes.func.isRequired
};

ModalArchiveDialog.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  () => {},
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog,
        putDialogToArchive: dialogsActions.putDialogToArchive
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalArchiveDialog));
