import React from 'react';
import { Emoji } from 'emoji-mart';
import { noop } from '../../../../../../../../utils/utils';

const SuggestionItem = ({
  colons,
  skin,
  isActive = false,
  onFocus = noop,
  onSelect = noop
}) => {
  const getClassName = () => {
    let className = 'emoji-suggestions__item';
    if (isActive) className += ' emoji-suggestions__item_active';
    return className;
  };

  return (
    <div className={getClassName()} onMouseEnter={onFocus} onMouseUp={onSelect}>
      <div
        className="emoji-suggestions__view"
        dangerouslySetInnerHTML={{
          __html: Emoji({
            html: true,
            set: 'apple',
            emoji: colons,
            skin,
            size: 24
          })
        }}
      />
      <div className="emoji-suggestions__name">{colons}</div>
    </div>
  );
};

export default SuggestionItem;
