import React, { useCallback, useEffect, useState } from 'react';
import { useTranslate } from '@hooks';
import {
  canRegistrationCompanyByInn,
  getUsrleInfo
} from '@root/storage/company.storage';
import { Icon, Typeahead, Typography } from '@link/react-components';

import Highlighter from 'react-highlight-words';
import styles from './FormUsrle.module.css';
import { COMPANY_ERRORS, COMPANY_ERRORS_TEXT } from './constants';

const getCompanyName = (company) => company.shortName;

const provider = {
  async get(search) {
    const companiesListAPI = await getUsrleInfo(search);
    return companiesListAPI.map((company) => ({
      key: `${company.shortName}-${company.inn}`,
      value: company
    }));
  }
};

const CompanyOption = ({ company, query }) => {
  const t = useTranslate();
  const { shortName, kpp, inn, legalAddress } = company;
  return (
    <div className={styles.formUsrleContainer}>
      <div className={styles.formUsrleContent}>
        <Typography variant="body1Reg" className={styles.textBold}>
          <Highlighter
            highlightClassName={styles.highlight}
            searchWords={[query]}
            autoEscape
            textToHighlight={shortName}
          />
        </Typography>
        <Typography variant="body1Reg" className={styles.textBold}>
          <Highlighter
            highlightClassName={styles.highlight}
            searchWords={[query]}
            autoEscape
            textToHighlight={`${t('inn')}: ${inn} ${t('kpp')}: ${kpp || '-'}`}
          />
        </Typography>
        <Typography variant="body2Reg" className={styles.textDefault} c>
          <Highlighter
            highlightClassName={styles.highlight}
            searchWords={[query]}
            autoEscape
            textToHighlight={legalAddress}
          />
        </Typography>
      </div>
      <Icon iconName="arrow-right" width={18} height={18} />
    </div>
  );
};

const NoResult = () => {
  const t = useTranslate();
  return (
    <Typography variant="body2Reg" className={styles.noResult}>
      {t('company_no_result')}
    </Typography>
  );
};

export const FormUsrle = ({
  isSelected,
  setInfo,
  disabled,
  defaultValue = ''
}) => {
  const t = useTranslate();
  const [searchValue, setSearchValue] = useState(defaultValue);

  const [error, setError] = useState('');

  const onBlur = () => {
    if (isSelected && !error) return;

    if (isSelected) {
      setError('');
      return;
    }

    setError((prevState) =>
      prevState === COMPANY_ERRORS.ALREADY_EXIST
        ? prevState
        : COMPANY_ERRORS.UNSET_COMPANY
    );
  };
  useEffect(() => {
    setSearchValue(defaultValue);
  }, [defaultValue]);

  const onChange = (value) => {
    if (isSelected) {
      setInfo({});
      setError(COMPANY_ERRORS.UNSET_COMPANY);
    } else {
      setError((prevState) =>
        prevState === COMPANY_ERRORS.ALREADY_EXIST
          ? COMPANY_ERRORS.UNSET_COMPANY
          : prevState
      );
    }

    setSearchValue(value);
  };

  const renderOption = useCallback(
    (entity) => <CompanyOption company={entity} query={searchValue} />,
    [searchValue]
  );

  const onSelect = async (company) => {
    const canRegistrationCompany = await canRegistrationCompanyByInn(
      company.inn
    );

    if (!canRegistrationCompany) {
      setInfo({});
      setError(COMPANY_ERRORS.ALREADY_EXIST);
      return;
    }
    setInfo(company);
    setError('');
  };

  return (
    <div>
      <Typography className={styles.label}>
        <label htmlFor="form-usrle">
          {t('Company name')}
          &nbsp;
          <span className={styles.redStar}>* </span>
        </label>
      </Typography>
      <Typeahead
        initialValue={searchValue}
        id="form-usrle"
        disabled={disabled}
        valid={!error}
        onBlur={onBlur}
        placeholder={t('product_placeholder')}
        className={styles.typeahead}
        provider={provider}
        renderOption={renderOption}
        onChange={onChange}
        onSelect={onSelect}
        maxLength={Number.MAX_SAFE_INTEGER}
        getOptionLabel={getCompanyName}
        noOptionsComponent={<NoResult />}
        showLoader
      />
      {error && (
        <Typography variant="caption" className={styles.errorMessage}>
          {t(`${COMPANY_ERRORS_TEXT[error]}`)}
        </Typography>
      )}
    </div>
  );
};
