import URLSearchParams from 'url-search-params';

import request from '../lib/request';
import {
  CompanyInfoDto,
  CompanyPurchaseRequestDTO,
  EmployeeDto,
  SaveNoteDto,
  ExternalInfoDto
} from '../DTOs';
import { searchQuery } from '../utils/searchQuery';

class CompanyService {
  #baseUrl = '/api/companies';

  getCompanies({ offset = 0, limit = 20, search = '' } = {}) {
    const abortController = new AbortController();

    const cancel = () => abortController.abort();

    const searchParams = new URLSearchParams({ offset, limit, search });
    const fetchFn = async () =>
      request.get(`${this.#baseUrl}/list/?${searchParams}`, null, {
        signal: abortController.signal
      });

    return [fetchFn, cancel];
  }

  async getCompanyInfo(companyId) {
    const result = await request.get(`${this.#baseUrl}/${companyId}/info`);

    if (!result) return null;

    return new CompanyInfoDto(result);
  }

  async getEmployees(companyId, { offset = 0, limit = 20 } = {}) {
    const searchParams = new URLSearchParams({ offset, limit });

    const employees = await request.get(
      `${this.#baseUrl}/${companyId}/employees?${searchParams}`
    );

    return employees.map((employee) => new EmployeeDto(employee));
  }

  async getPurchaseRequests(companyId, { offset = 0, limit = 20 } = {}) {
    const searchParams = new URLSearchParams({ offset, limit });

    const requests = await request.get(
      `${this.#baseUrl}/${companyId}/requests?${searchParams}`
    );

    return requests.map((request) => new CompanyPurchaseRequestDTO(request));
  }

  async saveNote(companyId, description) {
    const savedNote = await request.post(
      `${this.#baseUrl}/${companyId}/save-note`,
      { description }
    );

    return new SaveNoteDto(savedNote);
  }

  async getExtraInfo(companyId) {
    const info = await request.get(`${this.#baseUrl}/${companyId}/extra-info`);

    if (!info) return null;

    return new ExternalInfoDto(info);
  }

  async getSuggestedInfo(companyId) {
    return request.get(`${this.#baseUrl}/${companyId}/suggested-info`);
  }

  async saveSuggestedInfo(
    companyId,
    {
      actualAddress,
      fullBankName,
      correspondentAccount,
      bik,
      logo,
      description,
      country,
      region,
      postAddress,
      isHintMarkAsRead
    }
  ) {
    return request.post(`${this.#baseUrl}/${companyId}/save-suggested-info`, {
      actualAddress,
      fullBankName,
      correspondentAccount,
      bik,
      logo,
      description,
      country,
      region,
      postAddress,
      isHintMarkAsRead
    });
  }

  /**
   Fetch company needs
   @param {number} companyId
   @param {object} params
   @param {number} params.limit - Максимальное кол-во записей
   @param {number} params.offset
   @param {number} params.search - identifier или name
   @param {number} params.authorId - Id автора
   @param {number} params.requestNumber - Номер заявки
   @param {Date} params.needDate - Время потребности
   @param {string} params.status - Статус потребности
   @param {number[]} params.categoriesIds - id категорий
   @param {{id:number,value:string}[]} params.extraOptions - Дополнительные параметры
   @returns {Promise<Array>}
   */
  async getNeeds(
    companyId,
    {
      authorId,
      limit = 20,
      offset = 0,
      status,
      needDate,
      requestNumber,
      search = '',
      categoriesIds,
      extraOptions
    }
  ) {
    const query = searchQuery.generate({ limit, offset, search });

    return request.post(`${this.#baseUrl}/${companyId}/needs${query}`, {
      authorId,
      status,
      requestNumber,
      needDate,
      categoriesIds,
      extraOptions
    });
  }

  getRequestNumbersAttachedToNeeds(companyId, { search = '' }) {
    const abortController = new AbortController();
    const cancel = () => abortController.abort();

    const fetchFn = async () =>
      request.post(
        `${this.#baseUrl}/${companyId}/needs/request-numbers`,
        {
          search
        },
        {
          signal: abortController.signal
        }
      );

    return [fetchFn, cancel];
  }

  /**
   Fetch company needs extra options
   @param {number} companyId
   @returns {Promise<Array>}
   */
  async getNeedsExtraOptions(companyId) {
    return request.get(`${this.#baseUrl}/${companyId}/needs-extra-options`);
  }

  /**
   Fetch company needs extra options
   @param {number} companyId - id компании
   @param {number} optionId - id дополнительного параметра
   @param {object} params
   @param {number} params.limit - Максимальное кол-во записей
   @param {string} params.search - Значение дополнительного параметра
   @returns {Promise<Array>}
   */
  async getNeedsExtraOptionValues(
    companyId,
    optionId,
    { limit = 10, search = '' }
  ) {
    const query = searchQuery.generate({ limit, search });
    return request.get(
      `${
        this.#baseUrl
      }/${companyId}/needs-extra-options/${optionId}/values${query}`
    );
  }

  /**
   Fetch need preview by id
   @param {object} params
   @param {number} params.companyId
   @param {number} params.needId
   @returns {Promise<object>}
   */
  async getNeedPreview({ companyId, needId }) {
    return request.get(`${this.#baseUrl}/${companyId}/needs/${needId}/preview`);
  }

  /**
   Create new company's need
   @param {object} params
   @param {number} params.companyId
   @param {object} params.data
   */
  async createNeed({ companyId, data }) {
    return request.post(`${this.#baseUrl}/${companyId}/needs/create`, data);
  }

  /**
   Update company's need
   @param {object} params
   @param {number} params.companyId
   @param {number} params.needId
   @param {object} params.data
   */
  async updateNeed({ companyId, needId, data }) {
    return request.patch(
      `${this.#baseUrl}/${companyId}/needs/${needId}/update`,
      data
    );
  }

  /**
   Delete company's need
   @param {object} params
   @param {number} params.companyId
   @param {number} params.needId
   */
  async deleteNeed({ companyId, needId }) {
    return request.delete(
      `${this.#baseUrl}/${companyId}/needs/${needId}/delete`
    );
  }

  async deleteNeeds({ companyId, query }) {
    return request.post(`${this.#baseUrl}/${companyId}/needs/delete`, query);
  }

  async getNeedsAuthors({ companyId }) {
    return request.get(`${this.#baseUrl}/${companyId}/needs/authors`);
  }

  /**
   Fetch needs info for transformation into products
   @param {object} params
   @param {number} params.companyId
   @param {object} params.filter
   */
  async getNeedsToProducts({ companyId, filter }) {
    return request.post(
      `${this.#baseUrl}/${companyId}/needs/to-products`,
      filter
    );
  }

  /**
   Update employee
   @param {number} companyId
   @param {number} employeeId
   @param {object} params
   @param {string} params.email - Емейл
   @param {string} params.position - Должность
   @param {string} params.department - Отдел
   @param {string} params.visibility - Видимость сотрудника
   @param {number} params.roleId - Видимость сотрудника
   */
  async updateEmployee(
    companyId,
    employeeId,
    { department, roleId, position, email, visibility }
  ) {
    return request.put(
      `${this.#baseUrl}/${companyId}/employees/${employeeId}/update`,
      {
        data: {
          department,
          roleId,
          position,
          email,
          visibility
        }
      }
    );
  }

  async getEmployeeById(companyId, employeeId) {
    return request.get(`${this.#baseUrl}/${companyId}/employees/${employeeId}`);
  }

  async getRoles(companyId) {
    return request.get(`${this.#baseUrl}/${companyId}/roles`);
  }

  async getAssignableRoles(companyId) {
    return request.get(`${this.#baseUrl}/${companyId}/assignable-roles`);
  }

  async deleteRole({ companyId, roleId }) {
    return request.delete(
      `${this.#baseUrl}/${companyId}/roles/${roleId}/delete`
    );
  }

  async createRole({ companyId, data }) {
    return request.post(`${this.#baseUrl}/${companyId}/roles/create`, data);
  }

  async updateRole({ companyId, roleId, data }) {
    return request.patch(
      `${this.#baseUrl}/${companyId}/roles/${roleId}/update`,
      data
    );
  }

  /**
   Fetch employees by roleId
   @param {number} companyId
   @param {number} roleId
   @param {object} params
   @param {number} params.offset
   @param {number} params.limit - Максимальное кол-во записей
   @param {number} params.search - Имя пользователя
   */
  async getEmployeesByRoleId(
    companyId,
    roleId,
    { limit = 20, offset = 0, search }
  ) {
    const query = searchQuery.generate({ limit, offset, search });
    return request.get(
      `${this.#baseUrl}/${companyId}/roles/${roleId}/assignees${query}`
    );
  }
}

export const companyService = new CompanyService();
