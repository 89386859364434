import React from 'react';

import { Typography } from '@link/react-components';

import styles from './SelectionBlock.module.css';

export const SelectionBlock = ({ children, headerText }) => (
  <div className={styles.container}>
    <Typography variant="body1Sb" className={styles.header}>
      {headerText}
    </Typography>
    <div>{children}</div>
  </div>
);
