import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Icon,
  Loader,
  Modal,
  Select,
  Typography
} from '@link/react-components';
import { useActions, useAsync, useAsyncFn, useTranslate } from '@hooks';

import { useSelector } from 'react-redux';
import { getCurrentEmployeeId, getCurrentUserStatus } from '@root/storeGetters';
import { statusesService, myCompanyService } from '@api';

import { useMethods } from 'react-use';
import { pick } from 'ramda';
import { STATUSES, StatusIcon } from '@root/entities';
import cn from 'classnames';
import * as notificationActions from '@root/action-creators/emailNotification';
import * as userStatusActions from '@root/modules/userStatus/actions';
import { STATUSES_ICONS } from '@root/entities/statuses/model/constants';
import styles from './ModalChangeUserStatus.module.css';
import { statusMapper, validate } from './lib';

import { MODAL_KEY } from './constants';
import {
  createMethods,
  DEFAULT_SETTINGS,
  initialStateMethods
} from './model/actions';
import { useChangeUserStatusModal } from './useChangeUserStatusModal';

function getOptionLabel(option) {
  return option.label;
}

function renderOption(option) {
  return (
    <Typography
      variant="body1Reg"
      className={cn(styles.option, { [styles.selected]: option.selected })}>
      <span className={styles.optionContent}>
        <StatusIcon iconName={option.key} className={styles.optionIcon} />
        {option.label}
      </span>

      {option.selected && (
        <Icon
          iconName="ok"
          height={18}
          className={styles.selectOptionMark}
          width={18}
        />
      )}
    </Typography>
  );
}

export const ModalChangeUserStatus = () => {
  const userStatus = useSelector(getCurrentUserStatus);
  const currentEmployeeId = useSelector(getCurrentEmployeeId);
  const { setSoundNotification } = useActions(notificationActions);
  const [, onCloseModal] = useChangeUserStatusModal();
  const { setUserStatus } = useActions(userStatusActions);
  const t = useTranslate();
  const [statuses, setStatuses] = useState([]);

  const [initialState, setInitialState] = useState({});

  const [defaultInitialStatus, setDefaultInitialStatus] = useState(null);
  const [state, actions] = useMethods(createMethods, initialStateMethods);
  const { loading } = useAsync(async () => {
    const statuses = await statusesService.getAll();

    const viewModelStatuses = statuses.map((status) =>
      statusMapper.toStatusViewModel(status, status.key === userStatus?.value, {
        t
      })
    );
    const isNoSelected = viewModelStatuses.every((el) => !el.value.selected);

    if (isNoSelected) {
      const status = statusMapper.toStatusViewModel(
        {
          id: null,
          key: STATUSES.noStatus.value
        },
        true,
        { t }
      );

      setDefaultInitialStatus(status);

      actions.clearStatus();
      setInitialState({
        status: { id: null, key: STATUSES.noStatus.value },
        notification: DEFAULT_SETTINGS.notification
      });

      viewModelStatuses.unshift(status);
    } else {
      const selectedStatus = viewModelStatuses.find((st) => st.value.selected);

      const statusModel = {
        id: selectedStatus.value.id,
        key: selectedStatus.value.key
      };
      setInitialState({
        status: statusModel,
        notification: userStatus.notification
      });
      actions.setStatus(statusModel);
      actions.setNotification(userStatus.notification);

      setDefaultInitialStatus(selectedStatus);
      viewModelStatuses.unshift(
        statusMapper.toStatusViewModel(
          {
            id: null,
            key: STATUSES.noStatus.value
          },
          false,
          { t }
        )
      );
    }

    setStatuses(viewModelStatuses);
  }, []);

  const statusesOptions = useMemo(() => {
    if (!statuses || !state.status.key) return [];
    return statuses.map((status) =>
      statusMapper.toStatusViewModel(status, status.key === state.status.key, {
        t
      })
    );
  }, [statuses, state.status.key]);

  const onChangeStatus = useCallback((option) => {
    const pickedStatus = pick(['id', 'key'], option.value);

    actions.setNotification(STATUSES[pickedStatus.key].notificationsEnabled);

    actions.setStatus(pickedStatus);
  }, []);

  const onChangeNotification = useCallback((e) => {
    actions.setNotification(e.target.checked);
  }, []);

  const translate = (...args) =>
    t(`modules.userStatuses.modal.${args.join('.')}`);

  const [{ loading: submitLoading }, updateStatus] = useAsyncFn(
    async ({ status, notification }) => {
      const { id, key } = status;
      setSoundNotification(notification);
      if (!id) {
        await myCompanyService.deleteMyStatus();
        return null;
      }
      await myCompanyService.updateMyStatus({
        statusId: id,
        notification
      });
      return { value: key, notification };
    },
    [setSoundNotification]
  );
  const onConfirm = useCallback(async () => {
    await updateStatus({
      status: state.status,
      notification: state.notification
    });

    const newStatus = state.status.id
      ? {
          value: state.status.key,
          notification: state.notification
        }
      : null;

    setUserStatus(currentEmployeeId, newStatus);
    onCloseModal();
  }, [state, onCloseModal, updateStatus, currentEmployeeId]);

  return (
    <Modal open onClose={onCloseModal}>
      <Modal.Header>{translate('title')}</Modal.Header>

      {loading ? (
        <Loader />
      ) : (
        <>
          <Modal.Content className={styles.content}>
            <div>
              <Typography variant="body2Reg" className={styles.label}>
                {translate('statusLabel')}
              </Typography>
              <Select
                options={statusesOptions}
                denyTyping
                onSelect={onChangeStatus}
                defaultValue={defaultInitialStatus}
                getOptionLabel={getOptionLabel}
                renderOption={renderOption}
                startAdornment={
                  <Icon
                    width={18}
                    height={18}
                    iconName={STATUSES_ICONS[state.status.key]}
                  />
                }
              />
            </div>

            <Checkbox
              checked={state.notification}
              onChange={onChangeNotification}
              disabled={STATUSES[state.status.key].notificationsControlDisabled}
              label={translate('notificationsEnabled')}
            />
          </Modal.Content>
          <Modal.Footer align="right">
            <Modal.Actions>
              <Button mode="text" onClick={onCloseModal}>
                {translate('cancelBtn')}
              </Button>
              <Button
                loading={submitLoading}
                onClick={onConfirm}
                disabled={!validate(state, initialState)}>
                {translate('confirmBtn')}
              </Button>
            </Modal.Actions>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};
export const initModalChangeUserStatus = { [MODAL_KEY]: ModalChangeUserStatus };
