import React, { Component } from 'react';
import { withTranslation as translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Col, Form, FormGroup, Button, Row } from 'react-bootstrap';
import Select from 'react-select';
import { ModalContainer } from './elements';

import * as modalActions from '../../action-creators/modal';
import * as adminActions from '../../action-creators/admin';
import * as messageActions from '../../action-creators/message';

class ModalAdminChangeOwner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyOwners: {}
    };
  }

  setCompanyOwner(v, id) {
    const companyOwners = {};
    companyOwners[id] = v;
    this.setState({ companyOwners });
  }

  render() {
    const {
      t,
      hideModalDialog,
      userId,
      changeCompanyOwner,
      users,
      setWarningMessage,
      showModal,
      getAllCompanies
    } = this.props;

    const user = users.find((u) => u.get('id') === userId);
    const companies =
      user &&
      user.get('companies') &&
      user.get('companies').filter((c) => c.get('ownerUserId') === userId);
    if (companies && companies.size === 0) {
      setWarningMessage({ key: 'not_owner_of_any_company' });
      hideModalDialog();
    }
    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="adminConfirm" onHide={() => hideModalDialog()} show>
          <Modal.Header>
            <Modal.Title>
              {t('Assign owner')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              horizontal
              style={{
                whiteSpace: 'nowrap'
              }}>
              <Row style={{ marginBottom: 15 }}>
                {t('change_owner_modal_text')}
              </Row>
              <FormGroup controlId="value" style={{ marginBottom: 0 }}>
                {companies &&
                  companies.map((c) => (
                    <Row style={{ marginBottom: 15 }}>
                      <Col sm={6} className="form-modal-input-col">
                        {c.get('name')}
                      </Col>
                      <Col sm={6} className="form-modal-input-col">
                        <Select
                          placeholder={t('not selected')}
                          className="branch-select"
                          value={this.state.companyOwners[c.get('id')]}
                          onChange={(e) =>
                            this.setCompanyOwner(e.value, c.get('id'))
                          }
                          options={c
                            .get('employees')
                            .map((e) => ({
                              value: e.get('id'),
                              label: `${e.get('firstName')} ${e.get(
                                'lastName'
                              )}`
                            }))
                            .toJS()}
                          searchable={false}
                          clearable={false}
                        />
                      </Col>
                    </Row>
                  ))}
              </FormGroup>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div>
              <Button
                id="cancel"
                className="pull-left"
                onClick={() => hideModalDialog()}>
                {t('Cancel')}
              </Button>
              <Button
                id="saveButton"
                bsStyle="primary"
                disabled={!this.state.companyOwners}
                onClick={() => {
                  // changeCompanyOwner(this.state.password, onSuccess);

                  showModal('ADMIN_CONFIRM', {
                    action: changeCompanyOwner,
                    attrs: { companyId: this.state.companyOwners },
                    onSuccess: () => getAllCompanies()
                  });
                  // hideModalDialog();
                }}>
                {t('Assign')}
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

export default connect(
  (state) => ({
    company: state.get('company'),
    users: state.getIn(['admin', 'users'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog,
        showModal: modalActions.showModal,
        changeCompanyOwner: adminActions.changeCompanyOwner,
        getAllCompanies: adminActions.getAllCompanies,
        setWarningMessage: messageActions.setWarningMessage
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalAdminChangeOwner));
