import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn-lite';
import {
  Trans as Interpolate,
  withTranslation as translate
} from 'react-i18next';
import { connect } from 'react-redux';
import { Map } from 'immutable';
import { bindActionCreators } from 'redux';
import Select from 'react-select';
import {
  Modal,
  Col,
  Row,
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  HelpBlock
} from 'react-bootstrap';
import './styles/modal_theme_default.styl';
import { ModalContainer } from './elements';
import { validateEmail } from '../../utils/validateField';
import keyDebounce from '../../utils/debounce';

import * as modalActions from '../../action-creators/modal';
import * as suppliersActions from '../../action-creators/suppliers';
import * as servicesAction from '../../action-creators/services';
import * as companyAction from '../../action-creators/company';
import * as ContactsUC from '../ChatWidget/modules/contacts';
import { isLoading } from '../../storeGetters';
import StatusMessage from '../StatusMessage';
import LoaderWithMessage from '../Loader/LoaderWithMessage';

const languages = [
  { value: 'ru', label: 'Русский' },
  { value: 'en', label: 'English' }
];

const b = block('modal-container');

const viewState = {
  DEFAULT: 'DEFAULT',
  INVITE_COMPANY: 'INVITE_COMPANY',
  ADD_COMPANY: 'ADD_COMPANY',
  ADD_USER: 'ADD_USER',
  ADD_COMPANY_AND_USER: 'ADD_COMPANY_AND_USER',
  COMPANY_ALREADY_ADDED: 'COMPANY_ALREADY_ADDED',
  COMPANY_AND_USER_ALREADY_ADDED: 'COMPANY_AND_USER_ALREADY_ADDED'
};

class ModalAddSupplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      groupId: '',
      companyName: '',
      employeeName: '',
      employeeId: '',
      pristine: true,
      lockName: false,
      lockCategory: false,
      lockResponsible: false,
      isClick: false,
      responsibleUser: '',
      view: viewState.DEFAULT,
      language: props.company.get('language'),
      isAlreadyAdded: false,
      isUserAlreadyAdded: false,
      isExist: false
    };
    this.inputProps = this.inputProps.bind(this);
    this.isValidNameField = this.isValidNameField.bind(this);
    this.isMyEmail = this.isMyEmail.bind(this);
    this.onKeyUp = keyDebounce(this.onKeyUp.bind(this));
    this.onClick = this.onClick.bind(this);
  }

  static isEmailValid(value) {
    if (value !== '') {
      return validateEmail(value);
    }
    return true;
  }

  getChildContext() {
    return { b };
  }

  UNSAFE_componentWillMount() {
    const { gaPageView, getGroups, groups } = this.props;
    gaPageView('supplier_add_open');
    if (groups.size === 0) {
      getGroups();
    }
  }

  async onKeyUp() {
    const { getInfo } = this.props;
    const {
      isExist,
      isAlreadyAdded,
      isEmailCompany,
      companyName,
      employeeName,
      employeeId,
      responsibleUser,
      isHiddenContact
    } = await getInfo(this.getProperty('email'), 'supplier');

    const isEmailValid = ModalAddSupplier.isEmailValid(
      this.getProperty('email')
    );
    const isMyEmail = this.isMyEmail();

    if (this.getProperty('email') && isEmailValid && !isMyEmail) {
      // Case when email isn't register
      if (!isExist) {
        this.setState({
          view: viewState.INVITE_COMPANY,
          lockCategory: false,
          lockResponsible: false,
          isUserAlreadyAdded: false
        });
      }
      // Case when company exist, but hasn't been added
      if (isExist && !isAlreadyAdded && isEmailCompany) {
        this.setState({
          view: viewState.ADD_COMPANY,
          lockCategory: false,
          lockResponsible: false,
          isUserAlreadyAdded: false
        });
      }
      // Case add user to contact list if company already added, but user is hidden
      if (isExist && isAlreadyAdded && !isEmailCompany && isHiddenContact) {
        this.setState({
          view: viewState.ADD_USER,
          lockCategory: true,
          lockResponsible: true,
          isUserAlreadyAdded: false
        });
      }
      // Case when email belongs to user which already added to contact
      if (isExist && isAlreadyAdded && !isEmailCompany && !isHiddenContact) {
        this.setState({ view: viewState.DEFAULT, isUserAlreadyAdded: true });
      }
      // Case when email belongs to user which isn't in the contact list
      if (isExist && !isAlreadyAdded && !isEmailCompany) {
        this.setState({
          view: viewState.ADD_COMPANY_AND_USER,
          lockCategory: false,
          lockResponsible: false,
          isUserAlreadyAdded: false
        });
      }
      // Case when email belongs to company which already added
      if (isExist && isAlreadyAdded && isEmailCompany) {
        this.setState({
          view: viewState.COMPANY_ALREADY_ADDED,
          lockCategory: true,
          lockResponsible: true,
          isUserAlreadyAdded: false
        });
      }
    } else {
      this.setState({ view: viewState.DEFAULT, isUserAlreadyAdded: false });
    }
    this.setState({
      isExist,
      isAlreadyAdded,
      companyName: companyName || '',
      employeeName: employeeName || '',
      employeeId: employeeId || '',
      responsibleUser: responsibleUser || ''
    });
  }

  getProperty(field) {
    return this.state[field];
  }

  onClick() {
    this.setState({ isClick: true }, () =>
      setTimeout(() => this.setState({ isClick: false }), 10000)
    );
  }

  isValidNameField() {
    return this.state.email.length < 1;
  }

  isMyEmail() {
    const { company } = this.props;
    const currentCompanyEmail = company.get('email');
    const currentCompanyUsers = company.get('listOfUsers')
      ? company.get('listOfUsers').toJS()
      : [];
    const currentCompanyUsersEmails = currentCompanyUsers
      .filter((employee) => !employee.deletedAt)
      .map(({ email = '' }) => email);

    const allCompanyEmails = [
      currentCompanyEmail,
      ...currentCompanyUsersEmails
    ];
    return allCompanyEmails.includes(this.state.email);
  }

  asterisk() {
    // eslint-disable-line
    return <span style={{ color: 'red' }}>*</span>;
  }

  inputProps(field) {
    return {
      value: this.state[field],
      onChange: (e) => {
        if (field === 'email') {
          return this.setState({ [field]: e.target.value.trim() });
        }
        return this.setState({ [field]: e.target.value });
      }
    };
  }

  showValidMailInfo() {
    const { t } = this.props;
    const { isUserAlreadyAdded } = this.state;
    if (!ModalAddSupplier.isEmailValid(this.getProperty('email'))) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Wrong email format')}
        </HelpBlock>
      );
    }
    if (this.isMyEmail()) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Can not invite yourself')}
        </HelpBlock>
      );
    }
    if (isUserAlreadyAdded) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t(
            'The company employee has already been added to your contact list'
          )}
        </HelpBlock>
      );
    }
    return null;
  }

  havePermissionToUpdate(user) {
    const permissions = user.get('permissions');

    return (
      permissions.getIn(['supplierOrders', 'update']) &&
      permissions.getIn(['suppliers', 'update'])
    );
  }

  getEmployees() {
    const { company } = this.props;
    return company
      .get('listOfUsers')
      .filter(this.havePermissionToUpdate)
      .filter((user) => user.get('deletedAt') === null)
      .map((i) => ({
        value: i.get('id'),
        label: `${i.get('lastName')} ${i.get('firstName')}`
      }))
      .toList()
      .toJS();
  }

  renderField = (field) => {
    const { t, groups, company } = this.props;
    const { lockCategory, lockResponsible, isUserAlreadyAdded } = this.state;

    const extGroups = groups.unshift(
      new Map({ id: 0, name: t('All suppliers') })
    );
    const category = extGroups
      .filter((i) => !i.get('service'))
      .map((group) => ({ value: group.get('id'), label: group.get('name') }));

    switch (field) {
      case 'email':
        return (
          <FormGroup
            controlId="email"
            validationState={
              (this.isValidNameField() ||
                this.isMyEmail() ||
                isUserAlreadyAdded ||
                !ModalAddSupplier.isEmailValid(
                  this.getProperty('email') ||
                    this.state.email === company.get('email')
                )) &&
              !this.state.pristine
                ? 'error'
                : null
            }
            style={{ overflow: 'hidden' }}>
            <ControlLabel>
              {`${t('E-mail')} (${t('only latin')}) `}
              {this.asterisk()}
            </ControlLabel>
            <h6 className="pull-right">
              <small>
                {this.state.email.length}
                /60
              </small>
            </h6>
            <FormControl
              type="text"
              {...this.inputProps('email')}
              placeholder="example@email.com"
              maxLength="60"
              onKeyUp={this.onKeyUp}
              onKeyDown={() => {
                if (this.state.pristine) {
                  this.setState({ pristine: false });
                }
              }}
            />
            {this.isValidNameField() && !this.state.pristine && (
              <HelpBlock style={{ marginBottom: 0 }}>
                {t('Required fields empty')}
              </HelpBlock>
            )}
            {this.showValidMailInfo()}
          </FormGroup>
        );
      case 'category':
        return (
          <FormGroup controlId="category">
            <ControlLabel>{t('Suppliers groups')}</ControlLabel>
            <Select
              id="group-select"
              value={this.state.groupId}
              onChange={(e) => this.setState({ groupId: e.value })}
              options={category.toJS()}
              placeholder={t('All suppliers')}
              clearable={false}
              searchable={false}
              disabled={lockCategory}
            />
          </FormGroup>
        );
      case 'responsible':
        return (
          <FormGroup controlId="responsible">
            <ControlLabel>{t('Responsible for supplier')}</ControlLabel>
            <Select
              id="responsible-select"
              value={this.state.responsibleUser}
              onChange={(e) => this.setState({ responsibleUser: e.value })}
              options={this.getEmployees()}
              placeholder={t('not selected')}
              clearable={false}
              searchable={false}
              disabled={lockResponsible}
            />
          </FormGroup>
        );
      case 'language':
        return (
          <FormGroup controlId="language">
            <ControlLabel>{t('invite_lang')}</ControlLabel>
            <Select
              id="language-select"
              value={this.state.language}
              onChange={({ value }) => this.setState({ language: value })}
              options={languages}
              clearable={false}
              searchable={false}
            />
          </FormGroup>
        );
      default:
        return null;
    }
  };

  renderEmailFieldWithInfo = () => {
    const { isExist, isAlreadyAdded } = this.state;
    let statusData = {};

    if (isExist && !isAlreadyAdded) {
      statusData = {
        text: 'Was_found',
        icon: 'ok'
      };
    } else if (isExist && isAlreadyAdded) {
      statusData = {
        text: 'Added',
        icon: 'ok'
      };
    } else if (!isExist) {
      statusData = {
        text: 'Not_found',
        color: 'gray'
      };
    }

    return (
      <Row>
        <Col xs={8}>{this.renderField('email')}</Col>
        <Col xs={4}>
          <div style={{ marginTop: '38px' }}>
            <StatusMessage {...statusData} />
          </div>
        </Col>
      </Row>
    );
  };

  renderLoading = () => (
    <>
      <Row>
        <Col md={8}>{this.renderField('email')}</Col>
      </Row>
      {this.renderHr()}
      <div style={{ marginBottom: '24px' }}>
        <LoaderWithMessage text="Wait loading" />
      </div>
    </>
  );

  renderTextMsg = () => {
    const { t } = this.props;
    const { view, companyName, employeeName } = this.state;
    let text = '';

    if (view === viewState.ADD_USER) {
      return (
        <div style={{ marginBottom: '22px' }}>
          <Interpolate
            i18nKey="Company already added into suppliers, add employee"
            values={{
              employeeName
            }}
          />
        </div>
      );
    }
    if (view === viewState.ADD_COMPANY_AND_USER) {
      return (
        <div style={{ marginBottom: '22px' }}>
          <Interpolate
            i18nKey="Adding company to suppliers and add employee"
            values={{
              employeeName,
              companyName
            }}
          />
        </div>
      );
    }
    if (view === viewState.INVITE_COMPANY) {
      text =
        'The company is not registered in the Service, clicking on the Invite button, select language';
    }
    if (view === viewState.COMPANY_ALREADY_ADDED) {
      text = 'Supplier company already added';
    }
    return <div style={{ marginBottom: '22px' }}>{t(text)}</div>;
  };

  renderHr = (style) => <hr style={{ marginTop: '0px', ...style }} />;

  renderLabel = (text, style) => {
    const { t } = this.props;

    return (
      <div style={{ fontWeight: 500, marginBottom: '10px', ...style }}>
        {t(text)}
      </div>
    );
  };

  renderCompanyAlreadyAddedCase = () => {
    const { companyName } = this.state;

    return (
      <>
        {this.renderEmailFieldWithInfo()}
        <Row>
          <Col md={12}>{this.renderField('category')}</Col>
        </Row>
        <Row>
          <Col md={12}>{this.renderField('responsible')}</Col>
        </Row>
        {this.renderHr()}
        {this.renderLabel('Company')}
        <div>{companyName}</div>
        {this.renderHr({ marginTop: '20px' })}
        {this.renderTextMsg()}
      </>
    );
  };

  renderAddUserCase = () => {
    const { companyName, employeeName } = this.state;

    return (
      <>
        {this.renderEmailFieldWithInfo()}
        <Row>
          <Col md={12}>{this.renderField('category')}</Col>
        </Row>
        <Row>
          <Col md={12}>{this.renderField('responsible')}</Col>
        </Row>
        {this.renderHr()}
        {this.renderLabel('Company')}
        <div>{companyName}</div>
        {this.renderLabel('Employee', { marginTop: '25px' })}
        <div>{employeeName}</div>
        {this.renderHr({ marginTop: '20px' })}
        {this.renderTextMsg()}
      </>
    );
  };

  renderAddCompanyAndUserCase = () => {
    const { companyName, employeeName } = this.state;

    return (
      <>
        {this.renderEmailFieldWithInfo()}
        <Row>
          <Col md={12}>{this.renderField('category')}</Col>
        </Row>
        <Row>
          <Col md={12}>{this.renderField('responsible')}</Col>
        </Row>
        {this.renderHr()}
        {this.renderLabel('Company')}
        <div>{companyName}</div>
        {this.renderLabel('Employee', { marginTop: '25px' })}
        <div>{employeeName}</div>
        {this.renderHr({ marginTop: '20px' })}
        {this.renderTextMsg()}
      </>
    );
  };

  renderDefaultCase = () => (
    <Row>
      <Col xs={8}>{this.renderField('email')}</Col>
    </Row>
  );

  renderInviteCompany = () => (
    <>
      {this.renderEmailFieldWithInfo()}
      <Row>
        <Col md={12}>{this.renderField('category')}</Col>
      </Row>
      <Row>
        <Col md={12}>{this.renderField('responsible')}</Col>
      </Row>
      {this.renderHr()}
      {this.renderTextMsg()}
      <Row>
        <Col md={12}>{this.renderField('language')}</Col>
      </Row>
    </>
  );

  renderAddCompanyCase = () => (
    <>
      {this.renderEmailFieldWithInfo()}
      <Row>
        <Col md={12}>{this.renderField('category')}</Col>
      </Row>
      <Row>
        <Col md={12}>{this.renderField('responsible')}</Col>
      </Row>
    </>
  );

  renderFormFields = () => {
    const { loading } = this.props;
    const { view } = this.state;

    if (loading) return this.renderLoading();

    switch (view) {
      case viewState.DEFAULT:
        return this.renderDefaultCase();
      case viewState.INVITE_COMPANY:
        return this.renderInviteCompany();
      case viewState.COMPANY_ALREADY_ADDED:
        return this.renderCompanyAlreadyAddedCase();
      case viewState.ADD_USER:
        return this.renderAddUserCase();
      case viewState.ADD_COMPANY_AND_USER:
        return this.renderAddCompanyAndUserCase();
      case viewState.ADD_COMPANY:
        return this.renderAddCompanyCase();
      default:
        return null;
    }
  };

  onSubmit = () => {
    const { badgeSocket: socket } = this.context;
    const {
      hideModalDialog,
      addSupplier,
      gaPageView,
      gaSend,
      user,
      unhideContactGeneral
    } = this.props;
    const { view, employeeId, employeeName, companyName } = this.state;

    if (view === viewState.ADD_USER) {
      const successMessage = {
        key: 'Employee from company added to your contact list',
        params: { companyName, employeeName }
      };
      return unhideContactGeneral(employeeId, successMessage).then(() =>
        hideModalDialog()
      );
    }

    this.onClick();
    gaSend({
      category: 'Customer',
      action: 'add_supplier',
      label: user.get('email')
    });
    return addSupplier(this.state).then(async () => {
      gaPageView('supplier_add_success');
      socket.emit(
        'updateCompanyBadges',
        JSON.stringify({ email: this.state.email })
      );
      hideModalDialog();
    });
  };

  render() {
    const { t, captionKey, hideModalDialog, gaPageView, company } = this.props;

    return (
      <ModalContainer mods={{ theme: 'default' }}>
        <Modal id="addSupplier" onHide={() => hideModalDialog()} show>
          <Modal.Header>
            <Modal.Title>
              {t(captionKey)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>{this.renderFormFields()}</Form>
            {this.asterisk()}
            {` ${t('Required fields')}`}
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                gaPageView('supplier_add_cancel');
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveButton"
              bsStyle="primary"
              disabled={
                this.state.isClick ||
                this.isValidNameField() ||
                this.isMyEmail() ||
                !ModalAddSupplier.isEmailValid(this.getProperty('email')) ||
                this.state.email === company.get('email') ||
                this.state.view === viewState.COMPANY_ALREADY_ADDED ||
                this.state.isUserAlreadyAdded
              }
              onClick={this.onSubmit}>
              {t(
                this.state.view === viewState.INVITE_COMPANY
                  ? 'Button invite'
                  : 'Button add'
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </ModalContainer>
    );
  }
}

ModalAddSupplier.contextTypes = {
  badgeSocket: PropTypes.object
};

ModalAddSupplier.propTypes = {
  t: PropTypes.func.isRequired
};

ModalAddSupplier.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    groups: state.getIn(['suppliers', 'groups']),
    user: state.getIn(['user', 'user']),
    company: state.get('company'),
    loading: isLoading('getInfoByEmail', state)
  }),
  (dispatch) =>
    bindActionCreators(
      {
        addSupplier: suppliersActions.addSupplier,
        getGroups: suppliersActions.getGroups,
        gaPageView: servicesAction.gaPageView,
        gaSend: servicesAction.gaSend,
        hideModalDialog: modalActions.hideModalDialog,
        getACompanyName: companyAction.getACompanyName,
        getInfo: companyAction.getInfoByEmail,
        unhideContactGeneral: ContactsUC.hideContactGeneral
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalAddSupplier));
