import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation as translate } from 'react-i18next';
import { Modal, Button, ControlLabel, Form } from 'react-bootstrap';
import Select from 'react-select';

import './styles/modal_theme_default.styl';

import { ModalContainer } from './elements';
import * as modalActions from '../../action-creators/modal';

class ModalResponsibleUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userId: null
    };
    this.hideModal = this.hideModal.bind(this);
    this.setResponsible = this.setResponsible.bind(this);
    this.submit = this.submit.bind(this);
  }

  async UNSAFE_componentWillMount() {
    const { userId } = this.props;
    if (userId) {
      this.setState({ userId });
    }
  }

  setResponsible(e) {
    this.setState({ userId: e.target.value });
  }

  hideModal() {
    this.props.hideModalDialog();
    this.props.onClosed();
  }

  submit() {
    const {
      hideModalDialog,
      onSubmited,
      submitAction,
      callback = true,
      customerId
    } = this.props;
    if (!callback) {
      submitAction(customerId, this.state.userId);
      return hideModalDialog();
    }
    return submitAction(customerId, this.state.userId).then(() => {
      hideModalDialog();
      onSubmited();
    });
  }

  render() {
    const {
      t,
      title = 'Change responsible for customer',
      text = 'Set responsible user modal text',
      label = 'Responsible for customer',
      saveButtonText = 'Appoint',
      type = 'customer',
      company
    } = this.props;

    let employeesSelectList = [];
    if (type === 'customer') {
      employeesSelectList = company
        .get('listOfUsers')
        .filter(
          (i) =>
            !i.get('deletedAt') &&
            i.getIn(['permissions', 'customerOrders', 'update']) &&
            i.getIn(['permissions', 'customers', 'update'])
        )
        .map((i) => ({
          value: i.get('id'),
          label: `${i.get('lastName')} ${i.get('firstName')}`
        }))
        .toList()
        .toJS();
    } else {
      employeesSelectList = company
        .get('listOfUsers')
        .filter(
          (i) =>
            !i.get('deletedAt') &&
            i.getIn(['permissions', 'supplierOrders', 'update']) &&
            i.getIn(['permissions', 'suppliers', 'update'])
        )
        .map((i) => ({
          value: i.get('id'),
          label: `${i.get('lastName')} ${i.get('firstName')}`
        }))
        .toList()
        .toJS();
    }

    employeesSelectList.unshift({ value: null, label: t('not selected') });

    return (
      <Modal id="setResponsible" onHide={this.hideModal} show>
        <ModalContainer mods={{ type: 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t(title)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => this.hideModal()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div controlId="comment">
                <ControlLabel
                  style={{
                    fontWeight: 'normal',
                    fontSize: '14px'
                  }}>
                  {t(text)}
                </ControlLabel>
                <br />
                <br />
                <div style={{ width: 300 }}>
                  <div className="select-label">{t(label)}</div>
                  <Select
                    id="responsible-select"
                    value={this.state.userId}
                    onChange={(e) => this.setState({ userId: e.value })}
                    options={employeesSelectList}
                    placeholder={t('not selected')}
                    clearable={false}
                    searchable={false}
                  />
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button id="cancel" className="pull-left" onClick={this.hideModal}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveOrderComment"
              bsStyle="primary"
              onClick={this.submit}>
              {t(saveButtonText)}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalResponsibleUser.propTypes = {
  t: PropTypes.func.isRequired,
  hideModalDialog: PropTypes.func.isRequired,
  onClosed: PropTypes.func
};

ModalResponsibleUser.childContextTypes = {
  b: PropTypes.func
};

ModalResponsibleUser.defaultProps = {
  onClosed: () => {}
};

export default connect(
  (state) => ({
    company: state.get('company')
  }),
  (dispatch) =>
    bindActionCreators(
      {
        showModal: modalActions.showModal,
        hideModalDialog: modalActions.hideModalDialog
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalResponsibleUser));
