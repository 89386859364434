import React from 'react';

export const Rejected = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.7418 6.98232L6.9418 11.7823M6.9418 6.98232L11.7418 11.7823M17.3418 9.38232C17.3418 13.8006 13.7601 17.3823 9.3418 17.3823C4.92352 17.3823 1.3418 13.8006 1.3418 9.38232C1.3418 4.96405 4.92352 1.38232 9.3418 1.38232C13.7601 1.38232 17.3418 4.96405 17.3418 9.38232Z"
      stroke="#DD1E2A"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
