import { useCallback, useEffect, useState } from 'react';

export function useScrollTo(deps = []) {
  const [cursor, setCursor] = useState(null);
  const [options, setOptions] = useState();

  useEffect(() => {
    if (cursor) {
      const $target = cursor.scrollIntoView
        ? cursor
        : document.getElementById(cursor);

      if ($target) {
        $target.scrollIntoView(options);
      } else {
        console.error('No such element for scroll with html id: ', cursor);
      }

      // Fixme: Find out solve when clear cursor
      const cursorTimer = setTimeout(() => setCursor(null), 3000);
      return () => {
        if (cursorTimer) {
          clearTimeout(cursorTimer);
        }
      };
    }
  }, [cursor, ...deps]);

  const scrollTo = useCallback((id, params = undefined) => {
    setCursor(id);

    if (params) {
      setOptions(params);
    }
  }, []);

  return {
    scrollTo
  };
}
