import {
  addDays,
  getHours,
  getMinutes,
  getSeconds,
  isValid,
  add,
  startOfDay
} from 'date-fns';

export function dateToMidnight(date = null) {
  return date
    ? new Date(new Date(date).setHours(0, 0, 0, 0))
    : new Date(new Date().setHours(0, 0, 0, 0));
}

export function compareTwoDates(responseEndDate) {
  const responseEndDateNumber = dateToMidnight(responseEndDate).getTime();
  return responseEndDateNumber > Date.now()
    ? responseEndDateNumber
    : Date.now();
}

export function translate(t, text, options) {
  return t(`purchaseRequest.extendRespondEndDateModal.${text}`, options);
}

export function canUpdateRespondEndDate(minDate, responseEndDate) {
  return minDate <= responseEndDate;
}

export function createTomorrow() {
  return addDays(dateToMidnight(), +1);
}

export const canBeUpdated = (responseEndDate) => {
  if (!isValid(responseEndDate)) return false;
  return createTomorrow().getTime() <= new Date(responseEndDate).getTime();
};

export const generateDefaultDate = (date) =>
  isValid(date) ? date : createTomorrow();

export const buildResponseEndDate = (endDate, endTime) => {
  const dayStart = startOfDay(endDate);

  const hours = getHours(endTime);
  const minutes = getMinutes(endTime);
  const seconds = getSeconds(endTime);

  return add(dayStart, { hours, minutes, seconds });
};
