import { INPUT_TYPES, MODE } from '../constants';

export const initialState = {
  file: '',
  error: null,
  mode: MODE.INPUT
};

export const reducer = (state, action) => {
  switch (action.type) {
    case INPUT_TYPES.LOADING: {
      return { ...state, mode: MODE.LOADING };
    }
    case INPUT_TYPES.SUCCESS: {
      return { ...state, mode: MODE.VIEW, error: null };
    }
    case INPUT_TYPES.ERROR: {
      return {
        ...state,
        mode: MODE.ERROR,
        error: action.payload
      };
    }
    case INPUT_TYPES.LOAD_FILE_SUCCESS: {
      return {
        ...state,
        file: action.payload,
        mode: MODE.VIEW,
        error: null
      };
    }
    case INPUT_TYPES.LOAD_FILE_ERROR: {
      return {
        ...state,
        mode: MODE.ERROR,
        file: action.payload.file,
        error: action.payload.error
      };
    }
    default:
      return state;
  }
};
