@import 'react-toastify/dist/ReactToastify.css';

:global(:root) {
  // TODO удалить !important после исправление очередности загрузки стилей на клиенте
  --toastify-font-family: var(--font) !important;
  --toastify-color-light: var(--bg-toast-color) !important;
  --toastify-color-dark: var(--bg-toast-color) !important;
  --toastify-color-info: var(--bg-toast-color) !important;
  --toastify-color-success: var(--bg-toast-color) !important;
  --toastify-color-warning: var(--bg-toast-color) !important;
  --toastify-color-error: var(--bg-toast-color) !important;
  --toastify-toast-width: max-content !important;
  --toastify-text-color-dark: var(--color-white) !important;
  --toastify-toast-min-height: 50px !important;
}

.CloseButton {
  margin: auto 0;

  > svg {
    > path {
      stroke: var(--color-gray-100);
    }
  }
}

.Text {
  display: inline;
}

.toast {
  cursor: pointer;
}

.toast.notInteractive {
  cursor: default;
}
