import React from 'react';
import { Typography } from '@link/react-components';
import styles from './ReliabilityMeter.module.css';

export function ReliabilityMeter({ prosScore, consScore }) {
  return (
    <div className={styles.reliabilityMeter}>
      <Typography variant="body1Med" className={styles.scale}>
        <span className={styles.scaleValue}>100</span>
        <span className={styles.scaleZero}>0</span>
        <span className={styles.scaleValue}>-100</span>
      </Typography>
      <div className={styles.meter}>
        <div className={styles.pros}>
          {prosScore > 0 && (
            <div
              className={styles.prosFilled}
              style={{ width: `${prosScore}%` }}>
              <Typography className={styles.score} variant="body1Med">
                {prosScore}
              </Typography>
            </div>
          )}
        </div>
        <div className={styles.zero} />
        <div className={styles.cons}>
          {consScore > 0 && (
            <div
              className={styles.consFilled}
              style={{ width: `${consScore}%` }}>
              <Typography className={styles.score} variant="body1Med">
                {consScore > 0 && `-${consScore}`}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
