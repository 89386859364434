import { nanoid } from 'nanoid';
import { hasPath, path } from 'ramda';
import { isNilOrEmpty } from '../../utils/utils';

import {
  draft,
  rework
} from '../../presenters/sidebars/PurchaseRequestResponsesSidebar/status';

const EMPTY_ARRAY = [];

export const genId = (size = 7) => nanoid(size);

export const transformHLink = ({ id = genId(7), text, url }) => ({
  id,
  name: text,
  url
});

export const getStatusForSupplier = (request) => {
  const status = path(['responded', 'status'], request);

  if (!status) return 'none';

  return status === 'sent' ? 'sent-as-supplier' : status;
};

export const getInitProductsByRequest = (request) =>
  request.products ?? EMPTY_ARRAY;

export const hasResponse = (request) =>
  hasPath(['responded', 'products'], request);
export const getResponseProducts = (request) =>
  path(['responded', 'products'], request);

export const prepareInitProducts = (product) => ({
  ...product,
  initPrice: product?.price || '',
  price: ''
});

export const getResponseComment = (request) =>
  path(['responded', 'comment'], request) ?? '';

export const makeBtnSmallIcon = ({ name, icon, onClick }) => ({
  name,
  icon,
  onClick
});

export function isProductsFilled(products, partResponseAllowed = false) {
  if (partResponseAllowed) {
    return products.some((item) => item.count && item.price);
  }

  return products.every((item) => item.count && item.price);
}

export function isProductsValid(products) {
  return !products.some(
    (item) =>
      (isNilOrEmpty(item.count) && item.price) ||
      (item.count && isNilOrEmpty(item.price))
  );
}
export const responseEndDate = (request) => path(['responseEndDate'], request);
export const responseEndTime = (request) =>
  new Date(responseEndDate(request)).getUTCHours();
export const hasResponded = (request) =>
  hasPath(['responded', 'status'], request);

export const isStatusDraft = (request) =>
  [draft, rework].includes(getStatusForSupplier(request));
export const isEndRespondTime = (request) => {
  const endDate = responseEndDate(request);
  return endDate
    ? new Date() >
        new Date(new Date(endDate).setHours(responseEndTime(request)))
    : false;
};

export const canRespond = (request) =>
  (!hasResponded(request) || isStatusDraft(request)) &&
  !isEndRespondTime(request);
