import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation as translate } from 'react-i18next';
import { isEmpty } from 'ramda';

import { disableTag } from '../messages';
import ContactItem from '../../list/contacts/item';
import * as useCase from '../../../useCases';
import BtnClose from './btnClose';
import NoResults from './noResults';

const CWASContacts = ({ t, contacts = [], tag, handlerContactList }) => {
  if (contacts === null) return null;
  if (isEmpty(contacts)) {
    return (
      <>
        <div className="cws-list-search__divider">
          <div className="cws-list-search__divider-text">
            {t(`chat-widget.advanced-search.hashtags.list.${tag}`)}
          </div>
          <div className="cws-list-search__divider-btn">
            <BtnClose onClick={() => disableTag.emit(tag)} />
          </div>
        </div>
        <NoResults />
      </>
    );
  }

  return (
    <>
      <div className="cws-list-search__divider">
        <div className="cws-list-search__divider-text">
          {t(`chat-widget.advanced-search.hashtags.list.${tag}`)}
        </div>
        <div className="cws-list-search__divider-btn">
          <BtnClose onClick={() => disableTag.emit(tag)} />
        </div>
      </div>
      {contacts.map((contact) => (
        <ContactItem
          {...contact}
          handler={(itemMenu) => handlerContactList(itemMenu, contact)}
        />
      ))}
    </>
  );
};

export default connect(null, (dispatch) =>
  bindActionCreators(useCase, dispatch)
)(translate()(CWASContacts));
