import React from 'react';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { Trans as Interpolate } from 'react-i18next';
import {
  generateLinkProps,
  getForwardedMessageText,
  getI18nKey,
  getUserName,
  initShowProfile,
  isNeedTranslate,
  isRenderComponent,
  getComponent
} from './dataType';

const LinkComponent = ({ to, text, onClick }) => (
  <Link to={to} onClick={onClick}>
    {text}
  </Link>
);

const Message = ({ vm }) => {
  if (isRenderComponent(vm)) {
    return <div className="forward-msgs-text-content">{getComponent(vm)}</div>;
  }

  // Draw translated message
  if (isNeedTranslate(vm)) {
    return (
      <div className="forward-msgs-text-content" onClick={initShowProfile(vm)}>
        <Interpolate
          i18nKey={getI18nKey(vm)}
          values={{
            userName: getUserName(vm)
          }}
          components={{
            link: <LinkComponent {...generateLinkProps(vm)} />
          }}
        />
      </div>
    );
  }

  return (
    <p
      className="forward-msgs-text-content"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(getForwardedMessageText(vm))
      }} // eslint-disable-line
      onClick={initShowProfile(vm)}
    />
  );
};

export default Message;
