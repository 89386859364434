import React from 'react';
import { Typography } from '@link/react-components';
import { useTranslate } from '@hooks';
import { useVideoChatContext } from '../../../../../../hooks';
import { translate } from '../../../../Lobby.helpers';
import c from './Hint.module.css';

export const Hint = ({ className }) => {
  const t = useTranslate();
  const { canUseCamera, canUseMicrophone } = useVideoChatContext();

  const renderHint = () => {
    if (!canUseCamera && !canUseMicrophone) {
      return (
        <Typography className={c.error} variant="body1Reg" align="center">
          {translate(t, 'errors.no-camera-and-microphone')}
        </Typography>
      );
    }

    if (!canUseCamera) {
      return (
        <Typography className={c.error} variant="body1Reg" align="center">
          {translate(t, 'errors.no-camera')}
        </Typography>
      );
    }

    if (!canUseMicrophone) {
      return (
        <Typography className={c.error} variant="body1Reg" align="center">
          {translate(t, 'errors.no-microphone')}
        </Typography>
      );
    }
  };

  return <div className={className}>{renderHint()}</div>;
};
