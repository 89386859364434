import React from 'react';
import cn from 'classnames';

import { useTranslate } from '@hooks';
import { Icon, Tooltip, Typography } from '@link/react-components';
import { useSelector } from 'react-redux';
import { routeType } from '@root/pages/constructor/builder/components/constants';
import {
  isAgreementType,
  isPublicationType
} from '@root/pages/constructor/builder/components/helpers';
import { usePermissions } from '@root/modules/permissions';
import styles from './UserCard.module.css';
import { convertUrl } from '../../../../ChatWidget/modules/files/utils';
import * as getters from '../../../../ChatWidget/modules/constructor/getters';

const requestWarningAssignedUserReason = Object.freeze({
  noReadPermissions: 'noViewedPermissions',
  noApprovePermissions: 'noApprovePermission',
  noWorkWithRequestPermissions: 'noWorkWithRequestPermissions'
});

const responseWarningAssignedUserReason = Object.freeze({
  noReadPermissions: 'noViewedPermissions',
  noApprovePermissions: 'noApprovePermission'
});

function PermissionsBlock({ reason }) {
  const t = useTranslate();
  const builderType = useSelector(getters.getBuilderType);

  const translate = (...args) => t(`constructor.tooltip.${args.join('.')}`);

  const title = (() => {
    if (builderType === routeType.request) {
      const translateRequestTooltip = translate.bind(
        undefined,
        routeType.request
      );
      switch (reason) {
        case requestWarningAssignedUserReason.noWorkWithRequestPermissions:
          return translateRequestTooltip(
            requestWarningAssignedUserReason.noWorkWithRequestPermissions
          );
        case requestWarningAssignedUserReason.noApprovePermissions:
          return translateRequestTooltip(
            requestWarningAssignedUserReason.noApprovePermissions
          );
        case requestWarningAssignedUserReason.noReadPermissions:
          return translateRequestTooltip(
            requestWarningAssignedUserReason.noReadPermissions
          );
        default:
          return '';
      }
    }

    if (builderType === routeType.response) {
      const translateResponseTooltip = translate.bind(
        undefined,
        routeType.response
      );
      switch (reason) {
        case responseWarningAssignedUserReason.noApprovePermissions:
          return translateResponseTooltip(
            responseWarningAssignedUserReason.noApprovePermissions
          );
        case responseWarningAssignedUserReason.noReadPermissions:
          return translateResponseTooltip(
            responseWarningAssignedUserReason.noReadPermissions
          );
        default:
          return '';
      }
    }
    return '';
  })();

  return (
    <Tooltip title={title} maxWidth={144} align="left" placement="left">
      <div className={styles.permissionsContainer}>
        <Icon
          iconName="alert-triangle"
          width={18}
          height={18}
          className={styles.alertIcon}
        />
        <Typography variant="body2Med" className={styles.permissionText}>
          {t('constructor.noPermissions')}
        </Typography>
      </div>
    </Tooltip>
  );
}

const RightBlock = ({ isDisabled, reason }) => {
  const t = useTranslate();
  if (isDisabled) {
    return (
      <Typography
        variant="body2Med"
        className={cn(styles.disabledTextBlock, styles.disabledText)}>
        {t('constructor.alreadyAssigned')}
      </Typography>
    );
  }

  if (!reason) return null;

  return <PermissionsBlock reason={reason} />;
};

export const UserCard = ({
  isActive = false,
  setSelected,
  isDisabled,
  user
}) => {
  const builderType = useSelector(getters.getBuilderType);
  const permissions = usePermissions();

  const rejectReason = (() => {
    if (builderType === routeType.request) {
      if (
        isPublicationType(user.currentStepType) &&
        !permissions.haveRequestsEditPermissions(user.permissions)
      ) {
        return requestWarningAssignedUserReason.noWorkWithRequestPermissions;
      }
      if (
        isAgreementType(user.currentStepType) &&
        !permissions.haveRequestsWithConfirmationCanExecutePermissions(
          user.permissions
        )
      )
        return requestWarningAssignedUserReason.noApprovePermissions;
      if (!permissions.haveRequestsReadPermissions(user.permissions)) {
        return requestWarningAssignedUserReason.noReadPermissions;
      }
      return null;
    }

    if (builderType === routeType.response) {
      if (
        isAgreementType(user.currentStepType) &&
        !permissions.haveResponsesWithConfirmationCanExecutePermissions(
          user.permissions
        )
      )
        return responseWarningAssignedUserReason.noApprovePermissions;
      if (!permissions.haveRequestsReadPermissions(user.permissions)) {
        return responseWarningAssignedUserReason.noReadPermissions;
      }
      return null;
    }

    return null;
  })();

  const onSelect = () => {
    if (isDisabled) return;

    setSelected(user);
  };

  return (
    <div className={styles.wrapper} onClick={onSelect}>
      <div
        className={cn(styles.content, {
          [styles.active]: isActive
        })}>
        <div
          className={cn(styles.avatar, {
            [styles.avatarDisabled]: isDisabled
          })}>
          <img src={convertUrl(user.avatar) || '/img/user.svg'} alt="avatar" />
        </div>
        <Typography
          variant="body1Med"
          className={cn(styles.title, {
            [styles.disabledText]: isDisabled
          })}>
          {`${user.lastName} ${user.firstName}`}
        </Typography>

        <div className={styles.rightBlock}>
          <RightBlock isDisabled={isDisabled} reason={rejectReason} />
        </div>
      </div>
      {isActive && <div className={styles.badge} />}
    </div>
  );
};
