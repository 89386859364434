import React from 'react';
import DOMPurify from 'dompurify';
import { pipe, prop } from 'ramda';
import linkifyHtml from 'linkifyjs/html';
import { replaceEmojiToMarkup } from '../../../utils';

const sanitizeDOM = (text) =>
  DOMPurify.sanitize(text, {
    ADD_ATTR: ['target']
  });

export const prepareMsg = (field) =>
  pipe(prop(field), linkifyHtml, replaceEmojiToMarkup, sanitizeDOM);

export const renderMessage = (message, field) => {
  if (typeof message[field] === 'string') {
    return (
      <div dangerouslySetInnerHTML={{ __html: prepareMsg(field)(message) }} />
    );
  }
  // Type message answerphone render
  if (React.isValidElement(message.msg)) {
    return message.msg;
  }
  return message[field];
};
