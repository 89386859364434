import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import block from 'bem-cn-lite';
import { withTranslation as translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Button, Row, ControlLabel, FormGroup } from 'react-bootstrap';

import { ModalContainer } from './elements';
import { TextArea } from '../FormControls';

import './styles/modal_theme_default.styl';

import * as modalActions from '../../action-creators/modal';
import * as requestsActions from '../../action-creators/purchaseRequests';
import * as serviceActions from '../../action-creators/services';

import { formatRequestNumber } from '../../utils/utils';

// const b = block('modal-container');

class ModalRespondToRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: ''
    };
  }

  UNSAFE_componentWillMount() {
    const { t, requestId } = this.props;
    if (!this.state.text) {
      this.setState({
        text: t('request_respond_text', {
          number: formatRequestNumber(requestId)
        })
      });
    }
  }

  render() {
    const { t, hideModalDialog, respondToRequest, requestId, user, gaSend } =
      this.props;
    const { chatSocket: socket } = this.context;

    return (
      <Modal id="requestRespondConfirm" onHide={() => hideModalDialog()} show>
        <ModalContainer mods={{ theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t('Respond confirm')}
              <span
                className="modal-header-cross pull-right"
                onClick={() => hideModalDialog()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
              <Row style={{ marginBottom: 10 }}>{t('respond_modal_text')}</Row>
              <Row style={{ marginBottom: 10 }}>
                <FormGroup controlId="productsListText">
                  <ControlLabel className="control-label-row">
                    {t('Request covering text')}
                  </ControlLabel>
                  <h6 className="pull-right" style={{ marginTop: 5 }}>
                    <small>
                      {this.state.text.length}
                      /500
                    </small>
                  </h6>
                  <TextArea
                    maxLength="500"
                    rows="4"
                    value={this.state.text}
                    onChange={(e) => this.setState({ text: e.target.value })}
                    style={{ height: 128 }}
                  />
                </FormGroup>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="cancel"
              className="pull-left"
              onClick={() => {
                hideModalDialog();
              }}>
              {t('Cancel')}
            </Button>
            <Button
              id="confirm"
              bsStyle="primary"
              onClick={async () => {
                gaSend({
                  category: 'Common',
                  action: 'respond_request',
                  label: user.get('email')
                });
                const res = await respondToRequest({
                  requestId,
                  text: this.state.text
                });
                if (res.responsibleUserChatId) {
                  await socket.emit(
                    'send-to',
                    this.state.text,
                    `${user.get('currentCompany')}.${user.get('id')}`,
                    res.responsibleUserChatId
                  );
                }
                hideModalDialog();
              }}>
              {t('Confirm')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalRespondToRequest.contextTypes = {
  chatSocket: PropTypes.object
};

ModalRespondToRequest.propTypes = {
  t: PropTypes.func.isRequired
};

ModalRespondToRequest.childContextTypes = {
  b: PropTypes.func
};

export default connect(
  (state) => ({
    user: state.getIn(['user', 'user']),
    company: state.get('company')
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalActions.hideModalDialog,
        respondToRequest: requestsActions.sendRespondToRequest,
        gaSend: serviceActions.gaSend
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalRespondToRequest));
