import React from 'react';
import cn from 'classnames';
import styles from './ChatButton.module.css';

export const ChatButton = ({
  icon,
  isSmall = false,
  isActive = false,
  className,
  ...props
}) => (
  <button
    className={cn(styles.btn, className, {
      [styles.smallBtn]: isSmall,
      [styles.active]: isActive
    })}
    {...props}>
    {icon}
  </button>
);
