import React, { useCallback, useRef, useState } from 'react';
import { Button, Modal, Typography } from '@link/react-components';
import { useAsyncFn, useTranslate } from '@hooks';
import { myCompanyService } from '@api';
import { useSelector } from 'react-redux';
import * as storeGetters from '@root/storeGetters';
import { InviteEmployeeForm } from '@root/widgets';
import { useInviteEmployeeModal } from './useInviteEmployeeModal';
import { MODAL_KEY } from './constants';
import styles from './ModalInviteEmployee.module.css';
import { validateForm } from './lib';

const ModalInviteEmployee = () => {
  const t = useTranslate();
  const [, hideModal] = useInviteEmployeeModal();

  const inviteEmployeeInfo = useRef(null);
  const companyId = useSelector(storeGetters.getCurrentCompanyId);

  const [{ loading }, createEmployee] = useAsyncFn(async () => {
    await myCompanyService.inviteEmployee({
      data: inviteEmployeeInfo.current
    });
    hideModal();
  }, [companyId]);

  const [canSubmit, setCanSubmit] = useState(false);

  const onFormChange = useCallback((formState) => {
    inviteEmployeeInfo.current = formState;
    const isValid = validateForm(formState);
    setCanSubmit((prevState) => (prevState === isValid ? prevState : isValid));
  }, []);

  return (
    <Modal open onClose={hideModal} className={styles.container}>
      <Modal.Header>{t('modules.inviteEmployee.title')}</Modal.Header>
      <Typography variant="body1Reg" className={styles.subtitle}>
        {t('modules.inviteEmployee.subtitle')}
      </Typography>

      <Modal.Content>
        <InviteEmployeeForm onFormChange={onFormChange} />
      </Modal.Content>
      <Modal.Footer align="right">
        <Modal.Actions>
          <Button onClick={hideModal} mode="text">
            {t('modules.inviteEmployee.actionButtons.cancel')}
          </Button>
          <Button
            disabled={!canSubmit}
            loading={loading}
            onClick={createEmployee}>
            {t('modules.inviteEmployee.actionButtons.submit')}
          </Button>
        </Modal.Actions>
      </Modal.Footer>
    </Modal>
  );
};

export const initModalInviteEmployee = { [MODAL_KEY]: ModalInviteEmployee };
