import { equals } from 'ramda';
import { STATUSES } from '@root/entities';
import { userStatusesLabels } from '../constants';

// export const getTimeByPeriod = (timeType) => {
//   switch (timeType) {
//     case statusPeriod[statusPeriodTypes.hour]:
//       return addHours(new Date(), TIMES_CONSTANTS.ONE_HOUR);
//     case statusPeriod[statusPeriodTypes.halfHour]:
//       return addMinutes(new Date(), TIMES_CONSTANTS.THIRTY_MINUTES);
//     case statusPeriod[statusPeriodTypes.thisWeek]:
//       return pipe(nextFriday, endOfDay)(new Date());
//     case statusPeriod[statusPeriodTypes.threeHour]:
//       return addHours(new Date(), TIMES_CONSTANTS.THREE_HOURS);
//     case statusPeriod[statusPeriodTypes.today]:
//       return endOfToday();
//     case statusPeriod[statusPeriodTypes.manually]:
//       return null;
//     case statusPeriod[statusPeriodTypes.infinite]:
//       return new Date(9999, 12, 31, 0, 0, 0, 0);
//     default:
//       return null;
//   }
// };

// export const showTimeBlock = (status) =>
//   status === statusPeriod[statusPeriodTypes.manually];

export const isCorrectStatus = (status) => status === STATUSES.noStatus.value;

// const makeDateOption = (hours, minutes = '') => ({
//   value: `${hours}:${minutes}`,
//   label: `${hours}:${minutes.toString().padStart(2, '0')}`
// });

// export const generateDateOption = (date) => {
//   const hours = date.getHours();
//   const minutes = date.getMinutes();
//
//   return makeDateOption(hours, minutes);
// };

// export const createTime = (props) => {
//   if (Object.values(props).some(isNil)) return console.error('Error');
//
//   const { year, month, day, hour, minutes } = props;
//
//   return new Date(year, month, day, hour, minutes);
// };

// export const generateDateOptions = (date) => {
//   const endOfTodayDate = endOfToday();
//
//   if (!isBefore(date, endOfTodayDate)) return [];
//
//   const hours = date.getHours();
//
//   const minutes = date.getMinutes();
//   let d;
//   if (minutes < 30) {
//     d = new Date(date.setMinutes(30));
//   } else {
//     d = new Date(date.setDate(hours + 1, 0, 0, 0));
//   }
//
//   const result = [];
//   while (isBefore(d, endOfDay(date))) {
//     const timeObj = generateDateOption(d);
//     result.push(timeObj);
//     d = addMinutes(d, TIMES_CONSTANTS.TIME_STEP);
//   }
//   return result;
// };

// export const validateTime = (date) => {
//   if (!date) return [];
//   const errors = [];
//
//   const dateMinutes = hoursToMinutes(date.getHours()) + date.getMinutes();
//
//   const dateDay = date.setDate(0, 0, 0, 0);
//
//   const today = new Date();
//
//   const todayTime = hoursToMinutes(today.getHours()) + today.getMinutes();
//
//   const todayDay = today.setDate(0, 0, 0, 0);
//
//   if (todayTime > dateMinutes) {
//     errors.push(ERROR_REASON.INCORRECT_TIME);
//   }
//
//   if (isBefore(dateDay, todayDay)) {
//     errors.push(ERROR_REASON.INCORRECT_DATE);
//   }
//
//   return errors;
// };

// export const hasErrorReason = (reasons = [], targetReason = '') =>
//   reasons.includes(targetReason);

const toStatusViewModel = (status, selected, { t }) => ({
  key: status.key,
  value: { ...status, label: t(userStatusesLabels[status.key]), selected }
});

export const statusMapper = {
  toStatusViewModel
};

export function validate(state, initialState) {
  if (equals(state, initialState)) return false;

  return true;
}
