import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useMount, usePrevious, useUnmount } from 'react-use';
import { useSelector } from 'react-redux';
import { useResizeLogic } from './editor/resize';
import { useDraftEditor } from './editor';
import { isAllowToSend, isTextEmpty } from './functions';
import {
  initAddMention,
  initHandleKeyCommand,
  initHandlePastedFiles,
  initHandlePastedText,
  initKeyBindingFn
} from './editor/handlers';
import { send } from './useCases/send';
import { DEFAULT_MAX_MSG_LENGTH } from '../../../../constants';
import View from './view';
import * as chatStorage from '../../getter';

const LS_KEY = 'inputChatSize';

const Editor = ({
  editorId,
  files,
  lsKey = LS_KEY,
  placeholder,
  mentions,
  onSaveTextBeforeUnmount,
  onUnmount,
  onChangeEditor,
  onTyping,
  onChange,
  onSubmit,
  onFocus,
  onSetEditMode,
  isDisableAttach,
  onAttachLocalFile,
  onAttachStorageFile,
  onDeleteFile,
  theme,
  mode,
  blockEdit,
  allowSendEmpty = false,
  maxMsgLength = DEFAULT_MAX_MSG_LENGTH,
  maxFilesLength = 5
}) => {
  const editorRef = useRef();

  const prevEditorId = usePrevious(editorId);

  const loadingFilesIds = useSelector(chatStorage.getLoadingFilesIds);

  const { setDefaultSize, onResize, onResizeStop, rndConfig } = useResizeLogic({
    ls: { key: lsKey }
  });

  const onUpdateEditorId = () => {
    setDefaultSize();
  };

  const { _editorState, _onChangeES, text, onSend } = useDraftEditor({
    editorRef,
    editorId,
    files,
    onTyping,
    onChange,
    onUpdateEditorId
  });

  const addMention = useCallback(
    initAddMention({
      editorState: _editorState,
      onChangeES: _onChangeES,
      focus: () => editorRef.current.focus()
    }),
    [_editorState, _onChangeES, editorRef]
  );

  useMount(() => document.dispatchEvent(new Event('input-mounted')));

  useEffect(() => {
    if (typeof onChangeEditor === 'function') {
      onChangeEditor({ prevId: prevEditorId, prevText: text });
    }
  }, [editorId]);

  useUnmount(() => {
    if (typeof onSaveTextBeforeUnmount === 'function')
      onSaveTextBeforeUnmount(text);
    if (typeof onUnmount === 'function') {
      onUnmount({ text, editorState: _editorState });
    }
  });

  const isSendDisable = useMemo(
    () =>
      !isAllowToSend({
        mode,
        text,
        files,
        blockEdit,
        allowSendEmpty,
        maxMsgLength,
        loadingFilesIds
      }),
    [
      mode,
      text,
      files,
      blockEdit,
      allowSendEmpty,
      maxMsgLength,
      loadingFilesIds
    ]
  );

  const onClickByWrapper = useCallback(() => {
    editorRef.current.focus();
    onFocus();
  }, [editorRef, onFocus]);

  const handleKeyCommand = useCallback(
    initHandleKeyCommand({
      onSend,
      onSubmit,
      onChange: _onChangeES,
      onSetEditMode,
      setDefaultSize
    }),
    [onSend, onSubmit, onSetEditMode, setDefaultSize, _onChangeES]
  );
  const keyBindingFn = useCallback(
    initKeyBindingFn({
      isTextEmpty: isTextEmpty(text),
      isSendDisable
    }),
    [isTextEmpty(text), isSendDisable]
  );
  const handlePastedText = useCallback(
    initHandlePastedText({ currentText: text, onChangeES: _onChangeES }),
    [text, _onChangeES]
  );
  const handlePastedFiles = useCallback(
    initHandlePastedFiles({ onAttachFile: onAttachLocalFile }),
    [onAttachLocalFile]
  );

  const onButtonSendClick = useCallback(() => {
    if (!isSendDisable) {
      send({ onSend, onSubmit, setDefaultSize });
    }
  }, [isSendDisable, onSend, onSubmit, setDefaultSize]);

  return (
    <View
      ref={editorRef}
      editorId={editorId}
      text={text}
      files={files}
      isDisableAttach={isDisableAttach}
      placeholder={placeholder}
      mentions={mentions}
      mode={mode}
      onResize={onResize}
      onResizeStop={onResizeStop}
      rndConfig={rndConfig}
      onAttachLocalFile={onAttachLocalFile}
      onAttachStorageFile={onAttachStorageFile}
      onDeleteFile={onDeleteFile}
      theme={theme}
      editorState={_editorState}
      onChangeES={_onChangeES}
      handleKeyCommand={handleKeyCommand}
      keyBindingFn={keyBindingFn}
      handlePastedText={handlePastedText}
      handlePastedFiles={handlePastedFiles}
      isSendDisable={isSendDisable}
      onButtonSendClick={onButtonSendClick}
      onClickByWrapper={onClickByWrapper}
      addMention={addMention}
      maxMsgLength={maxMsgLength}
      maxFilesLength={maxFilesLength}
    />
  );
};

export default React.memo(Editor);
