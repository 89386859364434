import { assoc, assocPath, append, defaultTo, clone, mergeRight } from 'ramda';
import {
  ADD_ARCHIVES,
  ADD_CHATS,
  SET_ARCHIVES,
  SET_CHATS,
  UPDATE_ARCHIVE,
  UPDATE_CHAT,
  UPDATE_GROUP_OF_CHATS,
  UPDATE_CHATS_FILTER,
  UPDATE_ARCHIVIES_FILTER,
  SET_MEMBER_STATUS
} from './constants';
import {
  concatList,
  getItem,
  makeBlockList,
  updateItem,
  mapList
} from '../../data-type/block-list';
import { getId } from '../../data-type/showElement';

export const defaultState = () => ({
  blockChat: makeBlockList(),
  blockArchive: makeBlockList(),
  whereChats: {
    direction: 'down',
    sortBy: 'sort-by-last-update',
    filterBy: 'all-chats'
  },
  whereArchivies: {
    direction: 'down',
    sortBy: 'sort-by-last-update',
    filterBy: 'all-chats'
  }
});

const setOpenChat = (chatWidget, chat) => assoc('openedChat', chat, chatWidget);
const setBlockChat = (chatWidget, value) =>
  assoc('blockChat', value, chatWidget);
const setBlockArchive = (chatWidget, value) =>
  assoc('blockArchive', value, chatWidget);
const setWhereChats = (chatWidget, value) =>
  assoc('whereChats', value, chatWidget);
const setWhereArchivies = (chatWidget, value) =>
  assoc('whereArchivies', value, chatWidget);

const initUpdateWhere =
  (setWhere, whereKey) =>
  (chatWidget, { direction, sortBy, filterBy }) =>
    setWhere(chatWidget, {
      direction: defaultTo(chatWidget[whereKey].direction, direction),
      sortBy: defaultTo(chatWidget[whereKey].sortBy, sortBy),
      filterBy: defaultTo(chatWidget[whereKey].filterBy, filterBy)
    });

const updateWhereChats = initUpdateWhere(setWhereChats, 'whereChats');
const updateWhereArchivies = initUpdateWhere(
  setWhereArchivies,
  'whereArchivies'
);

export const reducers = {
  [SET_CHATS]: (chatWidget, chats) => {
    const updatedCW = setBlockChat(chatWidget, chats);
    const foundChat = getItem(chats, getId(chatWidget.openedChat));
    if (foundChat) {
      return setOpenChat(updatedCW, foundChat);
    }
    return updatedCW;
  },
  [ADD_CHATS]: (chatWidget, chats) =>
    setBlockChat(chatWidget, concatList(chatWidget.blockChat, chats)),
  [UPDATE_CHAT]: (chatWidget, { chat }) => {
    const updatedChatWidget = setBlockChat(
      chatWidget,
      updateItem(chatWidget.blockChat, chat)
    );

    if (getId(chatWidget.openedChat) === chat.id) {
      return setOpenChat(updatedChatWidget, chat);
    }
    return updatedChatWidget;
  },
  [UPDATE_GROUP_OF_CHATS]: (chatWidget, { groupId, exclude, include }) => {
    const allChatIds = [...new Set([...exclude, ...include])];

    const needUpdateBlockChat = chatWidget.blockChat.list.some((chat) =>
      allChatIds.includes(chat.id)
    );
    const needUpdateBlockArchive = chatWidget.blockArchive.list.some((chat) =>
      allChatIds.includes(chat.id)
    );

    let result = chatWidget;

    function addOrExcludeChatsFromGroup(blockChat) {
      return mapList(blockChat, (chat) => {
        let updatedChat = { ...chat };
        if (exclude.includes(updatedChat.id)) {
          const updateUserCheckedLists = updatedChat.checkedLists.user.filter(
            (id) => id !== groupId
          );
          updatedChat = assocPath(
            ['checkedLists', 'user'],
            updateUserCheckedLists,
            chat
          );
        }
        if (include.includes(updatedChat.id)) {
          const updateUserCheckedLists = append(
            groupId,
            updatedChat.checkedLists.user
          );
          updatedChat = assocPath(
            ['checkedLists', 'user'],
            updateUserCheckedLists,
            chat
          );
        }
        return updatedChat;
      });
    }

    if (needUpdateBlockChat) {
      const updatedBlockChat = addOrExcludeChatsFromGroup(result.blockChat);

      result = setBlockChat(result, updatedBlockChat);
    }

    if (needUpdateBlockArchive) {
      const updatedBlockArchive = addOrExcludeChatsFromGroup(
        result.blockArchive
      );

      result = setBlockArchive(result, updatedBlockArchive);
    }

    return result;
  },
  [SET_MEMBER_STATUS]: (chatWidget, { employeeId, status }) => {
    const targetDialogIndex = chatWidget.blockChat.list.findIndex(
      (chat) =>
        chat.type.toLowerCase() === 'dialog' &&
        chat.members.some((member) => member.employeeId === employeeId)
    );

    if (targetDialogIndex === -1) return chatWidget;

    const updatedDialog = clone(chatWidget.blockChat.list[targetDialogIndex]);
    const memberIndex = updatedDialog.members.findIndex(
      (member) => member.employeeId === employeeId
    );

    updatedDialog.members[memberIndex].status = status;

    const updatedChatWidget = setBlockChat(
      chatWidget,
      updateItem(chatWidget.blockChat, updatedDialog)
    );

    if (getId(chatWidget.openedChat) === employeeId) {
      return setOpenChat(
        updatedChatWidget,
        mergeRight(chatWidget.openedChat, updatedDialog)
      );
    }

    return updatedChatWidget;
  },
  [SET_ARCHIVES]: (chatWidget, chats) => setBlockArchive(chatWidget, chats),
  [ADD_ARCHIVES]: (chatWidget, chats) =>
    setBlockArchive(chatWidget, concatList(chatWidget.blockArchive, chats)),
  [UPDATE_ARCHIVE]: (chatWidget, { chat }) => {
    const updatedChatWidget = setBlockArchive(
      chatWidget,
      updateItem(chatWidget.blockArchive, chat)
    );

    if (getId(chatWidget.openedChat) === chat.id) {
      return setOpenChat(updatedChatWidget, chat);
    }
    return updatedChatWidget;
  },
  [UPDATE_CHATS_FILTER]: updateWhereChats,
  [UPDATE_ARCHIVIES_FILTER]: updateWhereArchivies
};
