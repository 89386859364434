import React from 'react';
import { withTranslation as translate } from 'react-i18next';

const EmptySearchResults = ({ t }) => (
  <div className="cwsa-search-empty">
    <img
      className="cwsa-search-empty__icon"
      src="/img/chat-widget/magnifier.svg"
      alt="empty chat"
    />
    <div className="cwsa-search-empty__text">
      {t('chat-widget.chat.empty-advanced-search')}
    </div>
  </div>
);

export default translate()(EmptySearchResults);
