import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import { withTranslation as translate } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal, Nav, NavItem } from 'react-bootstrap';

import styled, { keyframes } from 'styled-components';
import { Flex, Button } from '../../../../styledComponents/atoms';
import { Footer } from '../Components';
import FilesList from './files-list';
import FilesNotFound from './FilesNotFound';
import ButtonIcon from '../../ButtonIcon';
import SearchBlock from './SearchBlock';

import { isMainService } from '../../../../lib/goodwix';

import './style.css';

import * as modalActions from '../../../../action-creators/modal';
import * as servicesAction from '../../../../action-creators/services';
import * as storageAction from '../../../../action-creators/storage';

class CWModalStorageFileAttache extends PureComponent {
  static navItem({ eventKey, name, disabled, hidden = 'block' }) {
    return (
      <NavItem
        eventKey={eventKey}
        disabled={!!disabled}
        key={eventKey}
        style={{ display: hidden }}>
        {name}
      </NavItem>
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      tab: 'chat',
      filesIds: [],
      search: '',
      searchActive: false,
      firstQuery: true
    };
    this.searchBlock = React.createRef();
    this.getFilesByTab = this.getFilesByTab.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
    this.onClickCheckbox = this.onClickCheckbox.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.onSearchCancel = this.onSearchCancel.bind(this);
    this.getFiles = this.getFiles.bind(this);
    this.loadFilesOnScroll = this.loadFilesOnScroll.bind(this);
  }

  async componentDidMount() {
    const { getStorageSize, addStorageResources, storage } = this.props;
    await getStorageSize();
    const files = storage.getIn(['chat', 'files']);
    if (files.size === 0) {
      await addStorageResources('chat', { offset: 0 });
    }
  }

  componentWillUnmount() {
    const { clearStorage } = this.props;
    if (!isMainService()) {
      clearStorage();
    }
  }

  onTabChange(tab) {
    const { storage } = this.props;
    const currentType = this.getTypeResource(tab);
    const size = storage.getIn([currentType, 'size']);

    if (size === 0) {
      this.getFilesByTab(tab);
    }
    this.setState({ tab });
    this.onSearchCancel();
    this.searchBlock.current.clearQuery();
  }

  onClickCheckbox(id) {
    const fileIds = [...this.state.filesIds];
    if (fileIds.includes(id)) {
      fileIds.splice(fileIds.indexOf(id), 1);
    } else {
      fileIds.push(id);
    }
    this.setState({ filesIds: fileIds });
  }

  onSearchClick() {
    this.setState((prevState) => ({ searchActive: !prevState.searchActive }));
  }

  onSearchSubmit(value) {
    const { getModalStorageSearch, clearStorageModalSearch } = this.props;
    const { tab } = this.state;
    clearStorageModalSearch();
    getModalStorageSearch(tab, { search: value });
    this.setState({ firstQuery: false });
  }

  onSearchChange(value) {
    const { clearStorageModalSearch } = this.props;
    const search = value.trim();
    this.setState({ search });
    if (search === '') {
      clearStorageModalSearch();
      this.setState({ firstQuery: true });
    }
  }

  onSearchCancel() {
    const { clearStorageModalSearch } = this.props;
    this.setState({ searchActive: false, search: '', firstQuery: true });
    clearStorageModalSearch();
  }

  getFilesByTab(tab, options = {}) {
    const { addStorageResources } = this.props;
    addStorageResources(tab, options);
  }

  getFiles() {
    const { storage } = this.props;
    const { tab, searchActive, search } = this.state;
    let files = List();
    if (searchActive && search !== '' && !this.state.firstQuery) {
      files = storage.get('all');
    } else {
      if (tab === 'chat') {
        files = storage.getIn(['chat', 'files']);
      }
      if (tab === 'uploads') {
        files = storage.getIn(['uploads', 'files']);
      }
      if (tab === 'catalogs') {
        files = storage.getIn(['catalogs', 'files']);
      }
      if (tab === 'customersOrders') {
        files = storage.getIn(['customersorders', 'files']);
      }
      if (tab === 'suppliersOrders') {
        files = storage.getIn(['suppliersorders', 'files']);
      }
    }
    return files.toJS().map((i) => {
      i.checked = this.state.filesIds.includes(i.fileId); // eslint-disable-line
      return i;
    });
  }

  get isMaxFiles() {
    return this.state.filesIds.length >= 5;
  }

  getTypeResource = (tab) => {
    switch (tab) {
      case 'customersOrders':
        return 'customersorders';
      case 'suppliersOrders':
        return 'suppliersorders';
      case 'catalogs':
        return 'catalogs';
      case 'chat':
        return 'chat';
      case 'uploads':
        return 'uploads';
      default:
        throw new Error(`no such type ${tab}`);
    }
  };

  loadFilesOnScroll() {
    const { totalSize, getModalStorageSearch, storage, page } = this.props;
    const searchActive = this.state.searchActive && this.state.search !== '';
    const currentType = this.getTypeResource(this.state.tab);
    const size = storage.getIn([currentType, 'size']);
    const offset = storage.getIn([currentType, 'offset']);
    const limit = storage.getIn([currentType, 'limit']);
    const files = this.getFiles();

    const element = document.getElementById('files-list');
    if (element) {
      const isAtBottom =
        element &&
        Math.abs(
          element.scrollHeight - element.scrollTop - element.clientHeight
        ) <= 3.0;
      if (isAtBottom && files.length < (searchActive ? totalSize : size)) {
        if (searchActive) {
          getModalStorageSearch(this.state.tab, {
            page: page + 1,
            limit,
            search: this.state.search
          });
        } else {
          this.getFilesByTab(this.state.tab, {
            offset: offset + limit,
            limit,
            size
          });
        }
      }
    }
  }

  render() {
    const { t, hideModalDialog, onAttach = () => {} } = this.props;

    const items = [
      { eventKey: 'suppliersOrders', name: t('Orders to suppliers') },
      { eventKey: 'customersOrders', name: t('Customers orders') },
      { eventKey: 'catalogs', name: t('Catalogs') },
      { eventKey: 'chat', name: t('Chat') },
      { eventKey: 'uploads', name: t('Uploads') }
    ];

    const filesCount = this.state.filesIds.length;
    const allFiles = this.getFiles();

    return (
      <ModalWrapper show className="autosize-modal">
        <ModalContainer>
          <ModalHeaderWrap>
            <ModalTitle>{t('File storage')}</ModalTitle>
            <ModalCloseBtn onClick={hideModalDialog}>
              <img src="/img/cross-modal.svg" alt="close" />
            </ModalCloseBtn>
          </ModalHeaderWrap>
          <ModalBody>
            <Section>
              <TabsSection>
                <ModalNav
                  bsStyle="tabs"
                  activeKey={this.state.tab}
                  onSelect={this.onTabChange}>
                  {items.map(CWModalStorageFileAttache.navItem)}
                </ModalNav>
                {!this.state.searchActive && (
                  <SearchButton
                    icon="search"
                    tooltip="search"
                    onClick={this.onSearchClick}
                  />
                )}
              </TabsSection>
              <Search out={!this.state.searchActive}>
                <SearchBlock
                  ref={this.searchBlock}
                  value={this.state.search}
                  disabled={!this.state.searchActive}
                  width="100%"
                  placeholder={t('chatSearchPlaceholder')}
                  onChange={this.onSearchChange}
                  onCancel={this.onSearchCancel}
                  onSubmit={this.onSearchSubmit}
                />
              </Search>
            </Section>
            {allFiles.length > 0 && (
              <FilesList
                files={allFiles}
                isMaxFiles={this.isMaxFiles}
                onClickCheckbox={this.onClickCheckbox}
                handleScroll={this.loadFilesOnScroll}
                out={!this.state.searchActive}
              />
            )}
            {allFiles.length <= 0 && <FilesNotFound t={t} />}
          </ModalBody>
          <Footer
            lbTitle={t('Cancel')}
            lbOnClick={hideModalDialog}
            rbTitle={`${t('attach')} ${filesCount > 0 ? filesCount : ''}`}
            rbDisabled={filesCount === 0}
            rbOnClick={async () => {
              await onAttach(this.state.filesIds);
              hideModalDialog();
            }}
          />
        </ModalContainer>
      </ModalWrapper>
    );
  }
}

const fadeIn = keyframes`
  from {
    transform: translateY(-5px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const ModalWrapper = styled(Modal)`
  .modal-content {
    border: none;
    border-radius: 4px;
    box-shadow: none;
  }
`;

const ModalContainer = styled(Flex)`
  width: 100%;
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: 4px;
`;

const ModalHeaderWrap = styled(Flex)`
  width: 100%;
  border-radius: 4px 4px 0 0;
  margin-bottom: 24px;
  align-items: center;
  padding: 32px 32px 0 32px;
  position: relative;
`;

const ModalTitle = styled.h3`
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  user-select: none;
  margin: 0;
  padding: 0;
`;

const ModalCloseBtn = styled((p) => <Button unstyled {...p} />)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  background-color: ${(p) => p.theme.colors.gray.lighter};
  border-radius: 0 4px 0 4px;
  user-select: none;
  position: absolute;
  top: 0;
  right: 0;
  &:hover {
    background-color: #f8f9fc;
  }
  &:active {
    background-color: #d2d4d8;
  }
`;

const ModalBody = styled(Flex)`
  width: 100%;
  flex-direction: column;
  padding: 0 32px;
  height: 100%;
  min-height: 430px;
  max-height: 430px;
  margin-bottom: 24px;
`;

const Section = styled(Flex)`
  width: 100%;
  flex-direction: column;
  margin: 0 0 24px 0;
`;

const TabsSection = styled(Flex)`
  width: 100%;
  align-items: center;
`;

const ModalNav = styled(Nav)`
  &.nav-tabs > li {
    margin-bottom: 0;
  }
  flex-grow: 1;
  border-bottom: none;
`;

const Search = styled.div`
  width: 100%;
  display: inline-block;
  opacity: ${(p) => (p.out ? '0' : '1')};
  animation: ${(p) => (p.out ? 'none' : fadeIn)} 0.2s ease-in;
  transition: all 0.2s ease-in;
`;

const SearchButton = styled(ButtonIcon)`
  margin-left: auto;
`;

CWModalStorageFileAttache.propTypes = {
  t: PropTypes.func.isRequired,
  hideModalDialog: PropTypes.func.isRequired,
  getStorageSize: PropTypes.func.isRequired,
  getModalStorageSearch: PropTypes.func.isRequired,
  clearStorageModalSearch: PropTypes.func.isRequired,
  storage: PropTypes.object.isRequired,
  totalSize: PropTypes.number,
  page: PropTypes.number,
  onAttach: PropTypes.func.isRequired
};

CWModalStorageFileAttache.defaultProps = {
  totalSize: 0,
  page: 0
};

export default connect(
  (state) => ({
    storage: state.get('storage'),
    chat: state.getIn(['storage', 'chat']),
    size: state.getIn(['storage', 'size']),
    totalSize: state.getIn(['storage', 'totalSize']),
    limit: state.getIn(['storage', 'limit']),
    offset: state.getIn(['storage', 'offset']),
    page: state.getIn(['storage', 'page'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        showModal: modalActions.showModal,
        hideModalDialog: modalActions.hideModalDialog,
        gaSend: servicesAction.gaSend,
        getStorageSize: storageAction.getStorageSize,
        addStorageResources: storageAction.addStorageResources,
        getModalStorageSearch: storageAction.getModalStorageSearch,
        clearStorageModalSearch: storageAction.clearStorageModalSearch,
        clearStorage: storageAction.clearStorage
      },
      dispatch
    )
)(translate()(CWModalStorageFileAttache));
