import React from 'react';
import { Typography } from '@link/react-components';
import { useTranslate } from '../../../../../TranslateProvider';
import styles from './Content.module.css';

export const Content = ({ onExportToExcel, children }) => {
  const t = useTranslate();

  return (
    <div className={styles.content}>
      <Typography
        variant="body1Sb"
        className={styles.description}
        align="center">
        {t('modalImportResponse.text.before')}
        <span className={styles.downLoadLink} onClick={onExportToExcel}>
          {t('modalImportResponse.text.middle')}
        </span>
        {t('modalImportResponse.text.after')}
      </Typography>
      <div className={styles.fileSection}>{children}</div>
    </div>
  );
};
