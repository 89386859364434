import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTranslation as translate } from 'react-i18next';
import styled from 'styled-components';
import keyDebounce from '../../../../../../utils/debounce';
import Row from './row';

const List = ({ t, files, onClickCheckbox, isMaxFiles, handleScroll, out }) => {
  const scrollContRef = useRef(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const debouncedLoad = keyDebounce(handleScroll);
    if (scrollContRef && scrollContRef.current) {
      scrollContRef.current.addEventListener('scroll', debouncedLoad);

      return () => {
        scrollContRef.current.removeEventListener('scroll', debouncedLoad);
      };
    }
  }, []);

  return (
    <FilesListWrapper out={out}>
      <div className="cwsa-file-list__header">
        <div className="cwsa-file-list__header_name">{t('Name')}</div>
        <div className="cwsa-file-list__header_date">{t('Date')}</div>
        <div className="cwsa-file-list__header_type">{t('Type')}</div>
        <div className="cwsa-file-list__header_size">{t('Size')}</div>
      </div>
      <div
        id="files-list"
        className="cwsa-file-lists__container"
        ref={scrollContRef}>
        {files.map(({ id, name, url, createdAt, type, size, checked }) => (
          <Row
            id={id}
            key={id}
            name={name}
            src={url}
            date={createdAt}
            type={type}
            size={size}
            checked={checked}
            onClickCheckbox={onClickCheckbox}
            isMaxFiles={isMaxFiles}
          />
        ))}
      </div>
    </FilesListWrapper>
  );
};

const FilesListWrapper = styled.div`
  width: 100%;
  display: inline-block;
  transform: ${(p) => (p.out ? 'translateY(-16px)' : 'translateY(0)')};
  transition: all 0.2s ease-in;
  height: 435px;
`;

List.propTypes = {
  t: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      date: PropTypes.string,
      type: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired,
      checked: PropTypes.bool,
      onClickCheckbox: PropTypes.func
    })
  ),
  onClickCheckbox: PropTypes.func.isRequired,
  isMaxFiles: PropTypes.bool.isRequired
};

List.defaultProps = {
  files: []
};

export default translate()(List);
