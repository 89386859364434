import { isActiveItem } from '../../../data-type/block-list';
import { getChatContact, getQueryList } from '../../../getter';
import {
  isAdmin as checkIsAdmin,
  isSupport as checkIsSupport
} from '../../../../../lib/goodwix';
import { getChatFilteredContact } from '../../../modules/contacts/getters';

const chooseConfig = (config, isAdmin) => {
  if (isAdmin) {
    return {
      clickByRow: config.clickByRow,
      extra: config.extra.adminList
    };
  }

  return {
    clickByRow: config.clickByRow,
    extra: config.extra.list
  };
};

const generateModelItem = (contacts, config) => (contact) => {
  const {
    employeeId,
    avatar,
    name,
    company,
    isBlockedContact,
    department,
    status
  } = contact;
  const isAdmin = checkIsAdmin(employeeId) || checkIsSupport(employeeId);

  return {
    key: employeeId,
    employeeId,
    active: isActiveItem(contacts, contact),
    avatarSrc: avatar,
    userName: name,
    companyName: company,
    isAdmin,
    isBlocked: isBlockedContact,
    department,
    config: chooseConfig(config, isAdmin),
    status
  };
};

export const generateViewModel = ({ contacts, config }) => ({
  size: contacts.size,
  list: contacts.list.map(generateModelItem(contacts, config))
});

export const defineShowMode = (blockContacts, searchParams) => {
  const { list, isLoading } = blockContacts;
  if (isLoading) return 'loading';
  if (!list.length && searchParams) return 'no-search-result';
  if (!list.length) return 'empty';
  return 'list';
};

export const getShowMode = (state) => {
  const blockContacts = getChatFilteredContact(state);
  const { contacts } = getQueryList(state);

  return defineShowMode(blockContacts, contacts.search);
};

export const generateContactsList = (state, props) => {
  const { config } = props;
  const blockContacts = getChatContact(state);

  return generateViewModel({ contacts: blockContacts, config });
};
