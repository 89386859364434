export const ACTION_TYPES = {
  INIT: 'INIT',
  CHANGE_FIELD: 'CHANGE_FIELD'
};

export const fields = Object.freeze({
  department: 'department',
  position: 'position',
  visibility: 'visibility',
  roleId: 'roleId'
});

export const requiredFields = [fields.roleId, fields.position];
