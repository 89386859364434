import { getBillingEditPermission } from '@root/storeGetters';
import { showModal } from '@root/action-creators/modal';
import { toast } from '@root/modules/toast';
import { applyState } from '../../utils';
import { showSpaceLimitNotificationCase } from './showSpaceLimitNotification';

export const showSpaceLimitNotification = () => (dispatch, getState) =>
  showSpaceLimitNotificationCase({
    getBillingEditPermission: applyState(getState, getBillingEditPermission),
    setMessage: (data) =>
      toast.system('', { key: 'upload-files-failed', data }),
    showModal: (name, props) => dispatch(showModal(name, props))
  });
