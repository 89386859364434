import React, { memo, useCallback, useEffect, useReducer } from 'react';
import * as messagesActions from '@root/action-creators/message';
import { checkFileAllowType } from '@root/useCases/files';
import * as userActions from '@root/action-creators/user';
import { FileSelect, ImageControl } from '@components';
import { useActions, useActionsReducer, useTranslate } from '@hooks';
import {
  initialState,
  reducer
} from '@root/features/PersonalInfo/model/reducer';
import { Input } from '@link/react-components';
import {
  validateEmail,
  validateOnlyCyrillicOrLatin,
  validatePhoneNumber
} from '@root/utils/validateField';
import { PhoneNumberControl } from '@root/features/PersonalInfo/ui/PhoneNumberControl/PhoneNumberControl';
import { ERRORS, ERRORS_TO_TEXT } from '../lib/constants';

import { actions } from '../model/actions';
import styles from './PersonalInfo.module.css';
import {
  fields,
  latOrCyrillicFields,
  requiredFields
} from '../model/constants';

export const PersonalInfo = ({ onChange }) => {
  const t = useTranslate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { changeFiled } = useActionsReducer(dispatch, actions);
  const translate = (...args) => t(`features.personalInfo.${args.join('.')}`);

  useEffect(() => {
    onChange(state);
  }, [state]);

  const { setErrorMessage } = useActions(messagesActions);
  const { uploadAvatar } = useActions(userActions);

  const handleFileChoose = async (files) => {
    const file = files[0];
    if (file.size > 20971520) {
      return setErrorMessage({ keyValue: 'File size must be less then 20 MB' });
    }
    try {
      if (checkFileAllowType(file.name)) {
        const uploadedFile = await uploadAvatar(file);

        if (uploadedFile) {
          changeFiled('avatar', uploadedFile);
        }
      } else {
        setErrorMessage({ keyValue: 'image_wrong_formant_error' });
      }
    } catch (error) {
      console.error('error: ', error);
      setErrorMessage({ keyValue: 'image_wrong_formant_error' });
    }

    return uploadAvatar;
  };

  const onInputChange = useCallback(
    (key, value) => {
      if (requiredFields.includes(key) && !value) {
        changeFiled(key, value, ERRORS.NOT_BE_EMPTY);
        return;
      }

      if (fields.email === key && !validateEmail(value)) {
        changeFiled(key, value, ERRORS.INCORRECT_EMAIL_WAS_PASSED);
        return;
      }

      if (
        fields.phoneNumber === key &&
        value.length &&
        !validatePhoneNumber(value)
      ) {
        changeFiled(key, value, ERRORS.INCORRECT_PHONE_NUMBER_WAS_PASSED);
        return;
      }

      if (
        latOrCyrillicFields.includes(key) &&
        !validateOnlyCyrillicOrLatin(value)
      ) {
        changeFiled(key, value, ERRORS.INCORRECT_VALUE_WAS_PASSED);
        return;
      }

      changeFiled(key, value);
    },
    [changeFiled]
  );

  return (
    <div className={styles.container}>
      <div className={styles.userInfo}>
        <div className={styles.userInfoInputs}>
          <Input
            onChange={(value) => onInputChange(fields.firstName, value)}
            value={state.firstName.value}
            valid={!state.firstName.error}
            errorText={t(ERRORS_TO_TEXT[`${state.firstName.error}`])}
            placeholder={translate('firstName', 'placeholder')}
            label={translate('firstName', 'label')}
            required
          />
          <Input
            onChange={(value) => onInputChange(fields.lastName, value)}
            value={state.lastName.value}
            valid={!state.lastName.error}
            errorText={t(ERRORS_TO_TEXT[`${state.lastName.error}`])}
            placeholder={translate('lastName', 'placeholder')}
            label={translate('lastName', 'label')}
            required
          />
          <Input
            onChange={(value) => onInputChange(fields.middleName, value)}
            value={state.middleName.value}
            valid={!state.middleName.error}
            errorText={t(ERRORS_TO_TEXT[`${state.middleName.error}`])}
            placeholder={translate('middleName', 'placeholder')}
            label={translate('middleName', 'label')}
          />
        </div>

        <FileSelect
          className={styles.fileSelect}
          multiple={false}
          onChange={handleFileChoose}
          component={({ onClick }) => (
            <ImageControl
              name="userImage"
              imgSrc={state.avatar.value}
              onFileChoose={onClick}
            />
          )}
        />
      </div>

      <div className={styles.divider} />

      <div className={styles.contactInfo}>
        <div className={styles.emailWrapper}>
          <Input
            onChange={(value) => onInputChange(fields.email, value)}
            value={state.email.value}
            valid={!state.email.error}
            errorText={t(ERRORS_TO_TEXT[`${state.email.error}`])}
            placeholder={translate('email', 'placeholder')}
            label={translate('email', 'label')}
            required
          />
        </div>
        <PhoneNumberControl
          value={state.phoneNumber.value}
          onChange={onInputChange}
          error={state.phoneNumber.error}
        />
      </div>
    </div>
  );
};

export const MemoizedPersonalInfo = memo(PersonalInfo);
