import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserCard } from '../userCard';

import styles from './UserList.module.css';
import { getAllUsersInTheBuilder } from '../../../../../pages/constructor/builder/components/helpers';
import * as getters from '../../../../ChatWidget/modules/constructor/getters';

export const UserList = ({ selected, setSelected, users }) => {
  const { builder } = useSelector(getters.getBuilderState);
  const disabledUsers = useMemo(() => getAllUsersInTheBuilder(builder), []);

  return (
    <div className={styles.userListContainer}>
      <div className={styles.userList}>
        {users.map((user) => {
          const isDisabled = disabledUsers.includes(user.id);
          return (
            <UserCard
              isDisabled={isDisabled}
              key={`user-${user.id}`}
              setSelected={setSelected}
              isActive={selected?.id === user.id}
              user={user}
            />
          );
        })}
      </div>
    </div>
  );
};
