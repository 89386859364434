import React from 'react';

import { Modal, Typography } from '@link/react-components';
import { usePermissions } from '@root/modules/permissions';

import { useActions, useTranslate } from '@hooks';
import { Link } from 'react-router-dom';

import * as modalActions from '../../../action-creators/modal';

import styles from './ModalShowNeedsDefinition.module.css';

const ModalShowNeedsDefinition = () => {
  const t = useTranslate();
  const permissions = usePermissions();

  const { hideModal } = useActions(modalActions);

  const disabledRoutesLink = !permissions.haveRoutesReadPermissions();

  return (
    <Modal onClose={hideModal} open>
      <Modal.Content className={styles.ModalContent}>
        <Typography variant="body1Reg">
          <span className={styles.needText}>
            {t('modals.needsDefinition.needIsProduct.need')}
          </span>
          {t('modals.needsDefinition.needIsProduct.needIs')}
        </Typography>
        <Typography variant="body1Reg">
          {t('modals.needsDefinition.needCanBeAgreedAsPartOfRequest.textPart')}
          {!disabledRoutesLink ? (
            <Link
              to="/constructor/list"
              onClick={hideModal}
              className={styles.linkToRoutes}>
              {t(
                'modals.needsDefinition.needCanBeAgreedAsPartOfRequest.linkPart'
              )}
            </Link>
          ) : (
            t('modals.needsDefinition.needCanBeAgreedAsPartOfRequest.linkPart')
          )}
        </Typography>
        <Typography variant="body1Reg">
          {t('modals.needsDefinition.canCreateUnlimitedNeeds')}
        </Typography>
        <Typography variant="body1Reg">
          {t('modals.needsDefinition.needCanContainOneItem')}
        </Typography>
      </Modal.Content>
    </Modal>
  );
};

export default ModalShowNeedsDefinition;
