import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Messenger from './MobMessenger';
import { getActiveTab } from '../../ChatWidget/getter';
import { handlerChatWidget } from '../../ChatWidget/useCases';
import { changeTab } from '../../ChatWidget/modules/tabs/wrapper';
import {
  getAllBadges,
  subscribeToUpdateBadges
} from '../../ChatWidget/modules/badges';

export default () => {
  const dispatch = useDispatch();
  const activeTab = useSelector(getActiveTab);

  useEffect(() => {
    dispatch(getAllBadges());
    dispatch(subscribeToUpdateBadges());
    dispatch(changeTab('chats'));
    dispatch(handlerChatWidget('init', { activeTab: activeTab || 'chats' }));
  }, []);

  useEffect(() => {
    const updateAppHeight = () => {
      document.documentElement.style.setProperty(
        '--app-height',
        `${window.innerHeight}px`
      );
    };

    document.addEventListener('resize', updateAppHeight);
    updateAppHeight();

    return () => {
      document.removeEventListener('resize', updateAppHeight);
    };
  }, []);

  return <Messenger />;
};
