import React from 'react';
import { isNil, isEmpty } from 'ramda';
import block from 'bem-cn-lite';
import { useSelector } from 'react-redux';
import Avatar from 'react-components/src/components/Avatar';
import { BADGES_TYPES } from 'react-components/src/components/Badge';
import { noop } from '@root/utils/utils';
import {
  getOnline,
  isOnline as isOnlineGetter
} from '../../../modules/online/getter';
import {
  isAdmin as checkIsAdmin,
  isSupport as checkIsSupport
} from '../../../lib/goodwix';
import { convertUrl } from '../modules/files/utils';
import './style.css';

const DEFAULT_AVATAR_INFO = { type: 'user', src: '', alt: '' };

const getUserImgSrc = (avatar) =>
  isNil(avatar) || isEmpty(avatar) ? '/img/user.svg' : convertUrl(avatar);
const getCompanyImgSrc = (avatar) =>
  isNil(avatar) || isEmpty(avatar) ? '/img/company.svg' : convertUrl(avatar);
const formatText = (text) => text.trim().charAt(0).toUpperCase();

const getIsAdmin = ({ type, employeeId }) =>
  type === 'user' &&
  employeeId &&
  (checkIsAdmin(employeeId) || checkIsSupport(employeeId));

const getIsOnline = ({ type, employeeId }, online) =>
  type === 'user' && employeeId && isOnlineGetter(online, employeeId);

const CHAT_IMG_PATH = '/img/chat-widget';

const imgBlock = block('cws-avatar');
function getChannelImg(info) {
  if (info.subType === 'request-channel') {
    return {
      className: imgBlock('img-color', { blue: true }),
      src: `${CHAT_IMG_PATH}/request-channel.svg`
    };
  }

  if (info.subType === 'news-feed-channel') {
    return {
      className: imgBlock(),
      src: `${CHAT_IMG_PATH}/news-feed-channel.svg`
    };
  }

  return {
    className: imgBlock('img-color'),
    src: info.src
  };
}

function showOnlineStatus(info) {
  return info.subType !== 'avatar-in-chat' && !info.hideOnlineStatus;
}

const CWSAvatarView = ({
  info,
  size,
  availability = 'none',
  fontSize,
  isCursorPointer,
  onClick
}) => {
  const online = availability === 'online' ? BADGES_TYPES.Online : null;

  switch (info.type) {
    case 'user':
      return (
        <Avatar
          src={
            getIsAdmin(info)
              ? '/img/chat-widget/avatar_support.svg'
              : getUserImgSrc(info.src)
          }
          size={size}
          badge={online}
          onClick={onClick}
          isCursorPointer={isCursorPointer}
          status={info.status}
        />
      );
    case 'company':
      return (
        <Avatar
          src={getCompanyImgSrc(info.src)}
          size={size}
          onClick={onClick}
          isCursorPointer={isCursorPointer}
        />
      );
    case 'color-char':
      return (
        <Avatar size={size} isCursorPointer={isCursorPointer} onClick={onClick}>
          <div
            className="cws-avatar__color-char"
            style={{ backgroundColor: info.color, fontSize }}>
            {formatText(info.text)}
          </div>
        </Avatar>
      );
    case 'color-icon':
      return (
        <Avatar size={size} isCursorPointer={isCursorPointer} onClick={onClick}>
          <div
            className={`cws-avatar__color-icon cws-avatar__color-icon_type_${info.icon}`}
            style={{ backgroundColor: info.color }}
          />
        </Avatar>
      );
    case 'color-avatar':
      return (
        <Avatar
          {...getChannelImg(info)}
          size={size}
          isCursorPointer={isCursorPointer}
          onClick={onClick}
        />
      );
    default:
      throw new Error(`No such handler for type = ${info.type}`);
  }
};

const CWSAvatarWithOnline = ({
  size,
  info,
  onClick,
  fontSize,
  isCursorPointer
}) => {
  const online = useSelector(getOnline);
  const isOnline = getIsOnline(info, online);
  const availability = isOnline ? 'online' : 'offline';

  return (
    <CWSAvatarView
      info={info}
      size={size}
      onClick={onClick}
      fontSize={fontSize}
      availability={availability}
      isCursorPointer={isCursorPointer}
    />
  );
};

const CWSAvatar = ({
  size = 46,
  info = DEFAULT_AVATAR_INFO,
  isCursorPointer,
  onClick = noop
}) => {
  const fontSize = size > 35 ? 16 : 15;

  if (showOnlineStatus(info)) {
    return (
      <CWSAvatarWithOnline
        size={size}
        info={info}
        fontSize={fontSize}
        onClick={onClick}
        isCursorPointer={isCursorPointer}
      />
    );
  }

  return (
    <CWSAvatarView
      info={info}
      size={size}
      onClick={onClick}
      fontSize={fontSize}
      isCursorPointer={isCursorPointer}
    />
  );
};

export default React.memo(CWSAvatar);
