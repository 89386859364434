import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  MemoizedCorporateInfo,
  MemoizedInvitePersonalInfo
} from '@root/features';
import { mergeLeft, pipe } from 'ramda';
import { inviteEmployeeFormMapper } from '../../lib/mapper';
import styles from './InviteEmployeeForm.module.css';

export const InviteEmployeeForm = ({ onFormChange }) => {
  const [invitePersonalInfo, setInvitePersonalInfo] = useState({});
  const [corporateInfo, setCorporateInfo] = useState({});

  const corporateInfoRef = useRef({});
  const invitePersonalInfoRef = useRef({});

  const onCorporateInfoChange = useCallback((_corporateInfo) => {
    corporateInfoRef.current = _corporateInfo;
    setCorporateInfo(_corporateInfo);
  }, []);

  const onInvitePersonalInfoChange = useCallback((_invitePersonalInfo) => {
    invitePersonalInfoRef.current = _invitePersonalInfo;
    setInvitePersonalInfo(_invitePersonalInfo);
  }, []);

  useEffect(() => {
    pipe(
      inviteEmployeeFormMapper.transformToModel,
      onFormChange
    )(mergeLeft(invitePersonalInfoRef.current, corporateInfoRef.current));
  }, [corporateInfo, invitePersonalInfo]);

  return (
    <div className={styles.container}>
      <MemoizedInvitePersonalInfo onChange={onInvitePersonalInfoChange} />
      <div className={styles.divider} />
      <MemoizedCorporateInfo onChange={onCorporateInfoChange} />
    </div>
  );
};
