import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader, Modal } from '@link/react-components';
import { useTranslate, useAsync } from '@hooks';
import { CreateSubscribeForm } from '@root/widgets/CreateSubscribeForm';
import { useCreateSubscribeModal } from '../../lib/useCreateSubcribeModal';
import { MODAL_KEY } from '../../lib/constants';
import styles from './ModalCreateSubscribe.module.css';
import { getPurchaseRequestFilterData } from '../../../../ChatWidget/PurchaseRequestsFilter/storage';
import { getCurrentUserLanguage } from '../../../../../storeGetters';

const ModalCreateSubscribe = ({ data = null }) => {
  const [, hideModal] = useCreateSubscribeModal();
  const t = useTranslate();
  const dispatch = useDispatch();
  const language = useSelector(getCurrentUserLanguage);

  const { loading } = data
    ? { loading: false }
    : useAsync(async () => {
        const thunk = getPurchaseRequestFilterData(language);
        await thunk(dispatch);
      }, [language]);

  return (
    <Modal
      open
      onClose={hideModal}
      className={loading ? styles.containerLoading : styles.container}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Modal.Header className={styles.header}>
            {t('pr_filter_title')}
          </Modal.Header>
          <Modal.Content className={styles.content}>
            <CreateSubscribeForm data={data} />
          </Modal.Content>
        </>
      )}
    </Modal>
  );
};

export const initCreateSubscribeModal = { [MODAL_KEY]: ModalCreateSubscribe };
