import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation as translate } from 'react-i18next';
import CWSAvatar from '../../Avatar';
import './style.css';

const InputName = ({ t, name, onChangeName }) => (
  <input
    value={name}
    onChange={(e) => onChangeName((e && e.target && e.target.value) || '')}
    placeholder={t('chat-widget.create-topic.type-name-placeholder')}
    className="cw-create-topic__header_name"
    type="text"
  />
);

InputName.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChangeName: PropTypes.func.isRequired
};

const ButtonsBlock = ({ t, onCancel, onSave, isSaveDisabled }) => (
  <div className="cw-create-topic__header_buttons-block">
    <button
      id="cw-create-topic__header_btn-cancel"
      type="button"
      onClick={onCancel}>
      {t('chat-widget.create-topic.button-cancel')}
    </button>
    <button
      id="cw-create-topic__header_btn-save"
      type="button"
      onClick={onSave}
      disabled={isSaveDisabled}>
      {t('chat-widget.create-topic.button-save')}
    </button>
  </div>
);

ButtonsBlock.propTypes = {
  t: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaveDisabled: PropTypes.bool.isRequired
};

const Header = ({
  t,
  name = '',
  onChangeName = () => {},
  color = '#ffffff',
  onCancel,
  onSave,
  isSaveDisabled
}) => (
  <div className="cw-create-topic__header">
    <CWSAvatar size={30} info={{ type: 'color-char', text: name, color }} />
    <InputName t={t} name={name} onChangeName={onChangeName} />
    <ButtonsBlock
      t={t}
      onCancel={onCancel}
      onSave={onSave}
      isSaveDisabled={isSaveDisabled}
    />
  </div>
);

Header.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChangeName: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaveDisabled: PropTypes.bool.isRequired
};

export default translate()(Header);
