import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { RobotPage } from './pages/robotPage/robotPage';

// Sentry.init({
//  dsn: "https://81883cd93ee6481ca2ebf4141bc19447@sentry.goodwix.com/2"
// });
// should have been called before using it here
// ideally before even rendering your react app

class CatchErrors extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      return <RobotPage />;
    }

    // when there's not an error, render children untouched
    return children;
  }
}

export default CatchErrors;
