import React from 'react';
import cn from 'classnames';

const PopupContent = ({ menuOptions, style = {} }) => (
  <>
    {menuOptions
      .filter(({ hide }) => !hide)
      .map(
        ({
          onClick = undefined,
          disabled = false,
          name,
          icon,
          isFocused = false,
          uniqueClassName = ''
        }) => (
          <div
            style={style}
            key={name}
            className={`compare-popup-item ${
              disabled
                ? 'compare-popup-disabled'
                : `compare-popup-item-hover${uniqueClassName && '_unique'}`
            } ${
              isFocused &&
              `compare-popup-item-hovered${uniqueClassName && '_unique'}`
            }`}
            onClick={disabled ? undefined : onClick}>
            {icon}
            <div style={{ width: 8 }} />
            {name}
          </div>
        )
      )}
  </>
);

export default PopupContent;
