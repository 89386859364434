import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation as translate } from 'react-i18next';
import SecurityBoxLogo from './SecurityBoxLogo';

const Loading = ({ t, text, theme = 'desktop' }) => (
  <div className={`basic-container basic-container_theme_${theme}`}>
    <SecurityBoxLogo />
    <p style={{ textAlign: 'center', marginTop: 55 }}>
      <h4 style={{ fontSize: 18, color: '#252b49', fontWeight: 'normal' }}>
        {t(text)}
      </h4>
    </p>
    <div style={{ marginTop: 15 }}>
      <div className="loader loader_page">
        <div className="loader-dots">
          <div className="loader-dot" />
          <div className="loader-dot" />
          <div className="loader-dot" />
        </div>
      </div>
    </div>
  </div>
);

export default connect(null, (dispatch) => bindActionCreators({}, dispatch))(
  translate(['ui'], { wait: true })(Loading)
);
