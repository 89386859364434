import { SORT_DIRECTIONS, DEFAULT_SORT } from '../SortBlock/constants';

export const TAX_PERCENT = 20;

export const ProductsTableMode = Object.freeze({
  Supplier: 'supplier',
  Customer: 'customer'
});

export const SupplierHints = Object.freeze({
  customProductName: 'custom-product-name'
});

export const ProductTableType = Object.freeze({
  Request: 'request',
  Response: 'response'
});

export const ResponseHints = Object.freeze({
  createRouteForResponse: 'create-route-for-response',
  supplierCommissionInfo: 'supplier-commission-info'
});

const SORT_ORDERS = {
  LAST_PUBLISHED_DATE: 'lastPublishedDate',
  DEFAULT: 'default'
};

const translateSort = (text) => `modules.allRequests.sort.options.${text}`;

export const sortOptions = [
  {
    value: DEFAULT_SORT,
    label: translateSort('default'),
    direction: SORT_DIRECTIONS.DEFAULT,
    order: SORT_ORDERS.DEFAULT
  },
  {
    value: 'lastPublishDateAsk',
    label: translateSort('lastPublishDateAsk'),
    direction: SORT_DIRECTIONS.DESC,
    order: SORT_ORDERS.LAST_PUBLISHED_DATE
  },
  {
    value: 'lastPublishDateDesc',
    label: translateSort('lastPublishDateDesc'),
    direction: SORT_DIRECTIONS.ASC,
    order: SORT_ORDERS.LAST_PUBLISHED_DATE
  }
];

export const cannotPublishReasons = Object.freeze({
  missingFields: 'someFieldsAreMissing',
  incorrectDate: 'incorrectDate'
});

export const priceRankThreshold = Object.freeze({
  good: 'good',
  pricy: 'pricy',
  overpriced: 'overpriced'
});
