export const getChildren = (parentId, list) =>
  list.filter((item) => item.parentId === parentId);

export const getParentsForFilteredCategories = (list, fullList) => {
  const parentsMap = {};

  list.forEach((category) => {
    const { parentId } = category;
    const isCategoryParent = !parentId;
    if (isCategoryParent) {
      parentsMap[category.id] = category;
      return;
    }
    const isParentCategoryPresented = !!parentsMap[parentId];
    if (isParentCategoryPresented) {
      return;
    }
    const parentCategory = fullList.find(
      (category) => category.id === parentId
    );
    parentsMap[parentId] = parentCategory;
  });

  return Object.values(parentsMap);
};
