import React from 'react';

export const EditIcon = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.7969 0.796901L6.64855 13.9455L1.18483 8.48167"
      stroke="#1C50DE"
      strokeWidth="2"
    />
  </svg>
);
