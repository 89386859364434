import React, { useCallback, useRef } from 'react';
import styles from './FileSelect.module.css';

const FileSelect = ({
  accept,
  multiple = true,
  component,
  disabled = false,
  className,
  onChange: _onChange
}) => {
  const inputRef = useRef(null);

  const onClick = useCallback((event) => {
    event.preventDefault();

    inputRef.current.click();
  }, []);

  const onChange = useCallback(
    (e) => {
      _onChange(e.target.files);
    },
    [_onChange]
  );

  return (
    <div className={className}>
      {component({
        onClick
      })}
      <input
        type="file"
        className={styles.inputFile}
        accept={accept}
        multiple={multiple}
        disabled={disabled}
        ref={inputRef}
        onChange={onChange}
      />
    </div>
  );
};

export default FileSelect;
