import React from 'react';
import { useSelector } from 'react-redux';
import { Typography } from '@link/react-components';
import { isDialog } from '@link/web-legacy/src/components/ChatWidget/helpers/chatType';
import { useTranslate } from '@link/web-legacy/src/TranslateProvider';
import * as commonGetters from '@root/components/ChatWidget/getter';
import ButtonBack from '@root/components/ChatWidget/ButtonBack';
import ChatAvatar from '@root/components/ChatWidget/main/ChatAvatar';
import ChatTitle from '@root/components/ChatWidget/ChatTitle';
import CWMBtns from '@root/components/ChatWidget/modules/dashboard/Buttons';
import DashboardCommon from '@root/components/ChatWidget/main/dashboard-common';
import TypingInfo from '@root/components/ChatWidget/main/TypingInfo';
import './style.css';

function getNumeric(number) {
  const t = useTranslate();
  let str = `${number} `;
  switch (number) {
    case 1:
      str += t('chat-widget.chat.single');
      break;
    case 2:
    case 3:
    case 4:
      str += t('chat-widget.chat.few');
      break;
    default:
      str += t('chat-widget.chat.several');
      break;
  }
  return str;
}

function CWDashboard({
  user,
  chat,
  config,
  isActive,
  isCursorPointer,
  onBackClick,
  onClickAvatar,
  onClickAvatars,
  interlocutor,
  status,
  avatarLists,
  onHandleClick
}) {
  const isTyping =
    useSelector(commonGetters.getTypingFromOpenedChat)?.length > 0;
  return (
    <DashboardCommon theme={config.theme}>
      <div className="cwm-dashboard">
        <div className="cwm-dashboard__side-left">
          {config.buttonBack && (
            <div className="cwm-dashboard__back-btn">
              <ButtonBack onClick={onBackClick} />
            </div>
          )}
          {config.avatar && (
            <div>
              <ChatAvatar
                chat={chat}
                isCursorPointer={isCursorPointer}
                onClick={onClickAvatar}
                size={30}
              />
              <div className="cwm-dashboard__separator" />
            </div>
          )}
          {config.title && (
            <div className="cwm-dashboard__title">
              <ChatTitle
                onClick={onClickAvatar}
                chatName={chat.name}
                chatType={chat.type}
                notificationOff={chat.notificationOff}
                interlocutor={interlocutor}
              />
              {isTyping ? (
                <TypingInfo
                  className="cwm-dashboard__typing"
                  isDialog={isDialog(chat)}
                />
              ) : (
                <>
                  {isDialog(chat) && (
                    <Typography
                      variant="body2Reg"
                      className="cwm-dashboard__status">
                      {status}
                    </Typography>
                  )}
                  {!isDialog(chat) && avatarLists && (
                    <Typography
                      variant="body2Reg"
                      className="cwm-dashboard__status"
                      role="presentation"
                      onClick={onClickAvatars}>
                      {getNumeric(avatarLists.total)}
                    </Typography>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        <div className="cwm-dashboard__side-right">
          <CWMBtns
            chat={chat}
            user={user}
            isActive={isActive}
            handler={onHandleClick}
            config={config}
            type={chat.type}
            size={18}
          />
        </div>
      </div>
    </DashboardCommon>
  );
}

export default CWDashboard;
