import React from 'react';

export const Approved = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.7418 9.00537L8.1418 11.4054L12.9418 6.60537M17.3418 9.00537C17.3418 13.4236 13.7601 17.0054 9.3418 17.0054C4.92352 17.0054 1.3418 13.4236 1.3418 9.00537C1.3418 4.58709 4.92352 1.00537 9.3418 1.00537C13.7601 1.00537 17.3418 4.58709 17.3418 9.00537Z"
      stroke="#2BA23E"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
