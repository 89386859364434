import React, { useCallback, useRef, useMemo, useState } from 'react';
import { addDays, startOfDay } from 'date-fns';
import {
  Button,
  DatePicker,
  Modal,
  TimePicker,
  Typography
} from '@link/react-components';
import { useActions, useAsyncFn, useTranslate } from '@hooks';
import { useSelector } from 'react-redux';
import { purchaseRequestService } from '@api';
import * as modalActions from '../../../action-creators/modal';

import { Switcher } from './components/Switcher';
import styles from './ModalExtendResponseEndDate.module.css';
import * as storeGetters from '../../../storeGetters';
import * as notificationMessageActions from '../../../action-creators/message';
import {
  compareTwoDates,
  createTomorrow,
  canBeUpdated,
  translate,
  buildResponseEndDate
} from './lib';

const Content = ({ responseEndDate, onDateChange, onTimeChange, children }) => {
  const lang = useSelector(storeGetters.getCurrentUserLanguage);

  const minDateRef = useRef(createTomorrow());

  const minResponseEndTime = useRef(startOfDay(createTomorrow()));

  return (
    <section className={styles.content}>
      <div className={styles.timePickers}>
        <DatePicker
          className={styles.dataPicker}
          minDate={minDateRef.current}
          locale={lang}
          date={responseEndDate}
          onDateChange={onDateChange}
        />

        <TimePicker
          className={styles.timePicker}
          limited
          onTimeChange={onTimeChange}
          locale={lang}
          initialTime={minResponseEndTime.current}
        />
      </div>
      {children}
    </section>
  );
};

const SwitchersBlock = React.memo(({ onChangeActiveDay, activeDay }) => {
  const t = useTranslate();

  const days = useMemo(
    () => [
      {
        text: translate(t, 'content.days3'),
        value: 3
      },
      {
        text: translate(t, 'content.days', { number: 5 }),
        value: 5
      },
      {
        text: translate(t, 'content.days', { number: 7 }),
        value: 7
      },
      {
        text: translate(t, 'content.days', { number: 14 }),
        value: 14
      }
    ],
    [t]
  );

  return (
    <>
      <Typography variant="body1Sb" className={styles.subtitle}>
        {translate(t, 'content.quickSelection')}
      </Typography>
      <div className={styles.switcherContainer}>
        {days.map((day) => (
          <Switcher
            key={day.text}
            isActive={day.value === activeDay}
            day={day}
            onClick={onChangeActiveDay}
          />
        ))}
      </div>
    </>
  );
});
const FooterButtons = ({ update, loading, disabled }) => {
  const t = useTranslate();
  const { hideModalDialog } = useActions(modalActions);
  const { setSuccessMessage } = useActions(notificationMessageActions);

  const submit = async () => {
    try {
      await update();
      setSuccessMessage({
        key: 'notifications.successUpdatedResponseEndDate'
      });
    } catch (error) {
      console.error('error: ', error);
    } finally {
      hideModalDialog();
    }
  };

  return (
    <Modal.Footer align="right">
      <Modal.Actions>
        <Button onClick={hideModalDialog} mode="text">
          {translate(t, 'footer.cancel')}
        </Button>
        <Button onClick={submit} loading={loading} disabled={disabled}>
          {translate(t, 'footer.prolong')}
        </Button>
      </Modal.Actions>
    </Modal.Footer>
  );
};

export const ModalExtendResponseEndDate = ({ request, updateRequest }) => {
  const t = useTranslate();
  const { hideModalDialog } = useActions(modalActions);

  const [responseEndDate, setResponseEndDate] = useState(() =>
    addDays(compareTwoDates(request.responseEndDate), +1)
  );

  const [responseEndTime, setResponseEndTime] = useState(Date.now());

  const [activeDay, setActiveDay] = useState(null);

  const onDateChange = useCallback((date) => {
    setResponseEndDate(date.date);
    setActiveDay(null);
  }, []);

  const onChangeActiveDay = useCallback(
    (day) => {
      if (day.value !== activeDay) {
        setActiveDay(day.value);
        setResponseEndDate(
          addDays(responseEndDate || Date.now(), day.value - (activeDay || 0))
        );
      }
    },
    [activeDay, responseEndDate]
  );

  const onTimeChange = useCallback((day) => {
    setResponseEndTime(day.time);
  }, []);

  const [{ loading }, update] = useAsyncFn(async () => {
    const { updated } = await purchaseRequestService.extendResponseEndDate({
      requestId: request.id,
      responseEndDate: buildResponseEndDate(responseEndDate, responseEndTime)
    });
    updateRequest({
      request: {
        ...request,
        ...updated
      }
    });
  }, [responseEndDate, responseEndTime]);

  const disabledSubmit = (() => {
    const currentResponseEndDate = buildResponseEndDate(
      responseEndDate,
      responseEndTime
    );
    return !canBeUpdated(currentResponseEndDate) || !responseEndTime;
  })();

  return (
    <Modal open onClose={hideModalDialog}>
      <Modal.Header>{translate(t, 'title')}</Modal.Header>
      <Modal.Content>
        <Content
          onDateChange={onDateChange}
          onTimeChange={onTimeChange}
          responseEndDate={responseEndDate}>
          <div className={styles.switchers}>
            <SwitchersBlock
              activeDay={activeDay}
              onChangeActiveDay={onChangeActiveDay}
            />
          </div>
        </Content>
      </Modal.Content>

      <FooterButtons
        loading={loading}
        update={update}
        disabled={disabledSubmit}
      />
    </Modal>
  );
};
