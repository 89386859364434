import { getInterlocutor } from 'core/data/light/dialog';
import { getDialogInfo } from '../dialogInfo/getters';
import { getOpenedChat } from '../../getter';
import { setChatMode } from '../chatMode';
import { showFavorites } from '../favoriteMessages/wrapper';
import { gaSend } from '../../../../action-creators/services';
import { showProfile } from '../profile';
import { dispatcher } from '../../useCases/utils';
import {
  toggleArchive,
  ac,
  sckt,
  unsubscribeToUpdateChat,
  showEditChatIncludesGroups,
  toggleNotify,
  showProfileWidget,
  showChooseHistoryDownload,
  leave,
  viewTopic,
  closeTopic
} from '../../useCases';
import { showModal } from '../../../../action-creators/modal';
import * as purchaseRequestsStorage from '../../PurchaseRequestsFilter/storage';

import * as delayedMessagesCases from '../delayedMessages/useCases';
import { videoIconClickedEVT } from '../../../../modules/VideoChat/commandsAndEvents';
import { getCurrentUserEmail } from '../../../../storeGetters';
import { noop } from '../../../../utils/utils';

export const handleDashboardDialog =
  (event, chat, options = {}) =>
  async (dispatch, getState) => {
    const employeeId = getState().getIn(['user', 'user', 'employeeId']);
    const userEmail = getState().getIn(['user', 'user', 'email']);
    const interlocutor = getInterlocutor(employeeId, chat);
    const userTo = getDialogInfo(getState());
    const normalizeNumber = (number) => number.replace(/[ ()-]/g, '');

    const callWithSkype = (isVideo = false) => {
      const skype = userTo.skypeLogin;
      window.open(`skype:${skype}?call${isVideo ? '&amp;video=true' : ''}`);
    };

    const callWithViber = () => {
      const viber = userTo.viberLogin;
      window.open(`viber://chat?number=${normalizeNumber(viber)}`);
    };

    const exitChat = async () => {
      const openedChat = getOpenedChat(getState());
      dispatch(setChatMode('none'));
      dispatch(ac.setOpenChat(null));
      unsubscribeToUpdateChat(openedChat.id, dispatch)(dispatch);
      dispatch(ac.clearSelectedMessages());
    };

    const handlers = {
      'exit-chat': exitChat,
      'add-to-list': () => dispatch(showEditChatIncludesGroups(chat)),
      'bell-on': async () => dispatch(toggleNotify(chat)),
      'bell-off': async () => dispatch(toggleNotify(chat)),
      archive: async () => dispatch(toggleArchive(chat)),
      unarchive: async () => dispatch(toggleArchive(chat)),
      favorite: () => dispatch(showFavorites(chat)),
      search: () => {
        dispatch(setChatMode('search'));
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'personal_chat_search',
            label: userEmail
          })
        );
      },
      'view-profile': () => dispatch(showProfile(interlocutor.employeeId)),
      'view-profile-widget': () => showProfileWidget(interlocutor.employeeId),
      'call-skype': () => {
        callWithSkype(false);
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'audio_skype',
            label: userEmail
          })
        );
      },
      'video-call-skype': () => {
        callWithSkype(true);
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'video_skype',
            label: userEmail
          })
        );
      },
      'call-viber': () => {
        callWithViber();
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'audio_viber',
            label: userEmail
          })
        );
      },
      'video-call-viber': () => {
        callWithViber();
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'video_viber',
            label: userEmail
          })
        );
      },
      'skype-inactive': () => {},
      'viber-inactive': () => {},
      'other-contacts-audio': () => {
        dispatch(showProfile(interlocutor.employeeId));
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'audio_else',
            label: userEmail
          })
        );
      },
      'other-contacts-video': () => {
        dispatch(showProfile(interlocutor.employeeId));
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'video_else',
            label: userEmail
          })
        );
      },
      'download-chat-history': () =>
        dispatch(showChooseHistoryDownload(chat.id, chat.createdAt)),
      'download-chat-history-disable': () => {},
      'call-toggle': () => {
        if (options.isOpen) {
          dispatch(
            gaSend({ category: 'Messenger', action: 'audio', label: userEmail })
          );
        }
      },
      'video-toggle': () => {
        if (options.isOpen) {
          dispatch(
            gaSend({ category: 'Messenger', action: 'video', label: userEmail })
          );
        }
      },
      'active-video-call': () => videoIconClickedEVT.emit({ chatId: chat.id }),
      'inactive-video-call': () =>
        videoIconClickedEVT.emit({ chatId: chat.id }),
      'show-delayed-messages': () =>
        dispatch(delayedMessagesCases.showDelayedMessages())
    };
    await dispatcher('handleDashboardDialog', handlers, event);
  };

export const handleDashboardChannel =
  (event, chat) => async (dispatch, getState) => {
    const { id, type, members } = chat;

    const userEmail = getState().getIn(['user', 'user', 'email']);

    const handlers = {
      'add-to-list': () => dispatch(showEditChatIncludesGroups(chat)),
      'bell-on': async () => dispatch(toggleNotify(chat)),
      'bell-off': async () => dispatch(toggleNotify(chat)),
      archive: async () => dispatch(toggleArchive(chat)),
      unarchive: async () => dispatch(toggleArchive(chat)),
      edit: () =>
        dispatch(showModal('CW_GROUP_CHAT_EDIT', { chat, mode: 'Channel' })),
      view: () =>
        dispatch(
          showModal('CW_GROUP_CHAT_EDIT', {
            chat,
            mode: 'Channel',
            readOnly: true,
            btnTextConfirm: 'Close'
          })
        ),
      'add-members': () =>
        dispatch(
          showModal('CW_GROUP_CHAT_ADD_MEMBERS', {
            submit: (inviteMembers, membersRoles, isShowPrevious) => {
              sckt.updateChannel(id, {
                inviteMembers,
                membersRoles,
                isShowPrevious
              });
              dispatch(
                gaSend({
                  category: 'Messenger',
                  action: 'group_chat_add_members',
                  label: userEmail
                })
              );
            },
            resetMembersIds: true,
            resetMembersRoles: true,
            btnSubmitText: 'Add',
            mode: 'Channel',
            actionType: 'Edit',
            members
          })
        ),
      'leave-channel': () => dispatch(leave(type, chat)),
      favorite: () => dispatch(showFavorites(chat)),
      search: () => {
        dispatch(setChatMode('search'));
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'group_chat_search',
            label: userEmail
          })
        );
      },
      'download-chat-history': () =>
        dispatch(showChooseHistoryDownload(chat.id, chat.createdAt)),
      'download-chat-history-disable': () => {},
      'edit-disable': () => {},
      'active-video-call': () => videoIconClickedEVT.emit({ chatId: chat.id }),
      'inactive-video-call': () =>
        videoIconClickedEVT.emit({ chatId: chat.id }),
      'show-delayed-messages': () =>
        dispatch(delayedMessagesCases.showDelayedMessages())
    };

    await dispatcher('handleDashboardChannel', handlers, event);
  };

export const handleDashboardTopic =
  (event, chat) => async (dispatch, getState) => {
    const { id, type, members } = chat;
    const userEmail = getState().getIn(['user', 'user', 'email']);
    const handlers = {
      'add-to-list': () => dispatch(showEditChatIncludesGroups(chat)),
      'bell-on': async () => dispatch(toggleNotify(chat)),
      'bell-off': async () => dispatch(toggleNotify(chat)),
      archive: async () => dispatch(toggleArchive(chat)),
      unarchive: async () => dispatch(toggleArchive(chat)),
      edit: () =>
        dispatch(showModal('CW_GROUP_CHAT_EDIT', { chat, mode: 'Topic' })),
      view: () => dispatch(viewTopic(chat)),
      'add-members': () =>
        dispatch(
          showModal('CW_GROUP_CHAT_ADD_MEMBERS', {
            submit: (inviteMembers, membersRoles, isShowPrevious) => {
              sckt.updateChannel(id, {
                inviteMembers,
                membersRoles,
                isShowPrevious
              });
              dispatch(
                gaSend({
                  category: 'Messenger',
                  action: 'topic_add_members',
                  label: userEmail
                })
              );
            },
            resetMembersIds: true,
            resetMembersRoles: true,
            btnSubmitText: 'Add',
            mode: 'Topic',
            actionType: 'Edit',
            members
          })
        ),
      'leave-topic': () => dispatch(leave(type, chat)),
      'close-topic': () => dispatch(closeTopic(chat)),
      favorite: () => dispatch(showFavorites(chat)),
      search: () => {
        dispatch(setChatMode('search'));
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'topic_search',
            label: userEmail
          })
        );
      },
      'download-chat-history': () =>
        dispatch(showChooseHistoryDownload(chat.id, chat.createdAt)),
      'download-chat-history-disable': () => {},
      'edit-disable': () => {},
      'active-video-call': () => videoIconClickedEVT.emit({ chatId: chat.id }),
      'inactive-video-call': () =>
        videoIconClickedEVT.emit({ chatId: chat.id }),
      'show-delayed-messages': () =>
        dispatch(delayedMessagesCases.showDelayedMessages())
    };

    await dispatcher('handleDashboardTopic', handlers, event);
  };

export const handlePurchaseRequestsChannel =
  (event, chat) => async (dispatch, getState) => {
    const { id, type, members } = chat;

    const userEmail = getState().getIn(['user', 'user', 'email']);

    const handlers = {
      'add-to-list': () => dispatch(showEditChatIncludesGroups(chat)),
      'bell-on': async () => dispatch(toggleNotify(chat)),
      'bell-off': async () => dispatch(toggleNotify(chat)),
      archive: async () => dispatch(toggleArchive(chat)),
      unarchive: async () => dispatch(toggleArchive(chat)),
      edit: () =>
        dispatch(showModal('CW_GROUP_CHAT_EDIT', { chat, mode: 'Channel' })),
      view: () =>
        dispatch(
          showModal('CW_GROUP_CHAT_EDIT', {
            chat,
            mode: 'Channel',
            readOnly: true,
            btnTextConfirm: 'Close'
          })
        ),
      'add-members': () =>
        dispatch(
          showModal('CW_GROUP_CHAT_ADD_MEMBERS', {
            submit: (inviteMembers, membersRoles, isShowPrevious) => {
              sckt.updateChannel(id, {
                inviteMembers,
                membersRoles,
                isShowPrevious
              });
              dispatch(
                gaSend({
                  category: 'Messenger',
                  action: 'group_chat_add_members',
                  label: userEmail
                })
              );
            },
            resetMembersIds: true,
            resetMembersRoles: true,
            btnSubmitText: 'Add',
            mode: 'Channel',
            actionType: 'Edit',
            members
          })
        ),
      'leave-channel': () => dispatch(leave(type, chat)),
      favorite: () => dispatch(showFavorites(chat)),
      search: () => {
        dispatch(setChatMode('search'));
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'group_chat_search',
            label: userEmail
          })
        );
      },
      'download-chat-history': () =>
        dispatch(showChooseHistoryDownload(chat.id, chat.createdAt)),
      'download-chat-history-disable': () => {},
      'edit-disable': () => {},
      'unsubscribe-purchase-requests': () =>
        dispatch(purchaseRequestsStorage.unsubscribe())
    };

    await dispatcher(
      'handleDashboardOfPurchaseRequestsChannel',
      handlers,
      event
    );
  };

export const handleNewsFeedChannel =
  (event, chat) => async (dispatch, getState) => {
    // Fixme don't forget fix this handler
    const { type } = chat;

    const userEmail = getCurrentUserEmail(getState());

    const handlers = {
      'add-to-list': () => dispatch(showEditChatIncludesGroups(chat)),
      archive: async () => dispatch(toggleArchive(chat)),
      unarchive: async () => dispatch(toggleArchive(chat)),
      view: () =>
        dispatch(
          showModal('CW_GROUP_CHAT_EDIT', {
            chat,
            mode: 'Channel',
            readOnly: true,
            btnTextConfirm: 'Close'
          })
        ),
      'leave-news-feed-channel': () => dispatch(leave(type, chat)),
      search: () => {
        dispatch(setChatMode('search'));
        dispatch(
          gaSend({
            category: 'Messenger',
            action: 'group_chat_search',
            label: userEmail
          })
        );
      },
      'download-chat-history': () =>
        dispatch(showChooseHistoryDownload(chat.id, chat.createdAt)),
      'download-chat-history-disable': noop,
      'edit-disable': noop
    };

    await dispatcher('handleDashboardOfNewsFeedChannel', handlers, event);
  };
