import { setSuccessMessage, setErrorMessage } from './message';
import customHistory from '../customHistory';

export const getAllCompanies =
  (orderBy = '', direction = '', search = '', offset = 0, limit = 20) =>
  (dispatch) => {
    dispatch({ type: 'ADMIN:GET_ALL_COMPANIES:REQUEST' });

    const url = '/api/admin/companies';

    fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ orderBy, direction, search, offset, limit })
    })
      .then((res) => res.json())
      .then(({ companies, size, status }) => {
        if (status === 302) {
          customHistory.push('/profile/edit');
        }
        dispatch({
          type: 'ADMIN:GET_ALL_COMPANIES:SUCCESS',
          payload: { companies, size, limit, offset }
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ADMIN:GET_ALL_COMPANIES:FAILURE',
          payload: { error }
        });
      });
  };

export const getAllUsers =
  (orderBy = '', direction = '', search = '', offset = 0, limit = 20) =>
  (dispatch) => {
    dispatch({ type: 'ADMIN:GET_ALL_USERS:REQUEST' });

    const url = '/api/admin/users';

    fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ orderBy, direction, search, offset, limit })
    })
      .then((res) => res.json())
      .then(({ users, size, status }) => {
        if (status === 302) {
          customHistory.push('/profile/edit');
        }
        dispatch({
          type: 'ADMIN:GET_ALL_USERS:SUCCESS',
          payload: { users, size, limit, offset }
        });
      })
      .catch((error) => {
        dispatch({ type: 'ADMIN:GET_ALL_USERS:FAILURE', payload: { error } });
      });
  };

export const getCompanyInfo =
  (companyId, orderBy = '', direction = '') =>
  (dispatch) => {
    dispatch({ type: 'ADMIN:GET_COMPANY_INFO:REQUEST' });

    const url = `/api/admin/companies/${companyId}?orderBy=${orderBy}&direction=${direction}`;

    fetch(url, {
      credentials: 'include',
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then((res) => res.json())
      .then(({ company, status }) => {
        if (status === 302) {
          customHistory.push('/profile/edit');
        }
        dispatch({
          type: 'ADMIN:GET_COMPANY_INFO:SUCCESS',
          payload: { company }
        });
      })
      .catch((error) => {
        dispatch({
          type: 'ADMIN:GET_COMPANY_INFO:FAILURE',
          payload: { error }
        });
      });
  };

export const getUserInfo =
  (userId, orderBy = '', direction = '') =>
  (dispatch) => {
    dispatch({ type: 'ADMIN:GET_COMPANY_INFO:REQUEST' });

    const url = `/api/admin/users/${userId}?orderBy=${orderBy}&direction=${direction}`;

    fetch(url, {
      credentials: 'include',
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then((res) => res.json())
      .then(({ user, status }) => {
        if (status === 302) {
          customHistory.push('/profile/edit');
        }
        dispatch({ type: 'ADMIN:GET_USER_INFO:SUCCESS', payload: { user } });
      })
      .catch((error) => {
        dispatch({ type: 'ADMIN:GET_USER_INFO:FAILURE', payload: { error } });
      });
  };

export const blockCompany =
  ({ companyId, password }, onSuccess = () => {}) =>
  (dispatch) => {
    dispatch({ type: 'ADMIN:BLOCK_COMPANY:REQUEST' });

    const url = `/api/admin/companies/${companyId}/block`;

    return fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password })
    })
      .then((res) => res.json())
      .then(({ status }) => {
        if (status === 302) {
          customHistory.push('/profile/edit');
        }
        if (status === 'blocked') {
          dispatch(setSuccessMessage({ key: 'company_blocked_succesfully' }));
          onSuccess();
          return dispatch({ type: 'ADMIN:BLOCK_COMPANY:SUCCESS' });
        }
        if (status === 'unblocked') {
          dispatch(setSuccessMessage({ key: 'company_unblocked_succesfully' }));
          onSuccess();
          return dispatch({ type: 'ADMIN:BLOCK_COMPANY:SUCCESS' });
        }
        if (status === 'wrong password') {
          dispatch(setErrorMessage({ key: 'wrong_password' }));
        }
        return dispatch({
          type: 'ADMIN:BLOCK_COMPANY:FAILURE',
          payload: { error: 'Wrong password ' }
        });
      })
      .catch((error) => {
        dispatch({ type: 'ADMIN:BLOCK_COMPANY:FAILURE', payload: { error } });
      });
  };

export const blockUser =
  ({ userId, password }, onSuccess = () => {}) =>
  (dispatch) => {
    dispatch({ type: 'ADMIN:BLOCK_COMPANY:REQUEST' });

    const url = `/api/admin/users/${userId}/block`;

    return fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password })
    })
      .then((res) => res.json())
      .then(({ status }) => {
        if (status === 302) {
          customHistory.push('/profile/edit');
        }
        if (status === 'blocked') {
          dispatch(setSuccessMessage({ key: 'user_blocked_succesfully' }));
          onSuccess();
          return dispatch({ type: 'ADMIN:BLOCK_USER:SUCCESS' });
        }
        if (status === 'unblocked') {
          dispatch(setSuccessMessage({ key: 'user_unblocked_succesfully' }));
          onSuccess();
          return dispatch({ type: 'ADMIN:BLOCK_USER:SUCCESS' });
        }
        if (status === 'wrong password') {
          dispatch(setErrorMessage({ key: 'wrong_password' }));
        }
        return dispatch({
          type: 'ADMIN:BLOCK_USER:FAILURE',
          payload: { error: 'Wrong password ' }
        });
      })
      .catch((error) => {
        dispatch({ type: 'ADMIN:BLOCK_USER:FAILURE', payload: { error } });
      });
  };

export const deleteUser =
  ({ userId, password }, onSuccess) =>
  (dispatch) => {
    dispatch({ type: 'ADMIN:DELETE_USER:REQUEST' });

    const url = `/api/admin/users/${userId}/delete`;

    fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password })
    })
      .then((res) => res.json())
      .then(({ status }) => {
        if (status === 302) {
          customHistory.push('/profile/edit');
        }
        if (status === 'wrong password') {
          return dispatch(setErrorMessage({ key: 'wrong_password' }));
        }
        if (status === 'user_is_owner') {
          return dispatch(setErrorMessage({ key: 'user_is_owner' }));
        }
        dispatch(setSuccessMessage({ key: 'user_deleted_succesfully' }));
        return onSuccess();
      })
      .catch((error) =>
        dispatch({ type: 'ADMIN:DELETE_USER:FAILURE', payload: { error } })
      );
  };

export const changeCompanyOwner =
  ({ newCompanyOwners, password }, onSuccess) =>
  (dispatch) => {
    dispatch({ type: 'ADMIN:CHANGE_COMPANY_OWNER:REQUEST' });

    const url = '/api/admin/companies/change_owner';

    fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password, newCompanyOwners })
    })
      .then((res) => res.json())
      .then(({ status }) => {
        if (status === 302) {
          customHistory.push('/profile/edit');
        }
        if (status === 'wrong password') {
          return dispatch(setErrorMessage({ key: 'wrong_password' }));
        }
        dispatch(
          setSuccessMessage({ key: 'company_owner_changed_succesfully' })
        );
        return onSuccess();
      })
      .catch((error) =>
        dispatch({ type: 'ADMIN:DELETE_USER:FAILURE', payload: { error } })
      );
  };

export const deleteCompany =
  ({ companyId, password }) =>
  (dispatch) => {
    dispatch({ type: 'ADMIN:DELETE_COMPANY:REQUEST' });

    const url = `/api/admin/companies/${companyId}/delete`;

    fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ password })
    })
      .then((res) => res.json())
      .then(({ status }) => {
        if (status === 302) {
          customHistory.push('/profile/edit');
        }
        if (status === 'wrong password') {
          dispatch(setErrorMessage({ key: 'wrong_password' }));
        }
        dispatch(setSuccessMessage({ key: 'company_deleted_start' }));
      })
      .catch((error) => {
        dispatch({ type: 'ADMIN:DELETE_COMPANY:FAILURE', payload: { error } });
      });
  };

export const setDeleteCompanyByAdminComplete =
  ({ companyName }) =>
  (dispatch) => {
    dispatch(getAllCompanies());
    dispatch(
      setSuccessMessage({
        key: 'company_deleted_succesfully',
        params: { companyName }
      })
    );
  };

export const updateCompany =
  ({ companyId, password, data }) =>
  (dispatch) => {
    dispatch({ type: 'ADMIN:CHANGE_COMPANY_TARIFF:REQUEST' });
    return fetch('/api/admin/companies/update_data', {
      credentials: 'include',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ companyId, password, data })
    }).catch((error) => {
      console.log(error);
      dispatch({
        type: 'ADMIN:CHANGE_COMPANY_TARIFF:FAILURE',
        payload: { error }
      });
    });
  };
