export const validator = (translate) => (values) => {
  const errors = {};
  if (!/^\d+$/.test(values.account) && values.account.length !== 0) {
    errors.account = translate('correspondentAccountError');
  }
  if (
    (!/^\d+$/.test(values.bankBic) && values.bankBic.length !== 0) ||
    (values.bankBic.length !== 9 && values.bankBic.length !== 0)
  ) {
    errors.bankBic = translate('bicError');
  }
  return errors;
};
