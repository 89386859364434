// Plugin for @draft-js-plugins/editor
import React from 'react';
import { findWithRegex } from '../utils';
import EmojiSuggestions from './Suggestions/EmojiSuggestions';
import TextWrapper from './TextWrapper';

export const createSuggestionsEmojiPlugin = () => {
  const EMOJI_REGEX = /(\s|^):[\w]*:?/g;

  const strategy = (contentBlock, callback) => {
    findWithRegex(EMOJI_REGEX, contentBlock, callback);
  };

  let clientRect;
  const store = {
    getEditorState: undefined,
    setEditorState: undefined,
    getClientRect: () => clientRect,
    updateClientRect: (value) => {
      clientRect = value;
    }
  };

  const callbacks = {
    handleReturn: () => 'not-handled'
  };

  const DecoratedTextWrapper = (props) => (
    <TextWrapper {...props} store={store} />
  );
  const DecoratedSuggestions = (props) => (
    <EmojiSuggestions {...props} store={store} callbacks={callbacks} />
  );

  return {
    EmojiSuggestions: DecoratedSuggestions,
    decorators: [
      {
        strategy,
        component: DecoratedTextWrapper
      }
    ],
    initialize: ({ getEditorState, setEditorState }) => {
      store.getEditorState = getEditorState;
      store.setEditorState = setEditorState;
    },
    handleReturn: (keyboardEvent) =>
      callbacks.handleReturn && callbacks.handleReturn(keyboardEvent)
  };
};
