.container {
  min-width: 530px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  padding: 32px 0;
  box-shadow: 0 6px 10px rgb(118 123 146 / 20%);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  height: auto;
}

.closeBtn {
  position: absolute;
  right: 0;
  top: 0;
}

.header {
  padding: 6px 32px;

  h2 {
    color: var(--color-gray-900);
  }
}

.footer {
  padding: 0 32px;
  display: flex;
  align-items: center;
  column-gap: 16px;
  row-gap: 16px;
  flex-wrap: wrap;
}

.content {
  padding: 0 32px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  color: var(--color-gray-900);
}

.content::-webkit-scrollbar {
  width: 8px;
  background-color: var(--color-gray-200);
}

.content::-webkit-scrollbar-thumb {
  background: rgb(12 35 98 / 20%);
  border-radius: 13px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.right {
  justify-content: right;
}

.center {
  justify-content: center;
}

.left {
  justify-content: left;
}

.space-between {
  justify-content: space-between;
}

.root {
  :global(.MuiDialog-scrollPaper) {
    align-items: flex-start;
  }

  :global(.MuiDialog-container) {
    :global(.MuiPaper-root) {
      max-width: 80vw;
      max-height: 80vh;
    }
  }
}

.paper {
  :global(.MuiDialog-container) {
    :global(.MuiPaper-root) {
      max-width: 80vw;
      max-height: 92vh;
    }
  }
}
