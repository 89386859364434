import request from '@root/lib/request';
import { searchQuery } from '@root/utils/searchQuery';

class MyCompanyService {
  #baseUrl = '/api/my-company';

  async getEmployees({ limit = 20, offset = 0, search = '' } = {}) {
    const query = searchQuery.generate({ limit, offset, search });

    return request.get(`${this.#baseUrl}/employees${query}`);
  }

  async addEmployee(payload) {
    return request.post(`${this.#baseUrl}/add-employee`, payload);
  }

  async inviteEmployee(payload) {
    return request.post(`${this.#baseUrl}/invite-employee`, payload);
  }

  async getPermissions(employeeId) {
    return request.get(`${this.#baseUrl}/${employeeId}/permissions`);
  }

  async updatePermissions(employeeId, data) {
    return request.post(
      `${this.#baseUrl}/${employeeId}/update-permissions`,
      data
    );
  }

  async deletePermissions(employeeId) {
    return request.delete(`${this.#baseUrl}/${employeeId}/delete-permissions`);
  }

  async updateAdmin(newOwnerUserId) {
    return request.post(`${this.#baseUrl}/update-admin`, { newOwnerUserId });
  }

  async deleteEmployees(usersId) {
    return request.delete(`${this.#baseUrl}/employees`, { usersId });
  }

  async updateMyStatus({ statusId, notification }) {
    return request.post(`${this.#baseUrl}/update-status`, {
      statusId,
      notificationsEnabled: notification
    });
  }

  async deleteMyStatus() {
    return request.delete(`${this.#baseUrl}/delete-status`);
  }

  /**
   Create/update company access token
   @returns {Promise<{ token: string }>}
   */
  async generateAccessToken() {
    return request.post(`${this.#baseUrl}/access-token/generate`);
  }

  /**
   * Get company access token
   */
  async getAccessToken() {
    return request.get(`${this.#baseUrl}/access-token`);
  }

  async getFinanceInfo() {
    return request.get(`${this.#baseUrl}/finance`);
  }

  async updatePrimaryPartner({ partnerCompanyId }) {
    return request.patch(`${this.#baseUrl}/primary-partner`, {
      partnerCompanyId
    });
  }

  async getCommissions({ limit = 20, offset = 0, from, to }) {
    const query = searchQuery.generate({ limit, offset });
    return request.post(`${this.#baseUrl}/finance${query}`, { to, from });
  }
}

export const myCompanyService = new MyCompanyService();
