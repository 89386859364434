import { STATUSES } from '@root/entities';

export const DEFAULT_SETTINGS = {
  status: STATUSES.noStatus.value,
  notification: true
};

export const initialStateMethods = {
  status: { id: null, key: DEFAULT_SETTINGS.status },
  notification: DEFAULT_SETTINGS.notification
};

export function createMethods(state) {
  return {
    init(defaultStatus) {
      return defaultStatus;
    },

    setNotification(notification) {
      return { ...state, notification };
    },
    setStatus(status) {
      return { ...state, status };
    },
    clearStatus() {
      return initialStateMethods;
    }
  };
}
