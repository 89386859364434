import URLSearchParams from 'url-search-params';
import request from '../lib/request';
import { ResponseProductDto } from '../DTOs';

class ProductsService {
  #baseUrl = '/api/products';

  getProducts({ search = '', limit = 20, offset = 0 } = {}) {
    const abortController = new AbortController();

    const searchParams = new URLSearchParams({ offset, limit, search });
    const cancel = () => abortController.abort();

    const fetchFn = async () =>
      request
        .get(`${this.#baseUrl}?${searchParams}`, '', {
          signal: abortController.signal
        })
        .then((products) =>
          products.map((product) => new ResponseProductDto(product))
        );

    return [fetchFn, cancel];
  }
}

export const productsService = new ProductsService();
