export const toClientFormData = (data) => ({
  actualAddress: data.actualAddress ?? '',
  bankName: data.fullBankName ?? '',
  account: data.correspondentAccount ?? '',
  bankBic: data.bik ?? '',
  logo: data.logo?.data ?? null,
  description: data.description ?? '',
  country: data.country ? data.country.value : '',
  region: data.region ? data.region.value : '',
  postalAddress: data.postAddress ?? ''
});

export const toInitialLogo = (data) => {
  let logo;

  logo = data.logo?.url ?? data.logo?.data;
  if (!logo) {
    logo = '';
  }
  return logo;
};

export const toInitialCountry = (data) =>
  data.country ? data.country.label : '';

export const toInitialRegion = (data) => {
  if (data.country && data.region) {
    return data.region.label;
  }
  return '';
};
