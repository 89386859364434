import URLSearchParams from 'url-search-params';
import request from '../lib/request';

class RouteService {
  routeUrl = '/api/route';

  async getRoutesListByAuthorId(authorId, excludedUsers) {
    return request.post(`${this.routeUrl}/author/${authorId}`, {
      excludedUsers
    });
  }

  async getCompanyRoutesList() {
    return request.get(`${this.routeUrl}/company/available-routes`);
  }

  async getCompanyRoutesListWithoutMe(type) {
    const typeParam = type ? new URLSearchParams({ type }) : '';
    return request.get(
      `${this.routeUrl}/company/available-routes/without-me?${typeParam}`
    );
  }

  async saveRoute(route) {
    return request.post(`${this.routeUrl}/single/`, { route });
  }

  async deleteRoute(routeId) {
    return request.delete(`${this.routeUrl}/single/${routeId}`);
  }

  async updateRoute({ route, routeId }) {
    return request.put(`${this.routeUrl}/single/${routeId}`, { route });
  }

  async getRouteByRouteId(routeId) {
    return request.get(`${this.routeUrl}/single/${routeId}`);
  }

  async getCompanyRoutesCount({ type }) {
    const searchParams = new URLSearchParams({ type });

    return request.get(`${this.routeUrl}/count?${searchParams}`);
  }

  async getExecutors() {
    return request.get(`${this.routeUrl}/executors`);
  }
}

export const routeService = new RouteService();
