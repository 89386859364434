import React, { useCallback, useMemo, useRef, useEffect } from 'react';

import {
  getPermissionsDiff,
  getPermissionsMappedToIds,
  groupPermissionsBySortedSections
} from './utils';

import { PermissionsSection } from './PermissionsSection';

import styles from './EmployeePermissions.module.css';

function convertToArray(object) {
  return Object.keys(object).map((key) => object[key]);
}

export const EmployeePermissions = ({
  initialPermissions,
  setPermissionsDiff,
  raw = false,
  readOnly = false
}) => {
  if (!initialPermissions) {
    return null;
  }
  const updatedPermissionsObjRef = useRef(
    getPermissionsMappedToIds(initialPermissions)
  );

  useEffect(() => {
    updatedPermissionsObjRef.current =
      getPermissionsMappedToIds(initialPermissions);
  }, [initialPermissions]);

  const onSwitchPermission = useCallback(
    ({ id, value }) => {
      updatedPermissionsObjRef.current[id].allowed = value;
      setPermissionsDiff(
        raw
          ? convertToArray(updatedPermissionsObjRef.current)
          : getPermissionsDiff(
              updatedPermissionsObjRef.current,
              initialPermissions
            )
      );
    },
    [updatedPermissionsObjRef]
  );

  const groupedBySections = useMemo(
    () => groupPermissionsBySortedSections([...initialPermissions]),
    [initialPermissions]
  );

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        {groupedBySections.map((section, index) => (
          <div key={section.sectionName}>
            {index > 0 && <div className={styles.divider} />}
            <PermissionsSection
              section={section}
              onSwitchPermission={onSwitchPermission}
              readOnly={readOnly}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
