export const SORT_DIRECTIONS = {
  DEFAULT: 'DEFAULT',
  DESC: 'DESC',
  ASC: 'ASC'
};

export const SORT_ICONS = {
  [SORT_DIRECTIONS.DESC]: 'sort-desc',
  [SORT_DIRECTIONS.ASC]: 'sort-asc',
  [SORT_DIRECTIONS.DEFAULT]: 'sort-default'
};

export const DEFAULT_SORT = 'defaultSort';
