import React, { useEffect, useState } from 'react';
import { useLayer, useHover, Arrow } from 'react-laag';
import { onShowTooltip } from './message';
import './style.css';

const isReactText = (children) =>
  ['string', 'number'].includes(typeof children);
const SHOW_TIME_MODE_CUSTOM = 3 * 1000;

function getCN(isWide) {
  let cn = 'cws-tooltip';

  if (isWide) cn += ' cws-tooltip_wide';

  return cn;
}

// NOTE: add className for hover from outside
// className="cws-tooltip_outside-hover"
function CWSTooltip({
  t,
  text = '',
  tooltipId,
  triggerOffset = 5,
  placement = 'top-center',
  isFlow = true,
  isWide = false,
  possiblePlacements = [
    'top-center',
    'left-center',
    'bottom-center',
    'right-center'
  ],
  isShowTooltip = true,
  children
}) {
  if (!text) {
    return (
      <div className={getCN(isWide)}>
        <div className="cws-tooltip__children">{children}</div>
      </div>
    );
  }

  const [isHover, hoverProps] = useHover({
    delayEnter: 200,
    delayLeave: 100
  });

  const [isOver, setIsOver] = useState(false);
  const [mode, setMode] = useState('default');
  useEffect(() => {
    onShowTooltip(({ id }) => {
      if (id === tooltipId) {
        setMode('custom');

        setTimeout(() => {
          setMode('default');
        }, SHOW_TIME_MODE_CUSTOM);
      }
    });
  }, [tooltipId]);

  useEffect(() => {
    if (mode === 'custom') setIsOver(true);
    if (mode === 'default') setIsOver(isHover);
  }, [mode, isHover]);

  // NOTE: fix bug with z-index for bootstrap, for lib react-laag
  useEffect(() => {
    if (isOver) {
      const DEFAULT_CONTAINER_ID = 'layers';
      const element = document.getElementById(DEFAULT_CONTAINER_ID);
      element.style.zIndex = '1301';
    }
  }, [isOver]);

  // Tell `useLayer()` how we would like to position our tooltip
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen: isOver,
    placement,
    possiblePlacements: isFlow ? possiblePlacements : [placement],
    auto: true,
    triggerOffset,
    overflowContainer: true // keep the menu positioned inside the container
  });

  if (!isShowTooltip) return children;

  // when children equals text (string | number), we need to wrap it in an
  // extra span-element in order to attach props
  let trigger;
  if (isReactText(children)) {
    trigger = (
      <div
        className="cws-tooltip-text-wrapper"
        {...triggerProps}
        {...hoverProps}>
        {children}
      </div>
    );
  } else {
    // In case of an react-element, we need to clone it in order to attach our own props
    // trigger = React.cloneElement(children, {
    //   ...triggerProps,
    //   ...hoverProps
    // });
    trigger = (
      <div className="cws-tooltip" {...triggerProps} {...hoverProps}>
        <div className="cws-tooltip__children">{children}</div>
      </div>
    );
  }

  return (
    <div className={getCN(isWide)}>
      {trigger}
      {renderLayer(
        isOver && (
          <div {...layerProps} className="cws-tooltip__text">
            {t(`chat-widget.tooltips.${text}`)}
            <Arrow
              {...arrowProps}
              backgroundColor="#767B92"
              angle={56}
              size={4}
              roundness={1}
            />
          </div>
        )
      )}
    </div>
  );
}

export default CWSTooltip;
