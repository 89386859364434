import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { isEmpty } from 'ramda';
import cn from 'classnames';
import { useTranslate, useDebounce } from '@hooks';
import { Button, InputLabel } from '@link/react-components';
import * as getters from '@root/modules/dictionaries/getters';
import { Select } from '@root/components/FormControls';
import {
  getCountries,
  getRegions,
  getCities,
  getCompanies,
  getPurchaseRequestsCategories
} from '@root/modules/dictionaries/storage';
import CategoriesSelect from '@root/components/CategoriesSelect';
import { useCreateSubscribeModal } from '@root/components/Modal';
import InputNumber from '@root/components/InputNumber';
import { changeSubscribe } from '@root/components/ChatWidget/PurchaseRequestsFilter/storage';
import { getCurrentUserLanguage } from '@root/storeGetters';
import { DEFAULT_COUNTRY_ID, DELAY } from '../../lib/constants';
import { useInitialValues } from '../../lib/useInitialValues';
import { toSubcribeDto } from '../../lib/mappers';
import styles from './CreateSubscribeForm.module.css';
import './style.css';

export const CreateSubscribeForm = ({ data = null }) => {
  const t = useTranslate();

  const [openModal, hideModal] = useCreateSubscribeModal();

  const withDebounce = useDebounce((callback) => callback(), DELAY);

  const dispatch = useDispatch();

  const countries = useSelector(getters.getCountries);
  const regions = useSelector(getters.getRegions);
  const cities = useSelector(getters.getCities);
  const categories = useSelector(getters.getPurchaseRequestsCategories);
  const customers = useSelector(getters.getCompanies);
  const language = useSelector(getCurrentUserLanguage);

  const initialValues = useInitialValues(data);
  const onSubmit = (values) => {
    changeSubscribe(toSubcribeDto(values))(dispatch);
    hideModal();
  };

  const formik = useFormik({
    initialValues,
    onSubmit
  });

  const onChangeCountries = (values) => {
    if (!values) return;

    if (formik.values.countries.length > values.length) {
      formik.setFieldValue('regions', []);
      formik.setFieldValue('cities', []);
    }

    if (Array.isArray(values)) {
      formik.setFieldValue('countries', values);
    }
  };

  const onChangeRegions = (values) => {
    if (!values) return;

    if (formik.values.regions.length > values.length) {
      formik.setFieldValue('cities', []);
    }

    if (Array.isArray(values)) {
      formik.setFieldValue('regions', values);
    }
  };

  const onChangeCities = (value) => {
    if (!value) return;

    if (Array.isArray(value)) {
      formik.setFieldValue('cities', value);
    }
  };

  const onChangeCategories = (value) => {
    if (!value) return;

    if (Array.isArray(value)) {
      formik.setFieldValue('categories', value);
    }
  };

  const onChangeCustomers = (value) => {
    if (!value) return;

    if (Array.isArray(value)) {
      formik.setFieldValue('customers', value);
    }
  };

  const onCountrySearch = (value) =>
    withDebounce(() => {
      const countriesThunk = getCountries({ search: value, lang: language });
      countriesThunk(dispatch);
    });

  const onRegionSearch = (value) =>
    withDebounce(async () => {
      if (!formik.values.countries.length) {
        await getCountries({ countryId: DEFAULT_COUNTRY_ID, lang: language })(
          dispatch
        );
        formik.setFieldValue('countries', {
          label: countries[0].label,
          value: DEFAULT_COUNTRY_ID
        });
      }
      const regionsThunk = getRegions({
        countryId: formik.values.countries,
        search: value,
        lang: language
      });
      regionsThunk(dispatch);
    });

  const onCitySearch = (value) =>
    withDebounce(() => {
      if (!formik.values.regions.length) {
        return;
      }
      const citiesThunk = getCities({
        countryId: formik.values.countries,
        regionId: formik.values.cities,
        search: value,
        lang: language
      });
      citiesThunk(dispatch);
    });

  const onCustomersSearch = (value) =>
    withDebounce(() => {
      const customersThunk = getCompanies({ search: value });
      customersThunk(dispatch);
    });

  const onChangeSumFrom = (value) => {
    if (formik.values.sumTo && Number(value) > Number(formik.values.sumTo)) {
      formik.setFieldValue('sumTo', value);
    }
    formik.setFieldValue('sumFrom', value);
  };

  const onChangeSumTo = (value) => {
    if (!formik.values.sumFrom) {
      formik.setFieldValue('sumFrom', 0);
    }
    formik.setFieldValue('sumTo', value);
  };

  const onSumBlur = (event) => {
    const { value } = event.target;
    if (Number(value) < Number(formik.values.sumFrom)) {
      formik.setFieldValue('sumFrom', value);
    }
  };

  useEffect(() => {
    onCountrySearch();
    getPurchaseRequestsCategories()(dispatch);
  }, []);

  const handleOpenModal = (categories = []) => {
    const data = {
      ...formik.values,
      categories
    };
    openModal({ data });
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <p className={styles.title}>{t('pr_filter_subtitle')}</p>

      <InputLabel
        text={t('Delivery country')}
        className={cn('cw-multi-select-wrapper', styles.field)}>
        <Select
          options={countries}
          value={formik.values.countries}
          onChange={onChangeCountries}
          onInputChange={onCountrySearch}
          id="country-select"
          arrowRenderer={null}
          multi
          placeholder={t('start_typing')}
          noResultsText={t('noResultsText')}
          clearable={false}
          removeSelected
          className={styles.select}
        />
      </InputLabel>

      <InputLabel
        text={t('Delivery region')}
        className={cn('cw-multi-select-wrapper', styles.field)}>
        <Select
          options={regions}
          value={formik.values.regions}
          onChange={onChangeRegions}
          onInputChange={onRegionSearch}
          id="region-select"
          arrowRenderer={null}
          multi
          placeholder={t('start_typing')}
          noResultsText={t('noResultsText')}
          clearable={false}
          removeSelected
          className={styles.select}
        />
      </InputLabel>

      {!isEmpty(formik.values.regions) && (
        <InputLabel
          text={t('Delivery city')}
          className={cn('cw-multi-select-wrapper', styles.field)}>
          <Select
            options={cities}
            value={formik.values.cities}
            onChange={onChangeCities}
            onInputChange={onCitySearch}
            id="city-select"
            arrowRenderer={null}
            multi
            placeholder={t('start_typing')}
            noResultsText={t('noResultsText')}
            clearable={false}
            removeSelected
            className={styles.select}
          />
        </InputLabel>
      )}

      <InputLabel
        text={t('category_goods_and_services')}
        className={styles.field}>
        <CategoriesSelect
          selectedList={formik.values.categories}
          list={categories}
          onChoose={onChangeCategories}
          nextModalHandler={handleOpenModal}
          className={styles.categoryInput}
        />
      </InputLabel>

      <InputLabel
        text={t('Customer')}
        className={cn('cw-multi-select-wrapper', styles.field)}>
        <Select
          options={customers}
          value={formik.values.customers}
          onChange={onChangeCustomers}
          onInputChange={onCustomersSearch}
          id="customers-select"
          arrowRenderer={null}
          multi
          placeholder={t('start_typing')}
          noResultsText={t('noResultsText')}
          clearable={false}
          removeSelected
          className={styles.select}
          menuStyle={{ height: '120px' }}
        />
      </InputLabel>

      <InputLabel
        text={`${t('sum_request')}, ${t('rub')}.`}
        className={styles.field}>
        <div className={styles.priceContainer}>
          <InputNumber
            value={formik.values.sumFrom}
            onChange={onChangeSumFrom}
            placeholder={t('minvalue')}
            noArrows
            autoSet={false}
            className={styles.priceInput}
          />
          {' – '}
          <InputNumber
            value={formik.values.sumTo}
            onChange={onChangeSumTo}
            placeholder={t('maxvalue')}
            noArrows
            autoSet={false}
            className={styles.priceInput}
            onBlur={onSumBlur}
          />
        </div>
      </InputLabel>

      <div className={styles.actions}>
        <Button mode="text" onClick={hideModal}>
          {t('Cancel')}
        </Button>
        <Button type="submit" className={styles.submit}>
          {t('Save changes')}
        </Button>
      </div>
    </form>
  );
};
