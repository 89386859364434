import React from 'react';
import { sendJoinRequest } from '../commandsAndEvents';
import { useVideoChatContext } from './useVideoChatContext';
import { VIDEO_CHAT_MODES } from './useVideoChat';

export function useClickByVideoChatLink() {
  const context = useVideoChatContext();

  const connectToVideoChat = React.useCallback(
    (roomId, chatId) => {
      context.addRoomChatConnection({ roomId, chatId });

      sendJoinRequest(roomId, context.peerId);
    },
    [context.peerId, context.addRoomChatConnection]
  );

  return React.useCallback(
    async (roomId, chatId) => {
      if (context.mode === VIDEO_CHAT_MODES.Open) {
        connectToVideoChat(roomId, chatId);
        return;
      }
      if (context.mode === VIDEO_CHAT_MODES.Hide) {
        if (context.chatId === chatId && context.roomId === roomId) {
          context.showWindow();
        } else {
          context.endCall({
            onSubmited: () => connectToVideoChat(roomId, chatId)
          });
        }
        return;
      }

      context.showLobby(roomId, chatId);
    },
    [
      context.mode,
      context.roomId,
      context.addRoomChatConnection,
      context.showLobby,
      connectToVideoChat
    ]
  );
}
