import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTranslation as translate } from 'react-i18next';
import { isNil } from 'ramda';
import { withRouter } from '../../../hoc/withRouter';
import './styles.css';

const memberName = (member, pathname, isCurrentMember) =>
  isCurrentMember ? (
    <div className="member_info__name" style={{ color: '#000000' }}>
      {member.userName}
    </div>
  ) : (
    <Link
      className="member_info__name"
      to={`${pathname}/contacts/${member.employeeId}`}>
      {member.userName}
    </Link>
  );

const Member = ({ t, member, location, isCurrentMember }) => {
  const imgSrc =
    isNil(member.avatarSrc) || !member.avatarSrc
      ? '/img/user.svg'
      : member.avatarSrc;
  const { pathname } = location;

  return (
    <div className="member_info">
      <img className="member_info__img" src={imgSrc} alt="avatar" />
      <div className="member_info__desc">
        {memberName(member, pathname, isCurrentMember)}
        <div className="member_info__company-name">{member.companyName}</div>
      </div>
      <div className="member_info_role">
        {member.memberRole ? t(`channel-roles-${member.memberRole}`) : '–'}
      </div>
    </div>
  );
};

Member.propTypes = {
  t: PropTypes.func.isRequired,
  member: PropTypes.object.isRequired,
  isCurrentMember: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(translate()(Member));
