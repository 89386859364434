import React from 'react';
import {
  Modal,
  Button,
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock
} from 'react-bootstrap';
import { withTranslation as translate } from 'react-i18next';
import { connect } from 'react-redux';
import ImageControl from './ImageControl';
import * as userActionCreators from '../action-creators/user';
import * as modalActionCreators from '../action-creators/modal';
import { validateSystemId } from '../utils/validateField';

class UserEditor extends React.Component {
  static isNameInCompanyValid(value) {
    if (value && value.length) {
      return validateSystemId(value);
    }
    return true;
  }

  constructor() {
    super();
    this._onSaveClick = this.onSaveClick.bind(this);
    this.onSetImg = this.onSetImg.bind(this);
    this.onDelImg = this.onDelImg.bind(this);
  }

  onSetImg() {
    const { showModal, updateUserField } = this.props;
    showModal('SAVE_IMG_URL', {
      submit: (url) => {
        updateUserField('avatar', url);
        return Promise.resolve();
      },
      onClosed: () => showModal('EDIT_USER')
    });
  }

  onDelImg() {
    this.props.updateUserField('avater', '');
  }

  onSaveClick() {
    const { saveUser } = this.props;
    saveUser(true);
  }

  getProperty(field) {
    return this.props.user.get(field) || '';
  }

  getUserValidationState() {
    const { user } = this.props;
    if (
      user &&
      user.get('firstName') &&
      user.get('lastName') &&
      user.get('nickname')
    ) {
      return true;
    }
    return false;
  }

  getFieldValidationState(field) {
    const { user } = this.props;
    return user && user.get(field);
  }

  userImg(type) {
    return this.props.user.getIn([type, 'thumbnail']);
  }

  showHelpInfo(field) {
    const { t } = this.props;
    if (!this.getFieldValidationState(field)) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Required fields empty')}
        </HelpBlock>
      );
    }
    return null;
  }

  showValidNameInCompanyInfo() {
    const { t, user } = this.props;
    if (!UserEditor.isNameInCompanyValid(user.get('nickname'))) {
      return (
        <HelpBlock id="invalidFieldMessage" style={{ color: 'red' }}>
          {t('Wrong name format')}
        </HelpBlock>
      );
    }
    return null;
  }

  render() {
    const { t, user, updateUserField } = this.props;
    const inputProps = (field, includeBlur = true) => ({
      value: this.getProperty(field),
      onChange: (e) => {
        updateUserField(field, e.target.value);
      },
      onBlur: includeBlur ? undefined : undefined
    });

    return (
      <Modal id="userModalWindow" enforceFocus show>
        <Modal.Header>
          <Modal.Title>{t('Enter information about yourself')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ImageControl
            name="userImage"
            imgSrc={user.get('avatar')}
            onFileChoose={this.onSetImg}
            onImageRemove={this.onDelImg}
            style={{
              position: 'absolute',
              right: '15px',
              width: '210px',
              height: '210px',
              zIndex: 1
            }}
          />
          <div style={{ marginRight: '220px' }}>
            <FormGroup
              controlId="userNameInCompany"
              validationState={
                this.getFieldValidationState('nickname') &&
                UserEditor.isNameInCompanyValid(user.get('nickname'))
                  ? 'success'
                  : 'error'
              }>
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {t('Name in company')}
                <span style={{ color: 'red' }}>* </span>
              </ControlLabel>
              <FormControl
                type="text"
                maxLength="60"
                value={user.get('nickname')}
                {...inputProps('nickname')}
              />
              {this.showHelpInfo('nickname')}
              {this.showValidNameInCompanyInfo()}
            </FormGroup>
            <FormGroup
              controlId="userFirstName"
              validationState={
                this.getFieldValidationState('firstName') ? 'success' : 'error'
              }>
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {t('First Name')}
                <span style={{ color: 'red' }}>* </span>
              </ControlLabel>
              <FormControl
                type="text"
                maxLength="60"
                value={user.get('firstName')}
                {...inputProps('firstName')}
              />
              {this.showHelpInfo('firstName')}
            </FormGroup>
            <FormGroup
              controlId="userLastName"
              validationState={
                this.getFieldValidationState('lastName') ? 'success' : 'error'
              }>
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {t('Last Name')}
                <span style={{ color: 'red' }}>* </span>
              </ControlLabel>
              <FormControl
                type="text"
                value={user.get('lastName')}
                {...inputProps('lastName')}
              />
              {this.showHelpInfo('lastName')}
            </FormGroup>
            <FormGroup controlId="userPhone">
              <ControlLabel
                style={{
                  color: '#999999',
                  fontWeight: 'normal',
                  fontSize: '14px'
                }}>
                {t('Phone number')}
              </ControlLabel>
              <FormControl
                type="text"
                value={user.get('phoneNumber')}
                {...inputProps('phoneNumber')}
              />
            </FormGroup>
          </div>
          <span style={{ color: 'red' }}>* </span>
          {t('Required fields')}
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="saveUserButton"
            bsStyle="primary"
            disabled={
              !this.getUserValidationState() ||
              !UserEditor.isNameInCompanyValid(user.get('nickname'))
            }
            onClick={this._onSaveClick}>
            {t('Save')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  company: state.get('company'),
  user: state.getIn(['user', 'user'])
});

export default translate(['ui'], { wait: true })(
  connect(
    mapStateToProps,
    Object.assign(userActionCreators, modalActionCreators)
  )(UserEditor)
);
