import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import cn from 'classnames';
import { createPortal } from 'react-dom';
import { Icon, Typography } from '@components';
import styles from './Snackbar.module.css';
import iconsView from '../../../assets/images/constants';

const DEFAULT_OPTIONS = Object.freeze({
  small: false,
  withAnimation: true,
  text: '',
  time: 3000,
  iconName: 'information',
  iconSize: 'm',
  iconClassName: '',
  textVariant: 'body1Reg',
  textClassName: styles.text,
  closable: true
});

const Snackbar = forwardRef((_, ref) => {
  const optionsRef = useRef(DEFAULT_OPTIONS);
  const [isShow, setIsShow] = useState(false);
  const [DOMContainer] = useState(
    document.getElementById('snackbar-container')
  );
  const timer = useRef(undefined);
  function handleTimeout() {
    timer.current = setTimeout(() => {
      setIsShow(false);
    }, time);
  }

  function handleClose() {
    clearTimeout(timer.current);
    setIsShow(false);
  }
  useEffect(() => {
    if (!optionsRef.current.withAnimation) return undefined;
    if (isShow) {
      handleTimeout();
    }

    return () => clearTimeout(timer.current);
  }, [isShow]);

  useEffect(() => {
    if (!isShow) {
      optionsRef.current = DEFAULT_OPTIONS;
    }
  }, [isShow]);

  useImperativeHandle(ref, () => ({
    show(text, options) {
      optionsRef.current = { ...optionsRef.current, ...(options || {}), text };

      setIsShow(true);
    },
    close() {
      setIsShow(false);
    }
  }));

  const {
    withAnimation,
    iconSize,
    iconClassName,
    textVariant,
    textClassName,
    text,
    time,
    iconName,
    closable,
    small
  } = optionsRef.current;

  return createPortal(
    isShow && (
      <div
        className={cn(styles.snackbar, {
          [styles.animation]: withAnimation,
          [styles.small]: small
        })}>
        <Icon name={iconName} size={iconSize} className={iconClassName} />
        <Typography variant={textVariant} className={textClassName}>
          {text}
        </Typography>
        {closable && (
          <Icon
            name={iconsView.CloseLarge}
            onClick={handleClose}
            size="xs"
            className={styles.closeIcon}
          />
        )}
      </div>
    ),
    DOMContainer
  );
});

export default Snackbar;
