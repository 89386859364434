import React from 'react';
import cn from 'classnames';
import { Typography } from '@link/react-components';
import { useTranslate } from '@hooks';
import styles from './commissionInfoTable.module.css';

const formatSum = (sum) =>
  new Intl.NumberFormat('ru-RU', {
    maximumFractionDigits: 2
  }).format(sum);
const Cell = ({ data, className }) => (
  <Typography variant="body1Reg" className={cn(styles.cell, className)}>
    {data}
  </Typography>
);

const Row = ({ children }) => <div className={styles.row}>{children}</div>;

const TitleCell = ({ data, className }) => (
  <Typography variant="body1Med" className={cn(styles.cell, className)}>
    {data}
  </Typography>
);

export function CommissionInfoTable({ commission }) {
  const t = useTranslate();

  const translate = (key) => t(`modules.finance.commissionInfoTable.${key}`);

  return (
    <div className={styles.table}>
      <Row>
        <TitleCell className={styles.title} data={translate(`sum`)} />
        <TitleCell className={styles.title} data={translate(`commission`)} />
      </Row>
      {commission.map((item, index) => (
        <Row key={commission.id}>
          <Cell
            data={
              index === commission.length - 1
                ? `${translate(`from`)} ${formatSum(
                    commission[index - 1].maxOrderPrice
                  )}`
                : `${translate(`to`)} ${formatSum(item.maxOrderPrice)}`
            }
          />
          <Cell data={`${formatSum(item.percentRate)} %`} />
        </Row>
      ))}
    </div>
  );
}
