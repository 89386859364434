import React from 'react';
import { connect } from 'react-redux';
import { isShow } from 'core/data/light/modalManager';
import PropTypes from 'prop-types';
import { getModalManager } from '../storeGetters';

import CompanyEditor from './CompanyEditor';
import ModalSimpleSubmit from '../containers/ContModalSimpleSubmit';
import ModalUpdateUser from './UserEditor';
import ModalEditEmployee from './Modal/ModalEditEmployeeOld';
import ModalShowEmployee from './Modal/ModalShowEmployee';
import ModalChangePassword from './Modal/ModalChangePassword';
import ModalSaveImgUrl from './Modal/ModalSaveImgUrl';
import ModalInputSubmit from './Modal/ModalInputSubmit';
import ModalAddSupplier from './Modal/ModalAddSupplier';
import ModalMoveSupplier from './Modal/ModalMoveSupplier';
import ModalEditSupplierGroup from './Modal/ModalEditSupplierGroup';
import ModalMoveCustomer from './Modal/ModalMoveCustomer';
import ModalAddContact from './Modal/ModalAddContact';
import {
  ModalAddPriceQuantityCategory,
  ModalEditPriceQuantityCategory,
  initNeedPreviewModal,
  initModalAddEmployee,
  initModalInviteEmployee,
  initManageEmployeePermissions,
  initModalEditEmployee,
  initViewEmployeesByRole,
  initModalConfirmResetEmployeePermissions,
  initModalChangeUserStatus,
  initModalQuickRegistration,
  initModalChangeOwner,
  initFillCompanyDataModal,
  initViewAttachmentsModal,
  initCreateSubscribeModal,
  initSetDateFilterModal,
  initCommissionInfoModal,
  initProposalCommissionInfoModal
} from './Modal';

import ModalResponsibleUser from './Modal/ModalResponsibleUser';
import ModalAdminConfirm from './Modal/ModalAdminConfirm';
import ModalAdminChangeOwner from './Modal/ModalAdminChangeOwner';
import ModalChangeCompanyEmail from './Modal/ModalChangeCompanyEmail';
import ModalHelp from './Modal/ModalHelp';
import ModalRespondToRequest from './Modal/ModalRespondToRequest';
import ModalPartnerInfo from './Modal/ModalPartnerInfo';
import ModalCreateGroupChat from './Modal/ModalCreateGroupChat';
import ModalCreateTopicChat from './Modal/ModalCreateTopicChat';
import ModalEditTopic from './Modal/ModalEditTopic';
import ModalAddToGroupChat from './Modal/ModalAddToGroupChat/ModalAddToGroupChat';
import ModalCreateContactList from './Modal/ModalCreateContactList';
import ModalCreateDialogList from './Modal/ModalCreateDialogList';
import ModalEditContactList from './Modal/ModalEditContactList';
import ModalInvitePartner from './Modal/ModalInvitePartner/ModalInvitePartner';
import ModalAddPartner from './Modal/ModalAddPartner';
import ModalAddHiddenEmployeeByLink from './Modal/ModalAddHiddenEmployeeByLink';
import ModalEditDialogList from './Modal/ModalEditDialogList';
import EditChannel from './Modal/EditChannel';
import EditMembers from './Modal/EditMembers';
import ModalEmployeesStorageStatistic from './Modal/ModalEmployeesStorageStatistic';
import ModalAccountDeleteRestricted from './Modal/ModalAccountDeleteRestricted';
import ModalArchiveDialog from './Modal/ModalArchiveDialog';
import ModalContactForwardMessage from './Modal/ModalContactForwardMessage';
import ModalDialogForwardMessage from './Modal/ModalDialogForwardMessage';
import ModalViewForwardMessage from './Modal/ModalViewForwardMessage';
import ModalEditChannelUserRole from './Modal/ModalEditChannelUserRole/ModalEditChannelUserRole';
import ModalViewChannelInfo from './Modal/ModalViewChannelInfo/ModalViewChannelInfo';
import ModalImageViewer from './Modal/ModalImageViewer/ModalImageViewer';
import ModalPDFViewer from './Modal/ModalPDFViewer/ModalPDFViewer';
import ModalDocxViewer from './Modal/ModalDocxViewer/ModalDocxViewer';
import ModalOtherViewer from './Modal/ModalOtherFileViewer';

import CWModalCreateContactList from './ChatWidget/Modal/CreateContactList';
import CWModalCreateContactSubList from './ChatWidget/Modal/CreateContactSubList';
import CWModalCreateDialogList from './ChatWidget/Modal/CreateDialogList';
import CWModalCreateDialogSubList from './ChatWidget/Modal/CreateDialogSubList';
import CWModalEditContactList from './ChatWidget/Modal/EditContactList';
import CWModalEditDialogList from './ChatWidget/Modal/EditDialogList';
import CWAddToContactList from './ChatWidget/Modal/AddToContactList';
import CWAddToDialogList from './ChatWidget/Modal/AddToDialogList';
import CWModalEditUserContactLists from './ChatWidget/Modal/EditUserContactLists';
import CWModalEditDialogDialogLists from './ChatWidget/Modal/EditDialogDialogLists';

import CWModalStorageFileAttache from './ChatWidget/Modal/StorageFileAttache';
import CWModalCreateGroupChat from './ChatWidget/Modal/CreateGroupChat';
import CWModalEditGroupChat from './ChatWidget/Modal/EditGroupChat';
import CWModalAddToGroupChat from './ChatWidget/Modal/AddToGroupChat';
import CWModalDeleteContact from './ChatWidget/Modal/DeleteContact';
import CWModalBlockContact from './ChatWidget/Modal/BlockContact';

import CWModalConfirmSubmit from './ChatWidget/Modal/ConfirmSubmit';

import CWModalContactsList from './ChatWidget/Modal/ListModals/Contacts';
import CWModalChatsList from './ChatWidget/Modal/ListModals/Chats';
import CWModalMessagesList from './ChatWidget/Modal/ListModals/Messages';

import CWModalChooseHistoryDownload from './ChatWidget/Modal/ChooseHistoryDownload';
import CWModalConfirmActionWithPassword from './ChatWidget/Modal/ConfirmActionWithPassword';
import CWModalStorageOversize from './ChatWidget/Modal/StorageOversize';
import ModalAddLink from './Modal/template/modal_add-link';
import ModalNotificationNoInternetConnection from './Modal/ModalNotificationNoInternetConnection';
import ModalSubmitWithComment from './Modal/ModalSubmitWithComment';
import ModalRefuseRespond from './Modal/PurchaseRequests/ModalRefuseRespond';
import ModalSendToReworkRespond from './Modal/PurchaseRequests/ModalSendToReworkRespond';
import { ModalInvitePartnerCompany } from './Modal/ModalInvitePartnerCompany';
import { TASK_MANAGER_MODALS } from '../tasks-manager/components/Modals';
import RequestKPByEmail from './Modal/RequestKPByEmail';
import ModalCategorySelect from './Modal/ModalCategorySelect';
import ModalItemsSelect from './Modal/ModalItemsSelect';
import ModalSendEmail from './Modal/ModalSendEmail';
import ModalAddUserToConstructor from './Modal/ModalAddUserToConstructor';
import {
  SuppliersActivitiesModal,
  SUPPLIERS_ACTIVITIES_MODAL
} from './SuppliersActivities';
import ModalCreatePurchaseRequest from './Modal/ModalCreatePurchaseRequest';
import ModalImportResponse from './Modal/ModalImportResponse';
import { wizards } from './Wizards';
import { ModalExtendResponseEndDate } from './Modal/ModalExtendRespondEndDate';
import * as ModalAcceptPurchaseResponse from './Modal/ModalAcceptPurchaseResponse';
import * as ModalAcceptPurchaseResponses from './Modal/ModalAcceptPurchaseResponses';
import { VIDEO_CHAT_MODALS } from './Modal/videoChat';
import { initVideoChatsModals } from '../modules/VideoChat/components';
import { ModalReliability } from './Modal/ModalReliability';
import { ModalResponseApprovalComments } from './Modal/ModalResponseApprovalComments';
import { ModalAssignResponseReviewFlow } from './Modal/ModalAssignResponseReviewFlow';
import ModalShowNeedsDefinition from './Modal/ModalShowNeedsDefinition/ModalShowNeedsDefinition';
import { ModalEmployeePreview } from './Modal/ModalEmployeePreview';
import { IMPORT_PARTNERS_MODAL_KEY } from './Modal/ModalImportPartners/constants';
import { ModalImportPartners } from './Modal/ModalImportPartners';
import { ModalGenerateApiToken } from './Modal/ModalGenerateApiToken';
import { ModalSelectPrimaryPartner } from './Modal/ModalSelectPrimaryPartner';

const MODAL_COMPONENTS = {
  EDIT_COMPANY_DIALOG: CompanyEditor,
  ADD_PRICES_QUANTITY_CATEGORY: ModalAddPriceQuantityCategory,
  EDIT_PRICE_QUANTITY_CATEGORY: ModalEditPriceQuantityCategory,
  SIMPLE_SUBMIT: ModalSimpleSubmit,
  SUBMIT_WITH_COMMENT: ModalSubmitWithComment,
  DECLINE_PURCHASE_RESPONSE: ModalRefuseRespond,
  SEND_TO_REWORK_PURCHASE_RESPONSE: ModalSendToReworkRespond,
  EDIT_USER: ModalUpdateUser,
  EDIT_EMPLOYEE: ModalEditEmployee,
  SHOW_EMPLOYEE: ModalShowEmployee,
  CHANGE_USER_PASSWORD: ModalChangePassword,
  SAVE_IMG_URL: ModalSaveImgUrl,
  INPUT_SUBMIT: ModalInputSubmit,
  ADD_SUPPLIER: ModalAddSupplier,
  INVITE_SUPPLIER: ModalAddSupplier,
  MOVE_SUPPLIER: ModalMoveSupplier,
  MOVE_CUSTOMER: ModalMoveCustomer,
  EDIT_SUPPLIER_GROUP: ModalEditSupplierGroup,
  ADD_NEW_CONTACT: ModalAddContact,
  RESPONSIBLE_USER: ModalResponsibleUser,
  ADMIN_CONFIRM: ModalAdminConfirm,
  ADMIN_CHANGE_OWNER: ModalAdminChangeOwner,
  CHANGE_COMPANY_EMAIL: ModalChangeCompanyEmail,
  INITIAL_HELP: ModalHelp,
  RESPOND_CONFIRM: ModalRespondToRequest,
  PARTNER_INFO: ModalPartnerInfo,
  CREATE_GROUP_CHAT: ModalCreateGroupChat,
  CREATE_TOPIC_CHAT: ModalCreateTopicChat,
  EDIT_TOPIC: ModalEditTopic,
  ADD_TO_GROUP_CHAT: ModalAddToGroupChat,
  CREATE_CONTACT_LIST: ModalCreateContactList,
  CW_CREATE_CONTACT_LIST: CWModalCreateContactList,
  CW_CREATE_CONTACT_SUBLIST: CWModalCreateContactSubList,
  CW_CREATE_DIALOG_LIST: CWModalCreateDialogList,
  CW_CREATE_DIALOG_SUBLIST: CWModalCreateDialogSubList,
  CW_EDIT_CONTACT_LIST: CWModalEditContactList,
  CW_EDIT_DIALOG_LIST: CWModalEditDialogList,
  CW_ADD_TO_CONTACT_LIST: CWAddToContactList,
  CW_ADD_TO_DIALOG_LIST: CWAddToDialogList,
  CW_EDIT_USER_CONTACT_LISTS: CWModalEditUserContactLists,
  CW_EDIT_DIALOG_DIALOG_LISTS: CWModalEditDialogDialogLists,
  CW_GROUP_CHAT_CREATE: CWModalCreateGroupChat,
  CW_GROUP_CHAT_EDIT: CWModalEditGroupChat,
  CW_GROUP_CHAT_ADD_MEMBERS: CWModalAddToGroupChat,
  CW_DELETE_CONTACT: CWModalDeleteContact,
  CW_BLOCK_CONTACT: CWModalBlockContact,
  CW_CHOOSE_HISTORY_DOWNLOAD: CWModalChooseHistoryDownload,
  CW_CONFIRM_ACTION_WITH_PASSWORD: CWModalConfirmActionWithPassword,
  ADD_LINK: ModalAddLink,
  CREATE_DIALOG_LIST: ModalCreateDialogList,
  EDIT_DIALOG_LIST: ModalEditDialogList,
  EDIT_CONTACT_LIST: ModalEditContactList,
  INVITE_PARTNER: ModalInvitePartner,
  INVITE_COMPANY_PARTNER: ModalInvitePartnerCompany,
  [IMPORT_PARTNERS_MODAL_KEY]: ModalImportPartners,
  GENERATE_API_TOKEN: ModalGenerateApiToken,
  SELECT_PRIMARY_PARTNER: ModalSelectPrimaryPartner,
  EDIT_CHANNEL: EditChannel,
  EDIT_MEMBERS: EditMembers,
  ADD_PARTNER: ModalAddPartner,
  ADD_HIDDEN_EMPLOYEE_BY_LINK: ModalAddHiddenEmployeeByLink,
  STORAGE_STATISTIC: ModalEmployeesStorageStatistic,
  ACCOUNT_DELETE_RESTRICTED: ModalAccountDeleteRestricted,
  ARCHIVE_DIALOG: ModalArchiveDialog,
  MSG_FORWARD_CONTACT: ModalContactForwardMessage,
  MSG_FORWARD_DIALOG: ModalDialogForwardMessage,
  MSG_FORWARD_VIEW: ModalViewForwardMessage,
  EDIT_CHANNEL_USER_ROLE: ModalEditChannelUserRole,
  VIEW_CHANNEL_INFORMATION: ModalViewChannelInfo,
  IMAGE_VIEWER: ModalImageViewer,
  PDF_VIEWER: ModalPDFViewer,
  DOCX_VIEWER: ModalDocxViewer,
  OTHER_VIEWER: ModalOtherViewer,
  STORAGE_FILE_ATTACHE: CWModalStorageFileAttache,
  CONFIRM_SUBMIT: CWModalConfirmSubmit,
  CONTACTS_LIST: CWModalContactsList,
  CHATS_LIST: CWModalChatsList,
  MESSAGES_LIST: CWModalMessagesList,
  CW_STORAGE_OVERSIZE: CWModalStorageOversize,
  NOTIFICATION_NO_INTERNET_CONNECTION: ModalNotificationNoInternetConnection,
  SEND_TO_EMAIL: RequestKPByEmail,
  CATEGORY_SELECT: ModalCategorySelect,
  ITEMS_SELECT: ModalItemsSelect,
  MODAL_SEND_EMAIL: ModalSendEmail,
  MODAL_ADD_USER_TO_CONSTRUCTOR: ModalAddUserToConstructor,
  MODAL_CREATE_PURCHASE_REQUEST: ModalCreatePurchaseRequest,
  MODAL_IMPORT_RESPONSE: ModalImportResponse,
  ...TASK_MANAGER_MODALS,
  [SUPPLIERS_ACTIVITIES_MODAL]: SuppliersActivitiesModal,
  MODAL_EXTEND_RESPONSE_END_DATE: ModalExtendResponseEndDate,
  ...wizards,
  [ModalAcceptPurchaseResponse.key]: ModalAcceptPurchaseResponse.Modal,
  [ModalAcceptPurchaseResponses.key]: ModalAcceptPurchaseResponses.Modal,
  ...VIDEO_CHAT_MODALS,
  ...initVideoChatsModals,
  MODAL_RELIABILITY: ModalReliability,
  MODAL_RESPONSE_APPROVAL_COMMENTS: ModalResponseApprovalComments,
  MODAL_ASSIGN_RESPONSE_REVIEW_FLOW: ModalAssignResponseReviewFlow,
  SHOW_NEEDS_DEFINITION: ModalShowNeedsDefinition,
  MODAL_EMPLOYEE_PREVIEW: ModalEmployeePreview,
  ...initNeedPreviewModal,
  ...initModalAddEmployee,
  ...initModalInviteEmployee,
  ...initViewEmployeesByRole,
  ...initManageEmployeePermissions,
  ...initModalEditEmployee,
  ...initModalConfirmResetEmployeePermissions,
  ...initModalChangeUserStatus,
  ...initModalQuickRegistration,
  ...initModalChangeOwner,
  ...initFillCompanyDataModal,
  ...initViewAttachmentsModal,
  ...initCreateSubscribeModal,
  ...initSetDateFilterModal,
  ...initCommissionInfoModal,
  ...initProposalCommissionInfoModal
};

const ModalRoot = ({ modalManager }) => {
  if (!isShow(modalManager)) return <div />;

  const SpecificModal = MODAL_COMPONENTS[modalManager.current.name];

  if (!SpecificModal) {
    console.error(
      `Not found modalManager component name: ${modalManager.current.name}`
    );
    return <div />;
  }

  return <SpecificModal {...modalManager.current.props} />;
};

ModalRoot.propTypes = {
  modalManager: PropTypes.object.isRequired
};

/**
 * Map state func
 *
 * @param {object} state
 * @returns {object}
 */
function mapStateToProps(state) {
  return {
    modalManager: getModalManager(state)
  };
}

export default connect(mapStateToProps)(ModalRoot);
