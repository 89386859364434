import React, { useCallback } from 'react';
import { myCompanyService } from '@api';
import { useTranslate } from '@hooks';
import { useSnackbar } from '@components/Snackbar';
import {
  useManageEmployeePermissionsModal,
  useConfirmResetEmployeePermissionsModal
} from '@root/components/Modal';
import iconsView from '@root/assets/images/constants';
import TemplateModal from '../ModalSimpleSubmit';
import { MODAL_KEY } from './constants';
import styles from './ModalConfirmResetEmployeePermissions.module.css';

const ModalConfirmResetEmployeePermissions = ({ employeeId }) => {
  const t = useTranslate();
  const [showSnackbar] = useSnackbar();
  const [, closeCurrentModal] = useConfirmResetEmployeePermissionsModal();
  const [openManageEmployeePermissionsModal] =
    useManageEmployeePermissionsModal();

  function translate(tKey) {
    return t(`modules.resetEmployeePermissionsModal.${tKey}`);
  }

  const resetPermissions = useCallback(async () => {
    await myCompanyService.deletePermissions(employeeId);
    closeCurrentModal();
  }, [employeeId, closeCurrentModal]);

  const onCancel = useCallback(() => {
    openManageEmployeePermissionsModal({ employeeId });
  }, [employeeId, openManageEmployeePermissionsModal]);

  const afterSuccessSubmit = useCallback(() => {
    showSnackbar(translate('afterSucceed'), {
      iconName: iconsView.Select,
      iconClassName: styles.snackbarIcon
    });
  }, [t, showSnackbar]);

  const afterFailureSubmit = useCallback(() => {
    showSnackbar(translate('afterFailure'));
  }, [t, showSnackbar]);

  return (
    <TemplateModal
      title={translate('title')}
      text={translate('text')}
      textBtnConfirm={translate('btnConfirmText')}
      textBtnCancel={translate('btnCancelText')}
      onCancel={onCancel}
      submit={resetPermissions}
      onSubmited={afterSuccessSubmit}
      onFailed={afterFailureSubmit}
    />
  );
};

export const initModalConfirmResetEmployeePermissions = {
  [MODAL_KEY]: ModalConfirmResetEmployeePermissions
};
