import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withTranslation as translate } from 'react-i18next';
import './Input.css';

const Input = ({
  t,
  id,
  value,
  placeholder,
  onChange,
  onSubmit,
  maxLength,
  maxHeight,
  className,
  style = {},
  hideBtn,
  hideCounter
}) => {
  // State for send image focus
  const [isFocused, setFocused] = useState(false);

  // Handle textarea height
  const setHeight = () => {
    const textarea = document.getElementById(id || 'input-textarea');
    if (textarea) {
      const currentHeight = textarea.scrollHeight;
      if (currentHeight < maxHeight) {
        $(textarea)
          .outerHeight(34)
          .outerHeight(textarea.scrollHeight + 2);
      } else {
        $(textarea).outerHeight(34).outerHeight(maxHeight);
      }
    }
  };
  useEffect(setHeight, [value]);

  // Handle change
  const handleChange = (e) => {
    const text = e.target.value;
    onChange(text, e);
  };

  // Handle submit
  const handleKeyDown = (e) => {
    const { keyCode, ctrlKey, shiftKey } = e;

    // Disable default behaviour for "Enter" key
    if (keyCode === 13) {
      e.preventDefault();
    }

    // If only "Enter" key pressed
    if (keyCode === 13 && !ctrlKey && !shiftKey) {
      onSubmit();
    }

    // If key combination pressed ("Enter" + "Ctrl" || "Enter" + "Shift")
    if ((keyCode === 13 && ctrlKey) || (keyCode === 13 && shiftKey)) {
      onChange(`${value}\n`);
    }
  };

  return (
    <>
      {!hideCounter && (
        <h6 className="input-component__counter">
          <small
            style={{ float: 'right' }}>{`${value.length}/${maxLength}`}</small>
        </h6>
      )}
      <div className="input-component__container">
        <textarea
          id={id || 'input-textarea'}
          className={`input-component__textarea ${className}`}
          style={{ ...style }}
          value={value}
          placeholder={placeholder || t('Enter message')}
          maxLength={maxLength}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
        {!hideBtn && (
          <div
            title={t('chat_button_hover')}
            className={
              isFocused
                ? 'chat-submit-button'
                : 'chat-submit-button-out-of-focus'
            }>
            <img
              onClick={onSubmit}
              className={isFocused ? '' : 'chat-icon-out-of-focus'}
              src="/img/send.svg"
              alt=""
            />
          </div>
        )}
      </div>
    </>
  );
};

Input.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  maxHeight: PropTypes.number,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  hideBtn: PropTypes.bool,
  hideCounter: PropTypes.bool
};

Input.defaultProps = {
  id: '',
  maxLength: 4000,
  maxHeight: 156,
  placeholder: '',
  className: '',
  style: {},
  hideBtn: false,
  hideCounter: false
};

export default translate()(Input);
