import React, { useRef, useEffect } from 'react';
import { Modal } from '@link/react-components';
import { Lobby } from '../../Lobby';
import { MODAL_KEY } from './constants';
import { useLobbyModal } from './useLobbyModal';
import {
  useVideoChatContext,
  useSubscribeVideoChatEvent,
  EventKeys
} from '../../../hooks';
import c from './LobbyModal.module.css';

export const LobbyModal = ({ onSubmit }) => {
  const [, hideModal] = useLobbyModal();
  const callCleanupBeforeDestroyRef = useRef(true);
  const videoChatContext = useVideoChatContext();

  useSubscribeVideoChatEvent(
    EventKeys.AllowedJoin,
    () => {
      callCleanupBeforeDestroyRef.current = false;
      hideModal();
    },
    []
  );

  useEffect(
    () => () => {
      if (callCleanupBeforeDestroyRef.current) {
        videoChatContext.destroyCurrentStream();
      }
    },
    []
  );

  return (
    <Modal className={c.container} open onClose={hideModal}>
      <Modal.Content className={c.content}>
        <Lobby onSubmit={onSubmit} onCancel={hideModal} />
      </Modal.Content>
    </Modal>
  );
};

export const initLobbyModal = { [MODAL_KEY]: LobbyModal };
