import { handle } from 'core/data/messageBus/frontend';
import { taskQRY } from 'core/messages/task';
import * as taskBadges from 'core/messages/taskBadges';
import {
  purchaseRequestMessages,
  videoRoomPeerMessages,
  contactMessages,
  partnerMessages
} from './messages';
import { makeProfile } from './components/ChatWidget/modules/profile/entity';
import {
  showProfile,
  updateProfile
} from './components/ChatWidget/modules/profile/actions';
import { actions as taskManagerAC } from './tasks-manager/reducers/task';
import * as storageGetters from './storeGetters';
import { setSuccessMessage } from './action-creators/message';
import * as modalActions from './action-creators/modal';

const NS = 'contact';

export default (history) => (dispatch, getState) => {
  handle(contactMessages.profileQRY.set, ({ profile }) =>
    dispatch(showProfile(makeProfile(profile)))
  );
  handle(contactMessages.profileQRY.update, (params) =>
    dispatch(updateProfile(params))
  );

  handle(
    { type: 'QUERY', name: `QUERY:${NS}:profile` },
    ({ profile, params, operation }) => {
      if (operation === 'set') {
        dispatch(showProfile(makeProfile(profile)));
      }
      if (operation === 'update') {
        dispatch(updateProfile(params));
      }
    }
  );

  handle(taskQRY.add, (params) => {
    dispatch(taskManagerAC.addTaskToList(params));
  });
  handle(taskQRY.update, (params) => {
    dispatch(taskManagerAC.updateTask(params));
  });

  handle(taskBadges.badgesQRY.set, (params) => {
    dispatch(taskManagerAC.setBadges(params));
  });

  handle(taskBadges.badgesQRY.update, ({ creatorUserId, taskId }) => {
    const currentUserId = storageGetters.getCurrentUserId(getState());

    if (currentUserId === creatorUserId) return;

    dispatch(taskManagerAC.addBadge({ badge: taskId }));
  });

  handle(taskBadges.badgesQRY.remove, (params) => {
    dispatch(taskManagerAC.removeBadge({ badge: params.taskId }));
  });

  handle(videoRoomPeerMessages.videoRoomPeerQRY.add, (params) => {
    console.log('call add peer', params);
  });

  handle(videoRoomPeerMessages.videoRoomPeerQRY.remove, (params) => {
    console.log('call remove peer', params);
  });

  handle(
    purchaseRequestMessages.createdEVT,
    ({ id, isNeedRedirect = true }) => {
      dispatch(setSuccessMessage({ key: 'Request create success' }));
      if (isNeedRedirect) {
        history.push(`/requests/my/${id}/edit`);
      }
    }
  );

  handle(partnerMessages.invitedByEmailEVT, () => {
    dispatch(modalActions.hideTargetModal('INVITE_COMPANY_PARTNER'));
  });
};
