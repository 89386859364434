import React from 'react';
import { IMaskInput } from 'react-imask';
import { useTranslate } from '@root/TranslateProvider';
import { Typography } from '@link/react-components';
import { ERRORS_TO_TEXT } from '@root/features/PersonalInfo/lib/constants';
import cn from 'classnames';
import styles from './PhoneNumberControl.module.css';

export const PhoneNumberControl = ({
  value,
  onChange,
  error,
  required = false
}) => {
  const t = useTranslate();

  const translate = (type) => t(`features.personalInfo.phoneNumber.${type}`);

  return (
    <div className={styles.phoneNumberWrapper}>
      <Typography className={styles.phoneNumberLabel}>
        <label htmlFor="visibility-select">{translate('label')}</label>
        {required && <span className={styles.requiredChar}>*</span>}
      </Typography>

      <IMaskInput
        className={cn(styles.input, { [styles.error]: error })}
        mask="+{0} (000) 000-0000"
        value={value}
        placeholder={translate('placeholder')}
        onAccept={(value) => onChange('phoneNumber', value)}
      />

      {error && (
        <Typography variant="caption" className={styles.errorMessage}>
          {t(ERRORS_TO_TEXT[error])}
        </Typography>
      )}
    </div>
  );
};
