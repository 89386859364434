import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation as translate } from 'react-i18next';
import { reduxForm, SubmissionError } from 'redux-form/immutable';
import {
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock
} from 'react-bootstrap';

import { Link } from 'react-router-dom';
import Icon from 'react-components/src/icons/index';
import iconsViews from 'react-components/src/icons/constants';
import { withRouter } from '../../hoc/withRouter';
import { syncValidation } from '../../utils/registrationValidator';
import Checkbox from '../../components/Checkbox';

import * as auth from '../../action-creators/auth';
import * as service from '../../action-creators/services';
import * as user from '../../action-creators/user';
import { validateEmail } from '../../utils/validateField';
import RoundSmallButton from '../../components/Buttons/RoundSmallButton';
import SecurityBoxSubmit from '../../components/MessengerAuth/SecurityBoxSubmit';
import { getToLink } from '../helpers';
import SuccessRegPage from './SuccessRegPage';
import './style.css';
import { FormUsrle } from './components/FormUsrle';
import { isNilOrEmpty } from '../../utils/utils';

class MessengerReg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      repeatPassword: '',
      firstName: '',
      antibot: '',
      lastName: '',
      middleName: '',
      phone: '',
      position: '',
      companyBranch: '',
      roles: { customer: true, supplier: true },
      propsSet: false,
      registrationSuccess: false,
      focusOut: false,
      termsOfConditions: false,
      companyInfo: {},
      termsOfConfidential: false,
      isPasswordShown: false,
      isRepeatPasswordShown: false
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.inputProps = this.inputProps.bind(this);
    this.verifyForm = this.verifyForm.bind(this);
    this.showEmailError = this.showEmailError.bind(this);
    this.showPasswordError = this.showPasswordError.bind(this);
    this.validatePassword = this.validatePassword.bind(this);
    this.setBranch = this.setBranch.bind(this);
    this.addBranch = this.addBranch.bind(this);
    this.removeBranch = this.removeBranch.bind(this);
    this.toggleCheckbox = this.toggleCheckbox.bind(this);
  }

  get isEmployeeInvite() {
    const { inviteInfo } = this.props;
    return (
      inviteInfo &&
      inviteInfo.get('isUploaded') &&
      inviteInfo.get('token') &&
      inviteInfo.get('type') === 'employee'
    );
  }

  UNSAFE_componentWillMount() {
    const {
      match: {
        params: { token }
      },
      getInviteData
    } = this.props;
    console.log('token', token);
    if (token) {
      getInviteData(token);
    }
    this.props.getCurrentUser();
  }

  UNSAFE_componentWillReceiveProps(props) {
    const { inviteInfo } = props;

    if (
      inviteInfo.get('isUploaded') &&
      !this.state.propsSet &&
      inviteInfo.get('token')
    ) {
      this.setState({
        email: inviteInfo.get('email'),
        companyNameFromInvite: inviteInfo.get('companyName'),
        companyInfo: { shortName: inviteInfo.get('companyName') },
        companyBranch:
          inviteInfo.get('type') === 'employee'
            ? inviteInfo.get('companyBranch')
            : '',
        propsSet: true
      });
    }
  }

  inputProps(field) {
    const { setRegisterErrors } = this.props;
    return {
      value: this.state[field],
      onChange: (e) => {
        if (field === 'email' && this.showEmailError()) {
          setRegisterErrors([]);
        }
        if (
          field === 'email' ||
          field === 'password' ||
          field === 'repeatPassword'
        ) {
          return this.setState({ [field]: e.target.value.trim() });
        }
        if (e.target.value === ' ') {
          return this.setState({ [field]: '' });
        }
        if (/^[ЁёА-яA-Za-z]*$/.test(e.target.value)) {
          return this.setState({
            [field]: e.target.value.replace(/^\s+|\s(?=\s)/g, '')
          });
        }
        return undefined;
      }
    };
  }

  onSubmit() {
    const {
      t,
      performRegistration,
      gaSend,
      match: {
        params: { token, tokenLink, partnerToken }
      }
    } = this.props;
    const {
      email,
      password,
      firstName,
      lastName,
      middleName,
      phone,
      position,
      companyInfo,
      antibot,
      roles
    } = this.state;
    gaSend({ category: 'Common', action: 'reg_open', label: this.state.email });

    return performRegistration({
      email,
      password,
      firstName,
      lastName,
      middleName,
      phone,
      position,
      companyInfo,
      companyBranch: '',
      token,
      tokenLink,
      partnerToken,
      antibot,
      roles
    })
      .then(({ ok, json }) => {
        if (ok) {
          if (json.location) {
            window.location = json.location;
          } else {
            this.setState({ registrationSuccess: true });
          }
        }
        return json;
      })
      .then((data) => {
        if (data && data.errors) {
          if (data.errors[0].message === 'Email not allowed to proceed') {
            alert(t(data.errors[0].message));
            // document.location = 'http://goodwix.com/#1481478485405-5b2d56a2-f3c8';
          }
          throw new SubmissionError({ _error: t(data.errors[0].message) });
        }
      });
  }

  toggleCheckbox(checkboxName) {
    return this.setState((prevState) => ({
      [checkboxName]: !prevState[checkboxName]
    }));
  }

  verifyEmail(email) {
    if (email.length) {
      return validateEmail(email);
    }
    return true;
  }

  verifyForm() {
    const {
      email,
      password,
      repeatPassword,
      firstName,
      lastName,
      companyInfo,
      termsOfConfidential,
      termsOfConditions,
      companyNameFromInvite
    } = this.state;
    return (
      this.verifyEmail(email) &&
      email.trim().length > 0 &&
      password.trim().length >= 8 &&
      password.trim() === repeatPassword.trim() &&
      firstName.trim().length > 0 &&
      lastName.trim().length > 0 &&
      (!!companyNameFromInvite || !!companyInfo.inn) &&
      termsOfConfidential &&
      termsOfConditions
    );
  }

  showEmailError() {
    const { t, errors } = this.props;
    if (errors && errors.size) {
      const error = errors.find((err) => err.get('type') === 'email');
      if (!error) return null;
      return (
        <HelpBlock id="emailErrorMessage" style={{ color: 'red' }}>
          {t(error.get('message'))}
        </HelpBlock>
      );
    }
    if (!this.verifyEmail(this.state.email)) {
      return (
        <HelpBlock id="emailErrorMessage" style={{ color: 'red' }}>
          {t('Wrong email format')}
        </HelpBlock>
      );
    }
    return null;
  }

  validatePassword() {
    const { password, repeatPassword } = this.state;
    return repeatPassword.length && password !== repeatPassword;
  }

  showPasswordError() {
    const { t } = this.props;
    const notValid = this.validatePassword();
    if (notValid) {
      return (
        <HelpBlock id="passwordErrorMessage" style={{ color: 'red' }}>
          {t('Passwords not match')}
        </HelpBlock>
      );
    }
    return null;
  }

  showMinLengthError() {
    const { t } = this.props;
    if (
      this.state.password.length > 0 &&
      this.state.password.length < 8 &&
      this.state.focusOut
    ) {
      return (
        <HelpBlock id="passwordErrorMessage" style={{ color: 'red' }}>
          {t('Min length 8 symbols')}
        </HelpBlock>
      );
    }
    return null;
  }

  setCompanyInfo = async (companyInfo) => {
    this.setState({ companyInfo });
  };

  setBranch(value, n) {
    const branches = this.state.companyBranch.split('| ');
    branches[n] = value;
    this.setState({ companyBranch: branches.join('| ') });
  }

  addBranch() {
    this.setState({ companyBranch: `${this.state.companyBranch}| ` });
  }

  removeBranch(n) {
    const branches = this.state.companyBranch.split('| ');
    branches.splice(n, 1);
    this.setState({ companyBranch: branches.join('| ') });
  }

  trimField(field) {
    this.setState({ [field]: this.state[field].trim() });
  }

  togglePassportView = (type) =>
    this.setState((prevState) => ({
      ...prevState,
      [type]: !this.state[type]
    }));

  render() {
    const {
      t,
      match: {
        params: { tokenLink, partnerToken }
      }
    } = this.props;
    const {
      termsOfConditions,
      termsOfConfidential,
      registrationSuccess,
      email,
      companyInfo
    } = this.state;

    if (registrationSuccess) {
      return <SuccessRegPage email={email} />;
    }

    return (
      <div className="reg-wrapper">
        <div className="reg-container">
          <div className="reg-left-side">
            <div className="reg-logo-container">
              <a className="reg-logo" href="https://istock.link/">
                {' '}
              </a>
            </div>
            <div className="reg-messenger-info">
              <div className="reg-messenger-info-item">
                <div className="reg-messenger-info-circle">
                  <div className="reg-messenger-info-circle-inner" />
                </div>
                {t('marketplace')}
              </div>
              <div className="reg-messenger-info-item">
                <div className="reg-messenger-info-square" />
                {t('corporate_messenger')}
              </div>
            </div>
            <img
              className="reg-image"
              src="/img/registration_image.svg"
              alt=""
            />
          </div>
          <div className="reg-right-side">
            <Form
              horizontal
              style={{
                whiteSpace: 'nowrap'
              }}>
              <div className="reg-form-row">
                <span className="reg-form-title">{t('Registration')}</span>
                <Link
                  className="reg-login-link"
                  to={getToLink({ tokenLink, partnerToken })}>
                  {t('registration.enter')}
                </Link>
              </div>
              <FormGroup
                controlId="email"
                validationState={
                  this.state.email.length
                    ? this.showEmailError()
                      ? 'error'
                      : 'success'
                    : null
                } // eslint-disable-line no-nested-ternary
                className="reg-form-group">
                <ControlLabel className="reg-form-label">
                  {t('E-mail')}
                  <span style={{ color: 'red' }}>* </span>
                </ControlLabel>
                <FormControl
                  type="mail"
                  placeholder={t('enter email')}
                  autoComplete="off"
                  {...this.inputProps('email')}
                />
                {this.showEmailError()}
              </FormGroup>
              <FormGroup
                controlId="password"
                validationState={
                  // eslint-disable-next-line no-nested-ternary
                  this.state.password.length
                    ? this.validatePassword() ||
                      (this.state.password.length < 8 &&
                        this.state.repeatPassword.length < 8)
                      ? 'error'
                      : 'success'
                    : null
                }
                className="reg-form-group"
                style={{ position: 'relative' }}>
                <ControlLabel className="reg-form-label">
                  {t('Password')}
                  <span style={{ color: 'red' }}>* </span>
                </ControlLabel>
                <FormControl
                  type={this.state.isPasswordShown ? 'text' : 'password'}
                  placeholder={t('Min length 8 symbols')}
                  autoComplete="off"
                  {...this.inputProps('password')}
                  onBlur={() => this.setState({ focusOut: true })}
                  className="reg-input-password"
                />
                <div className="form-group-reg-eye">
                  <RoundSmallButton
                    svg={
                      <Icon
                        size={18}
                        icon={
                          this.state.isPasswordShown
                            ? iconsViews.View
                            : iconsViews.Hide
                        }
                      />
                    }
                    onClick={() => this.togglePassportView('isPasswordShown')}
                    tabIndex="-1"
                  />
                </div>
                {this.showMinLengthError()}
              </FormGroup>
              <FormGroup
                controlId="passwordRepeat"
                validationState={
                  // eslint-disable-next-line no-nested-ternary
                  this.state.repeatPassword.length
                    ? this.validatePassword() ||
                      (this.state.password.length < 8 &&
                        this.state.repeatPassword.length < 8)
                      ? 'error'
                      : 'success'
                    : null
                }
                style={{ position: 'relative' }}
                className="reg-form-group">
                <ControlLabel className="reg-form-label">
                  {t('Password repeat')}
                  <span style={{ color: 'red' }}>* </span>
                </ControlLabel>
                <FormControl
                  type={this.state.isRepeatPasswordShown ? 'text' : 'password'}
                  placeholder={t('Min length 8 symbols')}
                  autoComplete="off"
                  {...this.inputProps('repeatPassword')}
                />
                <div className="form-group-reg-eye">
                  <RoundSmallButton
                    svg={
                      <Icon
                        size={18}
                        icon={
                          this.state.isRepeatPasswordShown
                            ? iconsViews.View
                            : iconsViews.Hide
                        }
                      />
                    }
                    onClick={() =>
                      this.togglePassportView('isRepeatPasswordShown')
                    }
                    tabIndex="-1"
                  />
                </div>
                {this.showPasswordError()}
              </FormGroup>
              <FormGroup controlId="companyName" className="reg-form-group">
                <FormUsrle
                  isSelected={!isNilOrEmpty(companyInfo)}
                  setInfo={this.setCompanyInfo}
                  disabled={this.isEmployeeInvite}
                  defaultValue={this.state.companyNameFromInvite}
                />
              </FormGroup>
              <FormGroup
                controlId="secondName"
                validationState={this.state.lastName.length ? 'success' : null}
                className="reg-form-group">
                <ControlLabel className="reg-form-label">
                  {t('Last Name')}
                  <span style={{ color: 'red' }}>* </span>
                </ControlLabel>
                <FormControl
                  type="text"
                  placeholder={t('enter surname')}
                  {...this.inputProps('lastName')}
                />
              </FormGroup>
              {/* anitbot field */}
              <FormGroup className="reg-form-group special-antibot-input">
                <ControlLabel className="reg-form-label">
                  {t('First Name')}
                  <span style={{ color: 'red' }}>* </span>
                </ControlLabel>
                <FormControl
                  type="text"
                  tabIndex="-1"
                  placeholder={t('enter name')}
                  {...this.inputProps('antibot')}
                />
              </FormGroup>
              <FormGroup
                controlId="name"
                validationState={this.state.firstName.length ? 'success' : null}
                className="reg-form-group">
                <ControlLabel className="reg-form-label">
                  {t('First Name')}
                  <span style={{ color: 'red' }}>* </span>
                </ControlLabel>
                <FormControl
                  type="text"
                  placeholder={t('enter name')}
                  {...this.inputProps('firstName')}
                />
              </FormGroup>
              <FormGroup
                controlId="middleName"
                validationState={
                  this.state.middleName.length ? 'success' : null
                }
                className="reg-form-group">
                <ControlLabel className="reg-form-label">
                  {t('Middle Name')}
                </ControlLabel>
                <FormControl
                  type="text"
                  placeholder={t('enter middleName')}
                  {...this.inputProps('middleName')}
                />
              </FormGroup>
              <FormGroup className="reg-form-group">
                <span style={{ color: 'red' }}>* </span>
                {t('Required fields')}
              </FormGroup>
              <FormGroup
                controlId="termsOfConditions"
                validationState={termsOfConditions ? 'success' : null}
                className="reg-form-group">
                <Checkbox
                  set={termsOfConditions}
                  onClick={() => this.toggleCheckbox('termsOfConditions')}
                />
                <span className="reg-terms-of-conditions">
                  <span
                    onClick={() => this.toggleCheckbox('termsOfConditions')}
                    style={{ cursor: 'pointer' }}>
                    {`${t('accept_conditions')} `}
                  </span>
                  <a
                    className="reg-terms-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://istock.link/soglashenie/">
                    {t('terms of condition')}
                  </a>
                </span>
              </FormGroup>
              <FormGroup
                controlId="termsOfConfidential"
                validationState={termsOfConfidential ? 'success' : null}
                className="reg-form-group">
                <Checkbox
                  set={termsOfConfidential}
                  onClick={() => this.toggleCheckbox('termsOfConfidential')}
                />
                <span className="reg-terms-of-conditions">
                  <span
                    onClick={() => this.toggleCheckbox('termsOfConfidential')}
                    style={{ cursor: 'pointer' }}>
                    {`${t('accept_conditions')} `}
                  </span>
                  <a
                    className="reg-terms-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://istock.link/privacy-policy/">
                    {t('terms of confidential')}
                  </a>
                </span>
              </FormGroup>
              <SecurityBoxSubmit
                name="registerUserButton"
                submitAction={this.onSubmit}
                isDisabled={!this.verifyForm()}
                text={t('Register')}
                className="reg-submit-button"
              />
            </Form>

            {/* Использование компонента Link нецелесообразно, требуется редизайн формы */}
            <div className="contact-support">
              <span>{`${t('contact-support')} `}</span>
              <a
                className="contact-support-link"
                href={`mailto:${process.env.SUPPORT_EMAIL}`}>
                {process.env.SUPPORT_EMAIL}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const MessengerRegForm = reduxForm({
  form: 'registration',
  validate: syncValidation
})(MessengerReg);

export default withRouter(
  connect(
    (state) => ({
      inviteInfo: state.getIn(['invite', 'inviteInfo']),
      errors: state.getIn(['invite', 'errors'])
    }),
    (dispatch) =>
      bindActionCreators(
        {
          performRegistration: auth.performRegistration,
          getInviteData: auth.getInviteData,
          setRegisterErrors: auth.setRegisterErrors,
          gaSend: service.gaSend,
          getCurrentUser: user.getCurrentUser
        },
        dispatch
      )
  )(translate()(MessengerRegForm))
);
