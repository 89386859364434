import request from '../lib/request';

class RegistrationService {
  #baseUrl = '/api/registration';

  async validateCompany(company) {
    return request.post(`${this.#baseUrl}/allow-company`, { inn: company.inn });
  }

  async validateEmail(email) {
    return request.post(`${this.#baseUrl}/allow-user`, { email });
  }
}

export const registrationService = new RegistrationService();
