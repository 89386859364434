import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import block from 'bem-cn-lite';
import { withTranslation as translate } from 'react-i18next';
import {
  Modal,
  Button,
  FormGroup,
  FormControl,
  ControlLabel
} from 'react-bootstrap';
import FileSelectWrap from '../FileSelectWrap';
import './styles/modal_theme_default.styl';
import Mimetype from '../../utils/mimetype';

import { ModalContainer } from './elements';
import Checkbox from '../Checkbox';
import * as modalAction from '../../action-creators/modal';
import * as messageAction from '../../action-creators/message';
import * as servicesAction from '../../action-creators/services';

const b = block('modal-container');
const mimetype = Mimetype();

class ModalSaveImgUrl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: '',
      compress: true
    };
    this.onChangeUrl = this.onChangeUrl.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.submit = this.submit.bind(this);
  }

  getChildContext() {
    return { b };
  }

  onChangeUrl(e) {
    this.setState({ url: e.target.value });
  }

  onClickCropCheckbox = () => {
    this.setState((prevState) => ({ compress: !prevState.compress }));
  };

  checkFileAllowType(url) {
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
    const fileType = mimetype.lookup(url);
    return allowedTypes.includes(fileType);
  }

  hideModal() {
    this.props.hideModalDialog();
    this.props.onClosed();
  }

  async submit() {
    const { submit, onClosed, user, gaSend } = this.props;
    await gaSend({
      category: 'Supplier',
      action: 'picture_loc_success',
      label: user.get('email')
    });
    return submit(this.state.url)
      .then(() => Promise.resolve(this.hideModal()))
      .then(() => onClosed());
  }

  handleFileChoose(files, needSuccessMessage) {
    const {
      upload,
      onClosed,
      setErrorMessage,
      setInfoMessage,
      setSuccessMessage,
      gaSend,
      imageCompressOption,
      user
    } = this.props;
    const { compress } = this.state;

    const limit = 20000;
    const approvedTypes = [
      'image/jpeg',
      'image/pjpeg',
      'image/png',
      'image/webp'
    ];
    gaSend({
      category: 'Supplier',
      action: 'picture_loc_success',
      label: user.get('email')
    });
    const size = Array.from(files).reduce(
      (acc, file) => (acc += file.size / 1000),
      0
    ); // eslint-disable-line
    const isTypesApproved = Array.from(files).every((file) =>
      approvedTypes.includes(file.type)
    );

    if (!isTypesApproved) {
      this.hideModal();
      return setErrorMessage({ key: 'image_wrong_formant_error' });
    }

    if (size > limit) {
      this.hideModal();
      return setErrorMessage({ key: 'Maximum file size is 20 Mb' });
    }

    setInfoMessage({ key: 'Uploading' });
    try {
      const options = {
        file: files[0]
      };

      if (imageCompressOption) {
        options.compress = compress;
      }

      upload(options);
    } catch (e) {
      if (e.status === 413) {
        this.hideModal();
        return setErrorMessage({ key: e.errors[0].message });
      }
    }
    if (needSuccessMessage) {
      setSuccessMessage({ key: 'upload_success' });
    }
    this.hideModal();
    return onClosed();
  }

  render() {
    const {
      t,
      title = 'Upload image',
      desc = t(
        'Enter the web - link to the image, and it will be displayed on the page'
      ),
      type,
      textBtnConfirm,
      imageCompressOption = false,
      needSuccessMessage = true
    } = this.props;

    return (
      <Modal id="saveImgUrl" onHide={this.hideModal} show>
        <ModalContainer mods={{ type: type || 'default', theme: 'default' }}>
          <Modal.Header>
            <Modal.Title>
              {t(title)}
              <span
                className="modal-header-cross pull-right"
                onClick={() => this.hideModal()}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>{desc}</p>
            <FormGroup
              controlId="formInlineName"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
              <ControlLabel style={{ fontWeight: 'normal', margin: 0 }}>{`${t(
                'Link'
              )}:`}</ControlLabel>
              <FormControl
                type="text"
                style={{ width: '100%', marginLeft: '10px' }}
                placeholder="www.example.com/image1.jpg"
                value={this.state.url}
                onChange={this.onChangeUrl}
              />
              <FileSelectWrap
                onChange={(files) =>
                  this.handleFileChoose(files, needSuccessMessage)
                }
                component={(props) => (
                  <a id="newimg" {...props} style={{ whiteSpace: 'nowrap' }}>
                    {t('select_file')}
                  </a>
                )}
                multiple={false}
                style={{ marginLeft: 10 }}
              />
            </FormGroup>
            {imageCompressOption ? (
              <div style={{ margin: '20px 0 15px' }}>
                <Checkbox
                  set={this.state.compress}
                  onClick={this.onClickCropCheckbox}
                />
                <span>{t('compress_image_for_storage')}</span>
              </div>
            ) : null}
          </Modal.Body>
          <Modal.Footer>
            <Button id="cancel" className="pull-left" onClick={this.hideModal}>
              {t('Cancel')}
            </Button>
            <Button
              id="saveCharacteristicButton"
              bsStyle="primary"
              onClick={this.submit}
              disabled={
                !this.state.url || !this.checkFileAllowType(this.state.url)
              }>
              {textBtnConfirm || t('Add')}
            </Button>
          </Modal.Footer>
        </ModalContainer>
      </Modal>
    );
  }
}

ModalSaveImgUrl.propTypes = {
  t: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  onClosed: PropTypes.func,
  hideModalDialog: PropTypes.func.isRequired
};

ModalSaveImgUrl.childContextTypes = {
  b: PropTypes.func
};

ModalSaveImgUrl.defaultProps = {
  onClosed: () => {}
};

export default connect(
  (state) => ({
    user: state.getIn(['user', 'user'])
  }),
  (dispatch) =>
    bindActionCreators(
      {
        hideModalDialog: modalAction.hideModalDialog,
        setErrorMessage: messageAction.setErrorMessage,
        setInfoMessage: messageAction.setInfoMessage,
        setSuccessMessage: messageAction.setSuccessMessage,
        gaSend: servicesAction.gaSend
      },
      dispatch
    )
)(translate(['ui'], { wait: true })(ModalSaveImgUrl));
