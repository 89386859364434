import React from 'react';
import { useModal } from '@hooks';
import { Button, Modal, Typography } from '@link/react-components';
import { useTranslate } from '../../../../TranslateProvider';
import { useVideoChatContext } from '../../../../modules/VideoChat/hooks';
import styles from './ModalExit.module.css';

const MODAL_KEY = 'MODAL_EXIT';
export const key = MODAL_KEY;

export const ModalExit = ({ endVideoCall }) => {
  const { onClose: hideModal } = useModal();
  const t = useTranslate();
  const { reconnectToCall } = useVideoChatContext();

  const onClose = () => {
    endVideoCall();
    hideModal();
  };

  const onReconnect = async () => {
    reconnectToCall();
    hideModal();
  };

  function translate(text) {
    return t(`videoChat.modals.confirmReturnToCall.${text}`);
  }

  return (
    <Modal disableBackdropClick open alignmentTop onClose={onClose}>
      <Modal.Content className={styles.content}>
        <Typography variant="h1">{translate('text')}</Typography>

        <Button onClick={onReconnect}>{translate('confirmBtn')}</Button>
      </Modal.Content>
    </Modal>
  );
};
