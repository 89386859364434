import React from 'react';
import cn from 'classnames';
import { useTranslate } from '@hooks';
import { Icon, Typography } from '@link/react-components';
import c from './InfoBlock.module.css';

// TODO почти полная копия legacy/frontend-web-publisher/src/components/BasicInformation/InfoBlock
export const InfoBlock = ({ className, text, children = null }) => {
  const t = useTranslate();

  return (
    <div className={cn(className, c.infoBlock)}>
      <Icon iconName="info-fill" width={18} height={18} className={c.icon} />
      {children ?? (
        <Typography className={c.text} variant="body1Reg">
          {t(text)}
        </Typography>
      )}
    </div>
  );
};
